import { Col, Row } from "reactstrap";
import type { ProjectMinimal } from "../../../utils/backend-types";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { useLoadProfilesGraph } from "../hooks/useLoadProfilesGraph";
import type { LoadProfileCategory } from "../LoadProfileManager.types";
import { LoadProfile } from "../LoadProfileManager.types";
import SettingsView from "../SettingsView/SettingsView";
import { LoadProfilePlots } from "./LoadProfilePlots/LoadProfilePlots";

interface LoadProfileProps {
  categories?: Array<LoadProfileCategory>;
  id: number;
  loadProfile: LoadProfile;
  projects?: Array<Pick<ProjectMinimal, "id" | "name">>;
  onAddCategory: (category: string) => void;
}

function LoadProfile({
  categories,
  id,
  loadProfile,
  projects,
  onAddCategory
}: LoadProfileProps) {
  const { data, isLoading, error } = useLoadProfilesGraph(id);

  return (
    <Row className="LoadProfile">
      <Col md={8}>
        <LoadOrError error={error} loading={isLoading}>
          {data && <LoadProfilePlots data={data} />}
        </LoadOrError>
      </Col>
      <Col md={4}>
        <SettingsView
          allProjects={projects}
          apiUrl={loadProfile.url}
          categories={categories}
          category={loadProfile.category}
          description={loadProfile.description}
          energyFormatted={loadProfile.energyFormatted}
          fullLoadHours={Math.round(
            loadProfile.yearlyEnergy / loadProfile.maxPower
          )}
          isGeneric={loadProfile.isGeneric}
          loadProfileId={id}
          maxPower={Math.round(loadProfile.maxPower)}
          name={loadProfile.name}
          projects={loadProfile.projects}
          type={loadProfile.type}
          unit={loadProfile.unit}
          onAddCategory={onAddCategory}
        />
      </Col>
    </Row>
  );
}

export { LoadProfile, LoadProfileProps };
