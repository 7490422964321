import { List } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function EegAnlageIncompleteErrors({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const eegAnlageIncompleteErrors =
    paragraph6CreditErrors.filter(
      (error) => error.eeg_anlage_incomplete?.length > 0
    ) || [];

  if (eegAnlageIncompleteErrors.length === 0) {
    return null;
  }

  const eegAnlageIncompleteError = eegAnlageIncompleteErrors[0];

  return (
    <List.Item>
      EEG-Anlage (EEG-Inbetriebnahmedatum und ggf. Jahr des Gebotstermins) von:{" "}
      {eegAnlageIncompleteError.eeg_anlage_incomplete.map((g, index) => (
        <React.Fragment key={`${g.site_id}_${g.id}`}>
          {g.site_id && g.id ? (
            <Link
              to={`../../liegenschaften/${g.site_id}/erzeuger/${g.id}/eeg-anlage`}
            >
              {g.name}
            </Link>
          ) : (
            g.name
          )}
          {index < eegAnlageIncompleteError.eeg_anlage_incomplete.length - 1 &&
            ", "}
        </React.Fragment>
      ))}
    </List.Item>
  );
}

export { EegAnlageIncompleteErrors };
