import { Paper, Title } from "@mantine/core";
import _ from "lodash";
import PropTypes from "prop-types";
import { PureComponent, createRef } from "react";
import { Navigate } from "react-router-dom";
import api from "../../../api";
import urls from "../../../urls";
import { uploadFileOrFiles } from "../../../utils/files/uploadFileOrFiles";
import { showToast } from "../../../utils/toast";
import { ErrorAlert } from "../../BuildingBlocks/ErrorAlert/ErrorAlert";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import UploadDropzone from "../../UploadDropzone/UploadDropzone";
import { DisplayAllToggleButton } from "./DisplayAllToggleButton/DisplayAllToggleButton";
import { ListItem } from "./ListItem/ListItem";
import LoadprofileUploadButtons from "./LoadprofileUploadButtons";
import "./ListView.scss";

const controller = new AbortController();

export default class ListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "list",
      loadProfiles: [],
      showAllLoadProfiles: false,
      uploadedLoadProfileId: null,
      error: undefined,
      uploading: false,
      dropzoneActive: false
    };
    this.dropzone = createRef();
  }

  toggleShowAllLoadProfiles = () => {
    this.setState({
      showAllLoadProfiles: !this.state.showAllLoadProfiles
    });
  };

  handleUploadFile = async (file) => {
    this.setState({ uploading: true });
    try {
      const response = await uploadFileOrFiles(
        file,
        urls.api.loadprofilesList(),
        "series",
        {
          project: this.props.projectId,
          name: "",
          type: "",
          category: null
        }
      );
      this.setState({
        uploading: false,
        uploadedLoadProfileId: response.data.id
      });
    } catch (error) {
      this.setState({ uploading: false });
      if (error.response.data.series) {
        alert(error.response.data.series);
      } else {
        showToast("error", error);
      }
    }
  };

  handleDeleteLoadProfile = async (id) => {
    if (window.confirm("Soll das Lastprofil wirklich gelöscht werden?")) {
      try {
        await this.deleteLoadProfile(id);
        const loadProfiles = this.state.loadProfiles.filter(
          (profile) => profile.id !== id
        );
        this.setState({
          loadProfiles: loadProfiles
        });
      } catch (error) {
        showToast("error", error);
      }
    }
  };

  async deleteLoadProfile(id) {
    const response = await api.delete(urls.api.loadprofilesDetail(id));
    return response.data;
  }

  handlePinLoadProfile = (id, name, pin) => {
    this.pinLoadProfile(id, pin)
      .then(() => {
        const loadProfiles = this.state.loadProfiles.slice();
        const loadProfile = loadProfiles.find(
          (loadProfile) => loadProfile.id === id
        );
        if (loadProfile) {
          let projects = loadProfile.projects.slice();
          if (
            pin &&
            !ListView.isLoadProfileAssignedToProject(
              loadProfile,
              this.props.projectId
            )
          ) {
            // if we should pin it and this project isn't already in the load profile's projects list...
            projects.push(this.props.projectId);
          } else if (!pin) {
            // if we should unpin, get all projects that aren't this one
            projects = projects.filter(
              (project) => project !== this.props.projectId
            );
          }

          // update the state with the new projects list
          loadProfile.projects = projects;
          this.setState({
            loadProfiles: loadProfiles
          });
        }
      })
      .catch((error) => {
        showToast("error", error);
      });
  };

  async pinLoadProfile(id, pin) {
    const submission = {
      project: this.props.projectId
    };

    const assignmentUrl = pin
      ? urls.api.loadprofilesAssignToProject(id)
      : urls.api.loadprofilesUnassignFromProject(id);
    const response = await api.post(assignmentUrl, submission);
    return response.data;
  }

  renderLoadProfile(loadProfile) {
    return (
      <ListItem
        category={loadProfile.category || ""}
        description={loadProfile.description}
        id={loadProfile.id}
        isGeneric={loadProfile.isGeneric}
        isPinned={ListView.isLoadProfileAssignedToProject(
          loadProfile,
          this.props.projectId
        )}
        key={loadProfile.id}
        maxPower={loadProfile.maxPower}
        name={loadProfile.name}
        totalKwh={loadProfile.yearlyEnergy}
        onDelete={this.handleDeleteLoadProfile}
        onPin={this.handlePinLoadProfile}
      />
    );
  }

  loadLoadProfiles = () => {
    this.setState({
      loading: true
    });
    this.getLoadProfiles()
      .then((data) => {
        // we want uploaded load profiles to appear first and all profiles should be sorted by creation
        const sortedData = _.sortBy(data, "isGeneric", "id");

        // sort load profiles by pinned and then unpinned
        const pinnedProfiles = sortedData.filter(
          (profile) =>
            profile.isGeneric ||
            ListView.isLoadProfileAssignedToProject(
              profile,
              this.props.projectId
            )
        );
        const unpinnedProfiles = sortedData.filter(
          (profile) =>
            !profile.isGeneric &&
            !ListView.isLoadProfileAssignedToProject(
              profile,
              this.props.projectId
            )
        );
        const allProfiles = pinnedProfiles.concat(unpinnedProfiles);

        this.setState({
          loadProfiles: allProfiles,
          loading: false,
          error: false
        });
      })
      .catch((error) => {
        if (!api.isCancel(error)) {
          console.log(error);
          this.setState({
            error: error
          });
        }
        this.setState({
          loading: false
        });
      });
  };

  async getLoadProfiles() {
    const response = await api.get(urls.api.loadprofilesList(), {
      signal: controller.signal
    });
    return response.data;
  }

  displayDropzone = () => {
    this.setState({ dropzoneActive: true });
  };

  hideDropzone = () => {
    this.setState({ dropzoneActive: false });
  };

  componentDidMount() {
    this.loadLoadProfiles();
  }

  componentWillUnmount() {
    controller.abort("API is being cancelled");
  }

  render() {
    const { projectId } = this.props;

    const {
      loadProfiles,
      showAllLoadProfiles,
      uploadedLoadProfileId,
      dropzoneActive,
      loading,
      error
    } = this.state;

    if (uploadedLoadProfileId) {
      return <Navigate replace to={uploadedLoadProfileId + "/"} />;
    }
    const filteredLoadProfiles = loadProfiles.filter((loadProfile) => {
      return (
        showAllLoadProfiles ||
        loadProfile.isGeneric ||
        ListView.isLoadProfileAssignedToProject(loadProfile, projectId)
      );
    });

    const dropzoneExplanationComponent = (
      <>
        <div className="dropzone-overlay-section">
          Die Datei muss aus zwei Spalten, jeweils mit Überschrift bestehen. Die
          erste Spalte muss das Datum und die Zeit der einzelnen Messwerte
          enthalten. Die zweite Spalte die Messwerte selbst. Die Einheit der
          Messwerte können Sie gesondert wählen.
        </div>
        <div className="dropzone-overlay-section">
          Wir stellen Vorlagen für das Format bereit.
        </div>
      </>
    );

    return (
      <UploadDropzone
        dropzoneExplanationComponent={dropzoneExplanationComponent}
        dropzoneIntroText="Um die Berechnung mit einem eigenen Lastprofil durchzuführen, können Sie es hier als Excel-Datei per Drag&amp;Drop hochladen."
        isActive={dropzoneActive}
        onHideDropzone={this.hideDropzone}
        onShowDropzone={this.displayDropzone}
        onUploadFile={this.handleUploadFile}
      >
        <Paper className="ListView">
          <Title variant="paper-header">Verfügbare Lastprofile</Title>
          <Section>
            <DisplayAllToggleButton
              showAll={this.state.showAllLoadProfiles}
              onChange={this.toggleShowAllLoadProfiles}
            />
            <div className="pull-right">
              <LoadprofileUploadButtons
                uploading={this.state.uploading}
                onUploadFile={this.displayDropzone}
              />
            </div>
          </Section>

          <Section>
            <div className="table-responsive">
              <table className="table m-table m-table--head-bg-brand">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Energie [kWh/a]</th>
                    <th>Höchstleistung [kW]</th>
                    <th>Vollbenutzungsstunden</th>
                    <th>Kategorie</th>
                    <th>Beschreibung</th>
                    <th width={110}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    filteredLoadProfiles.map((loadProfile) =>
                      this.renderLoadProfile(loadProfile)
                    )}
                </tbody>
              </table>
              {loading && (
                <div className="center-block">
                  <AnimatedLoadingIcon />
                </div>
              )}
              {error && <ErrorAlert error={error} />}
            </div>
          </Section>
        </Paper>
      </UploadDropzone>
    );
  }

  static isLoadProfileAssignedToProject(loadProfile, projectId) {
    return loadProfile.projects.includes(projectId);
  }
}

ListView.propTypes = {
  loadProfiles: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};
