import { memo, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { Navigate, useMatch, useResolvedPath } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useMissingData } from "../../../../../hooks/useMissingData";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import type { TabData } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import type { FormValues } from "../../../../CustomForm/useCustomForm";
import type { DataValue } from "../../../../DataSheet/DataSheet";
import type { FormFieldValue } from "../../../../DynamicForm/FormItems/FormField/FormField";
import type { FormFieldData } from "../../../../DynamicForm/FormItems/FormItems";
import type { ComponentEditWizardDispatchType } from "../ComponentEditWizard.types";
import { FormAction } from "../ComponentEditWizard.types";
import type { GeneralDataField } from "../Data/common";
import type { Form, FormSection } from "../Data/data-types";
import { EditFormControls } from "./EditFormControls/EditFormControls";
import { FormWithSections } from "./FormWithSections/FormWithSections";
import { Section } from "./Section/Section";
import { tabHasMissingData } from "./utils/tabHasMissingData";

export const SectionMemoized = memo(Section);

interface EditFormsProps {
  buttonContainer?: HTMLElement | null;
  forms: Array<Form>;
  formsDispatch: React.Dispatch<ComponentEditWizardDispatchType>;
  submitting: boolean;
  onClickSubmit: () => void;
  onClose: () => void;
}

function EditForms({
  forms,
  submitting,
  buttonContainer,
  formsDispatch,
  onClickSubmit,
  onClose
}: EditFormsProps) {
  const resolved = useResolvedPath(":activeTab");
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeTab = match?.params?.activeTab;
  const { missingData, missingFields, exclusiveOrFields } =
    useMissingData(activeTab);

  function extractValues(forms: Array<Form>) {
    return forms.reduce<FormValues>((acc, form) => {
      form.sections.forEach((section) => {
        Object.assign(acc, section.values);
      });
      return acc;
    }, {});
  }

  const allFormValues = extractValues(forms);

  const highlightedTabs = useMemo(() => {
    if (!missingData || !missingData.missingFields) {
      return [];
    }

    const tabs = Object.keys(missingData.missingFields);

    return tabs.filter((tab) => {
      return tabHasMissingData(
        tab,
        missingData,
        allFormValues,
        exclusiveOrFields
      );
    });
  }, [allFormValues, exclusiveOrFields, missingData]);

  function isHighlighted(
    missingFields: Array<string> | undefined,
    exclusiveOrFields: Array<Array<string>> | undefined,
    formItem: FormFieldData
  ) {
    if (!missingFields) {
      return false;
    }

    if (missingFields && missingFields.includes(formItem.name)) {
      if (exclusiveOrFields) {
        for (const exclusiveOrField of exclusiveOrFields) {
          if (exclusiveOrField.includes(formItem.name)) {
            return exclusiveOrField.every(
              (fieldName) =>
                !allFormValues[fieldName] || allFormValues[fieldName] === ""
            );
          }
        }
      }

      return true;
    }

    return false;
  }

  function handleUpdateValue(
    formIndex: number,
    sectionIndex: number,
    valueKeyValuePair: Record<string, FormFieldValue>
  ) {
    formsDispatch({
      type: FormAction.UpdateValue,
      payload: {
        formIndex: formIndex,
        sectionIndex: sectionIndex,
        valueKeyValuePair: valueKeyValuePair
      }
    });
  }

  function handleUpdateCustomComponentData(
    formIndex: number,
    data: Record<number, Record<string, DataValue>>
  ) {
    formsDispatch({
      type: FormAction.UpdateCustomComponentData,
      payload: {
        formIndex: formIndex,
        data: data
      }
    });
  }

  const fieldSatisfiesDependenciesMemoized = useCallback(
    (field: GeneralDataField) => {
      if (!field.dependencies) {
        return true;
      }

      for (let i = 0; i < field.dependencies.length; i++) {
        const dependency = field.dependencies[i];

        for (let j = 0; j < forms.length; j++) {
          for (let k = 0; k < forms[j].sections.length; k++) {
            const section = forms[j].sections[k];

            if (
              !!section.values &&
              Object.prototype.hasOwnProperty.call(
                section.values,
                dependency.field
              )
            ) {
              const fieldValue = section.values[dependency.field];

              if (
                Object.prototype.hasOwnProperty.call(dependency, "value") &&
                fieldValue !== dependency.value
              ) {
                return false;
              } else if (
                dependency.values &&
                !dependency.values.some(
                  (dependencyValue) => dependencyValue === fieldValue
                )
              ) {
                return false;
              }
            }
          }
        }
      }

      return true;
    },
    [forms]
  );

  const activeTabForm = activeTab
    ? forms.find((form) => form.name === activeTab)
    : forms[0];

  if (!activeTabForm) {
    return <Navigate replace to="." />;
  }

  const activeTabName = activeTabForm ? activeTabForm.name : forms[0].name;

  let Buttons: React.ReactNode = null;

  if (buttonContainer) {
    Buttons = (
      <>
        {ReactDOM.createPortal(
          <EditFormControls
            submitting={submitting}
            onClickCancel={onClose}
            onClickSubmit={onClickSubmit}
          />,
          buttonContainer
        )}
      </>
    );
  }

  const { tabs, tabComponents } = forms.reduce<{
    tabs: Array<TabData>;
    tabComponents: Record<string, React.ReactNode>;
  }>(
    (acc, form) => {
      acc.tabComponents[form.name] = (
        <FormTabName sections={form.sections} title={form.formTitle} />
      );
      acc.tabs.push({
        id: form.name,
        label: form.formTitle
      });

      return acc;
    },
    { tabs: [], tabComponents: {} }
  );

  return (
    <div className="EditForm">
      {forms.length > 1 && (
        <TabBar
          activeTab={activeTabName}
          firstTabIsIndex
          highlightedTabs={highlightedTabs}
          tabComponents={tabComponents}
          tabs={tabs}
        />
      )}
      <TabContent activeTab={activeTabName}>
        {forms.map((form, formIndex) => {
          return (
            <TabPane key={form.name} tabId={form.name}>
              {form.Component ? (
                <form.Component
                  {...form.extraProps}
                  onSetRequestData={(data) =>
                    handleUpdateCustomComponentData(formIndex, data)
                  }
                />
              ) : (
                <FormWithSections
                  exclusiveOrFields={exclusiveOrFields}
                  extraProps={form.extraProps}
                  fieldSatisfiesDependencies={
                    fieldSatisfiesDependenciesMemoized
                  }
                  isHighlighted={isHighlighted}
                  missingFields={missingFields}
                  sections={form.sections}
                  onUpdateValues={(sectionIndex, valueKeyValuePair) =>
                    handleUpdateValue(
                      formIndex,
                      sectionIndex,
                      valueKeyValuePair
                    )
                  }
                />
              )}
            </TabPane>
          );
        })}
      </TabContent>
      {Buttons}
    </div>
  );
}

interface FormTabNameProps {
  title: string;
  sections: Array<FormSection>;
}

function FormTabName({ title, sections }: FormTabNameProps) {
  const numErrors = sections
    ? sections.reduce((total, section) => {
        return section.errors
          ? total + Object.keys(section.errors).length
          : total;
      }, 0)
    : 0;

  return (
    <>
      {title} <ErrorInTabName numErrors={numErrors} />
    </>
  );
}

function ErrorInTabName({ numErrors }: { numErrors: number }) {
  if (numErrors === 0) {
    return null;
  }

  return (
    <span className="tab-error-count">
      <Icon name={IconName.Warning} />
      {numErrors}
    </span>
  );
}

export { EditForms, FormWithSections };
