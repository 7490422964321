import { useState } from "react";
import { Link } from "react-router-dom";

import type { WizardMissingData } from "../../../../../utils/backend-types";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { AssignmentType } from "../../EnergyWizard";

type MeterAssignmentProps = {
  done: boolean;
  missingData: Array<WizardMissingData>;
  isNext: boolean;
  isBlocked: boolean;
  updateSiteWizard: (type: AssignmentType) => Promise<void>;
};

function MeterAssignment({
  done,
  missingData,
  isNext,
  isBlocked,
  updateSiteWizard
}: MeterAssignmentProps) {
  const [isSpinning, setIsSpinning] = useState(false);
  const hasMissingData = missingData && missingData.length > 0;

  function onClickSubmit() {
    setIsSpinning(true);
    updateSiteWizard(AssignmentType.MeasurementData).then(() => {
      setIsSpinning(false);
    });
  }
  function getComponentByStep() {
    if (isBlocked) {
      return (
        <div className="missing-data-container">
          Der digitale Zwilling Ihrer Liegenschaft entspricht nicht den
          opti.node-Standards, so dass wir keine Aussage zur Datenverfügbarkeit
          treffen können. Bitte setzen Sie sich mit unserem Onboarding-Team in
          Verbindung
        </div>
      );
    } else if (hasMissingData && !done) {
      return (
        <div className="missing-data-container">
          <Button
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            tag={Link}
            to={"historischeMessdaten"}
          >
            Daten bereitstellen
          </Button>
        </div>
      );
    } else if (!done) {
      return (
        <div className="missing-data-container">
          <SpinButton
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            spin={isSpinning}
            onClick={() => onClickSubmit()}
          >
            Zählerdaten bestätigen
          </SpinButton>
        </div>
      );
    }
  }
  return <div>{getComponentByStep()}</div>;
}

export { MeterAssignment };
