import { List } from "@mantine/core";
import { Alert, AlertColor } from "../../../../../Alert/Alert";
import { Extendable } from "../../../../../Extendable/Extendable";

function RevokeDocumentInstruction() {
  return (
    <Alert
      color={AlertColor.Info}
      mb={"sm"}
      title="Sollten Sie Ihren Rückerstattungsantrag korrigieren wollen, empfehlen
        wir Ihnen folgendes Vorgehen:"
    >
      <Extendable>
        <List>
          <List.Item>
            Markieren Sie die zu korrigierenden Dokumente in der unten stehenden
            Liste als ungültig.
          </List.Item>

          <List.Item>
            Erstellen Sie einen neuen Antrag und die dazugehörigen Dokumente.
          </List.Item>

          <List.Item>
            Versenden Sie die Dokumente wie oben beschrieben per Mail an den
            Netzbetreiber.
          </List.Item>
        </List>
      </Extendable>
    </Alert>
  );
}

export { RevokeDocumentInstruction };
