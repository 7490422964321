import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import "./WizardTabBarComponent.scss";

interface WizardTabBarComponentProps {
  title: string;
  numErrors: number;
}

function WizardTabBarComponent({
  title,
  numErrors
}: WizardTabBarComponentProps) {
  return (
    <span className="WizardTabBarComponent">
      {title} <ErrorInTabName numErrors={numErrors} />
    </span>
  );
}

function ErrorInTabName({ numErrors }: { numErrors: number }) {
  if (numErrors === 0) {
    return null;
  }

  return (
    <span className="ErrorInTabName">
      <Icon name={IconName.Warning} />
      {numErrors}
    </span>
  );
}

export { WizardTabBarComponent, WizardTabBarComponentProps };
