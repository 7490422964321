import { useCallback, useMemo } from "react";

import urls from "../../../../../urls";
import type { Person } from "../../../../../utils/backend-types";
import { AdvancedCustomForm } from "../../../../CustomForm/CustomForm";
import { useCustomForm } from "../../../../CustomForm/useCustomForm";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../../../../PersonWizard/PersonWizard";
import type { FormFieldData } from "../../FormItems";
import FormItems from "../../FormItems";
import { withCreatableDropdown } from "../../withCreatableDropdown";
import { FormFieldType, type FormFieldValue } from "../FormField";

interface CompanyCellProps {
  companyId: number | null;
  companies: Array<Person>;
  isSolarContractor?: boolean;
  shouldRegulatoryDutiesBeChecked?: boolean;
  variantId: number;
  onChange: (companyId: number) => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CompanyCell({
  companyId,
  companies,
  isSolarContractor,
  shouldRegulatoryDutiesBeChecked,
  variantId,
  onChange
}: CompanyCellProps) {
  const choices = useMemo(
    () => [
      ...companies.map((company) => ({
        displayName: company.name,
        value: company.id
      }))
    ],
    [companies]
  );
  const formFields: Array<FormFieldData> = useMemo(
    () => [
      {
        name: "person",
        type: FormFieldType.Dropdown,
        label: "Unternehmen",
        placeholder: "Unternehmen auswählen",
        choices: choices,
        required: true,
        initialValue: companyId
      }
    ],
    [choices, companyId]
  );
  const CustomFormItemsComponent = useMemo(() => {
    const personNonFieldData: Record<string, FormFieldValue> =
      typeof isSolarContractor === "boolean" &&
      typeof shouldRegulatoryDutiesBeChecked === "boolean"
        ? {
            isSolarContractor: isSolarContractor,
            shouldRegulatoryDutiesBeChecked: shouldRegulatoryDutiesBeChecked,
            variant: variantId
          }
        : { variant: variantId };
    const personPostUrl = urls.api.personsByVariantId(variantId);
    const personPutUrlFunc = urls.api.person;

    return withCreatableDropdown(
      FormItems,
      "person",
      personPostUrl,
      personPutUrlFunc,
      PERSON_FORM_FIELD_NAMES,
      personNonFieldData
    );
  }, [isSolarContractor, shouldRegulatoryDutiesBeChecked, variantId]);

  const useCustomFormProps = useCustomForm(formFields, {});

  const handleChangeValue = useCallback(
    (name: string, value: string) => {
      useCustomFormProps.changeValue(name, value);
      onChange(parseInt(value, 10));
    },
    [useCustomFormProps, onChange]
  );

  return (
    <div className="company-cell">
      <LoadOrError loading={!CustomFormItemsComponent}>
        <AdvancedCustomForm
          {...useCustomFormProps}
          changeValue={handleChangeValue}
          CustomFormItemsComponent={CustomFormItemsComponent}
          formFields={formFields}
          hideButtons
        />
      </LoadOrError>
    </div>
  );
}

interface CompanyCellWrapperProps
  extends Omit<CompanyCellProps, "companies" | "variantId"> {
  companies?: Array<Person>;
  variantId?: number;
  value: number | null;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CompanyCellWrapper({
  companies,
  variantId,
  value,
  ...otherProps
}: CompanyCellWrapperProps) {
  if (!companies || typeof variantId === "undefined") {
    return <span>{value}</span>;
  }

  return (
    <CompanyCell companies={companies} variantId={variantId} {...otherProps} />
  );
}

export { CompanyCellWrapper as CompanyCell };
