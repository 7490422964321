import { Radio } from "@mantine/core";
import { RadioGroup } from "../../../../BuildingBlocks/Forms/RadioGroup/RadioGroup";

enum BooleanValue {
  True = "true",
  False = "false",
  Unknown = ""
}

interface BooleanFieldProps {
  name: string;
  id?: string;
  checked?: boolean | "";
  allowInput?: boolean;
  allowNull?: boolean;
  errorTexts?: Array<string>;
  onInput: (name: string, value: boolean | null) => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function BooleanField({
  name,
  id,
  checked,
  allowInput = true,
  allowNull,
  errorTexts = [],
  onInput
}: BooleanFieldProps) {
  function handleChange(newValue: string | null) {
    const value =
      newValue === BooleanValue.True
        ? true
        : newValue === BooleanValue.False
          ? false
          : null;
    onInput(name, value);
  }

  return (
    <RadioGroup
      className="FormField"
      errorText={errorTexts[0]}
      id={id}
      layout="horizontal"
      name={name}
      value={
        checked
          ? BooleanValue.True
          : checked === false
            ? BooleanValue.False
            : BooleanValue.Unknown
      }
      onChange={handleChange}
    >
      <Radio disabled={!allowInput} label="Ja" value={BooleanValue.True} />
      <Radio disabled={!allowInput} label="Nein" value={BooleanValue.False} />
      {allowNull && (
        <Radio
          disabled={!allowInput}
          label="Unbekannt"
          value={BooleanValue.Unknown}
        />
      )}
    </RadioGroup>
  );
}

export { BooleanField, BooleanFieldProps };
