// eslint-disable-next-line no-restricted-imports
import { ModalFooter as ReactstrapModalFooter } from "reactstrap";
import type { ModalFooterProps as ReactstrapModalFooterProps } from "reactstrap";

type ModalFooterProps = ReactstrapModalFooterProps;

function ModalFooter(props: ReactstrapModalFooterProps) {
  return <ReactstrapModalFooter {...props} />;
}

export { ModalFooter, ModalFooterProps };
