import classnames from "classnames";
import { useState } from "react";
import { useConfiguratorComponents } from "../../hooks/useConfiguratorComponents";
import { ObjectName, Product } from "../../utils/enums";
import { SquareButton } from "../Buttons/SquareButton/SquareButton";
import { ViewMode } from "../StructureView/StructureView";
import { VariantObjectWizard } from "../VariantObjectWizard/VariantObjectWizard";
import { ComponentTable } from "./ComponentList/ComponentTable";

import "./ComponentListContainer.scss";

const BUTTON_IDS = {
  [ObjectName.Generator]: "create-generator-button",
  [ObjectName.Consumer]: "create-consumer-button",
  [ObjectName.Storage]: "create-storage-button",
  [ObjectName.Person]: "create-person-button",
  [ObjectName.Connection]: "create-connection-button",
  [ObjectName.GasConnection]: "create-gasconnection-button",
  [ObjectName.Meter]: "create-meter-button",
  [ObjectName.GhostNode]: "create-ghost-node-button"
};

interface ComponentListContainerProps {
  objectName: ObjectName;
  objectDisplayName: string;
  siteId: number;
  variantId: number;
  product: Product;
  graphCoordinates: { x: number; y: number };
  userHasAccess: boolean;
  setShowNotAccessibleModal: (show: boolean) => void;
  onChangeViewMode: (viewMode: ViewMode) => void;
}

function ComponentListContainer({
  objectName,
  objectDisplayName,
  siteId,
  variantId,
  product,
  graphCoordinates,
  userHasAccess,
  onChangeViewMode,
  setShowNotAccessibleModal
}: ComponentListContainerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const siteOrVariantId = objectName === ObjectName.Person ? variantId : siteId;
  const { data: componentData } = useConfiguratorComponents(
    objectName,
    siteOrVariantId
  );

  const noComponentData = !!componentData && componentData.length === 0;
  const showButton = objectName !== ObjectName.GasConnection || noComponentData;

  function handleToggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div className="ComponentListContainer">
      <ComponentTable
        objectName={objectName}
        setShowNotAccessibleModal={setShowNotAccessibleModal}
        siteId={siteId}
        userHasAccess={userHasAccess}
        variantId={variantId}
      />
      <ComponentListContainerFooter
        objectName={objectName}
        product={product}
        onChangeViewMode={onChangeViewMode}
      >
        {showButton && (
          <SquareButton
            block
            className={classnames("m-btn--custom", {
              "m-btn--disabled": !userHasAccess
            })}
            color="brand"
            id={BUTTON_IDS[objectName]}
            onClick={() => {
              if (!userHasAccess) {
                setShowNotAccessibleModal(true);
              } else {
                handleToggleModal();
              }
            }}
          >
            {objectDisplayName} erstellen
          </SquareButton>
        )}
      </ComponentListContainerFooter>
      <VariantObjectWizard
        graphCoordinates={graphCoordinates}
        isOpen={isModalOpen}
        mode="create"
        objectDisplayName={objectDisplayName}
        objectName={objectName}
        product={product}
        siteId={siteId}
        variantId={variantId}
        onToggle={handleToggleModal}
      />
    </div>
  );
}

interface ComponentListContainerFooterProps {
  objectName: ObjectName;
  product: Product;
  onChangeViewMode: (viewMode: ViewMode) => void;
  children: React.ReactNode;
}

function ComponentListContainerFooter({
  objectName,
  product,
  onChangeViewMode,
  children
}: ComponentListContainerFooterProps) {
  return (
    <div className="component-list-container-footer">
      <div className="footer-button">{children}</div>
      <div className="footer-button">
        {objectName === ObjectName.Generator &&
          product === Product.Analyzer &&
          onChangeViewMode && (
            <SquareButton
              block
              className="m-btn--custom"
              color="secondary"
              id="simulate-solar-button"
              onClick={() => onChangeViewMode(ViewMode.PvSimulationForm)}
            >
              PV Anlage simulieren
            </SquareButton>
          )}
      </div>
    </div>
  );
}

export { ComponentListContainer };
