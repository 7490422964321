import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "../../../../BuildingBlocks/Dropdown/Dropdown";
import type { EdaFilter } from "../EnergyDataAcquisitionSelectionModal";
import { DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS } from "../EnergyDataAcquisitionSelectionModal";

declare const SITE_NAME: string;

interface DefinedFilterDropdownProps {
  definedFilters: Array<EdaFilter>;
  definedFilterId: number | null;
  savingDefinedFilters: boolean;
  onChange: (filterId: number) => void;
}

function DefinedFilterDropdown({
  definedFilters,
  definedFilterId,
  savingDefinedFilters,
  onChange
}: DefinedFilterDropdownProps) {
  function getFilterName(
    filter: EdaFilter | null,
    definedFilterId?: number | null
  ) {
    let name: string;

    if (filter) {
      name = filter.filterName;
    } else {
      if (definedFilterId === DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS) {
        name = "Neuen Filter erstellen";
      } else {
        name = "Definierten Filter auswählen";
      }
    }

    return name;
  }

  const selectedFilter = definedFilters.find(
    (filter) => filter.id === definedFilterId
  );
  const groupedDefinedFilters = definedFilters.reduce<{
    allProjects: Array<EdaFilter>;
    userDefined: Array<EdaFilter>;
  }>(
    (accumulator, definedFilter) => {
      if (definedFilter.availableInAllProjects) {
        accumulator.allProjects.push(definedFilter);
      } else {
        accumulator.userDefined.push(definedFilter);
      }

      return accumulator;
    },
    {
      allProjects: [],
      userDefined: []
    }
  );

  return (
    <div className="defined-filter-dropdown">
      <UncontrolledDropdown disabled={savingDefinedFilters}>
        <DropdownToggle caret>
          {getFilterName(selectedFilter ?? null, definedFilterId)}
        </DropdownToggle>
        <DropdownMenu className="scrollable-dropdown">
          <DropdownItem
            onClick={() => onChange(DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS)}
          >
            {getFilterName(null, DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS)}
          </DropdownItem>
          {groupedDefinedFilters.allProjects.length > 0 && (
            <DropdownItem header>{SITE_NAME}</DropdownItem>
          )}
          {groupedDefinedFilters.allProjects.map((definedFilter) => (
            <DropdownItem
              key={definedFilter.id}
              onClick={() => onChange(definedFilter.id)}
            >
              {getFilterName(definedFilter)}
            </DropdownItem>
          ))}
          {groupedDefinedFilters.userDefined.length > 0 && (
            <DropdownItem header>Nutzerdefinierte</DropdownItem>
          )}
          {groupedDefinedFilters.userDefined.map((definedFilter) => (
            <DropdownItem
              key={definedFilter.id}
              onClick={() => onChange(definedFilter.id)}
            >
              {getFilterName(definedFilter)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export { DefinedFilterDropdown };
