import { useState } from "react";

import { Input } from "../../../DynamicForm/FormItems/FormField/Input/Input";

interface NumberValueCellProps {
  value: number | null;
  decimalScale?: number;
  inputGroupText?: string;
  onChange: (value: number) => void;
}

function NumberValueCell({
  value,
  decimalScale,
  inputGroupText,
  onChange
}: NumberValueCellProps) {
  const [localValue, setLocalValue] = useState(value);

  function handleChange(newValue: number) {
    setLocalValue(newValue);
  }

  function handleBlurInput() {
    if (localValue !== null) {
      onChange(localValue);
    }
  }

  return (
    <Input
      className="number-value-cell"
      decimalScale={decimalScale}
      inputGroupText={inputGroupText}
      type="number"
      value={localValue}
      onBlur={handleBlurInput}
      onChange={handleChange}
      onKeyPressEnter={handleBlurInput}
    />
  );
}

export { NumberValueCell };
