import type {
  Choice,
  ChoiceValue
} from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

type ContractSelectorProps = {
  onChange: (e, d) => void;
  contractChoices: Choice<ChoiceValue>[];
  selectedContractId?: ChoiceValue;
};

export function ContractSelector({
  onChange,
  contractChoices,
  selectedContractId
}: ContractSelectorProps) {
  return (
    <div className="ContractSelector">
      <h6>Vertrag</h6>
      <TsDropdown
        choices={contractChoices}
        defaultValue={selectedContractId}
        id="contract-selector"
        name="contract-selector"
        placeholder="Vertrag Auswählen"
        required
        onChange={onChange}
      />
    </div>
  );
}
