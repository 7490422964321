import { Group } from "@mantine/core";
import { ButtonGroup } from "reactstrap";
import { Button } from "../Buttons/Button/Button";
import { CreateProjectButton } from "./CreateProjectButton/CreateProjectButton";

interface SiteStatusControlsProps {
  showDemoProjects: boolean;
  showDemoProjectsToggle: boolean;
  showCreateProjectButton: boolean;
  onToggleDemoProjects: (show: boolean) => void;
  onCreateProject: (projectId: string) => void;
}

function SiteStatusControls({
  showDemoProjects,
  showDemoProjectsToggle,
  showCreateProjectButton,
  onToggleDemoProjects,
  onCreateProject
}: SiteStatusControlsProps) {
  return (
    <Group className="SiteStatusControls" gap="md">
      {showDemoProjectsToggle && (
        <ButtonGroup className="project-mode">
          <Button
            active={!showDemoProjects}
            color="brand"
            outline
            onClick={() => onToggleDemoProjects(false)}
          >
            Kunden Projekte
          </Button>
          <Button
            active={showDemoProjects}
            color="brand"
            outline
            onClick={() => onToggleDemoProjects(true)}
          >
            Demo Projekte
          </Button>
        </ButtonGroup>
      )}
      {showCreateProjectButton && (
        <CreateProjectButton onCreateProject={onCreateProject} />
      )}
    </Group>
  );
}

export { SiteStatusControls };
