import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../../../api";
import urls from "../../../../urls";
import type { Project } from "../../../../utils/backend-types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { PROJECT_SETTINGS_FORM_FIELD_DATA } from "./ProjectSettingsForm.constants";

type ProjectSettingsFormProps = {
  project: Project;
  showStaffView: boolean;
  sapExportInputEnabled: boolean;
  onSubmit: (project: Project) => void;
};

function ProjectSettingsForm({
  project,
  showStaffView,
  sapExportInputEnabled,
  onSubmit
}: ProjectSettingsFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch
  } = useForm<Project>({
    defaultValues: project
  });
  const formFieldData = PROJECT_SETTINGS_FORM_FIELD_DATA;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onValidSubmit: SubmitHandler<Project> = async (data) => {
    try {
      const formData = Object.keys(data).reduce((acc, key) => {
        if (key in formFieldData) {
          acc[key] = data[key];
        }
        return acc;
      }, {});
      const url = urls.api.projectsDetail(project.id);
      const response = await api.patch(url, formData);
      onSubmit(response.data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<Project>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  async function handleClickSubmit() {
    setIsSubmitting(true);

    try {
      await submitCallback();
    } finally {
      setIsSubmitting(false);
    }
  }

  const staffOnlyFields = showStaffView ? (
    <>
      <FormFieldController
        control={control}
        data={formFieldData.customer}
        error={errors.customer}
      />
      <FormFieldController
        control={control}
        data={formFieldData.active}
        error={errors.active}
      />
      <FormFieldController
        control={control}
        data={formFieldData.parkabrechnungActive}
        error={errors.parkabrechnungActive}
      />
      <FormFieldController
        control={control}
        data={formFieldData.optichargeActive}
        error={errors.optichargeActive}
      />
      <FormFieldController
        control={control}
        data={{
          ...formFieldData.sapExportActive,
          allowInput: sapExportInputEnabled
        }}
        error={errors.sapExportActive}
      />
      <FormFieldController
        control={control}
        data={formFieldData.documentsInOnboardingAssistantActive}
        error={errors.documentsInOnboardingAssistantActive}
      />
      <FormFieldController
        control={control}
        data={formFieldData.customerMbkWriteAccessActive}
        error={errors.customerMbkWriteAccessActive}
      />
    </>
  ) : (
    <></>
  );

  return (
    <form className="ProjectSetttingsForm" onSubmit={submitCallback}>
      <FormFieldController
        control={control}
        data={formFieldData.name}
        error={errors.name}
      />
      <FormFieldController
        control={control}
        data={formFieldData.reminderMailsActive}
        error={errors.reminderMailsActive}
      />
      {staffOnlyFields}
      <SpinButton
        color="primary"
        spin={isSubmitting}
        onClick={handleClickSubmit}
      >
        Speichern
      </SpinButton>
    </form>
  );
}

export { ProjectSettingsForm, ProjectSettingsFormProps };
