import { DeleteIcon } from "../../../Buttons/DeleteIcon";

interface ControlsCellProps {
  canDelete: boolean;
  deleteTooltipText: string;
  onDelete: () => void;
}

function ControlsCell({
  canDelete,
  deleteTooltipText,
  onDelete
}: ControlsCellProps) {
  return (
    <DeleteIcon
      disabled={!canDelete}
      tooltipText={deleteTooltipText}
      onClick={onDelete}
    />
  );
}

export { ControlsCell };
