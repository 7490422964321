import { ActionIcon } from "@mantine/core";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";

type ChartTableSwitchState = "chart" | "table";
interface ChartTableSwitchProps {
  value: ChartTableSwitchState;
  setValue: (value: ChartTableSwitchState) => void;
}

function ChartTableSwitch({ value, setValue }: ChartTableSwitchProps) {
  return (
    <ActionIcon.Group>
      <ActionIcon
        aria-label="Charts"
        size="md"
        variant={value === "chart" ? "filled" : "default"}
        onClick={() => setValue("chart")}
      >
        <Icon name={IconName.AreaChart} />
      </ActionIcon>
      <ActionIcon
        aria-label="Tabelle"
        size="md"
        variant={value === "table" ? "filled" : "default"}
        onClick={() => setValue("table")}
      >
        <Icon name={IconName.Table} />
      </ActionIcon>
    </ActionIcon.Group>
  );
}

export { ChartTableSwitch, ChartTableSwitchState };
