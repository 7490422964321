import { useState } from "react";
import ReactDOM from "react-dom";

import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { DeliveryConstraints } from "../DeliveryConstraints/DeliveryConstraints";
import { useDeliveryConstraints } from "../DeliveryConstraints/useDeliveryConstraints";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";

interface DeliveryConstraintsWizardStepProps {
  siteId: number;
  componentId: number;
  buttonContainer?: HTMLElement;
  onDone: () => void;
}

function DeliveryConstraintsWizardStep({
  siteId,
  componentId,
  buttonContainer,
  onDone
}: DeliveryConstraintsWizardStepProps) {
  const {
    isLoading,
    error,
    deliveryConstraints,
    saveDeliveryConstraints,
    updateLocalDeliveryConstraint
  } = useDeliveryConstraints(siteId);
  const [isSaving, setIsSaving] = useState(false);

  function handleClickContinue() {
    setIsSaving(true);
    saveDeliveryConstraints()
      .then(onDone)
      .catch((error) => {
        openErrorAlertPopup(error);
        setIsSaving(false);
      });
  }

  return (
    <div className="delivery-contraints-step">
      <LoadOrError error={error} loading={isLoading}>
        {deliveryConstraints && (
          <DeliveryConstraints
            componentId={componentId}
            deliveryConstraints={deliveryConstraints}
            onChange={updateLocalDeliveryConstraint}
          />
        )}
      </LoadOrError>
      <>
        {buttonContainer &&
          ReactDOM.createPortal(
            <SpinButton
              className="continue-button"
              color="primary"
              disabled={isLoading || !!error}
              spin={isSaving}
              onClick={handleClickContinue}
            >
              Weiter
            </SpinButton>,
            buttonContainer
          )}
      </>
    </div>
  );
}

export { DeliveryConstraintsWizardStep };
