import { Paper, Title } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/toast";
import { Button } from "../../Buttons/Button/Button";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { AuthActivationSuccessModal } from "./AuthActivationSuccessModal";
import { AuthAppConfirmModal } from "./AuthAppConfirmModal";
import { AuthDeactivationConfirmModal } from "./AuthDeactivationConfirmModal";
import { AuthDeactivationModal } from "./AuthDeactivationModal";
import { AuthSelectModal } from "./AuthSelectModal";
import { EmailConfirmationModal } from "./EmailConfirmationModal";
import { activate2FaMethod } from "./hooks/activate2FaMethod";
import { confirm2FaMethod } from "./hooks/confirm2FaMethod";
import { deactivate2FaMethod } from "./hooks/deactivate2FaMethod";
import { AUTH_METHODS } from "./hooks/enum";
import { generate2FaCode } from "./hooks/generate2FaCode";
import { invalidate2FaConfigCache, use2FaConfig } from "./hooks/use2FaConfig";

function TwoFactorAuth() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    data: active2FaMethods,
    isLoading: is2FaConfigLoading,
    error: twoFaConfigError
  } = use2FaConfig();

  const [isActivationModalOpen, setActivationModalOpen] = useState(false);
  const [isDeactivateConfirmModalOpen, setDeactivateConfirmModalOpen] =
    useState(false);
  const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [method, setMethod] = useState<string>(AUTH_METHODS.EMAIL);
  const [savedMethod, setSavedMethod] = useState("");
  const [otp, setOtp] = useState("");
  const [appUrl, setAppUrl] = useState("");
  const [backupCodes, setBackupCodes] = useState<Array<string>>([]);
  const [error, setError] = useState("");

  if (!active2FaMethods) {
    return null;
  }

  const isEmailConfirmModalOpen = savedMethod === AUTH_METHODS.EMAIL;
  const isAppConfirmModalOpen = savedMethod === AUTH_METHODS.APP;
  const isAny2FaMethodActivated = Object.keys(active2FaMethods).length > 0;
  const isBackupCodesAvailable = backupCodes?.length > 0;
  const isEmail2FaActive =
    active2FaMethods?.[0]?.name === AUTH_METHODS.EMAIL &&
    active2FaMethods?.[0]?.isPrimary;

  function toggleActivationModal() {
    setActivationModalOpen((prevState) => !prevState);
  }

  function handleDropdownChange(value: string) {
    setMethod(value);
  }

  async function handleAuthMethodSave() {
    try {
      const data = await activate2FaMethod(method);

      if (method === AUTH_METHODS.APP) {
        setAppUrl(data.details);
      }

      setActivationModalOpen(false);
      setSavedMethod(method);
    } catch (error) {
      setError(
        error.response?.data?.[Object.keys(error.response?.data)?.[0]] || ""
      );
    }
  }

  async function handleAuthMethodConfirm() {
    try {
      const data = await confirm2FaMethod(savedMethod, otp);

      invalidate2FaConfigCache(queryClient);

      showToast("success", "2-Faktor-Authentifizierung erfolgreich aktiviert");

      setBackupCodes(data?.backupCodes || []);
      setMethod(AUTH_METHODS.EMAIL);
      setOtp("");
      setSavedMethod("");
    } catch (_) {
      setError(t("errors.2FA.InvalidOrExpiredCode"));
    }
  }

  async function handleOtpResend() {
    try {
      await generate2FaCode(savedMethod || active2FaMethods?.[0]?.name || "");

      showToast(
        "success",
        "Der Code wurde erneut gesendet. Bitte überprüfen Sie Ihren E-Mail-Eingang."
      );
    } catch (_) {
      setError(t("errors.2FA.CodeSendingFailed"));
    }
  }

  function handleOtpChange(value: string) {
    setOtp(value);
  }

  function handleConfirmModalClose() {
    setSavedMethod("");
    setError("");
  }

  function toggleDeactivationConfirmModal() {
    setDeactivateConfirmModalOpen((prevState) => !prevState);
  }

  async function handleAuthDeactivationConfirm() {
    try {
      await generate2FaCode(active2FaMethods?.[0]?.name || "");

      setDeactivateConfirmModalOpen(false);
      setDeactivateModalOpen(true);
    } catch (error) {
      showToast("error", error);
    }
  }

  async function handle2FaDeactivate() {
    try {
      await deactivate2FaMethod(otp);

      invalidate2FaConfigCache(queryClient);
      setDeactivateModalOpen(false);
      setOtp("");
      setError("");
      showToast(
        "success",
        "Die Zwei-Faktor-Authentifizierung wurde deaktiviert"
      );
    } catch (error) {
      showToast("error", error);
    }
  }

  return (
    <>
      <Paper>
        <Title variant="paper-header">Zwei-Faktor-Authentifizierung</Title>
        <LoadOrError error={twoFaConfigError} loading={is2FaConfigLoading}>
          {isAny2FaMethodActivated ? (
            <>
              <p>
                Die Zwei-Faktor-Authentifizierung ist aktiviert und Ihr Konto
                ist gesichert.
              </p>
              <Button color="danger" onClick={toggleDeactivationConfirmModal}>
                Deaktivieren
              </Button>
            </>
          ) : (
            <>
              <p>
                Die 2-Faktor-Authentifizierung bietet eine zusätzliche
                Sicherheitsebene für Ihr Konto, indem sie neben Ihrem Passwort
                eine zweite Bestätigung Ihrer Identität verlangt.
              </p>
              <p>
                Aktuell ist diese wichtige Funktion für Ihr Konto deaktiviert.
                Um Ihr Konto besser zu schützen, empfehlen wir Ihnen, die
                2-Faktor-Authentifizierung einzurichten
              </p>

              <Button color="primary" onClick={toggleActivationModal}>
                Aktivieren
              </Button>
            </>
          )}
        </LoadOrError>
      </Paper>

      <AuthSelectModal
        isOpen={isActivationModalOpen}
        onAuthMethodSave={handleAuthMethodSave}
        onDropdownChange={handleDropdownChange}
        onToggle={toggleActivationModal}
      />

      <EmailConfirmationModal
        error={error}
        isOpen={isEmailConfirmModalOpen}
        otp={otp}
        onAuthMethodConfirm={handleAuthMethodConfirm}
        onOtpChange={handleOtpChange}
        onOtpResend={handleOtpResend}
        onToggle={handleConfirmModalClose}
      />

      <AuthAppConfirmModal
        appUrl={appUrl}
        error={error}
        isOpen={isAppConfirmModalOpen}
        otp={otp}
        onAuthMethodConfirm={handleAuthMethodConfirm}
        onOtpChange={handleOtpChange}
        onToggle={handleConfirmModalClose}
      />

      <AuthDeactivationConfirmModal
        isOpen={isDeactivateConfirmModalOpen}
        onAuthDeactivationConfirm={handleAuthDeactivationConfirm}
        onToggle={toggleDeactivationConfirmModal}
      />

      <AuthDeactivationModal
        isEmail2FaActive={isEmail2FaActive}
        isOpen={isDeactivateModalOpen}
        otp={otp}
        onAuthDeactivation={handle2FaDeactivate}
        onOtpChange={handleOtpChange}
        onOtpResend={handleOtpResend}
        onToggle={() => setDeactivateModalOpen(false)}
      />

      <AuthActivationSuccessModal
        backupCodes={backupCodes}
        isOpen={isBackupCodesAvailable}
        onToggle={() => setBackupCodes([])}
      />
    </>
  );
}

export { TwoFactorAuth };
