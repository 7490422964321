import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { MantineYesNoRadioGroup } from "../../../../../../mantine/mrt/components/cell/MantineYesNoRadioGroup";
import { ControlledMantineEditYesNoRadioGroup } from "../../../../../../mantine/mrt/components/edit/ControlledMantineEditYesNoRadioGroup";

export function getBillingAddressIsSameAsAddressColumn<T extends MRT_RowData>(
  hasCellError: (rowIndex: number, accessorKey: string) => boolean
): MRT_ColumnDef<T> {
  return {
    accessorKey: "billingAddressIsSameAsAddress",
    header: "Adresse entspricht der Rechnungsadresse",
    Edit: ({ row, cell, column }) => {
      return (
        <ControlledMantineEditYesNoRadioGroup
          allowInput
          cell={cell}
          columnId={column.id}
          row={row}
        />
      );
    },
    Cell: ({ row }) => (
      <MantineYesNoRadioGroup
        allowInput={false}
        checked={row.original.billingAddressIsSameAsAddress}
        errorText={
          hasCellError(row.index, "isParticipatingInMieterstrom")
            ? "Bitte wählen Sie eine Option aus."
            : undefined
        }
      />
    )
  };
}
