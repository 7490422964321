import { ReactRenderer } from "@tiptap/react";
import type { SuggestionOptions } from "@tiptap/suggestion";
import {
  SuggestionPopup,
  type SuggestionPopupHandle
} from "../SuggestionPopup";

/**
 * Returns configuration for the "suggestion" part of the Mention extension for the RTE,
 * including how to render the popup & handle interaction, as well as how to filter the suggestions.
 */
export function getSuggestionConfig(
  variables: Array<string>
): Pick<SuggestionOptions<string>, "allowedPrefixes" | "items" | "render"> {
  return {
    allowedPrefixes: null,
    items: ({ query }) => {
      return variables.filter((item) =>
        item.toLowerCase().startsWith(query.toLowerCase())
      );
    },
    render: () => {
      let popupComponent: ReactRenderer<SuggestionPopupHandle>;

      return {
        onStart: (props) => {
          const clientRect = props.clientRect?.();
          if (!clientRect) {
            return;
          }

          popupComponent = new ReactRenderer(SuggestionPopup, {
            props: {
              ...props,
              posX: clientRect.right,
              posY: clientRect.bottom + window.scrollY
            },
            editor: props.editor
          });

          document.body.appendChild(popupComponent.element);
        },

        onUpdate(props) {
          const clientRect = props.clientRect?.();
          if (!clientRect) {
            return;
          }

          popupComponent.updateProps({
            ...props,
            posX: clientRect.right,
            posY: clientRect.bottom + window.scrollY
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            document.body.removeChild(popupComponent.element);
            return true;
          }

          return popupComponent.ref?.onKeyDown(props.event) ?? false;
        },

        onExit() {
          popupComponent.destroy();
        }
      };
    }
  };
}
