import classNames from "classnames";
import { Input } from "reactstrap";

import "./ToggleSwitch.scss";

interface ToggleSwitchProps {
  checked?: boolean;
  className?: string;
  text?: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function ToggleSwitch({
  checked,
  className,
  text,
  disabled,
  onChange,
  onClick
}: ToggleSwitchProps) {
  return (
    <div className={classNames("ToggleSwitch", className)} onClick={onClick}>
      <span className="m-switch m-switch--sm m-nav__link-text">
        <label style={{ marginBottom: 0 }}>
          <Input
            checked={checked}
            disabled={disabled}
            type="checkbox"
            onChange={onChange}
          />
          <span />
          {text}
        </label>
      </span>
    </div>
  );
}

export { ToggleSwitch, ToggleSwitchProps };
