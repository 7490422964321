import { Link } from "react-router-dom";
import type { Column } from "react-table";
import { useAnlagenStromstgGenerators } from "../../../../../../hooks/useAnlagenStromstgGenerators";
import type { AnlageStromstgListData } from "../../../../../../utils/backend-types";
import { DecimalLabel } from "../../../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { Button } from "../../../../../Buttons/Button/Button";
import { CustomReactTable } from "../../../../../CustomReactTable/CustomReactTable";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import "./AnlageStromstgList.scss";

interface AnlageStromstgListProps {
  list: Array<AnlageStromstgListData>;
}

function AnlageStromstgList({ list }: AnlageStromstgListProps) {
  const totalBruttoLeistung = list.reduce(
    (total, generator) => total + (generator.bruttoleistung ?? 0),
    0
  );

  const tableColumns: Array<Column<AnlageStromstgListData>> = [
    {
      Header: "Bezeichnung",
      accessor: "name",
      Footer:
        list.length > 0 ? <span>Leistung stromsteuerliche Anlage</span> : null
    },
    {
      Header: "Liegenschaft",
      accessor: "site"
    },
    {
      Header: "Bruttoleistung",
      accessor: "bruttoleistung",
      Cell: BruttoleistungCell,
      Footer:
        list.length > 0 ? (
          <span>
            <DecimalLabel value={totalBruttoLeistung} />
          </span>
        ) : null
    }
  ];

  return (
    <div className="AnlageStromstgList">
      <h6 className="section-header">Zugeordnete Einheiten:</h6>
      <div className="controls">
        <Button color="brand" tag={Link} to="anlage-stromstg-aendern">
          Zuordnung der Einheit ändern
        </Button>
      </div>
      <CustomReactTable
        columns={tableColumns}
        data={list}
        minRows={0}
        NoDataComponent={() => <NoDataComponent />}
        pageSize={list.length}
      />
    </div>
  );
}

function BruttoleistungCell({ value }: { value: number }) {
  if (value === null) {
    return <span>Nicht eingetragen</span>;
  }

  return <DecimalLabel value={value} />;
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Einheiten vorhanden.</p>
    </div>
  );
}

function AnlageStromstgListDataWrapper({ id }: { id: number }) {
  const { anlagenStromstgGenerators, error, isLoading } =
    useAnlagenStromstgGenerators(id);

  return (
    <LoadOrError error={error} loading={isLoading}>
      {anlagenStromstgGenerators && (
        <AnlageStromstgList list={anlagenStromstgGenerators} />
      )}
    </LoadOrError>
  );
}

export {
  AnlageStromstgListDataWrapper as AnlageStromstgList,
  AnlageStromstgList as AnlageStromstgListNoLoader,
  AnlageStromstgListProps as AnlageStromstgListNoLoaderProps
};
