import { IconHelpText } from "../../../../IconHelpText/IconHelpText";
import { OrangeCircle } from "../../../../OrangeCircle/OrangeCircle";
import "./FormFieldLabel.scss";

interface FormFieldLabelProps {
  formFieldId?: string;
  label?: string;
  required?: boolean;
  helpText?: string;
  highlight?: boolean;
  renderAs?: "label" | "div";
}

function FormFieldLabel({
  formFieldId,
  label,
  required,
  helpText,
  highlight,
  renderAs = "label"
}: FormFieldLabelProps) {
  if (!label) {
    return null;
  }

  return (
    <div className="FormFieldLabel">
      <div className="label-title-container">
        {highlight && <OrangeCircle />}
        {renderAs === "div" && (
          <div className="label-title">
            {label}
            {required && "*"}
          </div>
        )}
        {renderAs === "label" && (
          <label className="label-title" htmlFor={formFieldId}>
            {label}
            {required && "*"}
          </label>
        )}
      </div>
      {helpText && <IconHelpText helpText={helpText} />}
    </div>
  );
}

export { FormFieldLabel, FormFieldLabelProps };
