import { Radio } from "@mantine/core";
import { RadioGroup } from "../../../../components/BuildingBlocks/Forms/RadioGroup/RadioGroup";

export interface MantineYesNoRadioGroupProps {
  onChange?: (value: boolean | null) => void;
  checked?: boolean | null;
  allowInput?: boolean;
  errorText?: string;
}
export enum BooleanValue {
  True = "true",
  False = "false",
  Unknown = ""
}

function MantineYesNoRadioGroup({
  onChange,
  checked,
  allowInput,
  errorText
}: MantineYesNoRadioGroupProps) {
  return (
    <div className="MantineYesNoRadioGroup">
      <RadioGroup
        errorText={errorText}
        layout="horizontal"
        value={
          checked
            ? BooleanValue.True
            : checked === false
              ? BooleanValue.False
              : BooleanValue.Unknown
        }
        onChange={(val: string) =>
          onChange &&
          onChange(
            val === BooleanValue.True
              ? true
              : val === BooleanValue.False
                ? false
                : null
          )
        }
      >
        <Radio
          aria-disabled={!allowInput}
          color={!allowInput ? "lightgrey" : ""}
          label="Ja"
          value={BooleanValue.True}
        />
        <Radio
          aria-disabled={!allowInput}
          color={!allowInput ? "lightgrey" : ""}
          label="Nein"
          value={BooleanValue.False}
        />
      </RadioGroup>
    </div>
  );
}

export { MantineYesNoRadioGroup };
