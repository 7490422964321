import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useVariantDetailMutations } from "../../../../hooks/useVariantDetailMutations";
import { showBannerToast, showToast } from "../../../../utils/toast";
import { ToggleSwitch } from "../../../BuildingBlocks/Forms/ToggleSwitch/ToggleSwitch";
export interface UsePaymentInformationToggleSwitchProps {
  variantId: number;
  usePaymentInformation: boolean;
}

function UsePaymentInformationToggleSwitch({
  variantId,
  usePaymentInformation
}: UsePaymentInformationToggleSwitchProps) {
  const queryClient = useQueryClient();

  const { editMutation } = useVariantDetailMutations(variantId);

  const navigate = useNavigate();

  async function handleChangeUsePaymentInformationToggleSwitch(
    isChecked: boolean
  ) {
    const result = await editMutation
      .mutateAsync({
        paragraph_6_payment_information_instead_of_credit: isChecked
      })
      .catch((error) => {
        showToast("error", error);
      });

    queryClient.invalidateQueries({
      queryKey: ["paragraph6Credits", { variantId }]
    });

    if (
      result?.data?.paragraph_6_payment_information_instead_of_credit === true
    ) {
      showBannerToast("success", {
        headline: "Sie erstellen künftig Zahlungsinformationen!",
        text: "Mit dieser Einstellung werden Gutschriften durch Zahlungsinformationen ersetzt. Es werden keine Gutschriftennummernkreise eingebunden und es können keine Stornos zu Zahlungsinformationen angelegt werden."
      });
      navigate("../zahlungsinformationen");
    } else if (
      result?.data?.paragraph_6_payment_information_instead_of_credit === false
    ) {
      showBannerToast("success", {
        headline: "Sie erstellen künftig Gutschriften!",
        text: "Mit dieser Einstellung werden Zahlungsinformationen durch Gutschriften ersetzt. Es werden Gutschriftennummernkreise eingebunden und es können Stornos zu Gutschriften angelegt werden."
      });
      navigate("../gutschriften");
    }
  }

  return (
    <ToggleSwitch
      checked={usePaymentInformation}
      disabled={editMutation.isPending}
      text="Die Gutschriftserstellung soll durch die Verwendung von Zahlungsinformation ersetzt werden."
      onChange={(e) =>
        handleChangeUsePaymentInformationToggleSwitch(e.target.checked)
      }
    />
  );
}

export { UsePaymentInformationToggleSwitch };
