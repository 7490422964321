export interface ExpressionEditorContentProps {
  value: string | null | undefined;
  variables: Array<string>;
}

function VariableMention({ variableName }: { variableName: string }) {
  return (
    <span
      className="expression-variable"
      data-id={variableName}
      data-type="mention"
    >
      {variableName}
    </span>
  );
}

/** this is what you see in displaymode
 * if both should be the same, remember to update both
 */
function ExpressionEditorPreview({
  value,
  variables
}: ExpressionEditorContentProps) {
  if (!value) {
    return <></>;
  }

  const parts = variables.reduce(
    (acc, variable) => {
      return acc.flatMap((part) =>
        typeof part === "string" ? splitAndWrap(part, variable) : part
      );
    },
    [value]
  );

  return <>{parts}</>;
}

function splitAndWrap(text: string, variable: string) {
  return text.split(new RegExp(`(${variable})`, "g")).map((part, i) => {
    if (part === variable) {
      return (
        <VariableMention key={`${variable}-${i}`} variableName={variable} />
      );
    } else {
      return part;
    }
  });
}

export { ExpressionEditorPreview };
