import { Highlight } from "@mantine/core";
import type { Column } from "react-table";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type { Paragraph6FinancialDocument } from "../../../Paragraph6.types";
import { FinancialDocumentType } from "../../../Paragraph6.types";

function getCreditNumberColumn(highlightText = "") {
  return {
    Header: "Erstellt aus",
    accessor: (row) => (
      <Highlight highlight={highlightText}>
        {String(row.credit_number)}
      </Highlight>
    ),
    id: "creditNumber",
    Cell: (cellInfo: TypedCellInfo<never, Paragraph6FinancialDocument>) => {
      if (cellInfo.original.type == FinancialDocumentType.Credit) return null;
      return <span>{cellInfo.original.credit_number}</span>;
    },
    width: 100,
    sortable: true,
    resizable: true
  } satisfies Column<Paragraph6FinancialDocument>;
}

export { getCreditNumberColumn };
