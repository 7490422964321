import { Checkbox } from "../../BuildingBlocks/Forms/Checkbox/Checkbox";

export interface CheckboxCellProps {
  defaultValue: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

function CheckboxCell({ defaultValue, onChange, disabled }: CheckboxCellProps) {
  return (
    <Checkbox checked={defaultValue} disabled={disabled} onChange={onChange} />
  );
}

export { CheckboxCell };
