import { DateTime } from "luxon";
import { createContext, type ReactNode, useEffect, useState } from "react";
import type { Site } from "../../utils/backend-types";

export type ParkabrechnungContextDefaultValues = {
  selectedStartDate?: DateTime;
  selectedEndDate?: DateTime;
  selectedSite?: Site;
  selectedCompany?: number;
  ctPerKwh?: number;
  drittlieferungPrices?: DrittlieferungPrice[];
  isStromsteuer?: boolean;
  isStromlieferung?: boolean;
  poolContractId?: string;
};

type ParkabrechnungContextType = ParkabrechnungContextDefaultValues & {
  setSelectedStartDate: (date: DateTime) => void;
  setSelectedEndDate: (date: DateTime) => void;
  setSelectedSite: (site?: Site) => void;
  setSelectedCompany: (companyId: number | undefined) => void;
  setCtPerKwh: (ctPerKwh: number) => void;
  setDrittlieferungPrices: (prices: DrittlieferungPrice[]) => void;
  setIsStromsteuer: (isStromsteuer: boolean) => void;
  setIsStromlieferung: (isStromlieferung: boolean) => void;
  setPoolContractId: (poolContractId: string | undefined) => void;
};

export type DrittlieferungPrice = {
  supplierId?: number;
  priceCtPerKwh?: number;
  recipientIds?: number[];
  siteId: number;
};

export const ParkabrechnungContext = createContext<ParkabrechnungContextType>({
  selectedStartDate: undefined,
  selectedEndDate: undefined,
  setSelectedStartDate: () => {},
  setSelectedEndDate: () => {},
  selectedSite: undefined,
  setSelectedSite: () => {},
  selectedCompany: undefined,
  setSelectedCompany: () => {},
  ctPerKwh: undefined,
  setCtPerKwh: () => {},
  poolContractId: undefined,
  setPoolContractId: () => {},
  setDrittlieferungPrices: () => {},
  drittlieferungPrices: undefined,
  isStromsteuer: undefined,
  setIsStromsteuer: () => {},
  isStromlieferung: undefined,
  setIsStromlieferung: () => {}
});

export const ParkabrechnungProvider = ({
  children,
  defaultValues
}: {
  children: ReactNode;
  defaultValues?: ParkabrechnungContextDefaultValues;
}) => {
  const [isStromsteuer, setIsStromsteuer] = useState(
    defaultValues?.isStromsteuer ?? false
  );
  const [isStromlieferung, setIsStromlieferung] = useState(
    defaultValues?.isStromlieferung ?? false
  );

  // The default values are set to the start and end of 2022 as a temporary solution because there are no values for 2023 available yet
  const [selectedStartDate, setSelectedStartDate] = useState<DateTime>(
    defaultValues?.selectedStartDate ??
      DateTime.local().minus({ year: 2 }).startOf("year")
  );
  const [selectedEndDate, setSelectedEndDate] = useState<DateTime>(
    defaultValues?.selectedEndDate ??
      DateTime.local().minus({ year: 2 }).endOf("year")
  );
  const [selectedSite, setSelectedSite] = useState<Site | undefined>(
    defaultValues?.selectedSite
  );
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
    defaultValues?.selectedCompany
  );
  const [ctPerKwh, setCtPerKwh] = useState<number | undefined>(
    defaultValues?.ctPerKwh
  );

  const [poolContractId, setPoolContractId] = useState<string | undefined>(
    defaultValues?.poolContractId
  );

  const [drittlieferungPrices, setDrittlieferungPrices] = useState<
    DrittlieferungPrice[] | undefined
  >(defaultValues?.drittlieferungPrices);

  const value = {
    selectedStartDate,
    setSelectedStartDate: (date: DateTime) => setSelectedStartDate(date),
    selectedEndDate,
    setSelectedEndDate: (date: DateTime) => setSelectedEndDate(date),
    selectedSite,
    setSelectedSite: (site: Site) => setSelectedSite(site),
    selectedCompany,
    setSelectedCompany: (companyId: number | undefined) =>
      setSelectedCompany(companyId),
    ctPerKwh,
    setCtPerKwh: (ctPerKwh: number) => {
      setCtPerKwh(ctPerKwh);
      localStorage.setItem("ctPerKwh", ctPerKwh?.toString());
    },
    poolContractId: poolContractId,
    setPoolContractId: (poolContractId: string | undefined) => {
      setPoolContractId(poolContractId);
    },
    drittlieferungPrices,
    setDrittlieferungPrices: (prices: DrittlieferungPrice[]) =>
      setDrittlieferungPrices(prices),
    isStromsteuer,
    setIsStromsteuer: (isStromsteuer: boolean) =>
      setIsStromsteuer(isStromsteuer),
    isStromlieferung,
    setIsStromlieferung: (isStromlieferung: boolean) =>
      setIsStromlieferung(isStromlieferung)
  };

  // TODO: This is a temporary solution to persist the ctPerKwh value across page reloads. At a later time, we will save this value in the DB.
  useEffect(() => {
    if (typeof ctPerKwh === "undefined") {
      const ctPerKwh = localStorage.getItem("ctPerKwh");
      if (ctPerKwh) {
        setCtPerKwh(parseFloat(ctPerKwh));
      }
    }
  }, [ctPerKwh]);

  useEffect(() => {
    setDrittlieferungPrices(undefined);
  }, [selectedSite, selectedCompany, selectedEndDate, selectedStartDate]);

  return (
    <ParkabrechnungContext.Provider value={value}>
      {children}
    </ParkabrechnungContext.Provider>
  );
};
