import type {
  FormatToErrorObject,
  MeterTableErrors,
  SiteSetupProcessForForm
} from "../SiteSetupAssistant.types";

export function formatConsumerErrorsToMeterTableErrors(
  errors: FormatToErrorObject<SiteSetupProcessForForm["consumers"]>
): MeterTableErrors {
  if (!errors) return [];
  return errors.map((consumer) => consumer.meter || {});
}
