import { Text } from "@mantine/core";
function Paragraph6CreditsAlertsCreated() {
  return (
    <p>
      Sie haben den Status Ihrer Gutschrift auf{" "}
      <Text fs="italic" span>
        Bezahlt
      </Text>{" "}
      geändert! Wir haben Ihnen nun die Möglichkeit zur Rückerstattung im
      Bereich{" "}
      <Text fs="italic" span>
        Aufgaben
      </Text>{" "}
      freigeschaltet.
      <br />
      Haben Sie bereits alle Gutschriften für das laufende Jahr bezahlt können
      Sie direkt mit dem Rückerstattungsprozess starten.
    </p>
  );
}

function Paragraph6CreditsAlertsOpen() {
  return (
    <p>
      Sie haben eine Änderung in der Übersicht Ihrer Gutschriften vorgenommen!
      <br />
      opti.node hat dementsprechend Ihre Meldeunterlagen zur Rückerstattung
      finanzieller Beteiligung angepasst.
      <br />
      Bitte erstellen Sie im Bereich{" "}
      <Text fs="italic" span>
        Aufgaben
      </Text>{" "}
      neue Meldeunterlagen.
    </p>
  );
}

function Paragraph6CreditsAlertsNotRelevant() {
  return (
    <p>
      Sie haben eine Änderung am Status Ihrer Gutschrift vorgenommen. Dadurch
      ist die bisherige Aufgabe zur Rückerstattung finanzieller Beteiligung
      hinfällig und wurde auf{" "}
      <Text fs="italic" span>
        Nicht relevant
      </Text>{" "}
      geändert.
      <br />
      Um auf die Aufgabe dennoch zugreifen zu können, filtern Sie bitte nach{" "}
      <Text fs="italic" span>
        Nicht relevant
      </Text>
      .
    </p>
  );
}

export {
  Paragraph6CreditsAlertsCreated,
  Paragraph6CreditsAlertsOpen,
  Paragraph6CreditsAlertsNotRelevant
};
