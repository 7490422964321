import { Accordion } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { type DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { useMeteringOrMarketLocations } from "../../../../../hooks/useMeteringOrMarketLocations";
import { useMeters } from "../../../../../hooks/useMeters";
import type { Meter } from "../../../../../utils/backend-types";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { useSitePlausibility } from "../../hooks/useSitePlausibility";
import { getLocationIdFromSelectedId } from "../../utils/getLocationIdFromSelectedId";
import {
  getMeterIdFromSelectedId,
  METER_ID_PREFIX
} from "../../utils/getMeterIdFromSelectedId";
import { RawEnergyDataSiteMeters } from "./RawEnergyDataSiteMeters/RawEnergyDataSiteMeters";
import { SiteInfo } from "./SiteInfo/SiteInfo";
import { SiteTitle } from "./SiteTitle/SiteTitle";

interface RawEnergyDataSiteProps {
  selectedId?: string;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  siteId: number;
  siteName: string;
  onIdChanged: (id: string) => void;
  onSiteHasSelectedId: (siteId: number) => boolean;
}

function RawEnergyDataSite({
  selectedId,
  selectedDateRange,
  siteId,
  siteName,
  onIdChanged,
  onSiteHasSelectedId
}: RawEnergyDataSiteProps) {
  const {
    data: sitePlausibility,
    isLoading: isSitePlausibilityLoading,
    error: sitePlausibilityError
  } = useSitePlausibility(siteId, selectedDateRange);

  const {
    data: meters,
    isLoading: isMetersLoading,
    error: metersError
  } = useMeters(siteId);

  const {
    meteringOrMarketLocations: locations,
    isLoading: isLocationsLoading,
    error: locationsError
  } = useMeteringOrMarketLocations(siteId);

  const isEnergyDataLoading = isMetersLoading || isLocationsLoading;
  const energyDataError = metersError || locationsError;

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>();

  useEffect(() => {
    const selectedMeterId = getMeterIdFromSelectedId(selectedId);
    const selectedLocationId = getLocationIdFromSelectedId(selectedId);

    const siteContainsSelectedId =
      !!meters?.find((m) => m.id === selectedMeterId) ||
      !!locations?.find((l) => l.id === selectedLocationId);
    if (siteContainsSelectedId) {
      const accordionPanelWasChanged = onSiteHasSelectedId(siteId);
      if (accordionPanelWasChanged) scrollIntoView();
    }
  }, [
    locations,
    meters,
    onSiteHasSelectedId,
    scrollIntoView,
    selectedId,
    siteId
  ]);

  const { unplausibleMeters, unknownMeters } = useMemo(() => {
    const defaultMeters = {
      unplausibleMeters: [] as Array<Meter>,
      unknownMeters: [] as Array<Meter>
    };

    if (!sitePlausibility || !meters) {
      return defaultMeters;
    }

    const unplausibleAndUnknownMeters = sitePlausibility.reduce(
      (unplausibleAndUnknownMeters, meterPlausibility) => {
        if (meterPlausibility.plausibility === false) {
          const meter = meters.find(
            (meter) => meter.id === meterPlausibility.meter_id
          );

          if (meter) {
            unplausibleAndUnknownMeters.unplausibleMeters.push(meter);
          }
        } else if (meterPlausibility.plausibility === null) {
          const meter = meters.find(
            (meter) => meter.id === meterPlausibility.meter_id
          );

          if (meter) {
            unplausibleAndUnknownMeters.unknownMeters.push(meter);
          }
        }

        return unplausibleAndUnknownMeters;
      },
      defaultMeters
    );

    return unplausibleAndUnknownMeters;
  }, [sitePlausibility, meters]);

  function handleClickMeter(meterId: number) {
    onIdChanged(`${METER_ID_PREFIX}${meterId}`);
  }

  const showSiteInfo =
    !meters || meters.length === 0 || unknownMeters.length !== meters.length;

  return (
    <Accordion.Item ref={targetRef} value={siteId.toString()}>
      <Accordion.Control>
        <SiteTitle
          loading={isSitePlausibilityLoading}
          loadingFailed={!!sitePlausibilityError}
          showPlausibility={showSiteInfo}
          siteName={siteName}
          sitePlausibility={sitePlausibility}
        />
      </Accordion.Control>
      <Accordion.Panel>
        {showSiteInfo && (
          <SiteInfo
            loading={isSitePlausibilityLoading || isEnergyDataLoading}
            loadingFailed={!!sitePlausibilityError}
            noMeters={!!meters && meters.length === 0}
            unplausibleMeters={unplausibleMeters}
            onClickMeter={handleClickMeter}
          />
        )}
        <LoadOrError error={energyDataError} loading={isEnergyDataLoading}>
          {((meters && meters.length > 0) ||
            (locations && locations.length > 0)) && (
            <RawEnergyDataSiteMeters
              locations={locations}
              meterPlausibilities={sitePlausibility}
              meters={meters}
              selectedDateRange={selectedDateRange}
              selectedId={selectedId}
              onSetSelectedId={onIdChanged}
            />
          )}
        </LoadOrError>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export { RawEnergyDataSite };
