import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import "./MiniTile.scss";

interface MiniTileItem {
  label: string;
  value: React.ReactNode;
}

interface MiniTileProps {
  title: string | React.ReactNode;
  subTitle?: string;
  iconName: IconName;
  items: Array<MiniTileItem>;
  isLoading?: boolean;
}

function MiniTile({ title, iconName, items, isLoading }: MiniTileProps) {
  return (
    <Paper className="MiniTile">
      <Stack h="100%" justify="space-between">
        <Group
          className="mini-tile-header"
          gap="xs"
          justify="space-between"
          wrap="nowrap"
        >
          <Title fw={500} order={6} size="h4">
            {title}
          </Title>
          <Icon className="Icon" name={iconName} />
        </Group>
        <LoadOrError loading={isLoading}>
          <Group gap="sm" wrap="nowrap">
            {items.map((item) => (
              <Stack gap="xs" key={item.label}>
                <Text span>{item.label}</Text>
                <Text fw={500} fz="h4" span style={{ whiteSpace: "nowrap" }}>
                  {item.value}
                </Text>
              </Stack>
            ))}
          </Group>
        </LoadOrError>
      </Stack>
    </Paper>
  );
}

export { MiniTile, MiniTileProps, MiniTileItem };
