import { List } from "@mantine/core";
import { Link } from "react-router-dom";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function MasterErrors({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const masterErrors = paragraph6CreditErrors.some(
    (error) => error.master_data_check_failed
  );

  if (!masterErrors) {
    return null;
  }

  return (
    <List.Item>
      <Link to="../../unternehmen">notwendige Unternehmensdaten</Link>
    </List.Item>
  );
}

export { MasterErrors };
