import type { ReactNode } from "react";
import type { ToastContentProps } from "react-toastify";
import { Button } from "../Buttons/Button/Button";
import "./BannerToast.scss";

type BannerToastProps = {
  headline?: string;
  text?: ReactNode;
} & (
  | {
      ctaAction: () => void;
      ctaText: string;
    }
  | {
      ctaAction?: never;
      ctaText?: never;
    }
);

function BannerToast({
  headline,
  text,
  ctaAction,
  ctaText,
  closeToast
}: BannerToastProps & {
  closeToast?: ToastContentProps["closeToast"]; // injected from toast()
}) {
  return (
    <div className="BannerToast">
      {headline && <h5>{headline}</h5>}
      {text && <div className="toast-bodytext">{text}</div>}
      {ctaAction && ctaText && (
        <div className="toast-button-container">
          <Button
            color="dark"
            outline
            role="link"
            type="button"
            onClick={() => {
              ctaAction();
              closeToast?.();
            }}
          >
            {ctaText}
          </Button>
        </div>
      )}
    </div>
  );
}

export { BannerToast, BannerToastProps };
