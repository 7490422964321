import { useEffect, useState } from "react";
import { Link, Outlet, generatePath, useParams } from "react-router-dom";
import { useProjects } from "../../../hooks/useProjects";
import { ROUTES } from "../../../routes";
import urls from "../../../urls";
import type { ProjectMinimal } from "../../../utils/backend-types";
import { doesProjectHaveManagerVariant } from "../../../utils/doesProjectHaveManagerVariant";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { filterAnalyzerProjects } from "../../ProjectManager/utils/filterAnalyzerProjects";
import { DownIcon } from "../icons/DownIcon";
import { SeparatorIcon } from "../icons/SeparatorIcon";
import { MenuItem } from "../MenuItem/MenuItem";
import { MenuLink } from "../MenuLink/MenuLink";
import { filterManagerProjects } from "../utils/filterManagerProjects";
import "./ProjectListBreadcrumb.scss";

interface ProjectListBreadcrumbProps {
  listPath: string;
  projectPath: string;
  isManagerPage?: boolean;
}

function ProjectListBreadcrumb({
  listPath,
  projectPath,
  isManagerPage
}: ProjectListBreadcrumbProps) {
  const { projectId } = useParams();
  const { data: projects } = useProjects({
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const [project, setProject] = useState<ProjectMinimal>();
  const projectUrl = generatePath(projectPath, { projectId });

  useEffect(() => {
    if (projects) {
      const project = projects.find((project) => project.id === projectId);
      setProject(project);
    }
  }, [projects, projectId, setProject]);

  function filterProjectsByIsManagerPage(
    projects: Array<ProjectMinimal>,
    isManagerPage?: boolean
  ) {
    return isManagerPage
      ? filterManagerProjects(projects)
      : filterAnalyzerProjects(projects);
  }

  const filteredProjects = projects
    ? filterProjectsByIsManagerPage(projects, isManagerPage)
    : undefined;

  return (
    <>
      <MenuItem rel>{<MenuLink text="Dashboard" to={listPath} />}</MenuItem>
      <SeparatorIcon />
      {project && filteredProjects && (
        <>
          <MenuItem>
            <MenuLink text={project.name} to={projectUrl} />
          </MenuItem>
          {filteredProjects.length > 1 && (
            <MenuItem className="menu-item-variant-sub-menu">
              <ProjectSubMenu
                currentProjectId={project.id}
                isManagerPage={isManagerPage}
                projects={filteredProjects}
              />
            </MenuItem>
          )}
        </>
      )}
      <SeparatorIcon />
      <Outlet context={[project, setProject]} />
    </>
  );
}

interface ProjectSubMenuProps {
  projects: Array<ProjectMinimal>;
  currentProjectId: string;
  isManagerPage?: boolean;
}

function ProjectSubMenu({
  projects,
  currentProjectId,
  isManagerPage
}: ProjectSubMenuProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  let filteredProjects = projects;

  if (isManagerPage) {
    filteredProjects = filteredProjects.filter((project) =>
      doesProjectHaveManagerVariant(project)
    );
  }

  return (
    <Dropdown
      className="ProjectSubMenu"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle aria-expanded={dropdownOpen} tag="span">
        <DownIcon />
      </DropdownToggle>
      <DropdownMenu>
        {filteredProjects.map((project) => {
          if (project.id !== currentProjectId) {
            const path = isManagerPage
              ? generatePath(ROUTES.managerProjectView, {
                  projectId: project.id
                })
              : urls.projectList(project.id);
            return (
              <DropdownItem key={project.id} tag={Link} to={path}>
                {project.name}
              </DropdownItem>
            );
          } else {
            return null;
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export { ProjectListBreadcrumb };
