import Dropdown from ".";

const DEFAULT_ERROR_TEXTS = [];

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export type ChoiceValue =
  | string
  | number
  | boolean
  | Array<string>
  | Array<number>;

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export interface Choice<T = ChoiceValue> {
  value: T;
  displayName: string | number;
  group?: string;
  isFixed?: boolean;
}

interface TsDropdownProps {
  id?: string;
  className?: string;
  name: string;
  value?: ChoiceValue;
  defaultValue?: ChoiceValue;
  choices: Array<Choice>;
  placeholder?: string;
  multiselect?: boolean;
  required?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  invalid?: boolean;
  errorTexts?: Array<string>;
  menuWidth?: string | number;
  onChange: (domName: string, newValue: ChoiceValue) => void;
  onCreateOption?: (value: string) => void;
}

/** This Component temporarily gets around re-implementing Dropdown as a typescript component */
/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function TsDropdown({
  placeholder,
  errorTexts,
  ...otherProps
}: TsDropdownProps) {
  return (
    // @ts-expect-error we know the dropdown.js is working - better use a newer dropdown soon though
    <Dropdown
      errorTexts={errorTexts || DEFAULT_ERROR_TEXTS}
      placeholder={placeholder}
      {...otherProps}
    />
  );
}

export { TsDropdown, TsDropdownProps };
