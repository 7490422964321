import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";

import "./MetersSaveWarningModal.scss";

interface MetersSaveConfirmModalProps {
  isOpen: boolean;
  onCloseEditModal: () => void;
  onCloseSelectedMetersWarningModal: () => void;
}

function MetersSaveWarningModal({
  isOpen,
  onCloseEditModal,
  onCloseSelectedMetersWarningModal
}: MetersSaveConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} toggle={onCloseSelectedMetersWarningModal}>
      <ModalHeader
        className="MetersSaveWarningModalHeader"
        toggle={onCloseSelectedMetersWarningModal}
      >
        <Icon name={IconName.ExclamationOrange} style={{ width: "16px" }} />{" "}
        <span className="MeterSaveWarningTitle">
          Sie haben ungespeicherte Änderungen!
        </span>
      </ModalHeader>
      <ModalBody>
        <div className="MeterSaveWarningDescription">
          <p>
            Sie haben nicht gespeicherte Änderungen für Zähler, die beim
            Schließen verloren gehen würden.
          </p>
          <p>Sind Sie sicher, dass Sie das Fenster schließen wollen?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCloseSelectedMetersWarningModal}>
          Abbrechen
        </Button>
        <Button
          color="warning"
          onClick={() => {
            onCloseSelectedMetersWarningModal();
            onCloseEditModal();
          }}
        >
          Ja, schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { MetersSaveWarningModal };
