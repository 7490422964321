import { useState } from "react";
import { useSiteMutations } from "../../../hooks/useSiteMutations";
import type { Site } from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { DeleteConfirmationModal } from "../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { DeleteSiteModalBodyElement } from "./DeleteSiteModalBodyElement";

function DeleteSiteModal({
  site,
  toggleModal,
  onSuccess
}: {
  site: Site;
  toggleModal: () => void;
  onSuccess: () => void;
}) {
  const [allowDelete, setAllowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteMutation } = useSiteMutations(site.id);

  const modalBodyElement = (
    <DeleteSiteModalBodyElement
      siteName={site.name}
      onVerificationTextChange={setAllowDelete}
    />
  );

  async function deleteSite(site: Site) {
    setIsDeleting(true);
    try {
      await deleteMutation.mutateAsync(site.id);
      onSuccess();
    } catch (error) {
      showToast("error", error);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <DeleteConfirmationModal
      className="DeleteSiteModal"
      isConfirmationBtnDisabled={!allowDelete}
      isLoading={isDeleting}
      isModalOpen
      modalBodyElement={modalBodyElement}
      name={site.name}
      toggleModal={toggleModal}
      onClickConfirm={() => deleteSite(site)}
    />
  );
}

export { DeleteSiteModal };
