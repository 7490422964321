import classnames from "classnames";
interface MenuItemProps {
  children: React.ReactNode;
  rel?: boolean;
  className?: string;
}

function MenuItem({ children, rel, className }: MenuItemProps) {
  return (
    <li
      className={classnames(
        "MenuItem",
        { "m-menu__item--rel": rel },
        className
      )}
    >
      {children}
    </li>
  );
}

export { MenuItem };
