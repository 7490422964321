import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import type { ApiResponse } from "../../../../../api";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type {
  ProjectMinimal,
  VariantMinimal
} from "../../../../../utils/backend-types";
import type { SiteName } from "../../../../../utils/enums";
import { BasicConfirmationModal } from "../BasicConfirmationModal/BasicConfirmationModal";
import { DeleteConfirmationModal } from "../DeleteConfirmationModal/DeleteConfirmationModal";

declare const SITE_NAME: SiteName;

export enum Action {
  Copy = "copy",
  Delete = "delete"
}
export enum ActionObject {
  Project = "Projekt",
  Variant = "Variante"
}

type ActionConfirmationModalProps<T> = {
  action: Action;
  onActionSuccess: (data: T | null) => void;
  onClose: () => void;
  onError: (error: unknown) => void;
} & (
  | {
      object: ActionObject.Project;
      project: ProjectMinimal;
    }
  | {
      object: ActionObject.Variant;
      variant: VariantMinimal;
    }
);

function ActionConfirmationModal<T>({
  action,
  onActionSuccess,
  onClose,
  onError,
  ...objectProps
}: ActionConfirmationModalProps<T>) {
  const [disableSuccessButton, setDisableSuccessButton] = useState(
    action === Action.Delete && objectProps.object === ActionObject.Project
  );
  const [loading, setLoading] = useState(false);

  async function handleAction() {
    setLoading(true);

    let response: ApiResponse<T> | null = null;

    try {
      if (action === Action.Delete) {
        if (objectProps.object === ActionObject.Project) {
          response = await api.delete<T>(
            urls.api.projectsDetail(objectProps.project.id)
          );
        } else if (objectProps.object === ActionObject.Variant) {
          response = await api.delete<T>(
            urls.api.variantDetail(objectProps.variant.id)
          );
        }
      } else if (action === Action.Copy) {
        if (objectProps.object === ActionObject.Project) {
          response = await api.post<T>(
            urls.api.projectsCopy(objectProps.project.id)
          );
        } else if (objectProps.object === ActionObject.Variant) {
          response = await api.post<T>(
            urls.api.variantCopy(objectProps.variant.id)
          );
        }
      }

      onActionSuccess(response ? response.data : null);
    } catch (error) {
      onError(error);
    }

    onClose();
  }

  function verifyProjectName(projectName: string) {
    if (objectProps.object !== ActionObject.Project) {
      console.error("verifyProjectName called for non-project object");
      return;
    }

    setDisableSuccessButton(projectName !== objectProps.project.name);
  }

  let modalBodyElement: React.ReactNode | null = null;

  if (action === Action.Delete) {
    if (objectProps.object === ActionObject.Project) {
      modalBodyElement = (
        <DeleteProjectModalBodyElement
          projectName={objectProps.project.name}
          onVerificationTextChange={verifyProjectName}
        />
      );
    } else if (objectProps.object === ActionObject.Variant) {
      modalBodyElement = (
        <DeleteVariantModalBodyElement variantName={objectProps.variant.name} />
      );
    }

    return (
      <DeleteConfirmationModal
        headerName={objectProps.object}
        isConfirmationBtnDisabled={disableSuccessButton}
        isLoading={loading}
        isModalOpen={true}
        modalBodyElement={modalBodyElement}
        name={
          objectProps.object === ActionObject.Project
            ? objectProps.project.name
            : objectProps.variant.name
        }
        toggleModal={onClose}
        onClickConfirm={handleAction}
      />
    );
  } else if (action === Action.Copy) {
    if (objectProps.object === ActionObject.Project) {
      modalBodyElement = (
        <CopyProjectModalBodyElement projectName={objectProps.project.name} />
      );
    } else if (objectProps.object === ActionObject.Variant) {
      modalBodyElement = (
        <CopyVariantModalBodyElement variantName={objectProps.variant.name} />
      );
    }

    return (
      <BasicConfirmationModal
        confirmationButtonText="Duplikat erstellen"
        headerText="Duplizieren bestätigen"
        isConfirmationBtnDisabled={disableSuccessButton}
        isLoading={loading}
        isModalOpen
        toggleModal={onClose}
        onCancel={onClose}
        onConfirm={handleAction}
      >
        {modalBodyElement}
      </BasicConfirmationModal>
    );
  }

  console.error("ActionConfirmationModal: invalid action");

  return null;
}

interface DeleteProjectModalBodyElementProps {
  projectName: string;
  onVerificationTextChange: (text: string) => void;
}

function DeleteProjectModalBodyElement({
  projectName,
  onVerificationTextChange
}: DeleteProjectModalBodyElementProps) {
  const [value, setValue] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    onVerificationTextChange(event.target.value);
  }

  return (
    <div>
      <p>
        Sind Sie sicher, dass Sie dieses Projekt löschen möchten? Dieser Schritt
        kann nicht rückgängig gemacht werden.
      </p>
      <FormGroup>
        <Label for="confirm-delete">
          Geben Sie zur Bestätigung den Namen des Projektes{" "}
          <mark>{projectName}</mark> in das Textfeld ein.
        </Label>
        <Input
          id="confirm-delete"
          type="text"
          value={value}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
}

function DeleteVariantModalBodyElement({
  variantName
}: {
  variantName: string;
}) {
  return (
    <div>
      <p>
        Sind Sie sicher, dass Sie <mark>{variantName}</mark> löschen möchten?
        Dieser Schritt kann nicht rückgängig gemacht werden.
      </p>
      <p>
        Damit werden auch verknüpfte Objekte, wie Personen und Erzeuger
        gelöscht.
      </p>
    </div>
  );
}

function CopyProjectModalBodyElement({ projectName }: { projectName: string }) {
  return (
    <div>
      <p>
        Möchten Sie ein Duplikat des Projekts <mark>{projectName}</mark> auf{" "}
        {SITE_NAME} erstellen?
      </p>
    </div>
  );
}

function CopyVariantModalBodyElement({ variantName }: { variantName: string }) {
  return (
    <div>
      <p>
        Möchten Sie ein Duplikat der Variante{" "}
        {variantName && (
          <span>
            <mark>{variantName}</mark>{" "}
          </span>
        )}
        auf {SITE_NAME} erstellen?
      </p>
    </div>
  );
}

export { ActionConfirmationModal, ActionConfirmationModalProps };
