import PropTypes from "prop-types";
import { PureComponent } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { THEME_VARS } from "../../../utils/constants";
import { makeArrayUnique } from "../../../utils/data-utils";
import { matchFullTextCaseInsensitive } from "../../../utils/text-utils";
import { DecimalLabel } from "../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { Select } from "../../BuildingBlocks/Forms/Select/Select";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import loader from "../../Loader";

import "./LoadProfileSelectList.scss";

class LoadProfileSelectList extends PureComponent {
  static defaultProps = {
    value: {}
  };

  constructor(props) {
    super(props);
    this.setInstanceVariables(props);
    this.categories = makeArrayUnique(
      this.loadprofiles.map((lp) => lp.category)
    );
    this.categories.push("Alle auswählen");
  }

  setInstanceVariables = (props) => {
    this.loadprofiles = props.data[0].map((lp) => {
      return { ...lp, category: lp.category ? lp.category : "Keine Kategorie" };
    });

    this.columns = [
      {
        Header: "Name",
        id: "name",
        accessor: (l) => l,
        Cell: (row) => (
          <CellWithTooltip id={`name-${row.value.id}`} value={row.value.name} />
        ),
        filterMethod: (filter, row) =>
          matchFullTextCaseInsensitive(row[filter.id].name, filter.value)
      },
      {
        Header: "Jahresenergie",
        accessor: "yearlyEnergy",
        Cell: (row) => (
          <DecimalLabel className="float-right" value={row.value} />
        ),
        filterMethod: this.filterFloatValues,
        minWidth: 80
      },
      {
        Header: "Höchstleistung",
        accessor: "maxPower",
        Cell: (row) => (
          <DecimalLabel className="float-right" value={row.value} />
        ),
        filterMethod: this.filterFloatValues,
        minWidth: 70
      },
      {
        Header: "Vollbenutzungsstunden",
        id: "fullLoadHours",
        accessor: (l) => {
          return l.yearlyEnergy / l.maxPower;
        },
        Cell: (row) => (
          <DecimalLabel className="float-right" value={row.value} />
        ),
        filterMethod: this.filterFloatValues,
        minWidth: 50
      },
      {
        Header: "Kategorie",
        accessor: "category",
        filterMethod: this.filterLoadprofileCategory,
        Filter: ({ filter, onChange }) => {
          const allCategory = {
            value: "Alle auswählen",
            label: "Alle auswählen"
          };

          const defaultCategoryValue = filter
            ? {
                value: filter.value,
                label: filter.value
              }
            : allCategory;

          const categoryOptions = this.categories.map((category) => {
            return {
              value: category,
              label: category
            };
          });

          return (
            <div style={{ textAlign: "left" }}>
              <Select
                defaultValue={defaultCategoryValue}
                id="category-filter"
                options={categoryOptions}
                onChange={(selectedOption) => onChange(selectedOption.value)}
              />
            </div>
          );
        }
      },
      {
        Header: "Art",
        id: "generic",
        accessor: (l) => (l.isGeneric ? "Allgemein" : "Eigene"),
        sortMethod: (a) => {
          return a === "Eigene" ? 1 : -1;
        },
        filterable: false,
        width: 80
      },
      {
        Header: "Beschreibung",
        id: "description",
        accessor: (l) => l,
        Cell: (row) => (
          <CellWithTooltip
            id={`desc-${row.value.id}`}
            value={row.value.description}
          />
        ),
        filterMethod: (filter, row) =>
          row[filter.id].description &&
          matchFullTextCaseInsensitive(row[filter.id].description, filter.value)
      }
    ];
  };

  selectedRowProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () =>
          this.updateSelectedLoadprofile(rowInfo.index, rowInfo.original),
        style: {
          background:
            rowInfo.original === this.props.selectedLoadprofileId
              ? THEME_VARS.customLightGrey
              : "#FFFFFF",
          cursor: "pointer"
        }
      };
    } else {
      return { style: { cursor: "pointer" } };
    }
  };

  updateSelectedLoadprofile = (rowIndex, newLoadprofile) => {
    this.setState(
      {
        selected: newLoadprofile
      },
      () => this.props.onLoadprofileSelected(newLoadprofile)
    );
  };

  filterFloatValues = (filter, row) => {
    return row[filter.id].toFixed().startsWith(filter.value);
  };

  filterLoadprofileCategory = (filter, row) => {
    if (filter.value === "Alle auswählen") {
      return true;
    }
    return String(row[filter.id]).match(new RegExp(filter.value, "i"));
  };

  render() {
    return (
      <CustomReactTable
        className="LoadProfileSelectList"
        columns={this.columns}
        data={this.loadprofiles}
        defaultPageSize={this.loadprofiles.length}
        defaultSorted={[
          {
            id: "generic",
            desc: true
          }
        ]}
        filterable
        getTrProps={this.selectedRowProps}
        noDataText="Keine Lastprofile gefunden"
        style={{ height: "464px" }}
      />
    );
  }
}

LoadProfileSelectList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  value: PropTypes.object,
  onLoadprofileSelected: PropTypes.func.isRequired
};

function CellWithTooltip({ id, value }) {
  return (
    <span id={id}>
      {value}
      <UncontrolledTooltip delay={2} placement="top" target={id}>
        {value}
      </UncontrolledTooltip>
    </span>
  );
}

const loadProfileSelectListWithLoader = loader(LoadProfileSelectList);
export { loadProfileSelectListWithLoader as LoadProfileSelectList };
