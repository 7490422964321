import { Buffer } from "buffer";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { ProjectMinimal, VariantMinimal } from "../../utils/backend-types";

import { moveArrayElement } from "../../utils/moveArrayElement";
import loader from "../Loader";

import "./PageWithVariantSelection.scss";

interface PageWithVariantSelectionProps {
  variantId: number;
  data: {
    project: ProjectMinimal;
  };
  /* To remove this disabling it is needed to create interfaces for all wrapped components came from js files:
   * AnalyzeView
   * VariantComparisonViewWithScrollableMainContent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const withVariantSelection = (WrappedComponent) => {
  function PageWithVariantSelection({
    variantId,
    data,
    otherProps
  }: PageWithVariantSelectionProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedVariantIds, setSelectedVariantIds] = useState<Array<number>>(
      getVariantIdsFromUrlParameter()
    );

    function getVariantIdsFromUrlParameter(): Array<number> {
      const encodedSelectedVariantIds = searchParams.get("selectedVariantIds");
      let selectedVariantIds: Array<number> = [variantId];

      if (encodedSelectedVariantIds) {
        const decodedString = Buffer.from(
          encodedSelectedVariantIds,
          "base64"
        ).toString("utf8");
        selectedVariantIds = JSON.parse(decodedString);
      }

      return selectedVariantIds;
    }

    function changeSelectedVariantIds(
      selectedVariantIds: Array<number>
    ): string {
      orderAccordingToCurrentVariantOrder(selectedVariantIds);
      return setSelectedVariantIdsState(selectedVariantIds);
    }

    function setSelectedVariantIdsState(selectedVariantIds): string {
      const encodedString = Buffer.from(
        JSON.stringify(selectedVariantIds),
        "utf8"
      ).toString("base64");

      setSearchParams({
        selectedVariantIds: encodedString
      });
      setSelectedVariantIds(selectedVariantIds);

      return searchParams.toString();
    }

    /**
     * This method gets an array of newly selected variant id and orders it
     * according to the current order. The order could have changed when the move functions have been called.
     */
    function orderAccordingToCurrentVariantOrder(
      newlySelectedVariantIds: Array<number>
    ): void {
      const sortingObject = selectedVariantIds.reduce(
        (obj: Record<number, number>, variantId) => {
          const index = selectedVariantIds.indexOf(variantId);
          obj[variantId] = index < 0 ? Number.MAX_SAFE_INTEGER : index;
          return obj;
        },
        {}
      );

      newlySelectedVariantIds.sort(function (a, b) {
        return sortingObject[a] - sortingObject[b];
      });
    }

    function getDisplayNamesForSelectedVariantIds(
      variants: Array<VariantMinimal>
    ): Record<number, string> {
      return selectedVariantIds.reduce(
        (obj: Record<number, string>, variantId) => {
          const variant = variants.find((v) => v.id === variantId);

          if (variant) {
            obj[variantId] = variant.name;
          }

          return obj;
        },
        {}
      );
    }

    function moveVariantLeft(variantId: number): void {
      const newSelectedVariantIds = [...selectedVariantIds];
      const fromIndex = newSelectedVariantIds.indexOf(variantId);
      const toIndex = fromIndex - 1;

      if (toIndex >= 0) {
        moveArrayElement(newSelectedVariantIds, fromIndex, toIndex);
        setSelectedVariantIdsState(newSelectedVariantIds);
      }
    }

    function moveVariantRight(variantId: number): void {
      const newSelectedVariantIds = [...selectedVariantIds];
      const fromIndex = newSelectedVariantIds.indexOf(variantId);
      const toIndex = fromIndex + 1;

      if (toIndex < newSelectedVariantIds.length) {
        moveArrayElement(newSelectedVariantIds, fromIndex, toIndex);
        setSelectedVariantIdsState(newSelectedVariantIds);
      }
    }

    const displayNames = getDisplayNamesForSelectedVariantIds(
      data.project.variants
    );

    return (
      <WrappedComponent
        displayNames={displayNames}
        selectedVariantIds={selectedVariantIds}
        variants={data.project.variants}
        onChangeSelectedVariantIds={changeSelectedVariantIds}
        onMoveVariantLeft={moveVariantLeft}
        onMoveVariantRight={moveVariantRight}
        {...otherProps}
      />
    );
  }

  return loader(PageWithVariantSelection);
};

export default withVariantSelection;
