import {
  IconCircleDot,
  IconCircleMinus,
  IconCirclePlus
} from "@tabler/icons-react";
import type { ReactNode } from "react";
import { HistoryLogAction } from "../../types/api.types";

export const PAGE_SIZE = 25;

export const HistoryLogActionVerbs: Record<HistoryLogAction, string> = {
  [HistoryLogAction.Created]: "erstellt",
  [HistoryLogAction.Updated]: "aktualisiert",
  [HistoryLogAction.Deleted]: "gelöscht"
};

export const HistoryLogActionIcons: Record<HistoryLogAction, ReactNode> = {
  [HistoryLogAction.Created]: <IconCirclePlus />,
  [HistoryLogAction.Updated]: <IconCircleDot />,
  [HistoryLogAction.Deleted]: <IconCircleMinus />
};
