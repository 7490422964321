import { Button } from "@mantine/core";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import type { Column } from "react-table";
import { useIsOverflow } from "../../../../../hooks/useIsOverflow";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import {
  BasicConfirmationModal,
  BasicConfirmationModalIcon
} from "../../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { CustomReactTable } from "../../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import { AnimatedLoadingIcon } from "../../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { anchorTooltip } from "../../../../Tooltip/Tooltip";
import type { ImportLog } from "../../../ThirdPartySystems.types";
import { ImportLogsMessageType } from "../../../ThirdPartySystems.types";
import "./ImportLogsTable.scss";

interface ErrorPopupData {
  message: string | null;
  originalMessage: string | null;
}

interface ImportLogsTableProps {
  isLoading: boolean;
  logs: Array<ImportLog>;
  meterNames: Record<number, string>;
  numPages: number;
  pageSize: number;
  onFetchData: (state: { page: number; pageSize: number }) => void;
}

function ImportLogsTable({
  isLoading,
  logs,
  meterNames,
  numPages,
  pageSize,
  onFetchData
}: ImportLogsTableProps) {
  const { t } = useTranslation();
  const [shownOriginalMessage, setShownOriginalMessage] =
    useState<ErrorPopupData | null>(null);

  function handleShowOriginalMessage(
    message: string | null,
    originalMessage: string | null
  ) {
    setShownOriginalMessage({ message, originalMessage });
  }

  const tableColumns: Array<Column<ImportLog>> = [
    {
      Header: "Status",
      id: "messageType",
      accessor: (row) => row.messageType,
      width: 60,
      Cell: (cell: TypedCellInfo<ImportLog["messageType"], ImportLog>) => (
        <StatusCell status={cell.value} />
      ),
      sortable: false
    },
    {
      Header: "Zeitpunkt",
      id: "timestamp",
      accessor: (row) => row.timestamp,
      width: 120,
      Cell: (cell: TypedCellInfo<ImportLog["timestamp"], ImportLog>) => {
        return <span>{cell.value}</span>;
      },
      sortable: false
    },
    {
      Header: "Zähler",
      id: "meter",
      accessor: (row) => row.meter,
      Cell: (cell: TypedCellInfo<ImportLog["meter"], ImportLog>) => (
        <span>{meterNames[cell.value]}</span>
      ),
      sortable: false
    },
    {
      Header: "Meldung",
      id: "message",
      accessor: (row) => row.message,
      minWidth: 200,
      Cell: (cell: TypedCellInfo<ImportLog["message"], ImportLog>) => (
        <MessageCell
          message={cell.value}
          messageType={cell.original.messageType}
          originalMessage={cell.original.originalMessage}
          onClickShowOriginalMessage={() =>
            handleShowOriginalMessage(cell.value, cell.original.originalMessage)
          }
        />
      ),
      sortable: false
    },
    {
      Header: "Angefragter Zeitraum",
      id: "startDate",
      accessor: (row) => row.startDate,
      width: 170,
      Cell: (cell: TypedCellInfo<never, ImportLog>) => (
        <TimespanCell
          endDate={cell.original.endDate}
          startDate={cell.original.startDate}
        />
      ),
      sortable: false
    }
  ];

  return (
    <>
      <CustomReactTable
        className="ImportLogsTable"
        columns={tableColumns}
        data={logs}
        loading={isLoading}
        loadingText={<AnimatedLoadingIcon />}
        manual
        minRows={0}
        NoDataComponent={NoDataComponent}
        pages={numPages}
        pageSize={pageSize}
        showPageJump
        showPagination
        onFetchData={onFetchData}
      />
      {shownOriginalMessage && (
        <BasicConfirmationModal
          headerText={shownOriginalMessage.message ?? undefined}
          icon={BasicConfirmationModalIcon.Error}
          isModalOpen={!!shownOriginalMessage}
          toggleModal={() => setShownOriginalMessage(null)}
          onConfirm={() => setShownOriginalMessage(null)}
        >
          <ReactMarkdown>
            {shownOriginalMessage.originalMessage ?? t("errors.UnknownError")}
          </ReactMarkdown>
        </BasicConfirmationModal>
      )}
    </>
  );
}

interface StatusCellProps {
  status: ImportLogsMessageType;
}

function StatusCell({ status }: StatusCellProps) {
  return (
    <span
      className={classNames("StatusCell", {
        success: status === ImportLogsMessageType.Success,
        error: status === ImportLogsMessageType.Error
      })}
    >
      {status}
    </span>
  );
}

interface MessageCellProps {
  message: string | null;
  messageType: ImportLogsMessageType;
  originalMessage: string | null;
  onClickShowOriginalMessage: () => void;
}

function MessageCell({
  message,
  messageType,
  originalMessage,
  onClickShowOriginalMessage
}: MessageCellProps) {
  const { overflowRef, isOverflow } = useIsOverflow();
  const showTooltip = isOverflow && !!message;
  const tooltipProps = showTooltip ? anchorTooltip({ content: message }) : {};
  const iconName =
    messageType === ImportLogsMessageType.Error
      ? IconName.ExclamationRed
      : IconName.RoundedCheckGreen;

  return (
    <div className="MessageCell" {...tooltipProps}>
      <Icon className="message-icon" name={iconName} />
      <div className="message-box" ref={overflowRef}>
        <span>{message}</span>
      </div>
      {originalMessage && (
        <Button
          h="auto"
          variant="transparent"
          onClick={onClickShowOriginalMessage}
        >
          Mehr
        </Button>
      )}
    </div>
  );
}

interface TimespanCellProps {
  startDate: string | null;
  endDate: string | null;
}

function TimespanCell({ startDate, endDate }: TimespanCellProps) {
  const startDateTime = startDate
    ? backendDateOrDateTimeToLuxonDateTime(startDate)
    : null;
  const endDateTime = endDate
    ? backendDateOrDateTimeToLuxonDateTime(endDate)
    : null;

  const startString = startDateTime
    ? luxonDateTimeToBackendDateOrDateTime(startDateTime)
    : "";
  const endString = endDateTime
    ? luxonDateTimeToBackendDateOrDateTime(endDateTime)
    : "";

  const dateDisplay =
    startString && endString
      ? `${startString} - ${endString}`
      : startString || endString || "";

  return <span>{dateDisplay}</span>;
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Historie vorhanden.</p>
    </div>
  );
}

export { ImportLogsTable, ImportLogsTableProps };
