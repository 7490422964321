import { useState } from "react";
import ReactDOM from "react-dom";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../api";
import type { GhostNode } from "../../utils/backend-types";
import { FormFieldController } from "../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import type { WizardModeProps } from "../VariantObjectWizard/VariantObjectWizard";
import { GHOST_NODE_FORM_FIELD_DATA } from "./GhostNodeWizard.constants";

type GhostNodeFormProps = {
  buttonContainer?: HTMLElement;
  onCancel: () => void;
  onSubmit: (data: GhostNode) => Promise<ApiResponse>;
} & WizardModeProps<GhostNode>;

function GhostNodeForm({
  buttonContainer,
  onCancel,
  onSubmit,
  ...modeProps
}: GhostNodeFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = useForm<GhostNode>({
    defaultValues:
      modeProps.mode === "edit" ? modeProps.wizardObject : undefined
  });

  const formFieldData = GHOST_NODE_FORM_FIELD_DATA;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onValidSubmit: SubmitHandler<GhostNode> = async (data) => {
    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<GhostNode>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  async function handleClickSubmit() {
    setIsSubmitting(true);

    try {
      await submitCallback();
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleClickCancel() {
    reset();
    onCancel();
  }

  const Buttons = (
    <>
      <Button onClick={handleClickCancel}>Abbrechen</Button>
      <SpinButton
        color="primary"
        spin={isSubmitting}
        onClick={handleClickSubmit}
      >
        {modeProps.mode === "edit" ? "Speichern" : "Erstellen"}
      </SpinButton>
    </>
  );

  return (
    <form className="GhostNodeForm" onSubmit={submitCallback}>
      <FormFieldController
        control={control}
        data={formFieldData.name}
        error={errors.name}
      />

      {buttonContainer
        ? ReactDOM.createPortal(Buttons, buttonContainer)
        : Buttons}
    </form>
  );
}

export { GhostNodeForm, GhostNodeFormProps };
