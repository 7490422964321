import { NavLink } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { PopupBox } from "../PopupBox/PopupBox";

interface EdgePopupProps {
  x: number;
  y: number;
  edgeId: number;
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
  onClickDelete: (edgeId: number) => void;
}

function EdgePopup({
  x,
  y,
  edgeId,
  onClickDelete,
  setShowNotAccessibleModal,
  userHasNoAccess
}: EdgePopupProps) {
  function handleClickDelete() {
    if (userHasNoAccess) {
      setShowNotAccessibleModal?.(true);
    } else {
      onClickDelete(edgeId);
    }
  }

  return (
    <PopupBox className="EdgePopup" position={{ x, y }}>
      <NavLink
        component="button"
        disabled={userHasNoAccess}
        label="Verbindung löschen"
        leftSection={<IconTrash color="red" size="16" />}
        onClick={handleClickDelete}
      />
    </PopupBox>
  );
}

export { EdgePopup };
