import { useState } from "react";
import { ButtonDropdown } from "reactstrap";
import api from "../../../../api";
import { useLoadProfileTemplateYears } from "../../../../hooks/useLoadProfileTemplateYears";
import urls from "../../../../urls";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../../BuildingBlocks/Dropdown/Dropdown";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";

function LoadprofileTemplateDownload() {
  const { isLoading, error, years } = useLoadProfileTemplateYears();
  const sortedYears = years.sort().reverse();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

  return (
    <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle caret>Vorlage herunterladen</DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Jahr</DropdownItem>
        <DropdownItem divider />
        <LoadOrError error={error} loading={isLoading}>
          {sortedYears.map((year) => (
            <LoadprofileTemplateDownloadLink key={year} year={year} />
          ))}
        </LoadOrError>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

interface LoadprofileTemplateDownloadLinkProps {
  year: number;
}
function LoadprofileTemplateDownloadLink({
  year
}: LoadprofileTemplateDownloadLinkProps) {
  function handleDownload() {
    api
      .post(urls.api.loadprofilesTemplateDownload(), { year: year })
      .then((response) =>
        downloadCsvOrExcelFileForResponseWithContentDisposition(response, true)
      )
      .catch((error) => openErrorAlertPopup(error));
  }

  return <DropdownItem onClick={handleDownload}>{year}</DropdownItem>;
}

export { LoadprofileTemplateDownload };
