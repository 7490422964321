import { QRCodeSVG } from "qrcode.react";
import { Alert, AlertColor } from "../../Alert/Alert";
import { FormField } from "../../BuildingBlocks/Forms/FormField/FormField";
import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../Buttons/Button/Button";

interface AuthAppConfirmModalProps {
  isOpen: boolean;
  appUrl: string;
  otp: string;
  error: string | Array<string> | null;
  onToggle: () => void;
  onOtpChange: (value: string) => void;
  onAuthMethodConfirm: () => void;
}

function AuthAppConfirmModal({
  isOpen,
  appUrl,
  otp,
  error,
  onToggle,
  onOtpChange,
  onAuthMethodConfirm
}: AuthAppConfirmModalProps) {
  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Aktivierung bestätigen</ModalHeader>
      <ModalBody scrollable>
        <div>
          <p>
            1. Sie haben die Authentifizierungs-App als Ihre bevorzugte Methode
            für die 2-Faktor-Authentifizierung gewählt. Bitte scannen Sie den
            unten angezeigten QR-Code mit Ihrer Authentifizierungs-App.
          </p>
          <QRCodeSVG value={appUrl} />
        </div>
        <div>
          <p>
            2. Abschließend geben Sie bitte zur Bestätigung den von Ihrer App
            generierten Code im folgenden Feld ein.
          </p>
          <FormField
            label="Code"
            placeholder="XXXXXX"
            required
            type="text"
            value={otp}
            onChange={(e) => onOtpChange(e.target.value)}
          />
        </div>

        {error && (
          <Alert color={AlertColor.Danger}>
            <p>{error}</p>
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <Button color="primary" onClick={onAuthMethodConfirm}>
          Speichern
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { AuthAppConfirmModal, AuthAppConfirmModalProps };
