import { SegmentedControl } from "@mantine/core";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { numericFormatter } from "react-number-format";
import { type MeterDataGaps } from "../../../../../../../../utils/backend-types";
import { DATE_DISPLAY_FORMAT } from "../../../../../../../../utils/dates/dates.constants";
import { getDefaultNumericFormatterProps } from "../../../../../../../../utils/getDefaultNumericFormatterProps";
import { Icon } from "../../../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../../../BuildingBlocks/Icon/types";
import { MantineBarChart } from "../../../../../../../Charts/BarChart/MantineBarChart";
import { mapMeasurandToLabel } from "../utils/mapMeasurandToLabel";
import "./DataGapsChart.scss";

type Resolution = "day" | "week" | "month";

export interface DataGapsChartProps {
  dataGaps: MeterDataGaps;
  selectedTimeRange: [DateTime, DateTime];
}

function DataGapsChart({ dataGaps, selectedTimeRange }: DataGapsChartProps) {
  const seriesNames = Object.keys(dataGaps).filter((key) => key.includes("."));
  const monthsToShow = Math.round(
    selectedTimeRange[1].diff(selectedTimeRange[0], "months").toObject()
      .months || 0
  );
  const defaultResolution =
    monthsToShow >= 12 ? "month" : monthsToShow > 1 ? "week" : "day";

  const [selectedMeterAndMeasurand, setSelectedMeterAndMeasurand] = useState<
    string | undefined
  >(seriesNames[0]);
  const [currentResolution, setCurrentResolution] = useState<Resolution>(
    monthsToShow >= 12 ? "month" : monthsToShow > 1 ? "week" : "day"
  );
  const [zoomTimeRange, setZoomTimeRange] = useState<[DateTime, DateTime]>([
    selectedTimeRange[0],
    selectedTimeRange[1]
  ]);

  const allSlots: Array<{ intervalName: string; timestamps: Array<DateTime> }> =
    useMemo(() => {
      const temp: Array<{ intervalName: string; timestamps: Array<DateTime> }> =
        [];
      // The slot iterator iterates over all available slots
      // (days, calendar weeks or months - interval is resolution)
      let slotIterator = zoomTimeRange[0];
      while (slotIterator < zoomTimeRange[1]) {
        const timestampsInSlot: Array<DateTime> = [];
        let timestampIterator = slotIterator;
        // The timestamp iterator iterates over all timestamps in a specific slot (15 min interval)
        while (
          timestampIterator <=
            DateTime.fromMillis(
              slotIterator.endOf(currentResolution).toMillis()
            ) &&
          timestampIterator <= zoomTimeRange[1]
        ) {
          timestampsInSlot.push(timestampIterator);
          timestampIterator = timestampIterator.plus({ minutes: 15 });
        }

        temp.push({
          intervalName:
            currentResolution === "day"
              ? slotIterator.toFormat(DATE_DISPLAY_FORMAT)
              : currentResolution === "week"
                ? "KW " + slotIterator.toFormat("WW")
                : slotIterator.toFormat("MM/yyyy"),
          timestamps: timestampsInSlot
        });
        slotIterator = slotIterator
          .plus({ [currentResolution]: 1 })
          .startOf(currentResolution);
      }
      return temp;
    }, [currentResolution, zoomTimeRange]);

  const gapsPerSlot = useMemo(
    () =>
      allSlots.map((slot) => {
        return {
          ...slot,
          ...seriesNames.reduce(
            (result, seriesName) => ({
              ...result,
              [seriesName]: slot.timestamps.filter((timestamp) => {
                return (
                  dataGaps[seriesName].gaps.length !== 0 &&
                  dataGaps[seriesName].gaps.some(
                    (gap) =>
                      timestamp >= DateTime.fromISO(gap[0]) &&
                      timestamp <= DateTime.fromISO(gap[1])
                  )
                );
              }).length
            }),
            {}
          )
        };
      }),
    [dataGaps, seriesNames, allSlots]
  );

  const chartData = useMemo(
    () =>
      gapsPerSlot.map((entry) => {
        return {
          x: entry.intervalName,
          x_total: entry.timestamps.length,
          xStart: entry.timestamps[0],
          xEnd: entry.timestamps[entry.timestamps.length - 1],
          ...seriesNames.reduce(
            (result, seriesName) => ({
              ...result,
              [seriesName]:
                (entry.timestamps.length - entry[seriesName]) /
                entry.timestamps.length
            }),
            {}
          ),
          ...seriesNames.reduce(
            (result, seriesName) => ({
              ...result,
              [`${seriesName}_total`]: entry[seriesName]
            }),
            {}
          )
        };
      }),
    [seriesNames, gapsPerSlot]
  );

  function handleZoom(slotStart: DateTime, slotEnd: DateTime) {
    setZoomTimeRange([slotStart, slotEnd]);
    setCurrentResolution(currentResolution === "month" ? "week" : "day");
  }

  function handleZoomReset() {
    setZoomTimeRange(selectedTimeRange);
    setCurrentResolution(defaultResolution);
  }

  return (
    <div className="DataGapsChart" data-testid="data-gaps-chart-wrapper">
      {Object.keys(dataGaps).filter((key) => key.includes(".")).length ===
        0 && (
        <div className="no-data-display">
          <Icon
            className="no-data-display-check-circle"
            name={IconName.CheckCircle}
          />
          Keine Datenlücken!
        </div>
      )}
      {seriesNames.length > 1 && (
        <SegmentedControl
          data={seriesNames.map((seriesKey) => ({
            value: seriesKey,
            label: mapMeasurandToLabel(seriesKey.split(".").at(-1) || "")
          }))}
          mb="lg"
          value={selectedMeterAndMeasurand}
          onChange={(seriesKey) => setSelectedMeterAndMeasurand(seriesKey)}
        />
      )}
      <MantineBarChart
        data={chartData}
        data-testid="data-gaps-chart"
        dataKey="x"
        getBarColor={(value) =>
          value < 1
            ? value === 0
              ? "var(--mantine-color-red-9)"
              : "var(--mantine-color-yellow-5)"
            : "var(--mantine-color-lime-6)"
        }
        h={450}
        minBarSize={1}
        series={
          selectedMeterAndMeasurand
            ? [
                {
                  label: mapMeasurandToLabel(
                    selectedMeterAndMeasurand.split(".").at(-1) || ""
                  ),
                  name: selectedMeterAndMeasurand
                }
              ]
            : []
        }
        tooltipProps={{
          formatter: (_value, name, item) =>
            item.payload.x_total -
            item.payload[`${name}_total`] +
            "/" +
            item.payload.x_total +
            " Zeitstempel",
          labelFormatter: (label, payload) => {
            if (payload.length === 0) {
              return label;
            }

            const startString = DateTime.fromISO(
              payload[0].payload.xStart
            ).toFormat(DATE_DISPLAY_FORMAT);
            const endString = DateTime.fromISO(
              payload[0].payload.xEnd
            ).toFormat(DATE_DISPLAY_FORMAT);
            if (startString === endString) {
              return startString;
            }
            return startString + " - " + endString;
          }
        }}
        withLegend={false}
        xAxisLabel="Zeitraum"
        xAxisProps={{ angle: 90, height: 75, textAnchor: "start" }}
        yAxisLabel="Vollständigkeit der Daten"
        yAxisProps={{
          domain: [0, 1],
          tickFormatter: (value) =>
            numericFormatter((value * 100).toString(), {
              ...getDefaultNumericFormatterProps(),
              suffix: " %"
            })
        }}
        zoomable={true}
        onZoom={currentResolution !== "day" ? handleZoom : undefined}
        onZoomReset={
          currentResolution !== defaultResolution ? handleZoomReset : undefined
        }
      />
    </div>
  );
}

export { DataGapsChart };
