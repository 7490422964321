import { useDropzone } from "react-dropzone";

import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";

import "./UploadDropzone.scss";

interface UploadDropzoneProps {
  dropzoneIntroText: string;
  dropzoneExplanationComponent: React.ReactNode;
  isActive: boolean;
  onShowDropzone: () => void;
  onHideDropzone: () => void;
  onUploadFile: (file: File) => void;
  children: React.ReactNode;
}

function UploadDropzone({
  dropzoneIntroText,
  dropzoneExplanationComponent,
  isActive,
  onShowDropzone,
  onHideDropzone,
  onUploadFile,
  children
}: UploadDropzoneProps) {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDragEnter: onShowDropzone,
    onDragLeave: onHideDropzone,
    onDrop: handleDrop,
    noClick: true,
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": []
    }
  });

  function handleClickOpenFileInput(e: React.MouseEvent) {
    e.stopPropagation();
    open();
  }

  function handleDrop(files: Array<File>) {
    if (files.length === 1) {
      onUploadFile(files[0]);
    } else {
      alert("Bitte nur eine Datei mit der Endung .xls oder .xlsx auswählen.");
    }

    onHideDropzone();
  }

  return (
    <div {...getRootProps()} className="dropzone">
      {isActive && (
        <>
          <input {...getInputProps()} />
          <DropzoneOverlay
            explanationComponent={dropzoneExplanationComponent}
            introText={dropzoneIntroText}
            onHideDropzone={onHideDropzone}
            onOpenFileInput={handleClickOpenFileInput}
          />
        </>
      )}
      {!!children && children}
    </div>
  );
}

interface DropzoneOverlayProps {
  introText: string;
  explanationComponent: React.ReactNode;
  onHideDropzone: () => void;
  onOpenFileInput: (e: React.MouseEvent) => void;
}

function DropzoneOverlay({
  introText,
  explanationComponent,
  onHideDropzone,
  onOpenFileInput
}: DropzoneOverlayProps) {
  return (
    <div className="dropzone-overlay">
      <div className="pull-right">
        <Button color="danger" onClick={onHideDropzone}>
          <Icon name={IconName.Close} />
        </Button>
      </div>
      <h2>Upload von Energiedaten</h2>
      <div className="dropzone-overlay-section">{introText}</div>
      <div>
        <Icon name={IconName.Upload} style={{ fontSize: 130 }} />
      </div>
      <div className="dropzone-overlay-section">
        <Button color="primary" onClick={onOpenFileInput}>
          Datei auswählen
        </Button>
      </div>
      {!!explanationComponent && explanationComponent}
    </div>
  );
}

export default UploadDropzone;
