import { Box, Text } from "@mantine/core";
import { HistoryLogAction } from "../../../types/api.types";
import type { HistoryLog } from "../../../utils/backend-types";
import type { FieldNameToLabelMap } from "../HistoryTab";
import { ChangeDisplay } from "./ChangeDisplay/ChangeDisplay";

interface HistoryActionProps {
  historyLog: HistoryLog;
  fieldNameToLabelMap: FieldNameToLabelMap;
  highlight?: string;
}

function HistoryAction({
  historyLog,
  fieldNameToLabelMap,
  highlight = ""
}: HistoryActionProps) {
  switch (historyLog.action) {
    case HistoryLogAction.Created:
      return <Text>{historyLog.additional_data?.extra_information}</Text>;
    case HistoryLogAction.Updated:
      return (
        <Box ml="xs">
          {Object.keys(historyLog.changes).map((fieldName) => {
            return (
              <ChangeDisplay
                change={historyLog.changes[fieldName]}
                fieldName={fieldName}
                fieldNameToLabelMap={fieldNameToLabelMap}
                highlight={highlight}
                key={fieldName}
              />
            );
          })}
        </Box>
      );
    case HistoryLogAction.Deleted:
      return <Text>{historyLog.additional_data?.extra_information}</Text>;
    default:
      return null;
  }
}

export { HistoryAction, HistoryActionProps };
