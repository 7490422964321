import { useCallback, useEffect, useState } from "react";
import type {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Options,
  PropsValue
} from "react-select";
import ReactAsyncSelect from "react-select/async";
import AsyncCreatableSelect, {
  type AsyncCreatableProps
} from "react-select/async-creatable";
import api from "../../../../../api";

export interface AsyncSelectChoice {
  id: string | number;
  name: string;
  customLabel?: string;
}

interface AsyncSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AsyncCreatableProps<Option, IsMulti, Group> {
  creatable?: boolean;
  dataUrl: string;
  onChangeIsValidInput: (isValid: boolean) => void;
  labelField?: string;
}

function AsyncSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  creatable,
  dataUrl,
  defaultValue,
  formatCreateLabel,
  onChange,
  onChangeIsValidInput,
  labelField,
  onCreateOption,
  isDisabled,
  ...otherProps
}: AsyncSelectProps<Option, IsMulti, Group>) {
  const [value, setValue] = useState<PropsValue<Option> | undefined>(null);
  const [loadingInitialValue, setLoadingInitialValue] = useState(false);

  useEffect(() => {
    if (dataUrl && defaultValue) {
      setLoadingInitialValue(true);
      api
        .get(`${dataUrl}${defaultValue}/`)
        .then((response: { data: AsyncSelectChoice }) => {
          // typescript hack to force Option type
          const newValue: Option = {
            value: response.data.id,
            label:
              labelField && response.data[labelField]
                ? response.data[labelField]
                : response.data.name
          } as unknown as Option;
          setValue(newValue);
          setLoadingInitialValue(false);
        });
    }
  }, [dataUrl, defaultValue, labelField]);

  function handleInputChange(inputValue: string): void {
    onChangeIsValidInput(inputValue.length > 2);
  }

  function handleOnChange(
    selected: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>
  ): void {
    setValue(selected);

    if (onChange) {
      onChange(selected, actionMeta);
    }
  }

  function handleCreateOption(inputValue: string) {
    if (onCreateOption) {
      onCreateOption(inputValue);
    }
  }

  const promiseOptions = useCallback(
    (inputValue: string) => {
      if (inputValue.length <= 2) {
        return Promise.reject();
      }

      return api
        .get<Array<AsyncSelectChoice>>(`${dataUrl}?search=${inputValue}`)
        .then((response) => {
          return Promise.resolve(
            response.data.map((responseOption) => {
              return {
                value: responseOption.id,
                label:
                  labelField && responseOption?.[labelField]
                    ? responseOption?.[labelField]
                    : responseOption.name
              };
            }) as unknown as Options<Option> // ts: satisfy loadOptions on AsyncSelect
          );
        });
    },
    [dataUrl, labelField]
  );

  return creatable ? (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      formatCreateLabel={formatCreateLabel}
      loadOptions={promiseOptions}
      {...otherProps}
      isDisabled={loadingInitialValue || isDisabled}
      value={value}
      onChange={handleOnChange}
      onCreateOption={handleCreateOption}
      onInputChange={handleInputChange}
    />
  ) : (
    <ReactAsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      {...otherProps}
      isDisabled={loadingInitialValue || isDisabled}
      value={value}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
    />
  );
}

export { AsyncSelect, AsyncSelectProps };
