import { ActionIcon, Tooltip } from "@mantine/core";
import { EditIcon } from "../../../../components/Buttons/EditIcon";

interface EditButtonProps {
  disabled?: boolean;
  label?: string;
  onClick: () => void;
}

function EditButton({
  disabled,
  label = "Bearbeiten",
  onClick
}: EditButtonProps) {
  return (
    <Tooltip label={label}>
      <ActionIcon
        aria-label={label}
        disabled={disabled}
        variant="transparent"
        onClick={onClick}
      >
        <EditIcon disabled={disabled} />
      </ActionIcon>
    </Tooltip>
  );
}

export { EditButton, EditButtonProps };
