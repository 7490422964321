import { useState } from "react";
import { type UseFormSetValue, type UseFormWatch } from "react-hook-form";
import { AlertColor } from "../../../Alert/Alert";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import type {
  Meter,
  MeterTableErrors,
  MeterWithSource,
  SiteSetupProcessForForm
} from "../../SiteSetupAssistant.types";
import { MeterTable } from "../CustomFields/MeterTable/MeterTable";

interface MeterFormItemsProps {
  watch: UseFormWatch<SiteSetupProcessForForm>;
  onBlockNavigation: (blocked: boolean) => void;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
  meterTableErrors: MeterTableErrors | null;
}

function MeterFormItems({
  meterTableErrors,
  watch,
  onBlockNavigation,
  onSetFormValue
}: MeterFormItemsProps) {
  const pvPlants = watch("pvPlants");
  const consumers = watch("consumers");
  const batteryStorage = watch("batteryStorage");
  const connectionName = watch("connectionName");
  const connectionMeasurementType = watch("connectionMeasurementType");
  const connectionMeteringDirection = watch("connectionMeteringDirection");
  const connectionMeteringServiceProvider = watch(
    "connectionMeteringServiceProvider"
  );
  const connectionMeteringLocation = watch("connectionMeteringLocation");
  const connectionMarketLocationFeedin = watch(
    "connectionMarketLocationFeedin"
  );
  const connectionMarketLocationFeedout = watch(
    "connectionMarketLocationFeedout"
  );
  const connectionNumber = watch("connectionNumber");
  const connectionIsCalibrated = watch("connectionIsCalibrated");

  const pvPlantMeters: Array<MeterWithSource> = pvPlants.map(
    (pvPlant, index) => ({
      ...pvPlant.meter,
      source: "pvPlant",
      parentIndex: index
    })
  );
  const connectionMeter: MeterWithSource = {
    name: connectionName,
    measurement_type: connectionMeasurementType,
    metering_direction: connectionMeteringDirection,
    metering_service_provider: connectionMeteringServiceProvider,
    metering_location: connectionMeteringLocation,
    market_location_feedin: connectionMarketLocationFeedin,
    market_location_feedout: connectionMarketLocationFeedout,
    number: connectionNumber,
    is_calibrated: connectionIsCalibrated,
    source: "connection",
    parentIndex: 0
  };
  const batteryStorageMeter: MeterWithSource | undefined = batteryStorage?.meter
    ? {
        ...batteryStorage.meter,
        source: "batteryStorage",
        parentIndex: 0
      }
    : undefined;
  const consumerMeters: Array<MeterWithSource> = consumers.map(
    (consumer, index) => ({
      ...consumer.meter,
      source: "consumer",
      parentIndex: index
    })
  );

  const [meters, setMeters] = useState<Array<MeterWithSource>>(
    batteryStorageMeter
      ? [
          connectionMeter,
          batteryStorageMeter,
          ...pvPlantMeters,
          ...consumerMeters
        ]
      : [connectionMeter, ...pvPlantMeters, ...consumerMeters]
  );

  function handleChange(updatedMeters: Array<MeterWithSource>) {
    const changedMeter = updatedMeters.find((meter) =>
      Object.keys(meter).some(
        (key) => meter[key] !== meters.find((m) => m.name === meter.name)?.[key]
      )
    );

    if (!changedMeter) {
      return;
    }

    const meterWithoutSource = Object.keys(changedMeter)
      .filter((key) => key !== "source" && key !== "parentIndex")
      .reduce(
        (result, key) => ({
          ...result,
          [key]: changedMeter[key] === "" ? null : changedMeter[key]
        }),
        {}
      ) as Meter;

    if (changedMeter.source === "pvPlant") {
      const updatedPvPlants = pvPlants;
      updatedPvPlants.splice(changedMeter.parentIndex, 1, {
        ...pvPlants[changedMeter.parentIndex],
        meter: meterWithoutSource
      });
      onSetFormValue("pvPlants", updatedPvPlants);
    }
    if (changedMeter.source === "connection") {
      onSetFormValue(
        "connectionMeasurementType",
        changedMeter.measurement_type
      );
      onSetFormValue(
        "connectionMeteringLocation",
        changedMeter.metering_location
      );
      onSetFormValue(
        "connectionMarketLocationFeedin",
        changedMeter.market_location_feedin
      );
      onSetFormValue(
        "connectionMarketLocationFeedout",
        changedMeter.market_location_feedout
      );
      onSetFormValue(
        "connectionLocationType",
        changedMeter.metering_location
          ? "Messlokation"
          : changedMeter.market_location_feedin ||
              changedMeter.market_location_feedout
            ? "Marktlokation"
            : "Keine"
      );
      onSetFormValue("connectionNumber", changedMeter.number);
      onSetFormValue("connectionIsCalibrated", changedMeter.is_calibrated);
    }
    if (changedMeter.source === "consumer") {
      const updatedConsumers = consumers;
      updatedConsumers.splice(changedMeter.parentIndex, 1, {
        ...consumers[changedMeter.parentIndex],
        meter: meterWithoutSource
      });
      onSetFormValue("consumers", updatedConsumers);
    }
    if (changedMeter.source === "batteryStorage") {
      onSetFormValue(
        "batteryStorage",
        batteryStorage ? { ...batteryStorage, meter: meterWithoutSource } : null
      );
    }

    setMeters(updatedMeters);
  }

  return (
    <div className="MeterFormItems">
      <IconAlert color={AlertColor.Info}>
        Bitte tragen Sie hier für jede PV-Anlage und jeden Verbraucher die
        Informationen über den zugehörigen Zähler ein. Die Identifikationsnummer
        ist notwendig, um einen Zähler eindeutig identifizieren zu können.
        Sowohl für den Datenempfang (gilt nur für Messlokation und
        Marktlokation), als auch für das Ausweisen der Energiemengen auf der
        Rechnung. <br /> <br />
        Mehrfachnennungen einer Identifikationsnummer (Melo, Malo, Zählernummer)
        sind möglich. Die gleiche Identifikationsnummer führt dazu, dass die
        Verbraucher (PV-Anlagen) dem gleichen Zähler zugeordnet werden, also
        gemeinsam über einen Zähler gemessen werden.
      </IconAlert>
      <MeterTable
        meters={meters}
        meterTableErrors={meterTableErrors}
        onBlockNavigation={onBlockNavigation}
        onChange={handleChange}
      />
    </div>
  );
}

export { MeterFormItems };
