import type {
  FormatToErrorObject,
  SiteSetupProcessForForm,
  TenantTableErrors
} from "../SiteSetupAssistant.types";

export function formatConsumerErrorsToTenantTableErrors(
  errors: FormatToErrorObject<SiteSetupProcessForForm["consumers"]>
): TenantTableErrors {
  if (!errors) return [];
  return errors.map((consumer) => {
    return {
      tenantName: consumer?.tenant?.name,
      name: consumer?.name,
      tenantAddress: consumer?.tenant?.address,
      type: consumer?.consumer_type,
      billingAddressIsSameAsAddress:
        consumer?.tenant?.billing_address_is_same_as_address,
      isParticipatingInMieterstrom:
        consumer?.tenant?.is_participating_in_mieterstrom
    };
  });
}
