import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { numericFormatter } from "react-number-format";
import { ControlledMantineEditNumberInput } from "../../../../../mantine/mrt/components/edit/ControlledMantineEditNumberInput";
import { isMrtCreating } from "../../../../../mantine/mrt/utils/isMrtCreating";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import type { ValidationErrors } from "../../../../../types/inlineEditTable.types";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";

const HELP_TEXT = `Der Aufteilungsfaktor muss zwischen 0 und 1 liegen.\n\nAndere Eingaben werden automatisch korrigiert.`;

interface WeightingColumnProps {
  validationErrors: ValidationErrors;
  overrideShareValue?: string;
}

export function getWeightingColumn<T extends MRT_RowData>({
  validationErrors,
  overrideShareValue
}: WeightingColumnProps): MRT_ColumnDef<T> {
  return {
    accessorKey: "weighting",
    header: "Aufteilungsfaktor",
    Header: (
      <span className="header-with-help-icon">
        Aufteilungsfaktor
        <IconHelpText helpText={HELP_TEXT} />
      </span>
    ),
    enableSorting: true,
    Cell: ({ cell }) => {
      const value = cell.getValue<string | null | undefined>() ?? "";
      return numericFormatter(value, {
        decimalSeparator: ",",
        thousandSeparator: "."
      });
    },
    Edit: ({ cell, column, row, table }) => {
      const shouldOverrideValue = isMrtCreating(table);
      return (
        <ControlledMantineEditNumberInput
          cell={cell}
          columnId={column.id}
          errorText={validationErrors?.weighting}
          label="Aufteilungsfaktor"
          max={1}
          min={0}
          overrideValue={shouldOverrideValue ? overrideShareValue : undefined}
          placeholder="z.B. 0,5"
          row={row}
          onKeyDown={(event) => submitOrCancelOnKeyPress(event, table)}
        />
      );
    }
  };
}
