import { type MRT_ColumnDef, type MRT_RowData } from "mantine-react-table";
import { ErrorCell } from "../../TenantTable/ErrorCell/ErrorCell";

export function getNameColumn<T extends MRT_RowData>(
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof T
  ) => boolean | null | undefined
): MRT_ColumnDef<T> {
  return {
    accessorKey: "name",
    header: "Was wird gemessen?",
    enableEditing: false,
    Cell: ({ row }) => {
      const hasError = hasCellError(row.index, "name");
      return (
        <div>
          {row.original.name}
          {hasError && <ErrorCell />}
        </div>
      );
    }
  };
}
