import { useState } from "react";
import { useParams } from "react-router-dom";
import { useManagerVariant } from "../../hooks/useManagerVariant";
import { useSitesDetail } from "../../hooks/useSitesDetail";
import { EditIcon } from "../Buttons/EditIcon";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SiteModal } from "../MsmProjectView/Sites/SiteModal";
import "./SiteAddress.scss";

interface SiteAddressProps {
  siteId: number;
}

function SiteAddress({ siteId }: SiteAddressProps) {
  const {
    data: site,
    error: siteError,
    isLoading: siteIsLoading
  } = useSitesDetail(siteId);

  const { projectId } = useParams();
  const { variantId } = useManagerVariant(projectId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="SiteAddress" data-testid="site-address">
      <LoadOrError error={siteError} loading={siteIsLoading}>
        {variantId && site && (
          <>
            <h3>{site.name}</h3>
            <div>
              {formatAddress({
                street: site.address,
                city: site.city,
                zip: site.zip
              })}
              <EditIcon onClick={() => setIsModalOpen(true)} />
            </div>
            <SiteModal
              headerText="Liegenschaft bearbeiten"
              isOpen={isModalOpen}
              site={site}
              variantId={variantId}
              onToggle={() => setIsModalOpen(false)}
            />
          </>
        )}
      </LoadOrError>
    </div>
  );
}

type Address = {
  street?: string;
  city?: string;
  zip?: string;
};

function formatAddress(address: Address) {
  if (address.street && address.zip && address.city) {
    return address.street + ", " + address.zip + " " + address.city;
  } else if (address.street && address.zip) {
    return address.street + ", " + address.zip;
  } else if (address.street && address.city) {
    return address.street + ", " + address.city;
  } else if (address.zip && address.city) {
    return address.zip + " " + address.city;
  } else if (address.street) {
    return address.street;
  } else if (address.zip) {
    return address.zip;
  } else if (address.city) {
    return address.city;
  } else {
    return "Keine Adresse angegeben";
  }
}

export { SiteAddress, SiteAddressProps };
