import type { FormEvent } from "react";
import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";

import type { ApiError } from "../../api";
import { ROUTES } from "../../routes";
import { Alert, AlertColor } from "../Alert/Alert";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { FormItem } from "../DynamicForm/FormItem/FormItem";
import { FormFieldType } from "../DynamicForm/FormItems/FormField/FormField";
import { PasswordResetLayout } from "../Layout/PasswordResetLayout";
import { PublicLayout } from "../Layout/PublicLayout";
import { checkAuth } from "../Login/utils/checkAuth";
import { resetPassword } from "./utils/resetPassword";

import "./PasswordReset.scss";

function PasswordReset() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const emailSent = searchParams.get("emailSent");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | Array<string> | null>(
    null
  );
  const [passResetInProgress, setPassResetInProgress] =
    useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const redirectUrl = useMemo(
    () => searchParams.get("next") ?? ROUTES.productSelectPage,
    [searchParams]
  );

  useEffect(() => {
    const prepareRender = async () => {
      const hasAccess = await checkAuth(redirectUrl);

      setIsReady(hasAccess);
    };

    prepareRender();
  }, [redirectUrl]);

  if (!isReady) return null;

  const handleResetPass = async (e: FormEvent) => {
    e.preventDefault();
    setPassResetInProgress(true);

    try {
      await resetPassword(email);
      navigate(location.pathname + "?emailSent=true");
    } catch (e) {
      setEmailError(
        (e as ApiError<Record<string, Array<string>>>).response?.data?.[
          Object.keys(e.response?.data)?.[0]
        ] || ""
      );
    } finally {
      setPassResetInProgress(false);
    }
  };

  return (
    <PublicLayout>
      <PasswordResetLayout
        description={
          emailSent
            ? "Wenn für Ihre E-Mail-Adresse ein Account existiert, haben wir Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet. Falls Sie diese Mail nicht erhalten haben, stellen Sie bitte sicher, dass Sie die Adresse richtig eingeben und prüfen Sie Ihren Spam-Ordner."
            : "Falls Sie Ihr Passwort vergessen haben, geben Sie bitte Ihre E-Mail-Adresse ein. Sie erhalten eine E-Mail mit Anweisungen, um das Passwort zurückzusetzen."
        }
        title={emailSent ? "E-Mail versendet" : "Passwort vergessen"}
      >
        {!emailSent && (
          <Form className="PasswordResetForm" onSubmit={handleResetPass}>
            <FormGroup className="form-group">
              <FormItem
                initialValue=""
                label="E-Mail-Adresse"
                name="email"
                required
                type={FormFieldType.Email}
                value={email}
                onInput={(_, value: string) => setEmail(value)}
              />

              {emailError && (
                <Alert color={AlertColor.Danger}>
                  {Array.isArray(emailError) ? (
                    emailError.map((err) => (
                      <>
                        <span key={err}>{err}</span>
                        <br />
                      </>
                    ))
                  ) : (
                    <span>{emailError}</span>
                  )}
                </Alert>
              )}

              <div className="SubmitBtnWrapper">
                <SpinButton
                  className="PasswordSubmitBtn"
                  color="brand"
                  disabled={!email}
                  spin={passResetInProgress}
                >
                  Passwort zurücksetzen
                </SpinButton>
              </div>
            </FormGroup>
          </Form>
        )}
      </PasswordResetLayout>
    </PublicLayout>
  );
}

export { PasswordReset };
