import type { ExpressionEditorContentProps } from "./ExpressionEditorPreview";

function parseVariablesIntoMentions(
  value: string | null | undefined,
  variables: Array<string>
) {
  if (!value) {
    return "";
  }

  let enrichedValue = value;
  for (const variable of variables) {
    enrichedValue = enrichedValue.replace(
      new RegExp(`\\b${variable}\\b`, "g"),
      // note that the class will be overriden inside the actual editor by the `renderHTML` call below
      // but we also want to use the same class to show the field value even before the editor is rendered
      `<span class="expression-variable" data-type="mention" data-id="${variable}">${variable}</span>`
    );
  }

  return enrichedValue;
}

/** this is what you see in editmode
 * if both should be the same, remember to update both
 */
function getExpressionEditorContent({
  value,
  variables
}: ExpressionEditorContentProps) {
  const htmlEnrichedValue = parseVariablesIntoMentions(
    value,
    variables
  ).replace(/(\r\n|\r|\n)/g, "<br/>");

  return htmlEnrichedValue;
}

export { getExpressionEditorContent };
