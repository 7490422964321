import { useReactFlow } from "@xyflow/react";
import classnames from "classnames";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useNav } from "../../../../hooks/useNav";
import { useSitesDetail } from "../../../../hooks/useSitesDetail";
import { useSiteWizard } from "../../../../hooks/useSiteWizard";
import urls from "../../../../urls";
import { pollTaskStatus } from "../../../../utils/api-utils";
import type { SiteWizard } from "../../../../utils/backend-types";
import { THEME_VARS } from "../../../../utils/constants";
import { showToast } from "../../../../utils/toast";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { StructureViewModeContext } from "../../../StructureView/StructureViewModeContext";
import { createMeteringConceptPdf } from "../../../StructureView/utils/createMeteringConceptPdf";
import {
  getOnboardingTodoLabel,
  OnboardingTodo
} from "../../../Todos/onboarding-todos";
import { AssignmentType } from "../OnboardingWizard";
import { ConfigurationAssignment } from "./ConfigurationAssignment/ConfigurationAssignment";
import { InvoiceAssignment } from "./InvoiceAssignment/InvoiceAssignment";
import { MeteringConceptAssignment } from "./MeteringConceptAssignment/MeteringConceptAssignment";
import "./onboardingAssignment.scss";
import { OptiNodeAssignment } from "./OptiNodeAssignment/OptiNodeAssignment";
import { RegulatoryDataAssignment } from "./RegulatoryDataAssignment/RegulatoryDataAssignment";
import { VollmachtAssignment } from "./VollmachtAssignment/VollmachtAssignment";

export interface OnboardingAssignmentProps {
  siteWizard: SiteWizard;
  type: AssignmentType;
  isNext: boolean;
  isDone: boolean;
  projectId?: string;
  onSiteWizardUpdated: () => Promise<void>;
}

function OnboardingAssignment({
  siteWizard,
  type,
  isNext,
  isDone,
  projectId,
  onSiteWizardUpdated
}: OnboardingAssignmentProps) {
  const [assignmentIsLoading, setAssignmentIsLoading] =
    useState<AssignmentType | null>(null);
  const navigate = useNav();
  const { structureViewMode, updateStructureViewMode } = useContext(
    StructureViewModeContext
  );
  const { updateOnboardingPhaseDoneStatus } = useSiteWizard(siteWizard.id);
  const { getNodes } = useReactFlow();

  const { data: siteDetails } = useSitesDetail(siteWizard.id);

  async function updateSiteWizard(type: AssignmentType) {
    setAssignmentIsLoading(type);
    let msbAuthorityDone = siteWizard.phases.msbauthority.done;
    let regulatoryDone = siteWizard.phases.regulatory.done;
    let configurationDone = siteWizard.phases.configuration.done;
    let invoicesDone = siteWizard.phases.invoices.done;
    let meteringConceptDone = siteWizard.phases.meteringConcept.done;
    const currentYear = DateTime.now().year;
    let phase: string = "";

    switch (type) {
      case AssignmentType.Configuration:
        phase = getOnboardingTodoLabel(
          OnboardingTodo.KONFIGURATION_PRUEFEN,
          currentYear
        );
        configurationDone = true;
        break;
      case AssignmentType.MeteringConcept:
        createMeteringConceptPdf(
          siteDetails,
          siteWizard.id,
          structureViewMode,
          updateStructureViewMode,
          getNodes
        );
        phase = getOnboardingTodoLabel(
          OnboardingTodo.MESSKONZEPT_UND_ZAEHLERKONFIGURATION_DATEN_VERVOLLSTAENDIGEN,
          currentYear
        );
        meteringConceptDone = true;
        break;
      case AssignmentType.Regulatory:
        phase = getOnboardingTodoLabel(
          OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
          currentYear
        );
        regulatoryDone = true;
        break;
      case AssignmentType.Vollmacht:
        phase = getOnboardingTodoLabel(
          OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
          currentYear
        );
        msbAuthorityDone = true;
        break;
      case AssignmentType.Invoices:
        phase = getOnboardingTodoLabel(
          OnboardingTodo.LIEFERKONDITIONEN_HINTERLEGEN_STROMLIEFERUNG_UND_ABRECHNUNG,
          currentYear
        );
        invoicesDone = true;
        break;
      default:
        console.error(`Assignment type: ${type} not implemented`);
        break;
    }

    await updateOnboardingPhaseDoneStatus(phase, true)
      .catch(() => {
        showToast("error", "Fehler beim aktualisieren der Daten.");
      })
      .then((res) => {
        if (type === AssignmentType.MeteringConcept && res?.data) {
          const taskId: string = res.data.taskIds[0];
          const onMissing = () => {
            showToast(
              "error",
              "Fehler beim Erstellen der Messkonzept-Dokumente."
            );
            setAssignmentIsLoading(null);
          };
          pollTaskStatus(
            urls.api.jobStatus(taskId),

            //onSuccess
            () => {
              showToast("success", "Messkonzept Dokumente erstellt.");
              setAssignmentIsLoading(null);
            },

            //onError
            onMissing,

            //onMissing
            onMissing
          );
        }
        onSiteWizardUpdated();
        if (
          configurationDone &&
          regulatoryDone &&
          msbAuthorityDone &&
          (!siteWizard.phases.invoices.displayPhase || invoicesDone) &&
          (!siteWizard.phases.meteringConcept.displayPhase ||
            meteringConceptDone)
        ) {
          navigate("./on-stage-abgeschlossen");
        }
      })
      .finally(() => {
        if (type !== AssignmentType.MeteringConcept) {
          setAssignmentIsLoading(null);
        }
      });
  }

  function getAssignmentComponent() {
    switch (type) {
      case AssignmentType.MeteringConcept:
        return (
          <MeteringConceptAssignment
            done={siteWizard.phases.meteringConcept.done}
            isDisabled={!siteWizard.phases.configuration.done}
            isLoading={assignmentIsLoading === AssignmentType.MeteringConcept}
            isNext={isNext}
            missingData={siteWizard.phases.meteringConcept.missingData}
            siteId={siteWizard.id}
            updateSiteWizard={updateSiteWizard}
          />
        );
      case AssignmentType.Configuration:
        return (
          <ConfigurationAssignment
            done={siteWizard.phases.configuration.done}
            isNext={isNext}
            missingData={siteWizard.phases.configuration.missingData}
            projectId={projectId}
            siteId={siteWizard.id}
            updateSiteWizard={updateSiteWizard}
          />
        );
      case AssignmentType.Regulatory:
        return (
          <RegulatoryDataAssignment
            done={siteWizard.phases.regulatory.done}
            isDisabled={!siteWizard.phases.configuration.done}
            isNext={isNext}
            missingData={siteWizard.phases.regulatory.missingData}
            updateSiteWizard={updateSiteWizard}
          />
        );
      case AssignmentType.Vollmacht:
        return (
          <VollmachtAssignment
            done={siteWizard.phases.msbauthority.done}
            hasMissingDocuments={
              siteWizard.phases.msbauthority.hasMissingDocuments
            }
            isDisabled={!siteWizard.phases.configuration.done}
            isNext={isNext}
            missingData={siteWizard.phases.msbauthority.missingData}
            siteId={siteWizard.id}
            updateSiteWizard={updateSiteWizard}
            onSiteWizardUpdated={onSiteWizardUpdated}
          />
        );
      case AssignmentType.Invoices:
        return (
          <InvoiceAssignment
            done={siteWizard.phases.invoices.done}
            isDisabled={!siteWizard.phases.configuration.done}
            isNext={isNext}
            missingData={siteWizard.phases.invoices.missingData}
            objectsAreMissing={siteWizard.phases.invoices.objectsAreMissing}
            projectId={projectId}
            updateSiteWizard={updateSiteWizard}
          />
        );
      case AssignmentType.OptiNode:
        return (
          <OptiNodeAssignment
            done={siteWizard.onboardingDone.onboardingDone}
            isNext={isNext}
            mbkTodoDone={siteWizard.onboardingDone.todoStatus.mbkTodo}
            msbDataTodoDone={siteWizard.onboardingDone.todoStatus.msbDataTodo}
            regulatoryTodoDone={
              siteWizard.onboardingDone.todoStatus.regulatoryTodo
            }
            siteId={siteWizard.id}
            onSiteWizardUpdated={onSiteWizardUpdated}
          />
        );
      default:
        console.error(`Assignment type: ${type} not implemented`);
        return null;
    }
  }
  return (
    <div
      className={classnames("onboarding-assignment-item", {
        "onboarding-assignment-item--next": isNext
      })}
    >
      <div
        className={classnames("next-assignment-title", {
          "next-assignment-title-inactive": !isNext
        })}
      >
        <span>{type}</span>

        {isDone && (
          <Icon
            className="check-circle-icon"
            data-testid="onboarding-assignment-done-icon"
            name={IconName.CheckCircle}
            style={{ fontSize: "1.5rem", color: THEME_VARS.brandColor }}
          />
        )}
      </div>

      {getAssignmentComponent()}
    </div>
  );
}

export { OnboardingAssignment };
