import { DateTime } from "luxon";
import { DatePickerCell } from "./DatePickerCell";

interface EndDateCellProps {
  endDate: DateTime | null;
  startDate: DateTime | null;
  placeholder?: string;
  restrictFutureDates?: boolean;
  onChange: (newEndDate: DateTime | null) => void;
}

function EndDateCell({
  endDate,
  startDate,
  placeholder = "Aktuell",
  restrictFutureDates,
  onChange
}: EndDateCellProps) {
  const firstPossibleDate = startDate ?? undefined;
  const lastPossibleDate = restrictFutureDates
    ? DateTime.now().minus({ days: 1 })
    : undefined;

  return (
    <DatePickerCell
      firstPossibleDate={firstPossibleDate}
      lastPossibleDate={lastPossibleDate}
      placeholder={!endDate ? placeholder : undefined}
      value={endDate ?? undefined}
      onChange={onChange}
    />
  );
}

export { EndDateCell };
