import { useContainerRef } from "../../../../hooks/useContainerRef";
import type { Meter, Site } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import type { EnergyDataUploadPartialResult } from "../../EnergyDataUploadFlow/EnergyDataUploadFlow";
import { EnergyDataUploadFlow } from "../../EnergyDataUploadFlow/EnergyDataUploadFlow";

interface EnergyDataUploadModalProps {
  isOpen: boolean;
  variantId: number;
  meters: Array<Meter>;
  sites: Array<Site>;
  defaultMeterId?: number;
  onToggle: () => void;
  onFileUploadStarted: (
    energyDataUploadPartialResults: Array<EnergyDataUploadPartialResult>
  ) => void;
}

function EnergyDataUploadModal({
  isOpen,
  variantId,
  meters,
  sites,
  defaultMeterId,
  onToggle,
  onFileUploadStarted
}: EnergyDataUploadModalProps) {
  const { containerNode, containerRef } = useContainerRef();

  function handleDone(
    energyDataUploadPartialResults: Array<EnergyDataUploadPartialResult>
  ) {
    if (energyDataUploadPartialResults) {
      onFileUploadStarted(energyDataUploadPartialResults);
    }

    onToggle();
  }

  return (
    <Modal
      className="EnergyDataUploadModal"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>Upload von Energiedaten</ModalHeader>
      <ModalBody scrollable>
        <EnergyDataUploadFlow
          buttonContainer={containerNode}
          defaultMeterId={defaultMeterId}
          meters={meters}
          sites={sites}
          variantId={variantId}
          onDone={handleDone}
        />
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons" ref={containerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { EnergyDataUploadModal };
