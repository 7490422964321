import { create } from "zustand";

interface ClearWarningStoreProps {
  clearWarnings: Record<string, boolean>;
  registerClearWarning: (name: string) => void;
  unregisterClearWarning: (name: string) => void;
  unregisterAllClearWarnings: () => void;
  setClearWarningAccepted: (name: string, isAccepted: boolean) => void;
  allClearWarningsAccepted: () => boolean;
}

const useClearWarningStore = create<ClearWarningStoreProps>((set, get) => ({
  clearWarnings: {},
  registerClearWarning: (name: string) =>
    set((state) => ({
      clearWarnings: {
        ...state.clearWarnings,
        [name]: false
      }
    })),
  unregisterClearWarning: (name: string) =>
    set((state) => {
      const { [name]: _, ...remainingWarnings } = state.clearWarnings;
      return { clearWarnings: remainingWarnings };
    }),
  unregisterAllClearWarnings: () => set({ clearWarnings: {} }),
  setClearWarningAccepted: (name: string, isAccepted: boolean) =>
    set((state) => ({
      clearWarnings: {
        ...state.clearWarnings,
        [name]: isAccepted
      }
    })),
  allClearWarningsAccepted: () => {
    const { clearWarnings } = get();
    return (
      Object.keys(clearWarnings).length === 0 ||
      Object.values(clearWarnings).every((isAccepted) => isAccepted)
    );
  }
}));

export { useClearWarningStore };
