import type { ObjectName } from "../../utils/enums";

export interface TooltipData {
  name: string;
  text: string;
  type: string;
  isConsumptionShareEditable?: boolean;
  isConsumptionShareConfirmed?: boolean;
}

export interface IconData {
  imageName: string;
  imageUrl: string;
}
export enum ConnectionPointDirection {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Right = "right"
}
export interface EdgeData {
  from: number;
  id: number;
  to: number;
  fromConnectionPoint?: ConnectionPointDirection;
  toConnectionPoint?: ConnectionPointDirection;
  active: boolean;
}

export interface NodeData {
  color?: string;
  data: {
    active?: boolean;
    color: string;
    componentId: number;
    type: ObjectName;
    meterBillingRelevantOrSubmeter?: string;
    meterPriorityDescription?: string;
  };
  id: number;
  imageUrl: string;
  label: string;
  shape?: string;
  size?: number;
  tooltipData: TooltipData;
  x: number;
  y: number;
  yRelative?: number;
  xRelative?: number;
  parent?: string;
}

export interface GraphNodePlus extends Node {
  active: boolean;
  componentId: number;
  componentType: ObjectName;
  person: { color?: string };
  tooltipData: TooltipData;
}

export interface SketchData {
  nodes: Array<NodeData>;
  edges: Array<EdgeData>;
  icons: Array<IconData>;
}
