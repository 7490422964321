import { useMutation } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import { type SiteSetupProcessRaw } from "../SiteSetupAssistant.types";

export function useSiteSetupAssistantCreation(processId: string | undefined) {
  const creationMutation = useMutation({
    mutationFn: (newData: SiteSetupProcessRaw) => createSite(newData, processId)
  });
  const validationMutation = useMutation({
    mutationFn: () => validateSite(processId)
  });
  const validatePlausibilityMutation = useMutation({
    mutationFn: () => validatePlausibility(processId)
  });

  return { creationMutation, validationMutation, validatePlausibilityMutation };
}

function validatePlausibility(processId: string | undefined) {
  if (!processId) {
    return Promise.reject();
  }
  return apiWithoutCamelization.get(
    urls.apiWithoutCamelization.siteSetupAssistant.validatePlausibilityMieterstromData(
      processId
    )
  );
}

function createSite(
  siteData: SiteSetupProcessRaw,
  processId: string | undefined
) {
  if (!processId) {
    return Promise.reject();
  }
  return queryWithPollResponse<string>(
    (taskId?: string) =>
      apiWithoutCamelization.post(
        urls.apiWithoutCamelization.siteSetupAssistant.creation(
          processId,
          taskId
        ),
        siteData
      ),
    {
      queryAgainOnPollSuccess: true,
      queryAgainOnPollSuccessWithTaskId: true
    }
  );
}
function validateSite(processId: string | undefined) {
  if (!processId) {
    return Promise.reject();
  }
  return apiWithoutCamelization.get(
    urls.apiWithoutCamelization.siteSetupAssistant.validateMissingMieterstromData(
      processId
    )
  );
}
