import { Group, Stack, Text } from "@mantine/core";
import { MantineCopyButton } from "../../../../mantine/components/MantineCopyButton/MantineCopyButton";
import { AlertColor } from "../../../Alert/Alert";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import "./OAuthApplicationSuccess.scss";

interface OAuthApplicationSuccessProps {
  clientId: string;
  clientSecret: string;
}

function OAuthApplicationSuccess({
  clientId,
  clientSecret
}: OAuthApplicationSuccessProps) {
  return (
    <Stack className="OAuthApplicationSuccess">
      <Text>Die OAuth-Applikation wurde erfolgreich registriert.</Text>

      <IconAlert color={AlertColor.Warning}>
        Client ID und Client Secret werden nach der Registrierung einmalig in
        diesem Dialog angezeigt. Bitte sichern Sie Client ID und Client Secret,
        bevor Sie diesen Dialog schließen.
      </IconAlert>

      <Group justify="space-between" wrap="nowrap">
        <Text>
          <strong>Client ID:</strong>
          <br />
          {clientId}
        </Text>
        <MantineCopyButton copyText="Client ID kopieren" value={clientId} />
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Text>
          <strong>Client Secret:</strong>
          <br /> {clientSecret}
        </Text>
        <MantineCopyButton
          copyText="Client Secret kopieren"
          value={clientSecret}
        />
      </Group>
    </Stack>
  );
}

export { OAuthApplicationSuccess, OAuthApplicationSuccessProps };
