import { useState } from "react";
import { Link } from "react-router-dom";

import type { WizardMissingData } from "../../../../../utils/backend-types";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { AssignmentType } from "../../OnboardingWizard";

import "./regulatoryDataAssignment.scss";

type RegulatoryDataAssignmentProps = {
  missingData: Array<WizardMissingData>;
  done: boolean;
  isNext: boolean;
  isDisabled: boolean;
  updateSiteWizard: (type: AssignmentType) => Promise<void>;
};

function RegulatoryDataAssignment({
  missingData,
  done,
  isNext,
  isDisabled,
  updateSiteWizard
}: RegulatoryDataAssignmentProps) {
  const [isSpinning, setIsSpinning] = useState(false);
  const hasMissingData = missingData && missingData.length > 0;
  function onClickSubmit() {
    setIsSpinning(true);
    updateSiteWizard(AssignmentType.Regulatory).then(() => {
      setIsSpinning(false);
    });
  }
  function getComponentByStep() {
    if (hasMissingData && !done) {
      return (
        <div className="missing-data-container">
          <Button
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            disabled={isDisabled}
            tag={Link}
            to={"regulatorischeDaten"}
          >
            Fehlende Daten ergänzen
          </Button>
        </div>
      );
    } else if (!done) {
      return (
        <div className="missing-data-container">
          <SpinButton
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            disabled={isDisabled}
            spin={isSpinning}
            onClick={() => onClickSubmit()}
          >
            Als erledigt markieren
          </SpinButton>
        </div>
      );
    }
  }
  return (
    <div className="regulatory-assignment-container">
      {getComponentByStep()}
    </div>
  );
}

export { RegulatoryDataAssignment };
