import { useCallback } from "react";

import type { MachineAccountConfiguration } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";

interface ReimportMachineAccountModalProps {
  machineAccount: MachineAccountConfiguration | null;
  onClose: () => void;
  onSubmit: (id: number) => void;
}

function ReimportMachineAccountModal({
  machineAccount,
  onClose,
  onSubmit
}: ReimportMachineAccountModalProps) {
  const handleSubmit = useCallback(() => {
    if (!machineAccount) {
      return;
    }

    onSubmit(machineAccount.id);
    onClose();
  }, [machineAccount, onClose, onSubmit]);

  return (
    <Modal className="reimport-machine-account-modal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Machine Account Datei Re-Import
      </ModalHeader>
      <ModalBody>
        <p>{`Sind Sie sicher, dass Sie alle Dateien des Machine Accounts '${machineAccount?.email}' mit dem Parametersatz '${machineAccount?.parameterSetName}' erneut importieren wollen?`}</p>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button
            className="button-spacing"
            color="secondary"
            onClick={onClose}
          >
            Abbrechen
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Einsenden
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { ReimportMachineAccountModal };
