import classNames from "classnames";
import { PUBLIC_ROUTES } from "../../routes";
import { SiteName } from "../../utils/enums";

import "./FooterMenu.scss";

declare const BACKEND_BASE_URL: string | undefined;

const BASE_URL = BACKEND_BASE_URL || "";

export interface FooterMenuProps {
  siteName: SiteName;
  strongLinks?: boolean;
}

const links: {
  url: string;
  label: string;
  siteNames: ReadonlyArray<SiteName>;
}[] = [
  {
    url: PUBLIC_ROUTES.support,
    label: "Support",
    siteNames: [SiteName.Smarendo, SiteName.Optinode]
  },
  {
    url: "https://www.node.energy/legal/agb",
    label: "AGB",
    siteNames: [SiteName.Optinode]
  },
  {
    url: `${BASE_URL}/seiten/datenschutz`,
    label: "Datenschutz",
    siteNames: [SiteName.Smarendo]
  },
  {
    url: "https://www.node.energy/legal/avv",
    label: "Datenschutz",
    siteNames: [SiteName.Optinode]
  },
  {
    url: `${BASE_URL}/seiten/impressum`,
    label: "Impressum",
    siteNames: [SiteName.Smarendo, SiteName.Optinode]
  },
  {
    url: "https://www.roedl.de/",
    label: "Homepage",
    siteNames: [SiteName.Smarendo]
  },
  {
    url: "https://node.energy/",
    label: "Homepage",
    siteNames: [SiteName.Optinode]
  },
  {
    url: "https://www.alva-energie.de/impressum",
    label: "Impressum",
    siteNames: [SiteName.Alva]
  },
  {
    url: "https://www.alva-energie.de/datenschutz",
    label: "Datenschutz",
    siteNames: [SiteName.Alva]
  }
];

function FooterMenu({ siteName, strongLinks = false }: FooterMenuProps) {
  const wrapperClasses = classNames(
    "m-footer__nav",
    "m-nav",
    "m-nav--inline",
    "FooterMenu",
    { "footer-menu-strong-links": strongLinks }
  );

  return (
    <ul className={wrapperClasses}>
      {links
        .filter(({ siteNames }) => siteNames.includes(siteName))
        .map(({ url, label }) => (
          <li className="m-nav__item" key={label}>
            <a
              className="m-nav__link"
              href={url}
              rel="noreferrer"
              target="_blank"
            >
              <span className="footer-menu-label m-nav__link-text">
                {label}
              </span>
            </a>
          </li>
        ))}
    </ul>
  );
}

export { FooterMenu };
