import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { showToast } from "../../../utils/toast";
import { DeleteConfirmationModal } from "../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import type { SiteSetupProcess } from "../hooks/useSiteSetupProcesses";
import { useSiteSetupProcessMutations } from "../hooks/useSiteSetupProcessMutations";

function DeleteSiteSetupProcessModal({
  setupProcess,
  toggleModal,
  onSuccess
}: {
  setupProcess: SiteSetupProcess;
  toggleModal: () => void;
  onSuccess: () => void;
}) {
  const [allowDelete, setAllowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteMutation } = useSiteSetupProcessMutations();

  const modalBodyElement = (
    <DeleteSiteSetupProcessModalBodyElement
      name={setupProcess.name}
      onVerificationTextChange={setAllowDelete}
    />
  );

  async function deleteSiteProcess(setupProcessId: SiteSetupProcess["id"]) {
    setIsDeleting(true);
    try {
      await deleteMutation.mutateAsync(setupProcessId);
      onSuccess();
    } catch (error) {
      showToast("error", error);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <DeleteConfirmationModal
      className="DeleteSiteSetupProcessModal"
      isConfirmationBtnDisabled={!allowDelete}
      isLoading={isDeleting}
      isModalOpen
      modalBodyElement={modalBodyElement}
      name={setupProcess.name}
      toggleModal={toggleModal}
      onClickConfirm={() => deleteSiteProcess(setupProcess.id)}
    />
  );
}

interface DeleteSiteSetupProcessModalBodyElementProps {
  name: string;
  onVerificationTextChange: (isCorrect: boolean) => void;
}

function DeleteSiteSetupProcessModalBodyElement({
  name,
  onVerificationTextChange
}: DeleteSiteSetupProcessModalBodyElementProps) {
  const [value, setValue] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    onVerificationTextChange(event.target.value === name);
  }

  return (
    <div className="DeleteSiteSetupProcessModalBodyElement">
      <p>
        Sind Sie sicher, dass Sie diese Liegenschaft löschen möchten? Dieser
        Schritt kann nicht rückgängig gemacht werden.
      </p>
      <FormGroup>
        <Label for="confirm-delete">
          Geben Sie zur Bestätigung den Namen der Liegenschaft{" "}
          <mark>{name}</mark> in das Textfeld ein.
        </Label>
        <Input
          id="confirm-delete"
          type="text"
          value={value}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
}
export { DeleteSiteSetupProcessModal };
