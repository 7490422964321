import type { MRT_Cell, MRT_Row, MRT_RowData } from "mantine-react-table";
import { useCallback, useState } from "react";
import { MantineYesNoRadioGroup } from "../cell/MantineYesNoRadioGroup";

type ValueType = boolean | null;

interface ControlledMantineEditYesNoRadioGroupProps<T extends MRT_RowData> {
  cell: MRT_Cell<T, ValueType>;
  columnId: string;
  row: MRT_Row<T>;
  allowInput?: boolean;
  errorText?: string | undefined;
}

function ControlledMantineEditYesNoRadioGroup<T extends MRT_RowData>({
  cell,
  columnId,
  row,
  allowInput,
  errorText
}: ControlledMantineEditYesNoRadioGroupProps<T>) {
  const [value, setValue] = useState<ValueType>(cell.getValue() ?? null);

  const saveValueToRowCache = useCallback(
    (newValue: ValueType) => {
      //@ts-expect-error when using a generic type, the valuesCache is readonly
      row._valuesCache[columnId] = newValue;
    },
    [columnId, row]
  );

  function handleChange(newValue: ValueType) {
    setValue(newValue);
    saveValueToRowCache(newValue);
  }

  return (
    <MantineYesNoRadioGroup
      allowInput={allowInput}
      checked={value}
      errorText={errorText}
      onChange={handleChange}
    />
  );
}

export { ControlledMantineEditYesNoRadioGroup };
