import { Alert, Paper, Title } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { PureComponent } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { pathToLoggingTab } from "../../../AnalyzeView/AnalyzeView";
import { DecimalLabel } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import "./VariantComparisonHeader.scss";

export default class VariantComparisonHeader extends PureComponent {
  renderMovableHeaders = () => {
    const meteringConceptSize = this.props.meteringConceptKeys.length;
    return this.props.meteringConceptKeys.map((key, index) => {
      const config = this.props.meteringConceptConfiguration[key];
      const leftArrow =
        index > 0 && this.props.onMoveLeft ? (
          <ClickableArrowIcon
            direction="left"
            moveFunction={() => this.props.onMoveLeft(key)}
          />
        ) : (
          <span />
        );
      const rightArrow =
        index < meteringConceptSize - 1 && this.props.onMoveRight ? (
          <ClickableArrowIcon
            direction="right"
            moveFunction={() => this.props.onMoveRight(key)}
          />
        ) : (
          <span />
        );
      return (
        <Col
          className="col-header m--font-light flex-header"
          key={key}
          style={{ backgroundColor: config.headerBackgroundColor }}
        >
          <div className="header-moveable">
            {leftArrow}
            <span className="header-center">{config.header}</span>
            {rightArrow}
          </div>
        </Col>
      );
    });
  };

  renderStaticHeaders = () => {
    return this.props.meteringConceptKeys.map((key) => {
      const config = this.props.meteringConceptConfiguration[key];
      return (
        <Col
          className="col-header m--font-light"
          key={key}
          style={{ backgroundColor: config.headerBackgroundColor }}
        >
          <div style={{ textAlign: "center" }}>
            <span />
            <span className="header-center">{config.header}</span>
            <span />
          </div>
        </Col>
      );
    });
  };

  renderRow = (aggregateKey, labelConfiguration, yearlyAggregates) => {
    return this.props.meteringConceptKeys.map((key) => {
      const value =
        yearlyAggregates[key]?.[aggregateKey] !== undefined
          ? yearlyAggregates[key][aggregateKey]
          : "-";
      const backgroundColor =
        this.props.meteringConceptConfiguration[key].cellBackgroundColor;
      return (
        <Col
          className={`m--align-center background-color--${backgroundColor}`}
          key={key}
        >
          <div className="m-2">
            <DecimalLabel value={value} {...labelConfiguration} />
          </div>
        </Col>
      );
    });
  };

  render() {
    const {
      meteringConceptKeys,
      yearlyAggregates,
      meteringConceptConfiguration,
      isAnalyzeView
    } = this.props;
    const minWidth = `${320 * meteringConceptKeys.length}px`;

    const referenceValue = yearlyAggregates[meteringConceptKeys[0]];
    const yearlyAggegatesWithSavings = Object.keys(yearlyAggregates).reduce(
      (obj, key) => {
        if (referenceValue) {
          const referenceCosts = referenceValue.totalCosts;
          let savings = referenceCosts - yearlyAggregates[key].totalCosts;
          obj[key] = Object.assign(yearlyAggregates[key], { savings });
        } else {
          obj[key] = yearlyAggregates[key];
        }
        return obj;
      },
      {}
    );

    const renderHeaders =
      this.props.onMoveRight && this.props.onMoveLeft
        ? this.renderMovableHeaders
        : this.renderStaticHeaders;
    const configuration = [
      {
        rowLabel: "Gesamterlöse / -kosten",
        variable: "totalCosts",
        labelConfiguration: {
          suffix: " €",
          decimalScale: 0,
          convert: (value) => (isNaN(value) ? value : -value),
          fontDangerIfNegative: true
        }
      },
      {
        rowLabel: "Vorteil zu Basiskonzept",
        variable: "savings",
        labelConfiguration: {
          suffix: " €",
          decimalScale: 0,
          fontDangerIfNegative: true
        }
      },
      {
        rowLabel: "Lieferungen innerhalb der Kundenanlage",
        variable: "localDelivery",
        labelConfiguration: {
          suffix: " kWh",
          decimalScale: 0
        }
      },
      {
        rowLabel: "Lokalversorgungsanteil",
        variable: "localShare",
        labelConfiguration: {
          suffix: " %",
          decimalScale: 1,
          convert: (value) => 100 * value
        }
      }
    ];

    const title = isAnalyzeView
      ? "Ergebnisübersicht"
      : "Ergebnisübersicht Variantenvergleich";

    return (
      <Row style={{ minWidth }}>
        <Col>
          <Paper mb="md" p={this.props.formatForReport ? 0 : undefined}>
            <Title variant="paper-header">{title}</Title>
            <Row>
              <Col style={{ fontSize: "15px" }}>
                <Row>
                  <Col className="col-header m--bg-brand m--font-light m--font-bolder m--align-center">
                    <div className="m-2">Zusammenfassung</div>
                  </Col>
                  {renderHeaders()}
                </Row>
                {configuration.map((configurationObject) => {
                  return (
                    <Row key={configurationObject.variable}>
                      <Col className="m-2">{configurationObject.rowLabel}</Col>
                      {this.renderRow(
                        configurationObject.variable,
                        configurationObject.labelConfiguration,
                        yearlyAggegatesWithSavings
                      )}
                    </Row>
                  );
                })}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col />
              {meteringConceptKeys.map((key) => {
                return meteringConceptConfiguration[key].showAlert ? (
                  <Col key={key}>
                    <WarningAlert
                      useRouterLink={isAnalyzeView}
                      variantId={key}
                    />
                  </Col>
                ) : (
                  <Col key={key} />
                );
              })}
            </Row>
          </Paper>
        </Col>
      </Row>
    );
  }
}

const ClickableArrowIcon = ({ direction, moveFunction }) => (
  <Icon
    className="icon-move-concept"
    name={direction === "left" ? IconName.AngleLeft : IconName.AngleRight}
    onClick={moveFunction}
  />
);

function WarningAlert({ variantId, useRouterLink }) {
  const { projectId } = useParams();
  const pathToLogs = pathToLoggingTab(projectId, variantId);
  const linkToLogs = useRouterLink ? (
    <Link to={pathToLogs}>Protokoll</Link>
  ) : (
    <a href={pathToLogs}>Protokoll</a>
  );
  return (
    <Alert color="yellow" icon={<IconExclamationCircle />}>
      Bei der Bewertung dieser Variante sind Warnungen aufgetreten. <br /> Sie
      können diese im {linkToLogs} einsehen.
    </Alert>
  );
}
