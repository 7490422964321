import { TodoStatus } from "../../../utils/backend-types";
import { CustomForm } from "../../CustomForm/CustomForm";
import FormItems from "../../DynamicForm/FormItems";
import { withOverdueDateFields } from "../../DynamicForm/FormItems/withOverdueDateFields";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { Mode, PRIORITIES } from "../common";

import "./TodoForm.scss";
import { getTodoStatusChoices } from "../utils/getTodoStatusChoices";

const FormItemsWithOverdueDateFields = withOverdueDateFields(FormItems);

function TodoForm({
  initialValues,
  persons,
  users,
  variantId,
  postUrl = undefined,
  patchUrl,
  mode,
  disableLabelEdit,
  onSubmit,
  onCancel,
  buttonContainer
}) {
  const isStaff = useShouldShowStaffView();
  const universal = mode === Mode.Universal;

  const formFields = [
    {
      id: "tf-label",
      name: "label",
      type: "string",
      label: "Bezeichnung",
      initialValue: "",
      required: true,
      readOnly: disableLabelEdit
    },
    {
      id: "tf-regulatory-duty",
      name: "regulatoryDuty",
      type: "regulatory-duty",
      label: "Regulatorische Pflicht",
      placeholder: "Keine regulatorische Pflicht",
      initialValue: null
    },
    {
      id: "tf-person",
      name: "person",
      type: "dropdown",
      label: "Verpflichtetes Unternehmen",
      choices: persons.map((person) => {
        return { value: person.id, displayName: person.name };
      }),
      initialValue: null
    },
    {
      id: "tf-due-date",
      name: "dueDate",
      type: "date",
      label: "Fälligkeit",
      initialValue: ""
    },
    {
      id: "tf-overdue",
      name: "overdue",
      type: "boolean",
      label: "Überfällig",
      initialValue: false
    },
    {
      id: "tf-responsible",
      name: "responsible",
      type: "dropdown",
      label: "Verantwortlich",
      choices: users.map((user) => {
        return { value: user.id, displayName: user.name };
      }),
      initialValue: null,
      readOnly: universal
    },
    {
      id: "tf-priority",
      name: "priority",
      type: "dropdown",
      label: "Priorität",
      choices: Object.values(PRIORITIES),
      initialValue: PRIORITIES.UNKNOWN.value,
      required: true
    },
    {
      id: "tf-status",
      name: "status",
      type: "dropdown",
      label: "Status",
      choices: getTodoStatusChoices(TodoStatus.Open, "", isStaff),
      initialValue: TodoStatus.Open,
      required: true
    }
  ];

  if (initialValues) {
    formFields.forEach(function (formField) {
      if (formField.name && initialValues[formField.name] !== null) {
        if (
          formField.name === "regulatoryDuty" &&
          initialValues["regulatoryDuty"]
        ) {
          // we need an exception here because the default data is the object, not the id
          formField.initialValue = initialValues["regulatoryDuty"].id;
        } else if (formField.name === "person") {
          // we only have the person name but we want it to be the id
          const person = persons.find(
            (person) => person.name === initialValues["person"]
          );
          formField.initialValue = person ? person.id : null;
        } else {
          formField.initialValue = initialValues[formField.name];
        }
      }
    });
  }

  const nonFieldData = {
    variant: variantId
  };

  const CustomFormItemsComponent = (props) => (
    <FormItemsWithOverdueDateFields {...props} />
  );

  return (
    <CustomForm
      allowInput={true}
      buttonContainer={buttonContainer}
      CustomFormItemsComponent={CustomFormItemsComponent}
      formFields={formFields}
      nonFieldData={nonFieldData}
      patchUrl={patchUrl}
      postUrl={postUrl}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

export { TodoForm };
