import classnames from "classnames";
import { useEffect, useState } from "react";
import type { Control, FieldError } from "react-hook-form";
import { MixKind } from "../../../../../../../types/api.types";
import type { Delivery } from "../../../../../../../utils/backend-types";
import { DeliveryType } from "../../../../../../../utils/backend-types";
import type { FormInputData } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { HelperText } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { DELIVERY_FORM_FIELD_DATA } from "../../DeliveryForm.constants";
import "./MixKindField.scss";

interface MixKindProps {
  deliveryType: DeliveryType;
  mixKindValue?: MixKind;
  control: Control<Delivery>;
  error?: FieldError;
  data: FormInputData<Delivery, "mixKind">;
}

const DIRECT_DELIVERY_HELP_TEXT =
  "Wählen Sie Grünstrom wenn Sie explizit Herkunftsnachweise (HKN) beim " +
  "Umweltbundesamt/Herkunftsnachweisregister für den direkt gelieferten PV-Strom erworben oder generiert " +
  "haben. Andernfalls ist für die PV-Lieferung die Option Graustrom zu wählen, denn das EnWG kennt für lokal " +
  "gelieferten Grünstrom ohne HKN nur diese Zuordnung.";

const FORWARDING_DELIVERY_HELP_TEXT =
  "Wählen Sie Graustrom, wenn Sie (oder Ihr Lieferant) Ihren Strom direkt von der Börse beziehen und dadurch " +
  "eine Zuordnung zu einzelnen Kraftwerken/Erzeugungstechnologien nicht möglich ist. Für Graustrom werden die " +
  "deutschen Durchschnittswerte in der Stromkennzeichnung verwendet. \n" +
  "\n" +
  "Wählen Sie Grünstrom wenn Sie von Ihrem Lieferanten/Stromversorger explizit Grünstrom beziehen. \n" +
  "\n" +
  "Insofern Ihr Versorger einen individuellen Strommix aufweist, bspw. weil er eigene Kraftwerke betreibt, " +
  "so können Sie unter individueller Mix diese Zusammensetzung eintragen.";

export function MixKindField({
  deliveryType,
  mixKindValue,
  control,
  error,
  data
}: MixKindProps) {
  const [index, setIndex] = useState(0);
  const mixKindFieldChoices = DELIVERY_FORM_FIELD_DATA.mixKind.choices;

  //This is a workaround for a known bug, caused by TsDropdown. It should be fixed in the future.
  useEffect(() => {
    setIndex((prev) => prev + 1);
  }, [mixKindValue]);

  function filterChoices() {
    if (deliveryType === DeliveryType.DirectDelivery) {
      return mixKindFieldChoices.filter(
        (choice) => choice.value !== MixKind.Individual
      );
    } else if (deliveryType === DeliveryType.ForwardingDelivery) {
      return mixKindFieldChoices.filter(
        (choice) => choice.value !== MixKind.SubsidisedTenantSupply
      );
    }
    return mixKindFieldChoices;
  }

  function getHelpText() {
    if (deliveryType === DeliveryType.DirectDelivery) {
      return DIRECT_DELIVERY_HELP_TEXT;
    } else if (deliveryType == DeliveryType.ForwardingDelivery) {
      return FORWARDING_DELIVERY_HELP_TEXT;
    }

    return undefined;
  }

  return (
    <div
      className={classnames("MixKind", {
        IndividualMixSelected: mixKindValue === MixKind.Individual
      })}
      key={index}
    >
      <FormFieldController
        control={control}
        data={
          {
            ...data,
            choices: filterChoices(),
            helpText: getHelpText()
          } as FormInputData<Delivery, "mixKind">
        }
      />
      <HelperText error={error} />
    </div>
  );
}
