import * as Sentry from "@sentry/browser";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HTTP_METHODS, useDownload } from "../../../hooks/useDownload";
import urls from "../../../urls";
import { showToast } from "../../../utils/toast";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { isDataStringResponse } from "../../EdaVisualizationModal/CreateEdaVersionModal/CreateEdaVersionModal";
import { ParkabrechnungContext } from "../ParkabrechnungContext";

import type { NetzbezugDownloadButtonProps } from "./ParkabrechnungDownloadButton.types";
import {
  BUTTON_COLOR_BRAND,
  DOWNLOAD_BUTTON_LABEL,
  DOWNLOAD_BUTTON_LABEL_PROJECT_OVERVIEW
} from "./ParkabrechnungDownloadButton.types";

export function NetzbezugDownloadButton({
  startDate,
  endDate,
  projectId
}: NetzbezugDownloadButtonProps) {
  const { poolContractId } = useContext(ParkabrechnungContext);
  const { t } = useTranslation();
  const { download, isLoading, error } = useDownload();
  let errorMessage = "";
  if (error) {
    if (isDataStringResponse(error)) {
      errorMessage = error.response.data;
    } else {
      errorMessage = t("errors.ServerError");
      console.error(error);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage);
    }
  }, [errorMessage]);

  return (
    <SpinButton
      color={BUTTON_COLOR_BRAND}
      spin={isLoading}
      onClick={() =>
        download({
          downloadUrl: urls.api.exportParkabrechnungNetzbezug({
            projectId,
            queryParams: {
              end_date: endDate,
              start_date: startDate,
              pool_contract_id: poolContractId
            }
          }),
          options: {
            method: HTTP_METHODS.GET
          }
        })
      }
    >
      {poolContractId
        ? DOWNLOAD_BUTTON_LABEL
        : DOWNLOAD_BUTTON_LABEL_PROJECT_OVERVIEW}
    </SpinButton>
  );
}
