import { Radio } from "@mantine/core";
import { RadioGroup } from "../../BuildingBlocks/Forms/RadioGroup/RadioGroup";

interface RadioChoice {
  label: string;
  value: string;
}

interface RadioFilterItemProps {
  choices: Array<RadioChoice>;
  radioName: string;
  filterValue: string;
  onChange: (value: string) => void;
}

function RadioFilterItem({
  choices,
  radioName,
  filterValue: value,
  onChange
}: RadioFilterItemProps) {
  return (
    <RadioGroup
      layout="horizontal"
      name={radioName}
      value={value}
      onChange={onChange}
    >
      {choices.map((choice) => (
        <Radio key={choice.value} label={choice.label} value={choice.value} />
      ))}
    </RadioGroup>
  );
}

export { RadioChoice, RadioFilterItem, RadioFilterItemProps };
