import {
  Card,
  Group,
  NavLink,
  NumberFormatter,
  RingProgress,
  Text,
  Title
} from "@mantine/core";
import type { DateTime } from "luxon";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import type { components } from "../../../types/api.types";
import { AlertColor } from "../../Alert/Alert";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import { Extendable } from "../../Extendable/Extendable";
import { TABS as ENERGY_DATA_TABS } from "../EnergyDataView/EnergyDataView";
import { LOCATION_ID_PREFIX } from "../EnergyDataView/utils/getLocationIdFromSelectedId";
import { METER_ID_PREFIX } from "../EnergyDataView/utils/getMeterIdFromSelectedId";
import "./EnergyDataQuality.scss";

export type DataQualityMeloMaloListItem =
  components["schemas"]["DataQualityMeloMaloListItem"];

interface EnergyDataQualityProps {
  qualityPercent: number;
  totalVolume: number;
  missingDataSources?: Array<DataQualityMeloMaloListItem>;
  selectedDateRange?: [DateTime<true>, DateTime<true> | null];
}

function EnergyDataQuality({
  qualityPercent,
  totalVolume,
  missingDataSources,
  selectedDateRange
}: EnergyDataQualityProps) {
  const numberOfLinksBeforeDropdown = 3;
  const numberOfLinksInsideDropdown =
    missingDataSources &&
    missingDataSources.length > numberOfLinksBeforeDropdown
      ? missingDataSources.length - numberOfLinksBeforeDropdown
      : 0;
  const dataSourcesBeforeDropdown =
    missingDataSources?.slice(0, numberOfLinksBeforeDropdown) || [];
  const dataSourcesInsideDropdown =
    missingDataSources?.slice(numberOfLinksBeforeDropdown) || [];

  if (isNaN(qualityPercent)) {
    return (
      <IconAlert color={AlertColor.Warning}>
        Es sind keine Daten verfügbar.
      </IconAlert>
    );
  }

  return (
    <Card className="EnergyDataQuality" withBorder>
      <Group justify="space-between">
        <Title mb="xs" order={4} size="h5">
          Datenqualität
        </Title>
        <Icon name={IconName.WindGenerator} />
      </Group>
      <Group align="flex-start" justify="space-between">
        <Group gap="sm">
          <RingProgress
            label={
              <>
                <Text size="xs" ta="center">
                  <span className="data-quality-percent">
                    {Math.floor(qualityPercent)}&nbsp;%
                  </span>
                  <br />
                  verfügbar
                </Text>
              </>
            }
            rootColor="#d7d7d7"
            sections={[
              {
                value: qualityPercent,
                color: "#9050f7"
              }
            ]}
            size={100}
            thickness={6}
          />
          <div>
            <Title fw="normal" order={6} size="xs">
              Menge kWh
            </Title>
            <Text size="lg">
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                value={totalVolume}
              />
            </Text>
          </div>
        </Group>
        <div className="data-quality-details">
          {dataSourcesBeforeDropdown.length > 0 ? (
            <>
              <Title fw="normal" mb="xs" order={6} size="xs">
                Fehlende Daten in
              </Title>

              {dataSourcesBeforeDropdown.map((src) => (
                <MissingDataSourceLink
                  dataSource={src}
                  key={src.meter_id || src.melo_malo_id}
                  selectedDateRange={selectedDateRange}
                />
              ))}

              {dataSourcesInsideDropdown.length > 0 && (
                <Extendable
                  moreLabel={`und ${numberOfLinksInsideDropdown} weitere`}
                >
                  {dataSourcesInsideDropdown.map((src) => (
                    <MissingDataSourceLink
                      dataSource={src}
                      key={src.meter_id || src.melo_malo_id}
                      selectedDateRange={selectedDateRange}
                    />
                  ))}
                </Extendable>
              )}
            </>
          ) : (
            <>
              <Icon
                className="data-quality-ok"
                name={IconName.RoundedCheckGreen}
              />
              Alle Daten vollständig
            </>
          )}
        </div>
      </Group>
    </Card>
  );
}

interface MissingDataSourceLinkProps {
  dataSource: DataQualityMeloMaloListItem;
  selectedDateRange?: [DateTime<true>, DateTime<true> | null];
}

function MissingDataSourceLink({
  dataSource,
  selectedDateRange
}: MissingDataSourceLinkProps) {
  const label =
    dataSource.site_name +
    " — " +
    (dataSource.meter_name ?? dataSource.melo_malo_name);

  const meterIdForEnergyDataLink = dataSource.meter_id
    ? `${METER_ID_PREFIX}${dataSource.meter_id}`
    : undefined;
  const locationIdForEnergyDataLink = dataSource.melo_malo_id
    ? `${LOCATION_ID_PREFIX}${dataSource.melo_malo_id}`
    : undefined;
  const selectedIdForEnergyDataLink =
    meterIdForEnergyDataLink || locationIdForEnergyDataLink;

  const dateUrlParams = selectedDateRange
    ? `firstDate=${selectedDateRange[0].toISODate()}&lastDate=${selectedDateRange[1]?.toISODate() || ""}&`
    : "";
  const urlParams = `?${dateUrlParams}selectedId=${selectedIdForEnergyDataLink}`;

  return (
    <NavLink
      component={Link}
      label={<Text size="xs">{label}</Text>}
      py="5"
      to={{
        pathname: generatePath(
          `${ROUTES.energyData}/${ENERGY_DATA_TABS.TAB_RAW_ENERGY_DATA}`,
          { projectId: dataSource.project_id }
        ),
        search: urlParams
      }}
    />
  );
}

export { EnergyDataQuality, EnergyDataQualityProps };
