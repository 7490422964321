import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import { buildFieldNameToLabelMap } from "../../../../../../utils/react-hook-form/buildFieldNameToLabelMap";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormBody } from "../../../../../BuildingBlocks/Forms/utils/FormBody";
import { FormContainer } from "../../../../../BuildingBlocks/Forms/utils/FormContainer";
import { FormFooter } from "../../../../../BuildingBlocks/Forms/utils/FormFooter";
import { setErrorsFromResponseData } from "../../../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import type { TabBarProps } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { Button, buttonColors } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";
import { HistoryTab } from "../../../../../HistoryTab/HistoryTab";
import { WizardTabBarComponent } from "../../../../../VariantObjectWizard/WizardTabBarComponent/WizardTabBarComponent";
import type {
  Paragraph6Company,
  Paragraph6ContractFormFields,
  Paragraph6ContractPayload,
  Paragraph6ContractResponse
} from "../../../../Paragraph6.types";
import {
  type PARAGRAPH_6_CONTRACT_FORM_TABS,
  PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA,
  TABS
} from "./Paragraph6ContractForm.constants";
import { Beteiligungen } from "./Tabs/Beteiligungen";
import { Fristen } from "./Tabs/Fristen";
import { GeneralInformation } from "./Tabs/GeneralInformation";

interface Paragraph6ContractFormProps {
  paragraph6Contract?: Partial<Paragraph6ContractResponse>;
  paragraph6Companies: Array<Paragraph6Company>;
  isStaff?: boolean;
  isSubmitting?: boolean;
  submissionError?: Error;
  onSubmit: SubmitHandler<Paragraph6ContractPayload>;
  onCancel: () => void;
}

const fieldNameToLabelMap = buildFieldNameToLabelMap(
  PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA
);

function Paragraph6ContractForm({
  paragraph6Contract,
  paragraph6Companies,
  isStaff,
  isSubmitting = false,
  submissionError,
  onSubmit,
  onCancel
}: Paragraph6ContractFormProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<PARAGRAPH_6_CONTRACT_FORM_TABS>(
    TABS[0].id
  );

  const paragraph6ContractFormFields: Partial<Paragraph6ContractFormFields> = {
    ...paragraph6Contract,
    has_split_beteiligung:
      !!paragraph6Contract?.beteiligung_nicht_erstattungsfaehig,
    has_fiktive_strommenge:
      !!paragraph6Contract?.beteiligung_fiktive_strommengen
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
    reset
  } = useForm<Paragraph6ContractFormFields>({
    defaultValues: paragraph6ContractFormFields ?? {}
  });

  useEffect(() => {
    if (submissionError) {
      setErrorsFromResponseData<Paragraph6ContractPayload>(
        submissionError,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  }, [setError, submissionError, t, watch]);

  function countErrorsForTab(tabId: `${PARAGRAPH_6_CONTRACT_FORM_TABS}`) {
    return Object.values(PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA)
      .filter((field) => field.group === tabId)
      .reduce((count, field) => (errors[field.name] ? count + 1 : count), 0);
  }

  const tabComponents = TABS.reduce((acc, tab) => {
    const numErrors = countErrorsForTab(tab.id);
    acc[tab.id] = (
      <WizardTabBarComponent numErrors={numErrors} title={tab.label} />
    );
    return acc;
  }, {}) as TabBarProps["tabComponents"];

  const editMode = !!paragraph6Contract?.id;

  return (
    <FormContainer>
      <form
        className="Paragraph6ContractForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormBody>
          <TabBar<PARAGRAPH_6_CONTRACT_FORM_TABS>
            activeTab={activeTab}
            routeless
            tabComponents={tabComponents}
            tabs={TABS.filter((tab) => editMode || !tab.hiddenInCreateMode)}
            onActivateTab={setActiveTab}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Object.values(TABS)[0].id}>
              <GeneralInformation
                control={control}
                errors={errors}
                paragraph6Companies={paragraph6Companies}
                paragraph6Contract={paragraph6Contract}
              />
            </TabPane>
            <TabPane tabId={Object.values(TABS)[1].id}>
              <Fristen control={control} errors={errors} />
            </TabPane>
            <TabPane tabId={Object.values(TABS)[2].id}>
              <Beteiligungen
                control={control}
                errors={errors}
                isStaff={isStaff}
                watch={watch}
              />
            </TabPane>
            <TabPane tabId={Object.values(TABS)[3].id}>
              <section>
                <FormFieldController
                  control={control}
                  data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.notes}
                  error={errors.notes}
                />
              </section>
            </TabPane>
            {paragraph6Contract?.id && (
              <TabPane tabId={Object.values(TABS)[4].id}>
                <HistoryTab
                  entityId={paragraph6Contract.id}
                  entityType="paragraph6Contract"
                  fieldNameToLabelMap={fieldNameToLabelMap}
                />
              </TabPane>
            )}
          </TabContent>
        </FormBody>
        <FormFooter>
          <Button
            color={buttonColors.secondary}
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            Abbrechen
          </Button>
          <SpinButton
            color={buttonColors.brand}
            spin={isSubmitting}
            type="submit"
          >
            Speichern
          </SpinButton>
        </FormFooter>
      </form>
    </FormContainer>
  );
}

export { Paragraph6ContractForm, Paragraph6ContractFormProps };
