import { Accordion } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/toast";
import { MultiConfirmationModal } from "../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import type {
  DirektvermarktungContractOverviewResponse,
  DirektvermarktungGeneratorShareFormValues
} from "../Direktvermarktung.types";
import { useDirektvermarktungContract } from "../hooks/useDirektvermarktungContract";
import { useDirektvermarktungContractMutations } from "../hooks/useDirektvermarktungContractMutations";
import { useDirektvermarktungGeneratorShareMutations } from "../hooks/useDirektvermarktungGeneratorShareMutations";
import { DirektvermarktungContractTitle } from "./DirektvermarktungContractTitle/DirektvermarktungContractTitle";
import { DirektvermarktungGeneratorShareTable } from "./DirektvermarktungGeneratorShareTable/DirektvermarktungGeneratorShareTable";
import "./DirektvermarktungContractInformation.scss";

type DirektvermarktungContractInformationProps = {
  contract: DirektvermarktungContractOverviewResponse;
  onClickEditContract: (contractId: string) => void;
  highlight?: string;
};

function DirektvermarktungContractInformation({
  contract,
  onClickEditContract,
  highlight
}: DirektvermarktungContractInformationProps) {
  const { t } = useTranslation();

  const {
    data: contractData,
    isLoading: direktvermarktungContractLoading,
    error: direktvermarktungContractError
  } = useDirektvermarktungContract(contract.id);

  const { direktvermarktungContractDeleteMutation } =
    useDirektvermarktungContractMutations();
  const direktvermarktungGeneratorShareMutations =
    useDirektvermarktungGeneratorShareMutations({
      contractUuid: contract.id
    });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  async function deleteContract() {
    const deletePromise = direktvermarktungContractDeleteMutation.mutateAsync(
      contract.id,
      {
        onError: (error) => {
          showToast("error", error);
        },
        onSettled: () => {
          setDeleteModalOpen(false);
        }
      }
    );

    return Promise.all([deletePromise]).then(() => {});
  }

  async function handleSaveGeneratorShare(
    generatorShareFormValues: DirektvermarktungGeneratorShareFormValues,
    generatorId?: number
  ) {
    const isLocal = typeof generatorId === "undefined";

    if (isLocal) {
      const response =
        await direktvermarktungGeneratorShareMutations.createMutation.mutateAsync(
          {
            formValues: generatorShareFormValues,
            contractId: contract.id
          }
        );

      if (response.status === 201) {
        showToast("success", "Ihr Erzeuger wurde erfolgreich gespeichert.");

        return Promise.resolve(response);
      }

      return Promise.reject(response);
    }

    const generatorShare = contractData?.generation_shares.find(
      (generatorShare) => generatorShare.generator_id === generatorId
    );

    if (!generatorShare) {
      console.error(
        "Could not find local generator share with id",
        generatorId
      );
      return Promise.reject(t("errors.ServerError"));
    }

    return direktvermarktungGeneratorShareMutations.editMutation.mutateAsync({
      formValues: generatorShareFormValues,
      contractId: contract.id,
      localGeneratorShare: generatorShare
    });
  }

  async function handleDeleteGeneratorShare(generatorShareId: number) {
    const response =
      await direktvermarktungGeneratorShareMutations.deleteMutation.mutateAsync(
        generatorShareId
      );

    if (response.status === 204) {
      showToast("success", "Ihr Erzeuger wurde erfolgreich gelöscht.");

      return Promise.resolve();
    }

    return Promise.reject();
  }

  return (
    <>
      <Accordion.Item
        className="DirektvermarktungContractInformation"
        value={contract.id}
      >
        <Accordion.Control>
          {contract && (
            <DirektvermarktungContractTitle
              generatorCount={contractData?.generation_shares?.length}
              highlight={highlight}
              id={contract.id}
              isLoading={direktvermarktungContractLoading}
              name={contract.name}
              onClickDelete={() => setDeleteModalOpen(true)}
              onClickEdit={() => onClickEditContract(contract.id)}
            />
          )}
        </Accordion.Control>
        <Accordion.Panel>
          <LoadOrError
            error={direktvermarktungContractError}
            loading={
              contractData?.operator_id === undefined &&
              direktvermarktungContractLoading
            }
            loadingMessage="Verträge werden geladen ..."
          >
            {contract && (
              <DirektvermarktungGeneratorShareTable
                contractUuid={contract.id}
                generatorShares={contractData?.generation_shares || []}
                onDelete={handleDeleteGeneratorShare}
                onSubmit={handleSaveGeneratorShare}
              />
            )}
          </LoadOrError>
        </Accordion.Panel>
      </Accordion.Item>
      {contract && (
        <MultiConfirmationModal
          actionName="löschen"
          actionObjects={[contract.name]}
          confirmationText="Ihr Vertrag kann anschließend nicht wiederhergestellt werden."
          headerText="Wollen Sie diesen Vertrag löschen?"
          isModalOpen={deleteModalOpen}
          objectName="Vertrag"
          toggleModal={() => setDeleteModalOpen(false)}
          onAction={deleteContract}
        />
      )}
    </>
  );
}

export {
  DirektvermarktungContractInformation,
  DirektvermarktungContractInformationProps
};
