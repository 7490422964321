import { ActionIcon, Loader, Paper, Stack, Tooltip } from "@mantine/core";
import {
  IconDownload,
  IconSquarePlus2,
  IconTextPlus
} from "@tabler/icons-react";
import "./CustomElementMenu.scss";

interface CustomElementMenuProps {
  onAddTextElement: () => void;
  onAddBoxElement: () => void;
  onDownloadGraph: () => void;
  siteDetailsLoading?: boolean;
}

function CustomElementMenu({
  onAddBoxElement,
  onAddTextElement,
  onDownloadGraph,
  siteDetailsLoading
}: CustomElementMenuProps) {
  return (
    <Paper className="CustomElementMenu" p="xs" shadow="xl">
      <Stack gap="xs">
        <Tooltip label="Text hinzufügen" position="left">
          <ActionIcon
            aria-label="Text hinzufügen"
            color="black"
            variant="transparent"
            onClick={onAddTextElement}
          >
            <IconTextPlus />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Rahmen hinzufügen" position="left">
          <ActionIcon
            aria-label="Rahmen hinzufügen"
            color="black"
            variant="transparent"
            onClick={onAddBoxElement}
          >
            <IconSquarePlus2 />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Schaubild herunterladen" position="left">
          <ActionIcon
            aria-label="Schaubild herunterladen"
            color="black"
            disabled={siteDetailsLoading}
            variant="transparent"
            onClick={onDownloadGraph}
          >
            {siteDetailsLoading ? (
              <Loader color="gray" size="sm" />
            ) : (
              <IconDownload />
            )}
          </ActionIcon>
        </Tooltip>
      </Stack>
    </Paper>
  );
}

export { CustomElementMenu };
