import { SiteSetupProcessStatus } from "../../../../../types/api.types";
import { Button } from "../../../../Buttons/Button/Button";

export function ContinueEditingButton({
  status
}: {
  status?: SiteSetupProcessStatus | null;
}) {
  switch (status) {
    case SiteSetupProcessStatus.NotConfigured:
      return (
        <Button color="brand" outline>
          Daten erfassen
        </Button>
      );
    case SiteSetupProcessStatus.InProgress:
      return (
        <Button color="brand" outline>
          Fortschritt bearbeiten
        </Button>
      );
    default:
      return;
  }
}
