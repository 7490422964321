import type { CellContext, ColumnDef } from "@tanstack/react-table";
import type { Payment } from "../../../../../../utils/backend-types";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import { NumberCell } from "../Cells/NumberCell";
import type { UpdatePaymentsValue, Value } from "../PaymentsTab";

function getDeliveryAmountPredictionThisYearColumn(
  updatePaymentsValue: UpdatePaymentsValue
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Geschätzter Verbrauch{" "}
        <IconHelpText
          helpText="Insofern uns alle relevanten Messdaten des Vorjahres zur
              Verfügung stehen, können auf dieser Grundlage Abschläge für
              das entsprechende Jahr ermittelt werden. Liegen diese Daten
              nicht vor, oder soll eine andere Verbrauchsmenge als
              Grundlage für den Abschlagsplan diesen Jahres herangezogen
              werden, können Sie diese Menge hier eintragen."
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "deliveryAmountPredictionThisYear",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <div className="deliveryAmountPredictionThisYearCell">
        <NumberCell
          disabled={cellInfo.row.original.estimatedAdvancePayments !== null}
          key={cellInfo.row.original.year}
          unit="kWh"
          updateValue={(value: Value) =>
            updatePaymentsValue(
              value,
              {
                year: cellInfo.row.original.year,
                month: cellInfo.row.original.month
              },
              "deliveryAmountPredictionThisYear"
            )
          }
          value={cellInfo.getValue()}
        />
      </div>
    )
  };
}

export { getDeliveryAmountPredictionThisYearColumn };
