import urls from "../../../../../urls";
import type { TodoFile } from "../../../../../utils/backend-types";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { DownloadLink } from "../../../../DownloadLink/DownloadLink";

type UserDocumentsProps = {
  documents?: TodoFile[];
};

function UserDocuments({ documents }: UserDocumentsProps) {
  if (!documents || documents.length === 0) {
    return null;
  }

  return (
    <div className="msb-current-user-document-container">
      {documents.map((document) => (
        <div className="msb-current-user-document" key={document.id}>
          <Icon name={IconName.FileText} />
          <DownloadLink
            filename={document.name}
            url={urls.api.todoDocumentDownload(document.id)}
          >
            {document.name}
          </DownloadLink>
        </div>
      ))}
    </div>
  );
}

export { UserDocuments };
