import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertColor } from "../../../Alert/Alert";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { LoadProfileGraphData } from "../../LoadProfileManager.types";
import { LoadProfileBarPlot } from "./LoadProfileBarPlot";
import { LoadProfileLinePlot } from "./LoadProfileLinePlot";

interface LoadProfilePlotsProps {
  data: Array<LoadProfileGraphData>;
}

function LoadProfilePlots({ data }: LoadProfilePlotsProps) {
  const { t } = useTranslation();
  const [selectedPlotType, setSelectedPlotType] = useState<string>(
    data.length > 0 ? data[0].title : "Unbekannt"
  );

  function handlePlotTypeChange(_, value: string) {
    setSelectedPlotType(value);
  }

  const plotTypes = data.map((data) => data.title);
  const selectedPlotData = data.find((data) => data.title === selectedPlotType);

  if (!selectedPlotData) {
    return (
      <IconAlert color={AlertColor.Danger}>
        {t("errors.UnknownError")}
      </IconAlert>
    );
  }

  const plotData = selectedPlotData.figure.data;
  const plotTypeChoices = plotTypes.map<Choice<string>>((plotType) => {
    return { displayName: plotType, value: plotType };
  });

  return (
    <div className="LoadProfilePlots">
      <TsDropdown
        choices={plotTypeChoices}
        defaultValue={selectedPlotType}
        name="load-profile-types"
        required
        onChange={handlePlotTypeChange}
      />
      {plotData[0].type === "scatter" && (
        <LoadProfileLinePlot
          plotData={plotData}
          xAxisLabel={selectedPlotData.figure.layout.xaxis?.title.text || ""}
          yAxisLabel={selectedPlotData.figure.layout.yaxis?.title.text || ""}
        />
      )}
      {plotData[0].type === "bar" && (
        <LoadProfileBarPlot
          plotData={plotData}
          xAxisLabel={selectedPlotData.figure.layout.xaxis?.title.text || ""}
          yAxisLabel={selectedPlotData.figure.layout.yaxis?.title.text || ""}
        />
      )}
    </div>
  );
}

export { LoadProfilePlots };
