import type { Column } from "react-table";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type {
  LocationOverviewData,
  MarketOrMeteringLocationOverview
} from "../../LocationOverview.types";

function getUpdatedColumn() {
  return {
    Header: "Änderungsdatum",
    id: "updated",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="last-updates">
        {cellInfo.value.map((overview) => (
          <div className="sub-row" key={overview.location.id}>
            {overview.location.updated}
          </div>
        ))}
      </div>
    ),
    sortable: false,
    width: 165
  } satisfies Column<LocationOverviewData>;
}

export { getUpdatedColumn };
