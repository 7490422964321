import type { Row } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import api from "../../../../api";
import urls from "../../../../urls";
import type {
  Contract,
  Delivery,
  Person,
  Site
} from "../../../../utils/backend-types";
import { showToast } from "../../../../utils/toast";
import { Badge } from "../../../BuildingBlocks/Badge/Badge";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../Buttons/EditIcon";
import { TemplateIcon } from "../../../Buttons/TemplateIcon";
import type { ColumnDef } from "../../../Table/Table";
import { Table, createColumnHelper } from "../../../Table/Table";
import { MissingDeliveriesInfo } from "../MissingDeliveriesInfo/MissingDeliveriesInfo";
import { ContractDeliverySubComponent } from "./ContractDeliverySubComponent/ContractDeliverySubComponent";
import "./ContractDeliveryTable.scss";
import { getDeliveryCountFromContract } from "./utils/getDeliveryCountFromContract";
import { getSiteNameFromContractAndDeliveries } from "./utils/getSiteNameFromContractAndDeliveries";

type TableData = {
  id: number | string;
  name: string;
};

interface ContractDeliveryTableProps {
  contracts: Array<Contract>;
  deliveries: Array<Delivery>;
  persons: Array<Person>;
  onDeliveriesUpdated: () => void;
  onContractsUpdated: () => void;
  onDataDeleted: (ids: Array<number | string>) => void;
  sites: Array<Site>;
}

function ContractDeliveryTable({
  contracts,
  deliveries,
  persons,
  onDeliveriesUpdated,
  onContractsUpdated,
  onDataDeleted,
  sites
}: ContractDeliveryTableProps) {
  const [dataToDelete, setDataToDelete] = useState<Array<TableData>>([]);
  const dataToDeleteModalIsOpen = dataToDelete.length > 0;
  const dataToDeleteNames = dataToDelete.map((obj) => obj.name);
  const dataToDeleteIds = dataToDelete.map((obj) => obj.id);
  const toggleDeleteDataModal = () => setDataToDelete([]);
  const columnHelper = createColumnHelper<Contract>();
  const handleClickMarkContractAsTemplate = useCallback(
    async (contract?: Contract) => {
      if (contract) {
        const newContract = { ...contract };
        newContract.isTemplate = !contract.isTemplate;
        try {
          await api.put(urls.api.contract(contract.id), newContract);
        } catch (error) {
          showToast("error", error);
        } finally {
          onContractsUpdated();
        }
      }
    },
    [onContractsUpdated]
  );

  const renderSubComponent = ({ row }: { row: Row<Contract> }) => {
    return (
      <ContractDeliverySubComponent
        contract={row.original}
        deliveries={deliveries.filter(
          (delivery) => delivery.contract === row.original.id
        )}
        persons={persons}
        sites={sites}
        onDeliveriesUpdated={onDeliveriesUpdated}
      />
    );
  };

  const columns = useMemo(() => {
    const columns: Array<ColumnDef<Contract>> = [
      columnHelper.display({
        id: "expandRow",
        maxSize: 10,
        cell: (props) => {
          return (
            <div>
              <Icon
                className="contract-delivery-table-expand-row-icon"
                name={
                  props.row.getIsExpanded()
                    ? IconName.AngleDown
                    : IconName.AngleRight
                }
                onClick={() => {
                  props.row.toggleExpanded();
                }}
              />
            </div>
          );
        }
      }),
      columnHelper.display({
        id: "dateRange",
        header: "Abrechnungszeitraum",
        cell: (props) => {
          return (
            <div className="contract-delivery-table-date-range-cell">
              <div>{props.row.original.begin} - </div>
              <div>{props.row.original.minimumTerm}</div>
            </div>
          );
        }
      }),
      columnHelper.accessor("name", {
        header: "Vertragsname"
      }),
      columnHelper.display({
        id: "site",
        header: "Liegenschaft",
        cell: (props) => {
          return (
            <div className="contract-delivery-table-site-cell">
              {getSiteNameFromContractAndDeliveries(
                props.row.original,
                deliveries,
                sites
              )}
            </div>
          );
        }
      }),
      columnHelper.accessor("supplier", {
        header: "Lieferant",
        cell: (props) => {
          return (
            <div>
              {
                persons.find(
                  (person) => person.id === props.row.original.supplier
                )?.name
              }
            </div>
          );
        }
      }),
      columnHelper.accessor("supplied", {
        header: "Belieferter",
        cell: (props) => {
          return (
            <div>
              {
                persons.find(
                  (person) => person.id === props.row.original.supplied
                )?.name
              }
            </div>
          );
        }
      }),
      columnHelper.accessor("isTemplate", {
        header: "Status",
        cell: (props) => {
          return (
            <div>
              {props.row.original.isTemplate && (
                <div className="contract-delivery-table-badge-container">
                  <Badge
                    className="contract-delivery-table-badge"
                    color="info"
                    title={
                      "Dies ist sowohl ein Vertrag mit Lieferungen, als auch ein Referenzvertrag. " +
                      "Das heißt die Informationen aus diesem Vertrag können beim Hinzufügen neuer Verträge übernommen werden, " +
                      "wenn dieser Vertrag dabei als Referenzvertrag ausgewählt wird."
                    }
                  >
                    Referenzvertrag
                  </Badge>
                </div>
              )}
            </div>
          );
        }
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => {
          return (
            <div className="contract-delivery-table-actions-and-missing-container">
              <div>
                <MissingDeliveriesInfo
                  numberOfDeliveries={getDeliveryCountFromContract(
                    deliveries,
                    props.row.original.id
                  )}
                />
              </div>
              <div className="contract-delivery-table-actions-container">
                <EditIcon
                  to={"vertrag/" + props.row.original.id}
                  tooltipText="Vertrag bearbeiten"
                />
                <TemplateIcon
                  isTemplate={props.row.original.isTemplate}
                  onClick={() => {
                    handleClickMarkContractAsTemplate(
                      contracts.find(
                        (contract) => contract.id === props.row.original.id
                      )
                    );
                  }}
                />
                <DeleteIcon
                  tooltipText="Vertrag löschen"
                  onClick={() => {
                    handleClickDeleteContract(props.row.original);
                  }}
                />
              </div>
            </div>
          );
        }
      })
    ];

    return columns;
  }, [
    columnHelper,
    contracts,
    deliveries,
    handleClickMarkContractAsTemplate,
    persons,
    sites
  ]);

  function handleClickDeleteContract(contract?: Contract) {
    if (contract) {
      setDataToDelete([contract]);
    }
  }
  function handleDeleteSelectedData(
    ids: Array<number | string>
  ): Promise<void> {
    const deletePromises = ids.map((id) =>
      api.delete<void>(urls.api.contract(id.toString()))
    );

    return Promise.all(deletePromises)
      .then(() => {
        onDataDeleted(ids);
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setDataToDelete([]);
      });
  }
  return (
    <div className="ContractDeliveryTable">
      <Table
        options={{
          columns: columns,
          data: contracts,
          showPagination: contracts.length > 100,
          initialState: { pagination: { pageSize: 100 } }
        }}
        renderSubComponent={renderSubComponent}
      />
      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={dataToDeleteNames}
        confirmationText={`Möchten Sie den folgenden Vertrag löschen? Dieser Schritt kann nicht rückgängig gemacht werden.`}
        isModalOpen={dataToDeleteModalIsOpen}
        objectName={"Vertrag"}
        toggleModal={toggleDeleteDataModal}
        onAction={() => handleDeleteSelectedData(dataToDeleteIds)}
      />
    </div>
  );
}

export { ContractDeliveryTable };
