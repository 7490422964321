import { PureComponent } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import loader from "../Loader";

import "./DetailedAnalysis.scss";

class DetailedAnalysis extends PureComponent {
  render() {
    const { analysis } = this.props;

    return (
      <div
        className="DetailedAnalysis"
        dangerouslySetInnerHTML={{ __html: analysis }}
      ></div>
    );
  }
}

class DetailedAnalysisWrapper extends PureComponent {
  render() {
    const { data } = this.props;

    return <DetailedAnalysis analysis={data[0]} />;
  }
}

const DetailedAnalysisWrapperWithLoader = loader(DetailedAnalysisWrapper);
const DetailedAnalysisWrapperWithLoaderWithData = (props) => (
  <DetailedAnalysisWrapperWithLoader
    dataUrls={[props.url]}
    loadingText={props.loadingText}
  />
);

class DetailedAnalysisModal extends PureComponent {
  render() {
    const { onClose, ...otherProps } = this.props;

    return (
      <Modal isOpen size="xl" toggle={onClose}>
        <ModalHeader toggle={onClose}></ModalHeader>
        <ModalBody scrollable>
          <DetailedAnalysisWrapperWithLoaderWithData {...otherProps} />
        </ModalBody>
      </Modal>
    );
  }
}

export { DetailedAnalysisModal };
