import { Button, Paper, Stack, Title } from "@mantine/core";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { HistoryTab } from "../HistoryTab/HistoryTab";
import { HistoryFieldNameToLabelMaps } from "./HistoryView.constants";
import { useHistoryViewBacklink } from "./utils/useHistoryViewBacklink";

interface HistoryViewProps {
  defaultProjectId?: string;
  defaultEntityId?: string;
  defaultEntityType?: string;
  defaultFieldNameToLabelMap?: Record<string, string>;
}

function HistoryView({
  defaultProjectId,
  defaultEntityId,
  defaultEntityType,
  defaultFieldNameToLabelMap = {}
}: HistoryViewProps) {
  const [searchParams] = useSearchParams();
  const { projectId: paramProjectId } = useParams();
  const projectId = paramProjectId || defaultProjectId;
  const entityType = searchParams.get("entityType") || defaultEntityType;
  const entityId = searchParams.get("entityId") || defaultEntityId;
  const fieldNameToLabelMap = entityType
    ? HistoryFieldNameToLabelMaps[entityType]
    : defaultFieldNameToLabelMap;
  const backlink = useHistoryViewBacklink({ projectId, entityId, entityType });

  return (
    <PageContent>
      <PageHeader backButton={backlink || undefined} title="Historie" />
      <Paper>
        {entityType && entityId ? (
          <HistoryTab
            entityId={entityId}
            entityType={entityType}
            fieldNameToLabelMap={fieldNameToLabelMap}
          />
        ) : (
          <EmptyHistory />
        )}
      </Paper>
    </PageContent>
  );
}

function EmptyHistory() {
  return (
    <Stack align="center" mt={24}>
      <Title mt={24}>Objekt nicht gefunden</Title>
      <Button component={Link} size="lg" to={"/"} variant="outline">
        Zurück zur Startseite
      </Button>
    </Stack>
  );
}

export { HistoryView };
