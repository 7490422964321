import { generatePath, Link } from "react-router-dom";

import { ROUTES } from "../../../../routes";

interface ProjectCellProps {
  projectName: string;
  projectId: string;
  showLink?: boolean;
}

function ProjectCell({ projectName, projectId, showLink }: ProjectCellProps) {
  const projectPath = generatePath(ROUTES.managerProjectView, {
    projectId: projectId
  });

  if (showLink) {
    return <Link to={projectPath}>{projectName}</Link>;
  }

  return <span>{projectName}</span>;
}

export { ProjectCell };
