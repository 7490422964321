import { useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import type { Component } from "../utils/backend-types";
import type { ObjectName } from "../utils/enums";
import { getObjectListUrl } from "../utils/getObjectListUrl";
import { getPluralVariableNameFromObjectName } from "../utils/getPluralVariableNameFromObjectName";

export function useConfiguratorComponents<T = Component>(
  objectName: ObjectName,
  siteOrVariantId?: number,
  enabled = true
) {
  const cacheName = getPluralVariableNameFromObjectName(objectName);
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: [cacheName, { siteOrVariantId: siteOrVariantId }],
    queryFn: () => {
      return typeof siteOrVariantId === "number"
        ? fetchConfiguratorComponents(objectName, siteOrVariantId)
        : undefined;
    },
    staleTime: 10000,
    enabled: enabled && typeof siteOrVariantId === "number"
  });

  async function fetchConfiguratorComponents(
    objectName: ObjectName,
    siteOrVariantId: number
  ) {
    const url: string = getObjectListUrl(objectName, siteOrVariantId);
    const response = await api.get<Array<T>>(url);
    return response.data;
  }

  function invalidateConfiguratorComponents() {
    return queryClient.invalidateQueries({
      queryKey: [cacheName, { siteOrVariantId: siteOrVariantId }]
    });
  }

  return { isLoading, error, data, invalidateConfiguratorComponents };
}
