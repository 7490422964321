import { Tabs } from "@mantine/core";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import type { Unit } from "../../../../../utils/enums";
import { CounterDataViewMode } from "../../../../CounterDataView/counter-data-view-types";
import type { CounterData } from "../../../../DataSheet/DataSheet";
import { DataSheet } from "../../../../DataSheet/DataSheet";
import { AcquisitionPlot } from "../../AcquisitionPlot/AcquisitionPlot";

const TABS = {
  TAB_PLOT: "plot",
  TAB_TABLE: "table"
} as const;

const DEFAULT_SUBPAGE = TABS.TAB_PLOT;

interface AcquisitionTabsProps {
  acquisitionSerieses?: CounterData;
  selectedUnitValue: Unit;
}

function AcquisitionTabs({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionTabsProps) {
  return (
    <Routes>
      <Route
        // needs variable state to ensure that redirect always happens on re-render (when user switches to EnergyDataPage tab)
        element={<Navigate replace state={Date.now()} to={DEFAULT_SUBPAGE} />}
        index
      />
      <Route path=":acquisitionTab">
        <Route
          element={
            <AcquisitionTabDisplay
              acquisitionSerieses={acquisitionSerieses}
              selectedUnitValue={selectedUnitValue}
            />
          }
          index
        />
      </Route>
    </Routes>
  );
}

function AcquisitionTabDisplay({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionTabsProps) {
  const navigate = useNavigate();
  const { acquisitionTab } = useParams();

  const dataIds =
    acquisitionSerieses && Object.keys(acquisitionSerieses.header);

  return (
    <Tabs
      mb="lg"
      value={acquisitionTab}
      variant="pills"
      onChange={(value) => navigate(`../${value}`)}
    >
      <Tabs.List mb="lg">
        <Tabs.Tab value={TABS.TAB_PLOT}>Diagramm</Tabs.Tab>
        <Tabs.Tab value={TABS.TAB_TABLE}>Tabelle</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={TABS.TAB_PLOT}>
        {acquisitionSerieses && (
          <AcquisitionPlot
            acquisitionSerieses={acquisitionSerieses}
            selectedUnitValue={selectedUnitValue}
          />
        )}
      </Tabs.Panel>
      <Tabs.Panel value={TABS.TAB_TABLE}>
        {acquisitionSerieses && dataIds && (
          <DataSheet
            initialData={acquisitionSerieses}
            mode={CounterDataViewMode.DoubleIndex}
            pageSize={96}
            readOnly={true}
          />
        )}
      </Tabs.Panel>
    </Tabs>
  );
}

export { AcquisitionTabs };
