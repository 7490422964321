function MenuContainer({ children }) {
  return (
    <div
      className="m-aside-menu m-aside-menu--skin-dark"
      style={{ overflowX: "hidden" }}
    >
      <ul className="m-menu__nav">{children}</ul>
    </div>
  );
}

export { MenuContainer };
