import { useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../../api";
import urls from "../../../../urls";
import { showToast } from "../../../../utils/toast";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";

export default function VariantPublishButton({ variantId }) {
  const [variantIsHidden, setVariantIsHidden] = useState(true);
  const [loading, setLoading] = useState(null);

  async function updateVariantToBePublic() {
    setLoading(true);

    try {
      await api.patch(urls.api.variantDetail(variantId), { hidden: false });
      setLoading(false);
      setVariantIsHidden(!variantIsHidden);
    } catch (error) {
      showToast("error", error);
      setLoading(false);
    }
  }

  const { projectId } = useParams();

  if (variantIsHidden) {
    return (
      <SpinButton
        block
        color="primary"
        disabled={loading}
        spin={loading}
        onClick={updateVariantToBePublic}
      >
        Als Variante übernehmen
      </SpinButton>
    );
  } else {
    return (
      <a
        className="no-hover"
        href={urls.analyzerVariantStructure(variantId, projectId)}
      >
        <Button block color="primary">
          Zur Variante
        </Button>
      </a>
    );
  }
}
