import { t } from "i18next";
import type { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { showToast } from "../../../utils/toast";
import type {
  FormatToErrorObject,
  SiteSetupProcessForForm
} from "../SiteSetupAssistant.types";

/** Using `setError` from `useForm`, assigns incoming errors from an API response to their respective fields. */
export function setSiteSetupAssistantErrorsFromResponseData<
  T extends FieldValues
>(
  error: Partial<FormatToErrorObject<SiteSetupProcessForForm>>,
  fieldList: FieldValues,
  setError: UseFormSetError<T>,
  unknownErrorText: string,
  fieldNamePrefix?: string
) {
  const formKeys: ReadonlyArray<string> = Object.keys(fieldList);

  Object.keys(error).forEach((key) => {
    const keyWithPrefix = fieldNamePrefix ? `${fieldNamePrefix}${key}` : key;

    if (formKeys.includes(keyWithPrefix)) {
      setError(
        keyWithPrefix as Path<T>,
        {
          type: "custom",
          message:
            typeof error[key][0] === "string"
              ? t(`errors.${error[key][0]}`)
              : unknownErrorText
        },
        { shouldFocus: true }
      );
    }
  });

  if (error["nonFieldErrors"]) {
    showToast("error", error["nonFieldErrors"]);
  }
  if (error["non_field_errors"]) {
    showToast("error", error["non_field_errors"]);
  }
}
