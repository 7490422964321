import type { Filter } from "react-table";

import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";

enum BooleanTodoFilterOption {
  Done = "Erledigt",
  NotDone = "Nicht erledigt"
}

interface BooleanTodosCellProps {
  value: boolean;
}

function BooleanTodosCell({ value }: BooleanTodosCellProps) {
  return (
    <StatusSymbol
      colour={value ? StatusSymbolColour.Green : StatusSymbolColour.Red}
    />
  );
}

interface BooleanTodosFilterProps {
  filter: Filter;
  onChange: (selectedKeys: Record<string, boolean>) => void;
}

function BooleanTodosFilter({ filter, onChange }: BooleanTodosFilterProps) {
  const choices = Object.keys(BooleanTodoFilterOption).reduce(
    (choices, key) => {
      const value = BooleanTodoFilterOption[key];
      choices[value] = value;
      return choices;
    },
    {}
  );

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterBooleanTodos(
  filterValue: Record<BooleanTodoFilterOption, boolean>,
  value: boolean
) {
  if (value && filterValue[BooleanTodoFilterOption.Done]) {
    return true;
  } else if (!value && filterValue[BooleanTodoFilterOption.NotDone]) {
    return true;
  }

  return false;
}

export { BooleanTodosCell, BooleanTodosFilter, onFilterBooleanTodos };
