import classnames from "classnames";
import { DateTime } from "luxon";
import { forwardRef, useCallback, useMemo, useState } from "react";
import type { Column } from "react-table";
import { Card, CardBody, Collapse } from "reactstrap";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import type { ChangeableFieldData } from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { ChangeableFieldWidget } from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { CustomReactTable } from "../../../../CustomReactTable/CustomReactTable";
import type { FormItemProps } from "../../../FormItem/FormItem";
import { FormItemLabel } from "../../../FormItem/FormItem";
import "../ChangeablePriceField/ChangeablePriceField.scss";
import { FuturePriceWarning } from "../ChangeablePriceField/FuturePriceWarning/FuturePriceWarning";

const CHANGEABLE_FIELD_WIDGET_OPTIONS = {
  addButtonText: "weitere Preisänderung hinzufügen",
  addNullStartDateWhenNoValues: true,
  endDatePlaceholder: "-",
  startDatePlaceholder: "-",
  deleteTooltipText: "Preisänderung entfernen",
  numberValueHeader: "Preis",
  saveButtonText: "Übernehmen",
  collapsible: true
};

export interface ChangeablePrice {
  id?: number;
  firstDate: string | null;
  lastDate: string | null;
  value: number;
}

interface ChangeablePriceProps extends Omit<FormItemProps, "type" | "value"> {
  fieldName: string;
  unit: string;
  value: Array<ChangeablePrice>;
  collapsible?: boolean;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function ChangeablePriceFieldStatic(
  {
    allowInput,
    fieldName,
    formName,
    helpText,
    instance,
    label,
    name,
    value: prices = [],
    required,
    unit,
    onInput,
    collapsible = true
  }: ChangeablePriceProps,
  ref: React.Ref<HTMLDivElement>
) {
  const [isHistoryCollapseOpen, setIsHistoryCollapseOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [priceDataForModal, setPriceDataForModal] =
    useState<ChangeablePrice[]>();

  const optionsWithUnit = useMemo(() => {
    return {
      ...CHANGEABLE_FIELD_WIDGET_OPTIONS,
      inputGroupText: unit,
      collapsible
    };
  }, [collapsible, unit]);

  const formItemId = `id_${formName ? formName : "form"}_${name.replace(
    /\./g,
    "__"
  )}`;

  function handleClickHistoryButton() {
    setIsCollapseOpen(false);
    setIsHistoryCollapseOpen((open) => !open);
  }

  function handleClickEditButton() {
    setIsHistoryCollapseOpen(false);
    setIsCollapseOpen((open) => !open);
  }

  function getEarliestDate(noIdObjects: Array<ChangeablePrice>) {
    return noIdObjects.reduce((prev, curr) => {
      if (prev.firstDate === null) {
        return prev;
      } else if (curr.firstDate === null) {
        return curr;
      }

      const prevDate = backendDateOrDateTimeToLuxonDateTime(prev.firstDate);
      const currDate = backendDateOrDateTimeToLuxonDateTime(curr.firstDate);

      return prevDate < currDate ? prev : curr;
    });
  }
  const handleSavePrices = useCallback(
    (prices) => {
      setShowWarningModal(false);
      onInput(fieldName, prices);
      setIsCollapseOpen(false);
      return Promise.resolve();
    },
    [onInput, fieldName]
  );

  const submitPrices = useCallback(
    (changeableFieldData: Array<ChangeableFieldData>) => {
      const newPrices = convertChangeableFieldDataToPrices(changeableFieldData);
      const noIdObjects = newPrices.filter((obj) => obj.id === undefined);
      const oneMonthFromNow = DateTime.now().plus({ months: 1 });

      if (noIdObjects && noIdObjects.length > 0) {
        const firstNewDate = getEarliestDate(noIdObjects);
        const dateToCheck = firstNewDate.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(firstNewDate.firstDate)
          : firstNewDate.lastDate
            ? backendDateOrDateTimeToLuxonDateTime(firstNewDate.lastDate)
            : null;

        if (dateToCheck && dateToCheck < oneMonthFromNow) {
          setShowWarningModal(true);
          setPriceDataForModal(newPrices);
          return Promise.resolve();
        }
      }

      return handleSavePrices(newPrices);
    },
    [handleSavePrices]
  );

  function convertPricesToChangeableFieldData(prices?: Array<ChangeablePrice>) {
    if (!prices) {
      return undefined;
    }

    return prices
      .map<ChangeableFieldData>((price) => ({
        id: price.id,
        startDate: price.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(price.firstDate)
          : null,
        endDate: price.lastDate
          ? backendDateOrDateTimeToLuxonDateTime(price.lastDate)
          : null,
        value: price.value
      }))
      .sort((a, b) => {
        if (a.startDate === null && b.startDate === null) {
          return 0;
        } else if (a.startDate === null) {
          return -1;
        } else if (b.startDate === null) {
          return 1;
        } else if (a.startDate < b.startDate) {
          return -1;
        } else if (b.startDate < a.startDate) {
          return 1;
        }

        return 0;
      });
  }

  function convertChangeableFieldDataToPrices(
    values: Array<ChangeableFieldData>
  ) {
    return values.map<ChangeablePrice>((value) => ({
      id: value.id,
      value: value.value as number,
      firstDate: value.startDate
        ? luxonDateTimeToBackendDateOrDateTime(value.startDate)
        : null,
      lastDate: value.endDate
        ? luxonDateTimeToBackendDateOrDateTime(value.endDate)
        : null
    }));
  }

  function getCurrentPrice(prices: Array<ChangeablePrice>) {
    const now = DateTime.now();
    return prices.find((price) => {
      const firstDate = price.firstDate
        ? backendDateOrDateTimeToLuxonDateTime(price.firstDate)
        : null;
      const lastDate = price.lastDate
        ? backendDateOrDateTimeToLuxonDateTime(price.lastDate)
        : null;

      const firstDateInRange = !firstDate || firstDate <= now;
      const lastDateInRange = !lastDate || now <= lastDate;

      return firstDateInRange && lastDateInRange;
    });
  }

  const currentPrice = useMemo(() => {
    return getCurrentPrice(prices);
  }, [prices]);

  const suggestedChangeablePrices = useMemo(() => {
    return convertPricesToChangeableFieldData(prices);
  }, [prices]);

  const memoizedChangeableFieldWidget = useMemo(() => {
    if (!suggestedChangeablePrices) {
      return null;
    }
    return (
      <ChangeableFieldWidget
        dataName="Preisänderungen"
        initialValues={suggestedChangeablePrices}
        options={optionsWithUnit}
        onCancel={() => setIsCollapseOpen(false)}
        onDone={submitPrices}
      />
    );
  }, [optionsWithUnit, submitPrices, suggestedChangeablePrices]);

  return (
    <div className="ChangeablePriceField FormField" ref={ref}>
      {showWarningModal && priceDataForModal && (
        <FuturePriceWarning
          prices={priceDataForModal}
          onCancel={() => setShowWarningModal(false)}
          onSuccess={handleSavePrices}
        />
      )}
      <div>
        <div className="toggler-label">
          <FormItemLabel
            helpText={helpText}
            id={formItemId}
            instance={instance}
            label={label}
            required={required}
          />
          <div className="label-and-button">
            <span
              className={classnames("label-container", {
                disabled: !optionsWithUnit.collapsible
              })}
              onClick={() => {
                if (optionsWithUnit.collapsible) {
                  handleClickHistoryButton();
                }
              }}
            >
              <Icon
                className="toggle-icon"
                name={
                  isHistoryCollapseOpen
                    ? IconName.AngleDown
                    : IconName.AngleRight
                }
              />
              {currentPrice?.value}
            </span>
            <Button
              className="label-button"
              color="brand"
              disabled={!allowInput || isCollapseOpen}
              onClick={handleClickEditButton}
            >
              Preisänderung hinzufügen
            </Button>
          </div>
        </div>
      </div>
      <Collapse isOpen={isHistoryCollapseOpen}>
        {prices ? (
          <PriceHistory prices={prices} unit={unit} />
        ) : (
          <Card className="loading-card">
            <CardBody></CardBody>
          </Card>
        )}
      </Collapse>
      <Collapse isOpen={isCollapseOpen}>
        <Card className="prices-edit">
          <CardBody>{memoizedChangeableFieldWidget}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

interface PriceHistoryProps {
  prices: Array<ChangeablePrice>;
  unit: string;
}

function PriceHistory({ prices, unit }: PriceHistoryProps) {
  const tableColumns: Array<Column<ChangeablePrice>> = [
    {
      Header: "Von",
      accessor: "firstDate",
      width: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Bis",
      accessor: "lastDate",
      width: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Preis",
      accessor: "value",
      Cell: (cellData) => (
        <span>
          {cellData.value} {unit}
        </span>
      ),
      sortable: false
    }
  ];

  return (
    <div className="price-history" style={{ marginTop: "0.5rem" }}>
      <CustomReactTable
        columns={tableColumns}
        data={prices}
        minRows={0}
        pageSize={prices.length}
      />
    </div>
  );
}

const forwardedRefField = forwardRef(ChangeablePriceFieldStatic);

export { forwardedRefField as ChangeablePriceFieldStatic };
