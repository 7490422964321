import { Group, Paper, Title } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import urls from "../../../urls";
import type { Product } from "../../../utils/enums";
import { DefineSolarSimulationForm } from "../../DefineSolarSimulationForm/DefineSolarSimulationForm";
import DynamicForm from "../../DynamicForm";
import { IconHelpText } from "../../IconHelpText/IconHelpText";
import { ComponentTabs } from "../ComponentTabs/ComponentTabs";
import { ViewMode } from "../StructureView";

interface ComponentsPanelProps {
  initialViewMode?: ViewMode;
  projectId: string;
  siteId: number;
  variantId: number;
  title?: string;
  childProps?; // todo ts: set when DynamicForm is converted to typescript
  graphCoordinates: { x: number; y: number };
  product: Product;
  setShowNotAccessibleModal: (show: boolean) => void;
}

function ComponentsPanel({
  initialViewMode,
  projectId,
  siteId,
  variantId,
  title,
  childProps,
  product,
  graphCoordinates,
  setShowNotAccessibleModal
}: ComponentsPanelProps) {
  const [viewMode, setViewMode] = useState(initialViewMode);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewMode === ViewMode.MissingDataForm && !!panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [viewMode]);

  const handleChangeViewMode = useCallback(
    (newViewMode) => setViewMode(newViewMode),
    []
  );

  switch (viewMode) {
    case ViewMode.PvSimulationForm:
      return (
        <Paper>
          <Group justify="space-between" variant="paper-header">
            <Title variant="paper-header-font">
              Rahmenbedingungen neue PV-Anlage
            </Title>
            <IconHelpText helpText="Sie haben die Möglichkeit basierend auf der bestehenden Variante die Installation einer neuen Photovoltaikanlage zu bewerten. In diesem Formular können Sie die Rahmenbedingungen der neuen Anlage festlegen. Zwischen den Grenzen für minimale und maximale Leistung werden automatisch fünf Varianten berechnet und miteinander verglichen." />
          </Group>
          <DefineSolarSimulationForm
            dataUrls={[urls.api.computationAvailable(variantId)]}
            loadingText={
              "Die aktuelle Variante wird geprüft und ggf. neu bewertet."
            }
            projectId={projectId}
            siteId={siteId}
          />
        </Paper>
      );
    case ViewMode.MissingDataForm:
      return (
        <Paper ref={panelRef}>
          <Group justify="space-between" variant="paper-header">
            <Title variant="paper-header-font">{title}</Title>
            <IconHelpText helpText="Es werden weitere Informationen benötigt damit die Optimierung möglichst genau berechnet werden kann." />
          </Group>
          <DynamicForm {...childProps} />
        </Paper>
      );
    default:
      return (
        <Paper>
          <ComponentTabs
            graphCoordinates={graphCoordinates}
            product={product}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            siteId={siteId}
            variantId={variantId}
            onChangeViewMode={handleChangeViewMode}
          />
        </Paper>
      );
  }
}

export { ComponentsPanel };
