import { numericFormatter } from "react-number-format";
import { useStartPageEEG6 } from "../../../hooks/startPage/useStartPageEEG6";
import { AnchorLink } from "../../AnchorLink/AnchorLink";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type EEG6MiniTileProps = {
  bookable: boolean;
  projectId?: string;
  year: number;
};

const salesUrl =
  "https://go.node.energy/opti.node-cockpit-nachbuchung-add-on-6-eeg?utm_source=opti.node&utm_medium=link&utm_content=startkachel&utm_campaign=DE_CoCo_6-EEG-Upgrade";

function EEG6MiniTile({ bookable, projectId, year }: EEG6MiniTileProps) {
  const { data, isLoading } = useStartPageEEG6(bookable, year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Summe",
      value: data
        ? numericFormatter(data.sum.toString(), {
            decimalSeparator: ",",
            thousandSeparator: ".",
            suffix: " €"
          })
        : ""
    },
    {
      label: "Bezahlt",
      value: data
        ? numericFormatter(data.paid.toString(), {
            decimalSeparator: ",",
            thousandSeparator: ".",
            suffix: " €"
          })
        : ""
    }
  ];

  const bookingItem = {
    label: "Das Add-on ist nicht gebucht.",
    value: (
      <AnchorLink href={salesUrl}>
        <Icon name={IconName.ArrowCircleRight} /> Sales kontaktieren
      </AnchorLink>
    )
  };

  return (
    <MiniTile
      iconName={IconName.Contract}
      isLoading={isLoading}
      items={bookable ? [bookingItem] : items}
      title="§ 6 EEG"
    />
  );
}

export { EEG6MiniTile };
