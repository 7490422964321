import { Measurand } from "../../../../../../../../utils/backend-types";

export function mapMeasurandToLabel(measurand: Measurand | string) {
  switch (measurand) {
    case Measurand.lossOfWork:
    case "loss_of_work":
      return "Ausfallarbeit";
    case Measurand.negative:
      return "Erzeugung/Einspeisung";
    case Measurand.positive:
      return "Verbrauch/Entnahme";
    case Measurand.reactiveCapacitive:
    case "reactive_capacitive":
      return "Kapazitive Blindleistung";
    case Measurand.reactiveInductive:
    case "reactive_inductive":
      return "Induktive Blindleistung";
    default:
      return "Unbekannt";
  }
}
