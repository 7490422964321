import { Box, Group, Highlight, Stack } from "@mantine/core";
import "./SiteWithSetupProcess.scss";
import { generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../routes";
import type { Site } from "../../../../../utils/backend-types";
import { DeleteIcon } from "../../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../../Buttons/EditIcon";
import { PremiumBadge } from "../../../../PremiumBadge/PremiumBadge";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";

interface SiteItemProps {
  site: Site;
  isPremium: boolean;
  onDelete: (site: Site) => void;
  onClickEdit: (site: Site) => void;
  highlight: string;
}

function SiteItem({
  site,
  isPremium,
  onDelete,
  onClickEdit,
  highlight
}: SiteItemProps) {
  const { projectId } = useParams();
  const shouldShowStaffView = useShouldShowStaffView();
  const path = generatePath(ROUTES.managerVariantStructure, {
    projectId: projectId || null,
    siteId: site.id.toString()
  });

  return (
    <Box className="SiteWithSetupProcess">
      <Stack gap="lg">
        <Group gap="lg" justify="space-between">
          <Group>
            <a href={path}>
              <Highlight fw={500} highlight={highlight}>
                {site.name}
              </Highlight>
            </a>
            {isPremium && <PremiumBadge className="premium" />}
          </Group>
          <Group gap="xs">
            <EditIcon
              tooltipText="Liegenschaft bearbeiten"
              onClick={() => onClickEdit(site)}
            />
            {shouldShowStaffView && (
              <DeleteIcon
                tooltipText="Liegenschaft löschen"
                onClick={() => onDelete(site)}
              />
            )}
          </Group>
        </Group>
      </Stack>
    </Box>
  );
}

export { SiteItem, SiteItemProps };
