import { generatePath, Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../../../routes";
import { AlertColor } from "../../../../../../Alert/Alert";
import { IconAlert } from "../../../../../../BuildingBlocks/IconAlert/IconAlert";

interface VollbenutzungsstundenInfoProps {
  onToggle: () => void;
}
function VollbenutzungsstundenInfo({
  onToggle
}: VollbenutzungsstundenInfoProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const energyDataPath = generatePath(ROUTES.energyData, {
    projectId: projectId ?? null
  });

  return (
    <IconAlert
      className="VollbenutzungsstundenInfo"
      color={AlertColor.Info}
      withCloseButton
      onClose={onToggle}
    >
      opti.node berechnet automatisch die Vollbenutzungsstunden Ihrer
      KWK-Anlagen(n), sofern alle Energiedaten vorliegen. Es fehlen noch
      Datensätze? Dann ergänzen Sie diese bitte zeitnah. opti.node überprüft
      jeden Tag die vorhanden Energiedaten und beginnt die Berechnung
      automatisch, sobald diese vollständig vorliegen. Fehlende Energiedaten
      können Sie im Bereich <Link to={energyDataPath}>Energiedaten</Link>{" "}
      hochladen.
    </IconAlert>
  );
}

export { VollbenutzungsstundenInfo };
