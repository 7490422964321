import { Link } from "react-router-dom";
import { useMeteringConceptTodos } from "../../../../../hooks/useMeteringConceptTodos";
import type { WizardMissingData } from "../../../../../utils/backend-types";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { AssignmentType } from "../../OnboardingWizard";
import { MeteringConceptDocumentLinks } from "./MeteringConceptDocumentLinks/MeteringConceptDocumentLinks";

export interface MeteringConceptAssignmentProps {
  missingData: Array<WizardMissingData>;
  done: boolean;
  isNext: boolean;
  isDisabled: boolean;
  siteId: number;
  updateSiteWizard: (type: AssignmentType) => Promise<void>;
  isLoading?: boolean;
}

function MeteringConceptAssignment({
  missingData,
  isNext,
  isDisabled,
  done,
  updateSiteWizard,
  siteId,
  isLoading
}: MeteringConceptAssignmentProps) {
  const { meteringConceptTodos } = useMeteringConceptTodos(siteId, {
    enabled: done
  });

  const hasMissingData = missingData && missingData.length > 0;

  function onClickSubmit() {
    updateSiteWizard(AssignmentType.MeteringConcept);
  }

  if (done) {
    return <MeteringConceptDocumentLinks todos={meteringConceptTodos} />;
  } else if (hasMissingData) {
    return (
      <div className="missing-data-container">
        <Button
          className="missing-data-button"
          color={isNext ? "brand" : "secondary"}
          disabled={isDisabled}
          tag={Link}
          to="messkonzeptDaten"
        >
          Fehlende Daten ergänzen
        </Button>
      </div>
    );
  } else {
    return (
      <div className="missing-data-container">
        <SpinButton
          className="missing-data-button"
          color={isNext ? "brand" : "secondary"}
          disabled={isDisabled}
          spin={isLoading}
          onClick={() => onClickSubmit()}
        >
          Als erledigt markieren
        </SpinButton>
      </div>
    );
  }
}

export { MeteringConceptAssignment };
