import { type MRT_ColumnDef, type MRT_RowData } from "mantine-react-table";
import { MeterMeasurementType } from "../../../../../../types/api.types";
import type { Meter } from "../../../../SiteSetupAssistant.types";
import { ErrorCell } from "../../TenantTable/ErrorCell/ErrorCell";

const MEASUREMENT_TYPE_CHOICES = [
  { value: MeterMeasurementType.Single, label: "Lastgang" },
  {
    value: MeterMeasurementType.ArbitraryCumulative,
    label: "Zählerstände (selbst abgelesen)"
  },
  { value: MeterMeasurementType.NoMeter, label: "Kein Zähler vorhanden" }
];

export function getMeterMeasurementTypeColumn<T extends MRT_RowData>(
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof Meter
  ) => boolean | null | undefined
): MRT_ColumnDef<T> {
  return {
    accessorKey: "measurement_type",
    header: "Wie wird gemessen?",
    editVariant: "select",
    Cell: ({ cell, row }) => {
      const displayText = MEASUREMENT_TYPE_CHOICES.find(
        (choice) => choice.value === cell.getValue()
      )?.label;
      const hasError = hasCellError(row.index, "metering_location");
      return (
        <div>
          {displayText}
          {hasError && <ErrorCell />}
        </div>
      );
    },
    mantineEditSelectProps: () => ({
      "aria-label": "Wie wird gemessen?",
      data: MEASUREMENT_TYPE_CHOICES
    })
  };
}
