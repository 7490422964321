import { useCallback, useState } from "react";

import urls from "../../../urls";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { EdaForm } from "../EdaForm/EdaForm";

function CreateEdaModal(props) {
  const { isOpen, siteId, onToggle, onCreate } = props;

  const [footerNode, setFooterNode] = useState(null);
  const footerRef = useCallback((node) => {
    if (node !== null) {
      setFooterNode(node);
    }
  }, []);

  function handleSubmit(data) {
    onCreate(data);
  }

  const optionsUrl = urls.api.energyDataAcquisitionsCrud(siteId);
  const postUrl = urls.api.energyDataAcquisitionsCrud(siteId);

  return (
    <Modal
      className="CreateEdaModal"
      fade={false}
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>
        Energiedatenerfassung hinzufügen
      </ModalHeader>
      <ModalBody scrollable>
        <EdaForm
          buttonContainer={footerNode}
          optionsUrl={optionsUrl}
          postUrl={postUrl}
          siteId={siteId}
          onCancel={onToggle}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={footerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { CreateEdaModal };
