import { Center, Text, Timeline } from "@mantine/core";
import type { HistoryLogs } from "../../../utils/backend-types";
import type { FieldNameToLabelMap } from "../HistoryTab";
import { HistoryLogActionIcons, PAGE_SIZE } from "../HistoryTab.constants";
import { HistoryItem } from "./HistoryItem";

interface HistoryDisplayProps {
  historyLogs?: HistoryLogs;
  page: number;
  fieldNameToLabelMap: FieldNameToLabelMap;
  highlight?: string;
}

function HistoryDisplay({
  historyLogs,
  page,
  fieldNameToLabelMap,
  highlight
}: HistoryDisplayProps) {
  if (!historyLogs || historyLogs.length === 0)
    return (
      <Center>
        <Text>Keine Historie zu dieser Suche gefunden.</Text>
      </Center>
    );

  return (
    <Timeline active={Infinity} lineWidth={2}>
      {historyLogs?.slice(0, PAGE_SIZE * page).map((historyLog) => (
        <Timeline.Item
          bullet={HistoryLogActionIcons[historyLog.action]}
          key={historyLog.id}
        >
          <HistoryItem
            fieldNameToLabelMap={fieldNameToLabelMap}
            highlight={highlight}
            historyLog={historyLog}
          />
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

export { HistoryDisplay, HistoryDisplayProps };
