import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { ErrorCell } from "../../TenantTable/ErrorCell/ErrorCell";

export function getMarketLocationFeedinColumn<T extends MRT_RowData>({
  errorText,
  onChange,
  hasCellError
}: {
  errorText?: string | null;
  onChange?: (value: MRT_RowData) => void;
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof T
  ) => boolean | null | undefined;
}): MRT_ColumnDef<T> {
  return {
    accessorKey: "market_location_feedin",
    header: "Marktlokation (Einspeisung)",
    mantineEditTextInputProps: ({ row }) => ({
      disabled: row.original.source === "consumer",
      error: errorText,
      onChange: () => onChange?.(row)
    }),
    Cell: ({ row }) => {
      const hasError = hasCellError(row.index, "market_location_feedin");
      return (
        <div>
          {row.original.market_location_feedin}
          {hasError && <ErrorCell />}
        </div>
      );
    }
  };
}
