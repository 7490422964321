import PropTypes from "prop-types";
import { PureComponent } from "react";
import { Col, Collapse, Input } from "reactstrap";
import urls from "../../urls";
import { LoadprofileType } from "../../utils/enums";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Row } from "../BuildingBlocks/Layout/Row";
import { Button } from "../Buttons/Button/Button";
import { ErrorText } from "../DynamicForm/FormItems/FormField/utils/ErrorText";
import "./LoadProfileSelect.scss";
import { LoadProfileSelectList } from "./LoadProfileSelectList/LoadProfileSelectList";

class LoadProfileSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadprofileListOpen: props.initialLoadprofileListOpen
    };
  }

  toggleLoadprofileTable = () => {
    this.setState({ isLoadprofileListOpen: !this.state.isLoadprofileListOpen });
  };

  handleLoadprofileSelect = (loadprofile) => {
    this.toggleLoadprofileTable();
    this.props.onInput("loadprofile", loadprofile);
  };

  render() {
    const { errorTexts, loadprofileType, value, variantId } = this.props;

    if (!variantId) {
      return null;
    }

    const { isLoadprofileListOpen } = this.state;

    const dropdownIcon = isLoadprofileListOpen ? (
      <Icon name={IconName.AngleUp} />
    ) : (
      <Icon name={IconName.AngleDown} />
    );
    const loadprofileListUrl =
      loadprofileType === LoadprofileType.Generation
        ? urls.api.loadprofilesListGeneration(variantId)
        : urls.api.loadprofilesListConsumption(variantId);
    return (
      <div className="LoadProfileSelect">
        <Row mb2>
          <Col>
            <div className="input-group">
              <Input
                className="mr-2"
                disabled
                id="loadprofile"
                type="text"
                value={value ? value.name : "Kein Lastprofil ausgewählt"}
              />
              <div className="input-group-btn">
                <Button
                  color="brand"
                  type="button"
                  onClick={this.toggleLoadprofileTable}
                >
                  Lastprofil wählen {dropdownIcon}
                </Button>
              </div>
            </div>
            {errorTexts && errorTexts.map(ErrorText)}
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={isLoadprofileListOpen}>
              <LoadProfileSelectList
                dataUrls={[loadprofileListUrl]}
                value={value}
                onLoadprofileSelected={this.handleLoadprofileSelect}
              />
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  }
}

LoadProfileSelect.propTypes = {
  loadprofileType: PropTypes.oneOf(Object.values(LoadprofileType)).isRequired,
  value: PropTypes.object,
  errorTexts: PropTypes.arrayOf(PropTypes.string),
  onInput: PropTypes.func.isRequired,
  initialLoadprofileListOpen: PropTypes.bool,
  variantId: PropTypes.number
};

LoadProfileSelect.defaultProps = {
  errorTexts: [],
  initialLoadprofileListOpen: true
};

export { LoadProfileSelect };
