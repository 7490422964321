import {
  Popover as MantinePopover,
  type FloatingPosition,
  type PopoverProps as MantinePopoverProps
} from "@mantine/core";
import { forwardRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { CHARACTER_LIMIT, isTooLongText } from "../../../utils/isTooLongText";
import { truncateToStringElipsis } from "../../../utils/truncateToStringElipsis";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { ExternalLink } from "../../ReactMarkdown/ExternalLink";
import "./Popover.scss";

type PopoverProps = CustomPopoverProps & SimplifiedMantinePopoverProps;

type SimplifiedMantinePopoverProps = Pick<
  MantinePopoverProps,
  "opened" | "position"
>;
type CustomPopoverProps = {
  children: React.ReactNode;
  text: string;
  onOpenModal?: () => void;
};

const POPOVER_MAX_CHARACTERS = CHARACTER_LIMIT + 300;

function Popover(
  { children, opened, position = "left", text, onOpenModal }: PopoverProps,
  ref: React.Ref<HTMLDivElement>
) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const openModal = () => {
    if (typeof onOpenModal !== "undefined") {
      onOpenModal();
    }
    toggleModal();
  };

  const shouldShowTruncatedTextInPopover = text.length > POPOVER_MAX_CHARACTERS;

  const modalBodyText = shouldShowTruncatedTextInPopover
    ? truncateToStringElipsis(text)
    : text;

  const isBodyTooLong = isTooLongText(text, POPOVER_MAX_CHARACTERS);

  function preventClicksBelowPopover(e: React.MouseEvent) {
    e.stopPropagation();
  }

  return (
    <>
      <MantinePopover
        opened={opened}
        position={position}
        withArrow
        zIndex={1070}
      >
        <MantinePopover.Target>{children}</MantinePopover.Target>
        <MantinePopover.Dropdown
          className="popover-dropdown"
          onClick={preventClicksBelowPopover}
        >
          <div ref={ref}>
            <ReactMarkdown
              components={{
                a: ExternalLink
              }}
            >
              {modalBodyText}
            </ReactMarkdown>
            {isBodyTooLong && (
              <>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end"
                  }}
                >
                  <Button color="brand" size="sm" onClick={openModal}>
                    Vollständige Erklärung
                  </Button>
                </div>
              </>
            )}
          </div>
        </MantinePopover.Dropdown>
      </MantinePopover>

      <Modal autoFocus className="icon-help-text-modal" isOpen={isModalOpen}>
        <ModalBody scrollable>
          <ReactMarkdown
            components={{
              a: ExternalLink
            }}
          >
            {text}
          </ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Schließen
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const ForwardedRefPopover = forwardRef(Popover);

export {
  ForwardedRefPopover as Popover,
  FloatingPosition as PopoverPosition,
  PopoverProps
};
