import { Flex, Highlight, Text } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import type {
  HistoryChange,
  HistoryDateBoundedValue
} from "../../../../utils/backend-types";
import type { FieldNameToLabelMap } from "../../HistoryTab";
import { DateBoundedValuesTable } from "./DateBoundedValuesTable";

interface ChangeDisplayProps {
  fieldName: string;
  change: HistoryChange;
  fieldNameToLabelMap: FieldNameToLabelMap;
  highlight?: string;
}

function ChangeDisplay({
  fieldName,
  change,
  fieldNameToLabelMap,
  highlight
}: ChangeDisplayProps) {
  const fieldLabel = fieldNameToLabelMap[fieldName] ?? fieldName;
  if (isStringArray(change)) {
    return (
      <SimpleValueChangeDisplay
        fieldLabel={fieldLabel}
        highlight={highlight}
        newValue={change[1]}
        oldValue={change[0]}
      />
    );
  } else {
    return (
      <DateBoundedValueChangeDisplay
        fieldLabel={fieldLabel}
        highlight={highlight}
        values={change[1]}
      />
    );
  }
}

interface SimpleValueChangeDisplayProps {
  fieldLabel: string;
  oldValue: string;
  newValue: string;
  highlight?: string;
}

function SimpleValueChangeDisplay({
  fieldLabel,
  oldValue,
  newValue,
  highlight = ""
}: SimpleValueChangeDisplayProps) {
  function valueAsDisplayValue(value: string | Array<HistoryDateBoundedValue>) {
    /**
     * The backend stores simple changes as string representation values for simple changes
     * even though the original values have different data types. That's why we need some special logic
     */
    if (value === "" || value === "None") {
      return (
        <Text fs="italic" span>
          Leer
        </Text>
      );
    } else if (value === "True") {
      return "Ja";
    } else if (value === "False") {
      return "Nein";
    }
    return value.toString();
  }

  const displayOldValue = valueAsDisplayValue(oldValue);
  const displayNewValue = valueAsDisplayValue(newValue);

  return (
    <Flex align="center" columnGap="md" wrap="wrap">
      <Highlight
        display="inline-block"
        fw="500"
        highlight={highlight}
        miw="15%"
      >
        {`${fieldLabel}:`}
      </Highlight>
      {typeof displayOldValue === "string" ? (
        <Highlight highlight={highlight} span>
          {displayOldValue}
        </Highlight>
      ) : (
        displayOldValue
      )}{" "}
      <IconArrowRight size="1em" />{" "}
      {typeof displayNewValue === "string" ? (
        <Highlight highlight={highlight} span>
          {displayNewValue}
        </Highlight>
      ) : (
        displayNewValue
      )}
    </Flex>
  );
}

interface DateBoundedValuesChangeDisplayProps {
  fieldLabel: string;
  values: Array<HistoryDateBoundedValue>;
  highlight?: string;
}

function DateBoundedValueChangeDisplay({
  fieldLabel,
  values,
  highlight = ""
}: DateBoundedValuesChangeDisplayProps) {
  return (
    <Flex align="flex-start" direction="column">
      <Highlight highlight={highlight} span>
        {`${fieldLabel}:`}
      </Highlight>
      <DateBoundedValuesTable highlight={highlight} values={values} />
    </Flex>
  );
}

function isStringArray(change: HistoryChange): change is Array<string> {
  return (
    Array.isArray(change) && change.every((item) => typeof item === "string")
  );
}

export { ChangeDisplay, ChangeDisplayProps };
