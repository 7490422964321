import { Group, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { HelperText } from "../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { FormItemInfoText } from "../../../DynamicForm/FormItem/FormItemInfoText";
import { type PVPlantWithMeter } from "../../SiteSetupAssistant.types";
import { PlantWithoutSEE } from "./PlantWithoutSEE";
import { PlantWithSEE } from "./PlantWithSEE";
import "./PvPlantsFields.scss";

interface SeeNumberError {
  index: number;
  error: string;
}

interface PvPlantsFieldsProps {
  error?: string;
  onChangeNavigationBlock: (blocked: boolean) => void;
  onChange: (data: Array<PVPlantWithMeter>) => void;
  pvPlants: Array<PVPlantWithMeter>;
}

function PvPlantsFields({
  error,
  onChange,
  onChangeNavigationBlock,
  pvPlants = []
}: PvPlantsFieldsProps) {
  const [seeNumberErrors, setSeeNumberErrors] = useState<Array<SeeNumberError>>(
    []
  );

  function handleSetError(errorText: string | null, index: number) {
    const errorIndex = seeNumberErrors.findIndex(
      (error) => error.index === index
    );
    if (!errorText) {
      if (errorIndex !== -1) {
        const newErrors = [...seeNumberErrors];
        newErrors.splice(errorIndex, 1);
        setSeeNumberErrors(newErrors);
        onChangeNavigationBlock(newErrors.length > 0);
      }
    } else {
      if (errorIndex === -1) {
        setSeeNumberErrors([...seeNumberErrors, { index, error: errorText }]);
        onChangeNavigationBlock(true);
      } else {
        const newErrors = [...seeNumberErrors];
        newErrors[errorIndex] = { index, error: errorText };
        setSeeNumberErrors(newErrors);
        onChangeNavigationBlock(true);
      }
    }
  }

  function handleDeletePvPlant(index: number) {
    if (pvPlants) {
      const updatedPvPlants = pvPlants.filter((pvPlant, idx) => idx !== index);
      onChange(updatedPvPlants);
      setSeeNumberErrors(
        seeNumberErrors.filter((error) => error.index !== index)
      );
    }
  }

  function createNewPlantWithSeeNumber() {
    onChange([
      ...pvPlants,
      { see_number: "", meter: { name: "Zähler ", is_calibrated: true } }
    ]);
  }

  function createNewPlantWithoutSeeNumber() {
    onChange([
      ...pvPlants,
      { see_number: null, meter: { name: "Zähler ", is_calibrated: true } }
    ]);
  }

  return (
    <Stack className="PvPlantsFields" gap="sm" my="lg">
      <Text fw={500}>
        Markstammdatenregisternummer der PV-Anlage(n) (SEE-Nummer)
      </Text>
      <FormItemInfoText
        infoText="Jede PV-Anlage ist im Marktstammdatenregister anzumelden. 
      Opti.node kann sämtliche Stammdaten von dort beziehen. Hierfür benötigen wir die Registernummer, 
      um die Anlage zu finden.\
      [https://www.marktstammdatenregister.de/MaStR](https://www.marktstammdatenregister.de/MaStR) \
      Wenn noch keine SEE-Nummer vorhanden ist, können Sie die Anlage stattdessen mit Bezeichnung und 
      einem vorhandenen oder neuen Betreiber anlegen."
      />

      {pvPlants.map((pvPlant, index) =>
        typeof pvPlant.see_number === "string" ? (
          <PlantWithSEE
            error={
              seeNumberErrors.find((error) => error.index === index)?.error ||
              undefined
            }
            index={index}
            key={index}
            pvPlants={pvPlants}
            onChange={onChange}
            onDelete={handleDeletePvPlant}
            onSetError={handleSetError}
          />
        ) : (
          <PlantWithoutSEE
            error={
              seeNumberErrors.find((error) => error.index === index)?.error ||
              undefined
            }
            index={index}
            key={index}
            pvPlants={pvPlants}
            onChange={onChange}
            onDelete={handleDeletePvPlant}
          />
        )
      )}
      <HelperText error={error} />
      <Group>
        <IconButton
          color={buttonColors.brand}
          iconName={IconName.PlusCircle}
          noBackground
          onClick={createNewPlantWithSeeNumber}
        >
          Anlage mit SEE-Nummer hinzufügen
        </IconButton>
        <IconButton
          color={buttonColors.brand}
          iconName={IconName.PlusCircle}
          noBackground
          onClick={createNewPlantWithoutSeeNumber}
        >
          Anlage ohne SEE-Nummer hinzufügen
        </IconButton>
      </Group>
    </Stack>
  );
}

export { PvPlantsFields };
