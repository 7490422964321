import { useQuery } from "@tanstack/react-query";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { pollTaskStatus } from "../../../utils/api-utils";
import type { GeneratorChangesFromMastr } from "../../../utils/backend-types";
import { getErrorText } from "../../../utils/get-error-text";
import { getChangesFromMastr } from "../utils/getChangesFromMastr";
import { getChangesFromMastrResult } from "../utils/getChangesFromMastrResult";

export function useGetPossibleGeneratorChanges(generatorId: number | null) {
  const { t } = useTranslation();

  return useQuery({
    queryKey: ["getPossibleGeneratorChangesFromMastr", generatorId],
    queryFn: () => pollChangesFromMastr(generatorId, t),
    enabled: false
  });
}

async function pollChangesFromMastr(
  generatorId: number | null,
  t: TFunction<"translation">
) {
  if (generatorId === null) {
    return [];
  }
  const changesFromMastrData = await getChangesFromMastr(generatorId);

  return new Promise<Array<GeneratorChangesFromMastr>>((resolve, reject) => {
    pollTaskStatus(
      changesFromMastrData.taskStatusUrl,
      () => {
        resolve(getChangesFromMastrResult(changesFromMastrData?.taskIds?.[0]));
      },
      (error) => {
        reject(getErrorText(error));
      },
      () => {
        reject(t("errors.ServerError"));
      }
    );
  });
}
