import { useState } from "react";
import { type Control, Controller, type FieldErrors } from "react-hook-form";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import {
  type PVPlantWithMeter,
  type SiteSetupProcessForForm
} from "../../SiteSetupAssistant.types";
import { PvPlantsFields } from "../CustomFields/PvPlantsFields";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

interface NameAndPvPlantsFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  onBlockNavigation: (blocked: boolean) => void;
}

function NameAndPvPlantsFormItems({
  formControl,
  formErrors,
  onBlockNavigation
}: NameAndPvPlantsFormItemsProps) {
  const [error, setError] = useState<string | undefined>();

  function validatePvPlants(pvPlants: Array<PVPlantWithMeter>) {
    const pvPlantsWithoutSEE = pvPlants.filter(
      (pvPlant) => !pvPlant.see_number
    );
    const pvPlantsWithName = pvPlantsWithoutSEE.filter(
      (pvPlant) => pvPlant.name
    );

    if (
      new Set(pvPlantsWithName.map((pvPlant) => pvPlant.name)).size !==
      pvPlantsWithName.length
    ) {
      setError(
        "Bitte tragen Sie für alle Anlagen ohne SEE-Nummer unterschiedliche Namen ein."
      );
      onBlockNavigation(true);
    } else {
      setError(undefined);
      onBlockNavigation(false);
    }
  }

  return (
    <div className="NameAndPvPlantsFormItems">
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.name}
        error={formErrors.name}
      />
      <Controller
        control={formControl}
        name="pvPlants"
        render={({ field }) => (
          <PvPlantsFields
            error={error}
            pvPlants={field.value}
            onChange={(data) => {
              field.onChange(data);
              validatePvPlants(data);
            }}
            onChangeNavigationBlock={onBlockNavigation}
          />
        )}
      />
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.defaultPvPlantsMeasurementType}
        error={formErrors.defaultPvPlantsMeasurementType}
      />
    </div>
  );
}

export { NameAndPvPlantsFormItems };
