import {
  type SiteSetupProcessForForm,
  type SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function formatFrontendDataToBackend(
  frontendData: SiteSetupProcessForForm
): SiteSetupProcessRaw {
  const dataForBackend = {
    step: frontendData.step,
    site: {
      name: frontendData.name,
      pv_plants:
        frontendData.pvPlants.filter(
          (pvPlant) =>
            !!pvPlant.see_number || !!pvPlant.name || !!pvPlant.operator?.name
        ) || [],
      address: {
        address: frontendData.siteAddress || null,
        zip: frontendData.siteZip || null,
        city: frontendData.siteCity || null
      },
      supply_type: frontendData.siteSupplyType || null,
      battery_storage: frontendData.batteryStorage || null,
      connection_voltage_level: frontendData.connectionVoltageLevel || null,
      consumers: frontendData.consumers,
      connection_meter: {
        name: frontendData.connectionName || "Zähler Netzverknüpfungspunkt", // default value since there is no field in the liko for it
        measurement_type: frontendData.connectionMeasurementType || null,
        metering_direction: frontendData.connectionMeteringDirection || null,
        metering_service_provider:
          frontendData.connectionMeteringServiceProvider || null,
        metering_location: frontendData.connectionMeteringLocation || null,
        market_location_feedin:
          frontendData.connectionMarketLocationFeedin || null,
        market_location_feedout:
          frontendData.connectionMarketLocationFeedout || null,
        number: frontendData.connectionNumber || null,
        is_calibrated:
          typeof frontendData.connectionIsCalibrated === "boolean"
            ? frontendData.connectionIsCalibrated
            : null
      },
      grid_operator: frontendData.gridOperator || null
    },
    default_pv_plants_measurement_type:
      frontendData.defaultPvPlantsMeasurementType || null,
    connection_meter_energy_data_origin:
      frontendData.connectionMeterEnergyDataOrigin || null,
    voltage_level_determined_automatically:
      frontendData.voltageLevelDeterminedAutomatically ?? null,
    grid_operator_determined_automatically:
      frontendData.gridOperatorDeterminedAutomatically ?? null,
    consumer_amount: frontendData.consumerAmount || null,
    default_consumer_type: frontendData.defaultConsumerType || null,
    default_consumer_measurement_type:
      frontendData.defaultConsumerMeasurementType || null,
    all_tenant_addresses_are_same_as_site_address:
      frontendData.allTenantAddressesAreSameAsSiteAddress ?? null,
    all_tenants_participating_in_mieterstrom:
      frontendData.allTenantsParticipatingInMieterstrom ?? null,
    site_has_battery_storage: frontendData.siteHasBatteryStorage ?? null,
    is_battery_storage_measured_separately:
      frontendData.isBatteryStorageMeasuredSeparately ?? null
  };

  return dataForBackend;
}
