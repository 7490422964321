import { NavLink } from "@mantine/core";
import { Link, useMatch } from "react-router-dom";
import { ROUTES } from "../../../routes";
import type { ObjectName } from "../../../utils/enums";
import { getLinkToComponentEditModal } from "../../../utils/getLinkToComponentEditModal";

interface ComponentOpenEditModalLinkProps {
  componentId: number;
  objectName: ObjectName;
  linkContent: React.ReactNode;
  useNavLink?: boolean;
  navLinkIcon?: React.ReactNode;
}

function ComponentOpenEditModalLink({
  componentId,
  objectName,
  linkContent,
  useNavLink,
  navLinkIcon
}: ComponentOpenEditModalLinkProps) {
  const managerMatch =
    useMatch(ROUTES.managerVariantStructure + "/*") ?? undefined;
  const analyzerMatch =
    useMatch(ROUTES.analyzerVariantStructure + "/*") ?? undefined;
  const evaluationMatch = useMatch(ROUTES.evaluation + "/*") ?? undefined;
  const path = getLinkToComponentEditModal(
    objectName,
    componentId,
    managerMatch,
    analyzerMatch,
    evaluationMatch
  );

  if (!path) return null;

  if (useNavLink) {
    const commonProps = {
      label: linkContent,
      leftSection: navLinkIcon
    };

    if (evaluationMatch) {
      return <NavLink {...commonProps} href={path} />;
    } else {
      return <NavLink {...commonProps} component={Link} to={path} />;
    }
  }

  if (evaluationMatch) {
    return <a href={path}>{linkContent}</a>;
  }

  return <Link to={path}>{linkContent}</Link>;
}

export { ComponentOpenEditModalLink };
