import type { Meter } from "../../utils/backend-types";
import type { SubMeteringSystems } from "../../utils/enums";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";

export interface ImportLog {
  endDate: string;
  id: number;
  exceptionName: string;
  message: string | null;
  messageType: ImportLogsMessageType;
  meter: Meter["id"];
  originalMessage: string | null;
  startDate: string;
  statusCode: number | null;
  subMeteringConfiguration: number;
  timestamp: string;
  url: string | null;
  username: string | null;
  clientId: string | null;
}

export enum ImportLogsMessageType {
  Success = "Erfolg",
  Error = "Fehler"
}

export interface ImportLogsResponse {
  count: number;
  results: ImportLog[];
  next: string | null;
  previous: string | null;
}

export interface ConfigurationData extends Record<string, FormFieldValue> {
  id: number;
  name: string;
  password: string;
  subMeteringSystem: SubMeteringSystems;
  token: null | string;
  url: null | string;
  username: string;
  variant: number;
  clientId: null | string;
}

export type System = {
  value: string;
  displayName: string;
};
