import type { Dispatch, SetStateAction } from "react";
import type { TodoNote } from "../../../../utils/backend-types";
import { Note } from "./Note";
import { type NoteMutation } from "./Notes.types";

export interface NotesListProps {
  notes: TodoNote[];
  isStaffUser: boolean;
  ownUserId: number;
  onUpdateNote: NoteMutation;
  onDeleteNote: Dispatch<SetStateAction<number | null>>;
  onUpdateNoteDone?: () => void;
}

function NotesList({
  notes,
  isStaffUser,
  ownUserId,
  onUpdateNote,
  onUpdateNoteDone,
  onDeleteNote
}: NotesListProps) {
  return (
    <div className="Notes-list">
      {notes.map((note) => (
        <Note
          isStaffUser={isStaffUser}
          key={note.id}
          note={note}
          ownUserId={ownUserId}
          onDeleteNote={() => {
            if (!onDeleteNote) {
              return;
            }
            onDeleteNote(note.id);
          }}
          onUpdateNote={onUpdateNote}
          onUpdateNoteDone={onUpdateNoteDone}
        />
      ))}
    </div>
  );
}

export { NotesList };
