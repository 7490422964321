import { Paper } from "@mantine/core";
import { useState } from "react";
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { Badge } from "../BuildingBlocks/Badge/Badge";
import { Section } from "../BuildingBlocks/Layout/Section";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { useMeteringConceptLogMessages } from "./hooks/useMeteringConceptLogMessages";
import { Log, LogType } from "./LoggingView.types";
import "./LoggingView.scss";

declare const SITE_NAME: string;

interface LoggingViewProps {
  variantId: number;
}

function LoggingView({ variantId }: LoggingViewProps) {
  const { logs, isLoading, error } = useMeteringConceptLogMessages(variantId);
  const warningLogs =
    logs?.filter((log) => log.logType === LogType.Warning) ?? [];
  const otherLogs =
    logs?.filter((log) => log.logType !== LogType.Warning) ?? [];

  return (
    <Paper className="LoggingView">
      <Section>
        <LoadOrError error={error} loading={isLoading}>
          <p>
            Das unten stehende Protokoll beinhaltet Hintergrundinformationen zur
            von {SITE_NAME} durchgeführten Bewertung.
          </p>
          <ListGroup>
            {warningLogs.length > 0 && (
              <LogGroup
                color="warning"
                defaultIsOpen
                label="Warnungen"
                logs={warningLogs}
              />
            )}
            <LogGroup
              defaultIsOpen={warningLogs.length === 0}
              label="Verlauf"
              logs={otherLogs}
            />
          </ListGroup>
        </LoadOrError>
      </Section>
    </Paper>
  );
}

interface LogGroupProps {
  color?: string;
  defaultIsOpen: boolean;
  label: string;
  logs: Array<Log>;
}

function LogGroup({ color, defaultIsOpen, label, logs }: LogGroupProps) {
  const [isOpen, setIsOpen] = useState(!!defaultIsOpen);

  function toggleCollapse() {
    setIsOpen(!isOpen);
  }

  return (
    <ListGroupItem
      action
      color={color}
      href="#"
      tag="a"
      onClick={toggleCollapse}
    >
      {label} <Badge pill>{logs.length}</Badge>
      <Collapse isOpen={isOpen}>
        <ul className="logs">
          {logs.map((log, index) => (
            <Log key={index} message={log.message} />
          ))}
        </ul>
      </Collapse>
    </ListGroupItem>
  );
}

function Log({ message }: { message: string }) {
  return <li>{message}</li>;
}

export { LoggingView };
