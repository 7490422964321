import { useContext } from "react";
import type { Site } from "../../../../utils/backend-types";
import { THEME_VARS } from "../../../../utils/constants";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type { SimpleOrExpandedCompany } from "../../Parkabrechnung.types";
import { ParkabrechnungContext } from "../../ParkabrechnungContext";
import { PARKABRECHNUNG_TAGS } from "../ParkabrechnungData/ParkabrechnungData";
import "./SiteSelector.scss";

type SiteSelectorProps =
  | SiteSelectorNetzbezugProps
  | SiteSelectorDrittlieferungProps;

type SiteSelectorNetzbezugProps = {
  sites: Array<Site>;
  label: string;
  abrechnungType: "Netzbezug Parkabrechnung";
};

type SiteSelectorDrittlieferungProps = {
  sites: Array<Site>;
  companies: Array<SimpleOrExpandedCompany>;
  label: string | { companyLabel: string; siteLabel: string };
  abrechnungType: "Drittlieferung Parkabrechnung";
};

export function SiteSelector(props: SiteSelectorProps) {
  const { abrechnungType, sites, label } = props;

  const { selectedSite, setSelectedSite, setSelectedCompany, selectedCompany } =
    useContext(ParkabrechnungContext);

  const siteChoices = sites?.map((site) => {
    return {
      value: site.id,
      displayName: site.name
    };
  });

  const companyChoices =
    abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung
      ? props.companies?.map((company) => {
          return {
            value: company.id,
            displayName: company.name
          };
        })
      : undefined;

  function handleSiteChangeInput(__, value: string) {
    if (!value) {
      return setSelectedSite(undefined);
    } else {
      const selectedSite = sites?.find((site) => site.id === JSON.parse(value));

      setSelectedSite(selectedSite);
      setSelectedCompany(undefined);
    }
  }

  function handleCompanyChangeInput(__, value: number) {
    setSelectedCompany(value);
  }

  return (
    <div className="site-selector-container">
      {typeof label === "string" && <h6>{label}</h6>}
      <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
        {sites && sites.length !== 0 ? (
          <>
            <div>
              {typeof label !== "string" && <h6>{label.siteLabel}</h6>}
              <TsDropdown
                choices={siteChoices}
                defaultValue={selectedSite?.id}
                id="site-selector"
                name="site-selector"
                placeholder="Liegenschaft auswählen"
                required
                onChange={handleSiteChangeInput}
              />
            </div>
            {abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung && (
              <div>
                {typeof label !== "string" && <h6>{label.companyLabel}</h6>}
                <TsDropdown
                  choices={companyChoices ?? []}
                  defaultValue={selectedCompany}
                  disabled={!(companyChoices && companyChoices.length !== 0)}
                  id="company-selector"
                  name="company-selector"
                  placeholder={"Unternehmen auswählen"}
                  required
                  onChange={handleCompanyChangeInput}
                />
              </div>
            )}
          </>
        ) : (
          <AnimatedLoadingIcon color={THEME_VARS.primaryColor} />
        )}
      </div>
    </div>
  );
}
