import { ActionIcon, Group, Loader, Tooltip } from "@mantine/core";
import {
  IconDownload,
  IconPencil,
  IconPin,
  IconPinned,
  IconTrash
} from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../../api";
import urls from "../../../../urls";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { showToast } from "../../../../utils/toast";
import { DecimalLabel } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";

interface ListItemProps {
  id: number;
  name: string;
  totalKwh: number;
  maxPower?: number;
  description?: string;
  category: string;
  isGeneric: boolean;
  isPinned: boolean;
  onDelete: (id: number) => Promise<void>;
  onPin: (id: number, name: string, pin: boolean) => void;
}

export function ListItem({
  id,
  name,
  totalKwh,
  maxPower,
  description = "",
  category,
  isGeneric = true,
  isPinned = false,
  onDelete,
  onPin
}: ListItemProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  async function handleDownload() {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);

    try {
      const response = await axios.get(urls.api.loadprofilesDownload(id));
      downloadCsvOrExcelFileForResponseWithContentDisposition(response);
    } catch (error) {
      showToast("error", error);
    }

    setIsDownloading(false);
  }

  return (
    <tr className="ListItem">
      <td>{<Link to={id + "/"}>{name}</Link>}</td>
      <td align="right">
        <DecimalLabel value={totalKwh} />
      </td>
      <td align="right">
        <DecimalLabel value={maxPower} />
      </td>
      <td align="right">
        {maxPower && <DecimalLabel value={totalKwh / maxPower} />}
      </td>
      <td>{category}</td>
      <td>{description}</td>
      <td>
        <LoadProfileActions
          editUrl={id + "/"}
          isDownloading={isDownloading}
          isGeneric={isGeneric}
          isPinned={isPinned}
          onDelete={() => onDelete(id)}
          onDownload={handleDownload}
          onPin={() => onPin(id, name, !isPinned)}
        />
      </td>
    </tr>
  );
}

interface LoadProfileActionsProps {
  editUrl: string;
  isDownloading: boolean;
  isGeneric: boolean;
  isPinned: boolean;
  onDelete: () => void;
  onDownload: () => Promise<void>;
  onPin: () => void;
}

function LoadProfileActions({
  editUrl,
  isDownloading,
  isGeneric,
  isPinned,
  onDelete,
  onDownload,
  onPin
}: LoadProfileActionsProps) {
  const onlyDownloadAllowed = isGeneric;

  return (
    <Group gap={0} wrap="nowrap">
      <Tooltip
        label={
          onlyDownloadAllowed
            ? "Sie können dieses Lastprofil nicht bearbeiten"
            : "Bearbeiten"
        }
        position="left"
      >
        <ActionIcon
          aria-label="Bearbeiten"
          color="secondary"
          component={Link}
          data-disabled={onlyDownloadAllowed}
          to={editUrl}
          variant="transparent"
          onClick={(e) => {
            if (onlyDownloadAllowed) e.preventDefault();
          }}
        >
          <IconPencil size="16" />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Herunterladen" position="left">
        <ActionIcon
          aria-label="Herunterladen"
          color="secondary"
          disabled={isDownloading}
          variant="transparent"
          onClick={onDownload}
        >
          {isDownloading ? (
            <Loader color="gray" size="16" />
          ) : (
            <IconDownload size="16" />
          )}
        </ActionIcon>
      </Tooltip>
      <Tooltip
        label={
          onlyDownloadAllowed
            ? "Sie können dieses Lastprofil nicht löschen"
            : "Löschen"
        }
        position="left"
      >
        <ActionIcon
          aria-label="Löschen"
          color="secondary"
          disabled={onlyDownloadAllowed}
          variant="transparent"
          onClick={onDelete}
        >
          <IconTrash size="16" />
        </ActionIcon>
      </Tooltip>
      <Tooltip
        label={
          onlyDownloadAllowed
            ? "Dieses Lastprofil ist in allen Projekten verfügbar."
            : isPinned
              ? "Von Projekt entfernen"
              : "Aktuellem Projekt zuordnen"
        }
        position="left"
      >
        <ActionIcon
          aria-label={
            isPinned ? "Von Projekt entfernen" : "Aktuellem Projekt zuordnen"
          }
          color="secondary"
          disabled={onlyDownloadAllowed}
          variant="transparent"
          onClick={onPin}
        >
          {isPinned || onlyDownloadAllowed ? (
            <IconPinned size="16" />
          ) : (
            <IconPin size="16" />
          )}
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
