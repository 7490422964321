import classNames from "classnames";
interface SectionProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

function Section({ title, className, children }: SectionProps) {
  const head = title ? <div className="m-section__heading">{title}</div> : null;

  return (
    <div className={classNames("m-section", className)}>
      {head}
      <div className="m-section__content">{children}</div>
    </div>
  );
}

export { Section, SectionProps };
