import { Center, Flex, Stack, Text } from "@mantine/core";
import { useState } from "react";
import type { FormFieldLabelProps } from "../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import { Button } from "../Buttons/Button/Button";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SearchBar } from "../SearchBar/SearchBar";
import { HistoryDisplay } from "./HistoryDisplay/HistoryDisplay";
import { PAGE_SIZE } from "./HistoryTab.constants";
import "./HistoryTab.scss";
import type { EntityId, EntityType } from "./HistoryTab.types";
import { useHistory } from "./hooks/useHistory";

interface FieldNameToLabelMap {
  [key: string]: FormFieldLabelProps["label"];
}

interface HistoryTabProps {
  entityType: EntityType;
  entityId: EntityId;
  subEntityId?: EntityId;
  fieldNameToLabelMap: FieldNameToLabelMap;
}

function HistoryTab({
  entityId,
  subEntityId,
  entityType,
  fieldNameToLabelMap
}: HistoryTabProps) {
  const {
    data: historyData,
    isLoading,
    error
  } = useHistory({ entityId, entityType, subEntityId });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const filteredHistoryData = historyData?.filter((log) => {
    const searchLower = search.toLowerCase();
    return (
      log.user?.toLowerCase().includes(searchLower) ||
      log.object_label?.toLowerCase().includes(searchLower) ||
      Object.entries(log.changes).some(([fieldName, change]) => {
        return (
          fieldNameToLabelMap[fieldName]?.toLowerCase().includes(searchLower) ||
          (Array.isArray(change) &&
            change.some((changeEntry) => {
              if (typeof changeEntry === "string") {
                return changeEntry?.toLowerCase().includes(searchLower);
              }
              if (typeof changeEntry === "object") {
                return changeEntry?.some((innerChangeEntry) =>
                  innerChangeEntry.value.toLowerCase().includes(searchLower)
                );
              }
              return false;
            }))
        );
      })
    );
  });

  const displayShowMoreButton =
    filteredHistoryData &&
    filteredHistoryData.length > PAGE_SIZE &&
    page * PAGE_SIZE < filteredHistoryData.length;

  if (!search && !historyData?.length && !isLoading) {
    return <HistoryNoData />;
  }

  return (
    <LoadOrError error={error} loading={isLoading}>
      <Stack gap="md">
        <SearchBar
          btnHidden
          placeholder="Suche nach Benutzer:in, Objekt oder Aktion"
          onChange={(event) => setSearch(event.currentTarget.value)}
          onClear={() => setSearch("")}
        />

        <HistoryDisplay
          fieldNameToLabelMap={fieldNameToLabelMap}
          highlight={search}
          historyLogs={filteredHistoryData}
          page={page}
        />
        {displayShowMoreButton && (
          <Flex align="center" justify="center">
            <Button
              color="brand"
              onClick={() => setPage((prevPage: number) => prevPage + 1)}
            >
              Mehr anzeigen
            </Button>
          </Flex>
        )}
      </Stack>
    </LoadOrError>
  );
}

function HistoryNoData() {
  return (
    <Center>
      <Text>Aktuell ist noch keine Historie vorhanden.</Text>
    </Center>
  );
}

export { FieldNameToLabelMap, HistoryTab, HistoryTabProps };
