import type { ReactNode, FormEvent } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Alert, AlertColor } from "../../Alert/Alert";
import { comparePasswords } from "../utils/comparePasswords";
import { isPassTooShort } from "../utils/isPassTooShort";
import { passContainLowerAndUppercaseLetter } from "../utils/passContainLowerAndUppercaseLetter";
import { passContainNumberOrSpecialCharacter } from "../utils/passContainNumberOrSpecialCharacter";
import { validateToken } from "../utils/validateToken";

export interface CustomPasswordErrors {
  isTooShort: boolean;
  doesNotContainLowerAndUppercaseLetter: boolean;
  doesNotContainNumberOrSpecialCharacter: boolean;
  containsUserEmail: boolean;
  passwordsDoNotMatch: boolean;
}

export function useSetPass({ newPass, newPassConfirm, onPasswordSet }) {
  const [isSettingPass, setIsSettingPass] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] =
    useState<CustomPasswordErrors>({
      isTooShort: false,
      doesNotContainLowerAndUppercaseLetter: false,
      doesNotContainNumberOrSpecialCharacter: false,
      containsUserEmail: false,
      passwordsDoNotMatch: false
    });
  const [errors, setErrors] = useState<ReactNode | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = searchParams.get("user");
  const token = searchParams.get("token");
  const isPasswordSet = searchParams.get("passwordSet");

  async function handleSetPass(event: FormEvent) {
    event.preventDefault();

    const isTooShort = isPassTooShort(newPass);
    const containLowerAndUppercaseLetter =
      passContainLowerAndUppercaseLetter(newPass);
    const containNumberOrSpecialCharacter =
      passContainNumberOrSpecialCharacter(newPass);
    const passwordsMatch = comparePasswords(newPass, newPassConfirm);

    setValidationErrors({
      isTooShort,
      doesNotContainLowerAndUppercaseLetter: !containLowerAndUppercaseLetter,
      doesNotContainNumberOrSpecialCharacter: !containNumberOrSpecialCharacter,
      containsUserEmail: false,
      passwordsDoNotMatch: !passwordsMatch
    });

    setIsChecked(true);

    if (
      isTooShort ||
      !containLowerAndUppercaseLetter ||
      !containNumberOrSpecialCharacter ||
      !passwordsMatch
    ) {
      return;
    }

    if (token && user) {
      setIsSettingPass(true);

      try {
        // first we need to validate token and only after that reset password
        await validateToken(token, user);
      } catch (_) {
        setErrors(
          <Alert color={AlertColor.Danger}>
            Ungültiger Token. Wahrscheinlich ist der Link abgelaufen. Bitte
            fordern Sie einen Neuen an
          </Alert>
        );
        setIsSettingPass(false);
        return;
      }

      try {
        await onPasswordSet(newPass, newPassConfirm, user, token);

        searchParams.set("passwordSet", "true");
        navigate(location.pathname + "?" + searchParams.toString());
      } catch (error) {
        const errors =
          error.response?.data?.[Object.keys(error.response?.data)?.[0]] || "";

        if (
          errors.some(
            (err: string) =>
              err.match(/username\.$/g) || err.match(/E-Mail-Adresse\.$/g)
          )
        ) {
          setValidationErrors((prevCustomErrors) => ({
            ...prevCustomErrors,
            containsUserEmail: true
          }));

          return;
        }

        setErrors(
          <Alert color={AlertColor.Danger}>
            {Array.isArray(error) ? (
              error.map((err) => (
                <>
                  <span key={err}>{err}</span>
                  <br />
                </>
              ))
            ) : (
              <span>{error}</span>
            )}
          </Alert>
        );
      } finally {
        setIsSettingPass(false);
      }
    }
  }

  const isInvalidPass =
    validationErrors.isTooShort ||
    validationErrors.doesNotContainLowerAndUppercaseLetter ||
    validationErrors.doesNotContainNumberOrSpecialCharacter ||
    validationErrors.containsUserEmail;

  return {
    handleSetPass,
    isSettingPass,
    errors,
    isChecked,
    validationErrors,
    isPasswordSet,
    isInvalidPass
  };
}
