import { SimpleGrid } from "@mantine/core";
import {
  StartPageTileDisplay_mode,
  StartPageTileName
} from "../../../types/api.types";
import type { StartPageTile } from "../StartPage.types";
import { OnboardingProgressTile } from "./OnboardingProgressTile";
import { RegulatoryTodosTile } from "./RegulatoryTodosTile";

interface MySitesManagementProps {
  projectId?: string;
  tiles: Array<StartPageTile>;
}

function MySitesManagement({ projectId, tiles }: MySitesManagementProps) {
  return (
    <SimpleGrid className="MySitesManagement" cols={2} spacing="lg">
      {tiles
        .filter((tile) => tile.display_mode !== StartPageTileDisplay_mode.Hide)
        .map((tile) => {
          switch (tile.name) {
            case StartPageTileName.OnboardingProgress:
              return (
                <OnboardingProgressTile
                  key={"onboarding-progress-tile"}
                  projectId={projectId}
                />
              );
            case StartPageTileName.RegulatoryTodos:
              return (
                <RegulatoryTodosTile
                  key={"regulatory-todos-tile"}
                  projectId={projectId}
                />
              );
            default:
              return null;
          }
        })}
    </SimpleGrid>
  );
}

export { MySitesManagement, MySitesManagementProps };
