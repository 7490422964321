import type { ReactNode } from "react";
import "./PasswordResetLayout.scss";

interface PasswordResetLayoutProps {
  title: string;
  description: string | ReactNode;
  children: ReactNode;
}

function PasswordResetLayout({
  title,
  description,
  children
}: PasswordResetLayoutProps) {
  return (
    <div className="PasswordResetLayout">
      <div className="PasswordResetHeader">
        <h3 className="PasswordResetTitle">{title}</h3>
        <span className="PasswordResetDescription">{description}</span>
      </div>

      {children}
    </div>
  );
}

export { PasswordResetLayout };
