import { Accordion } from "@mantine/core";
import { Link } from "react-router-dom";
import type { Meter } from "../../../utils/backend-types";
import { AlertColor } from "../../Alert/Alert";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import "./DataWarningAlert.scss";

interface DataWarningAlertProps {
  objectMeters: Array<Pick<Meter, "id" | "name">>;
}

function DataWarningAlert({ objectMeters }: DataWarningAlertProps) {
  return (
    <IconAlert
      bg="#fdf7eb"
      className="DataWarningAlert"
      color={AlertColor.Warning}
    >
      <div>
        Überprüfen Sie die Verbrauchsanteile für folgende Zähler:
        {objectMeters.length === 1 && <span> {objectMeters[0].name}</span>}
      </div>
      {objectMeters.length > 1 && (
        <Accordion>
          <Accordion.Item value="meter-list">
            <Accordion.Control>Zähler-Liste</Accordion.Control>
            <Accordion.Panel>
              <ul className="object-list">
                {objectMeters?.map((meter) => (
                  <li key={meter.id}>
                    <Link to={`verbrauchsanteile/${meter.id}`}>
                      {meter.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </IconAlert>
  );
}

export { DataWarningAlert, DataWarningAlertProps };
