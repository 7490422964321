import { Group } from "@mantine/core";
import _ from "lodash";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { OAuthApplicationScope } from "../../../types/api.types";
import { FormFieldController } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { FormBody } from "../../BuildingBlocks/Forms/utils/FormBody";
import { FormContainer } from "../../BuildingBlocks/Forms/utils/FormContainer";
import { FormFooter } from "../../BuildingBlocks/Forms/utils/FormFooter";
import { setErrorsFromResponseData } from "../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import type { Choice } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type {
  OAuthApplicationCreate,
  OAuthApplicationCustomers
} from "../ApiSettings.types";
import {
  OAUTH_APPLICATION_FORM_FIELD_DATA,
  SCOPE_LABELS
} from "./OAuthApplicationForm.constants";

interface OAuthApplicationFormProps {
  buttonContainer?: HTMLElement;
  customers: OAuthApplicationCustomers;
  scopes: Array<OAuthApplicationScope>;
  onCancel: () => void;
  onSubmit: SubmitHandler<OAuthApplicationCreate>;
}

function OAuthApplicationForm({
  customers,
  scopes,
  onCancel,
  onSubmit
}: OAuthApplicationFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = useForm<OAuthApplicationCreate>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const customerChoices = _.sortBy(
    Object.keys(customers).map<Choice<number>>((customerId) => ({
      displayName: customers[customerId],
      value: Number(customerId)
    })),
    ["displayName"]
  );

  const scopeChoices = _.sortBy(
    scopes.map<Choice<OAuthApplicationScope>>((scope) => ({
      displayName: SCOPE_LABELS[scope],
      value: scope
    })),
    ["displayName"]
  );

  const onValidSubmit: SubmitHandler<OAuthApplicationCreate> = async (data) => {
    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<OAuthApplicationCreate>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  async function handleClickSubmit() {
    setIsSubmitting(true);

    try {
      await submitCallback();
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleClickCancel() {
    reset();
    onCancel();
  }

  return (
    <FormContainer>
      <form
        className="OAuthApplicationForm"
        noValidate
        onSubmit={handleClickSubmit}
      >
        <FormBody>
          <FormFieldController
            control={control}
            data={OAUTH_APPLICATION_FORM_FIELD_DATA.name}
            error={errors.name}
          />
          <FormFieldController
            control={control}
            data={{
              ...OAUTH_APPLICATION_FORM_FIELD_DATA.customer,
              choices: customerChoices
            }}
            error={errors.customer}
          />
          <FormFieldController
            control={control}
            data={{
              ...OAUTH_APPLICATION_FORM_FIELD_DATA.scope,
              choices: scopeChoices
            }}
            error={isFieldError(errors.scope) ? errors.scope : undefined}
          />
        </FormBody>
        <FormFooter>
          <Group gap="xs">
            <Button onClick={handleClickCancel}>Schließen</Button>
            <SpinButton
              color="primary"
              spin={isSubmitting}
              onClick={handleClickSubmit}
            >
              Registrieren
            </SpinButton>
          </Group>
        </FormFooter>
      </form>
    </FormContainer>
  );
}

export { OAuthApplicationForm, OAuthApplicationFormProps };
