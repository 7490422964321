import { Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api";
import { THIRD_PARTY_SYSTEMS_URL } from "../../../routes";
import urls from "../../../urls";
import { showToast } from "../../../utils/toast";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { useHistoricalDataImportState } from "../utils/useHistoricaDataImportState";
import {
  HistoricalDataImport,
  IMPORT_RUNNING_TEXT
} from "./HistoricalDataImport";

interface HistoricalDataImportWithSubmitProps {
  configurationId: number;
}

function HistoricalDataImportWithSubmit({
  configurationId
}: HistoricalDataImportWithSubmitProps) {
  const [startDate, endDate, setDates] = useHistoricalDataImportState();
  const [loading, setLoading] = useState(false);
  const [imported, setImported] = useState(false);

  useEffect(() => {
    setImported(false);
  }, [startDate, endDate]);

  function handleClickSubmit() {
    setLoading(true);

    api
      .post(
        urls.api.subMeteringConfigurationHistoricalDataImport(configurationId),
        { start: startDate, end: endDate }
      )
      .then(() => {
        setImported(true);
        showToast("success", IMPORT_RUNNING_TEXT);
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const canSubmit = !!startDate && !!endDate && !imported;

  return (
    <Stack align="center">
      <HistoricalDataImport onDatesChanged={setDates} />
      <Stack gap="xs" w={200}>
        <SpinButton
          color="brand"
          disabled={!canSubmit}
          spin={loading}
          onClick={handleClickSubmit}
        >
          Importieren
        </SpinButton>

        {imported && (
          <Button
            color="primary"
            tag={Link}
            to={`../../${THIRD_PARTY_SYSTEMS_URL}/${configurationId}/historie/`}
          >
            Import-Historie anzeigen
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export { HistoricalDataImportWithSubmit, HistoricalDataImportWithSubmitProps };
