import { Group } from "@mantine/core";
import type { CurrentUser } from "../../utils/backend-types";
import { SiteName } from "../../utils/enums";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { OptiNodeHelpIcon } from "../OptiNodeHelpIcon/OptiNodeHelpIcon";
import { SiteQuickSwitch } from "../SiteQuickSwitch/SiteQuickSwitch";
import { StaffViewToggle } from "../StaffViewToggle/StaffViewToggle";
import { Brand } from "./Brand";
import { UserMenu } from "./UserMenu";

import "./Header.scss";

interface Props {
  siteName: SiteName;
  currentUser: CurrentUser | undefined;
  toggleShowStaffView: () => void;
}

function Header({ siteName, currentUser, toggleShowStaffView }: Props) {
  return (
    <div className="Header">
      <Brand siteName={siteName} />
      <Group>
        <Breadcrumbs />
        <Group>
          <SiteQuickSwitch />
          <StaffViewToggle toggleShowStaffView={toggleShowStaffView} />
          {siteName === SiteName.Optinode && <OptiNodeHelpIcon />}
          <UserMenu currentUser={currentUser} siteName={siteName} />
        </Group>
      </Group>
    </div>
  );
}

export { Header };
