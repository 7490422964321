import type { TodoLog } from "../../../../utils/backend-types";

interface LogsTabProps {
  logs: Array<TodoLog>;
}

function LogsTab({ logs }: LogsTabProps) {
  return (
    <div className="logs">
      {logs.length > 0 && <LogList logs={logs} />}
      {logs.length === 0 && <span>Noch keine Historie.</span>}
    </div>
  );
}

type LogsListProps = LogsTabProps;

function LogList({ logs }: LogsListProps) {
  return (
    <ul className="log-list">
      {logs.map((log, index) => (
        <Log key={index} log={log} />
      ))}
    </ul>
  );
}

interface LogProps {
  log: TodoLog;
}

function Log({ log }: LogProps) {
  return <li className="log">{log.description}</li>;
}

export { LogsTab };
