import {
  Accordion,
  Checkbox,
  colorsTuple,
  createTheme,
  List,
  NumberFormatter,
  NumberInput,
  Pagination,
  Paper,
  Popover,
  rem,
  Select,
  Stepper,
  Tabs,
  Text,
  Tooltip
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
// this still needs explicit React import to work properly in Storybook
import React from "react";
import { THEME_VARS } from "../utils/constants";

const mantineTheme = createTheme({
  scale: 1.23077, // needed to compensate for legacy 13px font-size on body (from Metronic/Bootstrap styles)
  colors: {
    brand: colorsTuple(THEME_VARS.brandColor),
    secondary: colorsTuple(THEME_VARS.primaryColor),
    danger: colorsTuple(THEME_VARS.dangerColor),
    success: colorsTuple(THEME_VARS.successColor),
    customGrey: colorsTuple(THEME_VARS.customGrey),
    black: colorsTuple(THEME_VARS.nodeEnergyBlack)
  },
  components: {
    Accordion: Accordion.extend({
      defaultProps: {
        chevron: <IconChevronRight size={26} />,
        chevronPosition: "left",
        chevronSize: 26,
        order: 3,
        variant: "contained"
      }
    }),
    Checkbox: Checkbox.extend({ defaultProps: { variant: "outline" } }),
    List: List.extend({ defaultProps: { fz: "sm" } }),
    NumberFormatter: NumberFormatter.extend({
      defaultProps: { decimalSeparator: ",", thousandSeparator: "." }
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        allowedDecimalSeparators: [".", ","],
        decimalSeparator: ",",
        hideControls: true,
        thousandSeparator: ".",
        withKeyboardEvents: false
      }
    }),
    Pagination: Pagination.extend({ defaultProps: { size: "sm" } }),
    Paper: Paper.extend({
      defaultProps: {
        p: "lg",
        radius: 0,
        shadow: "md"
      }
    }),
    Popover: Popover.extend({ defaultProps: { shadow: "tooltip" } }),
    Select: Select.extend({
      defaultProps: {
        styles: {
          dropdown: {
            zIndex: 1060 // more than metronic modal
          }
        }
      }
    }),
    Stepper: Stepper.extend({
      styles: {
        steps: { rowGap: "var(--mantine-spacing-sm)" },
        separator: { marginInline: "var(--mantine-spacing-sm)" }
      }
    }),
    Tabs: Tabs.extend({ defaultProps: { color: THEME_VARS.primaryColor } }),
    Text: Text.extend({ defaultProps: { size: "sm" } }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        arrowSize: 7,
        color: "white",
        withArrow: true,
        zIndex: 1060 // more than metronic modal
      },
      styles: { tooltip: { boxShadow: "var(--mantine-shadow-tooltip)" } }
    })
  },
  fontFamily: "Montserrat, sans-serif",
  fontSizes: {
    // md-xl are Mantine defaults, only need to reduce xs & sm by 1px so that we have our legacy base 13px available
    xs: rem(11),
    sm: rem(13)
  },
  primaryColor: "brand",
  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "2px 3px 6px rgba(0, 0, 0, .25)",
    // should be kept in sync with old Tooltip.scss until that file is deleted
    // rgba(98, 95, 107, 0.1) is equivalent to rgba(color.scale(#c5c2cc, $lightness: -50%), 0.1)
    tooltip: "0 1px 15px 1px rgba(98, 95, 107, 0.1)"
  }
});

export { mantineTheme };
