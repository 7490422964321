import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useProjects } from "../../../hooks/useProjects";
import { MenuItem } from "../MenuItem/MenuItem";

declare const SITE_NAME: string;

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps) {
  const { projectId } = useParams();
  const { data: projects } = useProjects({
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const currentProject = projects?.find((project) => project.id === projectId);

  useEffect(() => {
    if (currentProject) {
      document.title = `${title} - ${currentProject.name} - ${SITE_NAME}`;
    } else {
      document.title = `${title} - ${SITE_NAME}`;
    }
  }, [currentProject, title]);

  return (
    <MenuItem>
      <span className="PageTitle">{title}</span>
    </MenuItem>
  );
}

export { PageTitle };
