import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import type { Column } from "react-table";
import { Col, Row } from "reactstrap";

import api from "../../../api";
import urls from "../../../urls";
import { postDataAndPollResponse } from "../../../utils/api-utils";
import type {
  AdvancePaymentsPlanData,
  AdvancePaymentsPlan as AdvancePaymentsPlanResponse,
  Contract,
  Person
} from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { DeleteConfirmationModal } from "../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { PersonCell } from "../../CustomReactTable/Cells/PersonCell";
import type { CustomReactSelectTableProps } from "../../CustomReactTable/CustomReactTable";
import { CustomReactSelectTable } from "../../CustomReactTable/CustomReactTable";
import { useCustomReactTableCheckboxes } from "../../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { DateRangeCell, FileCell } from "../DeliveryInvoices";

import "./AdvancePayments.scss";

type AdvancePaymentsPlan = Omit<
  AdvancePaymentsPlanResponse,
  "advancePaymentsPlanData"
> &
  Partial<Omit<AdvancePaymentsPlanData, "id">> & {
    supplied?: number;
    supplier?: number;
  };

interface AdvancePaymentsPlansProps
  extends CustomReactSelectTableProps<AdvancePaymentsPlan> {
  advancePaymentsPlans: Array<AdvancePaymentsPlan>;
  persons: Array<Person>;
}

function AdvancePaymentsPlans({
  advancePaymentsPlans,
  persons,
  ...customReactTableProps
}: AdvancePaymentsPlansProps) {
  const columns: Array<Column> = [
    {
      Header: "Abschlagsplan",
      accessor: "id",
      Cell: (row) => (
        <FileCell
          billingPeriodStart={row.original.paymentPlanBegin}
          persons={persons}
          prefix="Abschlag"
          suppliedId={row.original.supplied}
          supplierId={row.original.supplier}
          url={urls.api.downloadAdvancePaymentsPlan(row.value)}
        />
      )
    },
    {
      Header: "Lieferant",
      accessor: "supplier",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    {
      Header: "Belieferter",
      accessor: "supplied",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    {
      Header: "Abrechnungszeitraum",
      accessor: "billingPeriodStart",
      Cell: (row) => (
        <DateRangeCell
          beginDate={row.original.paymentPlanBegin}
          endDate={row.original.paymentPlanEnd}
        />
      )
    }
  ];

  const defaultSorted = [
    {
      id: "billingPeriodStart",
      desc: true
    }
  ];

  return (
    <CustomReactSelectTable
      {...customReactTableProps}
      columns={columns}
      data={advancePaymentsPlans}
      defaultSorted={defaultSorted}
      keyField="id"
      NoDataComponent={NoDataComponent}
      showPageJump
      showPagination
    />
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Abschläge vorhanden.</p>
    </div>
  );
}

function GenerateAdvancePaymentsPlansButton({
  onGenerateAdvancePaymentsPlans,
  year
}: {
  onGenerateAdvancePaymentsPlans: (year: number) => Promise<void>;
  year: number;
}) {
  const [submitting, setSubmitting] = useState(false);

  function handleClick() {
    setSubmitting(true);
    onGenerateAdvancePaymentsPlans(year).finally(() => {
      setSubmitting(false);
    });
  }

  return (
    <SpinButton
      className="create-button"
      color="brand"
      spin={submitting}
      onClick={handleClick}
    >
      Erstellen für {year}
    </SpinButton>
  );
}

interface ArchiveAdvancePaymentsButtonProps {
  selectedAdvancePaymentsPlans: Array<AdvancePaymentsPlan>;
  isArchiveLoading: boolean;
  onClick: (selected: Array<AdvancePaymentsPlan>) => void;
}

function ArchiveAdvancePaymentsButton({
  selectedAdvancePaymentsPlans,
  isArchiveLoading,
  onClick
}: ArchiveAdvancePaymentsButtonProps) {
  return (
    <SpinButton
      className="archive-button"
      color="brand"
      disabled={selectedAdvancePaymentsPlans.length === 0}
      spin={isArchiveLoading}
      onClick={() => onClick(selectedAdvancePaymentsPlans)}
    >
      Archivieren
    </SpinButton>
  );
}

function DeleteAdvancePaymentsButton({
  advancePaymentsPlans,
  setDeleteModalOpen
}) {
  return (
    <Button
      className="delete-button"
      color="danger"
      disabled={advancePaymentsPlans.length === 0}
      onClick={() => setDeleteModalOpen(true)}
    >
      Löschen
    </Button>
  );
}

interface AdvancePaymentsDataLoader {
  variantId: number;
  contracts: Array<Contract>;
  persons: Array<Person>;
}

function AdvancePaymentsPlansDataLoader({
  variantId,
  contracts,
  persons
}: AdvancePaymentsDataLoader) {
  const {
    data: advancePaymentsPlansResponse,
    isLoading: advancePaymentsPlanResponseLoading,
    error: advancePaymentsPlanResponseError
  } = useQuery({
    queryKey: ["advancePaymentsPlans", { variantId }],
    queryFn: () => fetchAdvancePayments(variantId)
  });
  const {
    data: archivedAdvancePaymentsPlansResponse,
    isLoading: archivedAdvancePaymentsPlanResponseLoading,
    error: archivedAdvancePaymentsPlanResponseError
  } = useQuery({
    queryKey: ["archivedAdvancePaymentsPlans", { variantId }],
    queryFn: () => fetchArchivedAdvancePayments(variantId)
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const queryClient = useQueryClient();

  const { setSelection, setSelectAll, getSelectedData, customReactTableProps } =
    useCustomReactTableCheckboxes<AdvancePaymentsPlan>();
  const {
    setSelection: setArchivedSelection,
    setSelectAll: setArchivedSelecteAll,
    getSelectedData: getArchivedSelectedData,
    customReactTableProps: archivedCustomReactTableProps
  } = useCustomReactTableCheckboxes<AdvancePaymentsPlan>();

  async function fetchAdvancePayments(
    variantId: number
  ): Promise<Array<AdvancePaymentsPlanResponse>> {
    const advancePaymentsPlansUrl = urls.api.advancePaymentsPlans(variantId);
    const response = await api.get<Array<AdvancePaymentsPlanResponse>>(
      advancePaymentsPlansUrl
    );

    return response.data;
  }

  async function fetchArchivedAdvancePayments(
    variantId: number
  ): Promise<Array<AdvancePaymentsPlanResponse>> {
    const advancePaymentsPlansUrl =
      urls.api.archivedAdvancePaymentsPlans(variantId);
    const response = await api.get<Array<AdvancePaymentsPlanResponse>>(
      advancePaymentsPlansUrl
    );

    return response.data;
  }

  const advancePaymentsPlans = useMemo(() => {
    if (advancePaymentsPlansResponse) {
      const newAdvancePaymentPlans: Array<AdvancePaymentsPlan> =
        advancePaymentsPlansResponse.map((advancePaymentsPlanResponse) => {
          const {
            advancePaymentsPlanData,
            ...advancePaymentsPlanWithoutDataId
          } = advancePaymentsPlanResponse;
          const { id, ...advancePaymentsPlanDataWithoutId } =
            advancePaymentsPlanData;
          const contract = contracts.find(
            (contract) => contract.id === advancePaymentsPlanData.contract
          );

          return {
            ...advancePaymentsPlanWithoutDataId,
            ...advancePaymentsPlanDataWithoutId,
            supplier: contract ? contract.supplier : undefined,
            supplied: contract ? contract.supplied : undefined
          };
        });

      return newAdvancePaymentPlans;
    }
  }, [advancePaymentsPlansResponse, contracts]);

  const archivedAdvancePaymentsPlans = useMemo(() => {
    if (archivedAdvancePaymentsPlansResponse) {
      const newAdvancePaymentPlans: Array<AdvancePaymentsPlan> =
        archivedAdvancePaymentsPlansResponse.map(
          (advancePaymentsPlanResponse) => {
            const {
              advancePaymentsPlanData,
              ...advancePaymentsPlanWithoutDataId
            } = advancePaymentsPlanResponse;
            const { id, ...advancePaymentsPlanDataWithoutId } =
              advancePaymentsPlanData;
            const contract = contracts.find(
              (contract) => contract.id === advancePaymentsPlanData.contract
            );

            return {
              ...advancePaymentsPlanWithoutDataId,
              ...advancePaymentsPlanDataWithoutId,
              supplier: contract ? contract.supplier : undefined,
              supplied: contract ? contract.supplied : undefined
            };
          }
        );

      return newAdvancePaymentPlans;
    }
  }, [archivedAdvancePaymentsPlansResponse, contracts]);

  async function handleGenerateAdvancePaymentsPlans(year: number) {
    const postUrl = urls.api.generateAdvancePaymentsPlans(variantId, year);

    return postDataAndPollResponse(postUrl, {})
      .then(() => {
        showToast(
          "success",
          "Alle angeforderten Abschlagspläne wurden erstellt."
        );
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: [
            "advancePaymentsPlans",
            {
              variantId
            }
          ]
        });
      });
  }

  function handleClickArchiveAdvancePaymentsPlans(
    selected: Array<AdvancePaymentsPlan>
  ) {
    if (!archivedAdvancePaymentsPlans) {
      return;
    }

    setIsArchiveLoading(true);

    const archivePromises = selected.map((item) => {
      const url = urls.api.archiveAdvancePaymentsPlan(item.id);
      return api.post(url);
    });

    let success = false;
    Promise.all(archivePromises)
      .then(() => {
        success = true;
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        if (success) {
          queryClient.invalidateQueries({
            queryKey: [
              "advancePaymentsPlans",
              {
                variantId
              }
            ]
          });
          queryClient.invalidateQueries({
            queryKey: [
              "archivedAdvancePaymentsPlans",
              {
                variantId
              }
            ]
          });
          setSelection([]);
          setSelectAll(false);
        }

        setIsArchiveLoading(false);
      });
  }

  function handleDeleteAdvancePaymentsPlans(
    selected: Array<AdvancePaymentsPlan>
  ) {
    if (!advancePaymentsPlans) {
      return;
    }

    setIsDeleteLoading(true);

    const deletePromises = selected.map((item) => {
      const url = urls.api.advancePaymentsPlan(item.id);
      return api.delete(url);
    });

    let success = false;
    Promise.all(deletePromises)
      .then(() => {
        success = true;
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        if (success) {
          queryClient.invalidateQueries({
            queryKey: [
              "archivedAdvancePaymentsPlans",
              {
                variantId
              }
            ]
          });
          setArchivedSelection([]);
          setArchivedSelecteAll(false);
        }

        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
      });
  }

  function getDeleteConfirmationModalText() {
    const numberOfSelected = getArchivedSelectedData().length;
    if (numberOfSelected === 1) {
      return `diesen Abschlagsplan`;
    } else {
      return `diese ${getArchivedSelectedData().length} Abschlagspläne`;
    }
  }

  return (
    <LoadOrError
      error={
        advancePaymentsPlanResponseError ||
        archivedAdvancePaymentsPlanResponseError
      }
      loading={
        advancePaymentsPlanResponseLoading ||
        archivedAdvancePaymentsPlanResponseLoading
      }
    >
      {advancePaymentsPlans && archivedAdvancePaymentsPlans && (
        <div className="AdvancePayments Invoices">
          <Row>
            <Col>
              <div className="controls">
                <h5>Aktive Abschlagspläne</h5>
                <GenerateAdvancePaymentsPlansButton
                  year={new Date().getFullYear()}
                  onGenerateAdvancePaymentsPlans={
                    handleGenerateAdvancePaymentsPlans
                  }
                />
                <GenerateAdvancePaymentsPlansButton
                  year={new Date().getFullYear() + 1}
                  onGenerateAdvancePaymentsPlans={
                    handleGenerateAdvancePaymentsPlans
                  }
                />
                <ArchiveAdvancePaymentsButton
                  isArchiveLoading={isArchiveLoading}
                  selectedAdvancePaymentsPlans={getSelectedData()}
                  onClick={handleClickArchiveAdvancePaymentsPlans}
                />
              </div>
              <AdvancePaymentsPlans
                advancePaymentsPlans={advancePaymentsPlans}
                persons={persons}
                {...customReactTableProps}
              />
            </Col>
          </Row>
          <Row className="archived-row">
            <Col>
              <div className="controls">
                <h5>Archivierte Abschlagspläne</h5>
                <DeleteAdvancePaymentsButton
                  advancePaymentsPlans={getArchivedSelectedData()}
                  setDeleteModalOpen={setDeleteModalOpen}
                />
                <DeleteConfirmationModal
                  isLoading={isDeleteLoading}
                  isModalOpen={deleteModalOpen}
                  name={getDeleteConfirmationModalText()}
                  toggleModal={() => {
                    setDeleteModalOpen(!deleteModalOpen);
                  }}
                  onClickConfirm={() =>
                    handleDeleteAdvancePaymentsPlans(getArchivedSelectedData())
                  }
                />
              </div>
              <AdvancePaymentsPlans
                advancePaymentsPlans={archivedAdvancePaymentsPlans}
                persons={persons}
                {...archivedCustomReactTableProps}
              />
            </Col>
          </Row>
        </div>
      )}
    </LoadOrError>
  );
}

export { AdvancePaymentsPlansDataLoader as AdvancePaymentsPlans };
