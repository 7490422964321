import type { Table } from "@tanstack/react-table";
import classNames from "classnames";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import {
  DOTS,
  usePagination
} from "../CustomTablePagination/hooks/usePagination";
import { Input } from "../DynamicForm/FormItems/FormField/Input/Input";
import "./TablePagination.scss";
import type { SelectItem } from "../Select/Select";
import { Select } from "../Select/Select";

const PAGE_SIZE_OPTIONS = [5, 10, 20, 25, 50, 100];

interface TablePaginationProps<TData> {
  table: Table<TData>;
  pageSizeOptions?: Array<number>;
  showPageJump: boolean;
}

function TablePagination<TData>({
  table,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  showPageJump
}: TablePaginationProps<TData>) {
  const paginationRange = usePagination({
    currentPage: table.getState().pagination.pageIndex,
    totalCount: table.getRowCount(),
    pages: table.getPageCount(),
    pageSize: table.getState().pagination.pageSize
  });

  const choices: Array<SelectItem<number>> = pageSizeOptions.map(
    (value) =>
      ({
        key: value,
        value: value,
        text: `${value} / Seite`
      }) as SelectItem<number>
  );

  return (
    <div className="TablePagination">
      <div className="table-pagination-range-container">
        <button
          aria-label="left-caret-btn"
          className="table-pagination-btn"
          disabled={!table.getCanPreviousPage()}
          role="prevPageButton"
          onClick={() => table.previousPage()}
        >
          <Icon name={IconName.AngleLeft} />
        </button>
        {paginationRange &&
          paginationRange.map((pageNumber, idx) => {
            if (pageNumber === DOTS)
              return (
                <div
                  className="table-pagination-dots"
                  key={idx}
                  title="pagination-ellipsis"
                />
              );

            return (
              <button
                aria-label="page-number-btn"
                className={classNames("table-pagination-range-btn", {
                  "table-pagination-range-btn__active":
                    table.getState().pagination.pageIndex ===
                    Number(pageNumber) - 1
                })}
                key={idx}
                role={`page${pageNumber}Button`}
                onClick={() => table.setPageIndex(Number(pageNumber) - 1)}
              >
                {pageNumber}
              </button>
            );
          })}
        <button
          aria-label="right-caret-btn"
          className="table-pagination-btn"
          disabled={!table.getCanNextPage()}
          role="nextPageButton"
          onClick={() => table.nextPage()}
        >
          <Icon name={IconName.AngleRight} />
        </button>
      </div>

      <div className="table-page-size-select">
        <Select
          defaultValue={table.getState().pagination.pageSize}
          items={choices}
          title="Seitengröße"
          onChange={(newValue: number) => table.setPageSize(newValue)}
        />
      </div>

      {showPageJump && (
        <div className="table-page-input-field">
          <Input
            allowNegative={false}
            decimalScale={0}
            isAllowed={(values) => {
              const { floatValue, value } = values;
              if (value === "") return true;

              if (floatValue !== undefined)
                return (
                  Number.isInteger(floatValue) &&
                  floatValue >= 1 &&
                  floatValue <= table.getPageCount()
                );

              return false;
            }}
            name="page"
            placeholder="Seite"
            thousandSeparator=""
            type="number"
            value={table.getState().pagination.pageIndex + 1}
            onChange={(newValue: number) => table.setPageIndex(newValue - 1)}
          />
        </div>
      )}
    </div>
  );
}

export { TablePagination };
