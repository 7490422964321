import PropTypes from "prop-types";
import { PureComponent } from "react";
import { PulseLoader } from "react-spinners";
import api from "../../api";
import urls from "../../urls";
import { THEME_VARS } from "../../utils/constants";
import { Button } from "../Buttons/Button/Button";
import loader from "../Loader";
import FormItems from "./FormItems/FormItems";
import "./DynamicForm.scss";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export default class DynamicForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
      formErrors: {},
      accessedVariables: [],
      complete: false,
      loading: false,
      title: null,
      explanation: null
    };
  }

  evaluate() {
    this.getEvaluationData()
      .then((data) => {
        this.parseEvaluationData(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getEvaluationData() {
    const response = await api.get(this.props.requestUrl);
    return response.data;
  }

  parseEvaluationData(data) {
    const parsedVariables = data.accessedVariables.filter(
      (variable) => variable.name !== "_evaluation"
    );

    const initialValues = parsedVariables.reduce((obj, av) => {
      obj[av.name] = av.initialValue;
      return obj;
    }, {});

    this.setState(
      {
        title: data.ruleTitle,
        explanation: data.ruleExplanation,
        accessedVariables: parsedVariables,
        formValues: initialValues,
        complete: data.complete,
        successRedirectUrl: data.redirectUrl
      },
      () => {
        if (this.props.onComplete) {
          this.props.onComplete(data.complete);
        }
      }
    );
  }

  handleInput = (name, value, updateEvaluation = true) => {
    const stateUpdateCallback = () => {
      if (updateEvaluation) {
        this.updateEvaluation();
      }
    };
    this.updateFormValuesAndResetErrors(name, value, stateUpdateCallback);
  };

  handleDone = () => {
    const { onDone } = this.props;
    const { successRedirectUrl } = this.state;

    if (successRedirectUrl) {
      window.location.href = successRedirectUrl;
    }

    if (onDone) {
      onDone();
    }
  };

  updateFormValuesAndResetErrors(name, value, stateUpdateCallback) {
    this.setState((previousState) => {
      return {
        formValues: {
          ...previousState.formValues,
          [name]: value
        },
        formErrors: {
          ...previousState.formErrors,
          [name]: undefined
        }
      };
    }, stateUpdateCallback);
  }

  updateEvaluation() {
    const { formValues } = this.state;

    this.setState({
      loading: true
    });

    if (this.props.onLoading) {
      this.props.onLoading();
    }

    api
      .put(this.props.requestUrl, formValues)
      .then((response) => {
        this.parseEvaluationData(response.data);

        this.setState({
          loading: false
        });
      })
      .catch((error) => {
        // there was an error, so we need to set the error state for this input
        this.setState({
          formErrors: error.response.data,
          complete: false,
          loading: false
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.requestUrl &&
      this.props.requestUrl !== prevProps.requestUrl
    ) {
      this.evaluate();
    }
  }

  componentDidMount() {
    if (
      !this.state.accessedVariables ||
      this.state.accessedVariables.length === 0
    ) {
      this.evaluate();
    }
  }

  render() {
    const { buttonContainer, hideDoneButton, onDone, onSubmit } = this.props;

    const {
      accessedVariables,
      formValues,
      formErrors,
      loading,
      complete,
      title,
      explanation,
      successRedirectUrl
    } = this.state;

    const showDoneButton =
      !hideDoneButton && complete && (successRedirectUrl || onDone);

    return (
      <div className="DynamicForm">
        {title && <h6>{title}</h6>}
        {explanation && <p>{explanation}</p>}
        <FormItems
          formErrors={formErrors}
          formItems={accessedVariables}
          formValues={formValues}
          onInput={this.handleInput}
          onSubmit={onSubmit || this.handleDone}
        />
        <Loader loading={loading} />
        {showDoneButton && (
          <DoneButton
            buttonContainer={buttonContainer}
            loading={loading}
            onDone={this.handleDone}
          />
        )}
      </div>
    );
  }
}

function DoneButton({ loading, buttonContainer, onDone }) {
  const doneButton = (
    <Button
      className="submit-button"
      color="primary"
      disabled={loading}
      type="submit"
      onClick={onDone}
    >
      Weiter
    </Button>
  );

  if (buttonContainer) {
    return (
      <>
        {ReactDOM.createPortal(
          <div className="dynamic-form-controls-inner">{doneButton}</div>,
          buttonContainer
        )}
      </>
    );
  } else {
    return <>{doneButton}</>;
  }
}

function Loader({ loading }) {
  if (!loading) {
    return null;
  }

  return (
    <div className="loader">
      <PulseLoader color={THEME_VARS.brandColor} loading={true} />
    </div>
  );
}

DynamicForm.propTypes = {
  requestUrl: PropTypes.string.isRequired,
  buttonContainer: PropTypes.object,
  hideDoneButton: PropTypes.bool,
  onLoading: PropTypes.func,
  onComplete: PropTypes.func,
  onDone: PropTypes.func,
  onSubmit: PropTypes.func
};

if (typeof window !== "undefined") {
  window.ReactDOM = ReactDOM;
}

class DynamicFormLoaderWrapper extends PureComponent {
  render() {
    const { evaluationId, ...passThroughProps } = this.props;

    const requestUrl = urls.api.evaluationDetail(evaluationId);

    return <DynamicForm {...passThroughProps} requestUrl={requestUrl} />;
  }
}

export const DynamicFormWithLoader = loader(DynamicFormLoaderWrapper);
