import type { TextBlockElement } from "../../../utils/backend-types";
import type { CustomTextElementNodeType } from "../CustomGraphComponents/CustomTextElementNode/CustomTextElementNode";

export function getFlowNodesFromTextBlocks(
  textBlockElements: Array<TextBlockElement>,
  siteId: number
): Array<CustomTextElementNodeType> {
  return textBlockElements.map((textBlockElement) => {
    return {
      id: textBlockElement.id.toString(),
      position: {
        x: textBlockElement.x,
        y: textBlockElement.y
      },
      data: {
        fontWeight: textBlockElement.fontWeight,
        fontSize: textBlockElement.fontSize,
        site: siteId,
        text: textBlockElement.text
      },
      type: "text"
    };
  });
}
