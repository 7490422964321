import { DateTime } from "luxon";
import { useContext } from "react";
import type { Site } from "../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { useParkabrechnungBelieferten } from "../hooks/useParkabrechnungBelieferten";
import { ParkabrechnungContext } from "../ParkabrechnungContext";
import { ParkabrechnungContracts } from "./ParkabrechnungContracts/ParkabrechnungContracts";
import type { ParkabrechnungTag } from "./ParkabrechnungData/ParkabrechnungData";
import {
  PARKABRECHNUNG_TAGS,
  ParkabrechnungData
} from "./ParkabrechnungData/ParkabrechnungData";
import "./ParkabrechnungOverview.scss";
import { SiteSelector } from "./SiteSelector/SiteSelector";

type ParkabrechnungOverviewProps = {
  sites: Array<Site>;
  variantId: number;
  projectId: string;
  abrechnungType: ParkabrechnungTag;
};

export function ParkabrechnungOverview({
  projectId,
  sites,
  variantId,
  abrechnungType
}: ParkabrechnungOverviewProps) {
  const { selectedSite, selectedCompany, selectedEndDate, selectedStartDate } =
    useContext(ParkabrechnungContext);

  function getStartDate(selectedStartDate: DateTime | undefined) {
    if (typeof selectedStartDate === "undefined") {
      const startOfLastYear = DateTime.now().minus({ year: 1 }).startOf("year");
      return luxonDateTimeToBackendDateOrDateTime(startOfLastYear, "ISO 8601");
    }

    return luxonDateTimeToBackendDateOrDateTime(selectedStartDate, "ISO 8601");
  }

  function getEndDate(selectedEndDate: DateTime | undefined) {
    if (typeof selectedEndDate === "undefined") {
      const endOfLastYear = DateTime.now().minus({ year: 1 }).endOf("year");
      return luxonDateTimeToBackendDateOrDateTime(endOfLastYear, "ISO 8601");
    }

    return luxonDateTimeToBackendDateOrDateTime(selectedEndDate, "ISO 8601");
  }

  const startDate = getStartDate(selectedStartDate);
  const endDate = getEndDate(selectedEndDate);

  const { data: beliefertenData } = useParkabrechnungBelieferten(
    projectId,
    {
      period: {
        startDate,
        endDate
      },
      siteId: selectedSite?.id.toString()
    },
    Boolean(
      abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung &&
        typeof selectedSite !== "undefined"
    )
  );

  return (
    <div className="netzbezug-overview-container">
      {abrechnungType === PARKABRECHNUNG_TAGS.vertrag ? (
        <ParkabrechnungContracts sites={sites} variantId={variantId} />
      ) : abrechnungType === PARKABRECHNUNG_TAGS.netzbezug ||
        (abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung &&
          selectedSite &&
          selectedCompany) ? (
        <ParkabrechnungData
          abrechnungType={abrechnungType}
          companies={beliefertenData ?? []}
          projectId={projectId}
          selectedSite={selectedSite}
          sites={sites}
          variantId={variantId}
        />
      ) : (
        <div className="site-selector-wrapper">
          <SiteSelector
            abrechnungType={abrechnungType}
            companies={beliefertenData ?? []}
            label={"Wählen Sie die Liegenschaft und das Unternehmen aus: "}
            sites={sites}
          />
        </div>
      )}
    </div>
  );
}
