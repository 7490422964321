import type { Edge } from "@xyflow/react";
import {
  ConnectionPointDirection,
  type EdgeData
} from "../StructureViewFlowDiagram.types";

export function getFlowEdgesFromEdges(edges: Array<EdgeData>): Array<Edge> {
  return edges.map((edge) => {
    return {
      id: edge.id.toString(),
      source: edge.from.toString(),
      target: edge.to.toString(),
      targetHandle: edge.toConnectionPoint ?? ConnectionPointDirection.Left,
      sourceHandle: edge.fromConnectionPoint ?? ConnectionPointDirection.Right
    };
  });
}
