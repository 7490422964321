import { Center } from "@mantine/core";
import { Link } from "react-router-dom";

import { ROUTES } from "../../routes";
import type { SiteName } from "../../utils/enums";
import { SiteLogo } from "./SiteLogo";

interface Props {
  siteName: SiteName;
  url?: string;
  isAdminDashboardPage?: boolean;
}

function Brand({
  siteName,
  url = ROUTES.productSelectPage,
  isAdminDashboardPage
}: Props) {
  return (
    <Center bg={"var(--brand-logo-background)"}>
      {isAdminDashboardPage ? (
        <a className="m-brand__logo-wrapper" href={url}>
          <SiteLogo siteName={siteName} useSecondaryImage />
        </a>
      ) : (
        <Link className="m-brand__logo-wrapper" to={url}>
          <SiteLogo siteName={siteName} useSecondaryImage />
        </Link>
      )}
    </Center>
  );
}

export { Brand };
