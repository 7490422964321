import { TagsInput } from "@mantine/core";

import { FormItemLabel } from "../../../FormItem/FormItem";
import type { FormFieldData, FormItemsProps } from "../../FormItems";
import "./MultipleEmails.scss";

interface ExtraProps {
  variantId: number;
  componentId: number;
}

interface MultipleEmailsProps
  extends Omit<FormItemsProps, "formItems" | "formValues">,
    ExtraProps {
  formValues: Array<{ emailAddress: string }>;
  fieldNames: Array<string>;
  formItems: Record<string, FormFieldData>;
}

function MultipleEmails({
  fieldNames,
  formItems,
  formValues,
  formName,
  allowInput,
  onInput
}: MultipleEmailsProps) {
  const EMAIL_FIELD_NAME = fieldNames[0];
  const formItem = formItems[EMAIL_FIELD_NAME];

  const formValue = formValues[EMAIL_FIELD_NAME] || [];
  const formItemId = `id_${
    formName ? formName : "form"
  }_${formItem.name.replace(/\./g, "__")}`;

  const handleChange = (newValue: string[]) => {
    const validEmails = newValue.filter((email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    });

    const emailObjects = validEmails.map((email) => ({
      emailAddress: email
    }));

    onInput(EMAIL_FIELD_NAME, emailObjects);
  };

  const displayValues = formValue.map((item) => item.emailAddress);

  return (
    <div className="MultipleEmails FormField">
      <div className="toggler-label">
        <FormItemLabel
          helpText={formItem.helpText}
          id={formItemId}
          instance={formItem.instance}
          label={formItem.label}
          required={formItem.required}
        />
      </div>

      <TagsInput
        clearable
        data={[]}
        disabled={!allowInput}
        maxTags={5}
        required={formItem.required}
        splitChars={[",", " "]}
        type="email"
        value={displayValues}
        onChange={handleChange}
      />
    </div>
  );
}

export { MultipleEmails };
