import type { DateTime } from "luxon";
import { useState } from "react";
import { showToast } from "../../../utils/toast";
import { DateInput } from "../../BuildingBlocks/Dates/DateInput/DateInput";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { useMieterstromMBKCreation } from "../EdaVisualization/hooks/useMbks";
import "./CreateMieterstromMBKModal.scss";

type CreateMieterstromMBKModalProps = {
  isOpen: boolean;
  onClose: () => void;
  siteId: number;
  onVersionCreated: () => void;
};

export function CreateMieterstromMBKModal({
  isOpen,
  onClose,
  siteId,
  onVersionCreated
}: CreateMieterstromMBKModalProps) {
  const [firstDate, setFirstDate] = useState<DateTime>();

  const handleMBKCreationSuccess = () => {
    showToast(
      "success",
      "Mess- und Bilanzierungskonzept erfolgreich erstellt."
    );
    onVersionCreated();
    onClose();
  };

  const handleMBKCreationError = (error) => {
    showToast("error", error);
  };

  const { mutate: createMieterstromMBK, isPending } =
    useMieterstromMBKCreation(siteId);

  const submit = () => {
    if (firstDate) {
      createMieterstromMBK(
        { firstDate },
        { onSuccess: handleMBKCreationSuccess, onError: handleMBKCreationError }
      );
    }
  };

  return (
    <Modal isOpen={isOpen} size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>{MODAL_HEADER_LABEL}</ModalHeader>
      <ModalBody scrollable>
        {DATEPICKER_LABEL}
        <div className="datepicker-container">
          <DateInput
            date={firstDate}
            id={DATEPICKER_LABEL}
            onChange={(e) => setFirstDate(e ?? undefined)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>{CANCEL_BUTTON_LABEL}</Button>
        {typeof firstDate !== "undefined" ? (
          <SpinButton
            color="brand"
            spin={isPending}
            onClick={() => {
              submit();
            }}
          >
            {SAVE_BUTTON_LABEL}
          </SpinButton>
        ) : (
          <Button disabled>{SAVE_BUTTON_LABEL}</Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

const MODAL_HEADER_LABEL =
  "Ab wann ist das Mess- und Bilanzierungskonzept gültig?";
const DATEPICKER_LABEL =
  "Bitte wählen Sie das gewünschte Startdatum für Ihr Mess- und Bilanzierungskonzept.";
const CANCEL_BUTTON_LABEL = "Abbrechen";
const SAVE_BUTTON_LABEL = "Mess- und Bilanzierungskonzept erstellen";
