import type { RefetchOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import type { Column } from "react-table";
import type { GeneratorChangesFromMastr } from "../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { DATE_DISPLAY_FORMAT } from "../../../../utils/dates/dates.constants";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../CustomReactTable/CustomReactTable.types";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
const COLUMNS: Array<Column> = [
  {
    Header: "Feld",
    accessor: "fieldVerboseName"
  },
  {
    Header: "Alter Wert",
    accessor: "oldValue",
    Cell: ParsedValueCell
  },
  {
    Header: "Neuer Wert",
    accessor: "newValue",
    Cell: ParsedValueCell
  }
];

interface GeneratorOverwriteConfirmationModalProps {
  possibleChanges?: Array<GeneratorChangesFromMastr> | Array<never>;
  isGettingPossibleChanges: boolean;
  isOverwritingData: boolean;
  isGeneratorOverwriteConfirmationModalOpen: boolean;
  refetchPossibleChanges: (options?: RefetchOptions) => void;
  onOverwriteGeneratorData: () => void;
  onCloseGeneratorOverwriteConfirmationModal: () => void;
}

function GeneratorOverwriteConfirmationModal({
  possibleChanges,
  isGettingPossibleChanges,
  isOverwritingData,
  isGeneratorOverwriteConfirmationModalOpen,
  refetchPossibleChanges,
  onOverwriteGeneratorData,
  onCloseGeneratorOverwriteConfirmationModal
}: GeneratorOverwriteConfirmationModalProps) {
  useEffect(() => {
    refetchPossibleChanges();
  }, [refetchPossibleChanges]);

  return (
    <BasicConfirmationModal
      cancelButtonText="Abbrechen"
      confirmationButtonColor="warning"
      confirmationButtonText="Fortfahren"
      headerText="Daten des Erzeugers werden überschrieben"
      isLoading={isOverwritingData || isGettingPossibleChanges}
      isModalOpen={isGeneratorOverwriteConfirmationModalOpen}
      size="lg"
      toggleModal={onCloseGeneratorOverwriteConfirmationModal}
      onCancel={onCloseGeneratorOverwriteConfirmationModal}
      onConfirm={onOverwriteGeneratorData}
    >
      <ModalBody
        isGettingPossibleChanges={isGettingPossibleChanges}
        possibleChanges={possibleChanges}
      />
    </BasicConfirmationModal>
  );
}

interface ModalBodyProps {
  possibleChanges?: Array<GeneratorChangesFromMastr>;
  isGettingPossibleChanges: boolean;
}

function ModalBody({
  possibleChanges,
  isGettingPossibleChanges
}: ModalBodyProps) {
  if (isGettingPossibleChanges) {
    return (
      <div>
        <p>
          Die neuesten Daten werden aus dem Marktstammdatenregister abgerufen.
        </p>
        <AnimatedLoadingIcon />
      </div>
    );
  }

  return (
    <div>
      {possibleChanges && possibleChanges.length > 0 ? (
        <>
          <p>
            Alle Daten des Erzeugers in opti.node werden automatisch durch die
            entsprechenden Daten der Anlage im Marktstammdatenregister
            überschrieben.
          </p>
          <p>Wollen Sie fortfahren?</p>
        </>
      ) : (
        <p>
          Die Daten auf dem Erzeuger stimmen mit den Daten im
          Marktstammdatenregister überein. Eine Aktualisierung ist nicht
          notwendig.
        </p>
      )}

      {possibleChanges && possibleChanges.length > 0 && (
        <CustomReactTable columns={COLUMNS} data={possibleChanges} />
      )}
    </div>
  );
}

function ParsedValueCell(
  cellInfo: TypedCellInfo<string | null, GeneratorChangesFromMastr>
) {
  if (!cellInfo.value) {
    return "—";
  }

  if (cellInfo.original.fieldName === "registrierungsdatum") {
    return backendDateOrDateTimeToLuxonDateTime(cellInfo.value).toFormat(
      DATE_DISPLAY_FORMAT
    );
  }

  return cellInfo.value;
}

export {
  GeneratorOverwriteConfirmationModal,
  GeneratorOverwriteConfirmationModalProps
};
