import { Paper, Title } from "@mantine/core";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { GuaranteeOfOriginGenerationView } from "./GuaranteeOfOriginGenerationView/GuaranteeOfOriginGenerationView";
import { useGOSites } from "./hooks/useGOSites";

export interface GuaranteeOfOriginPageProps {
  variantId: number;
}
function GuaranteeOfOriginPage({ variantId }: GuaranteeOfOriginPageProps) {
  const { sites, loading, error } = useGOSites(variantId);
  return (
    <Paper>
      <Title variant="paper-header">Herkunftsnachweis</Title>
      <LoadOrError error={error} loading={loading}>
        <GuaranteeOfOriginGenerationView sites={sites} />
      </LoadOrError>
    </Paper>
  );
}

export { GuaranteeOfOriginPage };
