import classnames from "classnames";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useVariantDetail } from "../../../hooks/useVariantDetail";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { DEFAULT_SUBPAGE, TABS } from "../Paragraph6View";
import "./Paragraph6Nav.scss";

export interface Paragraph6NavProps {
  activeTab?: string;
  variantId: number;
}

export function Paragraph6Nav({
  activeTab = DEFAULT_SUBPAGE,
  variantId
}: Paragraph6NavProps) {
  const {
    isLoading: paymentInformationIsLoading,
    error: paymentInformationError,
    variantDetail
  } = useVariantDetail(variantId);

  const usePaymentInformation =
    variantDetail?.paragraph_6_payment_information_instead_of_credit;

  const isUsingPaymentInformation =
    usePaymentInformation === true &&
    !paymentInformationIsLoading &&
    !paymentInformationError;
  const isUsingCredits =
    usePaymentInformation === false &&
    !paymentInformationIsLoading &&
    !paymentInformationError;

  return (
    <Nav className="Paragraph6Nav tabs" pills>
      <NavItem>
        <NavLink
          className={classnames({
            active: activeTab === TABS.CONTRACTS
          })}
          tag={Link}
          to={`../${TABS.CONTRACTS}`}
        >
          Verträge
        </NavLink>
      </NavItem>
      <LoadOrError loading={paymentInformationIsLoading}>
        {isUsingCredits && (
          <>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TABS.CREDITS
                })}
                tag={Link}
                to={`../${TABS.CREDITS}`}
              >
                Gutschriften
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TABS.CREDIT_NUMBER_RANGES
                })}
                tag={Link}
                to={`../${TABS.CREDIT_NUMBER_RANGES}`}
              >
                Gutschriftennummernkreise
              </NavLink>
            </NavItem>
          </>
        )}
        {isUsingPaymentInformation && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.PAYMENT_INFORMATION
              })}
              tag={Link}
              to={`../${TABS.PAYMENT_INFORMATION}`}
            >
              Zahlungsinformationen
            </NavLink>
          </NavItem>
        )}
      </LoadOrError>
    </Nav>
  );
}
