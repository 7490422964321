import { useEffect } from "react";
import type { Control, FieldError, FieldPath } from "react-hook-form";
import { showToast } from "../../../../../../utils/toast";
import type { FormInputData } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import type { SiteForForm } from "../../SiteForm.constants";
import "./BatchImportUpload.scss";
import { BatchImportErrorDisplay } from "./BatchImportErrorDisplay/BatchImportErrorDisplay";
import { useValidateBatchTemplate } from "./hooks/useValidateBatchTemplate";

interface BatchImportUploadProps {
  data: FormInputData<SiteForForm, FieldPath<SiteForForm>>;
  control: Control<SiteForForm>;
  error: FieldError | undefined;
  template: File | undefined;
  onReset: () => void;
  onTemplateHasErrors: (value: boolean) => void;
}
function BatchImportUpload({
  data,
  control,
  error,
  template,
  onReset,
  onTemplateHasErrors
}: BatchImportUploadProps) {
  const {
    data: checkErrors,
    isLoading,
    error: fetchError
  } = useValidateBatchTemplate(template);

  useEffect(() => {
    if (typeof checkErrors !== "undefined") {
      const hasErrors = checkErrors.length > 0;

      if (!hasErrors) {
        showToast("success", "Erfassungsbogen erfolgreich hochgeladen");
      }

      onTemplateHasErrors(hasErrors);
    }
  }, [checkErrors, onTemplateHasErrors]);

  return (
    <>
      <FormFieldController control={control} data={data} error={error} />
      <LoadOrError error={fetchError} loading={isLoading}>
        {checkErrors && checkErrors.length > 0 && (
          <BatchImportErrorDisplay errors={checkErrors} />
        )}
        {template && (
          <div className="uploaded-template">
            <div className="template-name">
              <Icon className="template-icon" name={IconName.FileExcel} />
              <div>{template.name}</div>
            </div>
            <div>
              <Icon
                className="remove-template-icon"
                name={IconName.Trash}
                onClick={onReset}
              />
            </div>
          </div>
        )}
      </LoadOrError>
    </>
  );
}

export { BatchImportUpload };
