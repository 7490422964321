import { ActionIcon, Tooltip } from "@mantine/core";
import { DeleteIcon } from "../../../../components/Buttons/DeleteIcon";

interface DeleteButtonProps {
  disabled?: boolean;
  label?: string;
  onClick: () => void;
}

function DeleteButton({
  disabled,
  label = "Löschen",
  onClick
}: DeleteButtonProps) {
  return (
    <Tooltip label={label}>
      <ActionIcon
        aria-label={label}
        disabled={disabled}
        variant="transparent"
        onClick={onClick}
      >
        <DeleteIcon disabled={disabled} />
      </ActionIcon>
    </Tooltip>
  );
}

export { DeleteButton, DeleteButtonProps };
