import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";

interface SearchBarButtonProps {
  isLoading: boolean;
  btnText: string;
  disabled: boolean;
  onSearch: () => void;
}

function SearchBarButton({
  isLoading,
  btnText,
  disabled,
  onSearch
}: SearchBarButtonProps) {
  return (
    <Button color="brand" disabled={disabled} onClick={onSearch}>
      {isLoading ? (
        <span className="spinner">
          <Icon className="spinner-icon" name={IconName.SpinnerSpinning} />
        </span>
      ) : (
        <>
          <Icon name={IconName.Search} style={{ marginRight: "6px" }} />
          {btnText}
        </>
      )}
    </Button>
  );
}

export { SearchBarButton };
