import { Group, Text } from "@mantine/core";
import {
  type Control,
  type FieldErrors,
  type UseFormWatch
} from "react-hook-form";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { IconHelpText } from "../../../IconHelpText/IconHelpText";
import { type SiteSetupProcessForForm } from "../../SiteSetupAssistant.types";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

interface ConsumerFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
}

function ConsumerFormItems({
  formControl,
  formErrors,
  watch
}: ConsumerFormItemsProps) {
  const consumers = watch("consumers");
  const siteHasBatteryStorage = watch("siteHasBatteryStorage");
  return (
    <div className="ConsumerFormItems">
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.siteHasBatteryStorage}
        error={formErrors.siteHasBatteryStorage}
      />
      {siteHasBatteryStorage && (
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.isBatteryStorageMeasuredSeparately}
          error={formErrors.isBatteryStorageMeasuredSeparately}
        />
      )}

      <Group align="center" gap="sm" mb="md" mt="xl">
        <Text fw={500}>Verbrauchsstellen</Text>
        <IconHelpText
          helpText="Verbrauchsstellen sind Orte, an denen Strom innerhalb der 
        Liegenschaft verbraucht wird. Verbrauchsstellen müssen immer einem Kunden zuordenbar 
        sein. Eine Fabrikhalle oder eine einzelne Wohnung sind bspw. eine Verbrauchstelle. Zumeist 
        sind Verbrauchsstellen mit einem Zähler abgegrenzt. Es gibt aber auch Ausnahmen: 
        Ungemessene Verbraucher, welche als Differenz angrenzender Zähler ermittelt werden. Oder 
        Verbraucher, deren Stromverbrauch über einen gemeinsamen Zähler erfasst wird und dann 
        bspw. nach Fläche auf die Kunden aufgeteilt wird."
        />
      </Group>
      <FormFieldController
        control={formControl}
        data={{
          ...SITE_SETUP_ASSISTANT_FORM.consumerAmount,
          disabled: Array.isArray(consumers) && consumers.length > 0
        }}
        error={formErrors.consumerAmount}
      />
      <FormFieldController
        control={formControl}
        data={{
          ...SITE_SETUP_ASSISTANT_FORM.defaultConsumerType,
          disabled: Array.isArray(consumers) && consumers.length > 0
        }}
        error={formErrors.defaultConsumerType}
      />
      <FormFieldController
        control={formControl}
        data={{
          ...SITE_SETUP_ASSISTANT_FORM.defaultConsumerMeasurementType,
          disabled: Array.isArray(consumers) && consumers.length > 0
        }}
        error={formErrors.defaultConsumerMeasurementType}
      />
    </div>
  );
}

export { ConsumerFormItems };
