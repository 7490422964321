import { useCallback, useState } from "react";

import urls from "../../../urls";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import loader from "../../Loader";
import { EdaForm } from "../EdaForm/EdaForm";

const EdaFormWithLoader = loader(EdaForm);

function EditEdaModal(props) {
  const { isOpen, acquisitionId, siteId, onToggle, onUpdate } = props;

  const [footerNode, setFooterNode] = useState(null);
  const footerRef = useCallback((node) => {
    if (node !== null) {
      setFooterNode(node);
    }
  }, []);

  function handleSubmit(data) {
    onUpdate(data);
  }

  if (!acquisitionId) {
    return null;
  }

  const optionsUrl = urls.api.energyDataAcquisitionsCrudDetail(acquisitionId);
  const putUrl = urls.api.energyDataAcquisitionsCrudDetail(acquisitionId);
  const dataUrls = {
    initialValues: putUrl
  };

  return (
    <Modal
      className="EditEdaModal"
      fade={false}
      isOpen={isOpen}
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>
        Energiedatenerfassung bearbeiten
      </ModalHeader>
      <ModalBody scrollable>
        <EdaFormWithLoader
          buttonContainer={footerNode}
          dataUrls={dataUrls}
          editing={true}
          optionsUrl={optionsUrl}
          putUrl={putUrl}
          siteId={siteId}
          onCancel={onToggle}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={footerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { EditEdaModal };
