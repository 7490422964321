import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Generator } from "../utils/backend-types";

export function useGenerators(siteId?: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["generators", { siteOrVariantId: siteId }],
    // siteOrVariantId to be the same as in useConfiguratorComponents.tsx to avoid double-fetch
    queryFn: () => fetchGenerators(siteId),
    enabled: !!siteId,
    staleTime: 10000
  });

  async function fetchGenerators(sideId?: number) {
    if (typeof sideId === "undefined") {
      return undefined;
    }

    const response = await api.get<Array<Generator>>(
      urls.api.generators(sideId)
    );

    return response.data;
  }

  return { data, isLoading, error };
}
