import { createContext, useCallback, useState } from "react";
import { StructureViewMode } from "./StructureView.constants";

interface StructureViewModeContextType {
  structureViewMode: StructureViewMode;
  updateStructureViewMode: (newValue: StructureViewMode) => void;
}

export const StructureViewModeContext =
  createContext<StructureViewModeContextType>({
    structureViewMode: StructureViewMode.New,
    updateStructureViewMode: () => {}
  });

export function StructureViewModeContextProvider({ children }) {
  const [structureViewMode, setStructureViewMode] = useState<StructureViewMode>(
    StructureViewMode.New
  );

  const updateStructureViewMode = useCallback(
    (newValue: StructureViewMode) => {
      setStructureViewMode(newValue);
    },
    [setStructureViewMode]
  );

  return (
    <StructureViewModeContext.Provider
      value={{ structureViewMode, updateStructureViewMode }}
    >
      {children}
    </StructureViewModeContext.Provider>
  );
}
