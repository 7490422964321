import { generatePath } from "react-router-dom";
import type { Column } from "react-table";
import { ROUTES } from "../../../../../routes";
import { ObjectUrlName } from "../../../../../utils/enums";
import { Badge } from "../../../../BuildingBlocks/Badge/Badge";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type {
  LocationOverviewData,
  LocationType,
  MarketOrMeteringLocationOverview
} from "../../LocationOverview.types";

function getLocationColumn() {
  return {
    Header: "Lokationen",
    id: "locationName",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="locations">
        {cellInfo.value.map((overview) => (
          <div className="sub-row" key={overview.location.id}>
            <LocationName
              componentId={overview.location.id}
              name={overview.location.name}
              projectId={overview.location.project}
              siteId={overview.location.site.id}
              type={overview.location.type}
            />
            <Badge
              color={
                overview.location.status === "aktiv" ? "brand" : "secondary"
              }
            >
              {overview.location.status === "aktiv" ? "Aktiv" : "Inaktiv"}
            </Badge>
          </div>
        ))}
      </div>
    ),
    sortable: false,
    width: 350
  } satisfies Column<LocationOverviewData>;
}

interface LocationNameProps {
  name: string;
  type: LocationType;
  projectId: string;
  siteId: number;
  componentId: number;
}

function LocationName({
  name,
  type,
  projectId,
  siteId,
  componentId
}: LocationNameProps) {
  const objectUrlName =
    type.isMarketLocation === true
      ? ObjectUrlName.MarketLocations
      : ObjectUrlName.MeteringLocations;

  const path = generatePath(ROUTES.managerVariantStructureComponent, {
    projectId,
    siteId: siteId.toString(),
    objectUrlName: objectUrlName,
    componentId: componentId.toString()
  });

  return <a href={path}>{name}</a>;
}

export { getLocationColumn };
