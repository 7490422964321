import { Paper, Stack, Text, Title } from "@mantine/core";
import { Link, Navigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTES } from "../../routes";
import { UserLicense } from "../../utils/enums";
import "./ProductSelectPage.scss";

interface ProductSelectPageProps {
  userLicenses: Array<UserLicense>;
}

function ProductSelectPage({ userLicenses }: ProductSelectPageProps) {
  function checkExclusiveLicense(license: UserLicense) {
    return userLicenses.length === 1 && userLicenses.includes(license);
  }

  if (checkExclusiveLicense(UserLicense.Analyzer)) {
    return <Navigate replace to={ROUTES.analyzerProjectList} />;
  } else if (checkExclusiveLicense(UserLicense.Manager)) {
    return <Navigate replace to={ROUTES.managerStartPage} />;
  } else {
    return (
      <Container className="ProductSelectPage">
        <Row>
          <Col>
            <ProductRouterLink
              className="analyzer"
              description="Planung & Optimierung"
              header="Analyzer"
              to={ROUTES.analyzerProjectList}
            />
          </Col>
          <Col>
            <ProductRouterLink
              className="manager"
              description="Administratives Management"
              header="Manager"
              to={ROUTES.managerStartPage}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

interface ProductRouterLinkProps {
  to: string;
  header: string;
  description: string;
  className?: string;
}

function ProductRouterLink({
  to,
  header,
  description,
  className
}: ProductRouterLinkProps) {
  return (
    <Link className="product-link" to={to}>
      <Paper className={className} p="xl">
        <Stack align="center" gap="0">
          <Title fw={500} mb="xs">
            {header}
          </Title>
          <Text c="black" mb="md">
            {description}
          </Text>
        </Stack>
      </Paper>
    </Link>
  );
}

export { ProductSelectPage };
