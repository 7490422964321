import { Button } from "@mantine/core";
import type { FormEvent } from "react";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { ROUTES } from "../../routes";
import { showToast } from "../../utils/toast";
import { Alert, AlertColor } from "../Alert/Alert";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { FormItem } from "../DynamicForm/FormItem/FormItem";
import { FormFieldType } from "../DynamicForm/FormItems/FormField/FormField";
import { PasswordResetLayout } from "../Layout/PasswordResetLayout";
import { PublicLayout } from "../Layout/PublicLayout";
import { loginWithCode } from "./utils/loginWithCode";
import { resendOtp } from "./utils/resendOtp";

function OtpConfirm() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [code, setCode] = useState<string>("");
  const [loginError, setLoginError] = useState<string>();
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  const authMethod = searchParams.get("method");
  const userEmail = searchParams.get("email");
  const isEmail2FaMethod = authMethod === "email";

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!sessionStorage.getItem("ephemeral_token")) {
        location.replace("/login");
      }
    }
  }, []);

  const handleLogin = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setLoginInProgress(true);

      try {
        await loginWithCode(
          code,
          sessionStorage.getItem("ephemeral_token") || ""
        );

        location.replace(ROUTES.productSelectPage);
      } catch (_) {
        setLoginError(t("errors.2FA.InvalidOrExpiredCode"));
      } finally {
        setLoginInProgress(false);
      }
    },
    [code, t]
  );

  async function handleOtpResend() {
    try {
      await resendOtp(authMethod || "", userEmail || "");
      showToast(
        "success",
        "Der Code wurde erneut gesendet. Bitte überprüfen Sie Ihren E-Mail-Eingang."
      );
    } catch (_) {
      setLoginError(t("error.2FA.CodeSendingFailed"));
    }
  }
  const description = isEmail2FaMethod
    ? `Wir haben Ihnen einen Sicherheitscode zur 2-Faktor-Authentifizierung per E-Mail gesendet. 
         Bitte geben Sie den Code im folgenden Feld ein.
         Alternativ können Sie auch einen Backup-Code eingeben.`
    : `Öffnen Sie Ihre Authentifizierungs-App und geben Sie den für Ihr Konto 
       generierten Sicherheitscode ein.
       Alternativ können Sie auch einen Backup-Code eingeben.`;

  return (
    <PublicLayout>
      <PasswordResetLayout
        description={description}
        title="Zwei-Faktor-Authentifizierung"
      >
        <Form className="PasswordResetForm" onSubmit={handleLogin}>
          <FormGroup className="form-group">
            <FormItem
              initialValue=""
              label="Authentifizierungscode"
              name="authCode"
              placeholder="Code"
              required
              type={FormFieldType.String}
              value={code}
              onInput={(_, value: string) => setCode(value)}
            />

            {loginError && (
              <Alert color={AlertColor.Danger}>{loginError}</Alert>
            )}

            {isEmail2FaMethod && (
              <Button p={0} variant="transparent" onClick={handleOtpResend}>
                Senden Sie den Code erneut
              </Button>
            )}
            <div className="SubmitBtnWrapper">
              <SpinButton
                className="PasswordSubmitBtn"
                color="brand"
                disabled={!code || loginInProgress}
                spin={loginInProgress}
              >
                Überprüfen
              </SpinButton>
            </div>
          </FormGroup>
        </Form>
      </PasswordResetLayout>
    </PublicLayout>
  );
}

export { OtpConfirm };
