const fieldSpecificToastMessages = [
  {
    fieldName: "unternehmenInSchwierigkeitenYears",
    message:
      "Sie haben den Status bei “Unternehmen in Schwierigkeiten” geändert. Daher gelten für Ihr Unternehmen nun neue Entlastungsbestände, die sich auf Ihre Meldeunterlagen auswirken. Wir bereiten Ihre neuen Meldeunterlagen vor und geben Ihnen Bescheid, sobald diese fertig sind."
  }
];

/** For use in ComponentEditWizard.tsx saveFunction(componentId, forms, product).then((result) => which is not properly typed
 * @param result the result of saving a form from ComponentEditWizard(=> new data)
 * @param forms  the forms that were saved (=> old data)
 * @returns {string[]} The toast messages if applicable..
 */
function getFieldSpecificToastMessages(result, forms) {
  const toastMessages: string[] = [];

  fieldSpecificToastMessages.forEach((fieldConfig) => {
    const newFieldValue = result.forms
      .flatMap((form) => form.sections)
      .flatMap((section) => section.values[fieldConfig.fieldName])
      .find((value) => value !== undefined);

    const oldFieldValue = forms
      .flatMap((form) => form.sections)
      .flatMap((section) =>
        section.fields.find((field) => field.name === fieldConfig.fieldName)
      )
      .find((field) => field?.initialValue !== undefined)?.initialValue;

    if (newFieldValue !== oldFieldValue) {
      toastMessages.push(fieldConfig.message);
    }
  });

  return toastMessages;
}

export default getFieldSpecificToastMessages;
