import { Checkbox } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";

import { Alert, AlertColor } from "../../Alert/Alert";
import { Button } from "../../Buttons/Button/Button";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import type { Choice } from "./FormField/Dropdown/TsDropdown";
import type { FormFieldValue } from "./FormField/FormField";
import { FormField } from "./FormField/FormField";
import { useClearWarningStore } from "./FormField/StructureViewCreationDropdown/useClearWarningStore";
import type { FormFieldData } from "./FormItems";

const FIELDS_WITH_CLEAR_WARNING = [
  "meteringLocation",
  "marketLocationFeedin",
  "marketLocationFeedout"
];

interface CreatableDropdownProps {
  formItemData: FormFieldData;
  formName?: string;
  value: FormFieldValue;
  choices: Array<Choice>;
  errors?: Array<string>;
  allowInput: boolean;
  isCreating: boolean;
  isEditing: boolean;
  onInput: (name: string, value: FormFieldValue) => void;
  onCreateFormNodeChange: (node: HTMLElement) => void;
  onEditFormNodeChange: (node: HTMLElement) => void;
  onStartCreating: () => void;
  onStartEditing: () => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CreatableDropdown({
  formItemData,
  formName,
  value,
  choices,
  errors,
  allowInput,
  isCreating,
  isEditing,
  onInput,
  onCreateFormNodeChange,
  onEditFormNodeChange,
  onStartCreating,
  onStartEditing
}: CreatableDropdownProps) {
  const [choicesKey, setChoicesKey] = useState(0);
  const [isWarningAccepted, setIsWarningAccepted] = useState(false);

  const isStaff = useShouldShowStaffView();
  const registerClearWarning = useClearWarningStore(
    (state) => state.registerClearWarning
  );
  const unregisterClearWarning = useClearWarningStore(
    (state) => state.unregisterClearWarning
  );
  const setClearWarningAccepted = useClearWarningStore(
    (state) => state.setClearWarningAccepted
  );

  useEffect(() => {
    setChoicesKey((oldChoicesKey) => oldChoicesKey + 1);
  }, [choices]);

  const createFormRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        onCreateFormNodeChange(node);
      }
    },
    [onCreateFormNodeChange]
  );

  const editFormRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        onEditFormNodeChange(node);
      }
    },
    [onEditFormNodeChange]
  );

  function handleClickCreate() {
    onStartCreating();
  }

  function handleClickEdit() {
    onStartEditing();
  }

  function handleWarningAcceptedChange() {
    setIsWarningAccepted((oldIsWarningAccepted) => !oldIsWarningAccepted);
    setClearWarningAccepted(formItemData.name, !isWarningAccepted);
  }

  const formItemName = formItemData.name;
  const formItemId = `id_${formName ? formName : "form"}_${formItemName.replace(
    /\./g,
    "__"
  )}`;

  const fieldHasBeenEmptied =
    formItemData?.initialValue !== null &&
    value !== formItemData?.initialValue &&
    value === "";

  useEffect(() => {
    if (fieldHasBeenEmptied && isStaff) {
      registerClearWarning(formItemName);
    } else {
      unregisterClearWarning(formItemName);
    }
  }, [
    fieldHasBeenEmptied,
    formItemName,
    registerClearWarning,
    unregisterClearWarning,
    isStaff
  ]);

  return (
    <div className="CreatableDropdown">
      <div className="controls">
        <FormField
          allowInput={!formItemData.readOnly && allowInput}
          errorTexts={errors}
          id={formItemId}
          key={choicesKey}
          value={value}
          onInput={onInput}
          {...formItemData}
          choices={choices}
        />
        <Button
          className="create-button"
          color="brand"
          disabled={isCreating || isEditing}
          onClick={handleClickCreate}
        >
          Hinzufügen
        </Button>
        <Button
          className="edit-button"
          color="brand"
          disabled={isCreating || isEditing || !value}
          onClick={handleClickEdit}
        >
          Bearbeiten
        </Button>
      </div>
      <Collapse isOpen={isCreating}>
        <Card>
          <CardBody>
            <div ref={createFormRef} />
          </CardBody>
        </Card>
      </Collapse>
      <Collapse isOpen={isEditing}>
        <Card>
          <CardBody>
            <div ref={editFormRef} />
          </CardBody>
        </Card>
      </Collapse>
      {fieldHasBeenEmptied &&
        isStaff &&
        FIELDS_WITH_CLEAR_WARNING.includes(formItemName) && (
          <Alert color={AlertColor.Danger} mt="sm">
            Das Entfernen der Lokation vom Zähler führt zum Verlust aller
            zugehörigen Energiedaten.
            <Checkbox
              checked={isWarningAccepted}
              label="Ich habe die Warnung gelesen und akzeptiere sie."
              mt="sm"
              onChange={handleWarningAcceptedChange}
            />
          </Alert>
        )}
    </div>
  );
}

export { CreatableDropdown };
