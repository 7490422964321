import { Col, Row } from "reactstrap";

interface DescriptionHeaderProps {
  description: string;
}

function DescriptionHeader({ description }: DescriptionHeaderProps) {
  return (
    <Row style={{ marginTop: "15px" }}>
      <Col>
        <span style={{ fontSize: "1.3em" }}>
          <i>{description}</i>
        </span>
      </Col>
    </Row>
  );
}

export { DescriptionHeader };
