import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import api from "../../api";
import { useIsMieterstrom } from "../../hooks/useIsMieterstrom";
import urls from "../../urls";
import { CREATE_STEPS } from "../../utils/constants";
import { ObjectName } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../utils/toast";
import {
  FIELDS_FOR_MIETERSTROM_SITES as CONNECTION_FIELDS_FOR_MIETERSTROM_SITES,
  DEFAULT_FIELDS as DEFAULT_CONNECTION_FIELDS
} from "../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Connection";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../CustomForm/CustomForm";
import FormItems from "../DynamicForm/FormItems";
import { withCreatableDropdown } from "../DynamicForm/FormItems/withCreatableDropdown";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../PersonWizard/PersonWizard";
import "./ConnectionWizard.scss";

type ConnectionWizardProps = {
  siteId: number;
  variantId: number;
  buttonContainer: HTMLElement | undefined;
  onClose?: () => void | undefined;
  graphCoordinates: { x: number; y: number };
};

function ConnectionWizard({
  siteId,
  variantId,
  buttonContainer,
  onClose,
  graphCoordinates
}: ConnectionWizardProps) {
  const [step, setStep] = useState(CREATE_STEPS.CREATE_FORM);
  const [allFormFields, setAllFormFields] = useState({});
  const queryClient = useQueryClient();
  const { data: siteIsMieterstrom } = useIsMieterstrom(siteId);

  const loadFormFields = useCallback(async () => {
    const optionsUrl = urls.api.connections(siteId);
    let response;

    try {
      response = await api.options(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return;
    }

    setAllFormFields(response.data.actions.pOST);
  }, [siteId]);

  useEffect(() => {
    setStep(CREATE_STEPS.CREATE_FORM);

    loadFormFields();
  }, [loadFormFields]);

  function handleClickCreate(responseData) {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Connection
    );
    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: siteId }]
    });

    if (responseData.ghostNode) {
      const objectCacheName = getPluralVariableNameFromObjectName(
        ObjectName.GhostNode
      );
      queryClient.invalidateQueries({
        queryKey: [objectCacheName, { siteOrVariantId: siteId }]
      });
    }
    showSuccessStep();
  }

  function showSuccessStep() {
    onClose?.();
    showToast("success", "Der Netzverknüpfungspunkt wurde erstellt.");
  }

  function renderStep() {
    switch (step) {
      case CREATE_STEPS.CREATE_FORM: {
        let formFieldNames;
        if (siteIsMieterstrom) {
          formFieldNames = CONNECTION_FIELDS_FOR_MIETERSTROM_SITES;
        } else {
          formFieldNames = DEFAULT_CONNECTION_FIELDS;
        }
        const postUrl = urls.api.connections(siteId);
        const nonFieldData = {
          site: siteId,
          sketchElement: {
            xPosition: graphCoordinates?.x,
            yPosition: graphCoordinates?.y
          }
        };
        const personPostUrl = urls.api.personsByVariantId(variantId);
        const personPutUrlFunc = urls.api.person;
        const personNonFieldData = {
          variant: variantId
        };
        let FormItemsWithCreatablePerson = withCreatableDropdown(
          FormItems,
          "connectingParty",
          personPostUrl,
          personPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          personNonFieldData
        );
        FormItemsWithCreatablePerson = withCreatableDropdown(
          FormItemsWithCreatablePerson as unknown as typeof FormItems,
          "supplyContractHeldBy",
          personPostUrl,
          personPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          personNonFieldData
        );
        const CustomFormItemsComponent = (props) => (
          <FormItemsWithCreatablePerson {...props} />
        );
        const selectedFormFields = getFormFieldsFromResponse(
          formFieldNames,
          allFormFields
        );

        return (
          <CustomForm
            buttonContainer={buttonContainer}
            CustomFormItemsComponent={CustomFormItemsComponent}
            formFields={selectedFormFields}
            nonFieldData={nonFieldData}
            postUrl={postUrl}
            submitButtonText="Erstellen"
            onCancel={onClose}
            onSubmit={handleClickCreate}
          />
        );
      }
      default:
        console.log("Unexpected step error!");
        return <div></div>;
    }
  }

  return <div className="ConnectionWizard">{renderStep()}</div>;
}

ConnectionWizard.propTypes = {
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  buttonContainer: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

export { ConnectionWizard };
