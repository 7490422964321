import { Highlight, Text } from "@mantine/core";

function HistoryUser({
  userName,
  highlight = ""
}: {
  userName?: string | null;
  highlight?: string;
}) {
  if (!userName) {
    return (
      <Text fs="italic" span>
        Gelöschte Benutzer:in
      </Text>
    );
  }
  return (
    <Text c="dimmed" mt="xs" size="sm">
      <Highlight highlight={highlight} span>
        {userName}
      </Highlight>
    </Text>
  );
}

export { HistoryUser };
