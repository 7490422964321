import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { MONTH_CHOICES } from "../../../utils/dates";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import type {
  Choice,
  ChoiceValue
} from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import "./SapExportModal.scss";

const getYearChoices = () => {
  const currentYear = new Date().getFullYear();
  const startingYear = 2010;
  const yearChoices: Array<Choice<number>> = [];

  for (let i = currentYear; i >= startingYear; i--) {
    yearChoices.push({ displayName: i.toString(), value: i });
  }
  return yearChoices;
};
export interface SapExportModalProps {
  closeModal: () => void;
  onClickExport: (month: number, year: number) => void;
}
function SapExportModal({ closeModal, onClickExport }: SapExportModalProps) {
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(
    new Date().getFullYear()
  );
  const { t } = useTranslation();

  function handleClickSave() {
    if (selectedMonth && selectedYear) {
      onClickExport(selectedMonth, selectedYear);
    } else {
      showToast("error", t("errors.DeliveryInvoices.SapExportNoDateError"));
    }
  }
  return (
    <div className="SapExportModal">
      <Modal isOpen>
        <ModalHeader>Erstellungsdatum für SAP-Export angeben</ModalHeader>
        <ModalBody>
          Bitte geben Sie den Monat und das Jahr für den SAP Export ein:
          <div className="sap-export-modal-date-input-container">
            <div className="sap-export-modal-date-input">
              <Label
                className="sap-export-modal-date-input-label"
                for="month-field"
              >
                Monat
              </Label>
              <TsDropdown
                choices={MONTH_CHOICES}
                defaultValue={selectedMonth ?? undefined}
                id={"month-field"}
                name={"month"}
                placeholder="wählen..."
                onChange={(_, newValue: ChoiceValue) => {
                  setSelectedMonth(newValue as number);
                }}
              />
            </div>
            <div className="sap-export-modal-date-input">
              <Label className="sap-export-modal-date-input-label">Jahr</Label>
              <TsDropdown
                choices={getYearChoices()}
                defaultValue={selectedYear ?? undefined}
                id={"year-field"}
                name={"year"}
                placeholder="wählen..."
                onChange={(_, newValue: ChoiceValue) => {
                  setSelectedYear(newValue as number);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>Abbrechen</Button>
          <Button color="brand" onClick={handleClickSave}>
            Erstellen
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { SapExportModal };
