import type { CellContext, ColumnDef } from "@tanstack/react-table";
import type { Payment } from "../../../../../../utils/backend-types";
import { RoundedNumberFormat } from "../../../../../../utils/RoundedNumberFormat";
import { EditIcon } from "../../../../../Buttons/EditIcon";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import type { PaymentDate } from "../PaymentsTab";

function getPendingPaymentsColumn(
  onClickEdit: (date: PaymentDate) => void
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Offen (Brutto){" "}
        <IconHelpText
          helpText="Erfassen Sie sonstige offene Positionen,
                    die auf der Rechnung aufgeführt werden sollen."
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "pendingPayments",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <div className="pendingPaymentsCell">
        <RoundedNumberFormat
          className="pendingPaymentsCellValue"
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          fixedDecimalScale
          key={cellInfo.row.original.year}
          suffix=" €"
          thousandSeparator="."
          value={cellInfo.getValue() ?? ""} // passing empty string forces the underlying value to be cleared https://github.com/s-yadav/react-number-format/issues/727
        />
        <EditIcon
          tooltipText={"Bearbeiten"}
          onClick={() =>
            onClickEdit({
              year: cellInfo.row.original.year,
              month: cellInfo.row.original.month
            })
          }
        />
      </div>
    )
  };
}

export { getPendingPaymentsColumn };
