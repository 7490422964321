import { Paper, Space, Text, Title } from "@mantine/core";
import { useState } from "react";
import { showToast } from "../../utils/toast";
import { DeleteConfirmationModal } from "../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import type { OAuthApplicationResponse } from "./ApiSettings.types";
import { useOAuthApplications } from "./hooks/useOAuthApplications";
import { useOAuthApplicationsMutations } from "./hooks/useOAuthApplicationsMutations";
import { OAuthApplicationsTable } from "./OAuthApplicationsTable/OAuthApplicationsTable";

function ApiSettingsView() {
  const { applications, isLoading, error } = useOAuthApplications();
  const { deleteMutation } = useOAuthApplicationsMutations();
  const [applicationToDelete, setApplicationToDelete] =
    useState<OAuthApplicationResponse | null>(null);
  const [applicationIsDeleting, setApplicationIsDeleting] = useState(false);

  async function deleteApplication() {
    if (!applicationToDelete) {
      return;
    }

    setApplicationIsDeleting(true);

    try {
      await deleteMutation.mutateAsync(applicationToDelete.id);
    } catch (error) {
      showToast("error", error);
    } finally {
      setApplicationIsDeleting(false);
      setApplicationToDelete(null);
    }
  }

  return (
    <>
      <PageContent className="ApiSettingsView">
        <PageHeader title="API-Einstellungen" />
        <Paper>
          <header>
            <Title order={4}>Registrierte OAuth-Applikationen</Title>
            <Text c="dimmed">
              Auflistung aller OAuth-Applikationen, die in opti.node registriert
              sind.
            </Text>
          </header>
          <Space h="lg" />
          <LoadOrError error={error} loading={isLoading}>
            {applications && (
              <OAuthApplicationsTable
                applications={applications}
                onDelete={(application) => setApplicationToDelete(application)}
              />
            )}
          </LoadOrError>
        </Paper>
      </PageContent>
      {applicationToDelete && (
        <DeleteConfirmationModal
          isLoading={applicationIsDeleting}
          isModalOpen={!!applicationToDelete}
          name={applicationToDelete?.name}
          toggleModal={() => setApplicationToDelete(null)}
          onClickConfirm={deleteApplication}
        />
      )}
    </>
  );
}

export { ApiSettingsView };
