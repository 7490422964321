import type { Person } from "../../../../utils/backend-types";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

type TodoPersonCellProps = {
  persons: Array<Person>;
  todoId: number;
  person: string | null;
  onChangePerson: (todoId: number, person: number) => void;
  notChangeable?: boolean;
};

function TodoPersonCell({
  notChangeable,
  persons,
  todoId,
  person,
  onChangePerson
}: TodoPersonCellProps) {
  const currentPerson = persons.find((p) => p.name === person);

  function handleChangePerson(_, value) {
    onChangePerson(todoId, value);
  }

  return (
    <div className="todo-person-cell-container">
      {notChangeable ? (
        <div>{currentPerson?.name}</div>
      ) : (
        <TsDropdown
          choices={persons.map((person) => {
            return { value: person.id, displayName: person.name };
          })}
          defaultValue={currentPerson?.id}
          id={"todo-person-cell-dropdown" + todoId}
          name="todo-person"
          onChange={handleChangePerson}
        />
      )}
    </div>
  );
}

export default TodoPersonCell;
