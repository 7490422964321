import ReactMarkdown from "react-markdown";
import { InfoIcon, InfoType } from "../../Icons/InfoIcon/InfoIcon";
import { ExternalLink } from "../../ReactMarkdown/ExternalLink";

interface FormItemInfoTextProps {
  infoText: string;
}

function FormItemInfoText({ infoText }: FormItemInfoTextProps) {
  return (
    <div className="form-item-info-text-container">
      <InfoIcon infoType={InfoType.Instruction}>
        <span className="form-item-info-text">
          <ReactMarkdown
            components={{
              a: ExternalLink
            }}
          >
            {infoText}
          </ReactMarkdown>
        </span>
      </InfoIcon>
    </div>
  );
}

export { FormItemInfoText };
