import type { Id, ToastOptions, TypeOptions } from "react-toastify";
import { Icons, toast } from "react-toastify";
import type { ApiError } from "../api";
import type { BannerToastProps } from "../components/BannerToast/BannerToast";
import { BannerToast } from "../components/BannerToast/BannerToast";
import { getErrorText } from "./get-error-text";

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 7000,
  hideProgressBar: true,
  closeButton: false,
  theme: "colored"
};

export const bannerDefaultOptions: ToastOptions = {
  ...defaultOptions,
  autoClose: false,
  className: "Toastify__banner-toast",
  closeButton: true,
  closeOnClick: false,
  draggable: false
};

export function showToast(
  toastType: TypeOptions,
  content?: unknown,
  options?: ToastOptions
): Id {
  const adjustedDefaultOptions = {
    ...defaultOptions,
    icon: toastType === "error" ? Icons.warning : defaultOptions.icon
  };

  let displayContent: string;

  // Check if content is an AxiosError and convert it to a string
  if (content && (content as ApiError).response) {
    const errorText = getErrorText(content);
    displayContent = Array.isArray(errorText)
      ? errorText.join("\n\n")
      : errorText;
  } else if (content && Array.isArray(content)) {
    displayContent = content.join("\n\n");
  } else {
    displayContent = content as string;
  }

  return toast[toastType](displayContent, {
    ...adjustedDefaultOptions,
    ...options
  });
}

export function showBannerToast(
  toastType: TypeOptions,
  bannerToastProps: BannerToastProps,
  options?: ToastOptions
) {
  return showToast(toastType, <BannerToast {...bannerToastProps} />, {
    ...bannerDefaultOptions,
    ...options
  });
}
