import { Accordion } from "@mantine/core";
import { useConfiguratorComponents } from "../../../hooks/useConfiguratorComponents";
import type {
  Connection,
  Consumer,
  Generator,
  Person,
  Storage
} from "../../../utils/backend-types";
import { ObjectName } from "../../../utils/enums";
import { ComponentOpenEditModalLink } from "../../ComponentListContainer/ComponentOpenEditModalLink/ComponentOpenEditModalLink";
import "./PersonsWidget.scss";

interface PersonsWidgetProps {
  connectingPartyIds: Array<number>;
  persons: Array<Person>;
  relatedPersonIds: Array<number>;
}

function PersonsWidget({
  connectingPartyIds,
  persons,
  relatedPersonIds
}: PersonsWidgetProps) {
  const Body = (
    <div className="persons-row">
      {persons.map((person) => (
        <PersonIcon
          isOnlyConnectingParty={
            !relatedPersonIds.includes(person.id) &&
            connectingPartyIds.includes(person.id)
          }
          key={person.id}
          person={person}
        />
      ))}
    </div>
  );

  return (
    <div className="PersonsWidget">
      {persons.length > 5 ? (
        <Accordion>
          <Accordion.Item value="persons">
            <Accordion.Control>
              <h5 className="persons-header no-margin">
                Unternehmen ({persons.length})
              </h5>
            </Accordion.Control>
            <Accordion.Panel>{Body}</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ) : (
        <>
          <h5 className="persons-header">Unternehmen</h5>
          {Body}
        </>
      )}
    </div>
  );
}

interface PersonsWidgetDataWrapperProps {
  siteId: number;
  variantId: number;
}

function PersonsWidgetDataWrapper({
  siteId,
  variantId
}: PersonsWidgetDataWrapperProps) {
  const { data: generators } = useConfiguratorComponents<Generator>(
    ObjectName.Generator,
    siteId
  );
  const { data: consumers } = useConfiguratorComponents<Consumer>(
    ObjectName.Consumer,
    siteId
  );
  const { data: storages } = useConfiguratorComponents<Storage>(
    ObjectName.Storage,
    siteId
  );
  const { data: persons } = useConfiguratorComponents<Person>(
    ObjectName.Person,
    variantId
  );
  const { data: connections } = useConfiguratorComponents<Connection>(
    ObjectName.Connection,
    siteId
  );

  if (
    !persons ||
    persons.length === 0 ||
    !generators ||
    !consumers ||
    !storages ||
    !connections
  ) {
    return null;
  }

  const relatedPersonIds = [
    ...generators.map((generator) => generator.person),
    ...consumers.map((consumer) => consumer.person),
    ...storages.map((storage) => storage.person)
  ];

  const connectingPartyIds = connections.map(
    (connection) => connection.connectingParty
  );

  const filteredPersons = persons.filter(
    (person) =>
      relatedPersonIds.includes(person.id) ||
      connectingPartyIds.includes(person.id)
  );

  return (
    <PersonsWidget
      connectingPartyIds={connectingPartyIds}
      persons={filteredPersons}
      relatedPersonIds={relatedPersonIds}
    />
  );
}

interface PersonIconProps {
  person: Person;
  isOnlyConnectingParty: boolean;
}

function PersonIcon({ person, isOnlyConnectingParty }: PersonIconProps) {
  const name = isOnlyConnectingParty
    ? `${person.name} (Netzanschlussnehmer)`
    : person.name;

  return (
    <div className="PersonIcon">
      <div
        className="person-color-box"
        style={{ backgroundColor: `#${person.color}` }}
      />
      <div>
        <ComponentOpenEditModalLink
          componentId={person.id}
          linkContent={name}
          objectName={ObjectName.Person}
        />
      </div>
    </div>
  );
}

export {
  PersonsWidgetDataWrapper as PersonsWidget,
  PersonsWidget as PersonsWidgetComponent,
  PersonsWidgetProps as PersonsWidgetComponentProps,
  PersonsWidgetDataWrapperProps as PersonsWidgetProps
};
