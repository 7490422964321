import { Box, Group, Paper, Stack, Title } from "@mantine/core";
import { Select } from "../../BuildingBlocks/Forms/Select/Select";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import "./Tile.scss";

interface TileFilterOption<T> {
  label: string;
  value: T;
}

interface TileProps<T> {
  title: string;
  iconName: IconName;
  children: React.ReactNode;
  error?: Error | null;
  isLoading: boolean;
  filterOptions?: Array<TileFilterOption<T>>;
  filterValue?: TileFilterOption<T>;
  onFilterChange?: (newFilter: TileFilterOption<T>) => void;
}

function Tile<T>({
  title,
  iconName,
  children,
  error,
  isLoading,
  filterOptions,
  filterValue,
  onFilterChange
}: TileProps<T>) {
  return (
    <Paper className="Tile">
      <Stack h="100%">
        <Group className="tile-header" justify="space-between" mb="lg" pb="md">
          <Title fw={500} order={6} size="h4">
            <Icon name={iconName} />
            {title}
          </Title>
          {filterOptions && filterOptions.length > 0 && (
            <Select<{ label: string; value: T }>
              className="tile-filter"
              options={filterOptions}
              value={filterValue}
              onChange={onFilterChange}
            />
          )}
        </Group>
        <Box h="100%">
          <LoadOrError error={error} loading={isLoading}>
            {children}
          </LoadOrError>
        </Box>
      </Stack>
    </Paper>
  );
}

export { Tile, TileProps };
