import { Group, Paper, Space } from "@mantine/core";
import { useCallback, useState } from "react";
import { MspCategory } from "../../../utils/enums";
import { AlertColor } from "../../Alert/Alert";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import type { FilterDropdownValues } from "../../FilterDropdown/FilterDropdown.types";
import { SearchBar } from "../../SearchBar/SearchBar";
import type { LocationOverviewDataQueryParams } from "./LocationOverview.types";
import { LocationOverviewFilterDropdown } from "./LocationOverviewFilterDropdown/LocationOverviewFilterDropdown";
import { DEFAULT_FILTER_VALUES } from "./LocationOverviewTable/LocationOverview.constants";
import { LocationOverviewTable } from "./LocationOverviewTable/LocationOverviewTable";
import { getLocationOverviewQueryParams } from "./utils/getLocationOverviewQueryParams";
import { useGetLocationOverviewTableData } from "./utils/useGetLocationOverviewTableData";

function LocationOverview() {
  const [selectedFilters, setSelectedFilters] = useState<FilterDropdownValues>(
    DEFAULT_FILTER_VALUES
  );
  const [queryOptions, setQueryOptions] =
    useState<LocationOverviewDataQueryParams>({
      limit: 5,
      offset: 0,
      ...getLocationOverviewQueryParams({
        categories: [],
        locationOverViewFilters: DEFAULT_FILTER_VALUES,
        searchValue: ""
      })
    });

  const { locationOverviewData, isLocationOverviewDataLoading } =
    useGetLocationOverviewTableData(queryOptions);

  const updateData = useCallback(
    (filters?: FilterDropdownValues, newSearchValue?: string) => {
      const locationOverViewFilters = filters || selectedFilters;

      const categories = Object.values(MspCategory).filter(
        (category) => locationOverViewFilters[category] === true
      );

      setQueryOptions((oldQueryOptions) => ({
        ...queryOptions,
        ...getLocationOverviewQueryParams({
          categories,
          locationOverViewFilters,
          searchValue: newSearchValue ?? oldQueryOptions.search_segment ?? ""
        })
      }));
    },

    [selectedFilters, queryOptions]
  );

  const handleFetchData = useCallback((state) => {
    const { page, pageSize } = state;

    const safePage = Math.floor(page);

    setQueryOptions((prevQueryOptions) => ({
      ...prevQueryOptions,
      limit: pageSize,
      offset: safePage * pageSize
    }));
  }, []);

  return (
    <Paper>
      <Group mb="xl">
        <SearchBar
          btnDisabled={isLocationOverviewDataLoading}
          isLoading={isLocationOverviewDataLoading}
          placeholder="Suche nach Lokationsnummer, Lokations-Name oder Liegenschafts-Name"
          onClear={() => updateData(undefined, "")}
          onSearch={(searchValue) => updateData(selectedFilters, searchValue)}
        />
        <LocationOverviewFilterDropdown
          selectedFilters={selectedFilters}
          onChangeFilters={(newFilters) => {
            setSelectedFilters(newFilters);
            updateData(newFilters);
          }}
        />
      </Group>

      <IconAlert color={AlertColor.Info}>
        Die Bewertung der Vollständigkeit (0-100%) für Lokationen erfolgt aus
        Performancegründen aktuell nur einmal täglich. Die gezeigten Werte
        können somit bis zu 24 Stunden alt sein.
      </IconAlert>

      <Space h="lg" />

      <LocationOverviewTable
        data={locationOverviewData?.results || []}
        isLoading={isLocationOverviewDataLoading}
        numPages={
          typeof locationOverviewData?.count === "number"
            ? Math.ceil(locationOverviewData?.totalNumber / queryOptions.limit)
            : 0
        }
        onFetchData={handleFetchData}
      />
    </Paper>
  );
}

export { LocationOverview };
