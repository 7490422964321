import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStructureViewFlowDiagramMutations } from "../../../../hooks/useStructureViewFlowDiagramMutations";
import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import type { StructureViewMode } from "../../../StructureView/StructureView.constants";

interface TextContentFormFields {
  text: string;
}

const TEXT_CONTENT_FORM_FIELD_DATA = {
  name: "text",
  type: "textarea"
} as const satisfies FormInputData<TextContentFormFields, "text">;

interface TextEditModalProps {
  initialTextContent?: string;
  nodeId: string;
  siteId: number;
  structureViewMode: StructureViewMode;
  onClose: () => void;
}

function TextEditModal({
  initialTextContent,
  nodeId,
  siteId,
  structureViewMode,
  onClose
}: TextEditModalProps) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch
  } = useForm<TextContentFormFields>({
    defaultValues: { text: initialTextContent }
  });

  const { updateTextElementNodeMutation } =
    useStructureViewFlowDiagramMutations(siteId, structureViewMode);

  const onValid: SubmitHandler<TextContentFormFields> = (data) => {
    setIsLoading(true);

    updateTextElementNodeMutation.mutate(
      {
        nodeId,
        text: data.text
      },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (error) => {
          setErrorsFromResponseData<TextContentFormFields>(
            error,
            watch(),
            setError,
            t("errors.UnknownError")
          );
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const submitCallback = handleSubmit(onValid);

  return (
    <BasicConfirmationModal
      className="TextEditModal"
      confirmationButtonText="Speichern"
      headerText="Text bearbeiten"
      isLoading={isLoading}
      isModalOpen={true}
      toggleModal={onClose}
      onCancel={onClose}
      onConfirm={submitCallback}
    >
      <form onSubmit={submitCallback}>
        <FormFieldController
          control={control}
          data={TEXT_CONTENT_FORM_FIELD_DATA}
          error={errors.text}
          rules={{
            required: {
              value: true,
              message: t("errors.Required")
            }
          }}
        />
      </form>
    </BasicConfirmationModal>
  );
}

export { TextEditModal };
