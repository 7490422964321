import { PureComponent } from "react";

import urls from "../../../../urls";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { OptionsForm } from "../../../OptionsForm/OptionsForm";

class CreateProjectModal extends PureComponent {
  handleSubmit = (data) => {
    this.props.onClickSave(data.variants[0].id, data.id);
  };

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <div className="CreateProjectModal">
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader>Neues Projekt</ModalHeader>
          <ModalBody className="CreateProjectModalBody" scrollable>
            <OptionsForm
              optionsUrl={urls.api.analyzerProjectsCreate()}
              postUrl={urls.api.analyzerProjectsCreate()}
              submitButtonText="Speichern"
              onCancel={toggle}
              onSubmit={this.handleSubmit}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export { CreateProjectModal };
