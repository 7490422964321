import classNames from "classnames";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";
import type { ButtonColor, ButtonProps } from "../Button/Button";
import { Button } from "../Button/Button";
import "./IconButton.scss";

interface IconButtonProps extends ButtonProps {
  iconName: IconName;
  iconColor?: ButtonColor;
}

function IconButton({
  iconName,
  iconColor,
  className,
  children,
  ...otherProps
}: IconButtonProps) {
  return (
    <Button
      className={classNames(
        "m-btn m-btn--icon IconButton mantine-focus-auto",
        className,
        iconColor && `iconcolor-${iconColor}`
      )}
      {...otherProps}
    >
      <span>
        <Icon name={iconName} />
        <span>{children}</span>
      </span>
    </Button>
  );
}

export { IconButton, IconButtonProps };
