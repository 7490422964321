import ReactMarkdown from "react-markdown";
import type { RegulatoryDuty } from "../../../utils/backend-types";
import { EMPTY_FIELD } from "../../../utils/constants";
import { ExternalLink } from "../../ReactMarkdown/ExternalLink";
import { RegulatoryDutyDetailRow } from "../../RegulatoryDuties/RegulatoryDutyList/RegulatoryDutyItem/RegulatoryDutyDetailRow/RegulatoryDutyDetailRow";

interface DescriptionProps {
  regulatoryDuty: RegulatoryDuty | null;
  userDefinedDescription: string | null;
}

function Description({
  regulatoryDuty,
  userDefinedDescription
}: DescriptionProps) {
  if (userDefinedDescription) {
    return (
      <div className="user-defined-description">
        <ReactMarkdown
          components={{
            a: ExternalLink
          }}
        >
          {userDefinedDescription}
        </ReactMarkdown>
      </div>
    );
  } else if (regulatoryDuty) {
    return (
      <div className="regulatory-duty-rows">
        <RegulatoryDutyDetailRow>
          {regulatoryDuty.description && (
            <ReactMarkdown
              components={{
                a: ExternalLink
              }}
            >
              {regulatoryDuty.description}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Empfänger">
          {regulatoryDuty.obligee}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Erfüllungsprozess">
          {regulatoryDuty.fulfillmentProcess && (
            <ReactMarkdown
              components={{
                a: ExternalLink
              }}
            >
              {regulatoryDuty.fulfillmentProcess}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzlich vorgegebene Frist">
          {regulatoryDuty.deadline}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzliche Grundlage">
          {regulatoryDuty.legalBasis}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Weitere Informationen">
          {regulatoryDuty.additionalInformation && (
            <ReactMarkdown
              components={{
                a: ExternalLink
              }}
            >
              {regulatoryDuty.additionalInformation}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
      </div>
    );
  } else {
    return <span>{EMPTY_FIELD}</span>;
  }
}

export { Description };
