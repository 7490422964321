import { generatePath } from "react-router-dom";
import { ROUTES } from "../../../routes";

type HistoryViewBacklinkType = {
  text: string;
  url: string;
};

const urlConfig: Record<string, HistoryViewBacklinkType> = {
  paragraph6: {
    text: "§ 6 EEG",
    url: ROUTES.paragraph6
  },
  paragraph6Contract: {
    text: "§ 6 EEG",
    url: ROUTES.paragraph6
  }
};

interface useHistoryViewBacklinkProps {
  projectId?: string;
  entityId?: string;
  entityType?: string;
}

function useHistoryViewBacklink({
  projectId,
  entityId,
  entityType
}: useHistoryViewBacklinkProps): HistoryViewBacklinkType | null {
  if (!entityType || !urlConfig[entityType]) {
    return null;
  }

  const url = generatePath(urlConfig[entityType].url, {
    projectId,
    entityId,
    entityType
  });

  const backlink = { url, text: urlConfig[entityType].text };
  return backlink;
}

export { HistoryViewBacklinkType, useHistoryViewBacklink };
