import type { EnergyRevenuesViewProps } from "../EnergyRevenuesView";
import { EnergyRevenuesView } from "../EnergyRevenuesView";

function CompanySection({
  view,
  energyRevenuesData,
  companyId,
  exportFileName
}: EnergyRevenuesViewProps) {
  return (
    <EnergyRevenuesView
      // dataVerificationColumns={["direktvermarktung_volume"]}
      companyId={companyId}
      energyRevenuesData={energyRevenuesData}
      exportFileName={exportFileName}
      view={view}
    />
  );
}

export { CompanySection };
