import type { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Contract } from "../../../../../../utils/backend-types";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldLabel } from "../../../../../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import { HelperText } from "../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { getErrorKeyFromFieldError } from "../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/getErrorKeyFromFieldError";
import {
  CONTRACT_FORM_FIELD_DATA,
  CONTRACT_FORM_HELP_TEXTS
} from "../ContractForm.constants";

interface AutomaticExtensionFieldsProps {
  control: Control<Contract>;
  errors: FieldErrors<Contract>;
}

function AutomaticExtensionFields({
  control,
  errors
}: AutomaticExtensionFieldsProps) {
  const { t } = useTranslation();
  const extensionErrorKey = getErrorKeyFromFieldError(
    errors.extensionDurationValue || errors.extensionDurationUnit
  );
  const extensionError = extensionErrorKey && t(extensionErrorKey);
  const cancelationErrorKey = getErrorKeyFromFieldError(
    errors.cancelationDeadlineUnit ||
      errors.cancelationDeadlineValue ||
      errors.cancelationReference
  );
  const cancelationError = cancelationErrorKey && t(cancelationErrorKey);

  return (
    <div className="AutomaticExtensionFields">
      <div>
        <FormFieldLabel
          formFieldId="id_form_extensionDurationValue"
          label="Ohne fristgerechte Kündigung automatische Verlängerung des Vertrages um"
        />
        <div style={{ display: "flex" }}>
          <FormFieldController
            control={control}
            data={{
              ...CONTRACT_FORM_FIELD_DATA.extensionDurationValue,
              label: undefined
            }}
          />
          <FormFieldController
            control={control}
            data={{
              ...CONTRACT_FORM_FIELD_DATA.extensionDurationUnit,
              label: undefined
            }}
          />
        </div>
        <HelperText error={extensionError} />
      </div>

      <div>
        <FormFieldLabel
          formFieldId="id_form_cancelationDeadlineValue"
          helpText={CONTRACT_FORM_HELP_TEXTS.cancelationReference}
          label="Die Kündigungsfrist beträgt"
        />
        <div style={{ display: "flex" }}>
          <FormFieldController
            control={control}
            data={{
              ...CONTRACT_FORM_FIELD_DATA.cancelationDeadlineValue,
              label: undefined
            }}
          />
          <FormFieldController
            control={control}
            data={{
              ...CONTRACT_FORM_FIELD_DATA.cancelationDeadlineUnit,
              label: undefined
            }}
          />
          <span className="text-between-fields">zum</span>
          <FormFieldController
            control={control}
            data={{
              ...CONTRACT_FORM_FIELD_DATA.cancelationReference,
              label: undefined
            }}
          />
        </div>
        <HelperText error={cancelationError} />
      </div>
    </div>
  );
}

export { AutomaticExtensionFields };
