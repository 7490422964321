import { Controller } from "react-hook-form";
import type { Column } from "react-table";
import type { InlineEditTableColumnData } from "../../../../../../types/inlineEditTable.types";
import { NumberInput } from "../../../../../BuildingBlocks/Forms/FormFields/NumberInput/NumberInput";
import type { TypedCellInfo } from "../../../../../CustomReactTable/CustomReactTable.types";
import { getErrorTextFromCellInfo } from "../../../utils/getErrorTextFromCellInfo";
import type { FictionalEnergyValueResponse } from "../../FictionalEnergyValueManager/FictionalEnergyValueManager";

export function getTechnischeNichtverfuegbarkeitColumn({
  rowStates,
  control,
  errors,
  t
}: InlineEditTableColumnData<FictionalEnergyValueResponse>): Column<FictionalEnergyValueResponse> {
  return {
    Header: "Technische Nichtverfügbarkeit (kWh)",
    accessor: (row) => row.technischeNichtverfuegbarkeitKwh,
    id: "technischeNichtverfuegbarkeitKwh",
    sortable: false,
    Cell: (cellInfo: TypedCellInfo<number, FictionalEnergyValueResponse>) => {
      const disableFormField = rowStates[cellInfo.original.id] === "loading";
      const showFormField =
        rowStates[cellInfo.original.id] === "edit" ||
        rowStates[cellInfo.original.id] === "loading";
      const errorText = getErrorTextFromCellInfo(
        errors,
        cellInfo,
        "technischeNichtverfuegbarkeitKwh",
        t
      );

      if (showFormField) {
        return (
          <Controller
            control={control}
            name={`${cellInfo.index}.technischeNichtverfuegbarkeitKwh`}
            render={({ field }) => (
              <NumberInput
                disabled={disableFormField}
                errorText={errorText}
                name={field.name}
                ref={field.ref}
                value={field.value ?? undefined}
                onChange={field.onChange}
                onKeyPressEnter={() => field.onChange(field.value)}
              />
            )}
            rules={{
              required: true
            }}
          />
        );
      }

      return cellInfo.value ?? "-";
    }
  };
}
