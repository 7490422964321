import AlvaLogo from "../../../../alva/assets/img/alva-logo.svg";
import LogoWhite from "../../../../default/assets/img/logo_white.png";
import WavelrLogo from "../../../../wavelr/assets/img/wavelr-logo.svg";
import { SiteName } from "../../../utils/enums";

export function getPublicLayoutLogo(siteName: SiteName) {
  if (siteName === SiteName.Alva) {
    return AlvaLogo;
  } else {
    if (siteName === SiteName.Wavelr) {
      return WavelrLogo;
    } else {
      return LogoWhite;
    }
  }
}
