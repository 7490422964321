import { List } from "@mantine/core";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function NetztransparenzValuesError({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const transparenzValuesErrors = paragraph6CreditErrors.filter(
    (error) => error.netztransparenz_values_missing
  );

  // render null if there are no transparenzValues errors
  if (transparenzValuesErrors.length === 0) {
    return null;
  }

  return (
    <List.Item>Veröffentlichung der Monats- und Jahresmarktwerte</List.Item>
  );
}

export { NetztransparenzValuesError };
