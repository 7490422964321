import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { StructureViewMode } from "../components/StructureView/StructureView.constants";
import type { SketchData } from "../components/StructureViewFlowDiagram/StructureViewFlowDiagram.types";
import urls from "../urls";
import { DIAGRAM_QUERY_KEY } from "./useStructureViewFlowDiagramMutations";

function useStructureViewFlowDiagram(siteId: number, mode: StructureViewMode) {
  const { isLoading, error, data } = useQuery({
    queryKey: DIAGRAM_QUERY_KEY(mode, siteId),
    queryFn: () => fetchPremiumSketchData(siteId, mode)
  });

  async function fetchPremiumSketchData(
    siteId: number,
    mode: StructureViewMode
  ) {
    const url =
      mode === StructureViewMode.MeteringConcept
        ? urls.api.meteringConceptSketchData(siteId)
        : urls.api.extendedSketchData(siteId);
    const response = await api.get<SketchData>(url);

    return response.data;
  }

  return {
    isLoading,
    error,
    data
  };
}

export { useStructureViewFlowDiagram };
