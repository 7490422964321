import { useEffect, useMemo, useState } from "react";
import type { Control, FieldError } from "react-hook-form";
import { useSupplierGenerators } from "../../../../../../../hooks/useSupplierGenerators";
import type { Delivery } from "../../../../../../../utils/backend-types";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { HelperText } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import type { Choice } from "../../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../../../../LoadOrError/LoadOrError";
import { DELIVERY_FORM_FIELD_DATA } from "../../DeliveryForm.constants";
import { areGeneratorsValid } from "../../utils/areGeneratorsValid";
interface DeliveringGeneratorProps {
  control: Control<Delivery>;
  error?: FieldError;
  contract: string;
  site: number | null;
  generatorsValue?: Array<number>;
  disabled?: boolean;
  resetGenerators: () => void;
}

function DeliveringGenerator({
  generatorsValue,
  control,
  error,
  contract,
  site,
  resetGenerators,
  disabled
}: DeliveringGeneratorProps) {
  const [indexKey, setIndexKey] = useState(0);
  const { isLoading, data: generators } = useSupplierGenerators(
    contract,
    site ?? undefined
  );
  const generatorChoices = useMemo(() => {
    return (
      generators?.map<Choice<number>>((generator) => ({
        value: generator.id,
        displayName: generator.name
      })) ?? []
    );
  }, [generators]);

  useEffect(() => {
    const generatorsFieldState = control.getFieldState(
      DELIVERY_FORM_FIELD_DATA.generators.name
    );

    if (
      generatorsFieldState.isDirty &&
      areGeneratorsValid(generators, generatorsValue)
    ) {
      resetGenerators();
      setIndexKey((prev) => prev + 1);
    }
  }, [generators, generatorsValue, resetGenerators, control]);

  return (
    <div className="DeliveringGenerator" key={indexKey}>
      <div className="fields">
        <LoadOrError loading={isLoading}>
          {!isLoading && (
            <>
              <FormFieldController
                control={control}
                data={{
                  ...DELIVERY_FORM_FIELD_DATA.generators,
                  choices: generatorChoices,
                  disabled
                }}
              />
              <HelperText error={error} />
            </>
          )}
        </LoadOrError>
      </div>
    </div>
  );
}

export { DeliveringGenerator };
