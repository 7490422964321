import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_RowData
} from "mantine-react-table";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDefaultMRTOptions } from "../../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTRowEditModeOptions } from "../../../../../mantine/getDefaultMRTRowEditModeOptions";
import type {
  Meter,
  MeterTableErrors,
  MeterWithSource
} from "../../../SiteSetupAssistant.types";
import { getIsCalibratedColumn } from "./Columns/getIsCalibratedColumn";
import { getMarketLocationFeedinColumn } from "./Columns/getMarketLocationFeedinColumn";
import { getMarketLocationFeedoutColumn } from "./Columns/getMarketLocationFeedoutColumn";
import { getMeteringLocationColumn } from "./Columns/getMeteringLocationColumn";
import { getMeterMeasurementTypeColumn } from "./Columns/getMeterMeasurementTypeColumn";
import { getMeterNumberColumn } from "./Columns/getMeterNumberColumn";
import { getNameColumn } from "./Columns/getNameColumn";
import { validateMeter } from "./validateMeter";

interface MeterTableProps {
  meterTableErrors: MeterTableErrors | null;
  meters: Array<MeterWithSource>;
  onBlockNavigation: (blocked: boolean) => void;
  onChange: (data: Array<MeterWithSource>) => void;
}

function MeterTable({
  meters,
  onBlockNavigation,
  onChange,
  meterTableErrors
}: MeterTableProps) {
  const [errors, setErrors] = useState<
    Partial<{ [k in keyof Meter]: string | null }>
  >({});

  const { t } = useTranslation();

  const handleRowValueChange = useCallback(
    (row: MRT_RowData) => {
      if (Object.keys(errors).length > 0) {
        const newErrors = validateMeter(row._valuesCache, t);
        if (Object.values(newErrors).some((error) => error !== null)) {
          setErrors(newErrors);
        } else {
          setErrors({});
        }
      }
    },
    [errors, t]
  );

  const hasCellError = useCallback(
    (rowIndex: number, accessorKey: keyof Meter) => {
      return (
        meterTableErrors &&
        meterTableErrors[rowIndex] &&
        meterTableErrors[rowIndex][accessorKey] &&
        !meters[rowIndex][accessorKey]
      );
    },
    [meterTableErrors, meters]
  );

  const columns = useMemo<Array<MRT_ColumnDef<MeterWithSource>>>(
    () => [
      getNameColumn(hasCellError),
      getMeterMeasurementTypeColumn(hasCellError),
      getIsCalibratedColumn(hasCellError),
      getMeteringLocationColumn({
        errorText: errors.metering_location,
        onChange: handleRowValueChange,
        hasCellError: hasCellError
      }),
      getMarketLocationFeedinColumn({
        errorText: errors.market_location_feedin,
        onChange: handleRowValueChange,
        hasCellError
      }),
      getMarketLocationFeedoutColumn({
        errorText: errors.market_location_feedout,
        onChange: handleRowValueChange,
        hasCellError
      }),
      getMeterNumberColumn(hasCellError)
    ],
    [errors, handleRowValueChange, hasCellError]
  );

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<MeterWithSource>({
      emptyRowsFallbackText:
        "Nach der Erstellung von Erzeugern und Verbrauchern in den vorherigen Schritten können hier die Zähler bearbeitet werden."
    }),
    ...getDefaultMRTRowEditModeOptions<MeterWithSource>({
      rowActions: {
        edit: {
          text: "Zähler bearbeiten",
          onClick: () => {
            onBlockNavigation(true);
          }
        }
      }
    }),
    columns,
    data: meters,
    enableRowSelection: false,
    enableFilters: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 15
      }
    },
    mantineTopToolbarProps: {
      hidden: true
    },
    onEditingRowSave: ({ values, row }) => {
      const newErrors = validateMeter(values, t);
      if (Object.values(newErrors).some((error) => error !== null)) {
        setErrors(newErrors);
      } else {
        const newData = [
          ...meters.map((meter) => {
            if (meter.name === row.getValue("name")) {
              return { ...meter, ...values };
            } else {
              return meter;
            }
          })
        ];
        newData[row.index] = {
          ...values,
          source: meters[row.index].source,
          parentIndex: meters[row.index].parentIndex
        };
        onChange(newData);
        table.setEditingRow(null);
        setErrors({});
        onBlockNavigation(false);
      }
    },
    onEditingRowCancel: () => {
      setErrors({});
      onBlockNavigation(false);
    }
  });

  return (
    <div className="MeterTable">
      <MantineReactTable table={table} />
    </div>
  );
}

export { MeterTable, MeterTableProps };
