import {
  Alert as MantineAlert,
  type AlertProps as MantineAlertProps
} from "@mantine/core";
import classNames from "classnames";

export enum AlertColor {
  Success = "teal",
  Danger = "red",
  Info = "blue",
  Warning = "yellow",
  /** @deprecated This is an old style from Bootstrap and shouldn't be used anymore. You can use the colour directly, if you want. */
  Light = "#fefefe",
  /** @deprecated This is an old style from Bootstrap and shouldn't be used anymore. You can use the colour directly, if you want. */
  Dark = "#d3d3d4",
  /** @deprecated This is an old style that shouldn't be used anymore. You can use the colour directly, if you want. */
  Brand = "#00a79d" // THEME_VARS.brandColor
}

export type AlertProps = Omit<MantineAlertProps, "color"> & {
  color?: AlertColor | MantineAlertProps["color"];
};

function Alert({ autoContrast, className, color, ...props }: AlertProps) {
  const overrideAutoContrast =
    color === AlertColor.Light || color === AlertColor.Dark;
  const variant =
    overrideAutoContrast || color === AlertColor.Brand
      ? "filled"
      : (props.variant ?? "light");

  return (
    <MantineAlert
      {...props}
      autoContrast={autoContrast ?? overrideAutoContrast}
      className={classNames("Alert", className)}
      closeButtonLabel="Schließen"
      color={color}
      variant={variant}
    >
      {props.children}
    </MantineAlert>
  );
}

export { Alert };
