import {
  type SiteSetupProcessForForm,
  type SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function formatBackendDataToFrontend(
  rawData?: SiteSetupProcessRaw
): SiteSetupProcessForForm | null {
  if (!rawData) {
    return null;
  }

  const frontendData: SiteSetupProcessForForm = {
    step: rawData.step,

    /* site */
    name: rawData.site?.name ?? "",
    pvPlants:
      rawData.site?.pv_plants?.map((pvPlant) => ({
        ...pvPlant,
        meter: pvPlant.meter || {
          name:
            "Zähler " +
            (pvPlant.see_number || pvPlant.name || pvPlant.operator?.name),
          is_calibrated: true
        }
      })) ?? [],
    siteAddress: rawData.site?.address?.address,
    siteZip: rawData.site?.address?.zip,
    siteCity: rawData.site?.address?.city,
    siteSupplyType: rawData.site?.supply_type,
    connectionVoltageLevel: rawData.site?.connection_voltage_level,
    gridOperator: rawData.site?.grid_operator,
    batteryStorage: rawData.site?.battery_storage,

    /* connection meter (in site) */
    connectionName:
      rawData.site?.connection_meter?.name || "Zähler Netzverknüpfungspunkt", // default value since there is no field in the liko for it
    connectionMeasurementType: rawData.site?.connection_meter?.measurement_type,
    connectionMeteringDirection:
      rawData.site?.connection_meter?.metering_direction,
    connectionMeteringServiceProvider:
      rawData.site?.connection_meter?.metering_service_provider,
    // frontend-only parameter
    connectionLocationType: rawData.site?.connection_meter?.metering_location
      ? "Messlokation"
      : rawData.site?.connection_meter?.market_location_feedin ||
          rawData.site?.connection_meter?.market_location_feedout
        ? "Marktlokation"
        : "Keine",
    connectionMeteringLocation:
      rawData.site?.connection_meter?.metering_location,
    connectionMarketLocationFeedin:
      rawData.site?.connection_meter?.market_location_feedin,
    connectionMarketLocationFeedout:
      rawData.site?.connection_meter?.market_location_feedout,
    connectionNumber: rawData.site?.connection_meter?.number || null,
    connectionIsCalibrated:
      typeof rawData.site?.connection_meter?.is_calibrated === "boolean"
        ? rawData.site.connection_meter.is_calibrated
        : true,

    defaultPvPlantsMeasurementType: rawData.default_pv_plants_measurement_type,
    connectionMeterEnergyDataOrigin:
      rawData.connection_meter_energy_data_origin,
    voltageLevelDeterminedAutomatically:
      rawData.voltage_level_determined_automatically,
    gridOperatorDeterminedAutomatically:
      rawData.grid_operator_determined_automatically,
    consumerAmount: rawData.consumer_amount,
    defaultConsumerType: rawData.default_consumer_type,
    defaultConsumerMeasurementType: rawData.default_consumer_measurement_type,
    allTenantAddressesAreSameAsSiteAddress:
      rawData.all_tenant_addresses_are_same_as_site_address,
    allTenantsParticipatingInMieterstrom:
      rawData.all_tenants_participating_in_mieterstrom,
    siteHasBatteryStorage: rawData.site_has_battery_storage,
    isBatteryStorageMeasuredSeparately:
      rawData.is_battery_storage_measured_separately,
    consumers: rawData.site?.consumers ?? []
  };

  return frontendData;
}
