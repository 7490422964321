import { Group, Kbd, Text } from "@mantine/core";
import debounce from "lodash/debounce";
import { useContext, useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Input, ListGroup, ListGroupItem } from "reactstrap";
import api from "../../api";
import { MANAGER_URL } from "../../routes";
import urls from "../../urls";
import { ShowStaffViewContext } from "../App/ShowStaffViewContext";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";
import "./SiteQuickSwitch.scss";

interface SearchResult {
  id: number;
  name: string;
}

const LIMIT = 10;

export function SiteQuickSwitch() {
  const [modal, setModal] = useState(false);
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>([]);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const showStaffView = useContext<boolean | undefined>(ShowStaffViewContext);
  const [term, setTerm] = useState<string | undefined>(undefined);
  const [offset, setOffset] = useState<number>(0);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const keyDownHandler = function (event: KeyboardEvent) {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault();
        toggle();
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (searchResults.length > 0) {
      setFocusIndex(0);
    } else {
      setFocusIndex(null);
    }
  }, [searchResults, setFocusIndex]);

  if (!showStaffView) {
    return null;
  }

  async function getNewSearchResults(term: string) {
    setTerm(term);
    setOffset(0);
    const results = await fetchSearchResults(term, LIMIT, 0);
    setSearchResults(results);
  }

  async function getMoreSearchResults(term: string) {
    const newOffset = offset + LIMIT;
    setOffset((prevOffset) => prevOffset + LIMIT);
    const results = await fetchSearchResults(term, LIMIT, newOffset);
    setSearchResults((prevSearchResults) => [...prevSearchResults, ...results]);
    inputRef?.current?.focus();
  }

  async function fetchSearchResults(
    term: string,
    limit: number,
    offset: number
  ) {
    const response = await api.get<Array<SearchResult>>(
      urls.api.siteSearch(term, limit, offset)
    );
    return response.data;
  }

  function toggle() {
    setModal((prevState) => !prevState);
    setSearchResults([]);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusIndex((prevFocusIndex) =>
        prevFocusIndex === null
          ? null
          : Math.min(prevFocusIndex + 1, searchResults.length - 1)
      );
    } else if (e.key == "ArrowUp") {
      e.preventDefault();
      setFocusIndex((prevFocusIndex) =>
        prevFocusIndex === null ? null : Math.max(prevFocusIndex - 1, 0)
      );
    } else if (e.key == "Enter") {
      if (focusIndex === null) return;
      const result = searchResults[focusIndex];
      if (result.id) navigateToSite(result.id);
    }
  }

  function navigateToSite(siteId: number) {
    navigate(
      generatePath(`${MANAGER_URL}liegenschaften/:siteId/`, {
        siteId: siteId.toString()
      })
    );
    toggle();
  }

  return (
    <>
      <Button
        title="Liegenschaft finden (Strg + K)"
        variant="outline"
        onClick={toggle}
      >
        <Group gap="xs">
          <Text c="dimmed" className="" fz="sm">
            Springen
          </Text>
          <Kbd fz={"xs"}>Ctrl + K</Kbd>
        </Group>
      </Button>
      <Modal
        autoFocus={false}
        isOpen={modal}
        toggle={toggle}
        onKeyDown={handleKeyDown}
      >
        <ModalHeader toggle={toggle}>Liegenschaft finden</ModalHeader>
        <ModalBody>
          <Input
            autoFocus
            id="searchbox"
            innerRef={inputRef}
            placeholder="Name oder ID der Liegenschaft eingeben"
            type="text"
            onChange={debounce(
              (event) => getNewSearchResults(event.target.value as string),
              300
            )}
          />
          <div id="search-results">
            {searchResults.length === 0 && (
              <div className="text-center">Keine Liegenschaften gefunden</div>
            )}

            <span className="small">
              {searchResults.length === 1 &&
                `${searchResults.length} Liegenschaft`}
              {searchResults.length > 1 &&
                `${searchResults.length} Liegenschaften`}
            </span>

            {searchResults.length > 0 && (
              <ListGroup numbered>
                {searchResults.map((result, index) => (
                  <ListGroupItem
                    className={focusIndex === index ? "focused" : ""}
                    key={result.id}
                    onClick={() => navigateToSite(result.id)}
                    onMouseEnter={() => setFocusIndex(index)}
                  >
                    <div className="search-result-box">
                      <div className="search-result-name">{result.name}</div>
                      <div className="search-result-id">{result.id}</div>
                      <div
                        className="search-result-focus-icon"
                        hidden={focusIndex !== index}
                      >
                        <Icon name={IconName.ArrowCircleRight} />
                      </div>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
            {term && searchResults.length === LIMIT + offset && (
              <div className="text-center" id="load-more-button">
                <Button color="link" onClick={() => getMoreSearchResults(term)}>
                  Mehr laden
                </Button>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
