import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ReactDOM from "react-dom";

import api from "../../../../../api";
import { ObjectName } from "../../../../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../../../../utils/getPluralVariableNameFromObjectName";
import type { FormValues } from "../../../../CustomForm/useCustomForm";
import { CreatableDropdown } from "../../CreatableDropdown";
import type { FormFieldData, FormItemsProps } from "../../FormItems";
import type { Choice } from "../Dropdown/TsDropdown";
import type { FormFieldValue } from "../FormField";
import { CreateForm } from "./CreateForm";
import { EditForm } from "./EditForm";
import type { CreatableObject } from "./types";

export const VARIABLE_NAMES_TO_OBJECT_NAMES = {
  generator: ObjectName.Generator,
  consumer: ObjectName.Consumer,
  storage: ObjectName.Storage,
  person: ObjectName.Person,
  connectingParty: ObjectName.Person,
  supplyContractHeldBy: ObjectName.Person,
  connection: ObjectName.Connection,
  gasConnection: ObjectName.GasConnection,
  meter: ObjectName.Meter,
  ghostNode: ObjectName.GhostNode,
  marketLocation: ObjectName.MarketLocation,
  marketLocationFeedin: ObjectName.MarketLocation,
  marketLocationFeedout: ObjectName.MarketLocation,
  meteringLocation: ObjectName.MeteringLocation
} as const;

type ChoicesDict = Record<string, Array<Choice>>;

const choicesDictContextDefaultValue: [
  state: ChoicesDict,
  setState: (state: ChoicesDict) => void
] = [
  {},
  () => undefined // noop default callback
];
const ChoicesDictContext = createContext<
  [ChoicesDict, React.Dispatch<React.SetStateAction<ChoicesDict>>]
>(choicesDictContextDefaultValue);

export const ChoicesDictContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [choicesDict, setChoicesDict] = useState<ChoicesDict>({});

  return (
    <ChoicesDictContext.Provider value={[choicesDict, setChoicesDict]}>
      {children}
    </ChoicesDictContext.Provider>
  );
};

async function fetchPreferredChoice(url: string | undefined) {
  if (!url) {
    return;
  }

  const response = await api.get<{ id: number }>(url);

  return response.data.id;
}

interface StructureViewCreationDropdownProps
  extends Pick<FormItemsProps, "allowInput" | "formName" | "onInput"> {
  errors: Array<string>;
  fieldName: string;
  formItemData: FormFieldData | undefined;
  formItemNode?: HTMLElement | null;
  includedFields: Array<string>; // fields to be included from the OPTIONS calls in the create/edit form
  nonFieldData: FormValues;
  postUrl: string;
  preferredChoiceUrl?: string;
  putUrl: string;
  value: FormFieldValue;
}

/**
 * A form field that allows the user to create new choices or edit existing for a dropdown.
 *
 * If `formItemNode` is not provided, the form field will be rendered in the same place as the other form fields.
 *
 * To get a dropdown, `formItemData` must include `choices` and be of a dropdown type (e.g. Choice).
 */
/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function StructureViewCreationDropdown({
  allowInput,
  errors,
  fieldName,
  formItemData,
  formItemNode,
  formName,
  includedFields,
  nonFieldData,
  postUrl,
  preferredChoiceUrl,
  putUrl,
  value,
  onInput
}: StructureViewCreationDropdownProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [choicesDict, setChoicesDict] = useContext(ChoicesDictContext);
  const [createFormNode, setCreateFormNode] = useState<HTMLElement | null>(
    null
  );
  const [editFormNode, setEditFormNode] = useState<HTMLElement | null>(null);
  const [numComponentsCreatedOrUpdated, setNumComponentsCreatedOrUpdated] =
    useState(0);

  const queryClient = useQueryClient();

  const { data: preferredChoice } = useQuery({
    queryKey: ["preferred-choice", { fieldName, preferredChoiceUrl }],
    queryFn: () => fetchPreferredChoice(preferredChoiceUrl),
    enabled: !!preferredChoiceUrl
  });

  useEffect(() => {
    if (!value && isEditing) {
      setIsEditing(false);
    }
  }, [value, isEditing]);

  const getNewOrUpdatedChoices = useCallback(() => {
    return choicesDict[postUrl] || [];
  }, [choicesDict, postUrl]);

  function setNewOrUpdatedChoices(newChoices: Array<Choice>) {
    setChoicesDict({
      ...choicesDict,
      [postUrl]: newChoices
    });
  }

  function handleSelect(id: string | number) {
    onInput(fieldName, id);
  }

  const handleCreateFormNodeChange = useCallback(
    (node: HTMLElement) => {
      setCreateFormNode(node);
    },
    [setCreateFormNode]
  );

  const handleEditFormNodeChange = useCallback(
    (node: HTMLElement) => {
      setEditFormNode(node);
    },
    [setEditFormNode]
  );

  function handleStartCreating() {
    setIsCreating(true);
  }

  function handleStartEditing() {
    setIsEditing(true);
  }

  function handleCreate(newObject: CreatableObject) {
    const newerChoices = [
      ...getNewOrUpdatedChoices(),
      {
        displayName: newObject.name,
        value: newObject.id
      } as Choice
    ];

    storeCreatedOrUpdatedComponent(newObject);
    setNewOrUpdatedChoices(newerChoices);
    handleSelect(newObject.id);
    setIsCreating(false);
    setNumComponentsCreatedOrUpdated(numComponentsCreatedOrUpdated + 1);
  }

  function handleEdit(updatedObject: CreatableObject) {
    const newerChoices = getNewOrUpdatedChoices().filter(
      (choice) => choice.value !== updatedObject.id
    );

    newerChoices.push({
      displayName: updatedObject.name,
      value: updatedObject.id
    });

    storeCreatedOrUpdatedComponent(updatedObject);
    setNewOrUpdatedChoices(newerChoices);
    handleSelect(updatedObject.id);
    setIsEditing(false);
    setNumComponentsCreatedOrUpdated(numComponentsCreatedOrUpdated + 1);
  }

  function storeCreatedOrUpdatedComponent(newOrUpdatedObject: CreatableObject) {
    const objectName: ObjectName = VARIABLE_NAMES_TO_OBJECT_NAMES[fieldName];
    const objectCacheName = getPluralVariableNameFromObjectName(objectName);

    if (objectName === ObjectName.Person) {
      queryClient.invalidateQueries({
        queryKey: [
          objectCacheName,
          {
            siteOrVariantId: newOrUpdatedObject.variant
          }
        ]
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          objectCacheName,
          {
            siteOrVariantId: newOrUpdatedObject.site
          }
        ]
      });
    }
  }

  const createFormName = `${fieldName}_form`;
  const editFormName = `${fieldName}_edit_form`;
  const choices = useMemo(() => {
    const formItemChoices = formItemData ? formItemData.choices : [];
    const combinedChoices = _.unionBy(
      getNewOrUpdatedChoices(),
      formItemChoices,
      (choice) => choice.value
    );

    if (!preferredChoice) {
      return combinedChoices;
    }

    return combinedChoices.map((choice) => ({
      ...choice,
      displayName:
        preferredChoice === choice.value
          ? `${choice.displayName} (automat. Vorschlag)`
          : choice.displayName
    }));
  }, [formItemData, getNewOrUpdatedChoices, preferredChoice]);

  return (
    <div className="CreatableDropdownField">
      {formItemData &&
        (formItemNode === undefined ? (
          <CreatableDropdown
            allowInput={allowInput}
            choices={choices}
            errors={errors}
            formItemData={formItemData}
            formName={formName}
            isCreating={isCreating}
            isEditing={isEditing}
            key={numComponentsCreatedOrUpdated}
            value={value}
            onCreateFormNodeChange={handleCreateFormNodeChange}
            onEditFormNodeChange={handleEditFormNodeChange}
            onInput={onInput}
            onStartCreating={handleStartCreating}
            onStartEditing={handleStartEditing}
          />
        ) : (
          formItemNode &&
          ReactDOM.createPortal(
            <CreatableDropdown
              allowInput={allowInput}
              choices={choices}
              errors={errors}
              formItemData={formItemData}
              formName={formName}
              isCreating={isCreating}
              isEditing={isEditing}
              key={numComponentsCreatedOrUpdated}
              value={value}
              onCreateFormNodeChange={handleCreateFormNodeChange}
              onEditFormNodeChange={handleEditFormNodeChange}
              onInput={onInput}
              onStartCreating={handleStartCreating}
              onStartEditing={handleStartEditing}
            />,
            formItemNode
          )
        ))}
      {createFormNode &&
        ReactDOM.createPortal(
          <CreateForm
            formName={createFormName}
            includedFields={includedFields}
            nonFieldData={nonFieldData}
            postUrl={postUrl}
            onCancel={() => setIsCreating(false)}
            onSubmit={handleCreate}
          />,
          createFormNode
        )}
      {editFormNode &&
        value &&
        isEditing &&
        ReactDOM.createPortal(
          <EditForm
            formName={editFormName}
            includedFields={includedFields}
            nonFieldData={nonFieldData}
            putUrl={putUrl}
            onCancel={() => setIsEditing(false)}
            onSubmit={handleEdit}
          />,
          editFormNode
        )}
    </div>
  );
}

export { StructureViewCreationDropdown, StructureViewCreationDropdownProps };
