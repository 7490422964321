import { List, Text } from "@mantine/core";
import type { DateTime } from "luxon";
import { useState } from "react";
import { useDeleteFullMbk } from "../../hooks/useDeleteFullMbk";
import { useSiteNotifications } from "../../hooks/useSiteNotifications";
import { LUXON_END_OF_LAST_YEAR } from "../../utils/dates";
import { showToast } from "../../utils/toast";
import { AlertColor } from "../Alert/Alert";
import { IconAlert } from "../BuildingBlocks/IconAlert/IconAlert";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { DeleteConfirmationModal } from "../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { EdaVisualization } from "./EdaVisualization/EdaVisualization";
import { EdaVisualization as EdaVisualizationV2 } from "./EdaVisualization/EdaVisualization-v2";
import { useEdaData } from "./EdaVisualization/hooks/useEdaData";
import "./EdaVisualizationModal.scss";

interface EdaVisualizationModalProps {
  projectId: string;
  siteId: number;
  onCloseModal: () => void;
  isV2?: boolean;
}

function EdaVisualizationModal({
  projectId,
  siteId,
  onCloseModal,
  isV2
}: EdaVisualizationModalProps) {
  const shouldShowStaffView = useShouldShowStaffView();
  const [v1FilterDate, setV1FilterDate] = useState<DateTime>(
    LUXON_END_OF_LAST_YEAR
  );
  const edaData = useEdaData(
    siteId,
    isV2 ? { isV2: true } : { isV2: false, filterDate: v1FilterDate }
  );
  const { mutateAsync: deleteFullMbkAsync, isPending: isPendingDeleteFullMbk } =
    useDeleteFullMbk();
  const { data: siteNotifications } = useSiteNotifications(siteId);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] =
    useState(false);

  function handleChangeV1FilterDate(date: DateTime | null) {
    if (date) {
      setV1FilterDate(date);
    }
  }

  async function handleConfirmDeleteFullMbk() {
    setDeleteConfirmationIsOpen(false);

    try {
      const { status } = await deleteFullMbkAsync(siteId);

      if (status >= 200) {
        showToast(
          "success",
          "Mess- und Bilanzierungskonzept erfolgreich gelöscht."
        );
        onCloseModal();
      }
    } catch (error) {
      showToast("error", error);
    }
  }

  const {
    metersConversionFactor,
    generatorsFullFeedin,
    tenantOutsideMieterstromModel
  } = siteNotifications || {};
  const showBanner =
    shouldShowStaffView &&
    generatorsFullFeedin &&
    tenantOutsideMieterstromModel &&
    metersConversionFactor &&
    (generatorsFullFeedin.length > 0 ||
      metersConversionFactor.length > 0 ||
      tenantOutsideMieterstromModel.length > 0);
  const validMessages = showBanner
    ? [
        generatorsFullFeedin?.length > 0 && (
          <>
            Die folgenden Erzeuger sind als Volleinspeiser markiert:{" "}
            {generatorsFullFeedin.map((g) => g.name).join(", ")}
          </>
        ),
        metersConversionFactor?.length > 0 && (
          <>
            Die folgenden Zähler haben einen Wandlungsfaktor:{" "}
            {metersConversionFactor.map((m) => m.name).join(", ")}
          </>
        ),
        tenantOutsideMieterstromModel?.length > 0 && (
          <>
            Die folgenden Unternehmen nehmen nicht am Mieterstrommodell Teil:{" "}
            {tenantOutsideMieterstromModel.map((t) => t.name).join(", ")}
          </>
        )
      ].filter(Boolean)
    : [];
  return (
    <Modal className="EdaVisualizationModal" isOpen toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        Mess- und Bilanzierungskonzept{" "}
        {isV2 && "mit zeitlicher Veränderbarkeit"}
      </ModalHeader>
      <ModalBody scrollable>
        {showBanner && (
          <IconAlert className="DataAlert" color={AlertColor.Info}>
            {validMessages.length > 1 ? (
              <List>
                {validMessages.map((content, index) => (
                  <List.Item key={index}>{content}</List.Item>
                ))}
              </List>
            ) : (
              <Text>{validMessages[0]}</Text>
            )}
          </IconAlert>
        )}
        {edaData.isV2 ? (
          <EdaVisualizationV2
            mbksData={edaData.data}
            mbksError={edaData.error}
            mbksIsLoading={edaData.isLoading}
            refetchMbks={edaData.refetchMbks}
            siteId={siteId}
          />
        ) : (
          <EdaVisualization
            filterDate={v1FilterDate}
            initialAcquisitions={edaData.data}
            initialAcquisitionsError={edaData.error}
            initialAcquisitionsIsLoading={edaData.isLoading}
            projectId={projectId}
            siteId={siteId}
            onChangeFilterDate={handleChangeV1FilterDate}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <div className="modal-two-button-footer">
          {isV2 && (
            <SpinButton
              color="danger"
              disabled={!edaData.data || edaData.data.length === 0}
              spin={isPendingDeleteFullMbk}
              onClick={() => setDeleteConfirmationIsOpen(true)}
            >
              Löschen
            </SpinButton>
          )}
          <Button color="secondary" onClick={onCloseModal}>
            Schließen
          </Button>
        </div>
        {isV2 && (
          <DeleteConfirmationModal
            isModalOpen={deleteConfirmationIsOpen}
            modalBodyElement={
              <p>
                Klicken Sie auf Löschen, wenn Sie das Mess- und
                Bilanzierungskonzept löschen möchten. Es kann danach nicht
                wiederhergestellt werden. In Ihrem Dashboard können Sie ein
                neues MBK erstellen.
              </p>
            }
            name="Mess- und Bilanzierungskonzept"
            toggleModal={() => setDeleteConfirmationIsOpen(false)}
            onClickConfirm={handleConfirmDeleteFullMbk}
          />
        )}
      </ModalFooter>
    </Modal>
  );
}

export enum EdaTab {
  General = "",
  Deliveries = "liefermodelle"
}

export { EdaVisualizationModal };
