import type { Column } from "react-table";
import { useOnboardingAssistantDocuments } from "../../../../hooks/useOnboardingAssistantDocuments";
import urls from "../../../../urls";
import type { OnboardingAssistantDocuments } from "../../../../utils/backend-types";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import { DownloadLink } from "../../../DownloadLink/DownloadLink";

export type OnboardingDocumentsProps = {
  siteId: number;
  onClose: () => void;
};

const COLUMNS: Array<Column<DocumentData>> = [
  {
    Header: "Dokument",
    accessor: "document",
    Cell: ({ value }) => {
      return (
        <>
          <Icon name={IconName.FileText} />
          <DownloadLink
            filename={value.name}
            url={urls.api.todoDocumentDownload(value.id)}
          >
            {value.name}
          </DownloadLink>
        </>
      );
    },
    minWidth: 150,
    style: { display: "flex", alignItems: "center" }
  },
  {
    Header: "Aufgabe",
    accessor: "todoLabel",
    minWidth: 150,
    style: { display: "flex", alignItems: "center" }
  },
  {
    Header: "Erstellt von",
    accessor: "createdBy",
    minWidth: 150,
    style: { display: "flex", alignItems: "center" }
  },
  {
    Header: "Erstellt am",
    accessor: "createdAt",
    minWidth: 150,
    sortMethod: sortBackendDates,
    style: { display: "flex", alignItems: "center" }
  }
];

interface DocumentData {
  document: {
    id: number;
    name: string;
  };
  todoLabel: string;
  createdBy?: string;
  createdAt: string;
}

function getDocumentData(documents: OnboardingAssistantDocuments) {
  const data: Array<DocumentData> = [];
  documents.forEach((document) => {
    document.todoFiles.forEach((file) => {
      data.push({
        document: {
          name: file.name,
          id: file.id
        },
        todoLabel: document.label,
        createdBy: file.createdBy?.name,
        createdAt: file.creationDatetime
      });
    });
  });
  return data;
}

function OnboardingDocuments({ siteId, onClose }: OnboardingDocumentsProps) {
  const { data: documents } = useOnboardingAssistantDocuments(siteId);
  const documentData = documents ? getDocumentData(documents) : [];
  return (
    <Modal isOpen size="xl">
      <ModalHeader>Onboarding Dokumente</ModalHeader>
      <ModalBody>
        <CustomReactTable
          columns={COLUMNS}
          data={documentData}
          pageSize={5}
          showPageJump
          showPagination={documentData.length > 5}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Schließen</Button>
      </ModalFooter>
    </Modal>
  );
}

export { OnboardingDocuments };
