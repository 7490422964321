import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import type { components } from "../../../types/api.types";
import urls from "../../../urls";

type SiteSetupProcess = components["schemas"]["SiteSetupProcess"];

interface SiteSetupProcessesReturnValue {
  siteSetupProcesses: Array<SiteSetupProcess>;
  isLoading: boolean;
  error: unknown;
}

function useSiteSetupProcesses(
  variantId: number | undefined
): SiteSetupProcessesReturnValue {
  const { featureFlags } = useFeatureFlags();
  const isSiteSetupAssistantEnabled = featureFlags.siteSetupAssistant;

  const { isLoading, error, data } = useQuery({
    queryKey: ["project-site-setup-processes-sites", { variantId }],
    queryFn: () => fetchSetupProcesses(variantId),
    enabled: isSiteSetupAssistantEnabled && typeof variantId !== "undefined",
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  return {
    isLoading,
    error,
    siteSetupProcesses: data || []
  };
}

export async function fetchSetupProcesses(variantId: number | undefined) {
  if (typeof variantId === "undefined") {
    return undefined;
  }
  const response = await apiWithoutCamelization.get<Array<SiteSetupProcess>>(
    urls.apiWithoutCamelization.siteSetupAssistant.siteSetupProcesses(variantId)
  );
  return response.data;
}

export { useSiteSetupProcesses, SiteSetupProcess };
