import { type LineChartSeries } from "@mantine/charts";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { Text as RechartsText } from "recharts";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { UNITS } from "../../../../utils/constants";
import { DATE_TIME_DISPLAY_WITHOUT_SECONDS_FORMAT } from "../../../../utils/dates/dates.constants";
import type { Unit } from "../../../../utils/enums";
import { MantineBarChart } from "../../../Charts/BarChart/MantineBarChart";
import { MantineLineChart } from "../../../Charts/LineChart/MantineLineChart";
import type { CounterData } from "../../../DataSheet/DataSheet";

interface AcquisitionPlotProps {
  acquisitionSerieses: CounterData;
  selectedUnitValue: Unit;
}

function AcquisitionPlot({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionPlotProps) {
  const barDataIds = Object.keys(acquisitionSerieses.header);
  const barSeries = [
    {
      name: "value"
    }
  ];
  const barData = barDataIds.map((id) => ({
    category: acquisitionSerieses.labels?.[id]?.replaceAll("<br>", " ") || id,
    value: acquisitionSerieses.values[id][0]
  }));

  const selectedUnit = UNITS[selectedUnitValue];
  const yAxisDescription = selectedUnit.physicalSize;
  const yAxisTitle = `${yAxisDescription} [${selectedUnitValue}]`;
  const valueCount = acquisitionSerieses.index
    ? acquisitionSerieses.index.length
    : 0;

  const dataIds = Object.keys(acquisitionSerieses.header);
  const series: Array<LineChartSeries & { color: string }> = dataIds.map(
    (id, idIndex) => ({
      name: id,
      label:
        acquisitionSerieses.labels?.[id].replaceAll("<br>", " ") || "Unbekannt",
      color: seriesColors[idIndex % seriesColors.length]
    })
  );
  const data = useMemo(
    () =>
      acquisitionSerieses.index.map((index, indexIndex) => ({
        date: DateTime.fromISO(index[0]).toMillis(),
        ...dataIds.reduce(
          (result, id) => ({
            ...result,
            [id]: acquisitionSerieses.values[id][indexIndex]
          }),
          {}
        )
      })),
    [acquisitionSerieses, dataIds]
  );

  if (valueCount === 1) {
    return (
      <MantineBarChart
        data={barData}
        dataKey="category"
        h={500}
        series={barSeries}
        tickLine="y"
        xAxisProps={{
          height: 90,
          tick: ({ x, y, payload }) => (
            <RechartsText
              breakAll={true}
              textAnchor="middle"
              verticalAnchor="start"
              width={60}
              x={x}
              y={y}
            >
              {payload.value}
            </RechartsText>
          )
        }}
        yAxisLabel={yAxisTitle}
        yAxisProps={{ width: 75 }}
      />
    );
  } else {
    return (
      <MantineLineChart
        data={data}
        dataKey="date"
        h={500}
        series={series}
        withLegend
        xAxisProps={{
          type: "number",
          tickFormatter: (timeStamp) =>
            DateTime.fromMillis(timeStamp).toFormat(
              DATE_TIME_DISPLAY_WITHOUT_SECONDS_FORMAT
            )
        }}
        yAxisLabel={yAxisTitle}
      />
    );
  }
}

export { AcquisitionPlot, AcquisitionPlotProps };
