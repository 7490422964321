import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { ControlledMantineEditAddressInfo } from "../../../../../../mantine/mrt/components/edit/ControlledMantineEditAddressInfo";
import { ErrorCell } from "../ErrorCell/ErrorCell";

export function getAddressColumn<T extends MRT_RowData>(
  hasCellError: (rowIndex: number, accessorKey: string) => boolean
): MRT_ColumnDef<T> {
  return {
    accessorKey: "tenantAddress",
    header: "Adresse des Mieters",
    Edit: ({ row, cell, column }) => {
      return (
        <ControlledMantineEditAddressInfo
          allowInput
          cell={cell}
          columnId={column.id}
          row={row}
        />
      );
    },
    Cell: ({ row }) => (
      <>
        {row.original.tenantAddress.address} {row.original.tenantAddress.zip}{" "}
        {row.original.tenantAddress.city}
        {hasCellError(row.index, "tenantAddress") && <ErrorCell />}
      </>
    )
  };
}
