import { memo } from "react";
import type { Column } from "react-table";

import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type { LocationOverviewData } from "../LocationOverview.types";
import { getDocumentFolderColumn } from "./Columns/DocumentFolderColumn";
import { getLocationColumn } from "./Columns/LocationColumn";
import { getMeteringDataColumn } from "./Columns/MeteringDataColumn";
import { getMsbColumn } from "./Columns/MsbColumn";
import { getSiteColumn } from "./Columns/SiteColumn";
import { getUpdatedColumn } from "./Columns/UpdatedColumn";

import "./LocationOverviewTable.scss";

const COLUMNS: Array<Column<LocationOverviewData>> = [
  {
    Header: "Lokationsnummer",
    id: "locationNumber",
    accessor: (row) => row.number,
    sortable: false,
    width: 275
  },
  getLocationColumn(),
  getMsbColumn(),
  getSiteColumn(),
  getDocumentFolderColumn(),
  getMeteringDataColumn(),
  getUpdatedColumn()
];

interface LocationOverviewTableProps {
  data: Array<LocationOverviewData>;
  isLoading: boolean;
  numPages: number;
  onFetchData: (page: number, pageSize: number) => void;
}

function LocationOverviewTable({
  data,
  isLoading,
  numPages,
  onFetchData
}: LocationOverviewTableProps) {
  return (
    <div className="LocationOverviewTable">
      <CustomReactTable
        columns={COLUMNS}
        data={data}
        loading={isLoading}
        loadingText={<AnimatedLoadingIcon />}
        manual
        minRows={0}
        pages={numPages}
        showPageJump
        showPagination
        onFetchData={onFetchData}
      />
    </div>
  );
}

const MemoizedLocationOverviewTable = memo(LocationOverviewTable);

export {
  MemoizedLocationOverviewTable as LocationOverviewTable,
  LocationOverviewTableProps
};
