import classNames from "classnames";
import { useId } from "react";
import type {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { FormInputData } from "../../../Controllers/FormFieldController";
import { FormFieldController } from "../../../Controllers/FormFieldController";
import { FormFieldLabel } from "../../../FormField/FormFieldLabel/FormFieldLabel";
import { HelperText } from "../../../FormField/HelperText/HelperText";
import { getErrorKeyFromFieldError } from "../../../FormField/HelperText/utils/getErrorKeyFromFieldError";
import { isFieldError } from "../../../FormField/HelperText/utils/isFieldError";
import "./DueDateFields.scss";

interface DueDateFieldsProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> {
  formFieldData: {
    [key in TName]: FormInputData<T, TName>;
  };
  valueFieldName: TName;
  unitFieldName: TName;
  control: Control<T>;
  errors: FieldErrors<T>;
  label?: string;
  prefix?: string;
}

function DueDateFields<T extends FieldValues, TName extends FieldPath<T>>({
  formFieldData,
  valueFieldName,
  unitFieldName,
  control,
  errors,
  label = "Fälligkeit",
  prefix
}: DueDateFieldsProps<T, TName>) {
  const { t } = useTranslation();
  const id = useId();

  const valueError = isFieldError(errors[valueFieldName])
    ? errors[valueFieldName]
    : undefined;
  const unitError = isFieldError(errors[unitFieldName])
    ? errors[unitFieldName]
    : undefined;
  const dueDateErrorKey = getErrorKeyFromFieldError(valueError || unitError);
  const dueDateError = dueDateErrorKey && t(dueDateErrorKey);

  const required =
    formFieldData[valueFieldName]?.required ||
    formFieldData[unitFieldName]?.required;

  const valueFieldData = { ...formFieldData[valueFieldName] };
  if (valueFieldData) {
    valueFieldData.id = id;
    valueFieldData.className = "due-date-value";
    valueFieldData.label = undefined;
  }

  const unitFieldData = formFieldData[unitFieldName];
  if (unitFieldData) {
    unitFieldData.className = "due-date-unit";
    unitFieldData.label = undefined;
  }

  return (
    <div
      className={classNames("DueDateFields", {
        "is-invalid": dueDateError
      })}
    >
      <FormFieldLabel formFieldId={id} label={label} required={required} />
      <div className="grouped-fields">
        {prefix && <span className="group-prefix">{prefix}</span>}
        {valueFieldData && (
          <FormFieldController control={control} data={valueFieldData} />
        )}
        {unitFieldData && (
          <FormFieldController control={control} data={unitFieldData} />
        )}
      </div>
      <HelperText error={dueDateError} />
    </div>
  );
}

export { DueDateFields, DueDateFieldsProps };
