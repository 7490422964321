import { DateTime } from "luxon";
import { Frequency } from "../../utils/enums";
import { Select } from "../BuildingBlocks/Forms/Select/Select";

import "./MonthYearRangePicker.scss";

export interface MonthYearRangePickerProps {
  start: DateTime;
  end: DateTime;
  onChange: (dates: { startDate: DateTime; endDate: DateTime | null }) => void;
  mode?: Frequency.Month | Frequency.Year;
  monthRange?: number;
  yearRange?: number;
}

interface MonthYearRangePickerOption {
  value: DateTime;
  label: string;
}

function MonthYearRangePicker({
  start,
  end,
  onChange,
  mode = Frequency.Year,
  monthRange = 4 * 12,
  yearRange = 4
}: MonthYearRangePickerProps) {
  const startOptions = buildOptions(DateTime.local().startOf(mode));
  const endOptions = buildOptions(DateTime.local().endOf(mode));

  function buildOptions(
    pivotDate: DateTime
  ): Array<MonthYearRangePickerOption> {
    const pastOptions: Array<MonthYearRangePickerOption> = [];
    const range = mode === "month" ? monthRange : yearRange;

    for (let i = 1; i <= range; i++) {
      const pastDate = pivotDate.minus({ [mode]: i });
      pastOptions.push(dateToOption(pastDate));
    }

    pastOptions.reverse();

    return [...pastOptions, dateToOption(pivotDate)];
  }

  function dateToOption(date: DateTime): MonthYearRangePickerOption {
    let format: Intl.DateTimeFormatOptions;

    if (mode === "month") {
      format = {
        month: "long",
        year: "numeric"
      };
    } else {
      format = {
        year: "numeric"
      };
    }

    return {
      value: date,
      label: date.setLocale("de-DE").toLocaleString(format)
    };
  }

  function handleStartChange(selected: { value: DateTime }) {
    const oldEndOrStart = selected.value > end ? selected.value : end;
    const newEnd = !end ? null : oldEndOrStart;

    onChange({ startDate: selected.value, endDate: newEnd });
  }

  function handleEndChange(selected: { value: DateTime }) {
    onChange({ startDate: start, endDate: selected.value });
  }

  return (
    <div className="FormField">
      <div className="MonthYearRangePicker">
        <Select
          defaultValue={start ? dateToOption(start) : undefined}
          name="start"
          options={startOptions}
          value={start ? dateToOption(start) : undefined}
          onChange={handleStartChange}
        />
        <span className="divider">bis</span>
        <Select
          defaultValue={end ? dateToOption(end) : undefined}
          name="end"
          options={endOptions.filter((option) => option.value >= start)}
          value={end ? dateToOption(end) : undefined}
          onChange={handleEndChange}
        />
      </div>
    </div>
  );
}

export { MonthYearRangePicker };
