import type { Filter } from "react-table";

import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import type { SiteStatusSite } from "../../site-status-types";
import { SiteCategory } from "../../site-status-types";

type CategoryRow = { _original: SiteStatusSite };

function CategoryFilter({ filter, onChange }) {
  const siteCategoryKeys = Object.keys(SiteCategory) as Array<
    keyof typeof SiteCategory
  >;
  const choices: Record<string, string> = siteCategoryKeys.reduce(
    (choices, key) => {
      const value = SiteCategory[key];
      choices[value] = value;
      return choices;
    },
    {}
  );

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterCategories(filter: Filter, row: CategoryRow) {
  const options = filter.value;
  const showVolleinspeisung = options[SiteCategory.Volleinspeisung];
  const showPvDirekt = options[SiteCategory.PvDirekt];
  const showEigenerzeugungPlus = options[SiteCategory.EigenerzeugungPlus];
  const showPremium = options[SiteCategory.Premium];
  const showPPAAAS = options[SiteCategory.PPAAAS];
  const site = row._original;

  if (
    showVolleinspeisung &&
    site.siteCategories.includes(SiteCategory.Volleinspeisung)
  ) {
    return true;
  }

  if (showPvDirekt && site.siteCategories.includes(SiteCategory.PvDirekt)) {
    return true;
  }

  if (
    showEigenerzeugungPlus &&
    site.siteCategories.includes(SiteCategory.EigenerzeugungPlus)
  ) {
    return true;
  }

  if (showPremium && site.siteCategories.includes(SiteCategory.Premium)) {
    return true;
  }

  if (showPPAAAS && site.siteCategories.includes(SiteCategory.PPAAAS)) {
    return true;
  }

  return false;
}

export { CategoryFilter, onFilterCategories, SiteCategory };
