import {
  Divider,
  Group,
  Select,
  Space,
  Stack,
  Title,
  type ComboboxItem
} from "@mantine/core";
import type { DatesRangeValue } from "@mantine/dates";
import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { AlertColor } from "../../Alert/Alert";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import {
  EnergyDataQuality,
  type DataQualityMeloMaloListItem
} from "../../EnergyData/EnergyDataQuality/EnergyDataQuality";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import type { ChartTableSwitchState } from "../ChartTableSwitch/ChartTableSwitch";
import { ChartTableSwitch } from "../ChartTableSwitch/ChartTableSwitch";
import { usePersonEnergyRevenuesData } from "../hooks/usePersonEnergyRevenuesData";
import type {
  EnergyRevenuesListResponse,
  RevenueMonitoringCompanies
} from "../RevenueMonitoring.types";
import "./EnergyRevenuesPage.scss";
import { CompanySection } from "./Sections/CompanySection";
import { GeneratorSection } from "./Sections/GeneratorSection";
import { getMinOrMaxDateFromEnergyRevenues } from "./utils/getMinOrMaxDateFromEnergyRevenues";

function flattenMissingDataSources(data: EnergyRevenuesListResponse) {
  const seenMeterIds = new Set();
  const flatList: DataQualityMeloMaloListItem[] = [];

  data.forEach((item) => {
    item.missing_data_sources.forEach((source) => {
      if (!seenMeterIds.has(source.melo_malo_id)) {
        seenMeterIds.add(source.melo_malo_id);
        flatList.push(source);
      }
    });
  });

  return flatList;
}

function averageAvailablePercent(data: EnergyRevenuesListResponse) {
  const total = data.reduce((sum, item) => sum + item.available_percent, 0);
  return total / data.length;
}

function calculateTotalVolume(energyRevenuesData: EnergyRevenuesListResponse) {
  return energyRevenuesData.reduce((acc, curr) => {
    return acc + curr.direktvermarktung_volume;
  }, 0);
}

interface EnergyRevenuesPageProps {
  companies: Array<RevenueMonitoringCompanies>;
}

function EnergyRevenuesPage({ companies }: EnergyRevenuesPageProps) {
  const { t } = useTranslation();
  const companyOptions = companies.map<ComboboxItem>((company) => ({
    label: company.name,
    value: company.id.toString()
  }));
  const [view, setView] = useState<ChartTableSwitchState>("chart");
  const [selectedCompany, setSelectedCompany] = useState<ComboboxItem>(
    companyOptions[0]
  );
  const [selectedDateRange, setSelectedDateRange] = useState<
    [DateTime<true>, DateTime<true> | null]
  >([DateTime.now().startOf("year"), DateTime.now().endOf("month")]);

  const selectedCompanyId = selectedCompany
    ? Number(selectedCompany.value)
    : undefined;

  const {
    energyRevenuesData,
    isLoading: isEnergyRevenuesDataLoading,
    error: energyRevenuesDataError
  } = usePersonEnergyRevenuesData(selectedCompanyId);

  const selectedEnergyRevenuesData = useMemo(() => {
    return energyRevenuesData?.filter((energyRevenues) => {
      const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
        energyRevenues.period_start
      );

      return (
        selectedDateRange[0] <= revenuesDate &&
        (selectedDateRange[1] === null || revenuesDate <= selectedDateRange[1])
      );
    });
  }, [energyRevenuesData, selectedDateRange]);

  const isCompanyEmpty = companies.length === 0;

  function handleDatePickerChange(dateRange: DatesRangeValue) {
    // only react if a valid & complete range is set
    if (dateRange[0] && (dateRange[1] || dateRange[1] === null)) {
      const firstDate = DateTime.fromJSDate(dateRange[0]);
      const lastDate = dateRange[1]
        ? DateTime.fromJSDate(dateRange[1]).endOf("month")
        : null;
      if (firstDate.isValid && (lastDate === null || lastDate.isValid)) {
        setSelectedDateRange([firstDate, lastDate]);
      }
    }
  }

  return (
    <div className="EnergyRevenuesPage">
      <Title mb="md" order={5}>
        Unternehmensübersicht
      </Title>
      <Group justify="space-between" mb="xl">
        <Group gap="xl">
          <Select
            allowDeselect={false}
            className="person-select"
            data={companyOptions}
            disabled={companies.length === 0}
            label="Unternehmen"
            placeholder="Unternehmen auswählen"
            searchable
            size="xs"
            value={selectedCompany?.value}
            onChange={(_, option) => setSelectedCompany(option)}
          />

          <MonthPickerInput
            allowSingleDateInRange
            disabled={energyRevenuesData?.length === 0}
            label="Berechneter Zeitraum"
            maxDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesData ?? [],
              "max"
            )?.toJSDate()}
            minDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesData ?? [],
              "min"
            )?.toJSDate()}
            size="xs"
            type="range"
            value={[
              selectedDateRange[0].toJSDate(),
              selectedDateRange[1]?.toJSDate() || null
            ]}
            onChange={handleDatePickerChange}
          />
        </Group>
        <ChartTableSwitch setValue={setView} value={view} />
      </Group>

      <LoadOrError
        error={energyRevenuesDataError}
        loading={isEnergyRevenuesDataLoading}
      >
        {selectedEnergyRevenuesData && !isCompanyEmpty ? (
          <Stack gap="lg">
            <EnergyDataQuality
              missingDataSources={flattenMissingDataSources(
                selectedEnergyRevenuesData
              )}
              qualityPercent={averageAvailablePercent(
                selectedEnergyRevenuesData
              )}
              selectedDateRange={selectedDateRange}
              totalVolume={calculateTotalVolume(selectedEnergyRevenuesData)}
            />

            <Space h="lg" />
            <CompanySection
              companyId={selectedCompanyId}
              energyRevenuesData={selectedEnergyRevenuesData}
              exportFileName={selectedCompany.label}
              view={view}
            />
            <Divider />
            <GeneratorSection
              companyId={Number(selectedCompanyId)}
              companyLabel={selectedCompany.label}
              handleDatePickerChange={handleDatePickerChange}
              selectedDateRange={selectedDateRange}
            />
          </Stack>
        ) : (
          <IconAlert color={AlertColor.Danger}>
            {t(
              isCompanyEmpty
                ? "errors.RevenueMonitoring.NoCompaniesError"
                : "errors.UnknownError"
            )}
          </IconAlert>
        )}
      </LoadOrError>
    </div>
  );
}

export { EnergyRevenuesPage, EnergyRevenuesPageProps };
