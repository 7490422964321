import { useCallback, useState } from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useMeteringOrMarketLocationMutation } from "../../hooks/useMeteringOrMarketLocationMutation";
import type { MeteringOrMarketLocation } from "../../utils/backend-types";
import { ObjectName } from "../../utils/enums";
import { buildFieldNameToLabelMap } from "../../utils/react-hook-form/buildFieldNameToLabelMap";
import type { TabData } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { HistoryTab } from "../HistoryTab/HistoryTab";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import type { WizardModeProps } from "../VariantObjectWizard/VariantObjectWizard";
import { WizardSuccessWidget } from "../VariantObjectWizard/WizardSuccessWidget/WizardSuccessWidget";
import { WizardTabBarComponent } from "../VariantObjectWizard/WizardTabBarComponent/WizardTabBarComponent";
import { MarketLocationForm } from "./MarketLocationForm/MarketLocationForm";
import { MARKET_LOCATION_FORM_FIELD_DATA } from "./MarketLocationForm/MarketLocationForm.constants";

const TABS: Array<TabData> = [
  {
    id: "general",
    label: "Allgemeine Daten"
  },
  {
    id: "historie",
    label: "Historie"
  }
];

type MarketLocationWizardProps = {
  buttonContainer?: HTMLElement;
  siteId: number;
  onClose: () => void;
} & WizardModeProps<MeteringOrMarketLocation>;

function MarketLocationWizard({
  buttonContainer,
  siteId,
  onClose,
  ...modeProps
}: MarketLocationWizardProps) {
  const resolved = useResolvedPath(":activeTab");
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeTab =
    modeProps.mode === "edit"
      ? (match?.params?.activeTab ?? TABS[0].id)
      : TABS[0].id;
  const showStaffView = useShouldShowStaffView();
  const { isSuccess: wasFormSubmittedSuccessfully, mutateAsync } =
    useMeteringOrMarketLocationMutation(
      ObjectName.MarketLocation,
      modeProps.mode,
      siteId
    );
  const [numErrors, setNumErrors] = useState(0);
  const [highlightedTabs, setHighlightedTabs] = useState<Array<string>>([]);

  const handleChangeNumErrors = useCallback((numErrors: number) => {
    setNumErrors(numErrors);
  }, []);

  const handleChangeNumHighlightedFields = useCallback(
    (numHighlightedFields: number) => {
      setHighlightedTabs(numHighlightedFields > 0 ? [TABS[0].id] : []);
    },
    []
  );

  const tabComponents = {
    [TABS[0].id]: (
      <WizardTabBarComponent numErrors={numErrors} title={TABS[0].label} />
    ),
    [TABS[1].id]: <WizardTabBarComponent numErrors={0} title={TABS[1].label} />
  };

  const fieldNameToLabelMap =
    buildFieldNameToLabelMap<MeteringOrMarketLocation>(
      MARKET_LOCATION_FORM_FIELD_DATA
    );

  return (
    <div className="MarketLocationWizard">
      {!wasFormSubmittedSuccessfully ? (
        <>
          {modeProps.mode === "edit" && (
            <TabBar
              activeTab={activeTab}
              firstTabIsIndex
              highlightedTabs={highlightedTabs}
              tabComponents={tabComponents}
              tabs={TABS}
            />
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS[0].id}>
              <MarketLocationForm
                buttonContainer={buttonContainer}
                showStaffView={showStaffView}
                onCancel={onClose}
                onChangeNumErrors={handleChangeNumErrors}
                onChangeNumHighlightedFields={handleChangeNumHighlightedFields}
                onSubmit={mutateAsync}
                {...modeProps}
              />
            </TabPane>
            {modeProps.mode === "edit" && (
              <TabPane tabId={TABS[1].id}>
                <HistoryTab
                  entityId={modeProps.wizardObject.id}
                  entityType={ObjectName.MarketLocation}
                  fieldNameToLabelMap={fieldNameToLabelMap}
                />
              </TabPane>
            )}
          </TabContent>
        </>
      ) : (
        <WizardSuccessWidget
          buttonContainer={buttonContainer}
          message={
            modeProps.mode === "create"
              ? "Die Marktlokation wurde erstellt."
              : "Die Änderungen wurden gespeichert."
          }
          onClose={onClose}
        />
      )}
    </div>
  );
}

export { MarketLocationWizard, MarketLocationWizardProps };
