import { NavLink, Text } from "@mantine/core";
import {
  IconChartDonut3,
  IconChartHistogram,
  IconEdit,
  IconLinkPlus,
  IconPencil,
  IconPhotoCircle,
  IconTrash
} from "@tabler/icons-react";
import { AxiosError } from "axios";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import type { GeneratorDataStatus } from "../../../../utils/backend-types";
import { UNNAMED_OBJECT_NAME } from "../../../../utils/constants";
import { ObjectName } from "../../../../utils/enums";
import { getErrorText } from "../../../../utils/get-error-text";
import { ComponentDeleteModal } from "../../../ComponentListContainer/ComponentList/ComponentDeleteModal/ComponentDeleteModal";
import { ComponentOpenEditModalLink } from "../../../ComponentListContainer/ComponentOpenEditModalLink/ComponentOpenEditModalLink";
import { TABS as ENERGY_DATA_TABS } from "../../../EnergyData/EnergyDataView/EnergyDataView";
import { METER_ID_PREFIX } from "../../../EnergyData/EnergyDataView/utils/getMeterIdFromSelectedId";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { useGetPossibleGeneratorChanges } from "../../hooks/useGetPossibleGeneratorChanges";
import { useOverwriteGeneratorMutation } from "../../hooks/useOverwriteGeneratorMutation";
import type { IconData } from "../../StructureViewFlowDiagram.types";
import { GeneratorOverwriteConfirmationModal } from "../GeneratorOverwriteConfirmationModal/GeneratorOverwriteConfirmationModal";
import { GeneratorStatusModal } from "../GeneratorStatusModal/GeneratorStatusModal";
import { IconSelectionModal } from "../IconSelectionModal/IconSelectionModal";
import { PopupBox } from "../PopupBox/PopupBox";
import "./NodePopup.scss";

export interface NodePopupData {
  name?: string;
  icon?: string;
  iconColor?: string;
  active?: boolean;
  type?: string;
  componentId?: number;
  componentType?: ObjectName;
  fontWeight?: number;
  fontSize?: number;
  text?: string;
  detail: NodePopupDataDetail | null;
}

interface NodePopupDataDetail {
  type: string;
  text: string;
  isConsumptionShareEditable?: boolean;
}

interface NodePopupProps {
  x: number;
  y: number;
  nodeId: number;
  icons: Array<IconData>;
  data: NodePopupData;
  projectId: string;
  siteId: number;
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
  onChangeIcon: (iconName: string) => void;
  onClickAddEdge: (nodeId: number) => void;
  onNodeDeleted: () => void;
  hideEdgeButton: boolean;
}

function NodePopup({
  x,
  y,
  nodeId,
  icons,
  data,
  projectId,
  siteId,
  hideEdgeButton,
  onChangeIcon,
  onClickAddEdge,
  onNodeDeleted,
  userHasNoAccess,
  setShowNotAccessibleModal
}: NodePopupProps) {
  const isStaff = useShouldShowStaffView();

  const [deleteNode, setDeleteNode] = useState(false);

  const [isIconSelectionModalOpen, setIsIconSelectionModalOpen] =
    useState(false);
  const [
    isGeneratorOverwriteConfirmationModalOpen,
    setIsGeneratorOverwriteConfirmationModalOpen
  ] = useState(false);
  const [isGeneratorStatusModalOpen, setIsGeneratorStatusModalOpen] =
    useState(false);

  const [generatorDataStatusText, setGeneratorDataStatusText] = useState<
    string | Array<string> | ReactNode | null
  >(null);
  const [generatorDataStatus, setGeneratorDataStatus] =
    useState<GeneratorDataStatus | null>(null);

  const {
    data: possibleChanges,
    isLoading: isGettingPossibleChanges,
    refetch: refetchPossibleChanges,
    error: getPossibleChangesError
  } = useGetPossibleGeneratorChanges(data.componentId ?? null);

  const { mutate: overwriteGeneratorData, isPending: isOverwritingData } =
    useOverwriteGeneratorMutation({
      generatorId: data.componentId,
      onSuccess: () => {
        setIsGeneratorOverwriteConfirmationModalOpen(false);
        setGeneratorDataStatus("overwriteSuccess");
        setIsGeneratorStatusModalOpen(true);

        setGeneratorDataStatusText(
          <>
            <p>
              Überschreiben der Erzeugerdaten in opti.node durch die Daten des
              Erzeugers im Marktstammdatenregister beendet.
            </p>
            <p>Die folgenden Erzeuger wurden erfolgreich importiert:</p>
            <ul>
              <li>{data.name}</li>
            </ul>
          </>
        );
      },
      onError: (error) => {
        setIsGeneratorOverwriteConfirmationModalOpen(false);
        setGeneratorDataStatus("overwriteFail");
        setIsGeneratorStatusModalOpen(true);
        setGeneratorDataStatusText(getErrorText(error));
      }
    });

  useEffect(() => {
    if (getPossibleChangesError) {
      setIsGeneratorOverwriteConfirmationModalOpen(false);
      setGeneratorDataStatus("getPossibleChangesFail");
      setIsGeneratorStatusModalOpen(true);
      setGeneratorDataStatusText(
        getPossibleChangesError instanceof AxiosError
          ? getErrorText(getPossibleChangesError)
          : (getPossibleChangesError as unknown as string)
      );
    }
  }, [getPossibleChangesError]);

  function handleDelete() {
    setDeleteNode(true);
  }

  function handleCloseComponentDelete() {
    setDeleteNode(false);
    onNodeDeleted();
  }

  const componentToDelete = data.componentId
    ? {
        id: data.componentId,
        name: data.name ?? "",
        site: siteId
      }
    : null;

  function handleShowGeneratorOverwriteConfirmationModal() {
    setIsGeneratorOverwriteConfirmationModalOpen(true);
  }

  function handleCloseGeneratorOverwriteConfirmationModal() {
    setIsGeneratorOverwriteConfirmationModalOpen(false);
  }

  function handleCloseGeneratorStatusModal() {
    setIsGeneratorStatusModalOpen(false);
  }

  function handleOverwriteGeneratorData() {
    if (possibleChanges) {
      overwriteGeneratorData(possibleChanges);
    }
  }

  return (
    <PopupBox className="NodePopup" position={{ x, y }}>
      <LinksAndDetails
        data={data}
        hideEdgeButton={hideEdgeButton}
        nodeId={nodeId}
        projectId={projectId}
        setShowNotAccessibleModal={setShowNotAccessibleModal}
        showGeneratorOverwriteConfirmationModal={
          handleShowGeneratorOverwriteConfirmationModal
        }
        userHasNoAccess={userHasNoAccess}
        onClickAddEdge={onClickAddEdge}
        onClickChangeIcon={() => setIsIconSelectionModalOpen(true)}
        onClickDelete={handleDelete}
      />
      {deleteNode && componentToDelete && data.componentType && (
        <ComponentDeleteModal
          component={componentToDelete}
          modalBodyElement={
            isStaff && data.componentType === ObjectName.Meter ? (
              <div>
                <p>
                  Sind Sie sicher, dass Sie <b>{data.name}</b> löschen möchten?
                </p>
                <p>
                  Alle auf dem Zähler gespeicherten Energiedaten werden
                  dauerhaft gelöscht. Dies kann auch Energiedaten einer vormals
                  verknüpften Lokation umfassen.
                </p>
                <p>Dieser Schritt kann nicht rückgängig gemacht werden.</p>
              </div>
            ) : undefined
          }
          objectName={data.componentType}
          onDeleteSuccess={handleCloseComponentDelete}
          onToggle={handleCloseComponentDelete}
        />
      )}
      {isIconSelectionModalOpen && (
        <IconSelectionModal
          iconColor={data.iconColor}
          icons={icons}
          initialIconUrl={data.icon}
          isOpen={isIconSelectionModalOpen}
          onClose={() => setIsIconSelectionModalOpen(false)}
          onIconChange={onChangeIcon}
        />
      )}
      {isGeneratorOverwriteConfirmationModalOpen && (
        <GeneratorOverwriteConfirmationModal
          isGeneratorOverwriteConfirmationModalOpen={
            isGeneratorOverwriteConfirmationModalOpen
          }
          isGettingPossibleChanges={isGettingPossibleChanges}
          isOverwritingData={isOverwritingData}
          possibleChanges={possibleChanges}
          refetchPossibleChanges={refetchPossibleChanges}
          onCloseGeneratorOverwriteConfirmationModal={
            handleCloseGeneratorOverwriteConfirmationModal
          }
          onOverwriteGeneratorData={handleOverwriteGeneratorData}
        />
      )}
      {isGeneratorStatusModalOpen && (
        <GeneratorStatusModal
          description={generatorDataStatusText}
          isGeneratorStatusModalOpen={isGeneratorStatusModalOpen}
          status={generatorDataStatus}
          onCloseGeneratorStatusModal={handleCloseGeneratorStatusModal}
        />
      )}
    </PopupBox>
  );
}

interface LinksAndDetailsProps {
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
  data: NodePopupData;
  nodeId: number;
  projectId: string;
  onClickChangeIcon: () => void;
  onClickDelete: () => void;
  onClickAddEdge: (nodeId: number) => void;
  showGeneratorOverwriteConfirmationModal: () => void;
  hideEdgeButton: boolean;
}

function LinksAndDetails({
  data,
  nodeId,
  projectId,
  onClickChangeIcon,
  onClickDelete,
  onClickAddEdge,
  userHasNoAccess,
  setShowNotAccessibleModal,
  showGeneratorOverwriteConfirmationModal,
  hideEdgeButton
}: LinksAndDetailsProps) {
  const isStaff = useShouldShowStaffView();

  const showChangeIconLink = !!data.detail;
  const name = data.name || UNNAMED_OBJECT_NAME;

  function handleClickAddEdge() {
    if (userHasNoAccess) {
      setShowNotAccessibleModal?.(true);
    } else {
      onClickAddEdge(nodeId);
    }
  }

  function handleClickDelete() {
    if (userHasNoAccess) {
      setShowNotAccessibleModal?.(true);
    } else {
      onClickDelete();
    }
  }

  return (
    <>
      <Text fw={700} mb={5} size="xs">
        {name}
      </Text>
      <Text mb={5} size="xs">
        {data.componentType}
      </Text>
      {data.detail && (
        <Text mb={5} size="xs">
          {data.detail.type}: {data.detail.text}
        </Text>
      )}
      {data.active === false && (
        <Text fs="italic" mb={5} size="xs">
          Dieser Erzeuger ist aktuell nicht in Betrieb.
        </Text>
      )}

      {data.componentType && (
        <>
          {data.componentId && (
            <ComponentOpenEditModalLink
              componentId={data.componentId}
              linkContent="Bearbeiten"
              navLinkIcon={<IconPencil size={16} />}
              objectName={data.componentType}
              useNavLink
            />
          )}
          {data.componentType === ObjectName.Meter && (
            <>
              <NavLink
                component={Link}
                label="Zähler- und Lokationsdaten"
                leftSection={<IconChartHistogram size="16" />}
                to={{
                  pathname: generatePath(
                    `${ROUTES.energyData}/${ENERGY_DATA_TABS.TAB_RAW_ENERGY_DATA}`,
                    { projectId }
                  ),
                  search: `?selectedId=${METER_ID_PREFIX}${data.componentId}`
                }}
              />
              {data.detail?.isConsumptionShareEditable && (
                <NavLink
                  component={Link}
                  label="Verbrauchsanteil bearbeiten"
                  leftSection={<IconChartDonut3 size="16" />}
                  to={{
                    pathname: generatePath(
                      `./verbrauchsanteile/${data.componentId}`
                    )
                  }}
                />
              )}
            </>
          )}
          {isStaff && data.componentType === ObjectName.Generator && (
            <NavLink
              component="button"
              label="Überschreiben aus Marktstammdatenregister"
              leftSection={<IconEdit size="16" />}
              onClick={showGeneratorOverwriteConfirmationModal}
            />
          )}
          {showChangeIconLink && (
            <NavLink
              component="button"
              label="Icon ändern"
              leftSection={<IconPhotoCircle size="16" />}
              onClick={onClickChangeIcon}
            />
          )}
        </>
      )}
      {!hideEdgeButton && (
        <NavLink
          component="button"
          disabled={userHasNoAccess}
          label="Verbindung hinzufügen"
          leftSection={<IconLinkPlus size="16" />}
          onClick={handleClickAddEdge}
        />
      )}
      <NavLink
        component="button"
        disabled={userHasNoAccess}
        label="Löschen"
        leftSection={<IconTrash color="red" size="16" />}
        onClick={handleClickDelete}
      />
    </>
  );
}

export { NodePopup, NodePopupProps };
