import { useState } from "react";

import { useCurrentUserQuery } from "../../../../hooks/useCurrentUserQuery";
import { showToast } from "../../../../utils/toast";
import { DeleteConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import "./Notes.scss";
import { useNotes } from "../../hooks/useNotes";
import { NotesEntry } from "./NotesEntry";
import { NotesList } from "./NotesList";

interface NotesProps {
  todoId: number;
}

function Notes({ todoId }: NotesProps) {
  const {
    notes,
    addNote,
    updateNote,
    deleteNote,
    isLoading: isNotesLoading
  } = useNotes({ todoId });

  const {
    currentUser,
    isLoading: isUserLoading,
    error
  } = useCurrentUserQuery();
  const isStaffUser = useShouldShowStaffView();

  const [noteToDelete, setNoteToDelete] = useState<number | null>(null);
  const [deletingLoading, setDeleteLoading] = useState(false);

  function handleDeleteNote() {
    setDeleteLoading(true);

    if (!noteToDelete) {
      return;
    }

    deleteNote(noteToDelete)
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setNoteToDelete(null);
        setDeleteLoading(false);
      });
  }

  const deleteNoteModalIsOpen = !!noteToDelete;

  return (
    <LoadOrError error={error} loading={isNotesLoading || isUserLoading}>
      {currentUser && notes && (
        <div className="Notes">
          <NotesEntry
            isStaffUser={isStaffUser}
            submitButtonText="Erstellen"
            onSaveNote={addNote}
          />
          <NotesList
            isStaffUser={isStaffUser}
            notes={notes}
            ownUserId={currentUser.id}
            onDeleteNote={setNoteToDelete}
            onUpdateNote={updateNote}
          />
          <DeleteConfirmationModal
            headerName="Notiz"
            isLoading={deletingLoading}
            isModalOpen={deleteNoteModalIsOpen}
            name="diese Notiz"
            toggleModal={() => setNoteToDelete(null)}
            onClickConfirm={handleDeleteNote}
          />
        </div>
      )}
    </LoadOrError>
  );
}

export { Notes };
