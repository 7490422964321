import { type RegulatoryDutiesList } from "../../utils/backend-types";
import loader from "../Loader/Loader";
import RegulatoryDutyList from "./RegulatoryDutyList/RegulatoryDutyList";
import "./RegulatoryDuties.scss";

interface RegulatoryDutiesProps {
  data: RegulatoryDutiesList;
  columnHeaders: Record<number, string>;
  selectedVariantIds: Array<number>;
}

function RegulatoryDuties({
  data,
  columnHeaders,
  selectedVariantIds
}: RegulatoryDutiesProps) {
  const duties = extendDutiesByApplicableStatus(data);

  return (
    <div className="RegulatoryDuties">
      <RegulatoryDutyList
        columnHeaders={columnHeaders}
        duties={duties}
        selectedVariantIds={selectedVariantIds}
      />
    </div>
  );
}

export function extendDutiesByApplicableStatus(data: RegulatoryDutiesList) {
  return Object.keys(data).reduce((obj, variantId) => {
    const dutyResponseObject = data[variantId];
    obj[variantId] = dutyResponseObject.allDuties.map((duty) =>
      Object.assign(duty, {
        applicable: dutyResponseObject.applicableDutyIds.includes(duty.id)
      })
    );
    return obj;
  }, {});
}

export default loader(RegulatoryDuties);
