import { Accordion } from "@mantine/core";
import { useMemo, useState } from "react";
import { Row } from "reactstrap";
import { useProject } from "../../../hooks/useProject";
import urls, { OptiSupportEndpoints } from "../../../urls";
import type { SiteWizard } from "../../../utils/backend-types";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { SiteTasksTitle } from "../SiteTasksTitle/SiteTasksTitle";
import { OnboardingAssignment } from "./OnboardingAssignment/OnboardingAssignment";
import { OnboardingDocuments } from "./OnboardingDocuments/OnboardingDocuments";
import "./onboardingWizard.scss";

const ONBOARDING_WIZARD_HELP_TEXT = `Der Onboarding-Assistent unterstützt Sie beim Bereitstellen von Informationen. Auf einen Blick sehen Sie Ihren aktuellen Fortschritt innerhalb des Onboardings und welche Angaben wir noch von Ihnen benötigen. Weitere Informationen finden Sie [hier](${urls.optiSupport(
  OptiSupportEndpoints.OnboardingProzessPVDirekt
)}).`;

export enum AssignmentType {
  Configuration = "Konfiguration",
  MeteringConcept = "Messkonzept & Zählerkonfiguration",
  Regulatory = "Regulatorische Daten",
  Vollmacht = "Messdatenversand einrichten",
  Invoices = "Verträge & Lieferungen",
  OptiNode = "Prüfung durch Mitarbeiter:innen"
}

export interface OnboardingWizardProps {
  siteWizard: SiteWizard;
  onSiteWizardUpdated: () => Promise<void>;
  projectId: string;
  siteIsFullFeedin?: boolean;
}

function OnboardingWizard({
  siteWizard,
  onSiteWizardUpdated,
  projectId,
  siteIsFullFeedin
}: OnboardingWizardProps) {
  const [documentModalOpen, setDocumentModalOpen] = useState(false);

  const nextPhase: AssignmentType | null = useMemo(() => {
    if (!siteWizard.phases.configuration.done) {
      return AssignmentType.Configuration;
    }

    if (
      !siteWizard.phases.meteringConcept.done &&
      siteWizard.phases.meteringConcept.displayPhase
    ) {
      return AssignmentType.MeteringConcept;
    }

    if (!siteWizard.phases.regulatory.done) {
      return AssignmentType.Regulatory;
    }

    if (
      !siteWizard.phases.invoices.done &&
      siteWizard.phases.invoices.displayPhase
    ) {
      return AssignmentType.Invoices;
    }

    if (!siteWizard.phases.msbauthority.done) {
      return AssignmentType.Vollmacht;
    }

    if (!siteWizard.onboardingDone.onboardingDone) {
      return AssignmentType.OptiNode;
    }
    return null;
  }, [siteWizard]);
  const { project } = useProject(projectId);

  return (
    <>
      {documentModalOpen && project?.documentsInOnboardingAssistantActive && (
        <OnboardingDocuments
          siteId={siteWizard.id}
          onClose={() => setDocumentModalOpen(false)}
        />
      )}
      <Accordion.Item
        className="onboarding-wizard-container"
        value="onboarding-wizard"
      >
        <Accordion.Control>
          <SiteTasksTitle
            helpText={ONBOARDING_WIZARD_HELP_TEXT}
            title="Onboarding"
          />
        </Accordion.Control>
        <Accordion.Panel>
          {project?.documentsInOnboardingAssistantActive && (
            <Row className="onboarding-wizard-document-row">
              <div
                className="onboarding-wizard-document-container"
                onClick={() => setDocumentModalOpen(true)}
              >
                <Icon
                  className="onboarding-wizard-document-icon"
                  name={IconName.FilePdf}
                />
                Dokumente
              </div>
            </Row>
          )}
          <Row style={{ margin: 0 }}>
            <OnboardingAssignment
              isDone={siteWizard.phases.configuration.done}
              isNext={nextPhase === AssignmentType.Configuration}
              projectId={projectId}
              siteWizard={siteWizard}
              type={AssignmentType.Configuration}
              onSiteWizardUpdated={onSiteWizardUpdated}
            />
            {siteWizard.phases.meteringConcept.displayPhase && (
              <OnboardingAssignment
                isDone={siteWizard.phases.meteringConcept.done}
                isNext={nextPhase === AssignmentType.MeteringConcept}
                projectId={projectId}
                siteWizard={siteWizard}
                type={AssignmentType.MeteringConcept}
                onSiteWizardUpdated={onSiteWizardUpdated}
              />
            )}
            <OnboardingAssignment
              isDone={siteWizard.phases.regulatory.done}
              isNext={nextPhase === AssignmentType.Regulatory}
              siteWizard={siteWizard}
              type={AssignmentType.Regulatory}
              onSiteWizardUpdated={onSiteWizardUpdated}
            />

            {siteWizard.phases.invoices.displayPhase && (
              <OnboardingAssignment
                isDone={siteWizard.phases.invoices.done}
                isNext={nextPhase === AssignmentType.Invoices}
                projectId={projectId}
                siteWizard={siteWizard}
                type={AssignmentType.Invoices}
                onSiteWizardUpdated={onSiteWizardUpdated}
              />
            )}

            <OnboardingAssignment
              isDone={siteWizard.phases.msbauthority.done}
              isNext={nextPhase === AssignmentType.Vollmacht}
              siteWizard={siteWizard}
              type={AssignmentType.Vollmacht}
              onSiteWizardUpdated={onSiteWizardUpdated}
            />

            {!siteIsFullFeedin && (
              <OnboardingAssignment
                isDone={siteWizard.onboardingDone.onboardingDone}
                isNext={nextPhase === AssignmentType.OptiNode}
                siteWizard={siteWizard}
                type={AssignmentType.OptiNode}
                onSiteWizardUpdated={onSiteWizardUpdated}
              />
            )}
          </Row>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}

export { OnboardingWizard };
