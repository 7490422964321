import { Controller } from "react-hook-form";
import type { Column } from "react-table";
import type { InlineEditTableColumnData } from "../../../../../../types/inlineEditTable.types";
import { DateInputField } from "../../../../../BuildingBlocks/Forms/FormFields/DateInputField/DateInputField";
import type { TypedCellInfo } from "../../../../../CustomReactTable/CustomReactTable.types";
import { getErrorTextFromCellInfo } from "../../../utils/getErrorTextFromCellInfo";
import type { FictionalEnergyValueResponse } from "../../FictionalEnergyValueManager/FictionalEnergyValueManager";

export function getLastDateColumn({
  rowStates,
  control,
  errors,
  t
}: InlineEditTableColumnData<FictionalEnergyValueResponse>): Column<FictionalEnergyValueResponse> {
  return {
    Header: "Enddatum",
    accessor: (row) => row.lastDate,
    id: "lastDate",
    sortable: false,
    width: 120,
    Cell: (cellInfo: TypedCellInfo<string, FictionalEnergyValueResponse>) => {
      const disableFormField = rowStates[cellInfo.original.id] === "loading";
      const showFormField =
        rowStates[cellInfo.original.id] === "edit" ||
        rowStates[cellInfo.original.id] === "loading";
      const errorText = getErrorTextFromCellInfo(
        errors,
        cellInfo,
        "lastDate",
        t
      );

      if (showFormField) {
        return (
          <Controller
            control={control}
            name={`${cellInfo.index}.lastDate`}
            render={({ field }) => (
              <DateInputField
                aria-label="Enddatum"
                disabled={disableFormField}
                errorText={errorText}
                invalid={!!errorText}
                ref={field.ref}
                useCompactErrorMessage
                value={field.value}
                onChange={field.onChange}
              />
            )}
            rules={{
              required: true
            }}
          />
        );
      }

      return cellInfo.value;
    }
  };
}
