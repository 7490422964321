import classNames from "classnames";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import "./MeteringArrows.scss";

export enum ArrowPosition {
  Top = "top",
  Side = "side"
}
export enum ArrowTypes {
  DoubleArrow = "double-arrow",
  BlueArrow = "blue-arrow",
  GreenArrow = "green-arrow"
}
export interface MeteringArrowsProps {
  type: ArrowTypes;
  position: ArrowPosition;
  meteringPriorityDescription?: string;
}

function MeteringArrows({
  type,
  position,
  meteringPriorityDescription
}: MeteringArrowsProps) {
  function getArrows() {
    let arrows: React.ReactNode;
    let positionStyle: React.CSSProperties;
    switch (type) {
      case ArrowTypes.DoubleArrow:
        positionStyle =
          position === ArrowPosition.Top
            ? { top: "-40px", left: "5px" }
            : { top: "30px", left: "35px" };
        arrows = (
          <>
            <span
              className={classNames(
                "metering-arrows-flow-arrow-label blue double",
                {
                  top: position === ArrowPosition.Top,
                  side: position === ArrowPosition.Side
                }
              )}
            >
              Z<sub>{meteringPriorityDescription ?? "/"}B</sub>
            </span>
            <span
              className={classNames(
                "metering-arrows-flow-arrow-label green double",
                {
                  top: position === ArrowPosition.Top,
                  side: position === ArrowPosition.Side
                }
              )}
            >
              Z<sub>{meteringPriorityDescription ?? "/"}L</sub>
            </span>

            <Icon
              className={classNames(
                "metering-arrows-flow-arrow-icon blue double-arrow",
                {
                  right: position === ArrowPosition.Top,
                  down: position === ArrowPosition.Side
                }
              )}
              name={IconName.FlowArrow}
            />
            <Icon
              className={classNames(
                "metering-arrows-flow-arrow-icon green double-arrow",
                {
                  left: position === ArrowPosition.Top,
                  up: position === ArrowPosition.Side
                }
              )}
              name={IconName.FlowArrow}
            />
          </>
        );
        break;
      case ArrowTypes.BlueArrow:
        positionStyle =
          position === ArrowPosition.Top
            ? { top: "-20px", left: "-34px" }
            : { top: "30px", left: "15px" };
        arrows = (
          <>
            <span
              className={classNames("metering-arrows-flow-arrow-label blue", {
                top: position === ArrowPosition.Top,
                side: position === ArrowPosition.Side
              })}
            >
              Z<sub>{meteringPriorityDescription ?? "/"}B</sub>
            </span>
            <Icon
              className={classNames("metering-arrows-flow-arrow-icon blue", {
                right: position === ArrowPosition.Top,
                down: position === ArrowPosition.Side
              })}
              name={IconName.FlowArrow}
            />
          </>
        );
        break;
      case ArrowTypes.GreenArrow:
        positionStyle =
          position === ArrowPosition.Top
            ? { top: "-20px", left: "-38px" }
            : { top: "30px", left: "-90px" };
        arrows = (
          <>
            <span
              className={classNames("metering-arrows-flow-arrow-label green", {
                top: position === ArrowPosition.Top,
                side: position === ArrowPosition.Side
              })}
            >
              Z<sub>{meteringPriorityDescription ?? "/"}L</sub>
            </span>
            <Icon
              className={classNames("metering-arrows-flow-arrow-icon green", {
                left: position === ArrowPosition.Top,
                up: position === ArrowPosition.Side
              })}
              name={IconName.FlowArrow}
            />
          </>
        );
        break;
      default:
        return null;
    }
    return (
      <div
        className={classNames("metering-arrow-container", {
          top: position === ArrowPosition.Top,
          side: position === ArrowPosition.Side
        })}
        style={positionStyle}
      >
        {arrows}
      </div>
    );
  }

  return <div className="MeteringArrows">{getArrows()}</div>;
}

export { MeteringArrows };
