import type { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getFieldSpecificError } from "../../utils/api-utils";
import { getErrorText } from "../../utils/get-error-text";
import { showToast } from "../../utils/toast";
import { FormField } from "../BuildingBlocks/Forms/FormField/FormField";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";
import type { EnergyDataImportRequest } from "../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";
import { requestSupportForDataUploadRequest } from "../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";

interface SupportRequestProps {
  dataImportRequest: EnergyDataImportRequest;
  onClose: () => void;
  onSupportRequestSent: (
    updatedDataImportRequest: EnergyDataImportRequest
  ) => void;
}

function SupportRequestModal({
  dataImportRequest,
  onClose,
  onSupportRequestSent
}: SupportRequestProps) {
  const { t } = useTranslation();
  const [commentValue, setCommentValue] = useState("");
  const [inputErrorText, setInputErrorText] = useState<string | undefined>();

  function handleRequestSupport(): void {
    if (!isValidInput(commentValue)) {
      showInputIfInvalidCommentValue();
      return;
    }

    requestSupportForDataUploadRequest(dataImportRequest.id, commentValue)
      .then((response) => {
        onSupportRequestSent(response.data);
      })
      .catch((e: AxiosError) => {
        const errorMessage = getFieldSpecificError(e);

        showToast(
          "error",
          getErrorText(
            e,
            errorMessage && e.response?.status
              ? { [e.response.status]: errorMessage }
              : {}
          )
        );
      });
  }

  function handleChangeCommentInput(value: string) {
    setInputErrorText(undefined);
    setCommentValue(value);
  }

  function isValidInput(value: string) {
    return value.trim().length <= 512;
  }

  function showInputIfInvalidCommentValue() {
    setInputErrorText(t("errors.TooLongText", { maxLength: 512 }));
  }

  return (
    <Modal className="SupportRequestModalModal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>Supportanfrage</ModalHeader>
      <ModalBody scrollable>
        <div className="intro">
          <p>
            Bitte schreiben Sie uns eventuelle Besonderheiten der Datei oder des
            aufgetretenen Fehlers und bestätigen die Anfrage.
          </p>
        </div>
        <form>
          <FormField
            autosize
            error={inputErrorText}
            label="Kommentar"
            minRows={9}
            name="comment"
            resize="vertical"
            type="textarea"
            value={commentValue}
            onChange={(e) => handleChangeCommentInput(e.target.value)}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="secondary" onClick={onClose}>
            Abbrechen
          </Button>{" "}
          <Button color="primary" onClick={handleRequestSupport}>
            Einsenden
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { SupportRequestModal };
