import { useTranslation } from "react-i18next";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";
import { anchorTooltip } from "../../../../Tooltip/Tooltip";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { EnergyDataFileImportRequestStatus } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import "./StatusCell.scss";

interface Props {
  file: EnergyDataImportRequest;
}

function StatusCell({ file }: Props) {
  const { t } = useTranslation();

  switch (file.status) {
    case EnergyDataFileImportRequestStatus.InProgress:
      return <span>In Bearbeitung</span>;
    case EnergyDataFileImportRequestStatus.Stopped:
    case EnergyDataFileImportRequestStatus.Error: {
      return (
        <span className="status-cell">
          {/** TODO: helpText must be provided via different field. It should be clarified in DIM-262 */}
          <IconHelpText
            helpText={file.errorMessage || t("errors.ServerError")}
          />
          <span
            className="status-text"
            {...anchorTooltip({
              content: file.errorMessage || t("errors.ServerError"),
              delayShow: 500
            })}
          >
            {file.errorMessage || t("errors.ServerError")}
          </span>
        </span>
      );
    }
    case EnergyDataFileImportRequestStatus.Success:
      return <span>Erfolgreich</span>;
    default:
      return <span>Unbekannt</span>;
  }
}

export { StatusCell };
