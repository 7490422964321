import * as Sentry from "@sentry/browser";
import { useState } from "react";
import { Collapse } from "reactstrap";
import { type ExtendedUser, type Todo } from "../../../utils/backend-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { ClarifyTodoModal } from "../ClarifyTodoModal/ClarifyTodoModal";
import {
  TODO_SYSTEM_DECISION_BY_USER_DECISION,
  TodoSystemDecision,
  TodoUserDecision
} from "../common";
import { Description } from "../TodoDescription/TodoDescription";
import { getTodoStatusChoices } from "../utils/getTodoStatusChoices";
import "./TodoDecisionWidget.scss";

const EMPTY_FIELD = "--";
const SYSTEM_DECISION_ORDER = [
  TodoSystemDecision.Applicable,
  TodoSystemDecision.CantTell,
  TodoSystemDecision.NotApplicable
];
const DECISION_TEXTS = {
  [TodoSystemDecision.Applicable]: {
    displayName: "Als offen übernehmen",
    explanation:
      'Diese Aufgaben wurden auf Basis Ihrer Projekt-Konfiguration als für Sie einschlägig erkannt. Durch Auswahl von "Als offen übernehmen" wird die Aufgabe in Ihre Aufgabenliste übernommen. Wenn Sie "Als erledigt übernehmen" auswählen, wird die Aufgabe übernommen und direkt als erledigt markiert.'
  },
  APPLICABLE_FINISHED: {
    displayName: "Als erledigt übernehmen",
    explanation: ""
  },
  [TodoSystemDecision.CantTell]: {
    displayName: "Klärung ausstehend",
    explanation:
      "Auf Basis Ihrer Angaben konnte noch nicht abschließend bewertet werden, ob diese Aufgaben für Sie einschlägig sind. Durch Klick auf den Titel einer Aufgabe erhalten Sie eine Beschreibung mit weiterführenden Informationen. Sollten Sie unsicher sein, ob eine Aufgabe erfüllt werden muss , steht Ihnen Ihr Ansprechpartner jederzeit gerne zur Verfügung."
  },
  [TodoSystemDecision.NotApplicable]: {
    displayName: "Nicht übernehmen",
    explanation:
      "Diese Aufgaben wurden vom System als für Sie nicht relevant erkannt bzw. entsprechend von Ihnen gekennzeichnet. Sofern Sie keine Änderungen vornehmen, werden die Aufgaben nicht in Ihre Aufgabenliste übernommen."
  }
};

const DECISIONS = [
  {
    decision: TodoUserDecision.Confirmed,
    finished: false
  },
  {
    decision: TodoUserDecision.Confirmed,
    finished: true
  },
  {
    decision: TodoUserDecision.Unclear,
    finished: false
  },
  {
    decision: TodoUserDecision.Rejected,
    finished: false
  }
];

interface TodoDecisionWidgetProps {
  todos: Array<Todo>;
  userDecisions: Record<number, TodoUserDecision>;
  finishedTodoIds: Array<number>;
  onChangeUserDecisionAndFinished: (
    todoId: number,
    userDecision: TodoUserDecision,
    finished: boolean
  ) => void;
  users: Array<ExtendedUser>;
}

function TodoDecisionWidget({
  todos,
  userDecisions,
  finishedTodoIds,
  onChangeUserDecisionAndFinished,
  users
}: TodoDecisionWidgetProps) {
  const [todoToClarify, setTodoToClarify] = useState<Todo | null>(null);
  const [clarifiedTodoIds, setClarifiedTodoIds] = useState<Array<number>>([]);

  function groupTodosByUserDecisionOrSystemDecision(todos: Array<Todo>) {
    return todos.reduce((groupedTodos, todo) => {
      let systemDecisionKey;

      const systemDecisionForUserDecision =
        TODO_SYSTEM_DECISION_BY_USER_DECISION[todo.userDecision];
      const systemDecisionKeyForUserDecision = Object.values(
        TodoSystemDecision
      ).find(
        (todoSystemDecision) =>
          todoSystemDecision === systemDecisionForUserDecision
      );

      if (systemDecisionForUserDecision) {
        systemDecisionKey = systemDecisionKeyForUserDecision;
      } else if (
        todo.systemDecision === TodoSystemDecision.NeedMoreInformation
      ) {
        // this todo is open and can be clarified - we want to group it with the other "zu klären" todos
        systemDecisionKey = Object.values(TodoSystemDecision).find(
          (todoSystemDecision) =>
            todoSystemDecision === TodoSystemDecision.CantTell
        );
      } else {
        systemDecisionKey = Object.values(TodoSystemDecision).find(
          (todoSystemDecision) => todoSystemDecision === todo.systemDecision
        );
      }

      if (groupedTodos[systemDecisionKey]) {
        groupedTodos[systemDecisionKey].push(todo);
      } else {
        groupedTodos[systemDecisionKey] = [todo];
      }

      return groupedTodos;
    }, {});
  }

  function handleClickClarify(todo: Todo) {
    setTodoToClarify(todo);
  }

  function handleTodoClarified(
    clarified: boolean,
    userDecision: TodoUserDecision,
    finished: boolean
  ) {
    if (todoToClarify) {
      onChangeUserDecisionAndFinished(todoToClarify.id, userDecision, finished);

      if (clarified) {
        setClarifiedTodoIds([...clarifiedTodoIds, todoToClarify.id]);
      }
    }
  }

  const orderedTodos = groupTodosByUserDecisionOrSystemDecision(todos);

  const orderedTodoKeys = SYSTEM_DECISION_ORDER.filter((decision) =>
    Object.keys(orderedTodos).includes(decision)
  );

  return (
    <div className="TodoConfirmationWidget">
      <p className="explanation-text">
        Folgende Aufgaben können für Ihr Projekt einschlägig sein. Sie können
        diese in Ihre Aufgabenliste übernehmen.
      </p>
      {orderedTodoKeys.map((orderedTodoKey) => {
        return (
          <TodosBySystemDecision
            clarifiedTodoIds={clarifiedTodoIds}
            explanation={DECISION_TEXTS[orderedTodoKey].explanation}
            finishedTodoIds={finishedTodoIds}
            key={orderedTodoKey}
            title={DECISION_TEXTS[orderedTodoKey].displayName}
            todos={orderedTodos[orderedTodoKey]}
            userDecisions={userDecisions}
            users={users}
            onChangeUserDecisionAndFinished={onChangeUserDecisionAndFinished}
            onClickClarify={handleClickClarify}
          />
        );
      })}
      {!!todoToClarify && (
        <ClarifyTodoModal
          currentUserDecision={userDecisions[todoToClarify.id]}
          isOpen={!!todoToClarify}
          todo={todoToClarify}
          todoIsFinished={finishedTodoIds.includes(todoToClarify.id)}
          onTodoClarified={handleTodoClarified}
          onToggle={() => setTodoToClarify(null)}
        />
      )}
    </div>
  );
}

interface TodosBySystemDecisionProps {
  title: string;
  explanation: string;
  todos: Array<Todo>;
  userDecisions: Record<number, TodoUserDecision>;
  finishedTodoIds: Array<number>;
  clarifiedTodoIds: Array<number>;
  users: Array<ExtendedUser>;
  onChangeUserDecisionAndFinished: (
    todoId: number,
    userDecision: TodoUserDecision,
    finished: boolean
  ) => void;
  onClickClarify: (todo: Todo) => void;
}

function TodosBySystemDecision({
  title,
  explanation,
  todos,
  userDecisions,
  finishedTodoIds,
  clarifiedTodoIds,
  users,
  onChangeUserDecisionAndFinished,
  onClickClarify
}: TodosBySystemDecisionProps) {
  return (
    <div className="todo-section">
      <h5 className="todo-header">{title}</h5>
      <p className="explantion">{explanation}</p>
      <TodoList
        clarifiedTodoIds={clarifiedTodoIds}
        finishedTodoIds={finishedTodoIds}
        todos={todos}
        userDecisions={userDecisions}
        users={users}
        onChangeUserDecisionAndFinished={onChangeUserDecisionAndFinished}
        onClickClarify={onClickClarify}
      />
    </div>
  );
}

interface TodoListProps {
  todos: Array<Todo>;
  userDecisions: Record<number, TodoUserDecision>;
  finishedTodoIds: Array<number>;
  clarifiedTodoIds: Array<number>;
  users: Array<ExtendedUser>;
  onChangeUserDecisionAndFinished: (
    todoId: number,
    userDecision: TodoUserDecision,
    finished: boolean
  ) => void;
  onClickClarify: (todo: Todo) => void;
}

function TodoList({
  todos,
  userDecisions,
  finishedTodoIds,
  clarifiedTodoIds,
  users,
  onChangeUserDecisionAndFinished,
  onClickClarify
}: TodoListProps) {
  const sortedTodos = todos.sort((a, b) => {
    if (a.overdue) {
      return -1;
    } else if (b.overdue) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div className="todo-list">
      {sortedTodos.map((todo) => (
        <Todo
          clarified={clarifiedTodoIds.some(
            (clarifiedTodoId) => clarifiedTodoId === todo.id
          )}
          finished={finishedTodoIds.some(
            (finishedTodoId) => finishedTodoId === todo.id
          )}
          key={todo.id}
          todo={todo}
          userDecision={userDecisions[todo.id]}
          users={users}
          onChangeUserDecision={(userDecision, finished) =>
            onChangeUserDecisionAndFinished(todo.id, userDecision, finished)
          }
          onClickClarify={onClickClarify}
        />
      ))}
    </div>
  );
}

interface TodoProps {
  todo: Todo;
  userDecision: TodoUserDecision;
  finished: boolean;
  clarified: boolean;
  users: Array<ExtendedUser>;
  onChangeUserDecision: (
    userDecision: TodoUserDecision,
    finished: boolean
  ) => void;
  onClickClarify: (todo: Todo) => void;
}

function Todo({
  todo,
  userDecision,
  finished,
  clarified,
  users,
  onChangeUserDecision,
  onClickClarify
}: TodoProps) {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  function toggle() {
    setIsCollapseOpen(!isCollapseOpen);
  }

  function getFullTodoLabel(todo: Todo) {
    const todoLabel = todo.label;

    if (todo.fulfillFor) {
      return `${todoLabel} - ${todo.fulfillFor}`;
    }

    return todoLabel;
  }

  const toggleIcon = isCollapseOpen ? IconName.CaretDown : IconName.CaretRight;

  return (
    <div className="todo">
      <div className="todo-toggle" onClick={toggle}>
        <Icon name={toggleIcon} />
      </div>
      <div className="todo-label" onClick={toggle}>
        <span>{getFullTodoLabel(todo)}</span>
        <Collapse isOpen={isCollapseOpen}>
          <TodoDetails todo={todo} users={users} />
        </Collapse>
      </div>
      {todo.systemDecision === TodoSystemDecision.NeedMoreInformation &&
        !clarified && (
          <div className="todo-clarify-button">
            <Button color="brand" onClick={() => onClickClarify(todo)}>
              Klären
            </Button>
          </div>
        )}
      <div className="todo-switch">
        <TodoSwitch
          finished={finished}
          userDecision={userDecision}
          onChangeUserDecision={onChangeUserDecision}
        />
      </div>
    </div>
  );
}

interface TodoDetailsProps {
  todo: Todo;
  users: Array<ExtendedUser>;
}

function TodoDetails({ todo, users }: TodoDetailsProps) {
  const isStaff = useShouldShowStaffView();
  const statusValues = getTodoStatusChoices(todo.status, todo.label, isStaff);
  const statusValue = statusValues.find(
    (status) => status.value === todo.status
  );
  const statusLabel = statusValue?.displayName ?? "";
  const responsibleUser = users.find((user) => user.id === todo.responsible);
  const responsiblePerson = responsibleUser
    ? responsibleUser.name
    : EMPTY_FIELD;

  return (
    <div className="todo-details">
      <div className="todo-property">
        <h6>Beschreibung</h6>
        <Description
          regulatoryDuty={todo.regulatoryDuty}
          userDefinedDescription={todo.description}
        />
      </div>
      <div className="todo-property">
        <h6>Verpflichtetes Unternehmen</h6>
        <span className="todo-property-value">
          {todo.person || EMPTY_FIELD}
        </span>
      </div>
      <div className="todo-property">
        <h6>Fälligkeit</h6>
        <span className="todo-property-value">
          {todo.overdue ? "Überfällig" : todo.dueDate || EMPTY_FIELD}
        </span>
      </div>
      <div className="todo-property">
        <h6>Verantwortlich</h6>
        <span className="todo-property-value">{responsiblePerson}</span>
      </div>
      <div className="todo-property">
        <h6>Status</h6>
        <span className="todo-property-value">{statusLabel}</span>
      </div>
    </div>
  );
}

interface TodoSwitchProps {
  userDecision: TodoUserDecision;
  finished: boolean;
  onChangeUserDecision: (decision: TodoUserDecision, finished: boolean) => void;
}

function TodoSwitch({
  userDecision,
  finished,
  onChangeUserDecision
}: TodoSwitchProps) {
  function getClassNameByDecision() {
    if (userDecision === TodoUserDecision.Confirmed && finished) {
      return `dropdown-decision-${userDecision}-finished`;
    }

    return `dropdown-decision-${userDecision}`;
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret className={getClassNameByDecision()}>
        {getDisplayTextByUserDecisionAndStatus(userDecision, finished)}
      </DropdownToggle>
      <DropdownMenu>
        {DECISIONS.map((decision) => (
          <DropdownItem
            key={`${decision.decision}-${decision.finished}`}
            onClick={() =>
              onChangeUserDecision(decision.decision, decision.finished)
            }
          >
            {getDisplayTextByUserDecisionAndStatus(
              decision.decision,
              decision.finished
            )}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function getDisplayTextByUserDecisionAndStatus(
  userDecision: TodoUserDecision,
  finished: boolean
) {
  switch (userDecision) {
    case TodoUserDecision.Confirmed:
      if (finished) {
        return DECISION_TEXTS["APPLICABLE_FINISHED"].displayName;
      } else {
        return DECISION_TEXTS[TodoSystemDecision.Applicable].displayName;
      }
    case TodoUserDecision.Rejected:
      return DECISION_TEXTS[TodoSystemDecision.NotApplicable].displayName;
    case TodoUserDecision.Unclear:
      return DECISION_TEXTS[TodoSystemDecision.CantTell].displayName;
    default:
      Sentry.captureMessage(
        `Unexpected user decision: ${userDecision} and finished: ${finished} supplied to getDisplayTextByUserDecisionAndStatus.`
      );
      return "";
  }
}

export { TodoDecisionWidget, TodoDecisionWidgetProps, TodoSwitch };
