import { useStartPageElectricityTax } from "../../../hooks/startPage/useStartPageElectricityTax";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type ElectricityTaxMiniTileProps = {
  projectId?: string;
  year: number;
};

function ElectricityTaxMiniTile({
  projectId,
  year
}: ElectricityTaxMiniTileProps) {
  const { data, isLoading } = useStartPageElectricityTax(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Formularvorlagen",
      value: data?.amountOfTemplates
    }
  ];

  return (
    <MiniTile
      iconName={IconName.FileJSON}
      isLoading={isLoading}
      items={items}
      title={<>Stromsteuer&shy;dokumente</>}
    />
  );
}

export { ElectricityTaxMiniTile };
