import { useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import type { ApiError } from "../../../api";
import { useContainerRef } from "../../../hooks/useContainerRef";
import type { Site } from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { AutoImportNotificationMsg } from "../AutoImportNotificationMsg/AutoImportNotificationMsg";
import { HistoricalDataImportWithSubmit } from "../HistoricalDataImport/HistoricalDataImportWithSubmit";
import { updateMeterParametersCache } from "../hooks/useMeterParameters";
import { MeterConfiguration } from "../MeterConfiguration/MeterConfiguration";
import { MetersSaveWarningModal } from "../MeterConfiguration/MetersSaveWarningModal/MetersSaveWarningModal";
import {
  SubmeteringConfigurationFormMode,
  SubMeteringConfigurationForm
} from "../SubMeteringConfigurationForm/SubMeteringConfigurationForm";
import type { ConfigurationData } from "../ThirdPartySystems.types";
import { checkSubMeteringConnection } from "../utils/checkSubMeteringConnection";
import { getConfigurationDisplayName } from "../utils/getConfigurationDisplayName";
import { showSaveMeterErrorToast } from "../utils/showSaveMeterErrorToast";
import { updateEntitiesMeterData } from "../utils/updateEntitiesMeterData";

const CONFIGURATION_FORM_TAB = "verbindung";
export const METER_SELECTION_TAB = "zähler";
const HISTORICAL_DATA_IMPORT_TAB = "import";
export const DEFAULT_SUBPAGE = CONFIGURATION_FORM_TAB;

export interface SelectedMeter {
  subMeteringSystemEntityId: number;
  meterId: number;
  meterName: string;
  parameterName: string;
  isSuggested?: boolean;
}

type DifferentParamsInfo = Array<Record<string, Array<string>>>;

interface SaveMeterErrorData {
  extraInfo?: {
    meterName: string;
    differingParameters: DifferentParamsInfo;
  };
  error?: string;
  detail?: string;
}

interface EditConfigurationModalProps {
  isOpen: boolean;
  configuration: ConfigurationData;
  sites: Array<Site>;
  variantId: number;
  onToggle: () => void;
  onConfigurationUpdated: (configuration: ConfigurationData) => void;
}

function EditConfigurationModal({
  isOpen,
  configuration,
  sites,
  variantId,
  onToggle,
  onConfigurationUpdated
}: EditConfigurationModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { subPage: activeTab } = useParams();

  const [connectionLoading, setConnectionLoading] = useState(false);
  const [
    isSelectedMetersWarningModalOpen,
    setIsSelectedMetersWarningModalOpen
  ] = useState<boolean>(false);
  const [selectedMeters, setSelectedMeters] = useState<Array<SelectedMeter>>(
    []
  );

  const { containerNode, containerRef } = useContainerRef();

  async function handleCheckSubMeteringConnection({ withSuccessToast = true }) {
    try {
      setConnectionLoading(true);
      const checkConnectionRes = await checkSubMeteringConnection(
        configuration.id
      );

      if (checkConnectionRes.data?.error) {
        throw new Error(
          checkConnectionRes.data?.error ||
            t("errors.ThirdPartySystems.AuthenticationFailed")
        );
      }

      if (withSuccessToast) showToast("success", "Verbindung erfolgreich");

      return true;
    } catch (error) {
      showToast(
        "error",
        error?.message || t("errors.ThirdPartySystems.AuthenticationFailed")
      );
    } finally {
      setConnectionLoading(false);
    }
  }

  const handleSaveMeters = useCallback(
    async (singleEntityId?: number) => {
      try {
        if (singleEntityId) {
          const singleSelectedMeter = selectedMeters.find(
            (selectedMeter) =>
              selectedMeter.subMeteringSystemEntityId === singleEntityId
          );

          const meterId = singleSelectedMeter?.meterId ?? null;

          await updateEntitiesMeterData(configuration.id, [
            {
              entityId: singleEntityId,
              meterId: meterId
            }
          ]);

          setSelectedMeters((prevSelectedMeters) => {
            return prevSelectedMeters.filter(
              (meter) => meter.subMeteringSystemEntityId !== singleEntityId
            );
          });

          updateMeterParametersCache(
            singleEntityId,
            { meter: meterId },
            queryClient,
            configuration.id
          );

          return { success: true };
        }

        await updateEntitiesMeterData(
          configuration.id,
          selectedMeters.map((selectedMeter) => ({
            entityId: selectedMeter.subMeteringSystemEntityId,
            meterId: selectedMeter.meterId
          }))
        );

        showToast(
          "success",
          <AutoImportNotificationMsg selectedMeters={selectedMeters} />,
          {
            autoClose: 10_000
          }
        );

        setSelectedMeters([]);

        return { success: true };
      } catch (error) {
        const err = error as ApiError<SaveMeterErrorData>;
        showSaveMeterErrorToast(err);

        return { success: false };
      }
    },
    [configuration.id, queryClient, selectedMeters]
  );

  async function handleSubmitSubMeteringConfigurationForm(
    configurationData: ConfigurationData,
    stopSpinButton: () => void
  ) {
    if (activeTab === METER_SELECTION_TAB) {
      if (selectedMeters.length > 0) {
        const { success } = await handleSaveMeters();

        if (success) {
          onToggle();
        }

        stopSpinButton();
        return;
      }

      onToggle();
      return;
    }

    onConfigurationUpdated(configurationData);
    onToggle();
  }

  function handleCloseSelectedMetersSaveModal() {
    setIsSelectedMetersWarningModalOpen(false);
  }

  function handleModalClose() {
    if (selectedMeters.length > 0) {
      setIsSelectedMetersWarningModalOpen(true);
      return;
    }

    onToggle();
  }

  return (
    <Modal
      className="EditConfigurationModal"
      isOpen={isOpen}
      size={activeTab === METER_SELECTION_TAB ? "xxl" : undefined}
      toggle={handleModalClose}
    >
      <ModalHeader toggle={handleModalClose}>
        {getConfigurationDisplayName(
          configuration.name,
          configuration.subMeteringSystem
        )}{" "}
        bearbeiten
      </ModalHeader>
      <ModalBody scrollable>
        <Nav className="tabs" pills>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === CONFIGURATION_FORM_TAB
              })}
              tag={Link}
              to={`../${CONFIGURATION_FORM_TAB}`}
            >
              Verbindung
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === METER_SELECTION_TAB
              })}
              tag={Link}
              to={`../${METER_SELECTION_TAB}`}
            >
              Zählerzuordnung
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === HISTORICAL_DATA_IMPORT_TAB
              })}
              tag={Link}
              to={`../${HISTORICAL_DATA_IMPORT_TAB}`}
            >
              Import
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={CONFIGURATION_FORM_TAB}>
            <SubMeteringConfigurationForm
              buttonContainer={containerNode}
              initialConfigurationData={configuration}
              isConnectionLoading={connectionLoading}
              mode={SubmeteringConfigurationFormMode.Edit}
              variantId={variantId}
              onCheckConnection={
                activeTab === CONFIGURATION_FORM_TAB
                  ? handleCheckSubMeteringConnection
                  : null
              }
              onSubmit={handleSubmitSubMeteringConfigurationForm}
            />
          </TabPane>
          <TabPane tabId={METER_SELECTION_TAB}>
            <MeterConfiguration
              configurationId={configuration.id}
              connectionLoading={connectionLoading}
              selectedMeters={selectedMeters}
              setSelectedMeters={setSelectedMeters}
              sites={sites}
              systemName={configuration.subMeteringSystem}
              onMetersSave={handleSaveMeters}
              onSubMeteringConnectionCheck={handleCheckSubMeteringConnection}
            />

            <MetersSaveWarningModal
              isOpen={isSelectedMetersWarningModalOpen}
              onCloseEditModal={onToggle}
              onCloseSelectedMetersWarningModal={
                handleCloseSelectedMetersSaveModal
              }
            />
          </TabPane>
          <TabPane tabId={HISTORICAL_DATA_IMPORT_TAB}>
            <HistoricalDataImportWithSubmit
              configurationId={configuration.id}
            />
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons" ref={containerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { EditConfigurationModal, EditConfigurationModalProps };
