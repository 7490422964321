export type appendQueryStringOptions =
  | {
      arrayFormat?: "comma" | "none";
      skipNull?: boolean;
      skipEmptyString?: boolean;
    }
  | undefined;

export function appendQueryString(
  url: string,
  params: Record<string, unknown> | null,
  options: appendQueryStringOptions = {
    arrayFormat: "none",
    skipNull: false,
    skipEmptyString: false
  }
): string {
  if (!params) return url;

  const searchParams = new URLSearchParams();

  Object.entries(params)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .forEach(([key, value]) => {
      if (
        (options.skipNull && value == null) ||
        (options.skipEmptyString && value === "") ||
        value === undefined
      ) {
        return;
      }
      if (Array.isArray(value)) {
        if (options.arrayFormat === "comma") {
          searchParams.append(key, value.join(","));
        } else {
          value.forEach((v) => {
            if (
              (options.skipNull && v == null) ||
              (options.skipEmptyString && v === "") ||
              v === undefined
            ) {
              return;
            }
            searchParams.append(
              key,
              v === null || v === undefined ? "" : String(v)
            );
          });
        }
      } else {
        searchParams.append(
          key,
          value === null || value === undefined ? "" : String(value)
        );
      }
    });

  const separator = url.includes("?") ? "&" : "?";
  const queryString = searchParams.toString();
  return `${url}${separator}${queryString}`;
}
