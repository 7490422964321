import { Accordion } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { type DateTime } from "luxon";
import { useEffect, useState } from "react";
import type { Unit } from "../../../../../../../utils/enums";
import {
  DATA_GAPS_PREFERENCE,
  DATA_GAPS_PREFERENCE_CHART,
  DATA_GAPS_PREFERENCE_TABLE
} from "../../../../../../../utils/sessionStorageKeys.constant";
import { AlertColor } from "../../../../../../Alert/Alert";
import { Icon } from "../../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../../BuildingBlocks/Icon/types";
import { IconAlert } from "../../../../../../BuildingBlocks/IconAlert/IconAlert";
import { LoadOrError } from "../../../../../../LoadOrError/LoadOrError";
import { useDataGaps } from "../hooks/useDataGaps";
import "./DataGaps.scss";
import type { DataGapsTableObject } from "./DataGaps.types";
import { DataGapsChart } from "./DataGapsChart/DataGapsChart";
import { DataGapsTable } from "./DataGapsTable/DataGapsTable";
import { getCorrectNameFromExpectedOrigin } from "./utils/getCorrectNameFromExpectedOrigin";
import { getTableDataFromDataGaps } from "./utils/getTableDataFromDataGaps";

interface DataGapsProps {
  meterName?: string;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  meter: number;
  unit: Unit;
}

function DataGaps({
  meter,
  unit,
  meterName,
  selectedDateRange
}: DataGapsProps) {
  const [dataGapsTableData, setDataGapsTableData] = useState<
    Array<DataGapsTableObject>
  >([]);

  const [dataGapsPreference, setDataGapsPreference] = useLocalStorage({
    key: DATA_GAPS_PREFERENCE,
    defaultValue: [DATA_GAPS_PREFERENCE_CHART, DATA_GAPS_PREFERENCE_TABLE]
  });

  const { dataGaps, isLoading, error } = useDataGaps(
    unit,
    meter,
    selectedDateRange[0].toJSDate(),
    selectedDateRange[1].toJSDate()
  );

  useEffect(() => {
    if (dataGaps) {
      setDataGapsTableData(getTableDataFromDataGaps(dataGaps));
    }
  }, [dataGaps]);

  const expectedOrigin = dataGaps?.expectedOrigin;

  return (
    <div className="DataGaps">
      <LoadOrError error={error} loading={isLoading}>
        {dataGaps && (
          <>
            {expectedOrigin && (
              <IconAlert
                className="data-gaps-origin-info"
                color={AlertColor.Info}
                my="lg"
              >
                Quelle der Daten für den Zähler{" "}
                <span className="data-gaps-origin-info-meter-name">
                  {meterName}
                </span>{" "}
                ist {getCorrectNameFromExpectedOrigin(expectedOrigin)}
              </IconAlert>
            )}
            <Accordion
              multiple
              value={dataGapsPreference}
              variant="separated"
              onChange={setDataGapsPreference}
            >
              <Accordion.Item value={DATA_GAPS_PREFERENCE_CHART}>
                <Accordion.Control>
                  <Icon
                    className="accordion-control-inner-icon"
                    name={IconName.AreaChart}
                  />
                  Datenverfügbarkeit
                </Accordion.Control>
                <Accordion.Panel>
                  <DataGapsChart
                    dataGaps={dataGaps}
                    selectedTimeRange={selectedDateRange}
                  />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item value={DATA_GAPS_PREFERENCE_TABLE}>
                <Accordion.Control>
                  <Icon
                    className="accordion-control-inner-icon"
                    name={IconName.Table}
                  />
                  Datenlücken
                </Accordion.Control>
                <Accordion.Panel>
                  <DataGapsTable data={dataGapsTableData} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </LoadOrError>
    </div>
  );
}

export { DataGaps };
