import type { SiteName } from "../../utils/enums";
import { PublicPageLayout } from "../Layout/PublicPageLayout";
import { Support } from "./Support";

declare const SITE_NAME: SiteName;

function SupportPage() {
  return (
    <PublicPageLayout siteName={SITE_NAME} wide>
      <Support />
    </PublicPageLayout>
  );
}

export { SupportPage };
