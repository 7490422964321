import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { DeleteConfirmationModal } from "../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../../Buttons/Button/Button";
import { formatEda } from "../../EnergyData/common";
import type { Acquisition } from "../../EnergyData/EnergyDataView/EnergyDataView";
import "./EdaDetailModal.scss";
import { EdaOverview } from "./EdaOverview/EdaOverview";

interface EdaDetailModalProps {
  isOpen: boolean;
  acquisition?: Acquisition;
  readOnly: boolean;
  onCloseModal: () => void;
  onClickDelete: () => void;
  onOpenEditAcquisitionModal: () => void;
}

function EdaDetailModal({
  isOpen,
  acquisition,
  readOnly,
  onCloseModal,
  onClickDelete,
  onOpenEditAcquisitionModal
}: EdaDetailModalProps) {
  if (!acquisition) {
    return null;
  }

  const formattedAcquisiton = formatEda(acquisition);

  return (
    <Modal className="EdaDetailModal" isOpen={isOpen} toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        {formattedAcquisiton.label}
      </ModalHeader>
      <ModalBody scrollable>
        <EdaOverview acquisition={formattedAcquisiton} />
      </ModalBody>
      <ModalFooter>
        {!readOnly && (
          <DeleteButton
            id={formattedAcquisiton.id}
            name={formattedAcquisiton.label}
            onClickDelete={onClickDelete}
          />
        )}
        {!readOnly && (
          <Button color="brand" onClick={onOpenEditAcquisitionModal}>
            Bearbeiten
          </Button>
        )}
        <Button color="brand" onClick={onCloseModal}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

interface DeleteButtonProps {
  id: number;
  name: string;
  onClickDelete: (id: number) => void;
}

export function DeleteButton({ id, name, onClickDelete }: DeleteButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggle() {
    setIsModalOpen(!isModalOpen);
  }

  function onSuccess() {
    toggle();
    onClickDelete(id);
  }

  return (
    <>
      <Button color="danger" onClick={toggle}>
        Löschen
      </Button>
      <DeleteConfirmationModal
        isModalOpen={isModalOpen}
        name={name}
        toggleModal={toggle}
        onClickConfirm={onSuccess}
      />
    </>
  );
}

export { EdaDetailModal };
