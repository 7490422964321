import type { ComboboxItem } from "@mantine/core";
import { Button, Select, Stack } from "@mantine/core";
import { FormFieldInfoText } from "../../../../BuildingBlocks/Forms/FormField/FormFieldInfoText/FormFieldInfoText";
import { FormFieldLabel } from "../../../../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import type { Paragraph6ContractPayload } from "../../../Paragraph6.types";

interface Paragraph6ContractTemplateSelectProps {
  templates?: Array<Paragraph6ContractPayload>;
  onChange: (template: Partial<Paragraph6ContractPayload> | null) => void;
}

function Paragraph6ContractTemplateSelect({
  templates,
  onChange
}: Paragraph6ContractTemplateSelectProps) {
  const templateItems =
    templates?.map(
      (template): ComboboxItem => ({
        label: template.name,
        value: template.id
      })
    ) || [];

  function handleChange(value: string | null) {
    const template = templates?.find((template) => template.id === value);
    if (template) {
      onChange({
        ...template,
        is_template: false,
        name: "",
        id: undefined,
        erzeuger_anteile: []
      });
    }
  }

  return (
    <div className="contract-templates-list">
      <FormFieldLabel
        formFieldId="Paragraph6ContractModal_template"
        label="Referenzvertrag auswählen:"
      />
      {templateItems.length <= 6 ? (
        <Stack gap={0}>
          {templateItems.map((template) => (
            <Button
              justify="flex-start"
              key={template.value}
              variant="subtle"
              onClick={() => handleChange(template.value)}
            >
              {template.label}
            </Button>
          ))}
        </Stack>
      ) : (
        <Select
          aria-label="Referenzvertrag auswählen"
          data={templateItems}
          id="Paragraph6ContractModal_template"
          role="combobox"
          searchable
          onChange={handleChange}
        />
      )}
      <FormFieldInfoText infoText="Wählen Sie einen Referenzvertrag aus, um das Formular im nächsten Schritt vorauszufüllen." />
    </div>
  );
}
export { Paragraph6ContractTemplateSelect };
