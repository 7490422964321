import { Group, Text } from "@mantine/core";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import "./ErrorCell.scss";

function ErrorCell() {
  return (
    <Group className="ErrorCell" gap="xs">
      <Icon className="error-cell-icon" name={IconName.Warning} />
      <Text c={"red"} fw={600} m={0}>
        Bitte tragen Sie einen Wert ein.
      </Text>
    </Group>
  );
}

export { ErrorCell };
