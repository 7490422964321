import { useState } from "react";
import {
  Link,
  Outlet,
  generatePath,
  useLocation,
  useOutletContext
} from "react-router-dom";
import { useRouteVariantId } from "../../../hooks/useRouteVariantId";
import { VARIANTS_URL_WITH_ID } from "../../../routes";
import urls from "../../../urls";
import type { Project, VariantMinimal } from "../../../utils/backend-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { DownIcon } from "../icons/DownIcon";
import { SeparatorIcon } from "../icons/SeparatorIcon";
import { MenuItem } from "../MenuItem/MenuItem";
import { MenuLink } from "../MenuLink/MenuLink";

function VariantBreadcrumbs() {
  const variantId = useRouteVariantId();
  const [project] = useOutletContext<[Project]>();

  if (!variantId || !project) {
    return null;
  }

  const variant = project.variants.find((variant) => variant.id === variantId);
  const visibleVariants = project.variants.filter(
    (variantToFilter) => !variantToFilter.isManagerVariant
  );

  if (!variant || !visibleVariants) {
    return null;
  }

  return (
    <>
      <VariantListBreadcrumbs projectId={project.id} />
      <SelectedVariantBreadcrumbs
        projectId={project.id}
        variantId={variantId}
        variantName={variant.name}
        visibleVariants={visibleVariants}
      />
      <Outlet />
    </>
  );
}

interface VariantListBreadcrumbsProps {
  projectId: string;
}

function VariantListBreadcrumbs({ projectId }: VariantListBreadcrumbsProps) {
  return (
    <>
      <MenuItem rel>
        <MenuLink text="Varianten" to={urls.projectList(projectId)} />
      </MenuItem>
      <SeparatorIcon />
    </>
  );
}

interface SelectedVariantBreadcrumbsProps {
  variantId: number;
  variantName: string;
  visibleVariants: Array<VariantMinimal>;
  projectId: string;
}

function SelectedVariantBreadcrumbs({
  variantId,
  variantName,
  visibleVariants,
  projectId
}: SelectedVariantBreadcrumbsProps) {
  const showVariantDropdown = visibleVariants && visibleVariants.length > 1;

  return (
    <>
      <MenuItem>
        <MenuLink
          text={variantName}
          textId="breadcrumb-variant"
          to={urls.analyzerVariantStructure(variantId, projectId)}
        />
      </MenuItem>
      {showVariantDropdown && (
        <MenuItem className="menu-item-variant-sub-menu">
          <VariantSubMenu
            currentVariantId={variantId}
            projectId={projectId}
            variants={visibleVariants}
          />
        </MenuItem>
      )}
      <SeparatorIcon />
    </>
  );
}

interface VariantSubMenuProps {
  variants: Array<VariantMinimal>;
  currentVariantId: number;
  projectId: string;
}

function VariantSubMenu({
  variants,
  currentVariantId,
  projectId
}: VariantSubMenuProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const currentVariantPath = generatePath(VARIANTS_URL_WITH_ID, {
    variantId: currentVariantId.toString(),
    projectId: projectId
  });
  const location = useLocation();

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle aria-expanded={dropdownOpen} tag="span">
        <DownIcon />
      </DropdownToggle>
      <DropdownMenu>
        {variants.map((variant) => {
          if (variant.id !== currentVariantId) {
            return (
              <DropdownItem
                key={variant.id}
                tag={Link}
                to={location.pathname.replace(
                  currentVariantPath,
                  generatePath(VARIANTS_URL_WITH_ID, {
                    variantId: variant.id.toString(),
                    projectId: projectId
                  })
                )}
              >
                {variant.name}
              </DropdownItem>
            );
          } else {
            return null;
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export { VariantBreadcrumbs };
