import type { ContainerElement } from "../../../utils/backend-types";
import type { ObjectName } from "../../../utils/enums";
import type { CustomContainerElementNodeType } from "../CustomGraphComponents/CustomContainerElementNode/CustomContainerElementNode";

export function getFlowNodesFromContainers(
  containerElements: Array<ContainerElement>,
  siteId: number
): Array<CustomContainerElementNodeType> {
  return containerElements.map((containerElement) => {
    return {
      id: containerElement.id.toString(),
      position: {
        y: containerElement.y,
        x: containerElement.x
      },
      data: {
        site: siteId,
        width: containerElement.width,
        height: containerElement.height,
        color: containerElement.data.color,
        type: containerElement.data.type as ObjectName
      },
      type: "container"
    };
  });
}
