import type { TFunction } from "i18next";
import { isRequiredStringValid } from "../../../../../mantine/mrt/validation/isRequiredStringValid";
import { MAX_GENERATOR_WEIGHTING_PER_LOCATION } from "../../../../StructureView/StructureView.constants";
import type { DirektvermarktungGeneratorShare } from "../../../Direktvermarktung.types";

/** Validates form values from a row in DirektvermarktungGeneratorShareTable. */
export function validateDirektvermarktungGeneratorShareFormValues(
  generatorShare: Partial<DirektvermarktungGeneratorShare>,
  otherRowsWeightingSum: number,
  t: TFunction
) {
  return {
    generatorId: !isRequiredStringValid(generatorShare.generator_display_name)
      ? t("errors.Required")
      : "",
    meteringOrMarketLocationId: !isRequiredStringValid(
      generatorShare.metering_or_market_location_id?.toString()
    )
      ? t("errors.Required")
      : "",
    weighting: validateShare(generatorShare.weighting, otherRowsWeightingSum, t)
  };
}

function validateShare(
  value: string | undefined | null,
  otherRowsWeightingSum: number,
  t: TFunction
) {
  if (!isRequiredStringValid(value)) {
    return t("errors.Required");
  }

  const parsedValue = parseFloat(value);
  if (parsedValue < 0 || parsedValue > 1) {
    return t("errors.Direktvermarktung.GeneratorShares.ShareRangeError");
  }

  if (
    otherRowsWeightingSum + parsedValue >
    MAX_GENERATOR_WEIGHTING_PER_LOCATION
  ) {
    return t("errors.ExcessWeightingError", {
      maxWeighting: MAX_GENERATOR_WEIGHTING_PER_LOCATION
    });
  }

  return "";
}
