import { Paper, Title } from "@mantine/core";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { MantineLineChart } from "../../../Charts/LineChart/MantineLineChart";
import { type LoadProfileSeries } from "../../LoadProfileManager.types";

const hhmmssRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

type LoadProfileLinePlotProps = {
  plotData: Array<LoadProfileSeries>;
  xAxisLabel: string;
  yAxisLabel: string;
};

function LoadProfileLinePlot({
  plotData,
  xAxisLabel,
  yAxisLabel
}: LoadProfileLinePlotProps) {
  const hasNumberXValues = typeof plotData[0].x[0] === "number";

  const mantineSeries = plotData.map((series, seriesIndex) => ({
    name: plotData.length > 1 ? series.name : yAxisLabel,
    color: seriesColors[seriesIndex % seriesColors.length]
  }));

  const allX = Array.from(
    new Set(plotData.map((series) => series.x).flat())
  ).sort((xA, xB) =>
    typeof xA === "number" && typeof xB === "number" ? xA - xB : 0
  );

  const mantineData = allX.map((xValue) => ({
    x: xValue,
    ...mantineSeries.reduce(
      (result, series, seriesIndex) => ({
        ...result,
        [series.name]:
          plotData[seriesIndex].y[
            plotData[seriesIndex].x.findIndex((value) => value === xValue)
          ]
      }),
      {}
    )
  }));

  return (
    <Paper>
      <Title mb="lg" order={5}>
        {yAxisLabel}
      </Title>
      <MantineLineChart
        data={mantineData}
        dataKey="x"
        h={380}
        series={mantineSeries}
        valueFormatter={(yValue) => yValue.toFixed(3)}
        withLegend={plotData.length > 1}
        xAxisLabel={xAxisLabel}
        xAxisProps={{
          scale: hasNumberXValues ? "linear" : undefined,
          tickFormatter: (value) =>
            hhmmssRegex.test(value)
              ? value.split(":")[0] + ":" + value.split(":")[1]
              : value,
          type: hasNumberXValues ? "number" : "category"
        }}
        zoomable={hasNumberXValues}
      />
    </Paper>
  );
}

export { LoadProfileLinePlot };
