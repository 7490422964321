import {
  QueryClient,
  QueryClientProvider,
  useQueryClient
} from "@tanstack/react-query";
import type {
  Connection,
  DefaultEdgeOptions,
  Edge,
  EdgeChange,
  NodeChange,
  OnEdgesChange,
  OnNodesChange
} from "@xyflow/react";
import {
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  ReactFlow as FlowGraph,
  getConnectedEdges,
  getNodesBounds,
  getViewportForBounds,
  useOnSelectionChange,
  useReactFlow,
  useStoreApi
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import classnames from "classnames";
import { toPng } from "html-to-image";
import { t } from "i18next";
import { DateTime } from "luxon";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  BrowserRouter as Router,
  useMatch,
  useNavigate
} from "react-router-dom";
import api from "../../api";
import { useSitesDetail } from "../../hooks/useSitesDetail";
import { useStructureViewFlowDiagram } from "../../hooks/useStructureViewFlowDiagram";
import {
  DIAGRAM_QUERY_KEY,
  useStructureViewFlowDiagramMutations
} from "../../hooks/useStructureViewFlowDiagramMutations";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import type { Meter, MeteringDirection } from "../../utils/backend-types";
import { ObjectName, StructureDiagramObjectName } from "../../utils/enums";
import { downloadFileAsName } from "../../utils/files/downloadFileAsName";
import { getLinkToComponentEditModal } from "../../utils/getLinkToComponentEditModal";
import { showToast } from "../../utils/toast";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { DataWarningAlert } from "../StructureView/DataWarningAlert/DataWarningAlert";
import { StructureViewMode } from "../StructureView/StructureView.constants";
import { UserAccessContext } from "../StructureView/UserAccessContext";
import {
  generateBlobImage,
  getFileName
} from "../StructureView/utils/createMeteringConceptPdf";
import { CustomConnectionLine } from "./CustomGraphComponents/CustomConnectionLine/CustomConnectionLine";
import {
  CustomContainerElementNode,
  type CustomContainerElementNodeType
} from "./CustomGraphComponents/CustomContainerElementNode/CustomContainerElementNode";
import { CustomContainerElementPopup } from "./CustomGraphComponents/CustomContainerElementPopup/CustomContainerElementPopup";
import { CustomElementMenu } from "./CustomGraphComponents/CustomElementMenu/CustomElementMenu";
import type {
  CustomFlowNodeProps,
  CustomFlowNodeType
} from "./CustomGraphComponents/CustomFlowNode/CustomFlowNode";
import { CustomFlowNode } from "./CustomGraphComponents/CustomFlowNode/CustomFlowNode";
import { CustomMeteringConnectionLine } from "./CustomGraphComponents/CustomMeteringConnectionLine/CustomMeteringConnectionLine";
import { CustomMeteringFlowNode } from "./CustomGraphComponents/CustomMeteringFlowNode/CustomMeteringFlowNode";
import type {
  CustomtTextElementNodeEditData,
  CustomTextElementNodeType
} from "./CustomGraphComponents/CustomTextElementNode/CustomTextElementNode";
import { CustomTextElementNode } from "./CustomGraphComponents/CustomTextElementNode/CustomTextElementNode";
import { CustomTextElementPopup } from "./CustomGraphComponents/CustomTextElementPopup/CustomTextElementPopup";
import { EdgePopup } from "./CustomGraphComponents/EdgePopup/EdgePopup";
import { FloatingEdge } from "./CustomGraphComponents/FloatingEdge/FloatingEdge";
import {
  NodePopup,
  type NodePopupData
} from "./CustomGraphComponents/NodePopup/NodePopup";
import { TextEditModal } from "./CustomGraphComponents/TextEditModal/TextEditModal";
import { DownloadConfirmationModal } from "./DownloadConfirmationModal/DownloadConfirmationModal";
import type { SketchData } from "./StructureViewFlowDiagram.types";
import { getFlowEdgesFromEdges } from "./utils/getFlowEdgesFromEdges";
import { getFlowNodesFromContainers } from "./utils/getFlowNodesFromContainers";
import { getFlowNodesFromNodes } from "./utils/getFlowNodesFromNodes";
import { getFlowNodesFromTextBlocks } from "./utils/getFlowNodesFromTextBlocks";
import { getSnapPoints } from "./utils/getSnapPoints";
import "./StructureViewFlowDiagram.scss";

export type FlowNode =
  | CustomFlowNodeType
  | CustomContainerElementNodeType
  | CustomTextElementNodeType;

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: "black"
};

const STRUCTURE_VIEW_IMAGE_WIDTH = 2560;
const STRUCTURE_VIEW_IMAGE_HEIGHT = 1440;

export enum DownloadLoading {
  None,
  Download,
  DownloadWithUpload
}

const defaultEdgeOptions: DefaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "black" },
  type: "floating",
  interactionWidth: 100
};

const meteringConceptEdgeOptions: DefaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "black" },
  type: "smoothstep"
};

const edgeTypes = {
  floating: FloatingEdge
};

interface StructureViewFlowDiagramProps
  extends Omit<SketchData, "nodes" | "edges"> {
  setShowNotAccessibleModal?: (show: boolean) => void;
  report: boolean;
  projectId: string;
  siteId: number;
  nodes: Array<FlowNode>;
  setCoordinates: (coordinates: { x: number; y: number }) => void;
  centerCoordinates: { x: number; y: number };
  invalidateMeterData: () => void;
  mode: StructureViewMode;
  meters?: Array<Meter>;
  edges: Array<Edge>;
}

function StructureViewFlowDiagram({
  siteId,
  nodes: initialNodes,
  edges: initialEdges,
  icons,
  projectId,
  centerCoordinates,
  mode,
  meters,
  invalidateMeterData,
  setCoordinates,
  setShowNotAccessibleModal
}: StructureViewFlowDiagramProps) {
  const [nodeFakeId, setNodeFakeId] = useState(0);
  const nodeTypes = useMemo(() => {
    return {
      custom: (props: CustomFlowNodeProps) => {
        let meteringDirection: MeteringDirection | null = null;
        if (props.data.type === ObjectName.Meter) {
          const meter = meters?.find(
            (meter) => meter.id === props.data.componentId
          );
          if (meter) {
            meteringDirection = meter.meteringDirection;
          }
        }
        if (mode === StructureViewMode.MeteringConcept) {
          return (
            <CustomMeteringFlowNode
              {...props}
              meteringDirection={meteringDirection}
              mode={mode}
            />
          );
        } else {
          return (
            <CustomFlowNode
              {...props}
              meteringDirection={meteringDirection}
              mode={mode}
            />
          );
        }
      },
      metering: (props) => {
        let meteringDirection: MeteringDirection | null = null;
        if (props.data.type === ObjectName.Meter) {
          const meter = meters?.find(
            (meter) => meter.id === props.data.componentId
          );
          if (meter) {
            meteringDirection = meter.meteringDirection;
          }
        }
        return (
          <CustomMeteringFlowNode
            {...props}
            meteringDirection={meteringDirection}
            mode={mode}
          />
        );
      },
      text: (props) => {
        return (
          <CustomTextElementNode
            {...props}
            handleDoubleClick={(editData) => setTextNodeEditData(editData)}
          />
        );
      },
      container: (props) => {
        return <CustomContainerElementNode {...props} mode={mode} />;
      }
    };
  }, [meters, mode]);
  const { getNodes, getIntersectingNodes, getInternalNode, getViewport } =
    useReactFlow();
  const {
    downloadMeteringConceptPDFMutation,
    deleteContainerElementNodeMutation,
    deleteTextElementNodeMutation,
    updateNodeIconMutation,
    updateNodePositionMutation,
    updateContainerColorMutation,
    updateTextConfigMutation,
    createContainerElementNodeMutation,
    createTextElementNodeMutation
  } = useStructureViewFlowDiagramMutations(siteId, mode);

  const queryClient = useQueryClient();
  const queryKey = DIAGRAM_QUERY_KEY(mode, siteId);
  const store = useStoreApi();
  const { height, width } = store.getState();
  const setCenterOfViewport = useCallback(() => {
    const { x: viewportX, y: viewportY, zoom } = getViewport();

    const zoomMultiplier = 1 / zoom;
    const centerX = -viewportX * zoomMultiplier + (width * zoomMultiplier) / 2;
    const centerY = -viewportY * zoomMultiplier + (height * zoomMultiplier) / 2;
    setCoordinates({ x: Math.round(centerX), y: Math.round(centerY) });
  }, [getViewport, height, setCoordinates, width]);
  const [centerOfViewportWasSetInitially, setCenterOfViewportWasSetInitially] =
    useState(false);
  const [selectedNodes, setSelectedNodes] = useState<Array<string>>([]);
  const onChangeSelectedNodes = useCallback(({ nodes }) => {
    setSelectedNodes(nodes.map((node) => node.id));
  }, []);

  useOnSelectionChange({
    onChange: onChangeSelectedNodes
  });

  useEffect(() => {
    if (!centerOfViewportWasSetInitially) {
      setCenterOfViewport();
      setCenterOfViewportWasSetInitially(true);
    }
  }, [centerOfViewportWasSetInitially, setCenterOfViewport]);

  const userCanCreateAndDelete = useContext(UserAccessContext);
  const [downloadLoading, setDownloadLoading] = useState(DownloadLoading.None);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [textNodeEditData, setTextNodeEditData] =
    useState<CustomtTextElementNodeEditData | null>(null);
  const [nodePopupId, setNodePopupId] = useState<string | null>(null);
  const [nodePopupData, setNodePopupData] = useState<NodePopupData | null>(
    null
  );
  const [edgePopupId, setEdgePopupId] = useState<string | null>(null);
  const [popupXY, setPopupXY] = useState<[number, number] | null>(null);
  const [addEdgeFromNode, setAddEdgeFromNode] = useState<string | null>(null);
  const [addEdgesToNode, setAddEdgesToNode] = useState<Array<string> | null>(
    null
  );

  const [nodes, setNodes] = useState<Array<FlowNode>>(initialNodes);
  const [edges, setEdges] = useState<Array<Edge>>(initialEdges);
  const onNodesChange: OnNodesChange<FlowNode> = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const [shiftIsPressed, setShiftIsPressed] = useState(false);
  const { data: siteDetails, isLoading: siteDetailsLoading } =
    useSitesDetail(siteId);

  const navigate = useNavigate();
  const managerMatch = useMatch(ROUTES.managerVariantStructure + "/*");
  const currentDate = DateTime.now().toFormat("yyyyLLdd_HHmmss");
  const flowGraphRef = useRef<HTMLDivElement>(null);
  const unConfirmedMeters = nodes.filter(
    (node) =>
      node.type === "custom" &&
      node.data.tooltipData?.isConsumptionShareEditable &&
      !node.data.tooltipData?.isConsumptionShareConfirmed
  );
  const edgeOptions =
    mode === StructureViewMode.New
      ? defaultEdgeOptions
      : meteringConceptEdgeOptions;

  const classes = classnames(
    "StructureViewFlowDiagram",
    { "connecting-nodes": !!addEdgeFromNode && !addEdgesToNode },
    { "edge-to-save": !!addEdgeFromNode && !!addEdgesToNode }
  );
  const removeEdge = useCallback(
    (source: string, target: string) => {
      const newEdges = edges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );
      setEdges(newEdges);
      setAddEdgesToNode(null);
    },
    [edges, setEdges]
  );
  const removeEdges = useCallback(
    (source: string, targets: Array<string>) => {
      const newEdges = edges.filter(
        (edge) => edge.source !== source || targets.indexOf(edge.target) === -1
      );
      setEdges(newEdges);
      setAddEdgesToNode(null);
    },
    [edges, setEdges]
  );

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && addEdgeFromNode) {
        if (addEdgeFromNode && addEdgesToNode) {
          removeEdges(addEdgeFromNode, addEdgesToNode);
        }

        setAddEdgeFromNode(null);
        setAddEdgesToNode(null);
      } else if (e.key === "Shift") {
        setShiftIsPressed(true);
      }
    },
    [addEdgeFromNode, addEdgesToNode, removeEdges]
  );

  const handleKeyup = useCallback((e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setShiftIsPressed(false);
    }
  }, []);

  useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges, setEdges]);

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, setNodes]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [handleKeydown, handleKeyup]);

  function handleNodesChange(newNodes: Array<NodeChange<FlowNode>>) {
    // fix `ResizeObserver` loop error in dev mode: https://github.com/xyflow/xyflow/issues/3076#issuecomment-2587294188
    setTimeout(() => onNodesChange(newNodes));
  }
  function handleEdgesChange(newEdges: Array<EdgeChange>) {
    // fix `ResizeObserver` loop error in dev mode: https://github.com/xyflow/xyflow/issues/3076#issuecomment-2587294188
    setTimeout(() => onEdgesChange(newEdges));
  }

  function generatePNGImage(): Promise<void | string> {
    const nodesBounds = getNodesBounds(getNodes());
    nodesBounds.width += nodesBounds.width * 0.1;
    const viewport = getViewportForBounds(
      nodesBounds,
      STRUCTURE_VIEW_IMAGE_WIDTH,
      STRUCTURE_VIEW_IMAGE_HEIGHT,
      0.5,
      2,
      0.1
    );
    const reactFlowViewport = document.querySelector(".react-flow__viewport");

    if (reactFlowViewport) {
      return toPng(reactFlowViewport as HTMLElement, {
        backgroundColor: "white",
        width: STRUCTURE_VIEW_IMAGE_WIDTH,
        height: STRUCTURE_VIEW_IMAGE_HEIGHT,
        style: {
          width: STRUCTURE_VIEW_IMAGE_WIDTH.toString(),
          height: STRUCTURE_VIEW_IMAGE_HEIGHT.toString(),
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`
        }
      });
    }

    return Promise.resolve();
  }

  async function handleClickExportMeteringConceptPDF(uploadToTodo = false) {
    if (uploadToTodo) {
      setDownloadLoading(DownloadLoading.DownloadWithUpload);
    } else {
      setDownloadLoading(DownloadLoading.Download);
    }
    const blob = await generateBlobImage(getNodes);
    if (!blob) {
      setDownloadLoading(DownloadLoading.None);
      showToast("error", "Fehler beim Erstellen des Bildes");
    } else {
      const image = new File(
        [blob],
        getFileName(siteDetails, currentDate, "pdf"),
        { type: blob.type }
      );
      await downloadMeteringConceptPDFMutation.mutateAsync(
        {
          uploadToTodo,
          image,
          siteDetails,
          siteId
        },
        {
          onSettled: () => {
            setDownloadLoading(DownloadLoading.None);
          },
          onSuccess: async (response) => {
            let taskIds;
            if (response) {
              taskIds = response.taskIds;
            }
            if (taskIds && taskIds[0]) {
              try {
                await downloadFileAsName(
                  urls.api.mkExportFinished(taskIds[0]),
                  getFileName(siteDetails, currentDate, "pdf")
                );
              } catch (error) {
                showToast("error", error);
                return;
              }
              setDownloadModalOpen(false);
              showToast(
                "success",
                "Messkonzeptdokument erfolgreich exportiert!",
                { position: "top-center" }
              );
            }
          },
          onError: (error) => {
            showToast("error", error);
          }
        }
      );
    }
  }

  function handleClickDownloadGraph() {
    if (mode === StructureViewMode.MeteringConcept) {
      setDownloadModalOpen(true);
    } else {
      generatePNGImage().then((dataUrl) => {
        if (!dataUrl) {
          return;
        }
        const a = document.createElement("a");

        a.setAttribute(
          "download",
          getFileName(siteDetails, currentDate, "png")
        );
        a.setAttribute("href", dataUrl);
        a.click();
      });
    }
  }

  function addEdge(
    source: string,
    target: string,
    sourceHandle?: string | null,
    targetHandle?: string | null
  ) {
    const newEdge: Edge = {
      source,
      target,
      sourceHandle,
      targetHandle,
      id: "000" + nodeFakeId
    };
    setNodeFakeId((prev) => prev + 1);
    setEdges([...edges, newEdge]);
    const newEdgesToNode = addEdgesToNode
      ? [...addEdgesToNode, target]
      : [target];
    setAddEdgesToNode(newEdgesToNode);
  }

  function addEdges(
    source: string,
    targets: Array<string>,
    sourceHandle?: string | null,
    targetHandle?: string | null
  ) {
    const newEdges: Array<Edge> = [...edges];
    targets.forEach((target, index) => {
      newEdges.push({
        source,
        target,
        sourceHandle,
        targetHandle,
        id: "000" + nodeFakeId + index
      });
    });
    setNodeFakeId((prev) => prev + 1);
    setEdges(newEdges);
    setAddEdgesToNode(targets);
  }

  function handleNodeMouseEnter(_, node: FlowNode) {
    if (
      addEdgeFromNode &&
      canAddEdge(addEdgeFromNode, node.id) &&
      node.type !== "text" &&
      node.type !== "container"
    ) {
      addEdge(addEdgeFromNode, node.id);
    }
  }
  function handleNodeMouseLeave(_, node: FlowNode) {
    if (addEdgeFromNode && addEdgesToNode?.indexOf(node.id) !== -1) {
      removeEdge(addEdgeFromNode, node.id);
    }
  }
  function isEdgeBetweenNodes(edge: Edge, source: string, target: string) {
    return (
      (edge.source === source && edge.target === target) ||
      (edge.target === source && edge.source === target)
    );
  }

  function canAddEdge(from: string, to: string) {
    if (!addEdgeFromNode || from === to) {
      return false;
    }

    const edgeExistsAlready = !!edges.find((edge) =>
      isEdgeBetweenNodes(edge, from, to)
    );

    return !edgeExistsAlready;
  }

  function handleNodeDeleted() {
    hideNodePopup();
  }
  function hideNodePopup() {
    setNodePopupId(null);
  }
  function showEdgePopup(id: string, x: number, y: number) {
    setPopupXY([x, y]);
    setEdgePopupId(id);
  }

  function hideEdgePopup() {
    setEdgePopupId(null);
  }
  function deleteEdge(id: string) {
    const edge = edges.find((edge) => edge.id === id);

    if (edge && edge.source && edge.target) {
      removeEdge(edge.source, edge.target);
      api
        .delete(urls.api.sketchEdgesDetail(parseInt(id)))
        .catch((error) => {
          if (edge.source && edge.target) {
            addEdge(edge.source, edge.target);
          }

          showToast("error", error);
        })
        .finally(() => {
          queryClient.invalidateQueries({
            queryKey
          });
          invalidateMeterData();
        });
    }
  }

  function handleClickDeleteEdge(id: string) {
    deleteEdge(id);
    hideEdgePopup();
  }

  async function handleClickDeleteTextBox(id: string) {
    await deleteTextElementNodeMutation.mutateAsync(id, {
      onError: (error) => {
        showToast("error", error);
      },
      onSettled: () => {
        hideNodePopup();
      }
    });
  }

  async function handleClickDeleteContainerElement(id: string) {
    await deleteContainerElementNodeMutation.mutateAsync(id, {
      onError: (error) => {
        showToast("error", error);
      },
      onSettled: () => {
        hideNodePopup();
      }
    });
  }

  function handleNodeDragEnd(
    event: React.MouseEvent<Element, MouseEvent>,
    _,
    nodes: Array<FlowNode>
  ) {
    nodes.forEach((node: FlowNode) => {
      if (node.type === "container") {
        const containerChildren = getIntersectingNodes(node);
        containerChildren.forEach((child) => {
          const internalNode = getInternalNode(child.id)?.internals;
          const positionAbsoluteX =
            internalNode?.positionAbsolute?.x ?? child.position.x;
          const positionAbsoluteY =
            internalNode?.positionAbsolute?.y ?? child.position.y;
          updateNodePosition(
            child.id,
            child.position.x,
            child.position.y,
            child.type,
            child.parentId,
            positionAbsoluteX,
            positionAbsoluteY
          );
        });
      }
      if (
        (!node.position.x || !node.position.y) &&
        (!node.data["position"].x || !node.data["position"].y)
      ) {
        return;
      }

      const positionX = isNaN(node.position.x)
        ? node.data["position"].x
        : node.position.x;
      const positionY = isNaN(node.position.y)
        ? node.data["position"].y
        : node.position.y;
      const internalNode = getInternalNode(node.id)?.internals;
      const positionAbsoluteX =
        internalNode?.positionAbsolute.x &&
        !isNaN(internalNode.positionAbsolute.x)
          ? internalNode?.positionAbsolute?.x
          : positionX;
      const positionAbsoluteY =
        internalNode?.positionAbsolute.y &&
        !isNaN(internalNode.positionAbsolute.y)
          ? internalNode?.positionAbsolute?.y
          : positionY;

      updateNodePosition(
        node.id,
        positionX,
        positionY,
        node.type,
        node.parentId,
        positionAbsoluteX,
        positionAbsoluteY
      );
    });
  }

  function updateNodePosition(
    nodeId: string,
    x: number,
    y: number,
    type?: string,
    parentId?: string,
    xAbsolute?: number,
    yAbsolute?: number
  ) {
    const newNodes = JSON.parse(JSON.stringify([...nodes]));
    const node = newNodes.find((node) => node.id === nodeId);
    if (!node) {
      return;
    }
    const connectedEdges = getConnectedEdges([node], edges);
    const { snapRelativeX, snapRelativeY, snapAbsoluteX, snapAbsoluteY } =
      getSnapPoints(
        nodeId,
        newNodes,
        connectedEdges,
        x,
        y,
        mode,
        getInternalNode
      );

    const newX = snapAbsoluteX ?? xAbsolute ?? x;
    const newY = snapAbsoluteY ?? yAbsolute ?? y;
    let newRelativeX = snapRelativeX ?? x;
    let newRelativeY = snapRelativeY ?? y;
    let newParentId: string | null | undefined = parentId;

    if (type === "custom") {
      const intersectingNodes = getIntersectingNodes(node);

      const containerNode = intersectingNodes.find(
        (node) => node.type === "container"
      );
      if (containerNode) {
        if (!parentId) {
          newRelativeX = x - containerNode.position.x;
          newRelativeY = y - containerNode.position.y;
          newParentId = containerNode.id;
        }
      } else {
        if (parentId) {
          newRelativeX = xAbsolute ?? x;
          newRelativeY = yAbsolute ?? y;
          newParentId = null;
        }
      }
    }

    // save the old values in case of failure
    const oldX = node.position.x;
    const oldY = node.position.y;
    const oldAbsoluteX = node.positionAbsolute?.x;
    const oldAbsoluteY = node.positionAbsolute?.y;

    // update the values
    node.position.x = newRelativeX;
    node.position.y = newRelativeY;
    node.positionAbsolute = {
      x: newX,
      y: newY
    };
    node.parentId = newParentId ?? undefined;

    setNodes(newNodes);

    //make an interface for the type below
    const payload = {
      xPosition: Math.round(node.positionAbsolute.x),
      yPosition: Math.round(node.positionAbsolute.y),
      xRelative: Math.round(newRelativeX),
      yRelative: Math.round(newRelativeY),
      parent: newParentId
    };
    const nodeUpdateUrl =
      type === "text"
        ? urls.api.standaloneSketchElementsTextBlockOptions(nodeId)
        : type === "container"
          ? urls.api.standaloneSketchElementsContainerOptions(nodeId)
          : urls.api.standaloneSketchUpdate(nodeId);

    updateNodePositionMutation.mutateAsync(
      { updateUrl: nodeUpdateUrl, payload },
      {
        onError: (error) => {
          // reset position on error
          node.position.x = oldX;
          node.position.y = oldY;
          if (node.positionAbsolute && oldAbsoluteX && oldAbsoluteY) {
            node.positionAbsolute = {
              x: oldAbsoluteX,
              y: oldAbsoluteY
            };
          }

          setNodes(newNodes);
          showToast("error", error);
        }
      }
    );
  }

  const handleEnableAddEdgeMode = useCallback((id: string) => {
    hideNodePopup();
    setAddEdgeFromNode(id);
  }, []);

  async function handleChangeIcon(imageName: string) {
    const nodeIndex = nodes.findIndex((node) => node.id === nodePopupId);
    const imageData = icons.find((icon) => icon.imageName === imageName);
    const imageUrl = imageData ? imageData.imageUrl : null;
    if (nodeIndex > -1 && imageUrl) {
      const newNodes = [...nodes];
      const oldNode = nodes[nodeIndex];
      const newNode = { ...oldNode };
      if (newNode.type === "custom") {
        newNode.data.image = imageUrl;
      }
      newNodes[nodeIndex] = newNode;
      setNodes(newNodes);
      hideNodePopup();

      await updateNodeIconMutation.mutateAsync(
        { nodeId: newNode.id, icon: imageName },
        {
          onError: (error) => {
            const resetNodes = [...newNodes];
            resetNodes[nodeIndex] = oldNode;
            setNodes(resetNodes);
            showToast("error", error);
          }
        }
      );
    }
  }

  function showNodePopup(node: FlowNode, x: number, y: number) {
    setPopupXY([x, y]);
    const newNodePopupData: NodePopupData = {
      name: "",
      icon: "",
      active: false,
      componentId: 0,
      componentType: undefined,
      detail: null,
      fontWeight: 0,
      fontSize: 0,
      iconColor: ""
    };
    newNodePopupData.type = node.type;

    if (node.type === "custom") {
      newNodePopupData.name = node.data.tooltipData
        ? node.data.tooltipData.name
        : node.data.label;
      newNodePopupData.icon = node.data.image as string | undefined;
      newNodePopupData.iconColor = node.data.imageColor;
      newNodePopupData.active = node.data.active;
      newNodePopupData.componentId = node.data.componentId;
      newNodePopupData.componentType = node.data.type;
      newNodePopupData.detail =
        node.data.tooltipData &&
        Object.prototype.hasOwnProperty.call(node.data.tooltipData, "type") &&
        Object.prototype.hasOwnProperty.call(node.data.tooltipData, "text")
          ? {
              type: node.data.tooltipData.type,
              text: node.data.tooltipData.text,
              isConsumptionShareEditable:
                node.data.tooltipData.isConsumptionShareEditable
            }
          : null;
    } else if (node.type === "text") {
      newNodePopupData.fontWeight = node.data.fontWeight;
      newNodePopupData.fontSize = node.data.fontSize;
      newNodePopupData.text = node.data.text;
    } else if (node.type === "container") {
      newNodePopupData.iconColor = node.data.color;
    }

    setNodePopupData(newNodePopupData);
    setNodePopupId(node.id ?? null);
    setEdgePopupId(null);
  }

  function saveEdge(
    from: string,
    to: string,
    sourceHandle?: string | null,
    targetHandle?: string | null
  ) {
    if (from === to) {
      showToast(
        "error",
        t("errors.StructureViewFlowDiagram.SelfConnectingNode")
      );
      return;
    }
    const url =
      sourceHandle || targetHandle
        ? urls.api.meteringConceptSketchEdges()
        : urls.api.sketchEdges();
    api
      .post(url, {
        fromNode: from,
        toNode: to,
        fromConnectionPoint: sourceHandle,
        toConnectionPoint: targetHandle
      })
      .then((response) => {
        const edgeIndex = edges.findIndex((edge) =>
          isEdgeBetweenNodes(edge, from, to)
        );

        if (edgeIndex > -1) {
          const newEdges = JSON.parse(JSON.stringify(edges));
          newEdges[edgeIndex].id = response.data.id.toString();
          setEdges(newEdges);
        }
      })
      .catch((error) => {
        removeEdge(from, to);
        showToast("error", error);
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey
        });
        invalidateMeterData();
      });
  }

  function handleNodeDragStart() {
    setPopupXY(null);
    setNodePopupData(null);
    setNodePopupId(null);
  }

  function handleNodeClick(
    event: React.MouseEvent<Element, MouseEvent>,
    node: CustomFlowNodeType
  ) {
    const bounds = flowGraphRef?.current?.getBoundingClientRect();
    const left = bounds?.left ?? 0;
    const top = bounds?.top ?? 0;
    let clearAddEdgeFromNode = true;
    if (addEdgeFromNode && addEdgesToNode?.indexOf(node.id) !== -1) {
      saveEdge(addEdgeFromNode, node.id);
    } else if (shiftIsPressed) {
      setAddEdgeFromNode(node.id ?? null);
      clearAddEdgeFromNode = false;
    } else {
      showNodePopup(node, event.clientX - left, event.clientY - top);
    }
    if (clearAddEdgeFromNode) {
      setAddEdgeFromNode(null);
    }
  }

  function handleNodeDoubleClick(
    event: React.MouseEvent<Element, MouseEvent>,
    node: CustomFlowNodeType
  ) {
    const componentId = node.data.componentId;
    const objectName = node.data.type;
    if (managerMatch && componentId && objectName) {
      const path = getLinkToComponentEditModal(
        objectName,
        componentId,
        managerMatch
      );
      if (path) {
        navigate(path);
      }
    }
    hideNodePopup();
  }

  function handleEdgeClick(
    event: React.MouseEvent<Element, MouseEvent>,
    edge: Edge
  ) {
    const bounds = flowGraphRef?.current?.getBoundingClientRect();
    const left = bounds?.left ?? 0;
    const top = bounds?.top ?? 0;
    if (edge && edge.id) {
      showEdgePopup(edge.id, event.clientX - left, event.clientY - top);
    }
  }
  async function handleCreateTextElement() {
    await createTextElementNodeMutation.mutateAsync(centerCoordinates);
  }
  async function handleCreateContainerElement() {
    await createContainerElementNodeMutation.mutateAsync(centerCoordinates);
  }

  async function handleContainerColorChange(color: string, nodeId: string) {
    await updateContainerColorMutation.mutateAsync({ id: nodeId, color });
  }

  async function handleTextConfigChange(
    fontWeight: number,
    fontSize: number,
    nodeId: string
  ) {
    await updateTextConfigMutation.mutateAsync({
      id: nodeId,
      fontWeight,
      fontSize
    });
  }
  function handleConnect(connection: Connection) {
    const nodeInSelection = selectedNodes.find(
      (node) => node === connection.target
    );

    if (nodeInSelection) {
      const newSelectedNodes = selectedNodes.filter(
        (node) => node !== connection.source
      );
      addEdges(
        connection.source,
        newSelectedNodes,
        connection.sourceHandle,
        connection.targetHandle
      );
      newSelectedNodes.forEach((node) => {
        saveEdge(
          connection.source,
          node,
          connection.sourceHandle,
          connection.targetHandle
        );
      });
    } else {
      addEdge(
        connection.source,
        connection.target,
        connection.sourceHandle,
        connection.targetHandle
      );
      saveEdge(
        connection.source,
        connection.target,
        connection.sourceHandle,
        connection.targetHandle
      );
    }
  }

  return (
    <>
      {unConfirmedMeters.length > 0 && (
        <DataWarningAlert
          objectMeters={unConfirmedMeters.map((node: CustomFlowNodeType) => {
            return {
              id: node.data.componentId,
              name: node.data.tooltipData.name
            };
          })}
        />
      )}

      <div
        className={classes}
        data-testid={
          mode === StructureViewMode.New
            ? "structure-view-flow-diagram"
            : "structure-view-metering-concept"
        }
        style={{ width: "100%", height: "100%" }}
      >
        <FlowGraph
          connectionLineComponent={
            mode === StructureViewMode.MeteringConcept
              ? CustomMeteringConnectionLine
              : CustomConnectionLine
          }
          connectionLineStyle={connectionLineStyle}
          defaultEdgeOptions={edgeOptions}
          edges={edges}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 0.1 }}
          minZoom={0.1}
          nodeDragThreshold={1}
          nodes={nodes}
          nodeTypes={nodeTypes}
          ref={flowGraphRef}
          selectNodesOnDrag={false}
          onConnect={handleConnect}
          onEdgeClick={handleEdgeClick}
          onEdgesChange={handleEdgesChange}
          onMoveEnd={() => setCenterOfViewport()}
          onNodeClick={handleNodeClick}
          onNodeDoubleClick={handleNodeDoubleClick}
          onNodeDragStart={handleNodeDragStart}
          onNodeDragStop={handleNodeDragEnd}
          onNodeMouseEnter={handleNodeMouseEnter}
          onNodeMouseLeave={handleNodeMouseLeave}
          onNodesChange={handleNodesChange}
          onPaneClick={() => {
            setPopupXY(null);
            setAddEdgeFromNode(null);
            setNodePopupData(null);
            setNodePopupId(null);
          }}
          onSelectionDragStop={(event, nodes) => {
            handleNodeDragEnd(event, null, nodes);
          }}
        >
          <CustomElementMenu
            siteDetailsLoading={siteDetailsLoading}
            onAddBoxElement={handleCreateContainerElement}
            onAddTextElement={handleCreateTextElement}
            onDownloadGraph={handleClickDownloadGraph}
          />
          <Controls />
        </FlowGraph>
        {downloadModalOpen && (
          <DownloadConfirmationModal
            downloadLoading={downloadLoading === DownloadLoading.Download}
            downloadWithUploadLoading={
              downloadLoading === DownloadLoading.DownloadWithUpload
            }
            onCancel={() => setDownloadModalOpen(false)}
            onDownload={() => handleClickExportMeteringConceptPDF()}
            onDownloadWithUpload={() =>
              handleClickExportMeteringConceptPDF(true)
            }
          />
        )}
        {textNodeEditData && (
          <TextEditModal
            initialTextContent={textNodeEditData.textContent}
            nodeId={textNodeEditData.nodeId}
            siteId={siteId}
            structureViewMode={mode}
            onClose={() => {
              setTextNodeEditData(null);
              hideNodePopup();
            }}
          />
        )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          !(
            nodePopupData.type === "text" || nodePopupData.type === "container"
          ) && (
            <NodePopup
              data={nodePopupData}
              hideEdgeButton={mode === StructureViewMode.MeteringConcept}
              icons={icons}
              key={nodePopupId}
              nodeId={parseInt(nodePopupId, 10)}
              projectId={projectId}
              setShowNotAccessibleModal={setShowNotAccessibleModal}
              siteId={siteId}
              x={popupXY[0]}
              y={popupXY[1]}
              onChangeIcon={(e) => handleChangeIcon(e)}
              onClickAddEdge={(id) => handleEnableAddEdgeMode(id.toString())}
              onNodeDeleted={() => handleNodeDeleted()}
            />
          )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          nodePopupData.type === "text" && (
            <CustomTextElementPopup
              currentFontSize={nodePopupData.fontSize}
              currentFontWeight={nodePopupData.fontWeight}
              nodeId={nodePopupId}
              textContent={nodePopupData.text}
              x={popupXY[0]}
              y={popupXY[1]}
              onClickDelete={(nodeId) => handleClickDeleteTextBox(nodeId)}
              onClickEdit={(editData) => setTextNodeEditData(editData)}
              onEditTextConfig={(fontWeight, fontSize) =>
                handleTextConfigChange(fontWeight, fontSize, nodePopupId)
              }
            />
          )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          nodePopupData.type === "container" && (
            <CustomContainerElementPopup
              currentlySelectedColor={nodePopupData.iconColor}
              nodeId={nodePopupId}
              x={popupXY[0]}
              y={popupXY[1]}
              onClickDelete={(nodeId) =>
                handleClickDeleteContainerElement(nodeId)
              }
              onColorChange={(color) => {
                handleContainerColorChange(color, nodePopupId);
                hideNodePopup();
              }}
            />
          )}
        {!!edgePopupId && popupXY !== null && (
          <EdgePopup
            edgeId={parseInt(edgePopupId as string, 10)}
            key={edgePopupId}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            userHasNoAccess={
              userCanCreateAndDelete &&
              !userCanCreateAndDelete[StructureDiagramObjectName.Edge]
            }
            x={popupXY[0]}
            y={popupXY[1]}
            onClickDelete={(id) => handleClickDeleteEdge(id.toString())}
          />
        )}
      </div>
    </>
  );
}

export interface StructureViewFlowDiagramWrapperProps
  extends Omit<StructureViewFlowDiagramProps, "nodes" | "edges" | "icons"> {
  enabled: boolean;
  setCoordinates: (coordinates: { x: number; y: number }) => void;
}

function StructureViewFlowDiagramWrapper({
  enabled,
  siteId,
  setCoordinates,
  mode,
  meters,
  ...otherProps
}: StructureViewFlowDiagramWrapperProps) {
  const { data, isLoading, error } = useStructureViewFlowDiagram(siteId, mode);

  const convertAllNodesToFlowNodes = useCallback(
    (nodes, edges) => {
      const containerNodes = nodes.filter(
        (node) => node.data.type === "Container"
      );
      const textNodes = nodes.filter((node) => node.data.type === "Freitext");
      const customNodes = nodes.filter(
        (node) =>
          node.data.type !== "Container" && node.data.type !== "Freitext"
      );
      const newContainerNodes = getFlowNodesFromContainers(
        containerNodes,
        siteId
      );
      const newCustomNodes = getFlowNodesFromNodes(customNodes, edges);
      const newTextNodes = getFlowNodesFromTextBlocks(textNodes, siteId);
      return [...newContainerNodes, ...newCustomNodes, ...newTextNodes];
    },
    [siteId]
  );
  const convertedNodes = useMemo(() => {
    if (data) {
      return convertAllNodesToFlowNodes(data.nodes, data.edges);
    }
    return [];
  }, [convertAllNodesToFlowNodes, data]);
  const convertedEges = useMemo(() => {
    if (data) {
      return getFlowEdgesFromEdges(data.edges);
    }
    return [];
  }, [data]);
  return (
    <LoadOrError error={error} loading={!enabled || isLoading}>
      {data && (
        <StructureViewFlowDiagram
          edges={convertedEges}
          icons={data.icons}
          meters={meters}
          mode={mode}
          nodes={convertedNodes}
          setCoordinates={setCoordinates}
          siteId={siteId}
          {...otherProps}
        />
      )}
    </LoadOrError>
  );
}

interface StructureViewDiagramAnalyzerReportWrapperProps
  extends Omit<
    StructureViewFlowDiagramWrapperProps,
    "report" | "enabled" | "setCoordinates" | "projectId"
  > {
  siteId: number;
}

function StructureViewDiagramAnalyzerReportWrapper({
  siteId,
  invalidateMeterData
}: StructureViewDiagramAnalyzerReportWrapperProps) {
  const queryClient = new QueryClient();
  function doNothing() {
    // no need to set coordinates for the report
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <StructureViewFlowDiagramWrapper
          centerCoordinates={{ x: 0, y: 0 }}
          enabled
          invalidateMeterData={invalidateMeterData}
          mode={StructureViewMode.New}
          projectId={""} // project id is not needed for the report
          report
          setCoordinates={doNothing}
          siteId={siteId}
        />
      </QueryClientProvider>
    </Router>
  );
}

export {
  STRUCTURE_VIEW_IMAGE_HEIGHT,
  STRUCTURE_VIEW_IMAGE_WIDTH,
  StructureViewDiagramAnalyzerReportWrapper as StructureViewDiagramAnalyzerReport,
  StructureViewFlowDiagramWrapper as StructureViewFlowDiagram,
  StructureViewFlowDiagram as StructureViewFlowDiagramComponent,
  StructureViewFlowDiagramProps as StructureViewFlowDiagramComponentProps
};
