import { Col, Row } from "reactstrap";
import { DecimalLabel } from "../../BuildingBlocks/DecimalLabel/DecimalLabel";

import "./PriceComponentComparison.scss";

export default function PriceComponentComparison({
  priceComponentKey,
  mergedPriceComponents,
  meteringConceptKeys,
  meteringConceptConfiguration
}) {
  const emptyPriceComponentValues = [
    <Col key="price" />,
    <Col key="quantity" />,
    <Col key="yearlyCost" />
  ];
  const priceComponentValueCols = meteringConceptKeys.map(
    (meteringConceptKey) => {
      const priceComponent =
        mergedPriceComponents.merged[priceComponentKey][meteringConceptKey];
      const renderedPriceComponentValues = priceComponent ? (
        <PriceComponentValues priceComponent={priceComponent} />
      ) : (
        emptyPriceComponentValues
      );
      return (
        <Col
          className={`background-color--${meteringConceptConfiguration[meteringConceptKey].cellBackgroundColor}`}
          key={meteringConceptKey}
        >
          <Row>{renderedPriceComponentValues}</Row>
        </Col>
      );
    }
  );
  return (
    <Row className="price-component-row" key={priceComponentKey}>
      <Col>{mergedPriceComponents.common[priceComponentKey].label}</Col>
      {priceComponentValueCols}
    </Row>
  );
}

function PriceComponentValues({ priceComponent }) {
  const renderCol = ({ key, value, unit, negate = false, ...other }) => {
    const suffix = unit ? " " + unit : "";
    const displayValue = negate ? safeNegate(value) : value;
    return (
      <Col key={key}>
        <DecimalLabel
          alignRight
          fontDanger={displayValue < 0}
          suffix={suffix}
          value={displayValue}
          {...other}
        />
      </Col>
    );
  };

  return [
    renderCol({
      key: "price",
      value: priceComponent.price,
      negate: true,
      unit: priceComponent.priceUnit,
      decimalScale: 3
    }),
    renderCol({
      key: "quantity",
      value: priceComponent.quantity,
      unit: priceComponent.quantityUnit
    }),
    renderCol({
      key: "yearlyCost",
      value: priceComponent.yearlyCost,
      unit: "€",
      negate: true
    })
  ];
}

const safeNegate = (value) => {
  return value === null ? null : -value;
};
