import { useContext, useEffect } from "react";
import { Checkbox } from "../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { FormItem } from "../../../DynamicForm/FormItem/FormItem";
import type {
  Choice,
  ChoiceValue
} from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { FormFieldType } from "../../../DynamicForm/FormItems/FormField/FormField";
import { Input } from "../../../DynamicForm/FormItems/FormField/Input/Input";
import { ParkabrechnungContext } from "../../ParkabrechnungContext";
import { removeItemAtIndex } from "../utils";
import type { ParkabrechnungTag } from "./ParkabrechnungData";
import { PARKABRECHNUNG_TAGS } from "./ParkabrechnungData";

type ParkabrechnungDataPriceInputProps = {
  abrechnungType: ParkabrechnungTag;
  projectId: string;
  belieferterCompanies: Choice<ChoiceValue>[];
};

export function ParkabrechnungDataPriceInput({
  abrechnungType,
  belieferterCompanies
}: ParkabrechnungDataPriceInputProps) {
  const {
    ctPerKwh,
    setCtPerKwh,
    selectedSite,
    selectedCompany,
    setDrittlieferungPrices,
    drittlieferungPrices,
    isStromlieferung,
    isStromsteuer,
    setIsStromlieferung,
    setIsStromsteuer
  } = useContext(ParkabrechnungContext);

  const siteId = selectedSite?.id;

  useEffect(() => {
    if (
      (typeof drittlieferungPrices === "undefined" ||
        drittlieferungPrices.length === 0) &&
      typeof siteId === "number" &&
      selectedCompany
    ) {
      setDrittlieferungPrices([{ siteId, supplierId: selectedCompany }]);
    }
  }, [drittlieferungPrices, setDrittlieferungPrices, siteId, selectedCompany]);
  const addPrice = () => {
    if (siteId && Array.isArray(drittlieferungPrices)) {
      setDrittlieferungPrices([
        ...drittlieferungPrices,
        { siteId, supplierId: selectedCompany }
      ]);
    }
  };
  return (
    <>
      <h6>Berechnung für {selectedSite?.name}</h6>
      {abrechnungType === PARKABRECHNUNG_TAGS.netzbezug && (
        <>
          <p>Berechnung von Strombezugspreis</p>
          <div className="netzbezug-data-price-input">
            <Input
              className="number-value-cell"
              decimalScale={2}
              inputGroupText={"ct/kWh"}
              type="number"
              value={ctPerKwh ?? null}
              onChange={(newValue: number) => setCtPerKwh(newValue)}
            />
          </div>
        </>
      )}
      {abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung && (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
            <Checkbox
              checked={isStromsteuer}
              onChange={(isChecked) => setIsStromsteuer(isChecked)}
            >
              Stromsteuer
            </Checkbox>

            <Checkbox
              checked={isStromlieferung}
              onChange={(isChecked) => setIsStromlieferung(isChecked)}
            >
              Stromlieferung
            </Checkbox>
          </div>
          <div className="drittlieferung-data-price">
            {drittlieferungPrices?.map((price, index) => {
              return (
                <div className="drittlieferung-data-price-input" key={index}>
                  <FormItem
                    allowInput={isStromlieferung}
                    choices={belieferterCompanies}
                    label="Belieferter"
                    multiselect
                    name="belieferter"
                    type={FormFieldType.Field}
                    onInput={(_, recipientIds) => {
                      const index = drittlieferungPrices.findIndex(
                        (p) => price.priceCtPerKwh === p.priceCtPerKwh
                      );
                      if (Array.isArray(recipientIds)) {
                        const newPrices = [...drittlieferungPrices];
                        newPrices[index].recipientIds = recipientIds.map((id) =>
                          Number(id)
                        );

                        setDrittlieferungPrices(newPrices);
                      }
                    }}
                  />
                  <FormItem
                    allowInput={isStromlieferung}
                    inputGroupText="ct/kWh"
                    label="Berechnung von Stromlieferpreis"
                    name="priceCtPerKwh"
                    type={FormFieldType.Float}
                    value={price.priceCtPerKwh ?? null}
                    onInput={(_, newValue) => {
                      const newPrices = [...drittlieferungPrices];
                      const oldIndex = newPrices.findIndex(
                        (p) => price.priceCtPerKwh === p.priceCtPerKwh
                      );

                      newPrices[oldIndex].priceCtPerKwh = Number(newValue);

                      setDrittlieferungPrices(newPrices);
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end"
                    }}
                  >
                    {drittlieferungPrices.length > 1 && (
                      <IconButton
                        iconName={IconName.Trash}
                        style={{
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}
                        onClick={() => {
                          const newPrices = drittlieferungPrices;
                          setDrittlieferungPrices(
                            removeItemAtIndex(newPrices, index)
                          );
                        }}
                      >
                        Löschen
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            })}

            {isStromlieferung && (
              <IconButton iconName={IconName.PlusCircle} onClick={addPrice}>
                Add Preis
              </IconButton>
            )}
          </div>
        </>
      )}
    </>
  );
}
