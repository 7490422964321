import type { ReactNode } from "react";
import { Fragment } from "react";

import type { GeneratorDataStatus } from "../../../../utils/backend-types";
import {
  BasicConfirmationModal,
  BasicConfirmationModalIcon
} from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { getGeneratorStatusHeaderText } from "../../utils/getGeneratorStatusHeaderText";

interface GeneratorOverwriteStatusModalProps {
  isGeneratorStatusModalOpen: boolean;
  onCloseGeneratorStatusModal: () => void;
  status: GeneratorDataStatus | null;
  description: string | Array<string> | ReactNode | null;
}

function GeneratorStatusModal({
  isGeneratorStatusModalOpen,
  onCloseGeneratorStatusModal,
  status,
  description
}: GeneratorOverwriteStatusModalProps) {
  return (
    <BasicConfirmationModal
      headerText={getGeneratorStatusHeaderText(status)}
      icon={
        status === "overwriteSuccess"
          ? BasicConfirmationModalIcon.Success
          : BasicConfirmationModalIcon.Error
      }
      isModalOpen={isGeneratorStatusModalOpen}
      toggleModal={onCloseGeneratorStatusModal}
      onConfirm={onCloseGeneratorStatusModal}
    >
      {Array.isArray(description)
        ? description.map((error) => (
            <Fragment key={error}>
              <div>{error}</div>
              <br />
            </Fragment>
          ))
        : description}
    </BasicConfirmationModal>
  );
}

export { GeneratorStatusModal };
