import { type DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import {
  DirektvermarktungContractContract_remuneration_type,
  DirektvermarktungContractFunding_marketing_form
} from "../../../../types/api.types";
import type { Direktvermarkter } from "../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { sortDateTime } from "../../../../utils/dates/sortDateTime";
import { buildFieldNameToLabelMap } from "../../../../utils/react-hook-form/buildFieldNameToLabelMap";
import { AlertColor } from "../../../Alert/Alert";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { DateRangeInput } from "../../../BuildingBlocks/Forms/FormFields/GroupedFields/DateRangeInput/DateRangeInput";
import { FormBody } from "../../../BuildingBlocks/Forms/utils/FormBody";
import { FormContainer } from "../../../BuildingBlocks/Forms/utils/FormContainer";
import { FormFooter } from "../../../BuildingBlocks/Forms/utils/FormFooter";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import { TabBar } from "../../../BuildingBlocks/Layout/TabBar/TabBar";
import { Button, buttonColors } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { HistoryTab } from "../../../HistoryTab/HistoryTab";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import type {
  DirektvermarktungContract,
  DirektvermarktungContractResponse,
  DirektvermarktungOperator,
  PPAFixedPriceComponent
} from "../../Direktvermarktung.types";
import type { DV_CONTRACT_FORM_TABS } from "./DirektvermarktungContractForm.constants";
import {
  DIREKTVERMARKTER_NONE_ID,
  DIREKTVERMARKTUNG_FORM_INPUT_DATA,
  DIREKTVERMARKTUNG_MARKETING_FORM_CHOICES_WITHOUT_DIREKTVERMARKTER,
  TABS
} from "./DirektvermarktungContractForm.constants";
import {
  type DirektvermarktungContractFixedPriceTableData,
  FIXED_PRICE_TABLE_ROW_LIMIT,
  FixedPriceTable,
  PERIOD_FORMAT
} from "./FixedPriceTable/FixedPriceTable";
import { updatePPAFixedPriceComponentsOnDateRangeChange } from "./utils/updatePPAFixedPriceComponentsOnDateRangeChange";

interface DirektvermarktungContractFormProps {
  direktvermarktungContract?: Partial<DirektvermarktungContractResponse>;
  operators: Array<DirektvermarktungOperator>;
  direktvermarkter: Array<Direktvermarkter>;
  formulaEntgeltVariables: Array<string>;
  isSubmitting?: boolean;
  submissionError?: Error;
  onSubmit: SubmitHandler<DirektvermarktungContract>;
  onCancel: () => void;
}

const fieldNameToLabelMap = buildFieldNameToLabelMap(
  DIREKTVERMARKTUNG_FORM_INPUT_DATA
);

function DirektvermarktungContractForm({
  direktvermarktungContract,
  operators,
  direktvermarkter,
  formulaEntgeltVariables,
  isSubmitting = false,
  submissionError,
  onSubmit,
  onCancel
}: DirektvermarktungContractFormProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<DV_CONTRACT_FORM_TABS>(TABS[0].id);

  const shouldShowStaffView = useShouldShowStaffView();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
    unregister,
    watch
  } = useForm<DirektvermarktungContract>({
    defaultValues: direktvermarktungContract ?? {}
  });

  const operatorChoices: Array<Choice> =
    operators
      ?.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      ?.map((operator) => ({
        value: operator.id,
        displayName: operator.name
      })) || [];
  const operatorFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.operator_id,
    choices: operatorChoices,
    disabled:
      direktvermarktungContract?.generation_shares &&
      direktvermarktungContract?.generation_shares.length > 0
  };

  const direktvermarkterChoices: Array<Choice> =
    direktvermarkter
      ?.sort((a, b) => {
        if (a.id === DIREKTVERMARKTER_NONE_ID) return -1;
        if (b.id === DIREKTVERMARKTER_NONE_ID) return 1;
        return a.name.localeCompare(b.name);
      })
      .map((direktvermarkter) => ({
        value: direktvermarkter.id,
        displayName: direktvermarkter.name
      })) || [];
  const direktvermarkterFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.direktvermarkter_id,
    choices: direktvermarkterChoices
  };

  const selectedDirektvermarkterId = watch("direktvermarkter_id");
  const isDirektvermarkterNoneSelected =
    selectedDirektvermarkterId === DIREKTVERMARKTER_NONE_ID;

  const fundingMarketingFormFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.funding_marketing_form,
    choices: isDirektvermarkterNoneSelected
      ? DIREKTVERMARKTUNG_MARKETING_FORM_CHOICES_WITHOUT_DIREKTVERMARKTER
      : DIREKTVERMARKTUNG_FORM_INPUT_DATA.funding_marketing_form.choices
  };

  const formulaEntgeltFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.formula_entgelt,
    variables: formulaEntgeltVariables
  };

  const showFixedPriceTable =
    watch("contract_remuneration_type") ===
    DirektvermarktungContractContract_remuneration_type.ppa_contract;
  const firstDate = watch("first_date");
  const lastDate = watch("last_date");
  const ppaFixedPriceComponents = watch("ppa_fixed_price_components");

  const isValidNumberOfMonths = checkValidNumberOfMonths(firstDate, lastDate);

  useEffect(() => {
    if (submissionError) {
      setErrorsFromResponseData<DirektvermarktungContract>(
        submissionError,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  }, [setError, submissionError, t, watch]);

  useEffect(() => {
    if (isDirektvermarkterNoneSelected) {
      setValue(
        "funding_marketing_form",
        DirektvermarktungContractFunding_marketing_form.subsidized_direct_marketing
      );
    }
  }, [isDirektvermarkterNoneSelected, setValue]);

  useEffect(() => {
    if (showFixedPriceTable) {
      register("ppa_fixed_price_components");
    } else {
      unregister("ppa_fixed_price_components");
    }
  }, [showFixedPriceTable, register, unregister]);

  useEffect(() => {
    if (!showFixedPriceTable || !isValidNumberOfMonths) {
      return;
    }

    const updatedComponents = updatePPAFixedPriceComponentsOnDateRangeChange(
      ppaFixedPriceComponents,
      firstDate,
      lastDate
    );
    if (updatedComponents) {
      setValue(
        "ppa_fixed_price_components",
        updatedComponents as PPAFixedPriceComponent[]
      );
    }
  }, [
    firstDate,
    lastDate,
    isValidNumberOfMonths,
    ppaFixedPriceComponents,
    showFixedPriceTable,
    setValue
  ]);

  function handleRevenueChange(
    newRevenue: number | undefined,
    startDate: DateTime,
    endDate: DateTime
  ) {
    const backendStartDate = luxonDateTimeToBackendDateOrDateTime(
      startDate,
      "ISO 8601"
    );
    const backendEndDate = luxonDateTimeToBackendDateOrDateTime(
      endDate,
      "ISO 8601"
    );
    const updatedComponent: PPAFixedPriceComponent = {
      end_date: backendEndDate,
      revenue: newRevenue || null,
      start_date: backendStartDate
    };
    const fixedPriceComponents = ppaFixedPriceComponents ?? [];
    const updatedComponents = fixedPriceComponents.map((component) => {
      if (
        component.end_date === updatedComponent.end_date &&
        component.start_date === updatedComponent.start_date
      ) {
        return updatedComponent;
      }

      return component;
    });

    setValue("ppa_fixed_price_components", updatedComponents);
  }

  function handleAllRevenuesChange(newRevenue: number | undefined) {
    const fixedPriceComponents = ppaFixedPriceComponents ?? [];
    const updatedComponents = fixedPriceComponents.map((component) => ({
      ...component,
      revenue: newRevenue || null
    }));

    setValue("ppa_fixed_price_components", updatedComponents);
  }

  const fixedPriceTableData =
    ppaFixedPriceComponents
      ?.sort((a, b) =>
        sortDateTime(
          backendDateOrDateTimeToLuxonDateTime(a.start_date),
          backendDateOrDateTimeToLuxonDateTime(b.start_date)
        )
      )
      .map<DirektvermarktungContractFixedPriceTableData>((component) => {
        const startDateTime = backendDateOrDateTimeToLuxonDateTime(
          component.start_date
        );
        const period = startDateTime.toFormat(PERIOD_FORMAT);
        const revenue = component.revenue ?? undefined;

        return {
          period,
          revenue
        };
      }) ?? [];

  const editMode = !!direktvermarktungContract?.id;

  return (
    <FormContainer>
      <form
        className="DirektvermarktungContractForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormBody>
          <TabBar<DV_CONTRACT_FORM_TABS>
            activeTab={activeTab}
            routeless
            tabs={TABS.filter((tab) => editMode || !tab.hiddenInCreateMode)}
            onActivateTab={setActiveTab}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Object.values(TABS)[0].id}>
              <section>
                <FormFieldController
                  control={control}
                  data={DIREKTVERMARKTUNG_FORM_INPUT_DATA.name}
                  error={errors.name}
                />
                <DateRangeInput
                  endDateFieldName="last_date"
                  errors={errors}
                  label="Gültigkeit der Vermarktungsinformationen"
                  register={register}
                  registerOptions={DIREKTVERMARKTUNG_FORM_INPUT_DATA}
                  setValue={setValue}
                  startDateFieldName="first_date"
                  unregister={unregister}
                  watch={watch}
                />
                <FormFieldController
                  control={control}
                  data={operatorFieldInfo}
                  error={errors.operator_id}
                />
                <FormFieldController
                  control={control}
                  data={direktvermarkterFieldInfo}
                  error={errors.direktvermarkter_id}
                />
                <FormFieldController
                  control={control}
                  data={fundingMarketingFormFieldInfo}
                  error={errors.funding_marketing_form}
                />
                <FormFieldController
                  control={control}
                  data={
                    DIREKTVERMARKTUNG_FORM_INPUT_DATA.contract_remuneration_type
                  }
                  error={errors.contract_remuneration_type}
                />
                {showFixedPriceTable && isValidNumberOfMonths && (
                  <FixedPriceTable
                    data={fixedPriceTableData}
                    onAllRevenuesChange={handleAllRevenuesChange}
                    onRevenueChange={handleRevenueChange}
                  />
                )}
                {showFixedPriceTable && !isValidNumberOfMonths && (
                  <IconAlert color={AlertColor.Danger}>
                    Der ausgewählte Zeitraum für die Gültigkeit der
                    Vermarktungsinformationen ist zu groß, um einzelne Werte für
                    den Tatsächlichen Erlös einzutragen. Bitte wählen Sie einen
                    kleineren Zeitraum aus.
                  </IconAlert>
                )}
                <FormFieldController
                  control={control}
                  data={{
                    ...formulaEntgeltFieldInfo,
                    readOnly: !shouldShowStaffView,
                    infoText: !shouldShowStaffView
                      ? "Für Anpassungswünsche wenden Sie sich bitte an den [Support](/seiten/support)."
                      : undefined
                  }}
                  error={errors.formula_entgelt}
                />
              </section>
            </TabPane>
            {direktvermarktungContract?.id && (
              <TabPane tabId={Object.values(TABS)[1].id}>
                <HistoryTab
                  entityId={direktvermarktungContract.id}
                  entityType="direktvermarktungContract"
                  fieldNameToLabelMap={fieldNameToLabelMap}
                />
              </TabPane>
            )}
          </TabContent>
        </FormBody>
        <FormFooter>
          <Button
            color={buttonColors.secondary}
            disabled={isSubmitting}
            type="button"
            onClick={onCancel}
          >
            Abbrechen
          </Button>
          <SpinButton
            color={buttonColors.brand}
            spin={isSubmitting}
            type="submit"
          >
            Speichern
          </SpinButton>
        </FormFooter>
      </form>
    </FormContainer>
  );
}

function checkValidNumberOfMonths(
  firstSelectedDate: string,
  lastSelectedDate: string
) {
  const firstDate = backendDateOrDateTimeToLuxonDateTime(firstSelectedDate);
  const lastDate = backendDateOrDateTimeToLuxonDateTime(lastSelectedDate);
  return (
    Interval.fromDateTimes(firstDate, lastDate).count("months") <=
    FIXED_PRICE_TABLE_ROW_LIMIT
  );
}

export { DirektvermarktungContractForm, DirektvermarktungContractFormProps };
