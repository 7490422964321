import { useEffect } from "react";
import { useIds } from "../../../../../hooks/useIds";
import { useVariantSites } from "../../../../../hooks/useVariantSites";
import { FormFieldInfoText } from "../../../../BuildingBlocks/Forms/FormField/FormFieldInfoText/FormFieldInfoText";
import {
  GENERAL_FIELDS_MIETER,
  GENERAL_FIELDS_PART_1,
  GENERAL_FIELDS_PART_2,
  GENERAL_PARTNER_FIELDS
} from "../../../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Person";
import { FormItem } from "../../../FormItem/FormItem";
import type { FormItemsProps } from "../../FormItems";
import { SectionHeader } from "../../SectionHeader/SectionHeader";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function GeneralPartnerFields({
  formItems,
  formValues,
  formErrors,
  formName,
  onInput,
  allowInput,
  missingFields,
  exclusiveOrFields,
  isHighlighted
}: FormItemsProps) {
  const { variantId } = useIds();
  const { sites } = useVariantSites(variantId ?? undefined);

  const applicantLegalStructureValue =
    formValues[GENERAL_FIELDS_PART_2.APPLICANT_LEGAL_STRUCTURE];

  const isLegalStructureWithPhg =
    applicantLegalStructureValue === "kg" ||
    applicantLegalStructureValue === "ohg" ||
    applicantLegalStructureValue === "kgaa";

  const isParagraph6EegActive =
    formValues[GENERAL_FIELDS_PART_1.IS_PARAGRAPH_6_EEG_ACTIVE];

  const projectHasSitesWithInvoicing = sites.some(
    (site) => site.invoicing_active
  );

  const isSolarContractor =
    formValues[GENERAL_FIELDS_MIETER.IS_SOLAR_CONTRACTOR];

  // isP6ActiveOrProjectHasSitesWithInvoicing equals the backed condition for showing the general partner fields
  const isP6ActiveOrProjectHasSitesWithInvoicing =
    isParagraph6EegActive ||
    (projectHasSitesWithInvoicing && isSolarContractor);

  function getPlaceholderText(field: string) {
    switch (field) {
      case GENERAL_PARTNER_FIELDS.GENERAL_PARTNER_NAME:
        return "z. B. node.energy GmbH";
      case GENERAL_PARTNER_FIELDS.GENERAL_PARTNER_CITY:
        return "z. B. Frankfurt am Main";
      case GENERAL_PARTNER_FIELDS.GENERAL_PARTNER_COURT:
        return "z. B. Amtsgericht Frankfurt am Main";
      case GENERAL_PARTNER_FIELDS.GENERAL_PARTNER_REGISTER_NUMBER:
        return "z. B. HRB 108778";
      case GENERAL_PARTNER_FIELDS.GENERAL_PARTNER_CEO:
        return "z. B. Manu Musterperson";
      default:
        return "";
    }
  }

  useEffect(() => {
    if (!isLegalStructureWithPhg || !isP6ActiveOrProjectHasSitesWithInvoicing) {
      Object.values(GENERAL_PARTNER_FIELDS).forEach((field) => {
        if (formValues[field]) {
          onInput(field, "");
        }
      });
    }
  }, [
    isLegalStructureWithPhg,
    isP6ActiveOrProjectHasSitesWithInvoicing,
    onInput,
    formValues
  ]);

  if (!isLegalStructureWithPhg || !isP6ActiveOrProjectHasSitesWithInvoicing) {
    return null;
  }

  return (
    <div>
      <SectionHeader>Persönlich haftende Gesellschafter</SectionHeader>
      <FormFieldInfoText infoText="Da der persönlich haftende Gesellschafter Ihre KG, OHG oder KGaA ein anderes Unternehmen ist, ergänzen Sie bitte noch die folgenden Informationen:" />
      {Object.values(GENERAL_PARTNER_FIELDS).map((field) => (
        <FormItem
          key={field}
          {...formItems[field]}
          allowInput={allowInput}
          errors={formErrors[field]}
          formName={formName}
          highlight={
            isHighlighted
              ? isHighlighted(
                  missingFields,
                  exclusiveOrFields,
                  formItems[field]
                )
              : !!missingFields && missingFields.includes(formItems[field].name)
          }
          placeholder={getPlaceholderText(field)}
          value={formValues[field]}
          onInput={onInput}
        />
      ))}
    </div>
  );
}

export { GeneralPartnerFields };
