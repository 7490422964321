import { Accordion } from "@mantine/core";
import { useMemo } from "react";
import { Row } from "reactstrap";
import type { SiteEnergyWizard } from "../../../utils/backend-types";
import { SiteTasksTitle } from "../SiteTasksTitle/SiteTasksTitle";
import { EnergyAssignment } from "./EnergyAssignment/EnergyAssignment";

const ENERGY_WIZARD_HELP_TEXT =
  "Der Energiedaten-Assistent unterstützt Sie beim Bereitstellen Ihrer Energiedaten. In der Übersicht sehen Sie alle Zähler, für welchen uns die Messdaten für das jeweilige Meldejahr noch nicht vollständig vorliegen.";

type EnergyWizardProps = {
  energyWizard: SiteEnergyWizard;
  onEnergyWizardUpdated: () => void;
};

export enum AssignmentType {
  MeasurementData = "Historische Messdaten"
}

function EnergyWizard({
  energyWizard,
  onEnergyWizardUpdated
}: EnergyWizardProps) {
  const nextPhase: AssignmentType | null = useMemo(() => {
    if (!energyWizard.phases.meters.done) {
      return AssignmentType.MeasurementData;
    }
    return null;
  }, [energyWizard]);
  return (
    <Accordion.Item value="energy-wizard">
      <Accordion.Control>
        <SiteTasksTitle
          helpText={ENERGY_WIZARD_HELP_TEXT}
          title="Energiedaten"
        />
      </Accordion.Control>
      <Accordion.Panel>
        <Row style={{ margin: 0 }}>
          <EnergyAssignment
            energyWizard={energyWizard}
            isDone={energyWizard.phases.meters.done}
            isNext={nextPhase === AssignmentType.MeasurementData}
            type={AssignmentType.MeasurementData}
            onEnergyWizardUpdated={onEnergyWizardUpdated}
          />
        </Row>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export { EnergyWizard };
