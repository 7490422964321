import { useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type {
  ActiveCompanies,
  Contract,
  Site
} from "../../../../../utils/backend-types";
import {
  MeasuringPointPriceOption,
  PaymentIntervalAndBillingMethod,
  PaymentMethod,
  VATExemptionReason
} from "../../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { FormFieldController } from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { DueDateFields } from "../../../../BuildingBlocks/Forms/FormFields/GroupedFields/DueDateFields/DueDateFields";
import { setErrorsFromResponseData } from "../../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { applyHighlightingToObjectFormFieldData } from "../../../../VariantObjectWizard/utils/applyHighlightingToObjectFormFieldData";
import { ChangeablePricesField } from "../../SharedFields/ChangeablePricesField";
import type { ContractFormOptions } from "../ContractModal.types";
import {
  CONTRACT_FORM_FIELD_DATA,
  NEW_CONTRACT_DEFAULT_VALUES
} from "./ContractForm.constants";
import "./ContractForm.scss";
import { ContractFormControls } from "./ContractFormControls/ContractFormControls";
import { AutomaticExtensionFields } from "./GroupedFields/AutomaticExtensionFields";
import { SupplierAndSupplied } from "./GroupedFields/SupplierAndSupplied";
import { TemplateFields } from "./GroupedFields/TemplateFields";

interface ContractFormProps {
  activeCompanies?: ActiveCompanies;
  buttonContainer?: HTMLElement;
  contract?: Contract;
  options: ContractFormOptions;
  sites?: Array<Site>;
  templateContracts?: Array<Contract>;
  withTemplate?: boolean;
  onSubmit: (contract: Contract) => Promise<Contract>;
  onCancel: () => void;
  missingFields: Array<string>;
  shouldFieldBeHighlighted: (
    fieldName: string,
    relevantFormValues: Record<string, unknown>
  ) => boolean;
}

function ContractForm({
  activeCompanies,
  buttonContainer,
  contract,
  options,
  templateContracts,
  withTemplate,
  sites,
  onSubmit,
  onCancel,
  missingFields,
  shouldFieldBeHighlighted
}: ContractFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch
  } = useForm<Contract>({
    defaultValues: contract ?? NEW_CONTRACT_DEFAULT_VALUES
  });
  const { t } = useTranslation();

  const { formFieldData } = applyHighlightingToObjectFormFieldData(
    CONTRACT_FORM_FIELD_DATA,
    watch,
    contract
      ? {
          wizardObject: contract,
          fieldsThatCouldBeMissing: missingFields,
          shouldFieldBeHighlighted: shouldFieldBeHighlighted
        }
      : undefined
  );

  const watchCreationTemplate = watch("creationTemplate");
  const watchCreationTemplateSite = watch("creationTemplateSite");
  const templatesFieldsAreFilled =
    watchCreationTemplate && watchCreationTemplateSite;
  const showTemplateFields =
    withTemplate && templateContracts && sites && !templatesFieldsAreFilled;
  const watchSupplied = watch("supplied");
  const watchSupplier = watch("supplier");
  const showAutomaticExtension = watch("automaticExtension") === true;
  const showMonthlyInvoiceDocumentsForAdvancePayment =
    watch("billingMethodAndPeriod") ===
    PaymentIntervalAndBillingMethod.MonthlyAdvancePaymentsAndAnnualFinalInvoice;
  const showPreviousElectricityConsumption =
    watch("monthlyInvoiceDocumentsForAdvancePayment") === true;
  const showMandateReference =
    watch("paymentMethod") === PaymentMethod.DirectDebit;
  const watchAddPriceMeasuringPoint = watch("addPriceMeasuringPoint");
  const showPriceMeasuringPoint =
    watchAddPriceMeasuringPoint &&
    watchAddPriceMeasuringPoint !== MeasuringPointPriceOption.None;
  const showAddBasePrice = watch("addBasePrice");
  const watchIsVatIncluded = watch("isVatIncluded");
  const showVatExemptionReason = watchIsVatIncluded === false;
  const showCustomVatExemptionReason =
    showVatExemptionReason &&
    watch("vatExemptionReason") === VATExemptionReason.custom;
  const shouldShowStaffView = useShouldShowStaffView();

  useEffect(() => {
    if (
      watchCreationTemplate &&
      templateContracts &&
      watchCreationTemplateSite
    ) {
      const valuesFromTemplate = templateContracts?.find(
        (contract) => contract.id === watchCreationTemplate
      );

      if (valuesFromTemplate) {
        reset({
          ...valuesFromTemplate,
          id: undefined,
          isTemplate: false,
          name: undefined,
          supplier: undefined,
          supplied: undefined,
          creationTemplate: watchCreationTemplate,
          creationTemplateSite: watchCreationTemplateSite
        });
      } else {
        reset();
      }
    }
  }, [
    watchCreationTemplate,
    watchCreationTemplateSite,
    templateContracts,
    setValue,
    reset
  ]);

  function getPriceMeasuringPointLabel(priceOption: MeasuringPointPriceOption) {
    if (priceOption === MeasuringPointPriceOption.Total) {
      return "Monatliche Messkosten (netto) in Summe";
    } else if (priceOption === MeasuringPointPriceOption.PerMeter) {
      return "Monatliche Messkosten (netto) je Zähler";
    }

    return "Monatliche Messkosten (netto, ggf. je Zähler)";
  }

  const onValidSubmit: SubmitHandler<Contract> = async (data) => {
    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<Contract>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  function handleClickCancel() {
    reset();
    onCancel();
  }

  const formClassNames = "ContractForm FormItems";
  const submitCallback = handleSubmit(onValidSubmit);

  if (showTemplateFields) {
    return (
      <form className={formClassNames} onSubmit={submitCallback}>
        <TemplateFields
          control={control}
          errors={errors}
          sites={sites}
          templateContracts={templateContracts}
        />
        <ContractFormControls
          buttonContainer={buttonContainer}
          onCancel={handleClickCancel}
          onSubmit={submitCallback}
        />
      </form>
    );
  }

  return (
    <form className={formClassNames}>
      <FormFieldController
        control={control}
        data={formFieldData.name}
        error={errors.name}
      />

      <FormFieldController
        control={control}
        data={formFieldData.productName}
        error={errors.productName}
        rules={{
          maxLength: {
            value: 50,
            message: t("errors.MaxLength", { maxLength: 50 })
          }
        }}
      />

      <SupplierAndSupplied
        activeCompanies={activeCompanies}
        control={control}
        errors={errors}
        selectedSite={watchCreationTemplateSite}
        supplied={watchSupplied}
        suppliedChoices={options.supplied}
        supplier={watchSupplier}
        supplierChocies={options.supplier}
      />

      <FormFieldController
        control={control}
        data={formFieldData.begin}
        error={errors.begin}
      />

      <FormFieldController
        control={control}
        data={formFieldData.minimumTerm}
        error={errors.minimumTerm}
      />

      <FormFieldController
        control={control}
        data={formFieldData.automaticExtension}
        error={errors.automaticExtension}
      />

      {showAutomaticExtension && (
        <AutomaticExtensionFields control={control} errors={errors} />
      )}

      <FormFieldController
        control={control}
        data={formFieldData.end}
        error={errors.end}
        rules={{
          validate: (endValue: string, formValues) => {
            if (endValue && formValues.begin) {
              const beginDateTime = backendDateOrDateTimeToLuxonDateTime(
                formValues.begin
              );
              const endDateTime =
                backendDateOrDateTimeToLuxonDateTime(endValue);

              if (endDateTime < beginDateTime) {
                return t("errors.ContractForm.EndBeforeBegin");
              }
            }

            return true;
          }
        }}
      />

      <FormFieldController
        control={control}
        data={formFieldData.billingMethodAndPeriod}
        error={errors.billingMethodAndPeriod}
      />

      {showMonthlyInvoiceDocumentsForAdvancePayment && (
        <FormFieldController
          control={control}
          data={formFieldData.monthlyInvoiceDocumentsForAdvancePayment}
          error={errors.monthlyInvoiceDocumentsForAdvancePayment}
        />
      )}

      {showPreviousElectricityConsumption && shouldShowStaffView && (
        <FormFieldController
          control={control}
          data={CONTRACT_FORM_FIELD_DATA.previousElectricityConsumption}
          error={errors.previousElectricityConsumption}
        />
      )}

      <FormFieldController
        control={control}
        data={formFieldData.paymentMethod}
        error={errors.paymentMethod}
      />

      <DueDateFields
        control={control}
        errors={errors}
        formFieldData={formFieldData}
        unitFieldName="dueDateUnit"
        valueFieldName="dueDateValue"
      />

      {showMandateReference && (
        <FormFieldController
          control={control}
          data={formFieldData.mandateReference}
          error={errors.mandateReference}
          rules={{
            maxLength: {
              value: 50,
              message: t("errors.MaxLength", { maxLength: 50 })
            }
          }}
        />
      )}

      <FormFieldController
        control={control}
        data={formFieldData.allowanceSendInvoiceViaMail}
        error={errors.allowanceSendInvoiceViaMail}
      />

      <FormFieldController
        control={control}
        data={formFieldData.automaticSendInvoiceActive}
        error={errors.automaticSendInvoiceActive}
      />

      <FormFieldController
        control={control}
        data={formFieldData.addPriceMeasuringPoint}
        error={errors.addPriceMeasuringPoint}
      />

      {showPriceMeasuringPoint && (
        <ChangeablePricesField
          className="MeasuringPointPrices"
          control={control}
          error={
            isFieldError(errors.measuringPointPrices)
              ? errors.measuringPointPrices
              : undefined
          }
          label={getPriceMeasuringPointLabel(watchAddPriceMeasuringPoint)}
          name="measuringPointPrices"
          unit="EUR/Monat"
        />
      )}

      <FormFieldController
        control={control}
        data={formFieldData.addBasePrice}
        error={errors.addBasePrice}
      />

      {showAddBasePrice && (
        <ChangeablePricesField
          className="BasePrices"
          control={control}
          error={
            isFieldError(errors.basePrices) ? errors.basePrices : undefined
          }
          label={"Monatlicher Grundpreis (netto)"}
          name="basePrices"
          unit="EUR/Monat"
        />
      )}

      <FormFieldController
        control={control}
        data={formFieldData.deposit}
        error={errors.deposit}
        rules={{
          maxLength: {
            value: 50,
            message: t("errors.MaxLength", { maxLength: 50 })
          },
          min: {
            value: 0,
            message: t("errors.ContractForm.DepositMin0", { maxLength: 0 })
          }
        }}
      />

      <FormFieldController
        control={control}
        data={formFieldData.isVatIncluded}
        error={errors.isVatIncluded}
      />

      {showVatExemptionReason && (
        <FormFieldController
          control={control}
          data={formFieldData.vatExemptionReason}
          error={errors.vatExemptionReason}
        />
      )}

      {showCustomVatExemptionReason && (
        <FormFieldController
          control={control}
          data={formFieldData.customVatExemptionReason}
          error={errors.customVatExemptionReason}
        />
      )}

      <ContractFormControls
        buttonContainer={buttonContainer}
        onCancel={handleClickCancel}
        onSubmit={submitCallback}
      />
    </form>
  );
}

export { ContractForm, ContractFormProps };
