import { useState } from "react";

import { Input } from "../../../DynamicForm/FormItems/FormField/Input/Input";

interface TextValueCellProps {
  value: string | null;
  inputGroupText?: string;
  onChange: (value: string) => void;
}

function TextValueCell({
  value,
  inputGroupText,
  onChange
}: TextValueCellProps) {
  const [localValue, setLocalValue] = useState(value);

  function handleChange(newValue: string) {
    setLocalValue(newValue);
  }

  function handleBlurInput() {
    if (localValue !== null) {
      onChange(localValue);
    }
  }

  return (
    <Input
      className="text-value-cell"
      data-testid="text-value-cell"
      inputGroupText={inputGroupText}
      type="text"
      value={localValue}
      onBlur={handleBlurInput}
      onChange={handleChange}
      onKeyPressEnter={handleBlurInput}
    />
  );
}

export { TextValueCell };
