import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { type TodoNote } from "../../../../utils/backend-types";
import { type SiteName } from "../../../../utils/enums";
import { ExternalLink } from "../../../ReactMarkdown/ExternalLink";
import { SYSTEM_USER_NAME } from "../../common";
import { NoteControls } from "./NoteControls";
import { type NoteMutation } from "./Notes.types";
import { NotesEntry } from "./NotesEntry";

declare const SITE_NAME: SiteName;

export function Spacer() {
  return <span className="spacer">|</span>;
}

interface NoteProps {
  note: TodoNote;
  isStaffUser: boolean;
  ownUserId: number;
  onDeleteNote: () => void;
  onUpdateNote: NoteMutation;
  onUpdateNoteDone?: (note: TodoNote) => void;
}

function Note({
  note,
  isStaffUser,
  ownUserId,
  onUpdateNote,
  onUpdateNoteDone,
  onDeleteNote
}: NoteProps) {
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => setEditing(!editing);

  const authorIsSystemUser = note.author.name === SYSTEM_USER_NAME;
  const authorIsCurrentUser = ownUserId === note.author.id;
  const author = authorIsSystemUser ? SITE_NAME : note.author.name;
  const canEdit = authorIsCurrentUser || (isStaffUser && authorIsSystemUser);
  const canDelete = authorIsCurrentUser || (isStaffUser && authorIsSystemUser);
  const showControls = canEdit || canDelete;

  function handleUpdateNoteDone(note: TodoNote) {
    if (onUpdateNoteDone) onUpdateNoteDone(note);
    setEditing(false);
  }

  return (
    <div className="Note" tabIndex={0}>
      {editing ? (
        <NotesEntry
          isStaffUser={isStaffUser}
          note={note}
          submitButtonText="Speichern"
          onCancel={toggleEditing}
          onSaveNote={onUpdateNote}
          onSaveNoteDone={handleUpdateNoteDone}
        />
      ) : (
        <>
          <div className="info">
            <span className="author">{author}</span>
            <Spacer />
            <span className="date">{note.created}</span>
            {note.internal && (
              <>
                <Spacer />
                <span className="internal">Intern</span>
              </>
            )}
            {showControls && (
              <NoteControls
                canDelete={canDelete}
                canEdit={canEdit}
                onDelete={onDeleteNote}
                onEdit={toggleEditing}
              />
            )}
          </div>
          <div className="text">
            <ReactMarkdown
              components={{
                a: ExternalLink
              }}
            >
              {note.note}
            </ReactMarkdown>
          </div>
        </>
      )}
    </div>
  );
}

export { Note };
