import { Paper } from "@mantine/core";
import { useParams } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Paragraph6ContractPage } from "./Paragraph6ContractPage/Paragraph6ContractPage";
import { Paragraph6CreditNumberRangesPage } from "./Paragraph6CreditNumberRangesPage/Paragraph6CreditNumberRangesPage";
import { Paragraph6CreditsPage } from "./Paragraph6CreditsPage/Paragraph6CreditsPage";
import { Paragraph6Nav } from "./Paragraph6Nav/Paragraph6Nav";

export const TABS = {
  CONTRACTS: "vertraege",
  CREDITS: "gutschriften",
  CREDIT_NUMBER_RANGES: "gutschriftennummernkreise",
  PAYMENT_INFORMATION: "zahlungsinformationen"
} as const;

export const DEFAULT_SUBPAGE = TABS.CONTRACTS;

interface Paragraph6ViewProps {
  variantId: number;
}

function Paragraph6View({ variantId }: Paragraph6ViewProps) {
  const { tab: activeTab } = useParams();

  return (
    <PageContent className="Paragraph6View">
      <PageHeader title="§ 6 EEG" />
      <Paragraph6Nav activeTab={activeTab} variantId={variantId} />
      <Paper>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.CONTRACTS}>
            <Paragraph6ContractPage variantId={variantId} />
          </TabPane>
          <TabPane tabId={TABS.CREDITS}>
            <Paragraph6CreditsPage variantId={variantId} />
          </TabPane>
          <TabPane tabId={TABS.CREDIT_NUMBER_RANGES}>
            <Paragraph6CreditNumberRangesPage variantId={variantId} />
          </TabPane>
          <TabPane tabId={TABS.PAYMENT_INFORMATION}>
            <Paragraph6CreditsPage variantId={variantId} />
          </TabPane>
        </TabContent>
      </Paper>
    </PageContent>
  );
}

export { Paragraph6View, Paragraph6ViewProps };
