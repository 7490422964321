import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import type { Meter } from "../../../../SiteSetupAssistant.types";
import { ErrorCell } from "../../TenantTable/ErrorCell/ErrorCell";

export function getMeteringLocationColumn<T extends MRT_RowData>({
  errorText,
  onChange,
  hasCellError
}: {
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof Meter
  ) => boolean | null | undefined;
  errorText?: string | null;
  onChange?: (value: MRT_RowData) => void;
}): MRT_ColumnDef<T> {
  return {
    accessorKey: "metering_location",
    header: "Messlokation",
    mantineEditTextInputProps: ({ row }) => ({
      error: errorText,
      onChange: () => onChange?.(row)
    }),
    Cell: ({ row }) => {
      const hasError = hasCellError(row.index, "metering_location");
      return (
        <div>
          {row.original.metering_location}
          {hasError && <ErrorCell />}
        </div>
      );
    }
  };
}
