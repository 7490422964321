import type { Todo } from "../../../utils/backend-types";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { Mode } from "../common";
import { DeleteTodosButton } from "../DeleteTodosButton/DeleteTodosButton";
import { TodoCalendarIntegration } from "../TodoCalendarIntegration/TodoCalendarIntegration";
import "./TodoControls.scss";

interface TodoControlsProps {
  mode: Mode;
  variantId: number;
  getSelectedAndVisibleTodos: () => Array<Todo>;
  onClickDeleteTodo: (todo: Todo) => Promise<void>;
  onClickDeleteMultipleTodos: (todos: Array<Todo>) => Promise<void>;
  onClickNewTodo: () => void;
  onClickRefreshTodoList: () => void;
}

function TodoControls({
  mode,
  variantId,
  getSelectedAndVisibleTodos,
  onClickDeleteTodo,
  onClickDeleteMultipleTodos,
  onClickNewTodo,
  onClickRefreshTodoList
}: TodoControlsProps) {
  const shouldShowStaffView = useShouldShowStaffView();

  if (!shouldShowStaffView) {
    return null;
  }

  return (
    <div className="TodoControls">
      {mode !== Mode.Onboarding && (
        <DeleteTodosButton
          todos={getSelectedAndVisibleTodos()}
          onDeleteMultipleTodos={onClickDeleteMultipleTodos}
          onDeleteTodo={onClickDeleteTodo}
        />
      )}
      {mode === Mode.Normal && (
        <>
          <Button onClick={onClickRefreshTodoList}>Liste aktualisieren</Button>
          <TodoCalendarIntegration variantId={variantId} />
          <IconButton iconName={IconName.Plus} onClick={onClickNewTodo}>
            Neue Aufgabe
          </IconButton>
        </>
      )}
    </div>
  );
}

export { TodoControls, TodoControlsProps };
