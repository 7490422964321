import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";

import "./StatusSymbol.scss";

export enum StatusSymbolColour {
  Red = "#E81224",
  Yellow = "#FFF100",
  Green = "#16C60C",
  Warning = "#ffb822"
}

interface StatusSymbolProps {
  colour?: string;
  noStatus?: boolean;
}

function StatusSymbol({
  colour = "#000",
  noStatus = false
}: StatusSymbolProps) {
  if (noStatus) {
    return (
      <Icon
        name={IconName.QuestionCircle}
        style={{ color: "#000", fontSize: "24px" }}
      />
    );
  }

  return (
    <span
      className="StatusSymbol"
      data-testid="StatusSymbol"
      style={{
        backgroundColor: colour
      }}
    />
  );
}

export { StatusSymbol };
