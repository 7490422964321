import { Group, Title } from "@mantine/core";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import api from "../../api";
import urls from "../../urls";
import type { Variant } from "../../utils/backend-types";
import { getErrorText } from "../../utils/get-error-text";
import { getTextWidth } from "../../utils/getTextWidth";
import { isApiError } from "../../utils/isApiError";
import type { FormInputData } from "../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../BuildingBlocks/Forms/Controllers/FormFieldController";
import { HelperText } from "../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { Button } from "../Buttons/Button/Button";
import { EditIcon } from "../Buttons/EditIcon";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import "./VariantRename.scss";

interface VariantRenameProps {
  initialVariant: Variant;
}

function VariantRename({ initialVariant }: VariantRenameProps) {
  const [variant, setVariant] = useState(initialVariant);
  const [editMode, setEditMode] = useState(false);
  const [textboxWidthToFitContent, setTextboxWidthToFitContent] = useState(
    getTextWidth(variant.name, "400 13px sans-serif, Arial") + 40
  );

  function activateEditMode() {
    setEditMode(true);
    setTextboxWidthToFitContent(
      getTextWidth(variant.name, "400 13px sans-serif, Arial") + 40
    );
  }

  async function saveChanges(newVariant: Variant) {
    return api
      .patch<Variant>(urls.api.variantDetail(newVariant.id), {
        name: newVariant.name
      })
      .then((response) => {
        setVariant(response.data);
        setEditMode(false);
        updateBreadcrumb(response.data.name);

        return Promise.resolve(response.data);
      });
  }

  function handleClickCancel() {
    setEditMode(false);
  }

  function updateBreadcrumb(variantName: string) {
    const breadcrumb = document.getElementById("breadcrumb-variant");

    if (breadcrumb) {
      breadcrumb.innerHTML = variantName;
    } else {
      Sentry.captureMessage(
        `Variant name breadcrumb could not be updated. Maybe the dom element id changed?`
      );
    }
  }

  if (editMode) {
    return (
      <div className="VariantRename">
        <VariantRenameForm
          inputWidth={textboxWidthToFitContent}
          variant={variant}
          onCancel={handleClickCancel}
          onSubmit={saveChanges}
        />
      </div>
    );
  } else {
    return (
      <Group className="VariantRename" onClick={activateEditMode}>
        <Title fw="normal" order={3} size="h4">
          Variante: {variant.name}
        </Title>
        <EditIcon tooltipText="Namen ändern" />
      </Group>
    );
  }
}

interface VariantRenameFormProps {
  inputWidth: number;
  variant: Variant;
  onCancel: () => void;
  onSubmit: (data: Variant) => Promise<Variant>;
}

function VariantRenameForm({
  inputWidth,
  variant,
  onCancel,
  onSubmit
}: VariantRenameFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm<Variant>({ defaultValues: variant });
  const [loading, setLoading] = useState(false);

  const formData = {
    name: {
      name: "name",
      type: "text",
      label: "Variante:",
      id: "variant-name",
      className: "column-field",
      style: {
        width: `${inputWidth}px`
      }
    }
  } as const satisfies {
    [key in keyof Variant]?: FormInputData<Variant, key>;
  };

  const onValidSubmit: SubmitHandler<Variant> = async (data) => {
    setLoading(true);

    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      if (
        isApiError<Record<keyof Variant, Array<string>>>(error) &&
        error.response?.data &&
        error.response.status === 400
      ) {
        setError("name", {
          type: "custom",
          message: "Dieser Name ist ungültig."
        });
      } else {
        const errorText = getErrorText(error);

        setError("name", {
          type: "custom",
          message: Array.isArray(errorText) ? errorText[0] : errorText
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  return (
    <form className="VariantRenameForm" onSubmit={submitCallback}>
      <div className="inner-form">
        <FormFieldController control={control} data={formData.name} />
        <Button color="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <SpinButton color="primary" spin={loading}>
          Speichern
        </SpinButton>
      </div>
      <div className={classNames("errors", { show: !!errors.name })}>
        <HelperText error={errors.name} />
      </div>
    </form>
  );
}

function VariantRenameLoader({ variantId }: { variantId: number }) {
  const { data: variant, isLoading } = useQuery({
    queryKey: ["variant", { variantId }],
    queryFn: () => fetchVariant(variantId)
  });

  async function fetchVariant(variantId: number) {
    const response = await api.get<Variant>(urls.api.variantDetail(variantId));
    return response.data;
  }

  if (!variant) {
    if (!isLoading) {
      console.error(
        "VariantRename: Variant could not be loaded for ",
        variantId
      );
    }

    return null;
  }

  return <VariantRename initialVariant={variant} />;
}

export { VariantRenameLoader as VariantRename };
