interface ExternalLinkProps {
  children: React.ReactNode;
  href: string;
}

function ExternalLink({ children, href }: ExternalLinkProps) {
  return (
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
}

export { ExternalLink };
