interface AnchorLinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
}

function AnchorLink({ href, className, children }: AnchorLinkProps) {
  return (
    <a className={className} href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
}

export { AnchorLink };
