import { Group } from "@mantine/core";
import { useState } from "react";
import ReactDOM from "react-dom";
import { showToast } from "../../../utils/toast";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { useHistoricalDataImportState } from "../utils/useHistoricaDataImportState";
import { HistoricalDataImport } from "./HistoricalDataImport";

interface HistoricalDataImportWithStateAndControlsProps {
  buttonContainer?: HTMLElement;
  onSubmit: (startDate: string, endDate: string) => Promise<void>;
  onSkip: () => void;
}

function HistoricalDataImportWithStateAndControls({
  buttonContainer,
  onSubmit,
  onSkip
}: HistoricalDataImportWithStateAndControlsProps) {
  const [loading, setLoading] = useState(false);
  const [startDate, endDate, setDates] = useHistoricalDataImportState();

  function handleSubmit() {
    if (!startDate || !endDate) return;

    setLoading(true);
    onSubmit(startDate, endDate)
      .catch((e) => showToast(e))
      .finally(() => setLoading(false));
  }

  function handleClickSkip() {
    onSkip();
  }

  const submitIsDisabled = !startDate || !endDate;

  return (
    <>
      <HistoricalDataImport onDatesChanged={setDates} />
      {buttonContainer && (
        <>
          {ReactDOM.createPortal(
            <Controls
              loading={loading}
              submitIsDisabled={submitIsDisabled}
              onClickImport={handleSubmit}
              onClickSkip={handleClickSkip}
            />,
            buttonContainer
          )}
        </>
      )}
    </>
  );
}

function Controls({
  loading,
  submitIsDisabled,
  onClickSkip,
  onClickImport
}: {
  loading: boolean;
  submitIsDisabled: boolean;
  onClickSkip: () => void;
  onClickImport: () => void;
}) {
  return (
    <Group gap="xs">
      <Button
        className="skip"
        color="brand"
        disabled={loading}
        onClick={onClickSkip}
      >
        Überspringen
      </Button>
      <SpinButton
        color="primary"
        disabled={submitIsDisabled}
        spin={loading}
        onClick={onClickImport}
      >
        Importieren
      </SpinButton>
    </Group>
  );
}

export {
  HistoricalDataImportWithStateAndControls,
  HistoricalDataImportWithStateAndControlsProps
};
