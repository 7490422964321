import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import type { Paragraph6ContractResponse } from "../../Paragraph6.types";
import { Paragraph6ContractModal } from "./Paragraph6ContractModal/Paragraph6ContractModal";
import { Paragraph6GeneratorModal } from "./Paragraph6GeneratorModal/Paragraph6GeneratorModal";

interface Paragraph6ContractModalsProps {
  variantId: number;
  contractId: string | null;
  newContractModalOpen?: boolean;
  newGeneratorModalOpen?: boolean;
  onNewContractModalClose: () => void;
  onNewGeneratorModalClose: () => void;
  templates?: Array<Paragraph6ContractResponse>;
}

function Paragraph6ContractModals({
  variantId,
  contractId,
  newContractModalOpen,
  newGeneratorModalOpen,
  onNewContractModalClose,
  onNewGeneratorModalClose,
  templates
}: Paragraph6ContractModalsProps) {
  return (
    <>
      <Paragraph6ContractModal
        isOpen={newContractModalOpen}
        templates={templates}
        variantId={variantId}
        onClose={onNewContractModalClose}
      />
      {contractId && (
        <Paragraph6GeneratorModal
          contractId={contractId}
          isOpen={newGeneratorModalOpen}
          variantId={variantId}
          onClose={onNewGeneratorModalClose}
        />
      )}
      <Routes>
        <Route
          element={<EditContractHelper variantId={variantId} />}
          path=":contractId"
        />
        <Route
          element={
            contractId && (
              <EditGeneratorHelper
                contractId={contractId}
                variantId={variantId}
              />
            )
          }
          path="erzeuger-bearbeiten/:generatorShareId"
        />
      </Routes>
    </>
  );
}
interface EditContractHelperProps {
  variantId: number;
}

function EditContractHelper({ variantId }: EditContractHelperProps) {
  const navigate = useNavigate();
  const { contractId } = useParams();

  return (
    <Paragraph6ContractModal
      contractId={contractId}
      isOpen
      variantId={variantId}
      onClose={() => navigate("../")}
    />
  );
}

interface EditGeneratorHelperProps {
  variantId: number;
  contractId: string;
}

function EditGeneratorHelper({
  variantId,
  contractId
}: EditGeneratorHelperProps) {
  const navigate = useNavigate();
  const { generatorShareId } = useParams();

  return (
    <Paragraph6GeneratorModal
      contractId={contractId}
      generatorShareId={
        generatorShareId ? parseInt(generatorShareId) : undefined
      }
      isOpen
      variantId={variantId}
      onClose={() => navigate("../")}
    />
  );
}

export { Paragraph6ContractModals };
