import type { ComboboxData } from "@mantine/core";
import {
  type MeterMeasurementType,
  ConsumerType,
  type components,
  type MeteringDirection,
  type SiteSetupProcessDataConnection_meter_energy_data_origin,
  type SiteSetupProcessDataStep
} from "../../types/api.types";
import { getLabelFromConsumerType } from "./utils/getLabelFromConsumerType";

export type SiteSetupProcessRaw = components["schemas"]["SiteSetupProcessData"];
export type VoltageLevel = components["schemas"]["VoltageLevel"];
export type Address = components["schemas"]["AddressInfo"];
export type SupplyType = components["schemas"]["SupplyType"];
export type Meter = components["schemas"]["Meter"];
export type PVPlant = components["schemas"]["PVPlant"];
export type PVPlantWithMeter = components["schemas"]["PVPlant"] & {
  meter: Exclude<Meter, null>;
};
export type Consumer = components["schemas"]["Consumer"];

export type BatteryStorage = components["schemas"]["BatteryStorage"];
export type Operator = components["schemas"]["Operator"];
export type AddressInfo = components["schemas"]["AddressInfo"];

export interface TenantTableData {
  name: components["schemas"]["Consumer"]["name"];
  tenantAddress: components["schemas"]["Tenant"]["address"];
  tenantName: components["schemas"]["Tenant"]["name"];
  type: components["schemas"]["Consumer"]["consumer_type"];
  billingAddressIsSameAsAddress: components["schemas"]["Tenant"]["billing_address_is_same_as_address"];
  isParticipatingInMieterstrom: components["schemas"]["Tenant"]["is_participating_in_mieterstrom"];
  meter: Meter;
}
// frontend-only
export type LocationType = "Messlokation" | "Marktlokation" | "Keine";
export type MeterWithSource = Meter & {
  source: "pvPlant" | "consumer" | "connection" | "batteryStorage";
  parentIndex: number;
};

export interface SiteSetupProcessForForm {
  step?: SiteSetupProcessDataStep;

  /* site */
  name: string;
  pvPlants: Array<PVPlantWithMeter>;
  siteAddress?: string | null;
  siteZip?: string | null;
  siteCity?: string | null;
  siteSupplyType?: SupplyType | null;
  connectionVoltageLevel?: VoltageLevel | null;
  consumers: Array<Consumer>;
  batteryStorage?: BatteryStorage | null;
  gridOperator?: number | null;

  /* connection meter (in site) */
  connectionName: string;
  connectionMeasurementType?: MeterMeasurementType | null;
  connectionMeteringDirection?: MeteringDirection | null;
  connectionMeteringServiceProvider?: number | null;
  connectionLocationType: LocationType; // frontend-only
  connectionMeteringLocation?: string | null;
  connectionMarketLocationFeedin?: string | null;
  connectionMarketLocationFeedout?: string | null;
  connectionNumber?: string | null;
  connectionIsCalibrated?: boolean | null;

  defaultPvPlantsMeasurementType?: MeterMeasurementType | null;
  connectionMeterEnergyDataOrigin?: SiteSetupProcessDataConnection_meter_energy_data_origin | null;
  voltageLevelDeterminedAutomatically?: boolean | null;
  gridOperatorDeterminedAutomatically?: boolean | null;
  consumerAmount?: number | null;
  defaultConsumerType?: ConsumerType | null;
  defaultConsumerMeasurementType?: MeterMeasurementType | null;
  allTenantAddressesAreSameAsSiteAddress?: boolean | null;
  allTenantsParticipatingInMieterstrom?: boolean | null;
  siteHasBatteryStorage?: boolean | null;
  isBatteryStorageMeasuredSeparately?: boolean | null;
}

export const CONSUMER_TYPE_CHOICES: ComboboxData = [
  {
    value: ConsumerType.Industry,
    label: getLabelFromConsumerType(ConsumerType.Industry)
  },
  {
    value: ConsumerType.PrivateHousehold,
    label: getLabelFromConsumerType(ConsumerType.PrivateHousehold)
  },
  {
    value: ConsumerType.NonProducingIndustry,
    label: getLabelFromConsumerType(ConsumerType.NonProducingIndustry)
  },
  {
    value: ConsumerType.PublicProperty,
    label: getLabelFromConsumerType(ConsumerType.PublicProperty)
  },
  {
    value: ConsumerType.Agriculture,
    label: getLabelFromConsumerType(ConsumerType.Agriculture)
  },
  {
    value: ConsumerType.ElectricChargingStation,
    label: getLabelFromConsumerType(ConsumerType.ElectricChargingStation)
  },
  {
    value: ConsumerType.HeatPump,
    label: getLabelFromConsumerType(ConsumerType.HeatPump)
  },
  {
    value: ConsumerType.NightStorageHeating,
    label: getLabelFromConsumerType(ConsumerType.NightStorageHeating)
  },
  {
    value: ConsumerType.Other,
    label: getLabelFromConsumerType(ConsumerType.Other)
  }
];

type Primitive = string | number | bigint | boolean;
export type FormatToErrorObject<T> = T extends Primitive
  ? Array<string>
  : {
      [P in keyof T]: FormatToErrorObject<T[P]>;
    };

export type TenantTableErrors = Array<
  FormatToErrorObject<Omit<TenantTableData, "meter">>
>;

export type MeterTableErrors = Array<FormatToErrorObject<Meter>>;

export const STEP_TITLE = {
  step1: "Beginnen wir mit den Erzeugungsanlagen Ihrer Liegenschaft",
  step2: "Lassen Sie uns Ihre Liegenschaft konfigurieren",
  step3: "Diesen Netzverknüpfungspunkt gibt es in Ihrer Liegenschaft",
  step4: "Diese Verbraucher gibt es in Ihrer Liegenschaft",
  step5: "Welche Mieter beziehen Strom von Ihnen?",
  step6: "Welche Zähler befinden sich in Ihrer Liegenschaft?"
};

export const NAME_AND_PVP_PLANTS_FIELDS = [
  "name",
  "pvPlants",
  "defaultPvPlantsMeasurementType"
];

export const ADDRESS_FORM_FIELDS = [
  "siteAddress",
  "siteZip",
  "siteCity",
  "siteSupplyType"
];

export const CONNECTION_FORM_FIELDS = [
  "connectionVoltageLevel",
  "connectionMeasurementType",
  "connectionMeteringDirection",
  "connectionMeteringServiceProvider",
  "connectionLocationType",
  "connectionMarketLocationFeedin",
  "connectionMarketLocationFeedout",
  "connectionMeteringLocation",
  "gridOperator"
];

export const CONSUMER_FORM_FIELDS = [
  "siteHasBatteryStorage",
  "isBatteryStorageMeasuredSeparately",
  "consumerAmount",
  "defaultConsumerType",
  "defaultConsumerMeasurementType"
];
