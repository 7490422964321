import { useState, useCallback, useMemo } from "react";

import type { ApiError } from "../../../../../api";
import { useFileOrigins } from "../../../../../hooks/useFileOrigins";
import { getFieldSpecificError } from "../../../../../utils/api-utils";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../Buttons/Button/Button";
import { FormItem } from "../../../../DynamicForm/FormItem/FormItem";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { FormFieldType } from "../../../../DynamicForm/FormItems/FormField/FormField";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { retryUploadWithOriginRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { GeneralImportDetailsList } from "../../EnergyDataImportDetailsModal/GeneralImportDetailsList";
import { useDataImportRequestDetails } from "../../hooks/useDataImportRequestDetails";

import "../RetryModal.scss";

const NOT_PROVIDED_CHOICE = { value: "notProvided", displayName: "Anderes" };
const DEFAULT_INPUT_ERROR = "Bitte mindestens zwei Zeichen eingeben";
const LENGTH_INPUT_ERROR = "Bitte geben Sie höchsten 200 Zeichen ein";

const isValidInput = (value: string | null) =>
  !!value && value.trim().length >= 2;

const isOverLengthInput = (value: string | null) =>
  !!(value && value.trim().length > 200);

interface OriginModalProps {
  dataImportRequest: EnergyDataImportRequest;
  onClose: () => void;
  onRetryUploadDone: (dataImportRequest: EnergyDataImportRequest) => void;
}

function OriginModal({
  dataImportRequest,
  onClose,
  onRetryUploadDone
}: OriginModalProps) {
  const { dataImportRequestWithDetails, isLoading } =
    useDataImportRequestDetails(dataImportRequest.id);

  const {
    origins,
    isLoading: isOriginsLoading,
    error: originsError
  } = useFileOrigins();

  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [newOriginValue, setNewOriginValue] = useState("");
  const [isShowInput, setIsShowInput] = useState(false);
  const [inputErrorTexts, setInputErrorTexts] = useState<string[]>([]);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  const originChoices = useMemo(
    () => [
      NOT_PROVIDED_CHOICE,
      ...origins.map((origin) => ({
        value: origin.id,
        displayName: origin.name
      }))
    ],
    [origins]
  );

  const handleChangeOriginOption = useCallback((_, value: string) => {
    if (value === NOT_PROVIDED_CHOICE.value) {
      setSelectedOrigin("");
      setIsShowInput(true);
      setSubmitIsDisabled(false);
    } else {
      setInputErrorTexts([]);
      setIsShowInput(false);
      setNewOriginValue("");

      if (value) {
        setSelectedOrigin(value);
        setSubmitIsDisabled(false);
      }
    }
  }, []);

  const handleChangeOriginInput = useCallback((_, value: string) => {
    setInputErrorTexts([]);
    setNewOriginValue(value);

    if (isValidInput(value) && !isOverLengthInput(value)) {
      setSubmitIsDisabled(false);
    }
  }, []);

  const showInputIfInvalidOriginValue = useCallback((errorMsg: string) => {
    setInputErrorTexts([errorMsg]);
    setSubmitIsDisabled(true);
  }, []);

  const handleRetryUploadWithOrigin = useCallback(() => {
    if (selectedOrigin) {
      setSubmitIsDisabled(false);
    } else if (isOverLengthInput(newOriginValue)) {
      showInputIfInvalidOriginValue(LENGTH_INPUT_ERROR);
      return;
    } else if (!isValidInput(newOriginValue)) {
      showInputIfInvalidOriginValue(DEFAULT_INPUT_ERROR);
      return;
    }

    retryUploadWithOriginRequest(
      dataImportRequest.id,
      selectedOrigin,
      newOriginValue
    )
      .then((response) => {
        onRetryUploadDone(response.data);
      })
      .catch((e: ApiError) => {
        const errorMessage = getFieldSpecificError(e, ["fileOriginId"]);

        openErrorAlertPopup(
          e,
          errorMessage ? { [e.response?.status as number]: errorMessage } : {}
        );
      })
      .finally(() => {
        onClose();
      });
  }, [
    dataImportRequest.id,
    selectedOrigin,
    newOriginValue,
    onClose,
    onRetryUploadDone,
    showInputIfInvalidOriginValue
  ]);

  return (
    <Modal className="OriginModalModal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Importdetails: {dataImportRequest.energyDataFile.name}
      </ModalHeader>
      <ModalBody className="details">
        <GeneralImportDetailsList
          generalDetails={
            isLoading ? dataImportRequest : dataImportRequestWithDetails
          }
        />
      </ModalBody>
      <ModalBody>
        <p>
          Leider konnte nicht automatisch erkannt werden aus welchen Quellsystem
          die Datei stammt. Bitte wählen Sie das Quellsystem (das System aus dem
          die Datei exportiert wurde) aus. Dies hilft den Import zu ermöglichen
          oder zumindest mögliche Fehlerquellen einzugrenzen.
        </p>
        <LoadOrError error={originsError} loading={isOriginsLoading}>
          <TsDropdown
            choices={originChoices}
            defaultValue=""
            id="raw-file-origins-data"
            name="raw-file-origins-data"
            required
            onChange={handleChangeOriginOption}
          />
          {isShowInput && (
            <form className="FormItems">
              <FormItem
                allowInput
                errors={inputErrorTexts}
                helpText="Bitte geben Sie den Namen des Systems ein von dem Sie die Datei bezogen haben."
                label="Name des Quellsystems*"
                name="newOriginValue"
                type={FormFieldType.String}
                value={newOriginValue}
                onInput={handleChangeOriginInput}
              />
            </form>
          )}
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="secondary" onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={submitIsDisabled}
            onClick={handleRetryUploadWithOrigin}
          >
            Wiederholen
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { OriginModal };
