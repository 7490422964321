import { numericFormatter } from "react-number-format";

export const formatEnergyValue = (value: number): string => {
  if (value >= 10000000) {
    return numericFormatter((value / 1000000).toFixed(1), {
      decimalSeparator: ",",
      thousandSeparator: ".",
      suffix: " GWh"
    });
  } else if (value >= 10000) {
    return numericFormatter((value / 1000).toFixed(1), {
      decimalSeparator: ",",
      thousandSeparator: ".",
      suffix: " MWh"
    });
  } else {
    return numericFormatter(value.toString(), {
      decimalSeparator: ",",
      thousandSeparator: ".",
      suffix: " kWh"
    });
  }
};
