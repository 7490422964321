import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { ApiError } from "../../../../api";
import { MANAGER_URL } from "../../../../routes";
import type { Project } from "../../../../utils/backend-types";
import { showToast } from "../../../../utils/toast";
import {
  Action,
  ActionConfirmationModal,
  ActionObject
} from "../../../BuildingBlocks/Layout/Modals/ActionConfirmationModal/ActionConfirmationModal";
import { Button } from "../../../Buttons/Button/Button";

interface DeleteProjectWidgetProps {
  project?: Project;
  showStaffView?: boolean;
}

function DeleteProjectWidget({
  project,
  showStaffView
}: DeleteProjectWidgetProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  function handleClickDeleteProject() {
    setIsModalOpen(true);
  }

  function handleCloseActionModal() {
    setIsModalOpen(false);
  }

  function handleActionSuccess() {
    navigate(`${MANAGER_URL}dashboard/`);
  }

  function handleError(error: ApiError) {
    showToast("error", error);
  }

  if (!showStaffView) {
    return null;
  }

  return (
    <>
      <Button
        color="danger"
        disabled={!project}
        onClick={handleClickDeleteProject}
      >
        Projekt löschen
      </Button>
      {isModalOpen && project && (
        <ActionConfirmationModal
          action={Action.Delete}
          object={ActionObject.Project}
          project={project}
          onActionSuccess={handleActionSuccess}
          onClose={handleCloseActionModal}
          onError={handleError}
        />
      )}
    </>
  );
}

export { DeleteProjectWidget, DeleteProjectWidgetProps };
