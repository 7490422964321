import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HTTP_METHODS, useDownload } from "../../../../../hooks/useDownload";
import urls from "../../../../../urls";
import { showToast } from "../../../../../utils/toast";
import { buttonColors } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { isDataStringResponse } from "../../../../EdaVisualizationModal/CreateEdaVersionModal/CreateEdaVersionModal";

interface FeedinDownloadButtonProps {
  contractId: string | null;
  disabled?: boolean;
  startDate: string | null;
  endDate: string | null;
}

function FeedinDownloadButton({
  contractId,
  disabled,
  startDate,
  endDate
}: FeedinDownloadButtonProps) {
  const { t } = useTranslation();
  const { download, isLoading, error } = useDownload();
  let errorMessage = "";

  if (error) {
    if (isDataStringResponse(error)) {
      errorMessage = error.response.data;
    } else {
      errorMessage = t("errors.ServerError");
      console.error(error);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage);
    }
  }, [errorMessage]);

  function handleClickDownload() {
    if (!contractId || !startDate || !endDate) {
      return;
    }

    download({
      downloadUrl: urls.api.exportParkabrechnungFeedinProceeds(
        contractId,
        startDate,
        endDate
      ),
      options: {
        method: HTTP_METHODS.GET
      }
    });
  }

  return (
    <SpinButton
      color={buttonColors.brand}
      disabled={disabled || !contractId || !startDate || !endDate}
      spin={isLoading}
      onClick={handleClickDownload}
    >
      Herunterladen
    </SpinButton>
  );
}

export { FeedinDownloadButton, FeedinDownloadButtonProps };
