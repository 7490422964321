import { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { DeliveryConstraints } from "../DeliveryConstraints/DeliveryConstraints";
import { useDeliveryConstraints } from "../DeliveryConstraints/useDeliveryConstraints";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { IconHelpText } from "../IconHelpText/IconHelpText";
import { LoadOrError } from "../LoadOrError/LoadOrError";

import "./DeliveryConstraintsModal.scss";

declare const SITE_NAME: string;

interface DeliveryConstraintsModalProps {
  siteId: number;
  onCloseDeliveryConstraintsModal: () => void;
}

export default function DeliveryConstraintsModal({
  siteId,
  onCloseDeliveryConstraintsModal
}: DeliveryConstraintsModalProps) {
  const {
    isLoading,
    error,
    deliveryConstraints,
    saveDeliveryConstraints,
    updateLocalDeliveryConstraint
  } = useDeliveryConstraints(siteId);
  const [isSaving, setIsSaving] = useState(false);

  function handleClickSave() {
    setIsSaving(true);
    saveDeliveryConstraints()
      .then(() => {
        setIsSaving(false);
        onCloseDeliveryConstraintsModal();
      })
      .catch((error) => {
        openErrorAlertPopup(error);
        setIsSaving(false);
      });
  }

  return (
    <div id="delivery-constraints">
      <Modal isOpen size="xl" toggle={onCloseDeliveryConstraintsModal}>
        <ModalHeader>
          <DeliveryConstraintTitle />
        </ModalHeader>
        <ModalBody scrollable>
          <LoadOrError error={error} loading={isLoading}>
            {deliveryConstraints && (
              <DeliveryConstraints
                deliveryConstraints={deliveryConstraints}
                onChange={updateLocalDeliveryConstraint}
              />
            )}
          </LoadOrError>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCloseDeliveryConstraintsModal}>
            Abbrechen
          </Button>
          <SpinButton color="primary" spin={isSaving} onClick={handleClickSave}>
            Speichern und Schließen
          </SpinButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}

interface DeliveryConstraintTitleProps {
  create?: boolean;
}

export function DeliveryConstraintTitle({
  create = false
}: DeliveryConstraintTitleProps) {
  const titleText = create
    ? "Lieferbeziehungen einstellen"
    : "Lieferbeziehungen bearbeiten";

  return (
    <span>
      <span
        className="delivery-constraints-header-title"
        id={"delivery-constraint-title"}
      >
        {titleText}
      </span>
      <DeliveryConstraintHelpText />
    </span>
  );
}

export function DeliveryConstraintHelpText() {
  const text = `Im Rahmen der finanziellen Bewertung erstellt ${SITE_NAME} automatisch ein finanziell optimiertes Bilanzierungskonzept für die in der jeweiligen Variante enthaltenen Erzeuger, Verbraucher und ggf. Speicher. ${SITE_NAME} bestimmt also die Reihenfolge und Priorisierung, nach der dezentral erzeugter Strom entweder vor Ort genutzt (Eigenverbrauch oder Direktlieferung) oder ins öffentliche Netz eingespeist werden sollte. Wenn Sie „Belieferung in Optimierung berücksichtigen“ deaktivieren, werden die betreffenden Lieferbeziehungen im Rahmen der Optimierung nicht mehr berücksichtigt.`;

  return <IconHelpText helpText={text} popoverPosition={"bottom"} />;
}
