import { useCallback, useEffect, useState } from "react";

import type { FormValues } from "../../CustomForm/useCustomForm";
import { StructureViewCreationDropdown } from "./FormField/StructureViewCreationDropdown/StructureViewCreationDropdown";
import type FormItemsType from "./FormItems";
import type { FormItemsProps } from "./FormItems";

type FormItemsWithCreatableDropdown = typeof FormItemsType;

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function withCreatableDropdown(
  FormItems: typeof FormItemsType | FormItemsWithCreatableDropdown,
  fieldName: string,
  postUrl: string,
  putUrlFunc: (...args) => string,
  includedFields: Array<string>,
  nonFieldData: FormValues,
  preferredChoiceUrl?: string
) {
  const WrappedFormItemsWithCreatableDropdownField = ({
    formItems,
    formName,
    formValues,
    formItemsWithPortals,
    formErrors,
    allowInput,
    onInput,
    onFormItemNodeChange,
    ...otherProps
  }: FormItemsProps) => {
    const getInitialFormItemsWithPortals = useCallback(() => {
      return [...(formItemsWithPortals || []), fieldName];
    }, [formItemsWithPortals]);
    const [localFormItemsWithPortals, setLocalFormItemsWithPortals] = useState(
      getInitialFormItemsWithPortals()
    );
    const [formItemNode, setFormItemNode] = useState<HTMLElement | null>(null);

    useEffect(() => {
      setLocalFormItemsWithPortals((currentLocalFormItemsWithPortals) => {
        if (!currentLocalFormItemsWithPortals.includes(fieldName)) {
          return getInitialFormItemsWithPortals();
        }

        return currentLocalFormItemsWithPortals;
      });
    }, [getInitialFormItemsWithPortals]);

    function handleFormItemNodeChange(formItemName: string, node: HTMLElement) {
      if (formItemName === fieldName) {
        setFormItemNode(node);
      } else if (onFormItemNodeChange) {
        onFormItemNodeChange(formItemName, node);
      }
    }

    const errors = formErrors[fieldName];
    const formItemData = formItems.find(
      (formItem) => formItem.name === fieldName
    );
    const putUrl = putUrlFunc(formValues[fieldName]);
    const value = formValues[fieldName];

    return (
      <div className="CreatableDropdownFormWrapper">
        <FormItems
          {...otherProps}
          allowInput={allowInput}
          formErrors={formErrors}
          formItems={formItems}
          formItemsWithPortals={localFormItemsWithPortals}
          formValues={formValues}
          onFormItemNodeChange={handleFormItemNodeChange}
          onInput={onInput}
        />
        <StructureViewCreationDropdown
          allowInput={allowInput}
          errors={errors}
          fieldName={fieldName}
          formItemData={formItemData}
          formItemNode={formItemNode}
          formName={formName}
          includedFields={includedFields}
          nonFieldData={nonFieldData}
          postUrl={postUrl}
          preferredChoiceUrl={preferredChoiceUrl}
          putUrl={putUrl}
          value={value}
          onInput={onInput}
        />
      </div>
    );
  };

  return WrappedFormItemsWithCreatableDropdownField;
}

export { withCreatableDropdown };
