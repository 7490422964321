import { Accordion, Paper } from "@mantine/core";
import type { DatesRangeValue } from "@mantine/dates";
import { MonthPickerInput } from "@mantine/dates";
import { IconExclamationCircle } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useVariantSites } from "../../../../hooks/useVariantSites";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { EARLIEST_YEAR } from "../../common";
import { useRawEnergyDataPageState } from "../hooks/useRawEnergyDataPageState";
import { RawEnergyDataSite } from "./RawEnergyDataSite/RawEnergyDataSite";
import "./RawEnergyDataPage.scss";

const MIN_DATE_RANGE = new Date(EARLIEST_YEAR, 0, 1);
const MAX_DATE_RANGE = DateTime.now().endOf("month").toJSDate();

interface RawEnergyDataPageProps {
  variantId: number;
}

function RawEnergyDataPage({ variantId }: RawEnergyDataPageProps) {
  const { pageState, setPageState } = useRawEnergyDataPageState();
  const [activeAccordionPanel, setActiveAccordionPanel] = useState<
    string | null | undefined
  >();
  const [intialPanelDefined, setInitialPanelDefined] = useState(false);

  const {
    sites,
    isLoading: isSitesLoading,
    error: sitesError
  } = useVariantSites(variantId);

  useEffect(() => {
    if (sites.length === 1) {
      setActiveAccordionPanel(sites[0].id.toString());
    }
  }, [sites]);

  function handleDatePickerChange(dateRange: DatesRangeValue) {
    // only react if a valid & complete range is set
    if (dateRange[0] && dateRange[1]) {
      const firstDate = DateTime.fromJSDate(dateRange[0]);
      const lastDate = DateTime.fromJSDate(dateRange[1]).endOf("month");
      if (firstDate.isValid && lastDate.isValid) {
        setPageState((state) => {
          return {
            ...state,
            firstDate,
            lastDate
          };
        });
      }
    }
  }

  function handleIdChange(selectedId: string) {
    setPageState((state) => {
      return {
        ...state,
        selectedId
      };
    });
  }

  function handleSiteHasSelectedId(siteId: number) {
    if (!intialPanelDefined) {
      setActiveAccordionPanel(siteId.toString());
      setInitialPanelDefined(true);

      // signal to the caller that a panel change was implemented
      return true;
    }

    return false;
  }

  return (
    <LoadOrError error={sitesError} loading={isSitesLoading}>
      <div className="RawEnergyDataPage">
        {sites.length > 0 ? (
          <>
            <Paper mb="md">
              <MonthPickerInput
                allowSingleDateInRange
                className="date-range-input"
                defaultValue={[
                  pageState.firstDate.toJSDate(),
                  pageState.lastDate.toJSDate()
                ]}
                label="Zeitraum"
                maxDate={MAX_DATE_RANGE}
                minDate={MIN_DATE_RANGE}
                type="range"
                onChange={handleDatePickerChange}
              />
            </Paper>
            <Accordion
              value={activeAccordionPanel}
              onChange={setActiveAccordionPanel}
            >
              {sites.map((site) => (
                <RawEnergyDataSite
                  key={site.id}
                  selectedDateRange={[pageState.firstDate, pageState.lastDate]}
                  selectedId={pageState.selectedId}
                  siteId={site.id}
                  siteName={site.name}
                  onIdChanged={handleIdChange}
                  onSiteHasSelectedId={handleSiteHasSelectedId}
                />
              ))}
            </Accordion>
          </>
        ) : (
          <Alert color={AlertColor.Warning} icon={<IconExclamationCircle />}>
            Es sind noch keine Liegenschaften konfiguriert.
          </Alert>
        )}
      </div>
    </LoadOrError>
  );
}

export { RawEnergyDataPage, RawEnergyDataPageProps };
