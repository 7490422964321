import { Paper } from "@mantine/core";
import { PureComponent } from "react";
import { Col, Row } from "reactstrap";
import { DecimalLabel } from "../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { Button } from "../../Buttons/Button/Button";
import { PVInvestmentCalculationButton } from "./PVInvestmentCalculationButton/PVInvestmentCalculationButton";
import VariantPublishButton from "./VariantPublishButton";

export default class SimulationComparisonHeader extends PureComponent {
  renderHeaders = () => {
    return this.props.variantIds.map((key) => {
      const config = this.props.meteringConceptConfiguration[key];
      return (
        <Col
          className="col-header m--font-light"
          key={key}
          style={{ backgroundColor: config.headerBackgroundColor }}
        >
          <div>
            <span />
            <span className="header-center">{config.header}</span>
            <span />
          </div>
        </Col>
      );
    });
  };

  renderRow = (aggregateKey, labelConfiguration, yearlyAggregates) => {
    return this.props.variantIds.map((key) => {
      const value = yearlyAggregates[key][aggregateKey];
      const backgroundColor =
        this.props.meteringConceptConfiguration[key].cellBackgroundColor;
      return (
        <Col
          className={`m--align-center background-color--${backgroundColor}`}
          key={key}
        >
          <div className="m-2">
            <DecimalLabel value={value} {...labelConfiguration} />
          </div>
        </Col>
      );
    });
  };

  computeReferenceValues = (
    yearlyAggregates,
    additionalInstalledPower,
    referenceVariantId
  ) => {
    const referenceCosts = yearlyAggregates[referenceVariantId].totalCosts;
    return Object.keys(yearlyAggregates).reduce((obj, variantId) => {
      let savings = referenceCosts - yearlyAggregates[variantId].totalCosts;
      const installedPower = additionalInstalledPower[variantId];
      let advantagePerKwP = installedPower > 0 ? savings / installedPower : 0;
      savings = this.roundSmallValuesToZero(savings);
      advantagePerKwP = this.roundSmallValuesToZero(advantagePerKwP);
      obj[variantId] = Object.assign(yearlyAggregates[variantId], {
        savings,
        advantagePerKwP,
        installedPower
      });
      return obj;
    }, {});
  };

  roundSmallValuesToZero = (value, threshold = 1e-5) => {
    return Math.abs(value) < threshold ? 0 : value;
  };

  renderDeleteButtons = () => {
    return this.props.variantIds.map((key, index) => {
      if (index < 1) {
        return <Col key={key} />;
      }
      return (
        <Col className="py-1" key={key}>
          <Button block onClick={() => this.props.removeScenario(key)}>
            Ausblenden
          </Button>
        </Col>
      );
    });
  };

  renderCalculatePVInvestmentButton = () => {
    return this.props.variantIds.map((variantId, index) => {
      if (index < 1) {
        return <Col key={variantId} />;
      }
      const scenario = this.props.scenarios.find(
        (s) => s.variant.id === variantId
      );
      return (
        <Col className="py-1" key={variantId}>
          <PVInvestmentCalculationButton scenarioId={scenario.id} />
        </Col>
      );
    });
  };

  renderPublishVariantButtons = () => {
    return this.props.variantIds.map((variantId, index) => {
      if (index < 1) {
        return <Col key={variantId} />;
      }
      return (
        <Col className="py-1" key={variantId}>
          <VariantPublishButton variantId={variantId} />
        </Col>
      );
    });
  };

  render() {
    const { variantIds, yearlyAggregates, additionalInstalledPower } =
      this.props;
    const minWidth = `${320 * variantIds.length}px`;
    const referenceVariantId = variantIds[0];
    const yearlyAggegatesWithComputedReferenceValues =
      this.computeReferenceValues(
        yearlyAggregates,
        additionalInstalledPower,
        referenceVariantId
      );

    const configuration = [
      {
        rowLabel: "Gesamterlöse / -kosten",
        variable: "totalCosts",
        labelConfiguration: {
          suffix: " €",
          decimalScale: 0,
          convert: (value) => -value,
          fontDangerIfNegative: true
        }
      },
      {
        rowLabel: "Vorteil zu Basiskonzept",
        variable: "savings",
        labelConfiguration: {
          suffix: " €",
          decimalScale: 0,
          fontDangerIfNegative: true
        }
      },
      {
        rowLabel: "Lieferungen innerhalb der Kundenanlage",
        variable: "localDelivery",
        labelConfiguration: {
          suffix: " kWh",
          decimalScale: 0
        }
      },
      // TODO currently the calculation for localShare is incorrect and therefore it is not shown
      // {
      //   rowLabel: "Lokalversorgungsanteil",
      //   variable: "localShare",
      //   labelConfiguration: {
      //     suffix: " %",
      //     decimalScale: 1,
      //     convert: (value) => 100 * value
      //   }
      // },
      {
        rowLabel: "Neue Nettonennleistung",
        variable: "installedPower",
        labelConfiguration: {
          suffix: " kW",
          decimalScale: 0
        }
      },
      {
        rowLabel: "Spezifischer Vorteil zu Basiskonzept pro installiertem kWp",
        variable: "advantagePerKwP",
        labelConfiguration: {
          suffix: " € pro Jahr",
          decimalScale: 0,
          fontDangerIfNegative: true
        }
      }
    ];
    return (
      <Row style={{ minWidth }}>
        <Col>
          <Paper
            mb={this.props.formatForReport ? undefined : "md"}
            p={this.props.formatForReport ? 0 : undefined}
          >
            <Row>
              <Col style={{ fontSize: "15px" }}>
                <Row>
                  <Col className="col-header m--bg-brand m--font-light m--font-bolder m--align-center">
                    <div className="m-2">Zusammenfassung</div>
                  </Col>
                  {this.renderHeaders()}
                </Row>
                {configuration.map((configurationObject) => {
                  return (
                    <Row key={configurationObject.variable}>
                      <Col>{configurationObject.rowLabel}</Col>
                      {this.renderRow(
                        configurationObject.variable,
                        configurationObject.labelConfiguration,
                        yearlyAggegatesWithComputedReferenceValues
                      )}
                    </Row>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col />
              {this.renderDeleteButtons()}
            </Row>
            <Row>
              <Col />
              {this.renderPublishVariantButtons()}
            </Row>
            <Row>
              <Col />
              {this.renderCalculatePVInvestmentButton()}
            </Row>
          </Paper>
        </Col>
      </Row>
    );
  }
}
