import { useState, useCallback, useMemo } from "react";

import type { ApiError } from "../../../../../api";
import { getFieldSpecificError } from "../../../../../utils/api-utils";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../Buttons/Button/Button";
import type { Choice } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import type {
  EnergyDataImportRequest,
  EnergyDataImportRequestDetails
} from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { retryUploadWithParameterSetRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { GeneralImportDetailsList } from "../../EnergyDataImportDetailsModal/GeneralImportDetailsList";
import { EnergyDataImportDetailsTable } from "../../EnergyDataImportDetailsTable/EnergyDataImportDetailsTable";
import { useDataImportRequestDetails } from "../../hooks/useDataImportRequestDetails";

import "./ParameterSetModal.scss";

interface Props {
  dataImportRequest: EnergyDataImportRequest;
  onClose: () => void;
  onRetryUploadDone: (dataImportRequest: EnergyDataImportRequest) => void;
}

function ParameterSetModal({
  dataImportRequest,
  onClose,
  onRetryUploadDone
}: Props) {
  const { dataImportRequestWithDetails, isLoading, error } =
    useDataImportRequestDetails(dataImportRequest.id);

  const [parameterSetId, setParameterSetId] = useState<string | null>(null);
  const [matchedDetails, setMatchedDetails] = useState<
    EnergyDataImportRequestDetails[]
  >([]);

  const parameterSetOptions = useMemo(
    () =>
      dataImportRequestWithDetails?.details.reduce<Choice[]>(
        (acc, { parameterSet: { id, name, originName } = {} }) => {
          if (acc.find(({ value }) => value === id)) {
            return acc;
          }

          return [
            ...acc,
            {
              value: id || "",
              displayName: originName ? `${originName}: ${name}` : name || ""
            }
          ];
        },
        []
      ),
    [dataImportRequestWithDetails?.details]
  );

  const onSelectParameterSet = useCallback(
    (_, value: string) => {
      const selectedParameterSet = value ?? null;
      setParameterSetId(selectedParameterSet);

      setMatchedDetails(
        dataImportRequestWithDetails?.details.filter(
          ({ parameterSet: { id } = {} }) => id === selectedParameterSet
        )
      );
    },
    [dataImportRequestWithDetails?.details]
  );

  const handleRetryUpload = useCallback(() => {
    retryUploadWithParameterSetRequest(dataImportRequest.id, parameterSetId)
      .then((response) => {
        onRetryUploadDone(response.data);
      })
      .catch((e: ApiError) => {
        const errorMessage = getFieldSpecificError(e, ["parameterSetId"]);

        openErrorAlertPopup(
          e,
          errorMessage ? { [e.response?.status as number]: errorMessage } : {}
        );
      })
      .finally(() => {
        onClose();
      });
  }, [dataImportRequest.id, onClose, onRetryUploadDone, parameterSetId]);

  return (
    <Modal className="parameter-set-modal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Importdetails: {dataImportRequest.energyDataFile.name}
      </ModalHeader>
      <ModalBody className="details">
        <GeneralImportDetailsList
          generalDetails={
            isLoading ? dataImportRequest : dataImportRequestWithDetails
          }
        />
      </ModalBody>
      <ModalBody>
        <p>
          Wir haben mehrere Dateiformate erkannt, mit denen die Datei importiert
          werden könnte. Bitte wählen Sie das passende Format aus und klicken
          Sie auf Wiederholen. Die Werte aus der Tabelle helfen Ihnen dabei das
          passende Format auszuwählen.
        </p>
        <div className="parameter-set-control">
          <TsDropdown
            choices={parameterSetOptions}
            defaultValue=""
            id="raw-file-origins-data"
            name="raw-file-origins-data"
            required={true}
            onChange={onSelectParameterSet}
          />
        </div>
        <EnergyDataImportDetailsTable
          customEmptyDataMessage="Bitte wählen Sie das passende Format"
          dataImportRequestDetails={matchedDetails}
          dataType={dataImportRequest.typeOfData}
          error={error}
          isLoading={isLoading}
        />
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="secondary" onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={parameterSetId === null}
            onClick={handleRetryUpload}
          >
            Wiederholen
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { ParameterSetModal };
