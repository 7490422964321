import { Stepper } from "@mantine/core";
import classNames from "classnames";
import {
  type Control,
  type FieldErrors,
  type UseFormSetValue,
  type UseFormWatch
} from "react-hook-form";
import { SiteSetupProcessDataStep } from "../../../types/api.types";
import { AlertColor } from "../../Alert/Alert";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import { AddressFormItems } from "../Forms/FormItems/AddressFormItems";
import { ConnectionFormItems } from "../Forms/FormItems/ConnectionFormItems";
import { ConsumerFormItems } from "../Forms/FormItems/ConsumerFormItems";
import { MeterFormItems } from "../Forms/FormItems/MeterFormItems";
import { NameAndPvPlantsFormItems } from "../Forms/FormItems/NameAndPvPlantsFormItems";
import { TenantFormItems } from "../Forms/FormItems/TenantFormItems";
import {
  ADDRESS_FORM_FIELDS,
  CONNECTION_FORM_FIELDS,
  CONSUMER_FORM_FIELDS,
  type MeterTableErrors,
  NAME_AND_PVP_PLANTS_FIELDS,
  type SiteSetupProcessForForm,
  type TenantTableErrors
} from "../SiteSetupAssistant.types";
import { getErrorMessageAndStep } from "../utils/getErrorMessageAndStep";
import { getOrderFromStep } from "../utils/getOrderFromStep";
import { getStepFromOrder } from "../utils/getStepFromOrder";
import "./SiteSetupAssistantNavigation.scss";

interface SiteSetupAssistantNavigationProps {
  tenantTableErrors: TenantTableErrors | null;
  meterTableErrors: MeterTableErrors | null;
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  step: SiteSetupProcessDataStep;
  onBlockNavigation: (blocked: boolean) => void;
  onChangeStep: (step: SiteSetupProcessDataStep) => void;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
  plausibilityErrors: Array<string>;
}

function SiteSetupAssistantNavigation({
  formControl,
  formErrors,
  step,
  onBlockNavigation,
  onChangeStep,
  onSetFormValue,
  watch,
  tenantTableErrors,
  meterTableErrors,
  plausibilityErrors
}: SiteSetupAssistantNavigationProps) {
  const activeStep = getOrderFromStep(step);

  const plausibilityErrorObjects = plausibilityErrors.map((error) => {
    const { message, step } = getErrorMessageAndStep(error);
    return { message, step };
  });
  const plausibilityErrorAlertsForStep = (step: number) =>
    plausibilityErrorObjects.filter((error) => error.step === step);

  //Check if one of the keys in formerrors is in the NAME_AND_PVP_PLANTS_FIELDS constant
  const nameAndPvPlantsHasError = Object.keys(formErrors).some((key) => {
    return (
      NAME_AND_PVP_PLANTS_FIELDS.indexOf(key) > -1 ||
      plausibilityErrorAlertsForStep(
        getOrderFromStep(SiteSetupProcessDataStep.name_and_pv_plants)
      ).length > 0
    );
  });

  const addressHasError = Object.keys(formErrors).some((key) => {
    return (
      ADDRESS_FORM_FIELDS.indexOf(key) > -1 ||
      plausibilityErrorAlertsForStep(
        getOrderFromStep(SiteSetupProcessDataStep.address_and_supply_type)
      ).length > 0
    );
  });

  const connectionHasError = Object.keys(formErrors).some((key) => {
    return (
      CONNECTION_FORM_FIELDS.indexOf(key) > -1 ||
      plausibilityErrorAlertsForStep(
        getOrderFromStep(SiteSetupProcessDataStep.connection)
      ).length > 0
    );
  });

  const consumerHasError = Object.keys(formErrors).some((key) => {
    return (
      CONSUMER_FORM_FIELDS.indexOf(key) > -1 ||
      plausibilityErrorAlertsForStep(
        getOrderFromStep(SiteSetupProcessDataStep.consumers)
      ).length > 0
    );
  });

  const tenantHasError =
    (tenantTableErrors && tenantTableErrors.length > 0) ||
    plausibilityErrorAlertsForStep(
      getOrderFromStep(SiteSetupProcessDataStep.tenants)
    ).length > 0;
  const meterHasError =
    (meterTableErrors && meterTableErrors.length > 0) ||
    plausibilityErrorAlertsForStep(
      getOrderFromStep(SiteSetupProcessDataStep.meters)
    ).length > 0;

  const alertBanner = (step: SiteSetupProcessDataStep) =>
    plausibilityErrorAlertsForStep(getOrderFromStep(step)).map((error) => (
      <IconAlert
        className="site-setup-assistant-navigation-alert"
        color={AlertColor.Danger}
        key={error.message}
      >
        {error.message}
      </IconAlert>
    ));
  return (
    <Stepper
      active={activeStep}
      className="SiteSetupAssistantNavigation"
      completedIcon={({ step }) => step + 1}
      contentPadding="lg"
      size="sm"
      onStepClick={(newStepOrder) => {
        const newStep = getStepFromOrder(newStepOrder);
        if (newStep) {
          onChangeStep(newStep);
        }
      }}
    >
      <Stepper.Step
        className={classNames({
          active:
            activeStep ===
            getOrderFromStep(SiteSetupProcessDataStep.name_and_pv_plants),
          error: nameAndPvPlantsHasError
        })}
        color={nameAndPvPlantsHasError ? "red" : undefined}
        label="Bezeichnung & PV-Anlage(n)"
      >
        {alertBanner(SiteSetupProcessDataStep.name_and_pv_plants)}
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.name_and_pv_plants) && (
          <NameAndPvPlantsFormItems
            formControl={formControl}
            formErrors={formErrors}
            onBlockNavigation={onBlockNavigation}
          />
        )}
      </Stepper.Step>
      <Stepper.Step
        className={classNames({
          active:
            activeStep ===
            getOrderFromStep(SiteSetupProcessDataStep.address_and_supply_type),
          error: addressHasError
        })}
        color={addressHasError ? "red" : undefined}
        label="Adresse und Art der Versorgung"
      >
        {alertBanner(SiteSetupProcessDataStep.address_and_supply_type)}
        {activeStep ===
          getOrderFromStep(
            SiteSetupProcessDataStep.address_and_supply_type
          ) && (
          <AddressFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step
        className={classNames({
          active:
            activeStep ===
            getOrderFromStep(SiteSetupProcessDataStep.connection),
          error: connectionHasError
        })}
        color={connectionHasError ? "red" : undefined}
        label="Netzverknüpfungspunkt"
      >
        {alertBanner(SiteSetupProcessDataStep.connection)}
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.connection) && (
          <ConnectionFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step
        className={classNames({
          active:
            activeStep === getOrderFromStep(SiteSetupProcessDataStep.consumers),
          error: consumerHasError
        })}
        color={consumerHasError ? "red" : undefined}
        label="Verbraucherdaten"
      >
        {alertBanner(SiteSetupProcessDataStep.consumers)}
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.consumers) && (
          <ConsumerFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
          />
        )}
      </Stepper.Step>
      <Stepper.Step
        className={classNames({
          active:
            activeStep === getOrderFromStep(SiteSetupProcessDataStep.tenants),
          error: tenantHasError
        })}
        color={tenantHasError ? "red" : undefined}
        label="Belieferte"
      >
        {alertBanner(SiteSetupProcessDataStep.tenants)}
        {activeStep === getOrderFromStep(SiteSetupProcessDataStep.tenants) && (
          <TenantFormItems
            formControl={formControl}
            formErrors={formErrors}
            tenantTableErrors={tenantTableErrors}
            watch={watch}
            onBlockNavigation={onBlockNavigation}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step
        className={classNames({
          active:
            activeStep === getOrderFromStep(SiteSetupProcessDataStep.meters),
          error: meterHasError
        })}
        color={meterHasError ? "red" : undefined}
        label="Zählerdaten"
      >
        {alertBanner(SiteSetupProcessDataStep.meters)}
        {activeStep === getOrderFromStep(SiteSetupProcessDataStep.meters) && (
          <MeterFormItems
            meterTableErrors={meterTableErrors}
            watch={watch}
            onBlockNavigation={onBlockNavigation}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
    </Stepper>
  );
}

export { SiteSetupAssistantNavigation, SiteSetupAssistantNavigationProps };
