import { Box, Button, Collapse, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

interface ExtendableProps {
  children: React.ReactNode;
  moreLabel?: string;
  lessLabel?: string;
}

function Extendable({
  children,
  moreLabel = "Mehr anzeigen",
  lessLabel = "Weniger anzeigen"
}: ExtendableProps) {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box className="Extendable">
      <Collapse in={opened}>{children}</Collapse>
      <Button
        className="extendable-toggle"
        fw="normal"
        px={0}
        variant="transparent"
        onClick={toggle}
      >
        <Group gap={3}>
          {opened ? (
            <>
              <IconChevronUp stroke-width="1" />
              {lessLabel}
            </>
          ) : (
            <>
              <IconChevronDown stroke-width="1" />
              {moreLabel}
            </>
          )}
        </Group>
      </Button>
    </Box>
  );
}

export { Extendable, ExtendableProps };
