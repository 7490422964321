import { appendQueryString } from "../appendQueryString";

const startPageApi = {
  sites: {
    url: (projectId?: string) =>
      appendQueryString("/api/start-page/sites/", {
        project_id: projectId
      }),
    key: (projectId?: string) => ["startPage", "sites", { projectId }]
  },
  onboardingProgress: {
    url: (
      page: number,
      pageSize: number,
      projectId?: string,
      siteId?: number
    ) =>
      appendQueryString("/api/start-page/onboarding-progress/", {
        page,
        page_size: pageSize,
        project_id: projectId,
        site_id: siteId
      }),
    key: (
      page?: number,
      pageSize?: number,
      projectId?: string,
      siteId?: number
    ) => [
      "startPage",
      "onboardingProcess",
      { page, pageSize, projectId, siteId }
    ]
  },
  companies: {
    url: (projectId?: string) =>
      appendQueryString("/api/start-page/companies/", {
        project_id: projectId
      }),
    key: (projectId?: string) => ["startPage", "companies", { projectId }]
  },
  regulatoryTodos: {
    url: (
      page: number,
      pageSize: number,
      projectId?: string,
      companyId?: number
    ) =>
      appendQueryString("/api/start-page/regulatory-todos/", {
        page,
        page_size: pageSize,
        project_id: projectId,
        company_id: companyId
      }),
    key: (
      page?: number,
      pageSize?: number,
      projectId?: string,
      companyId?: number
    ) => [
      "startPage",
      "regulatoryTodos",
      { page, pageSize, projectId, companyId }
    ]
  },
  invoices: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/invoices/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "invoices",
      { year, projectId }
    ]
  },
  co2Savings: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/co2-savings/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "co2Savings",
      { year, projectId }
    ]
  },
  deliveredElectricity: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/delivered-electricity/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "deliveredElectricity",
      { year, projectId }
    ]
  },
  producedElectricity: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/produced-electricity/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "ProducedElectricity",
      { year, projectId }
    ]
  },
  electricityTax: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/stromsteuer/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "electricityTax",
      { year, projectId }
    ]
  },
  eeg6: {
    url: (year: number, projectId?: string) =>
      appendQueryString("/api/start-page/paragraph-6-eeg/", {
        project_id: projectId,
        year
      }),
    key: (year?: number, projectId?: string) => [
      "startPage",
      "eeg6",
      { year, projectId }
    ]
  }
};

const startPageApiWithoutCamelization = {
  tiles: {
    url: (projectId?: string) =>
      appendQueryString("/api/start-page/tiles/", {
        project_id: projectId
      }),
    key: (projectId?: string) => ["startPage", "tiles", { projectId }]
  }
};

export { startPageApi, startPageApiWithoutCamelization };
