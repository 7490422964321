import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import type { EnergyDataImportRequest } from "../../../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";
import { EnergyDataImportDetailsTable } from "../EnergyDataImportDetailsTable/EnergyDataImportDetailsTable";
import { useDataImportRequestDetails } from "../hooks/useDataImportRequestDetails";
import { GeneralImportDetailsList } from "./GeneralImportDetailsList";

interface EnergyDataImportDetailsModalProps {
  onClose: () => void;
  dataImportRequest: EnergyDataImportRequest;
}

function EnergyDataImportDetailsModal({
  onClose,
  dataImportRequest
}: EnergyDataImportDetailsModalProps) {
  const { dataImportRequestWithDetails, isLoading, error } =
    useDataImportRequestDetails(dataImportRequest.id);

  return (
    <Modal isOpen scrollable size="xxl" toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Importdetails: {dataImportRequest.energyDataFile.name}
      </ModalHeader>
      <ModalBody scrollable>
        <GeneralImportDetailsList
          generalDetails={
            isLoading ? dataImportRequest : dataImportRequestWithDetails
          }
        />
        <EnergyDataImportDetailsTable
          dataImportRequestDetails={dataImportRequestWithDetails?.details}
          dataType={dataImportRequest.typeOfData}
          error={error}
          isLoading={isLoading}
        />
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="brand" onClick={onClose}>
            Schließen
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { EnergyDataImportDetailsModal };
