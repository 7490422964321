import { Text } from "@mantine/core";
import { DeleteIcon } from "../../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../../Buttons/EditIcon";
import "./ParkabrechnungContractTitle.scss";

interface ParkabrechnungContractTitleProps {
  name: string;
  onClickDelete: () => void;
  onClickEdit: () => void;
}

function ParkabrechnungContractTitle({
  name,
  onClickDelete,
  onClickEdit
}: ParkabrechnungContractTitleProps) {
  return (
    <div className="ParkabrechnungContractTitle">
      <Text flex="1 1 auto">{name}</Text>
      <div className="action-panel">
        <span
          className="edit-icon"
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit();
          }}
        >
          <EditIcon tooltipText="Bearbeiten" />
        </span>
        <DeleteIcon
          tooltipText="Löschen"
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete();
          }}
        />
      </div>
    </div>
  );
}

export { ParkabrechnungContractTitle };
