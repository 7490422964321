import type { Node, NodeProps, ReactFlowState } from "@xyflow/react";
import { Handle, Position, useStore } from "@xyflow/react";
import { default as classnames, default as classNames } from "classnames";
import { useMemo, useState } from "react";
import type { MeteringDirection } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import { StructureViewMode } from "../../../StructureView/StructureView.constants";
import type { EdgeData } from "../../StructureViewFlowDiagram.types";
import { ConnectionPointDirection } from "../../StructureViewFlowDiagram.types";
import { getArrowTypeFromMeteringDirection } from "../../utils/getArrowTypeFromMeteringDirection";
import { getCorrectSizeFromComponentType } from "../../utils/getCorrectSizeFromComponentType";
import { getStructureViewImageUrl } from "../../utils/getStructureViewImageUrl";
import "./CustomMeteringFlowNode.scss";
import { ArrowPosition, MeteringArrows } from "./MeteringArrows/MeteringArrows";

const DISABLED_OPACITY = 0.7;

const connectionNodeIdSelector = (state: ReactFlowState) =>
  state.connection.fromHandle?.nodeId;

export type CustomMeteringFlowNodeData = {
  active?: boolean;
  componentId: number;
  image: string;
  imageColor: string;
  label: string;
  size?: number;
  tooltipData: {
    name: string;
    text: string;
    type: string;
    isConsumptionShareConfirmed?: boolean;
    isConsumptionShareEditable?: boolean;
  };
  type: ObjectName;
  meterBillingRelevantOrSubmeter?: string;
  meterPriorityDescription?: string;
  connectedSourceEdges: Array<EdgeData>;
  id: string;
  position: { x: number; y: number };
};

export type CustomMeteringFlowNodeType = Node<
  CustomMeteringFlowNodeData,
  "custom"
>;

export interface CustomMeteringFlowNodeProps
  extends NodeProps<CustomMeteringFlowNodeType> {
  mode: StructureViewMode;
  meteringDirection?: MeteringDirection | null;
}
function CustomMeteringFlowNode({
  id,
  data,
  mode,
  meteringDirection
}: CustomMeteringFlowNodeProps) {
  const connectionNodeId = useStore<string | undefined>(
    connectionNodeIdSelector
  );

  const isConnectingNode = connectionNodeId === id;
  const [isHovering, setIsHovering] = useState(false);

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const imageUrl = useMemo(() => {
    const url = data.image
      ? getStructureViewImageUrl(
          data.image,
          data.imageColor,
          data.active === false ? DISABLED_OPACITY : undefined
        )
      : null;
    return url;
  }, [data.active, data.image, data.imageColor]);

  const size: number | undefined = getCorrectSizeFromComponentType(
    data.type,
    data.size,
    mode === StructureViewMode.MeteringConcept
  );
  const isGhostNode = data.type === ObjectName.GhostNode;
  const isMeter = data.type === ObjectName.Meter;
  const label =
    isMeter && mode === StructureViewMode.MeteringConcept
      ? data.tooltipData.name
      : data.label;
  const isMCMeter = isMeter && mode === StructureViewMode.MeteringConcept;
  const isMCMeterAndBillingRelevant =
    isMCMeter &&
    data.meterBillingRelevantOrSubmeter === "Bilanzierungsrelevanter Zähler";
  const arrowType =
    isMCMeter && meteringDirection
      ? getArrowTypeFromMeteringDirection(meteringDirection)
      : null;
  return (
    <div
      className="CustomMeteringFlowNode"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={classnames("customNodeBody", {
          ghostNode: isGhostNode,
          mcMeterBillingRelevant: isMCMeterAndBillingRelevant
        })}
        style={{
          borderStyle: isTarget ? "dashed" : "solid",
          backgroundColor: isGhostNode ? "black" : "",
          backgroundImage: imageUrl ? `url(${imageUrl})` : "",
          width: size ?? 60,
          height: size ?? 60
        }}
      >
        {isMCMeter && arrowType && (
          <MeteringArrows
            meteringPriorityDescription={data.meterPriorityDescription}
            position={
              data.connectedSourceEdges[0] &&
              data.connectedSourceEdges[0].fromConnectionPoint ===
                ConnectionPointDirection.Right
                ? ArrowPosition.Top
                : ArrowPosition.Side
            }
            type={arrowType}
          />
        )}
      </div>
      <div className="custom-flow-node-label">{label}</div>

      <>
        <Handle
          className={classNames("customHandle", "customSourceHandle", {
            hidden: !isHovering || (isConnecting && !isConnectingNode)
          })}
          id={ConnectionPointDirection.Right}
          isConnectable
          position={Position.Right}
          type="source"
        />
        <Handle
          className={classNames("customHandle", "customSourceHandle", {
            hidden: !isHovering || (isConnecting && !isConnectingNode)
          })}
          id={ConnectionPointDirection.Bottom}
          isConnectable
          position={Position.Bottom}
          type="source"
        />
      </>

      <Handle
        className={classNames("customHandle", "customTargetHandle", {
          hidden: !isConnecting || isConnectingNode
        })}
        id={ConnectionPointDirection.Left}
        isConnectable={!isConnectingNode}
        position={Position.Left}
        type="target"
      />
      <Handle
        className={classNames("customHandle", "customTargetHandle", {
          hidden: !isConnecting || isConnectingNode
        })}
        id={ConnectionPointDirection.Top}
        isConnectable={!isConnectingNode}
        position={Position.Top}
        type="target"
      />
    </div>
  );
}

export { CustomMeteringFlowNode };
