import classNames from "classnames";
import { type Control, Controller } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import { ChangeableSpotMarketFormulaFieldStatic } from "../../../../../../DynamicForm/FormItems/FormField/ChangeableSpotMarketFormulaFieldStatic/ChangeableSpotMarketFormulaFieldStatic";
import { DELIVERY_FORM_HELP_TEXTS } from "../../DeliveryForm.constants";

interface ChangeableSpotMarketFieldProps {
  control: Control<Delivery>;
  error?: Record<string, Array<string>>;
  name: keyof Pick<Delivery, "spotMarketPrices">;
  label: string;
  unit: string;
  className?: string;
  allowInput?: boolean;
  collapsible?: boolean;
}

function ChangeableSpotMarketField({
  control,
  error,
  name,
  label,
  unit,
  className,
  allowInput = true,
  collapsible = true
}: ChangeableSpotMarketFieldProps) {
  return (
    <div className={classNames("ChangeableSpotMarketField", className)}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <ChangeableSpotMarketFormulaFieldStatic
            {...field}
            allowInput={allowInput}
            collapsible={collapsible}
            errors={error}
            fieldName={field.name}
            helpText={DELIVERY_FORM_HELP_TEXTS.spotMarketPrices}
            label={label}
            unit={unit}
            onInput={(_, value) => field.onChange(value)}
          />
        )}
      />
    </div>
  );
}

export { ChangeableSpotMarketField };
