import { useNavigate } from "react-router-dom";
import { Paragraph6EEGCreditResponseTodo_alert_state } from "../../../types/api.types";
import { showBannerToast } from "../../../utils/toast";
import {
  Paragraph6CreditsAlertsCreated,
  Paragraph6CreditsAlertsNotRelevant,
  Paragraph6CreditsAlertsOpen
} from "../Paragraph6CreditsPage/Paragraph6CreditsAlerts/Paragraph6CreditsAlerts";

function useShowParagraph6CreditsAlerts() {
  const navigate = useNavigate();

  function showParagraph6CreditsAlerts({
    todoUrl,
    todoPageUrl,
    todoAlertState
  }: {
    todoUrl?: string;
    todoPageUrl: string;
    todoAlertState?: Paragraph6EEGCreditResponseTodo_alert_state;
  }) {
    if (todoAlertState === Paragraph6EEGCreditResponseTodo_alert_state.none)
      return;
    showBannerToast(todoUrl ? "success" : "warning", {
      text:
        todoAlertState ===
        Paragraph6EEGCreditResponseTodo_alert_state.created ? (
          <Paragraph6CreditsAlertsCreated />
        ) : todoAlertState ===
          Paragraph6EEGCreditResponseTodo_alert_state.open ? (
          <Paragraph6CreditsAlertsOpen />
        ) : (
          <Paragraph6CreditsAlertsNotRelevant />
        ),
      ctaText: todoUrl ? "Zur Rückerstattungsaufgabe" : "Zu den Aufgaben",
      ctaAction: () => (todoUrl ? navigate(todoUrl) : navigate(todoPageUrl))
    });
  }

  return { showParagraph6CreditsAlerts };
}
export { useShowParagraph6CreditsAlerts };
