import type { Node, NodeProps } from "@xyflow/react";
import "./CustomTextElementNode.scss";

export type CustomTextElementNodeData = {
  text: string;
  site: number;
  fontSize: number;
  fontWeight: number;
};

export type CustomTextElementNodeType = Node<CustomTextElementNodeData, "text">;

export type CustomtTextElementNodeEditData = {
  nodeId: string;
  textContent: string;
};

interface CustomTextElementNodeProps
  extends NodeProps<CustomTextElementNodeType> {
  handleDoubleClick: ({
    nodeId,
    textContent
  }: CustomtTextElementNodeEditData) => void;
}

function CustomTextElementNode({
  data,
  id,
  handleDoubleClick
}: CustomTextElementNodeProps) {
  return (
    <div
      className="CustomTextElementNode"
      onDoubleClick={() =>
        handleDoubleClick({ nodeId: id, textContent: data.text })
      }
    >
      <div
        className="custom-text-element-body"
        style={{ fontSize: data.fontSize, fontWeight: data.fontWeight }}
      >
        {data.text.split("\n").map((line, index) => (
          <p
            className="custom-text"
            key={index}
            style={{ margin: !line ? "10px" : "0px" }}
          >
            {line}
          </p>
        ))}
      </div>
    </div>
  );
}

export { CustomTextElementNode };
