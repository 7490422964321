import { generatePath } from "react-router-dom";
import type { Column } from "react-table";
import { ROUTES } from "../../../../../routes";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type {
  LocationOverviewData,
  MarketOrMeteringLocationOverview
} from "../../LocationOverview.types";

function getDocumentFolderColumn() {
  return {
    Header: "Dokumenten-Ordner",
    id: "documentFolder",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="document-folders">
        {cellInfo.value.map((location) => (
          <div className="sub-row" key={location.location.id}>
            <DocumentFolder
              projectId={location.location.project}
              todoId={location.todo}
            />
          </div>
        ))}
      </div>
    ),
    sortable: false,
    width: 190
  } satisfies Column<LocationOverviewData>;
}

interface DocumentFolderProps {
  projectId: string;
  todoId: number | null;
}

function DocumentFolder({ projectId, todoId }: DocumentFolderProps) {
  if (todoId === null) {
    return null;
  }

  const path = generatePath(ROUTES.onboardingTodo, {
    projectId,
    todoId: todoId.toString()
  });

  return (
    <a href={path}>
      Ordner <Icon name={IconName.ExternalLink} />
    </a>
  );
}

export { getDocumentFolderColumn };
