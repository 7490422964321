import type { Control, FieldError } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import type { FormInputData } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { HelperText } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { SectionHeader } from "../../../../../../DynamicForm/FormItems/SectionHeader/SectionHeader";

interface PriceModelWithHeaderProps {
  control: Control<Delivery>;
  error?: FieldError;
  data: FormInputData<Delivery, "priceModel">;
  disabled?: boolean;
}
function PriceModelWithHeader({
  control,
  error,
  data,
  disabled
}: PriceModelWithHeaderProps) {
  return (
    <div className="PriceModelWithHeader">
      <SectionHeader>Lieferkonditionen</SectionHeader>
      <FormFieldController
        control={control}
        data={{ ...data, disabled } as FormInputData<Delivery, "priceModel">}
      />
      <HelperText error={error} />
    </div>
  );
}

export { PriceModelWithHeader };
