import type { DropdownProps as ReactstrapDropdownProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { Dropdown as ReactstrapDropdown } from "reactstrap";
import type { DropdownItemProps } from "./DropdownItem/DropdownItem";
import { DropdownItem } from "./DropdownItem/DropdownItem";
import type { DropdownMenuProps } from "./DropdownMenu/DropdownMenu";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import type { DropdownToggleProps } from "./DropdownToggle/DropdownToggle";
import { DropdownToggle } from "./DropdownToggle/DropdownToggle";
import type { UncontrolledDropdownProps } from "./UncontrolledDropdown/UncontrolledDropdown";
import { UncontrolledDropdown } from "./UncontrolledDropdown/UncontrolledDropdown";

type DropdownProps = ReactstrapDropdownProps;

function Dropdown(props: DropdownProps) {
  return <ReactstrapDropdown {...props} />;
}

export {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown
};
export type {
  DropdownItemProps,
  DropdownMenuProps,
  DropdownToggleProps,
  UncontrolledDropdownProps
};
