import type { MRT_TableInstance } from "mantine-react-table";
import type { GenerationShareResponse } from "../../../../../hooks/useGenerationShares";
import type { DirektvermarktungGeneratorShareResponse } from "../../../Direktvermarktung.types";

type WeightingCompatibleDataset =
  | GenerationShareResponse
  | DirektvermarktungGeneratorShareResponse;

export function getOtherRowsWeightingSum<T extends WeightingCompatibleDataset>(
  table: MRT_TableInstance<T>,
  currentRowIndex: number,
  currentMeloMaloId: number
) {
  const otherRowsFromSameMeloMalo = table
    .getRowModel()
    .rows.filter(
      (row) =>
        Number(row.id) !== currentRowIndex &&
        row.original.metering_or_market_location_id === currentMeloMaloId
    );

  return otherRowsFromSameMeloMalo.reduce(
    (acc, curr) => acc + (Number(curr.original.weighting) || 0),
    0
  );
}
