import type { DateTime } from "luxon";
import type { Accessor, Column, TableCellRenderer } from "react-table";
import type { FormErrors } from "../../CustomForm/useCustomForm";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import { IconHelpText } from "../../IconHelpText/IconHelpText";
import type { ChangeableFieldData } from "../ChangeableFieldWidget";
import { ControlsCell } from "./Cells/ControlsCell";
import { EndDateCell } from "./Cells/EndDateCell";
import { NumberValueCell } from "./Cells/NumberValueCell";
import { StartDateCell } from "./Cells/StartDateCell";
import { TextValueCell } from "./Cells/TextValueCell";
import "./ChangeableFieldTable.scss";

export interface ChangeableFieldTableOptions {
  startDateHelpText?: string;
  startDateIsRequired?: boolean;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  allowDeletionOfAllRows?: boolean;
  restrictFutureDates?: boolean;
  deleteTooltipText?: string;
  numberValueHeader?: string;
  numberValueDecimalScale?: number;
  inputGroupText?: string;
  dateCellWidth?: number;
  isTextField?: boolean;
}

export interface ChangeableFieldTableValueColumnsData<T> {
  header: TableCellRenderer;
  accessor: Accessor<ChangeableFieldData<T>>;
  Cell: (value: T, onChange: (value: T) => void) => React.ReactNode;
  width?: number;
}

interface ChangeableFieldTableProps<T> {
  values: Array<ChangeableFieldData<T>>;
  dataName: string;
  tableOptions?: ChangeableFieldTableOptions;
  valueColumns?: Array<ChangeableFieldTableValueColumnsData<T>>;
  onUpdateValue: (index: number, updatedValue: ChangeableFieldData<T>) => void;
  onDeleteValue: (index: number) => void;
  errors?: FormErrors;
}

function ChangeableFieldTable<T>({
  values,
  dataName,
  errors,
  tableOptions = {},
  valueColumns,
  onUpdateValue,
  onDeleteValue
}: ChangeableFieldTableProps<T>) {
  const tableColumns: Array<Column<ChangeableFieldData<T>>> = [
    {
      Header: () => (
        <span>
          Von
          {tableOptions.startDateIsRequired && "*"}
          {!!tableOptions.startDateHelpText && (
            <>
              {" "}
              <IconHelpText helpText={tableOptions.startDateHelpText} />
            </>
          )}
        </span>
      ),
      accessor: "startDate",
      width: tableOptions.dateCellWidth ? tableOptions.dateCellWidth : 110,
      Cell: (cellData) => (
        <StartDateCell
          endDate={cellData.original.endDate}
          isFirstDate={cellData.index === 0}
          placeholder={tableOptions.startDatePlaceholder}
          previousRowStartDate={
            cellData.index > 0
              ? values[cellData.index - 1].startDate
              : undefined
          }
          restrictFutureDates={tableOptions.restrictFutureDates}
          startDate={cellData.value}
          onChange={(newStartDate) =>
            handleChangeStartDate(cellData.index, newStartDate)
          }
        />
      ),
      sortable: false
    },
    {
      Header: `Bis*`,
      accessor: "endDate",
      width: tableOptions.dateCellWidth ? tableOptions.dateCellWidth : 110,
      Cell: (cellData) => (
        <EndDateCell
          endDate={cellData.value}
          placeholder={tableOptions.endDatePlaceholder}
          restrictFutureDates={tableOptions.restrictFutureDates}
          startDate={cellData.original.startDate}
          onChange={(newEndDate) =>
            handleChangeEndDate(cellData.index, newEndDate)
          }
        />
      ),
      sortable: false
    }
  ];

  if (valueColumns) {
    valueColumns.forEach((columnData) => {
      const column: Column<ChangeableFieldData<T>> = {
        Header:
          typeof columnData.header === "string"
            ? `${columnData.header}*`
            : columnData.header,
        accessor: columnData.accessor,
        Cell: (cellData) =>
          columnData.Cell(cellData.original.value, (newValue) =>
            handleChangeValue(cellData.index, newValue)
          ),
        width: columnData.width,
        sortable: false
      };

      tableColumns.push(column);
    });
  } else {
    tableColumns.push({
      Header: `${tableOptions.numberValueHeader}*`,
      accessor: "value",
      Cell: (cellData) =>
        tableOptions.isTextField ? (
          <div className="text-value-cell-container">
            <TextValueCell
              inputGroupText={tableOptions.inputGroupText}
              value={cellData.original.value}
              onChange={(value) =>
                handleChangeValue(cellData.index, value as T)
              }
            />
            <div className="table-error-messages-container">
              {getErrorForIndex(cellData.index)}
            </div>
          </div>
        ) : (
          <NumberValueCell
            decimalScale={tableOptions.numberValueDecimalScale}
            inputGroupText={tableOptions.inputGroupText}
            value={cellData.original.value}
            onChange={(value) => handleChangeValue(cellData.index, value as T)}
          />
        ),
      sortable: false
    } as Column<ChangeableFieldData<T>>);
  }

  tableColumns.push({
    Header: "",
    width: 30,
    Cell: (cellData) => (
      <ControlsCell
        canDelete={
          cellData.index !== 0 || !!tableOptions.allowDeletionOfAllRows
        }
        deleteTooltipText={tableOptions.deleteTooltipText ?? `Reihe löschen`}
        onDelete={() => onDeleteValue(cellData.index)}
      />
    )
  });

  function handleChangeStartDate(index: number, newStartDate: DateTime | null) {
    onUpdateValue(index, {
      ...values[index],
      startDate: newStartDate ? newStartDate.startOf("day") : null
    });

    const previousRowEndDate = index > 0 ? values[index - 1].endDate : null;

    if (previousRowEndDate && newStartDate) {
      const newPreviousRowEndDate = newStartDate
        .minus({ day: 1 })
        .startOf("day");

      onUpdateValue(index - 1, {
        ...values[index - 1],
        endDate: newPreviousRowEndDate
      });
    }
  }

  function handleChangeEndDate(index: number, newEndDate: DateTime | null) {
    let adjustedEndDate = newEndDate;

    if (newEndDate) {
      adjustedEndDate = newEndDate.startOf("day");
    }

    onUpdateValue(index, {
      ...values[index],
      endDate: adjustedEndDate
    });
  }

  function getErrorForIndex(index: number) {
    if (!errors) {
      return undefined;
    }

    if (errors[index] && errors[index]["formula"]) {
      return errors[index]["formula"].map((val, mapIndex) => (
        <span
          className="table-error-message"
          key={index.toString() + "/" + mapIndex.toString()}
        >
          {val}
        </span>
      ));
    }
    return undefined;
  }
  function handleChangeValue(index: number, value: T) {
    onUpdateValue(index, {
      ...values[index],
      value: value
    });
  }

  return (
    <div className="ChangeableFieldTable">
      <CustomReactTable
        columns={tableColumns}
        data={values}
        dataName={dataName}
        minRows={0}
        pageSize={values.length}
      />
    </div>
  );
}

export { ChangeableFieldTable, ChangeableFieldTableProps };
