import { useStartPageDeliveredElectricity } from "../../../hooks/startPage/useStartPageDeliveredElectricity";
import { formatEnergyValue } from "../../../utils/formatEnergyValue";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type DeliveredElectricityMiniTileProps = {
  projectId?: string;
  year: number;
};

function DeliveredElectricityMiniTile({
  projectId,
  year
}: DeliveredElectricityMiniTileProps) {
  const { data, isLoading } = useStartPageDeliveredElectricity(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Direktlieferung",
      value: data ? formatEnergyValue(data.sumDirect) : ""
    },
    {
      label: "Weiterleitung",
      value: data ? formatEnergyValue(data.sumForwarded) : ""
    }
  ];

  return (
    <MiniTile
      iconName={IconName.SolarPowerGenerator}
      isLoading={isLoading}
      items={items}
      title="Gelieferte Strommengen"
    />
  );
}

export { DeliveredElectricityMiniTile };
