import classnames from "classnames";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { Input, Label } from "reactstrap";

import { RoundedNumberFormat } from "../../../utils/RoundedNumberFormat";
import { ErrorText } from "../../DynamicForm/FormItems/FormField/utils/ErrorText";

class YearlyEnergyInput extends PureComponent {
  handleEnergyValueChanged = ({ floatValue }) => {
    const name = this.props.name;
    const energy = typeof floatValue === "undefined" ? null : floatValue;
    this.props.onInput(name, energy);
  };

  render() {
    const { name, value, overwriteMode, errorTexts, onToggleOverwriteMode } =
      this.props;

    const numberField = (
      <RoundedNumberFormat
        className={classnames("form-control m-input", {
          "is-invalid": errorTexts && errorTexts.length > 0
        })}
        decimalScale={0}
        disabled={!overwriteMode}
        displayType="input"
        id="yearly-energy"
        name={name}
        type="text"
        value={value}
        onValueChange={this.handleEnergyValueChanged}
      />
    );
    return (
      <div className="FormField">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Label
                className="m-checkbox m-checkbox--brand form-check-label form-check-label m-checkbox--single mr-1"
                htmlFor="overwrite"
                style={{ height: "18px" }}
              >
                <Input
                  checked={overwriteMode}
                  className="form-check-input"
                  id="overwrite"
                  type="checkbox"
                  onChange={onToggleOverwriteMode}
                />
                <span />
              </Label>
              Skalieren
            </span>
          </div>
          {numberField}
          {errorTexts && errorTexts.map(ErrorText)}
        </div>
      </div>
    );
  }
}

YearlyEnergyInput.propTypes = {
  name: PropTypes.string.isRequired,
  errorTexts: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.number,
  overwriteMode: PropTypes.bool,
  loadprofile: PropTypes.object,
  onInput: PropTypes.func.isRequired,
  onToggleOverwriteMode: PropTypes.func.isRequired
};

YearlyEnergyInput.defaultProps = {
  errorTexts: []
};

export { YearlyEnergyInput };
