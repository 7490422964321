import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import { StructureViewMode } from "../components/StructureView/StructureView.constants";
import type { ContainerElementsNodeParameters } from "../components/StructureViewFlowDiagram/CustomGraphComponents/CustomContainerElementNode/CustomContainerElementNode";
import type { ConnectionPointDirection } from "../components/StructureViewFlowDiagram/StructureViewFlowDiagram.types";
import urls from "../urls";
import { queryWithPollResponse } from "../utils/api-utils";
import { uploadFileOrFiles } from "../utils/files/uploadFileOrFiles";

export const DIAGRAM_QUERY_KEY = (mode, siteId) =>
  mode === StructureViewMode.New
    ? ["premium-sketch-data", { siteId }]
    : ["premium-metering-concept-sketch-data", { siteId }];

export interface UpdateNodePositionPayload {
  xPosition: number;
  yPosition: number;
  xRelative: number;
  yRelative: number;
  parent: string | null | undefined;
}

export interface UpdateMeteringEdgePayload {
  fromConnectionPoint?: ConnectionPointDirection;
  toConnectionPoint?: ConnectionPointDirection;
}

export function useStructureViewFlowDiagramMutations(
  siteId: number,
  mode: StructureViewMode
) {
  const queryClient = useQueryClient();
  const queryKey = DIAGRAM_QUERY_KEY(mode, siteId);

  const downloadMeteringConceptPDFMutation = useMutation({
    mutationFn: ({
      siteId,
      uploadToTodo,
      image
    }: {
      siteId: number;
      uploadToTodo: boolean;
      image: File;
      siteDetails;
    }) => downloadMeteringConceptPDF(siteId, uploadToTodo, image)
  });

  const createContainerElementNodeMutation = useMutation({
    mutationFn: (centerCoordinates: { x: number; y: number }) =>
      createContainerElementNode(siteId, centerCoordinates),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const createTextElementNodeMutation = useMutation({
    mutationFn: (centerCoordinates: { x: number; y: number }) =>
      createTextElementNode(siteId, centerCoordinates),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateContainerColorMutation = useMutation({
    mutationFn: ({ id, color }: { id: string; color: string }) =>
      updateContainerColor(id, color),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateTextConfigMutation = useMutation({
    mutationFn: ({
      id,
      fontWeight,
      fontSize
    }: {
      id: string;
      fontWeight: number;
      fontSize: number;
    }) => updateTextConfig(id, fontWeight, fontSize),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateNodePositionMutation = useMutation({
    mutationFn: ({
      updateUrl,
      payload
    }: {
      updateUrl: string;
      payload: UpdateNodePositionPayload;
    }) => updateNodePosition(updateUrl, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateNodeIconMutation = useMutation({
    mutationFn: ({ nodeId, icon }: { nodeId: string; icon: string }) =>
      updateNodeIcon(nodeId, icon),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });
  const deleteTextElementNodeMutation = useMutation({
    mutationFn: (nodeId: string) => deleteTextElementNode(nodeId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const deleteContainerElementNodeMutation = useMutation({
    mutationFn: (nodeId: string) => deleteContainerElementNode(nodeId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateResizedContainerElementNodeMutation = useMutation({
    mutationFn: ({
      nodeId,
      parameters
    }: {
      nodeId: string;
      parameters: ContainerElementsNodeParameters;
    }) => updateResizedContainerElementNode(nodeId, parameters),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });

  const updateTextElementNodeMutation = useMutation({
    mutationFn: ({ nodeId, text }: { nodeId: string; text: string }) =>
      updateTextElementNode(nodeId, text),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey
      });
    }
  });
  function updateTextConfig(id: string, fontWeight: number, fontSize: number) {
    const textElementNodeUrl =
      urls.api.standaloneSketchElementsTextBlockOptions(id);
    return api.patch(textElementNodeUrl, {
      fontWeight,
      fontSize
    });
  }

  function updateContainerColor(id: string, color: string) {
    const containerElementNodeUrl =
      urls.api.standaloneSketchElementsContainerOptions(id);
    return api.patch(containerElementNodeUrl, {
      color
    });
  }

  async function updateNodePosition(
    updateUrl: string,
    payload: UpdateNodePositionPayload
  ) {
    await queryClient.cancelQueries({
      queryKey: queryKey
    });
    return api.patch(updateUrl, payload);
  }

  function deleteTextElementNode(id: string) {
    const textElementNodeUrl =
      urls.api.standaloneSketchElementsTextBlockOptions(id);
    return api.delete(textElementNodeUrl);
  }

  function deleteContainerElementNode(id: string) {
    const containerElementNodeUrl =
      urls.api.standaloneSketchElementsContainerOptions(id);
    return api.delete(containerElementNodeUrl);
  }

  function updateResizedContainerElementNode(
    id: string,
    parameters: ContainerElementsNodeParameters
  ) {
    const containerElementNodeUrl =
      urls.api.standaloneSketchElementsContainerOptions(id);
    return api.patch(containerElementNodeUrl, {
      width: parameters.width,
      height: parameters.height,
      xPosition: Math.round(parameters.x),
      yPosition: Math.round(parameters.y)
    });
  }

  function updateTextElementNode(id: string, text: string) {
    const textElementNodeUrl =
      urls.api.standaloneSketchElementsTextBlockOptions(id);
    return api.patch(textElementNodeUrl, {
      text
    });
  }

  function updateNodeIcon(id: string, icon: string) {
    const nodeUrl = urls.api.standaloneSketchUpdate(id);
    return api.patch(nodeUrl, {
      icon
    });
  }

  function createTextElementNode(
    siteId: number,
    centerCoordinates: { x: number; y: number }
  ) {
    return api.post(urls.api.standaloneSketchElementsTextBlock(), {
      site: siteId,
      xPosition: centerCoordinates.x,
      yPosition: centerCoordinates.y
    });
  }

  function downloadMeteringConceptPDF(
    siteId: number,
    uploadToTodo: boolean,
    image: File
  ) {
    return queryWithPollResponse<{ taskIds: Array<string> }>(() =>
      uploadFileOrFiles(image, urls.api.mkExport(), "image", {
        uploadToTodo,
        site: siteId
      })
    );
  }

  function createContainerElementNode(
    siteId: number,
    centerCoordinates: { x: number; y: number }
  ) {
    return api.post(urls.api.standaloneSketchElementContainer(), {
      site: siteId,
      xPosition: centerCoordinates.x,
      yPosition: centerCoordinates.y
    });
  }

  return {
    downloadMeteringConceptPDFMutation,
    deleteTextElementNodeMutation,
    deleteContainerElementNodeMutation,
    updateResizedContainerElementNodeMutation,
    updateTextElementNodeMutation,
    updateNodeIconMutation,
    updateNodePositionMutation,
    updateContainerColorMutation,
    updateTextConfigMutation,
    createTextElementNodeMutation,
    createContainerElementNodeMutation
  };
}
