import classNames from "classnames";
import { useCallback, useEffect, useRef } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import type { ButtonColor } from "../../../../Buttons/Button/Button";
import { Button } from "../../../../Buttons/Button/Button";
import { Icon } from "../../../Icon/Icon";
import { IconName } from "../../../Icon/types";
import { HelperText } from "../../FormField/HelperText/HelperText";
import "./FileUpload.scss";

enum AcceptedFileType {
  CSV = "CSV",
  EXCEL = "Excel",
  TEXT = "Text"
}

export interface FileUploadOptions {
  dropzoneInfoText?: string;
  buttonText?: string;
  buttonColor?: ButtonColor;
  maxFiles?: number;
  accept?: Array<AcceptedFileType>;
}

export interface FileUploadProps {
  name: string;
  id?: string;
  onChange: (value: Array<File>) => void;
  value?: Array<File>;
  options?: FileUploadOptions;
  invalid?: boolean;
  disabled?: boolean;
}

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

function FileUpload({
  onChange,
  invalid,
  disabled = false,
  options: {
    dropzoneInfoText = "Ziehen Sie Ihre Datei(en) einfach in dieses Feld oder klicken Sie, um Ihre Datei(en) auszuwählen.",
    buttonText = "Datei(en) auswählen",
    buttonColor = "brand",
    maxFiles,
    accept
  } = {}
}: FileUploadProps) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      onChange(acceptedFiles);
    },
    [onChange]
  );
  const buttonRef = useRef<HTMLButtonElement>(null);

  const dropzoneAccept = accept?.reduce((acc, type) => {
    switch (type) {
      case AcceptedFileType.CSV:
        acc["text/csv"] = [];
        break;
      case AcceptedFileType.EXCEL:
        acc["application/vnd.ms-excel"] = [];
        acc["application/vnd.ms-excel.sheet.macroEnabled.12"] = [];
        acc[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ] = [];
        break;
      case AcceptedFileType.TEXT:
        acc["text/plain"] = [];
        break;
      default:
        break;
    }

    return acc;
  }, {});

  const {
    getRootProps,
    getInputProps,
    open,
    fileRejections,
    isDragAccept,
    isDragReject,
    isFocused
  } = useDropzone({
    accept: dropzoneAccept,
    onDrop,
    disabled,
    maxFiles,
    maxSize: MAX_FILE_SIZE
  });

  useEffect(() => {
    if (!isFocused) {
      buttonRef.current?.blur();
    }
  }, [isFocused]);

  function handleClickOpenFileInput(e: React.MouseEvent) {
    e.stopPropagation();
    open();
  }

  const mapErrorCodeToErrorMessage = (
    fileName: string,
    code: ErrorCode | string
  ) => {
    switch (code) {
      case ErrorCode.FileInvalidType:
        return t("errors.FileUpload.FileInvalidType", {
          validTypes: accept?.join("-, "),
          fileName
        });
      case ErrorCode.FileTooLarge:
        return t("errors.FileUpload.FileTooLarge", { fileName });
      case ErrorCode.FileTooSmall:
        return t("errors.FileUpload.FileTooSmall", { fileName });
      case ErrorCode.TooManyFiles:
        if (maxFiles && maxFiles > 1) {
          return t("errors.FileUpload.MoreThanMaxAllowedFile", {
            fileName,
            maxFiles
          });
        } else {
          return t("errors.FileUpload.MoreThanOneFile", { fileName });
        }
      default:
        return t("errors.UnknownError");
    }
  };

  return (
    <>
      <div
        className={classNames("FileUpload", {
          "is-invalid": fileRejections.length > 0 || invalid || isDragReject,
          "is-valid": isDragAccept
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>{dropzoneInfoText}</p>
        <div className="upload-icon-container">
          <Icon className="upload-icon" name={IconName.Upload} />
        </div>
        <Button
          className="upload-button"
          color={buttonColor}
          disabled={disabled}
          innerRef={buttonRef}
          onClick={handleClickOpenFileInput}
        >
          {buttonText}
        </Button>
      </div>
      {fileRejections.map((rejection) => (
        <HelperText
          error={rejection.errors
            .map((e) => mapErrorCodeToErrorMessage(rejection.file.name, e.code))
            .join(". ")}
          key={rejection.file.size}
        />
      ))}
    </>
  );
}

export { AcceptedFileType, FileUpload };
