import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { useGenerators } from "../../../../../hooks/useGenerators";
import { MaStRNumberCell } from "../../../../ComponentListContainer/ComponentList/ComponentTable";

export function getMaStRColumn<T extends MRT_RowData>(
  siteId?: number
): MRT_ColumnDef<T> {
  return {
    header: "MaStR-Nummer",
    enableEditing: false,
    enableSorting: true,
    Cell: ({ row }) => (
      <SmartMaStRNumberCell
        generatorId={row.original.generator_id}
        siteId={siteId || row.original.site_id}
      />
    )
  };
}

function SmartMaStRNumberCell({
  siteId,
  generatorId
}: {
  siteId: number;
  generatorId: number;
}) {
  const { data: generators, error } = useGenerators(siteId);

  const endpoint = generators?.find(
    (generator) => generator.id === generatorId
  )?.einheit;

  if (error) return <>{error.message}</>;
  return <MaStRNumberCell endpoint={endpoint} />;
}
