import type { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useConsumptionShare } from "../../hooks/useConsumptionShare";
import type {
  ConsumptionShareModel,
  DateRangeConsumptionShare,
  Meter
} from "../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { AlertColor } from "../Alert/Alert";
import { IconAlert } from "../BuildingBlocks/IconAlert/IconAlert";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import {
  BasicConfirmationModal,
  BasicConfirmationModalIcon
} from "../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { ConsumptionShareMask } from "./ConsumptionShareMask/ConsumptionShareMask";
import { ConsumptionShareTable } from "./ConsumptionShareTable/ConsumptionShareTable";
import { getNewConsumptionSharesOnAdd } from "./utils/getNewConsumptionSharesOnAdd";

interface ConsumptionShareModalProps {
  onCloseModal: () => void;
  meters: Array<Meter> | undefined;
  invalidateMeterData: () => void;
}
function ConsumptionShareModal({
  onCloseModal,
  meters,
  invalidateMeterData
}: ConsumptionShareModalProps) {
  const { componentId: meterId } = useParams();
  const consumptionShareId = useMemo(() => {
    if (meters) {
      const currentMeter = meters.find((meter) => meter.id === Number(meterId));
      if (currentMeter) {
        return currentMeter.consumptionShare;
      }
    }
  }, [meterId, meters]);
  const [warningModalMessage, setWarningModalMessage] = useState<string | null>(
    null
  );
  const [showSaveWarning, setShowSaveWarning] = useState(false);
  const [dataHasBeenSet, setDataHasBeenSet] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {
    data: consumptionShareData,
    isLoading,
    error,
    saveConsumptionShareData,
    invalidateConsumptionShareData
  } = useConsumptionShare(consumptionShareId, {
    enabled: !!consumptionShareId
  });

  const [consumptionShareModelData, setConsumptionShareModelData] = useState<
    ConsumptionShareModel | undefined
  >();

  const consumers = useMemo(() => {
    const consumerObjects: Array<{ consumer: number; consumerName: string }> =
      [];
    const savedConsumers: Array<number> = [];

    if (consumptionShareData?.dateRangeConsumptionShares) {
      consumptionShareData.dateRangeConsumptionShares.forEach(
        (dateRangeShares) => {
          dateRangeShares.consumerConsumptionShares.forEach((consumer) => {
            if (!savedConsumers.includes(consumer.consumer)) {
              consumerObjects.push({
                consumer: consumer.consumer,
                consumerName: consumer.consumerName
              });
              savedConsumers.push(consumer.consumer);
            }
          });
        }
      );
    }
    return consumerObjects;
  }, [consumptionShareData?.dateRangeConsumptionShares]);

  useEffect(() => {
    if (!dataHasBeenSet && consumptionShareData) {
      setConsumptionShareModelData({
        ...consumptionShareData,
        isConfirmed: true
      });
      setDataHasBeenSet(true);
    }
  }, [consumptionShareData, dataHasBeenSet]);

  const earliestDatePossible: () => DateTime | undefined = () => {
    if (consumptionShareModelData) {
      const dateRangeConsumptionShares =
        consumptionShareModelData.dateRangeConsumptionShares;
      const lastDateInData =
        dateRangeConsumptionShares &&
        dateRangeConsumptionShares[dateRangeConsumptionShares.length - 1]
          .firstDate;
      const newEarliestDatePossible =
        lastDateInData &&
        backendDateOrDateTimeToLuxonDateTime(lastDateInData).plus({ days: 1 });
      if (newEarliestDatePossible) {
        return newEarliestDatePossible;
      }
    }
  };

  function handleAddNewVersion(data: DateRangeConsumptionShare) {
    const newShares = getNewConsumptionSharesOnAdd(
      consumptionShareModelData,
      data
    );
    if (!newShares) {
      setWarningModalMessage(
        "Keine Daten vorhanden. Bitte fügen Sie Daten hinzu."
      );
    } else if (
      data.consumerConsumptionShares.length !== consumers.length ||
      data.consumerConsumptionShares.some(
        (consumer) => !consumer.consumptionPercentile
      )
    ) {
      setWarningModalMessage(
        "Sie müssen erst alle Verbrauchsanteile einfügen."
      );
    } else if (!data.firstDate) {
      setWarningModalMessage("Sie müssen erst ein Datum einfügen.");
    } else {
      setConsumptionShareModelData(newShares);
    }
  }

  function handleClickSaveConsumptionShares() {
    setShowSaveWarning(true);
  }

  function handleSaveConsumptionShares() {
    setShowSaveWarning(false);
    setIsSaving(true);
    if (consumptionShareModelData && consumptionShareId) {
      saveConsumptionShareData(consumptionShareModelData)
        .then(() => {
          onCloseModal();
        })
        .catch((error) => {
          openErrorAlertPopup(error);
        })
        .finally(() => {
          invalidateConsumptionShareData();
          invalidateMeterData();
          setIsSaving(false);
        });
    }
  }

  function closeSaveWarningModal() {
    setShowSaveWarning(false);
  }

  function closeWarningModal() {
    setWarningModalMessage(null);
  }

  function handleChangeConsumptionShareData(
    index: number,
    consumerId?: number | null,
    percentile?: string | null,
    firstDate?: string | null,
    lastDate?: string | null
  ) {
    let newConsumptionShareData = [
      ...(consumptionShareModelData?.dateRangeConsumptionShares ?? [])
    ];
    let fitPreviousDate = false;
    let fitNextDate = false;
    let newWarningModalMessage = "";

    newConsumptionShareData = newConsumptionShareData.map((share, i) => {
      const newShare = { ...share };
      if (newShare.consumerConsumptionShares && i === index) {
        const foundConsumerIndex = newShare.consumerConsumptionShares.findIndex(
          (c) => c.consumer === consumerId
        );
        if (percentile) {
          if (foundConsumerIndex !== -1) {
            newShare.consumerConsumptionShares[foundConsumerIndex] = {
              ...newShare.consumerConsumptionShares[foundConsumerIndex],
              consumptionPercentile: percentile
            };
          } else {
            newShare.consumerConsumptionShares.push({
              consumer: consumerId ?? 0,
              consumptionPercentile: percentile,
              consumerName:
                consumers.find((consumer) => consumer.consumer === consumerId)
                  ?.consumerName ?? ""
            });
          }
        }

        if (firstDate) {
          const previousShare = newConsumptionShareData?.[i - 1];

          if (previousShare) {
            const newLastDateWithDayBefore =
              backendDateOrDateTimeToLuxonDateTime(firstDate).minus({
                days: 1
              });

            if (
              previousShare.firstDate !== null &&
              backendDateOrDateTimeToLuxonDateTime(previousShare.firstDate) >
                newLastDateWithDayBefore
            ) {
              newWarningModalMessage = "Datum liegt vor dem vorherigen Datum";
            } else {
              fitPreviousDate = true;
            }
          }
          newShare.firstDate = firstDate;
        }

        if (lastDate) {
          const nextShare = newConsumptionShareData?.[i + 1];

          if (nextShare) {
            const newFirstDateWithDayAfter =
              backendDateOrDateTimeToLuxonDateTime(lastDate).plus({ days: 1 });

            if (
              nextShare.lastDate !== null &&
              backendDateOrDateTimeToLuxonDateTime(nextShare.lastDate) <
                newFirstDateWithDayAfter
            ) {
              newWarningModalMessage = "Datum liegt nach dem nächsten Datum";
            } else {
              fitNextDate = true;
            }
          }
          newShare.lastDate = lastDate;
        }
      }

      return newShare;
    });

    if (fitPreviousDate && firstDate && newConsumptionShareData[index - 1]) {
      newConsumptionShareData[index - 1].lastDate =
        luxonDateTimeToBackendDateOrDateTime(
          backendDateOrDateTimeToLuxonDateTime(firstDate).minus({ days: 1 })
        );
    }
    if (fitNextDate && lastDate && newConsumptionShareData[index + 1]) {
      newConsumptionShareData[index + 1].firstDate =
        luxonDateTimeToBackendDateOrDateTime(
          backendDateOrDateTimeToLuxonDateTime(lastDate).plus({ days: 1 })
        );
    }
    if (newWarningModalMessage) {
      setWarningModalMessage(newWarningModalMessage);
    } else if (newConsumptionShareData) {
      setConsumptionShareModelData((prevState) => {
        return {
          ...prevState,
          dateRangeConsumptionShares: newConsumptionShareData
        };
      });
    }
  }

  function handleClickDeleteRow(index: number) {
    const newConsumptionShareData = [
      ...(consumptionShareModelData?.dateRangeConsumptionShares ?? [])
    ];
    if (index === 0) {
      newConsumptionShareData[index + 1].firstDate = null;
    } else if (
      newConsumptionShareData[index - 1] &&
      newConsumptionShareData[index + 1]?.firstDate
    ) {
      newConsumptionShareData[index - 1].lastDate =
        luxonDateTimeToBackendDateOrDateTime(
          backendDateOrDateTimeToLuxonDateTime(
            newConsumptionShareData[index + 1].firstDate ?? ""
          ).minus({ days: 1 })
        );
    } else if (!newConsumptionShareData[index + 1]) {
      newConsumptionShareData[index - 1].lastDate = null;
    }

    newConsumptionShareData.splice(index, 1);
    setConsumptionShareModelData((prevState) => {
      return {
        ...prevState,
        dateRangeConsumptionShares: newConsumptionShareData
      };
    });
  }

  if (!consumptionShareModelData) {
    return null;
  }

  return (
    <div className="consumption-share-modal-container">
      {warningModalMessage && (
        <ConsumptionShareWarningModal
          message={warningModalMessage}
          onCloseModal={closeWarningModal}
        />
      )}
      <BasicConfirmationModal
        confirmationButtonColor="danger"
        confirmationButtonText="Ja"
        headerText="Warnung"
        icon={BasicConfirmationModalIcon.Warning}
        isModalOpen={showSaveWarning}
        toggleModal={closeSaveWarningModal}
        onCancel={closeSaveWarningModal}
        onConfirm={handleSaveConsumptionShares}
      >
        <p>
          Geänderte oder neue Verbrauchsanteile verändern die Mengenermittlung
          in Ihrer Liegenschaft. Möchten Sie die neu angelegten
          Verbrauchsanteile abspeichern?
        </p>
      </BasicConfirmationModal>
      <Modal isOpen size="xl">
        <ModalHeader>Verbrauchsanteile bearbeiten</ModalHeader>
        <ModalBody>
          {!consumptionShareData?.isConfirmed && (
            <IconAlert color={AlertColor.Warning}>
              Überprüfen Sie den Anteil des Verbrauchs, der beim Herstellen der
              Verbindung mit dem Zähler automatisch zugewiesen wurde. Sie können
              den Wert des Verbrauchs direkt in der Tabelle bearbeiten oder eine
              neue Version davon hinzufügen.
            </IconAlert>
          )}
          <LoadOrError error={error} loading={isLoading}>
            <ConsumptionShareMask
              consumers={consumers}
              earliestDatePossible={earliestDatePossible()}
              onAddNewVersion={handleAddNewVersion}
            />
            <ConsumptionShareTable
              consumers={consumers}
              initialData={
                consumptionShareModelData?.dateRangeConsumptionShares ?? []
              }
              onChangeConsumptionShareData={handleChangeConsumptionShareData}
              onClickDeleteRow={handleClickDeleteRow}
            />
          </LoadOrError>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseModal}>Abbrechen</Button>
          <SpinButton
            color="brand"
            spin={isSaving}
            onClick={handleClickSaveConsumptionShares}
          >
            Speichern
          </SpinButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}

interface ConsumptionShareWarningModalProps {
  onCloseModal: () => void;
  message: string;
}
function ConsumptionShareWarningModal({
  onCloseModal,
  message
}: ConsumptionShareWarningModalProps) {
  return (
    <div>
      <Modal isOpen>
        <ModalHeader>Warnung</ModalHeader>
        <ModalBody>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseModal}>Abbrechen</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { ConsumptionShareModal };
