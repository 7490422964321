import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStartPageOnboardingProgress } from "../../../hooks/startPage/useStartPageOnboardingProgress";
import { useStartPageSites } from "../../../hooks/startPage/useStartPageSites";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { Badge } from "../../BuildingBlocks/Badge/Badge";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { StartPageOnboardingProgress } from "../StartPage.types";
import type { TableLightColumn } from "./TableLight/TableLight";
import { TableLight } from "./TableLight/TableLight";
import { Tile } from "./Tile";

interface OnboardingProgressTileProps {
  projectId?: string;
}

function OnboardingProgressTile({ projectId }: OnboardingProgressTileProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [storedOnboardingSiteId, setStoredOnboardingSiteId] = useSessionStorage<
    number | undefined
  >("startPageOnboardingSiteId", undefined);

  const { sites } = useStartPageSites(projectId);
  const {
    onboardingProgress,
    error: onboardingProgressError,
    isLoading: onboardingProgressLoading
  } = useStartPageOnboardingProgress(
    currentPage,
    pageSize,
    projectId,
    storedOnboardingSiteId
  );

  const columns: Array<TableLightColumn<StartPageOnboardingProgress>> = [
    { accessor: "projectId", hidden: true },
    { accessor: "siteId", hidden: true },
    { accessor: "siteName" },
    {
      accessor: "phaseName",
      render: (phaseName, onboardingProgress) => (
        <Link
          to={`../projekte/${onboardingProgress.projectId}/liegenschaften/${onboardingProgress.siteId}`}
        >
          {phaseName}
        </Link>
      )
    },
    {
      accessor: "phaseStatus",
      render: () => <Badge color="warning">Offen</Badge>
    }
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [projectId, storedOnboardingSiteId]);

  function handleSiteChange(newSiteId: number | undefined) {
    setCurrentPage(1);
    setStoredOnboardingSiteId(newSiteId);
  }

  function handleSwitchPage(newCurrentPage: number, newPageSize: number) {
    setCurrentPage(newCurrentPage);
    setPageSize(newPageSize);
  }

  const allSitesChoice = { value: undefined, label: "Alle Liegenschaften" };
  const sitesOptions = sites
    ? [
        allSitesChoice,
        ...sites.map((site) => ({
          label: site.name,
          value: site.id
        }))
      ]
    : [];

  return (
    <Tile
      error={onboardingProgressError}
      filterOptions={sitesOptions}
      filterValue={sitesOptions.find(
        (site) => site.value === storedOnboardingSiteId
      )}
      iconName={IconName.MapPin}
      isLoading={onboardingProgressLoading}
      title="Onboarding-Fortschritt"
      onFilterChange={(newFilter) => handleSiteChange(newFilter.value)}
    >
      {onboardingProgress && (
        <TableLight<StartPageOnboardingProgress>
          columns={columns}
          count={onboardingProgress.count}
          currentPage={currentPage}
          data={onboardingProgress.results}
          NoDataComponent={NoDataComponent}
          pageSize={pageSize}
          onCurrentPageChange={(newCurrentPage) =>
            handleSwitchPage(newCurrentPage, pageSize)
          }
          onPageSizeChange={(newPageSize) => handleSwitchPage(1, newPageSize)}
        />
      )}
    </Tile>
  );
}

function NoDataComponent() {
  return <h3 className="NoDataComponent">Super, alles erledigt!</h3>;
}

export { OnboardingProgressTile, OnboardingProgressTileProps };
