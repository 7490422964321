import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface DeleteSiteModalBodyElementProps {
  siteName: string;
  onVerificationTextChange: (isCorrect: boolean) => void;
}

function DeleteSiteModalBodyElement({
  siteName,
  onVerificationTextChange
}: DeleteSiteModalBodyElementProps) {
  const [value, setValue] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    onVerificationTextChange(event.target.value === siteName);
  }

  return (
    <div className="DeleteSiteModalBodyElement">
      <p>
        Sind Sie sicher, dass Sie diese Liegenschaft löschen möchten? Dieser
        Schritt kann nicht rückgängig gemacht werden.
      </p>
      <FormGroup>
        <Label for="confirm-delete">
          Geben Sie zur Bestätigung den Namen der Liegenschaft{" "}
          <mark>{siteName}</mark> in das Textfeld ein.
        </Label>
        <Input
          id="confirm-delete"
          type="text"
          value={value}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
}

export { DeleteSiteModalBodyElement };
