import "./FormBody.scss";
import { ModalBody } from "../../Layout/Modal/Modal";

interface FormBodyProps {
  children?: React.ReactNode;
}

function FormBody({ children }: FormBodyProps) {
  return <ModalBody className="FormBody">{children}</ModalBody>;
}

export { FormBody };
