import { useCallback, useEffect, useState } from "react";

import api from "../../api";
import { usePersons } from "../../hooks/usePersons";
import urls from "../../urls";
import type { Person, Variant } from "../../utils/backend-types";
import { Alert, AlertColor } from "../Alert/Alert";
import { ToggleSwitch } from "../BuildingBlocks/Forms/ToggleSwitch/ToggleSwitch";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Section } from "../BuildingBlocks/Layout/Section";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import type { FormErrors } from "../CustomForm/useCustomForm";
import { TsDropdown } from "../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Input } from "../DynamicForm/FormItems/FormField/Input/Input";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { LoadOrError } from "../LoadOrError/LoadOrError";

import "./MoreVariantOptions.scss";

interface MoreVariantOptionsProps {
  isOpen: boolean;
  variantId: number;
  toggleModal: () => void;
}

function MoreVariantOptions({
  isOpen,
  variantId,
  toggleModal
}: MoreVariantOptionsProps) {
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [disregardEegLevy, setDisregardEegLevy] = useState(true);
  const [updatingDisregardEegLevy, setUpdatingDisregardEegLevy] =
    useState(true);
  const [disregardEegLevyErrors, setDisregardEegLevyErrors] = useState<
    Array<string>
  >([]);
  const [bundleConsumers, setBundleConsumers] = useState(true);
  const [updatingBundleConsumers, setUpdatingBundleConsumers] = useState(true);
  const [bundleConsumersErrors, setBundleConsumersErrors] = useState<
    Array<string>
  >([]);
  const [selectedPersonData, setSelectedPersonData] = useState<
    Person | undefined
  >(undefined);
  const [personDataErrors, setPersonDataErrors] = useState({});

  const loadBundleConsumersOption = useCallback(() => {
    return api
      .get<Variant>(urls.api.variantDetail(variantId))
      .then((response) => {
        setBundleConsumers(response.data.bundleConsumers);
        setUpdatingBundleConsumers(false);
      });
  }, [variantId]);

  const loadDisregardEegLevy = useCallback(() => {
    return api
      .get<Variant>(urls.api.variantDetail(variantId))
      .then((response) => {
        setDisregardEegLevy(response.data.disregardEegLevy);
        setUpdatingDisregardEegLevy(false);
      });
  }, [variantId]);

  useEffect(() => {
    setOptionsLoading(true);

    Promise.all([loadBundleConsumersOption(), loadDisregardEegLevy()])
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => setOptionsLoading(false));
    setPersonDataErrors({});
  }, [loadBundleConsumersOption, loadDisregardEegLevy]);

  useEffect(() => {
    setPersonDataErrors({});
  }, [selectedPersonData]);

  function handleDisregardEegLevyChanged(disregardEeg: boolean) {
    setDisregardEegLevy(disregardEeg);
    setDisregardEegLevyErrors([]);
  }

  async function updateDisregardEegLevy(disregardEeg: boolean) {
    try {
      await api.patch(urls.api.variantDetail(variantId), {
        disregardEegLevy: disregardEeg
      });
    } catch (error) {
      if ("disregardEegLevy" in error.response.data) {
        setDisregardEegLevyErrors(error.response.data.disregardEegLevy);
      }

      throw new Error(error);
    }
  }

  function handleBundlingOptionChanged(shouldBundle: boolean) {
    setBundleConsumers(shouldBundle);
    setBundleConsumersErrors([]);
  }

  function updateBundlingOption(shouldBundle: boolean) {
    return api
      .patch(urls.api.variantDetail(variantId), {
        bundleConsumers: shouldBundle
      })
      .catch((error) => {
        if ("bundleConsumers" in error.response.data) {
          setBundleConsumersErrors(error.response.data.bundleConsumers);
        }

        throw new Error(error);
      });
  }

  const handlePersonDataChanged = useCallback(
    (changedPersonData: Partial<Person>) => {
      setSelectedPersonData((currentPersonData) => {
        if (currentPersonData) {
          return { ...currentPersonData, ...changedPersonData };
        }

        return changedPersonData as Person;
      });
    },
    []
  );

  function updatePersonData(personData): Promise<void> {
    if (!personData) {
      // Don't block saving the other variant options
      return Promise.resolve();
    }

    const { id, ...dataToSend } = personData;
    const formattedData = { ...dataToSend };

    // replace empty strings with null
    if (formattedData.besondereAusgleichsregelungEeg === "") {
      formattedData.besondereAusgleichsregelungEeg = null;
    }

    if (formattedData.besondereAusgleichsregelungKwk === "") {
      formattedData.besondereAusgleichsregelungKwk = null;
    }

    if (formattedData.besondereAusgleichsregelungOffshore === "") {
      formattedData.besondereAusgleichsregelungOffshore = null;
    }

    return api
      .patch(urls.api.person(id), formattedData)
      .then(() => {
        setPersonDataErrors({});
      })
      .catch((error) => {
        if (error.response) {
          setPersonDataErrors(error.response.data);
          throw new Error();
        } else {
          throw new Error(error);
        }
      });
  }

  function handleClickSave() {
    setUpdatingDisregardEegLevy(true);
    setDisregardEegLevyErrors([]);
    setUpdatingBundleConsumers(true);
    setBundleConsumersErrors([]);

    const updateDisregardEegLevyPromise =
      updateDisregardEegLevy(disregardEegLevy);
    const updateBundlingOptionPromise = updateBundlingOption(bundleConsumers);
    const savePersonDataPromise = updatePersonData(selectedPersonData);

    Promise.all([
      updateDisregardEegLevyPromise,
      updateBundlingOptionPromise,
      savePersonDataPromise
    ])
      .then(() => {
        toggleModal();
      })
      .catch((error) => {
        if (error && error.length > 0) {
          openErrorAlertPopup(error);
        }
      })
      .finally(() => {
        setUpdatingDisregardEegLevy(false);
        setUpdatingBundleConsumers(false);
      });
  }

  const disableAndSpinSubmitButton =
    updatingBundleConsumers || updatingDisregardEegLevy;

  return (
    <Modal className="MoreVariantOptions" isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader>Weitere Einstellungen</ModalHeader>
      <ModalBody scrollable>
        <LoadOrError loading={optionsLoading}>
          <Section title="EEG-Umlage">
            <DisregardEegLevy
              checked={disregardEegLevy}
              errors={disregardEegLevyErrors}
              onChange={handleDisregardEegLevyChanged}
            />
          </Section>
          <Section title="Bewertung der Entgelte für Bezug über Netzverknüpfungspunkt">
            <BundlingOption
              disabled={updatingBundleConsumers}
              errors={bundleConsumersErrors}
              value={bundleConsumers}
              onChange={handleBundlingOptionChanged}
            />
          </Section>
          <Section title="Besondere Ausgleichregelung (Stromkostenintensive Unternehmen nach §§ 63 ff. EEG)">
            <BesondereAusgleichsregelung
              errors={personDataErrors}
              selectedPerson={selectedPersonData}
              variantId={variantId}
              onChangePersonData={handlePersonDataChanged}
            />
          </Section>
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Abbrechen
        </Button>
        <SpinButton
          color="primary"
          disabled={disableAndSpinSubmitButton}
          id="save-more-variant-options"
          spin={disableAndSpinSubmitButton}
          onClick={handleClickSave}
        >
          Speichern
        </SpinButton>
      </ModalFooter>
    </Modal>
  );
}

interface DisregardEegLevyProps {
  checked: boolean;
  errors: Array<string>;
  onChange: (checked: boolean) => void;
}

function DisregardEegLevy({
  checked,
  errors,
  onChange
}: DisregardEegLevyProps) {
  function handleToggle() {
    onChange(!checked);
  }

  return (
    <div
      className="FormField"
      id="variant-disregard-eeg-levy"
      style={{ display: "flex", alignItems: "center" }}
    >
      <ToggleSwitch
        checked={checked}
        text="EEG-Umlage für diese Variante nicht berücksichtigen"
        onChange={handleToggle}
      />
      {errors.length > 0 && (
        <Alert color={AlertColor.Danger}>{errors.map((error) => error)}</Alert>
      )}
    </div>
  );
}

interface BundlingOptionProps {
  value: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
  errors: Array<string>;
}

function BundlingOption({
  value,
  disabled,
  onChange,
  errors
}: BundlingOptionProps) {
  return (
    <div className="FormField m-radio-inline" id="variant-bundling-option">
      <label className="m-radio">
        <input
          checked={value === true}
          className="m-radio"
          disabled={disabled}
          name="bundling"
          type="radio"
          value="true"
          onChange={() => onChange(true)}
        />
        Netzbezüge der Verbraucher bündeln<span></span>
      </label>
      <label className="m-radio">
        <input
          checked={value === false}
          className="m-radio"
          disabled={disabled}
          name="bundling"
          type="radio"
          value="false"
          onChange={() => onChange(false)}
        />
        Netzbezüge der Verbraucher separat bewerten<span></span>
      </label>
      {errors.length > 0 && (
        <Alert color={AlertColor.Danger}>{errors.map((error) => error)}</Alert>
      )}
    </div>
  );
}

interface BesondereAusgleichsregelungProps {
  selectedPerson?: Person;
  variantId: number;
  errors: FormErrors;
  onChangePersonData: (person: Partial<Person>) => void;
}

function BesondereAusgleichsregelung({
  selectedPerson,
  variantId,
  errors,
  onChangePersonData
}: BesondereAusgleichsregelungProps) {
  const { isLoading, error, persons } = usePersons(variantId);
  useEffect(() => {
    if (persons) {
      onChangePersonData(persons[0]);
    }
  }, [persons, onChangePersonData]);

  function handleSelectedPersonChanged(_: string, personId: number): void {
    const person = persons?.find((person) => person.id === personId);

    if (person) {
      onChangePersonData(person);
    }
  }

  if (isLoading || error) {
    return <LoadOrError error={error} loading={isLoading} />;
  }

  if (!persons || persons.length === 0) {
    return (
      <div className="no-people-error">
        <p>Diese Einstellung kann ohne eine Person nicht getätigt werden.</p>
      </div>
    );
  }

  const peopleChoices = persons.map((person) => {
    return { value: person.id, displayName: person.name };
  });

  return (
    <LoadOrError loading={!selectedPerson}>
      {selectedPerson && (
        <div>
          <div className="form-group person-dropdown">
            <label className="col-form-label">Person</label>
            <TsDropdown
              choices={peopleChoices}
              defaultValue={selectedPerson.id}
              name="person"
              required
              onChange={handleSelectedPersonChanged}
            />
          </div>
          <div className="person-specific-options">
            {Object.values(selectedPerson).length > 0 ? (
              <PersonSpecificOptions
                errors={errors}
                personData={selectedPerson}
                onPersonDataChange={onChangePersonData}
              />
            ) : (
              <AnimatedLoadingIcon />
            )}
          </div>
        </div>
      )}
    </LoadOrError>
  );
}

interface PersonSpecificOptionsProps {
  personData: Person;
  errors: FormErrors;
  onPersonDataChange: (person: Partial<Person>) => void;
}

function PersonSpecificOptions({
  personData,
  errors,
  onPersonDataChange
}: PersonSpecificOptionsProps) {
  return (
    <div>
      <div className="options">
        <Option
          errorTexts={errors["besondereAusgleichsregelungEeg"]}
          inputDomId="variant-options-person-eeg"
          text="Besondere Ausgleichsregelung EEG: Tarif über 1 Mio. kWh"
          unit="ct/kWh"
          value={personData.besondereAusgleichsregelungEeg}
          onInputChange={(value) =>
            onPersonDataChange({ besondereAusgleichsregelungEeg: value })
          }
        />
        <Option
          errorTexts={errors["besondereAusgleichsregelungKwk"]}
          inputDomId="variant-options-person-kwk"
          text="Besondere Ausgleichsregelung KWK-Aufschlag: Tarif über 1 Mio. kWh"
          unit="ct/kWh"
          value={personData.besondereAusgleichsregelungKwk}
          onInputChange={(value) =>
            onPersonDataChange({ besondereAusgleichsregelungKwk: value })
          }
        />
        <Option
          errorTexts={errors["besondereAusgleichsregelungOffshore"]}
          inputDomId="variant-options-person-offshore"
          text="Besondere Ausgleichsregelung Offshore-Netzumlage: Tarif über 1 Mio. kWh"
          unit="ct/kWh"
          value={personData.besondereAusgleichsregelungOffshore}
          onInputChange={(value) =>
            onPersonDataChange({ besondereAusgleichsregelungOffshore: value })
          }
        />
      </div>
    </div>
  );
}

interface OptionProps {
  text: string;
  value: number | null;
  inputDomId: string;
  unit: string;
  errorTexts: Array<string>;
  onInputChange: (value: number) => void;
}

function Option({
  text,
  value,
  inputDomId,
  unit,
  errorTexts,
  onInputChange
}: OptionProps) {
  return (
    <div className="form-group option">
      <label className="col-form-label" htmlFor={inputDomId}>
        {text}
      </label>
      <div className="input-and-unit">
        <Input
          errorTexts={errorTexts}
          id={inputDomId}
          inputGroupText={unit}
          min={0}
          name={inputDomId}
          step={0.1}
          type="number"
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
}

export { MoreVariantOptions };
