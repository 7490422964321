import { ActionIcon, Box, Group } from "@mantine/core";
import { useState } from "react";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import "./ColorSelectionModal.scss";

const COLORS = [
  { value: "#88DDD9", name: "Türkis" },
  { value: "#96D6B9", name: "Grün" },
  { value: "#FECA99", name: "Orange" },
  { value: "#FDE498", name: "Gelb" },
  { value: "#EDDEFF", name: "Rosa" },
  { value: "#F29E91", name: "Rot" },
  { value: "#C3E5ED", name: "Blau" },
  { value: "#E4E5E7", name: "Grau" }
] as const;

const DEFAULT_COLOR = COLORS[COLORS.length - 1].value;

interface ColorSelectionModalProps {
  isOpen?: boolean;
  initialColor?: string;
  onColorChange: (color: string) => void;
  onClose: () => void;
}

function ColorSelectionModal({
  isOpen,
  initialColor,
  onColorChange,
  onClose
}: ColorSelectionModalProps) {
  const [selectedColor, setSelectedColor] = useState(
    initialColor ?? DEFAULT_COLOR
  );

  function handleModalCancel() {
    onClose();
    setSelectedColor(initialColor ?? DEFAULT_COLOR);
  }

  return (
    <BasicConfirmationModal
      className="ColorSelectionModal"
      confirmationButtonText="Speichern"
      headerText="Farbe wählen"
      isModalOpen={isOpen ?? false}
      toggleModal={handleModalCancel}
      onCancel={handleModalCancel}
      onConfirm={() => {
        onColorChange(selectedColor);
        onClose();
      }}
    >
      <Group gap="sm" justify="center">
        {COLORS.map((color) => (
          <ActionIcon
            aria-label={color.name}
            className={
              color.value === selectedColor ? "color-selected" : undefined
            }
            key={color.value}
            radius="xl"
            size="xl"
            variant="transparent"
            onClick={() => setSelectedColor(color.value)}
          >
            <Box bg={color.value} className="color-pane" />
          </ActionIcon>
        ))}
      </Group>
    </BasicConfirmationModal>
  );
}

export { ColorSelectionModal };
