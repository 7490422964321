import { Flex, ScrollArea } from "@mantine/core";
import {
  StartPageTileDisplay_mode,
  StartPageTileName
} from "../../../types/api.types";
import type { StartPageTile } from "../StartPage.types";
import { CO2SavingsMiniTile } from "./CO2SavingsMiniTile";
import { DeliveredElectricityMiniTile } from "./DeliveredElectricityMiniTile";
import { EEG6MiniTile } from "./EEG6MiniTile";
import { ElectricityTaxMiniTile } from "./ElectricityTaxMiniTile";
import { InvoicesMiniTile } from "./InvoicesMiniTile";
import { ProducedElectricityMiniTile } from "./ProducedElectricityMiniTile";
import "./MySitesInNumbers.scss";

interface MySitesInNumbersProps {
  projectId?: string;
  tiles: Array<StartPageTile>;
  year: number;
}

function MySitesInNumbers({ projectId, tiles, year }: MySitesInNumbersProps) {
  return (
    <ScrollArea className="MySitesInNumbers" pb="xs">
      <Flex gap="lg" pb="xs">
        {tiles
          .filter(
            (tile) => tile.display_mode !== StartPageTileDisplay_mode.Hide
          )
          .map((tile) => {
            switch (tile.name) {
              case StartPageTileName.Invoices:
                return (
                  <InvoicesMiniTile
                    key={"invoices-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              case StartPageTileName.DeliveredElectricity:
                return (
                  <DeliveredElectricityMiniTile
                    key={"delivered-electricity-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              case StartPageTileName.ProducedElectricity:
                return (
                  <ProducedElectricityMiniTile
                    key={"produced-electricity-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              case StartPageTileName.Co2Savings:
                return (
                  <CO2SavingsMiniTile
                    key={"co2-savings-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              case StartPageTileName.EEG6:
                return (
                  <EEG6MiniTile
                    bookable={
                      tile.display_mode === StartPageTileDisplay_mode.Bookable
                    }
                    key={"eeg6-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              case StartPageTileName.ElectricityTax:
                return (
                  <ElectricityTaxMiniTile
                    key={"electricity-tax-mini-tile"}
                    projectId={projectId}
                    year={year}
                  />
                );
              default:
                return null;
            }
          })}
      </Flex>
    </ScrollArea>
  );
}

export { MySitesInNumbers, MySitesInNumbersProps };
