import { Anchor, List, Text, Title } from "@mantine/core";
import type { Meter } from "../../../../../../utils/backend-types";
import { Alert, AlertColor } from "../../../../../Alert/Alert";

interface SiteInfoProps {
  loading: boolean;
  loadingFailed: boolean;
  noMeters: boolean;
  unplausibleMeters: Array<Meter>;
  onClickMeter: (id: number) => void;
}

function SiteInfo({
  loading,
  loadingFailed,
  noMeters,
  unplausibleMeters,
  onClickMeter
}: SiteInfoProps) {
  let alertColour = AlertColor.Success;
  let alertMessage: string | React.ReactNode = `
      Zum aktuellen Zeitpunkt haben wir keine Hinweise auf unplausible Werte in
      den hochgeladenen Zeitreihen gefunden. Zeiträume ohne Energiedaten werden
      nicht bewertet.`;

  if (loading) {
    alertColour = AlertColor.Info;
    alertMessage = "Die Energiedaten werden auf Plausbilität geprüft...";
  } else if (loadingFailed) {
    alertColour = AlertColor.Danger;
    alertMessage =
      "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.";
  } else if (unplausibleMeters.length > 0) {
    alertColour = AlertColor.Warning;
    alertMessage = (
      <>
        <Title fw={500} mb={5} order={5}>
          Hinweis auf unplausible Daten:
        </Title>
        <Text mb="sm">
          Bei der Überprüfung der Energiedaten haben wir Auffälligkeiten
          gefunden. Bitte prüfen Sie, ob es sich beispielsweise um fehlerhafte
          Daten handelt, welche durch Sie korrigiert werden können. Folgende
          Zähler sind betroffen:
        </Text>
        <List size="sm" withPadding>
          {unplausibleMeters.map((meter) => (
            <List.Item key={meter.id}>
              <Anchor
                c="secondary"
                component="span"
                onClick={() => onClickMeter(meter.id)}
              >
                {meter.name}
              </Anchor>
            </List.Item>
          ))}
        </List>
      </>
    );
  } else if (noMeters) {
    alertColour = AlertColor.Info;
    alertMessage = "Keine Zähler vorhanden.";
  }

  return (
    <Alert className="SiteInfo" color={alertColour} mb="lg" w={700}>
      {alertMessage}
    </Alert>
  );
}

export { SiteInfo, SiteInfoProps };
