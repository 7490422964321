import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import { ErrorCell } from "../../TenantTable/ErrorCell/ErrorCell";

export function getMeterNumberColumn<T extends MRT_RowData>(
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof T
  ) => boolean | null | undefined
): MRT_ColumnDef<T> {
  return {
    accessorKey: "number",
    header: "Zählernummer",
    Cell: ({ row }) => {
      const hasError = hasCellError(row.index, "number");
      return (
        <div>
          {row.original.number}
          {hasError && <ErrorCell />}
        </div>
      );
    }
  };
}
