import { useContainerRef } from "../../../../hooks/useContainerRef";
import type { ProjectMinimal } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { CreateManagerProjectForm } from "./CreateManagerProjectForm";

interface CreateProjectModalProps {
  isOpen: boolean;
  toggle: () => void;
  onClickSave: (project: ProjectMinimal) => void;
}

function CreateProjectModal({
  isOpen,
  toggle,
  onClickSave
}: CreateProjectModalProps) {
  const { containerNode, containerRef } = useContainerRef();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Neues Projekt</ModalHeader>
      <ModalBody scrollable>
        <CreateManagerProjectForm
          buttonContainer={containerNode}
          onCancel={toggle}
          onSubmit={onClickSave}
        />
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={containerRef}></div>
      </ModalFooter>
    </Modal>
  );
}

export { CreateProjectModal };
