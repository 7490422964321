import { useEffect, useState } from "react";
import { IconWithLink } from "../../../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import { Input } from "../../../../../DynamicForm/FormItems/FormField/Input/Input";
import { openErrorAlertPopup } from "../../../../../ErrorAlertPopup/openErrorAlertPopup";
import type { Value } from "../PaymentsTab";

interface NumberCellProps {
  value: Value;
  unit: string;
  min?: number;
  max?: number;
  updateValue: (value: Value) => void;
  importValue?: () => Promise<number>;
  disabled?: boolean;
}

function NumberCell({
  value,
  unit,
  min,
  max,
  updateValue,
  importValue,
  disabled = false
}: NumberCellProps) {
  const [localValue, setLocalValue] = useState(value);
  const [importedValueIsLoading, setImportedValueIsLoading] = useState(false);

  // TODO: https://node-energy.atlassian.net/browse/TT-1134
  useEffect(() => setLocalValue(value), [value]);

  function handleChange(newValue: string | number | undefined) {
    if (value !== newValue) {
      let updatedValue = typeof newValue !== "undefined" ? newValue : null;

      if (updatedValue !== null) {
        if (typeof min !== "undefined") {
          updatedValue = Math.max(min, Number(updatedValue));
        }

        if (typeof max !== "undefined") {
          updatedValue = Math.min(max, Number(updatedValue));
        }
      }

      setLocalValue(updatedValue);
      updateValue(updatedValue);
    }
  }

  async function handleClickImportSymbol() {
    if (!importValue) {
      return;
    }

    setImportedValueIsLoading(true);

    try {
      const value = await importValue();
      handleChange(value);
    } catch (error) {
      openErrorAlertPopup(error);
    } finally {
      setImportedValueIsLoading(false);
    }
  }

  const inputDisabled = disabled || importedValueIsLoading;

  return (
    <div className="payments-number-cell">
      <Input
        disabled={inputDisabled}
        inputGroupText={unit}
        max={max}
        min={min}
        type="number"
        value={localValue}
        onChange={handleChange}
      />
      {importValue && (
        <IconWithLink
          className="import-symbol"
          name={IconName.Paste}
          tooltipPlace="right"
          tooltipText="Wert aus Abschlagsplan übernehmen"
          onClick={
            !importedValueIsLoading ? handleClickImportSymbol : undefined
          }
        />
      )}
    </div>
  );
}

export { NumberCell };
