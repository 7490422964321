import type { ComboboxData, SelectProps } from "@mantine/core";
import { NavLink, Select, Stack, Text } from "@mantine/core";
import { IconCheck, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import type { CustomtTextElementNodeEditData } from "../CustomTextElementNode/CustomTextElementNode";
import { PopupBox } from "../PopupBox/PopupBox";
import "./CustomTextElementPopup.scss";

const FONT_SIZES = [12, 14, 16, 18, 20, 24, 32, 46, 60] as const;
const DEFAULT_FONT_SIZE = 18;

const FONT_WEIGHT_OPTIONS = [
  { value: "300", label: "Sehr dünn" },
  { value: "400", label: "Dünn" },
  { value: "500", label: "Normal" },
  { value: "600", label: "Fett" },
  { value: "700", label: "Sehr fett" }
] as const satisfies ComboboxData;
const DEFAULT_FONT_WEIGHT = 600;

const renderFontWeightOption: SelectProps["renderOption"] = ({
  option,
  checked
}) => (
  <>
    {checked && <IconCheck opacity={0.4} size={13} stroke={3} />}
    <Text fw={option.value} size="xs">
      {option.label}
    </Text>
  </>
);

interface CustomTextElementPopupProps {
  x: number;
  y: number;
  nodeId: string;
  currentFontWeight?: number;
  currentFontSize?: number;
  textContent?: string;
  onClickDelete: (nodeId: string) => void;
  onClickEdit: ({
    nodeId,
    textContent
  }: CustomtTextElementNodeEditData) => void;
  onEditTextConfig: (fontWeight: number, fontSize: number) => void;
}

function CustomTextElementPopup({
  x,
  y,
  nodeId,
  currentFontSize,
  currentFontWeight,
  textContent,
  onClickDelete,
  onClickEdit,
  onEditTextConfig
}: CustomTextElementPopupProps) {
  const [selectedFontSize, setSelectedFontSize] = useState(currentFontSize);
  const [selectedFontWeight, setSelectedFontWeight] =
    useState(currentFontWeight);

  function handleChangeFontWeight(newWeightOption: string) {
    const newWeight = Number(newWeightOption);
    setSelectedFontWeight(newWeight);
    onEditTextConfig(newWeight, selectedFontSize ?? DEFAULT_FONT_SIZE);
  }

  function handleChangeFontSize(newSizeOption: string) {
    const newSize = Number(newSizeOption);
    setSelectedFontSize(newSize);
    onEditTextConfig(selectedFontWeight ?? DEFAULT_FONT_WEIGHT, newSize);
  }

  return (
    <PopupBox className="CustomTextElementPopup" position={{ x, y }}>
      <Stack gap="xs" mb="xs">
        <Select
          allowDeselect={false}
          data={FONT_SIZES.map((s) => s.toString())}
          label="Größe"
          size="xs"
          value={selectedFontSize?.toString()}
          onChange={handleChangeFontSize}
        />
        <Select
          allowDeselect={false}
          data={FONT_WEIGHT_OPTIONS}
          label="Stärke"
          renderOption={renderFontWeightOption}
          size="xs"
          value={selectedFontWeight?.toString()}
          onChange={handleChangeFontWeight}
        />
      </Stack>
      <NavLink
        component="button"
        label="Text bearbeiten"
        leftSection={<IconPencil size="16" />}
        onClick={() => onClickEdit({ nodeId, textContent: textContent || "" })}
      />
      <NavLink
        component="button"
        label="Text löschen"
        leftSection={<IconTrash color="red" size="16" />}
        onClick={() => onClickDelete(nodeId)}
      />
    </PopupBox>
  );
}

export { CustomTextElementPopup };
