import { Highlight, Stack, Text, UnstyledButton } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { DateTime } from "luxon";
import type { HistoryLog } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { getTimeAgoString } from "../../../utils/dates/getTimeAgoString";
import type { FieldNameToLabelMap } from "../HistoryTab";
import { HistoryLogActionVerbs } from "../HistoryTab.constants";
import { HistoryAction } from "./HistoryAction";
import { HistoryUser } from "./HistoryUser";

interface HistoryItemProps {
  historyLog: HistoryLog;
  fieldNameToLabelMap: FieldNameToLabelMap;
  highlight?: string;
}

function HistoryItem({
  historyLog,
  fieldNameToLabelMap,
  highlight = ""
}: HistoryItemProps) {
  const datetime = backendDateOrDateTimeToLuxonDateTime(historyLog.timestamp);
  const [showRelativeTime, setShowRelativeTime] = useLocalStorage({
    key: "showRelativeTime",
    defaultValue: true
  });

  const handleToggleTime = () => {
    setShowRelativeTime(!showRelativeTime);
  };

  return (
    <Stack gap={0}>
      <Text>
        <Highlight highlight={highlight} span>
          {historyLog.object_label}
        </Highlight>{" "}
        wurde {HistoryLogActionVerbs[historyLog.action]}.
      </Text>
      <HistoryAction
        fieldNameToLabelMap={fieldNameToLabelMap}
        highlight={highlight}
        historyLog={historyLog}
      />
      <HistoryUser highlight={highlight} userName={historyLog.user} />
      <UnstyledButton title="Zeitformat ändern" onClick={handleToggleTime}>
        <Text size="xs">
          {showRelativeTime
            ? getTimeAgoString(datetime)
            : datetime.toLocaleString(DateTime.DATETIME_MED)}
        </Text>
      </UnstyledButton>
    </Stack>
  );
}

export { HistoryItem, HistoryItemProps };
