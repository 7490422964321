import { type ComboboxData } from "@mantine/core";
import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import type { Mutable } from "utility-types";
import { ControlledMantineEditSelect } from "../../../../../mantine/mrt/components/edit/ControlledMantineEditSelect";
import { isMrtCreating } from "../../../../../mantine/mrt/utils/isMrtCreating";
import type { ValidationErrors } from "../../../../../types/inlineEditTable.types";
import type { MeteringOrMarketLocation } from "../../../../../utils/backend-types";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";

interface ZaehlpunktColumnProps {
  validationErrors: ValidationErrors;
  meloMalos?: Array<MeteringOrMarketLocation>;
  suggestedMeloMaloId?: number;
  overrideMeteringOrMarketLocationId?: number;
  isLoading?: boolean;
}

export function getZaehlpunktColumn<T extends MRT_RowData>({
  validationErrors,
  meloMalos,
  suggestedMeloMaloId,
  overrideMeteringOrMarketLocationId,
  isLoading
}: ZaehlpunktColumnProps): MRT_ColumnDef<T> {
  const meloMaloChoices: ComboboxData =
    meloMalos
      ?.map((meloMalo) => ({
        value: meloMalo.id.toString(),
        label: meloMalo.name
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const groupedMeloMaloChoices: Mutable<ComboboxData> = [];
  if (suggestedMeloMaloId) {
    const suggestedMeloMalo = meloMalos?.find(
      (meloMalo) => meloMalo.id === suggestedMeloMaloId
    );

    if (suggestedMeloMalo) {
      groupedMeloMaloChoices.push({
        group: "Automatischer Vorschlag",
        items: [
          {
            value: suggestedMeloMalo.id.toString(),
            label: suggestedMeloMalo.name
          }
        ]
      });

      const otherMeloMalos = meloMalos?.filter(
        (meloMalo) => meloMalo.id !== suggestedMeloMaloId
      );

      if (otherMeloMalos) {
        groupedMeloMaloChoices.push({
          group: "Zählpunkte zur Auswahl",
          items: otherMeloMalos
            .map((meloMalo) => ({
              value: meloMalo.id.toString(),
              label: meloMalo.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        });
      }
    }
  }

  return {
    accessorKey: "metering_or_market_location_id",
    header: "Zählpunkt",
    editVariant: "select",
    enableSorting: true,
    Cell: ({ row }) => row.original.metering_or_market_location_display_name,
    Edit: ({ cell, column, row, table }) => {
      const shouldOverrideValue = isMrtCreating(table);
      return (
        <LoadOrError loading={isLoading}>
          <ControlledMantineEditSelect
            cell={cell}
            columnId={column.id}
            data={
              suggestedMeloMaloId ? groupedMeloMaloChoices : meloMaloChoices
            }
            disabled={!meloMalos || meloMalos.length === 0}
            errorText={validationErrors.meteringOrMarketLocationId}
            label="Zählpunkt"
            overrideValue={
              shouldOverrideValue
                ? overrideMeteringOrMarketLocationId?.toString()
                : undefined
            }
            required
            row={row}
          />
        </LoadOrError>
      );
    }
  };
}
