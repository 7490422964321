import type { CustomFlowNodeType } from "../CustomGraphComponents/CustomFlowNode/CustomFlowNode";
import type { EdgeData, NodeData } from "../StructureViewFlowDiagram.types";
import { getConnectedSourceEdgesOfNode } from "./getConnectedSourceEdgesOfMeter";

export function getFlowNodesFromNodes(
  nodes: Array<NodeData>,
  edges: Array<EdgeData>
): Array<CustomFlowNodeType> {
  return nodes.map((node) => {
    return {
      id: node.id.toString(),
      position: { x: node.xRelative ?? node.x, y: node.yRelative ?? node.y },
      positionAbsolute: { x: node.x, y: node.y },
      parentId: node.parent,
      data: {
        id: node.id.toString(),
        position: { x: node.xRelative ?? node.x, y: node.yRelative ?? node.y },
        active: node.data?.active,
        label: node.label,
        image: node.imageUrl,
        imageColor: node.data?.color,
        size: node.size,
        type: node.data?.type,
        componentId: node.data?.componentId,
        tooltipData: node.tooltipData,
        meterBillingRelevantOrSubmeter:
          node.data?.meterBillingRelevantOrSubmeter,
        meterPriorityDescription: node.data?.meterPriorityDescription,
        connectedSourceEdges: getConnectedSourceEdgesOfNode(
          node.id.toString(),
          edges
        )
      },
      type: "custom"
    };
  });
}
