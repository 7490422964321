import { THEME_VARS } from "../../../../../utils/constants";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { EnergyDataFileImportRequestStatus } from "../../../EnergyDataUploadFlow/EnergyDataUpload";

interface Props {
  file: EnergyDataImportRequest;
}

function IconCell({ file }: Props) {
  switch (file.status) {
    case EnergyDataFileImportRequestStatus.InProgress:
      return (
        <Icon
          name={IconName.SpinnerSpinning}
          style={{ color: THEME_VARS.brandColor }}
        />
      );
    case EnergyDataFileImportRequestStatus.Stopped:
      return (
        <Icon name={IconName.Pause} style={{ color: THEME_VARS.brandColor }} />
      );
    case EnergyDataFileImportRequestStatus.Error:
      return (
        <Icon name={IconName.Times} style={{ color: THEME_VARS.dangerColor }} />
      );
    case EnergyDataFileImportRequestStatus.Success:
      return (
        <Icon
          name={IconName.Check}
          style={{ color: THEME_VARS.successColor }}
        />
      );
    default:
      return null;
  }
}

export { IconCell };
