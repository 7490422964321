import PropTypes from "prop-types";
import { PureComponent } from "react";
import { Col, Row } from "reactstrap";
import urls from "../../urls";
import { getDataUrlsForSelectedVariantIds } from "../../utils/getDataUrlsForSelectedVariantIds";
import { EnergyFlows } from "../EnergyFlows/EnergyFlows";
import { DescriptionHeader } from "../Layout/DescriptionHeader";
import "./EnergyFlowsPage.scss";

class EnergyFlowsPage extends PureComponent {
  render() {
    const { selectedVariantIds, formatForReport } = this.props;

    const selectedDataUrls = getDataUrlsForSelectedVariantIds(
      selectedVariantIds,
      urls.api.meteringConceptEnergyFlows
    );
    const selectedVariantId = selectedVariantIds[0];

    return (
      <div className="EnergyFlowsPage">
        <Row>
          <Col className="mb-4">
            <DescriptionHeader description="Sehen Sie die bilanziellen Energieflüsse dieses Projektes" />
          </Col>
        </Row>
        <Row>
          <Col>
            <EnergyFlows
              formatForReport={formatForReport}
              selectedDataUrls={selectedDataUrls}
              selectedVariantId={selectedVariantId}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

EnergyFlowsPage.propTypes = {
  displayNames: PropTypes.object,
  selectedVariantIds: PropTypes.array,
  formatForReport: PropTypes.bool
};

export { EnergyFlowsPage };
