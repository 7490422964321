import { Group } from "@mantine/core";
import * as Sentry from "@sentry/browser";
import { IconSolarPanel2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useManagerVariant } from "../../../../hooks/useManagerVariant";
import { usePersons } from "../../../../hooks/usePersons";
import { FormField } from "../../../BuildingBlocks/Forms/FormField/FormField";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { type Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { type PVPlantWithMeter } from "../../SiteSetupAssistant.types";

interface PlantWithoutSEEProps {
  pvPlants: Array<PVPlantWithMeter>;
  index: number;
  error?: string;
  onChange: (data: Array<PVPlantWithMeter>, index: number) => void;
  onDelete: (index: number) => void;
}

function PlantWithoutSEE({
  pvPlants,
  index,
  error,
  onChange,
  onDelete
}: PlantWithoutSEEProps) {
  const { projectId } = useParams();
  const { variantId } = useManagerVariant(projectId);

  const { persons, error: companiesError, isLoading } = usePersons(variantId);

  const [operatorOptions, setOperatorOptions] = useState<Array<Choice>>([]);

  useEffect(() => {
    if (!isLoading && !error) {
      const usedOperatorNames = pvPlants
        .filter((pv) => pv.operator && pv.operator.name)
        .map((pv) => pv.operator?.name || "unbekannt");
      const uniqueOperatorOptions = Array.from(
        new Set(
          persons.map((person) => person.name).concat(...usedOperatorNames)
        )
      ).map((name) => ({ value: name, displayName: name }));

      setOperatorOptions(uniqueOperatorOptions);
    }
  }, [isLoading, error, persons, pvPlants]);

  const pvPlant = pvPlants[index];

  function handleChangeName(index: number, value: string) {
    const updatedPvPlants = pvPlants.map((pvPlant, idx) => {
      if (idx === index) {
        return {
          ...pvPlant,
          name: value || null,
          meter: { ...pvPlant.meter, name: "Zähler " + value }
        };
      }
      return pvPlant;
    });
    onChange(updatedPvPlants, index);
  }

  function handleCreateOperator(index: number, value: string) {
    setOperatorOptions(operatorOptions.concat({ value, displayName: value }));
    handleChangeOperator(index, value);
  }

  function handleChangeOperator(index: number, value: string) {
    const updatedPvPlants = pvPlants.map((pvPlant, idx) => {
      if (idx === index) {
        return {
          ...pvPlant,
          operator: {
            name: value
          }
        };
      }
      return pvPlant;
    });
    onChange(updatedPvPlants, index);
  }

  if (!variantId) {
    console.error("A variant ID is required for PlantWithoutSEE component.");
    Sentry.captureException(
      new Error(
        "A variant ID is required for PlantWithoutSEE component at " +
          window.location.href
      )
    );
    return null;
  }

  return (
    <Group align="end" className="PlantWithoutSEE  single-plant" gap="sm">
      <LoadOrError error={companiesError} loading={isLoading}>
        <Group pb={error ? "xl" : "md"}>
          <IconSolarPanel2 />
        </Group>
        <FormField
          error={error}
          label="Bezeichnung der Anlage"
          type="text"
          value={pvPlant.name ?? undefined}
          onChange={(event) => handleChangeName(index, event.target.value)}
        />
        <FormField
          choices={operatorOptions}
          error={error}
          label="Name der Betreibergesellschaft"
          name="operator"
          type="dropdown"
          value={pvPlant.operator?.name ?? undefined}
          onChange={(value) => handleChangeOperator(index, value)}
          onCreateOption={(value) => handleCreateOperator(index, value)}
        />
        <Group pb={error ? "xl" : "md"}>
          <Icon
            className="delete-icon"
            name={IconName.Trash}
            tooltipText="Anlage löschen"
            onClick={() => onDelete(index)}
          />
        </Group>
      </LoadOrError>
    </Group>
  );
}

export { PlantWithoutSEE };
