import { useMemo, useState } from "react";
import type { SortingRule, TableProps } from "react-table";
import ReactTable from "react-table";

import { COMMON_PAGINATION_PROPS } from "./constants";
import type { CustomReactTableWithSelectProps } from "./CustomReactTableWithSelect/CustomReactTableWithSelect";
import { CustomReactTableWithSelect } from "./CustomReactTableWithSelect/CustomReactTableWithSelect";
import { usePageSize } from "./usePageSize";
import { replaceColumnsWithCustomHeaders } from "./utils/replaceColumnsWithCustomHeaders";

import "./CustomReactTable.scss";

interface CustomReactTableProps<T>
  extends Omit<Partial<TableProps<T>>, "onPageSizeChange"> {
  dataName?: string;
  setTableRef?: (ref: ReactTable<T> | null) => void;
}

function CustomReactTable<T>({
  columns,
  data,
  dataName,
  defaultSorted,
  pageSize: maxPageSize,
  showPagination = false,
  showPageJump = false,
  onSortedChange,
  setTableRef,
  ...otherProps
}: CustomReactTableProps<T>) {
  const [sortedColumns, setSortedColumns] = useState(defaultSorted);
  const { pageSize, setPageSize } = usePageSize<T>(data, maxPageSize);
  const columnsWithCustomHeader = useMemo(
    () => replaceColumnsWithCustomHeaders(columns, sortedColumns),
    [columns, sortedColumns]
  );

  function handleSortedChange(
    newSorted: Array<SortingRule>,
    column: unknown,
    additive: boolean
  ) {
    if (onSortedChange) {
      onSortedChange(newSorted, column, additive);
    }

    return setSortedColumns(newSorted);
  }

  const tableRef = useMemo(() => {
    if (!setTableRef) {
      return undefined;
    }

    return (ref: ReactTable<T> | null) => setTableRef(ref);
  }, [setTableRef]);

  return (
    <ReactTable<T>
      columns={columnsWithCustomHeader}
      data={data}
      defaultSorted={defaultSorted}
      NoDataComponent={() => <NoDataComponent name={dataName} />}
      pageSize={pageSize}
      ref={tableRef}
      showPageJump={showPageJump}
      showPagination={showPagination}
      onPageSizeChange={setPageSize}
      onSortedChange={handleSortedChange}
      {...COMMON_PAGINATION_PROPS}
      {...otherProps}
    />
  );
}

interface CustomReactSelectTableProps<T>
  extends Omit<CustomReactTableWithSelectProps<T>, "onPageSizeChange"> {
  dataName?: string;
}

function CustomReactSelectTable<T>({
  columns,
  data,
  defaultSorted,
  pageSize: maxPageSize,
  showPagination = false,
  showPageJump = false,
  dataName,
  onSortedChange,
  ...otherProps
}: CustomReactSelectTableProps<T>) {
  const [sortedColumns, setSortedColumns] = useState(defaultSorted);
  const { pageSize, setPageSize } = usePageSize<T>(data, maxPageSize);
  const columnsWithCustomHeader = useMemo(
    () => replaceColumnsWithCustomHeaders(columns, sortedColumns),
    [columns, sortedColumns]
  );

  function handleSortedChange(
    newSorted: Array<SortingRule>,
    column: unknown,
    additive: boolean
  ) {
    if (onSortedChange) {
      onSortedChange(newSorted, column, additive);
    }

    return setSortedColumns(newSorted);
  }

  const commonProps = {
    columns: columnsWithCustomHeader,
    data: data,
    defaultSorted: defaultSorted,
    pageSize: pageSize,
    showPagination: showPagination,
    showPageJump: showPageJump,
    NoDataComponent: () => <NoDataComponent name={dataName} />,
    ...COMMON_PAGINATION_PROPS,
    onPageSizeChange: setPageSize,
    onSortedChange: handleSortedChange
  };

  const props = {
    ...commonProps,
    ...otherProps
  };

  return <CustomReactTableWithSelect<T> {...props} />;
}

type NoDataComponentProps =
  | NoDataComponentDefaultProps
  | NoDataComponentCustomTextProps;

type NoDataComponentDefaultProps = { name?: string; hasCustomText?: false };
type NoDataComponentCustomTextProps = { hasCustomText: true; text: string };

function NoDataComponent(props: NoDataComponentProps) {
  if (props.hasCustomText) {
    return (
      <div className="no-data-component">
        <p>{props.text}</p>
      </div>
    );
  }

  return (
    <div className="no-data-component">
      <p>Keine {props.name ?? "Daten"} vorhanden.</p>
    </div>
  );
}

export {
  COMMON_PAGINATION_PROPS,
  CustomReactSelectTable,
  CustomReactSelectTableProps,
  CustomReactTable,
  CustomReactTableProps,
  NoDataComponent
};
