import { CloseButton, Group, Input, Popover, Stack } from "@mantine/core";
import {
  DatePicker,
  type DatesRangeValue,
  type DateValue
} from "@mantine/dates";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { backendDateOrDateTimeToLuxonDateTimeOrNull } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTimeOrNull";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { luxonDateTimeToBackendDateOrDateTimeOrNull } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTimeOrNull";
import { parseManualDateInput } from "../../../../utils/parseManualDateInput";
import { Button } from "../../../Buttons/Button/Button";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../Dropdown/Dropdown";
import "./DateRangePicker.scss";

export enum OpenDirection {
  OpenUp = "top",
  OpenDown = "bottom"
}

export enum DatePresetType {
  All = "all",
  MonthYear = "monthyear"
}

interface DatePreset {
  text: string;
  start: DateTime;
  end: DateTime;
}

const yesterday = DateTime.now().minus({ days: 1 });
const singlePresets: Array<DatePreset> = [
  {
    text: "Gestern",
    start: yesterday,
    end: yesterday
  },
  {
    text: "Letzte Woche",
    start: DateTime.now().minus({ weeks: 1 }).startOf("week"),
    end: DateTime.now().minus({ weeks: 1 }).endOf("week")
  },
  {
    text: "Nächste 30 Tage",
    start: DateTime.now(),
    end: DateTime.now().plus({ days: 30 })
  }
];

const monthPresets: Array<DatePreset> = Array.from({ length: 12 }, (_, i) => ({
  text: DateTime.now()
    .minus({ months: i })
    .toLocaleString({ month: "long", year: "numeric" }),
  start: DateTime.now().minus({ months: i }).startOf("month"),
  end: DateTime.now().minus({ months: i }).endOf("month")
}));

const yearPresets: Array<DatePreset> = Array.from({ length: 5 }, (_, i) => ({
  text: DateTime.now().minus({ years: i }).toFormat("yyyy"),
  start: DateTime.now().minus({ years: i }).startOf("year"),
  end: DateTime.now().minus({ years: i }).endOf("year")
}));

interface DateRangePickerProps {
  allowSingleDateInRange?: boolean;
  disabled?: boolean;
  endDatePlaceholderText?: string;
  endDateValue: string | null;
  fireChangeOnlyWithTwoValidInputs?: boolean;
  initialVisibleDate?: DateTime;
  initialVisibleEndDate?: DateTime;
  invalid?: {
    startDate: boolean;
    endDate: boolean;
  };
  minDate?: DateTime;
  maxDate?: DateTime;
  maxSpan?: number;
  openDirection?: OpenDirection;
  presets?: DatePresetType;
  showClearDates?: boolean;
  startDatePlaceholderText?: string;
  startDateValue: string | null;
  withinPortal?: boolean;
  onChange?: (change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) => void;
  onChangeEndDateValue: (value: string | null) => void;
  onChangeStartDateValue: (value: string | null) => void;
}

function DateRangePicker({
  allowSingleDateInRange,
  disabled,
  endDateValue,
  endDatePlaceholderText = "Enddatum",
  fireChangeOnlyWithTwoValidInputs,
  minDate,
  maxDate,
  maxSpan,
  initialVisibleDate,
  invalid,
  openDirection,
  presets,
  showClearDates,
  startDatePlaceholderText = "Startdatum",
  startDateValue,
  withinPortal,
  onChange,
  onChangeEndDateValue,
  onChangeStartDateValue
}: DateRangePickerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateForPickerPage, setDateForPickerPage] = useState(
    getInitialDateForPickerPage()
  );

  function getInitialDateForPickerPage() {
    if (initialVisibleDate) {
      return initialVisibleDate.toJSDate();
    } else if (minDate) {
      return minDate.toJSDate();
    }

    return new Date();
  }

  function handleStartDateValueChange(newDateString: string) {
    let adjustedDateString = newDateString;

    if (minDate && newDateString !== "") {
      const newDate = backendDateOrDateTimeToLuxonDateTime(newDateString);

      if (newDate < minDate) {
        adjustedDateString = luxonDateTimeToBackendDateOrDateTime(minDate);
      }
    }

    onChangeStartDateValue(adjustedDateString);

    if (
      adjustedDateString === "" ||
      backendDateOrDateTimeToLuxonDateTime(adjustedDateString).isValid
    ) {
      maybeFireOnChangeAfterValueInput({
        startDate:
          adjustedDateString !== ""
            ? backendDateOrDateTimeToLuxonDateTime(adjustedDateString)
            : null,
        endDate: backendDateOrDateTimeToLuxonDateTimeOrNull(endDateValue)
      });
    }
  }

  function handleEndDateValueChange(newDateString: string) {
    let adjustedDateString = newDateString;

    if (maxDate && newDateString !== "") {
      const newDate = backendDateOrDateTimeToLuxonDateTime(newDateString);

      if (newDate > maxDate) {
        adjustedDateString = luxonDateTimeToBackendDateOrDateTime(maxDate);
      }
    }

    onChangeEndDateValue(adjustedDateString);

    if (
      adjustedDateString === "" ||
      backendDateOrDateTimeToLuxonDateTime(adjustedDateString).isValid
    ) {
      maybeFireOnChangeAfterValueInput({
        startDate: backendDateOrDateTimeToLuxonDateTimeOrNull(startDateValue),
        endDate:
          adjustedDateString !== ""
            ? backendDateOrDateTimeToLuxonDateTime(adjustedDateString)
            : null
      });
    }
  }

  function maybeFireOnChangeAfterValueInput(change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    if (
      (change.startDate && change.endDate) ||
      (!change.startDate && !change.endDate)
    ) {
      setDatePickerOpen(false);
    }

    if (shouldFireOnChange(change)) {
      onChange?.(change);
    }
  }

  const parsedStartDateValue: DateValue = useMemo(() => {
    const parsedDate = startDateValue
      ? parseManualDateInput(startDateValue)
      : null;
    return dayjs(parsedDate).isValid() ? parsedDate : null;
  }, [startDateValue]);
  const parsedEndDateValue: DateValue = useMemo(() => {
    const parsedDate = endDateValue ? parseManualDateInput(endDateValue) : null;
    return dayjs(parsedDate).isValid() ? parsedDate : null;
  }, [endDateValue]);

  useEffect(() => {
    if (parsedEndDateValue) {
      setDateForPickerPage(parsedEndDateValue);
    } else if (parsedStartDateValue) {
      setDateForPickerPage(parsedStartDateValue);
    }
  }, [parsedStartDateValue, parsedEndDateValue]);

  function handleDatePickerChange(dateRange: DatesRangeValue) {
    onChangeStartDateValue(
      dateRange[0]
        ? luxonDateTimeToBackendDateOrDateTime(
            DateTime.fromJSDate(dateRange[0])
          )
        : null
    );
    onChangeEndDateValue(
      dateRange[1]
        ? luxonDateTimeToBackendDateOrDateTime(
            DateTime.fromJSDate(dateRange[1])
          )
        : null
    );

    if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
      setDatePickerOpen(false);
    }

    const change = {
      startDate: dateRange[0] ? DateTime.fromJSDate(dateRange[0]) : null,
      endDate: dateRange[1] ? DateTime.fromJSDate(dateRange[1]) : null
    };

    if (shouldFireOnChange(change)) {
      onChange?.(change);
    }
  }

  function shouldFireOnChange(change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    return (
      !fireChangeOnlyWithTwoValidInputs ||
      (!!change.startDate && !!change.endDate) ||
      (change.startDate === null && change.endDate === null)
    );
  }

  function displayFormattedDates() {
    const formattedStartDate = parsedStartDateValue
      ? luxonDateTimeToBackendDateOrDateTime(
          DateTime.fromJSDate(parsedStartDateValue)
        )
      : null;
    const formattedEndDate = parsedEndDateValue
      ? luxonDateTimeToBackendDateOrDateTime(
          DateTime.fromJSDate(parsedEndDateValue)
        )
      : null;

    onChangeStartDateValue(formattedStartDate);
    onChangeEndDateValue(formattedEndDate);
  }

  function closePickerWhenLeavingInputs(event: React.KeyboardEvent) {
    if (
      event.key === "Tab" &&
      event.shiftKey &&
      document.activeElement?.closest(".date-range-start")
    ) {
      setDatePickerOpen(false);
    }

    if (
      event.key === "Tab" &&
      !event.shiftKey &&
      document.activeElement?.closest(".date-range-end")
    ) {
      setDatePickerOpen(false);
    }

    if (
      event.key === "Enter" &&
      (document.activeElement?.closest(".date-range-start") ||
        document.activeElement?.closest(".date-range-end"))
    ) {
      displayFormattedDates();
    }
  }

  function closePickerWhenClickingOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (
      !target.closest(".date-range-dropdown") &&
      !target.closest(".grouped-fields") &&
      // the month and year buttons are unmounted after clicking them,
      // so the above checks won't work, which is why we need to check for the classes directly
      !target.classList.contains("mantine-DatePicker-calendarHeaderLevel") &&
      !target.classList.contains("mantine-DatePicker-yearsListControl") &&
      !target.classList.contains("mantine-DatePicker-monthsListControl")
    ) {
      setDatePickerOpen(false);
    }
  }

  const documentRef = useRef(document);
  // modal prevents click to propagate to document, therefore put event listener directly on modal in this case
  const modalOrDocument = ref.current?.closest(".modal") || documentRef.current;
  useEffect(() => {
    modalOrDocument.addEventListener("click", closePickerWhenClickingOutside);

    return () => {
      modalOrDocument.removeEventListener(
        "click",
        closePickerWhenClickingOutside
      );
    };
  }, [modalOrDocument]);

  function handleClickClearDates() {
    onChangeStartDateValue(null);
    onChangeEndDateValue(null);
  }

  const maxDateWithSpan =
    startDateValue && maxSpan
      ? backendDateOrDateTimeToLuxonDateTime(startDateValue)
          .plus({ days: maxSpan - 1 })
          .toJSDate()
      : maxDate
        ? maxDate.toJSDate()
        : undefined;

  const dateRangeValue: DatesRangeValue = [
    parsedStartDateValue,
    parsedEndDateValue
  ];

  return (
    <Popover
      classNames={{
        dropdown: "date-range-dropdown"
      }}
      opened={datePickerOpen}
      position={openDirection}
      withinPortal={withinPortal}
      zIndex={1060}
    >
      <Popover.Target>
        <Group
          className="grouped-fields"
          gap="xs"
          w="fit-content"
          onBlur={displayFormattedDates}
          onKeyDown={closePickerWhenLeavingInputs}
        >
          <Input
            aria-label="Startdatum"
            className="date-range-start"
            disabled={disabled}
            error={invalid?.startDate}
            id="due-date-start-date"
            placeholder={startDatePlaceholderText}
            value={startDateValue ?? ""}
            onChange={(e) => handleStartDateValueChange(e.target.value)}
            onFocus={() => setDatePickerOpen(true)}
          />
          <span>–</span>
          <Input
            aria-label="Enddatum"
            className="date-range-end"
            disabled={disabled}
            error={invalid?.endDate}
            id="due-date-end-date"
            placeholder={endDatePlaceholderText}
            value={endDateValue ?? ""}
            onChange={(e) => handleEndDateValueChange(e.target.value)}
            onFocus={() => setDatePickerOpen(true)}
          />
          {showClearDates && <CloseButton onClick={handleClickClearDates} />}
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="md">
          <DatePicker
            allowSingleDateInRange={allowSingleDateInRange}
            date={dateForPickerPage}
            maxDate={maxDateWithSpan}
            minDate={minDate?.toJSDate()}
            type="range"
            value={dateRangeValue}
            onChange={handleDatePickerChange}
            onDateChange={setDateForPickerPage}
          />
          {renderDatePresets((dates) => {
            onChangeStartDateValue(
              luxonDateTimeToBackendDateOrDateTime(dates.startDate)
            );
            onChangeEndDateValue(
              luxonDateTimeToBackendDateOrDateTime(dates.endDate)
            );
            maybeFireOnChangeAfterValueInput(dates);
          }, presets)}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

interface UncontrolledDateRangePickerProps
  extends Omit<
    DateRangePickerProps,
    | "startDateValue"
    | "endDateValue"
    | "onChangeStartDateValue"
    | "onChangeEndDateValue"
  > {
  initialStartDate?: DateTime;
  initialEndDate?: DateTime;
}

function UncontrolledDateRangePicker({
  initialStartDate,
  initialEndDate,
  ...dateRangePickerProps
}: UncontrolledDateRangePickerProps) {
  const [startDateValue, setStartDateValue] = useState<string | null>(
    luxonDateTimeToBackendDateOrDateTimeOrNull(initialStartDate ?? null)
  );
  const [endDateValue, setEndDateValue] = useState<string | null>(
    luxonDateTimeToBackendDateOrDateTimeOrNull(initialEndDate ?? null)
  );

  return (
    <DateRangePicker
      {...dateRangePickerProps}
      endDateValue={endDateValue}
      startDateValue={startDateValue}
      onChangeEndDateValue={setEndDateValue}
      onChangeStartDateValue={setStartDateValue}
    />
  );
}

function renderDatePresets(
  onDatesChange: (dates: { startDate: DateTime; endDate: DateTime }) => void,
  presets?: DatePresetType
) {
  function onClickPreset(start: DateTime, end: DateTime) {
    onDatesChange({ startDate: start, endDate: end });
  }

  if (!presets) {
    return null;
  }

  return (
    <div className="date-presets">
      {presets === "all" && (
        <div className="single-presets">
          <PresetButtons presets={singlePresets} onClick={onClickPreset} />
        </div>
      )}
      {(presets === "all" || presets === "monthyear") && (
        <div className="dropdown-row">
          <PresetDropdownButton
            label="Monat"
            presets={monthPresets}
            onClick={onClickPreset}
          />
          <PresetDropdownButton
            label="Jahr"
            presets={yearPresets}
            onClick={onClickPreset}
          />
        </div>
      )}
    </div>
  );
}

interface PresetButtonsProps {
  presets: Array<DatePreset>;
  onClick: (start: DateTime, end: DateTime) => void;
}

function PresetButtons({ presets, onClick }: PresetButtonsProps) {
  return (
    <>
      {presets.map(({ text, start, end }) => {
        return (
          <Button
            color="brand"
            key={text}
            size="sm"
            onClick={() => onClick(start, end)}
          >
            {text}
          </Button>
        );
      })}
    </>
  );
}

interface PresetDropdownButtonProps {
  label: string;
  presets: Array<DatePreset>;
  onClick: (start: DateTime, end: DateTime) => void;
}

function PresetDropdownButton({
  label,
  presets,
  onClick
}: PresetDropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown group isOpen={isOpen} size="sm" toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle caret color="brand">
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {presets.map(({ text, start, end }) => {
          return (
            <DropdownItem key={text} onClick={() => onClick(start, end)}>
              {text}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export {
  DateRangePicker,
  DateRangePickerProps,
  UncontrolledDateRangePicker,
  UncontrolledDateRangePickerProps
};
