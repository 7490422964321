import { useState } from "react";
import { Link } from "react-router-dom";

import type { WizardMissingData } from "../../../../../utils/backend-types";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { AssignmentType } from "../../OnboardingWizard";
import "./configurationAssignment.scss";
import { ConfigurationConfirmationModal } from "./ConfigurationConfirmationModal/ConfigurationConfirmationModal";

type ConfigurationAssignmentProps = {
  done: boolean;
  missingData: Array<WizardMissingData>;
  isNext: boolean;
  siteId: number;
  projectId?: string;
  updateSiteWizard: (type: AssignmentType) => Promise<void>;
};

function ConfigurationAssignment({
  done,
  missingData,
  isNext,
  siteId,
  projectId,
  updateSiteWizard
}: ConfigurationAssignmentProps) {
  const [isSpinning, setIsSpinning] = useState(false);
  const hasMissingData = missingData && missingData.length > 0;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  function onClickSubmit() {
    setShowConfirmationModal(false);
    setIsSpinning(true);
    updateSiteWizard(AssignmentType.Configuration).then(() => {
      setIsSpinning(false);
    });
  }
  function openConfirmationModal() {
    setShowConfirmationModal(true);
  }

  function getComponentByStep() {
    if (hasMissingData && !done) {
      return (
        <div className="missing-data-container">
          <Button
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            tag={Link}
            to={"konfiguration"}
          >
            Fehlende Daten ergänzen
          </Button>
        </div>
      );
    } else if (!done) {
      return (
        <div className="missing-data-container">
          <SpinButton
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            spin={isSpinning}
            onClick={() => openConfirmationModal()}
          >
            Konfiguration bestätigen
          </SpinButton>
        </div>
      );
    }
  }

  return (
    <div className="configuration-assignment-container">
      {showConfirmationModal && (
        <ConfigurationConfirmationModal
          projectId={projectId}
          siteId={siteId}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={() => onClickSubmit()}
        />
      )}
      {getComponentByStep()}
    </div>
  );
}

export { ConfigurationAssignment };
