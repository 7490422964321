import type { Filter } from "react-table";

import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import type { SiteLabel, SiteStatusSite } from "../../site-status-types";
import { convertSiteLabelsToString } from "../../utils/convertSiteLabelsToString";

interface SiteLabelCellProps {
  siteLabels: Array<SiteLabel>;
}

function SiteLabelCell({ siteLabels }: SiteLabelCellProps) {
  return (
    <span className="SiteLabelCell">
      {convertSiteLabelsToString(siteLabels)}
    </span>
  );
}

function sortSiteLabelsByName(a: Array<SiteLabel>, b: Array<SiteLabel>) {
  return convertSiteLabelsToString(a).localeCompare(
    convertSiteLabelsToString(b)
  );
}

interface SiteLabelFilterProps {
  sites: Array<SiteStatusSite>;
  filter: Filter;
  onChange: (selectedKeys: Record<string, boolean>) => void;
}

function SiteLabelFilter({ sites, filter, onChange }: SiteLabelFilterProps) {
  const labels = sites
    .reduce<Array<string>>((labels, site) => {
      const labelsForSite = site.siteLabels.map((label) => label.name);
      labels.push(...labelsForSite);
      return labels;
    }, [])
    .filter((label) => !!label) as Array<string>;
  labels.sort();

  const choices = labels.reduce<Record<string, string>>((choices, label) => {
    if (!choices[label]) {
      choices[label] = label;
    }

    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      showWithoutOption
      onChange={onChange}
    />
  );
}

function onFilterSiteLabels(filter: Filter, row: SiteStatusSite) {
  if (!filter.value) {
    return true;
  }

  if (row.siteLabels.length === 0 && filter.value["ohne"]) {
    return true;
  }

  return row.siteLabels.some((label) => filter.value[label.name]);
}

export {
  SiteLabelCell,
  sortSiteLabelsByName as sortSiteLabels,
  SiteLabelFilter,
  onFilterSiteLabels
};
