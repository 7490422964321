import { Button } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";

import { useClearWarningStore } from "../../../../../DynamicForm/FormItems/FormField/StructureViewCreationDropdown/useClearWarningStore";
import "./EditFormControls.scss";

interface EditFormControlsProps {
  submitting: boolean;
  onClickSubmit: () => void;
  onClickCancel: () => void;
}

function EditFormControls({
  submitting,
  onClickSubmit,
  onClickCancel
}: EditFormControlsProps) {
  const isAllClearWarningsAccepted = useClearWarningStore((state) =>
    state.allClearWarningsAccepted()
  );
  const unregisterAllClearWarnings = useClearWarningStore(
    (state) => state.unregisterAllClearWarnings
  );

  return (
    <div className="controls">
      <Button
        className="button-spacing"
        onClick={() => {
          unregisterAllClearWarnings();
          onClickCancel();
        }}
      >
        Abbrechen
      </Button>
      <SpinButton
        className="submit-button"
        color="primary"
        disabled={!isAllClearWarningsAccepted || submitting}
        spin={submitting}
        onClick={() => {
          unregisterAllClearWarnings();
          onClickSubmit();
        }}
      >
        Speichern
      </SpinButton>
    </div>
  );
}

export { EditFormControls };
