import { Controller } from "react-hook-form";
import type { Column } from "react-table";
import type { InlineEditTableColumnData } from "../../../../../../types/inlineEditTable.types";
import type { Generator } from "../../../../../../utils/backend-types";
import { InputWrapper } from "../../../../../BuildingBlocks/Forms/utils/InputWrapper";
import type { TypedCellInfo } from "../../../../../CustomReactTable/CustomReactTable.types";
import type {
  Choice,
  TsDropdownProps
} from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { getErrorTextFromCellInfo } from "../../../utils/getErrorTextFromCellInfo";
import type { FictionalEnergyValueResponse } from "../../FictionalEnergyValueManager/FictionalEnergyValueManager";

export function getGeneratorColumn(
  {
    rowStates,
    control,
    errors,
    t
  }: InlineEditTableColumnData<FictionalEnergyValueResponse>,
  generators: Array<Generator> | undefined
): Column<FictionalEnergyValueResponse> {
  generators?.sort((a, b) => {
    if (a.siteName > b.siteName) return 1;
    if (a.siteName < b.siteName) return -1;
    if (a.siteName === b.siteName) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    }
    return 0;
  });

  const generatorChoices =
    generators?.map<Choice<number>>((generator) => ({
      displayName: `${generator.name} (${generator.siteName})`,
      value: generator.id
    })) ?? [];

  return {
    Header: "Erzeuger",
    accessor: (row) => row.generatorDisplayName,
    id: "generator",
    sortable: false,
    width: 300,
    Cell: (cellInfo: TypedCellInfo<number, FictionalEnergyValueResponse>) => {
      const disableFormField = rowStates[cellInfo.original.id] === "loading";
      const showFormField =
        rowStates[cellInfo.original.id] === "edit" ||
        rowStates[cellInfo.original.id] === "loading";
      const errorText = getErrorTextFromCellInfo(
        errors,
        cellInfo,
        "generator",
        t
      );

      if (showFormField) {
        return (
          <Controller
            control={control}
            name={`${cellInfo.index}.generator`}
            render={({ field }) => (
              <DropdownWithError
                choices={generatorChoices}
                disabled={disableFormField}
                errorText={errorText}
                invalid={!!errorText}
                name={field.name}
                required
                value={field.value ?? undefined}
                onChange={(_, value) => field.onChange(value)}
              />
            )}
            rules={{
              required: true
            }}
          />
        );
      }

      return cellInfo.value;
    }
  };
}

interface DropdownWithErrorProps extends TsDropdownProps {
  errorText?: string;
}

function DropdownWithError({
  errorText,
  ...otherProps
}: DropdownWithErrorProps) {
  return (
    <InputWrapper className="dropdown-input-wrapper" errorText={errorText}>
      <TsDropdown {...otherProps} />
    </InputWrapper>
  );
}
