import { Group, Text } from "@mantine/core";
import {
  Controller,
  type ControllerRenderProps,
  type Control,
  type FieldErrors,
  type UseFormWatch,
  type UseFormSetValue
} from "react-hook-form";
import { MeteringDirection, SupplyType } from "../../../../types/api.types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { type SiteSetupProcessForForm } from "../../SiteSetupAssistant.types";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

const zipRegex = /^\d{5}$/;

interface AddressFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
}

function AddressFormItems({
  formControl,
  formErrors,
  watch,
  onSetFormValue
}: AddressFormItemsProps) {
  const connectionMeteringDirection = watch("connectionMeteringDirection");

  function handleSupplyTypeChange(
    newType: SupplyType,
    field: ControllerRenderProps<SiteSetupProcessForForm, "siteSupplyType">
  ) {
    if (newType === SupplyType.FullSupply) {
      if (connectionMeteringDirection === null) {
        onSetFormValue(
          "connectionMeteringDirection",
          MeteringDirection.Zweirichtungszaehler
        );
      }
    } else if (newType === SupplyType.PartialSupply) {
      if (connectionMeteringDirection === null) {
        onSetFormValue(
          "connectionMeteringDirection",
          MeteringDirection.Erzeugung_Einspeisung
        );
      }
    }

    field.onChange(newType);
  }

  return (
    <div className="AddressFormItems">
      <Text fw={500} mb="sm">
        Adresse der Liegenschaft
      </Text>
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.siteAddress}
        error={formErrors.siteAddress}
      />
      <Group>
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.siteZip}
          error={formErrors.siteZip}
          rules={{
            validate: (value) =>
              !value ||
              zipRegex.test((value || "").toString()) ||
              "Bitte geben Sie eine Postleitzahl bestehend aus fünf Ziffern an."
          }}
        />
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.siteCity}
          error={formErrors.siteCity}
        />
      </Group>

      <Text fw={500} mb="md" mt="lg">
        Versorgungskonzept
      </Text>
      <Controller
        control={formControl}
        name="siteSupplyType"
        render={({ field }) => {
          return (
            <>
              <Text mb="md">
                Welches Versorgungskonzept streben Sie in Ihrer Liegenschaft
                an?*
              </Text>
              <Group gap="lg" justify="center">
                <Button
                  active={field.value === SupplyType.FullSupply}
                  className="supply-type-button"
                  onClick={() =>
                    handleSupplyTypeChange(SupplyType.FullSupply, field)
                  }
                >
                  <Icon name={IconName.Home} />
                  <br />
                  Vollversorgung (Netz- und PV-Strom)
                </Button>
                <Button
                  active={field.value === SupplyType.PartialSupply}
                  className="supply-type-button"
                  onClick={() =>
                    handleSupplyTypeChange(SupplyType.PartialSupply, field)
                  }
                >
                  <Icon name={IconName.SolarPowerGenerator} />
                  <br />
                  Teilversorgung (nur PV-Strom)
                </Button>
              </Group>
            </>
          );
        }}
      />
    </div>
  );
}

export { AddressFormItems };
