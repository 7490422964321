import { ActionIcon, Tooltip } from "@mantine/core";
import { EraserIcon } from "../../../../components/Buttons/EraserIcon";

interface EraseButtonProps {
  disabled?: boolean;
  label?: string;
  onClick: () => void;
}

function EraseButton({
  disabled,
  label = "Löschen",
  onClick
}: EraseButtonProps) {
  return (
    <Tooltip label={label}>
      <ActionIcon
        aria-label={label}
        disabled={disabled}
        variant="transparent"
        onClick={onClick}
      >
        <EraserIcon disabled={disabled} />
      </ActionIcon>
    </Tooltip>
  );
}

export { EraseButton, EraseButtonProps };
