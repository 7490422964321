import { PureComponent } from "react";
import type { LoadprofileType } from "../../../utils/enums";
import type { FieldDependency } from "../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/data-types";
import { FormItem } from "../FormItem/FormItem";
import type { Choice } from "./FormField/Dropdown/TsDropdown";
import type {
  FormFieldType,
  FormFieldValue,
  Warning
} from "./FormField/FormField";
import "./FormItems.scss";

/** @deprecated Please use FormFieldData from FormFieldController.tsx from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export interface FormFieldData {
  allowNull?: boolean;
  choices?: Array<Choice>;
  dataUrl?: string;
  firstPossibleDate?: string;
  helpText?: string;
  infoText?: string;
  initialValue?: FormFieldValue;
  instance?: {
    label: string;
  };
  inputGroupText?: string;
  label: string;
  loadprofileType?: LoadprofileType;
  minValue?: number;
  maxValue?: number;
  multiselect?: boolean;
  name: string;
  noLabel?: boolean; // not from the backend: see FormItem
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  invalid?: boolean;
  type: FormFieldType;
  warnings?: Array<Warning>;
  dependencies?: Array<FieldDependency>;
}

export interface FormItemsProps {
  formItems: Array<FormFieldData>;
  formName?: string;
  formItemsWithPortals?: Array<string>;
  formValues: Record<string, FormFieldValue>;
  formWarnings?: Record<string, string>;
  formErrors: Record<string, Array<string>>;
  allowInput: boolean;
  onInput: (
    name: string | null,
    value?:
      | FormFieldValue
      | Record<string, FormFieldValue>
      | Record<string, FormFieldValue>[]
  ) => void;
  onFormItemNodeChange?: (name: string, node: HTMLElement | null) => void;
  onSubmit: () => void;
  missingFields?: Array<string>;
  exclusiveOrFields?: Array<Array<string>>;
  isHighlighted?: (missingFields, exclusiveOrFields, formItem) => boolean;
}

/** @deprecated Please use BuildingBlocks/Forms/FormField from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export default class FormItems extends PureComponent<FormItemsProps> {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const {
      formItems,
      formName,
      formItemsWithPortals,
      formValues,
      formErrors,
      onInput,
      onFormItemNodeChange,
      allowInput,
      missingFields,
      exclusiveOrFields,
      isHighlighted
    } = this.props;

    return (
      <form className="FormItems" onSubmit={this.handleSubmit}>
        {formItems.map((formItem) => (
          <FormItem
            allowInput={allowInput}
            errors={formErrors[formItem.name]}
            formName={formName}
            highlight={
              isHighlighted
                ? isHighlighted(missingFields, exclusiveOrFields, formItem)
                : !!missingFields && missingFields.includes(formItem.name)
            }
            key={formItem.name}
            shouldUsePortal={
              formItemsWithPortals &&
              formItemsWithPortals.includes(formItem.name)
            }
            value={formValues[formItem.name]}
            onFormItemNodeChange={onFormItemNodeChange}
            onInput={onInput}
            {...formItem}
          />
        ))}
      </form>
    );
  }

  static defaultProps = {
    formItems: [],
    formValues: {},
    formErrors: {},
    formWarnings: {},
    onInput: function () {
      // do nothing as default
    },
    allowInput: true
  };
}
