import { useState } from "react";
import ReactDOM from "react-dom";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";

interface SiteFormControlsProps {
  buttonContainer?: HTMLElement;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  askForConfirmation: boolean;
}

function SiteFormControls({
  buttonContainer,
  onCancel,
  onSubmit,
  askForConfirmation
}: SiteFormControlsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleClickSubmit() {
    if (askForConfirmation) {
      setIsConfirmationOpen(true);
    } else {
      handleSubmit();
    }
  }

  async function handleSubmit() {
    setIsSubmitting(true);

    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className="SiteFormControls">
      {buttonContainer &&
        ReactDOM.createPortal(
          <>
            <SpinButton
              color="primary"
              spin={isSubmitting}
              onClick={handleClickSubmit}
            >
              Speichern
            </SpinButton>
            <Button color="secondary" onClick={onCancel}>
              Abbrechen
            </Button>
          </>,
          buttonContainer
        )}
      <BasicConfirmationModal
        confirmationButtonColor="warning"
        confirmationButtonText="Ja, erstellen"
        headerText={`Wollen Sie die Liegenschaft erstellen?`}
        isModalOpen={isConfirmationOpen}
        toggleModal={() => setIsConfirmationOpen(!isConfirmationOpen)}
        onCancel={() => setIsConfirmationOpen(false)}
        onConfirm={() => {
          handleSubmit();
          setIsConfirmationOpen(false);
        }}
      >
        Bitte beachten Sie, dass einige Daten unvollständig oder fehlerhaft sein
        werden. Sie können die Liegenschaft jetzt erstellen und die Daten
        jederzeit später aktualisieren.
      </BasicConfirmationModal>
    </div>
  );
}

export { SiteFormControls, SiteFormControlsProps };
