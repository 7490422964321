import { FormItem } from "../../FormItem/FormItem";
import type { FormItemsProps } from "../FormItems";

import "./AddressFields.scss";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function AddressFields({
  allowInput,
  formErrors,
  formItems,
  formValues,
  onInput,
  exclusiveOrFields,
  fieldNames,
  formName,
  isHighlighted,
  missingFields
}: FormItemsProps & { fieldNames: Array<string> }) {
  return (
    <div className="AddressFieldsContainer">
      {fieldNames.map((fieldName, idx) => {
        const item = formItems[fieldName];
        const value = formValues[fieldName];
        const error = formErrors[fieldName];

        return (
          <div className="AddressFieldBox" key={idx}>
            <FormItem
              {...item}
              allowInput={allowInput}
              errors={error}
              formName={formName}
              highlight={
                isHighlighted
                  ? isHighlighted(missingFields, exclusiveOrFields, item)
                  : !!missingFields && missingFields.includes(item.name)
              }
              value={value}
              onInput={onInput}
            />
          </div>
        );
      })}
    </div>
  );
}

export { AddressFields };
