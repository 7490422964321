import { DateTime } from "luxon";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { calculateFirstPossibleStartDate } from "../../utils/calculateFirstPossibleStartDate";
import { DatePickerCell } from "./DatePickerCell";

interface StartDateCellProps {
  startDate: DateTime | null;
  endDate: DateTime | null;
  previousRowStartDate: DateTime | null | undefined;
  isFirstDate: boolean;
  placeholder?: string;
  restrictFutureDates?: boolean;
  onChange: (newEndDate: DateTime | null) => void;
}

function StartDateCell({
  startDate,
  endDate,
  previousRowStartDate,
  isFirstDate,
  placeholder,
  restrictFutureDates,
  onChange
}: StartDateCellProps) {
  const firstPossibleDate =
    calculateFirstPossibleStartDate(previousRowStartDate);
  const lastPossibleDate = restrictFutureDates
    ? endDate
      ? endDate
      : DateTime.now()
    : undefined;

  if (!startDate) {
    return <span>{placeholder}</span>;
  }

  if (isFirstDate) {
    return <span>{luxonDateTimeToBackendDateOrDateTime(startDate)}</span>;
  }

  return (
    <DatePickerCell
      firstPossibleDate={firstPossibleDate}
      lastPossibleDate={lastPossibleDate}
      value={startDate}
      onChange={onChange}
    />
  );
}

export { StartDateCell };
