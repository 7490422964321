import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Column, RowInfo } from "react-table";
import api from "../../../api";
import { ReversalInvoice } from "../../../images";
import urls from "../../../urls";
import type {
  Contract,
  InvoiceState,
  Person
} from "../../../utils/backend-types";
import { INVOICE_STATE_CHOICES } from "../../../utils/constants";
import { sortBackendDates } from "../../../utils/dates/sortBackendDates";
import { showToast } from "../../../utils/toast";
import { showBasicConfirmationPopup } from "../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button } from "../../Buttons/Button/Button";
import { PersonCell } from "../../CustomReactTable/Cells/PersonCell";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import {
  DateRangeCell,
  FileCell,
  type Invoice,
  InvoiceStatusCell
} from "../DeliveryInvoices";
import "./ArchivedInvoices.scss";

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine stornierte Rechnungen vorhanden.</p>
    </div>
  );
}

interface ReversedInvoiceComponentProps {
  row: RowInfo;
  persons: Array<Person>;
  onChangeStatus: (
    state: InvoiceState,
    reversedInvoiceId: string,
    archivedInvoiceId: string
  ) => void;
  onSendMail(reversalId: string, supplied: number, contractId: string): void;
}

function ReversedInvoiceComponent({
  row,
  persons,
  onChangeStatus,
  onSendMail
}: ReversedInvoiceComponentProps) {
  function handleChangeStatus(state: InvoiceState, reversalInvoiceId: string) {
    onChangeStatus(state, reversalInvoiceId, row.original.id);
  }

  return (
    <div className="reversed-invoice-component-container">
      {row.original.reversalInvoice ? (
        <div className="reversed-invoice-cell-container">
          <div>
            <img className="reversal-invoice-icon" src={ReversalInvoice} />
            <FileCell
              billingPeriodStart={row.original.billingPeriodStart}
              persons={persons}
              prefix={
                row.original.isAdvanceInvoice
                  ? "Stornobeleg für Abschlagsrechnung"
                  : "Stornobeleg für Rechnung"
              }
              suppliedId={row.original.supplied}
              supplierId={row.original.supplier}
              url={urls.api.downloadInvoice(row.original.reversalInvoice.id)}
            />
          </div>
          <div className="reversed-invoice-status-and-mail-container">
            <InvoiceStatusCell
              id={row.original.reversalInvoice.id}
              status={row.original.reversalInvoice.state}
              onChangeStatus={handleChangeStatus}
            />
            <Button
              onClick={() =>
                onSendMail(
                  row.original.reversalInvoice.id,
                  row.original.supplied,
                  row.original.contract
                )
              }
            >
              Versenden
            </Button>
          </div>
        </div>
      ) : (
        <div className="no-reversed-available">
          Keine Stornorechnung vorhanden
        </div>
      )}
    </div>
  );
}

interface ArchivedInvoicesProps {
  invoices: Array<Invoice>;
  persons: Array<Person>;
  contracts: Array<Contract>;
  onChangeStatus: (
    state: InvoiceState,
    reversedInvoiceId: string,
    archivedInvoiceId: string
  ) => void;
  customReactTableProps;
}

function ArchivedInvoices({
  invoices,
  persons,
  contracts,
  customReactTableProps,
  onChangeStatus
}: ArchivedInvoicesProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState({});
  async function sendMail(id: string) {
    const sendUrl = urls.api.sendInvoiceToSupplied(id);
    try {
      await api.post(sendUrl);
      showToast("success", "E-Mail wurde erfolgreich verschickt.");
    } catch (e) {
      openErrorAlertPopup(e);
    }
  }

  async function onSendMail(
    reversalId: string,
    supplied: number,
    contractId: string
  ) {
    const person = persons.find((p) => p.id === supplied);
    const contract = contracts.find((c) => c.id === contractId);

    if (person && contract) {
      if (contract.allowanceSendInvoiceViaMail) {
        await sendMail(reversalId);
      } else {
        const text = `Liegt eine Einwilligung von ${person.name} für den elektronischen Rechnungsversand vor?`;
        showBasicConfirmationPopup({
          text: text,
          onConfirm: async () => {
            contract.allowanceSendInvoiceViaMail = true;
            const updateUrl = urls.api.contract(contract.id);
            try {
              await api.put<void>(updateUrl, contract);
              await sendMail(reversalId);
            } catch (e) {
              console.error(e);
              showToast("error", t("errors.DeliveryInvoices.AllowSendError"));
            }
          }
        });
      }
    }
  }

  const columns: Array<Column> = [
    {
      Header: "Rechnungsdokument",
      accessor: "id",
      Cell: (row) => (
        <FileCell
          billingPeriodStart={row.original.billingPeriodStart}
          persons={persons}
          prefix="Rechnung"
          suppliedId={row.original.supplied}
          supplierId={row.original.supplier}
          url={urls.api.downloadInvoice(row.value)}
        />
      )
    },
    {
      Header: "Erstellung",
      accessor: "created",
      width: 100,
      Cell: (row) => row.value.split(" ")[0]
    },
    {
      Header: "Lieferant",
      accessor: "supplier",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    {
      Header: "Belieferter",
      accessor: "supplied",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    { Header: "Liegenschaft", accessor: "siteName" },
    {
      Header: "Abrechnungszeitraum",
      accessor: "billingPeriodStart",
      width: 160,
      sortMethod: sortBackendDates,
      Cell: (row) => (
        <DateRangeCell
          beginDate={row.original.billingPeriodStart}
          endDate={row.original.billingPeriodEnd}
        />
      )
    },
    {
      Header: "Status",
      accessor: "state",
      width: 140,
      Cell: (row) => (
        <p>
          {
            INVOICE_STATE_CHOICES.find((choice) => choice.value === row.value)
              ?.displayName
          }
        </p>
      )
    },
    {
      Header: "E-Mail-Versand",
      accessor: "sentViaEmail",
      width: 140,
      Cell: () => <span />
    }
  ];

  const defaultSorted = [
    {
      id: "billingPeriodStart",
      desc: true
    }
  ];

  useEffect(() => {
    const expandedRows = invoices.map((element, index) => {
      return { [index]: true };
    });
    setExpanded(expandedRows);
  }, [invoices]);

  return (
    <div className="archived-invoices-container">
      <h5>Stornierte Rechnungen</h5>
      <CustomReactTable
        {...customReactTableProps}
        columns={columns}
        data={invoices}
        defaultSorted={defaultSorted}
        expanded={expanded}
        keyField="id"
        NoDataComponent={NoDataComponent}
        pageSize={10}
        showPageJump
        showPagination
        SubComponent={(row) => {
          return (
            <ReversedInvoiceComponent
              persons={persons}
              row={row}
              onChangeStatus={onChangeStatus}
              onSendMail={onSendMail}
            />
          );
        }}
        onExpandedChange={(newExpanded) => {
          setExpanded(newExpanded);
        }}
      />
    </div>
  );
}

export { ArchivedInvoices };
