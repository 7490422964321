import { useCallback, useEffect, useState } from "react";

import urls from "../../../urls";
import FormItems from "../../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../../DynamicForm/FormItems/withCreatableDropdown";
import {
  ESTIMATION_PROCEDURES,
  FIELDS,
  ORIGINS,
  TYPES
} from "../../EnergyData/common";
import { CREATE_FORM_FIELD_NAMES as METER_FORM_FIELD_NAMES } from "../../MeterWizard/MeterWizard";
import { OptionsForm } from "../../OptionsForm/OptionsForm";

const MISC_FIELDS = [FIELDS.CUSTOM_LABEL, FIELDS.REFERENCES];

const OTHER_TYPE_FIELDS = [FIELDS.REFERENCES];

const METER_FIELDS = [FIELDS.METER, FIELDS.TAGS];

const MARKET_LOCATION_FIELDS = [
  FIELDS.MEDIUM,
  FIELDS.MARKET_LOCATION,
  FIELDS.TAGS
];

const COMPUTATION_FIELDS = [
  FIELDS.MEDIUM,
  FIELDS.FREQUENCY,
  FIELDS.FORMULA,
  FIELDS.TAGS
];

const ESTIMATION_FIELDS = [FIELDS.MEDIUM, FIELDS.ESTIMATION_PROCEDURE];

const ESTIMATION_PROCEDURE_FIELDS_WORST_CASE = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.NO_OF_CONSUMERS,
  FIELDS.CONSTANT_LOAD_KW,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const ESTIMATION_PROCEDURE_FIELDS_TYPICAL_STANDARD_VALUES = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.NO_OF_CONSUMERS,
  FIELDS.ANNUAL_CONSUMPTION,
  FIELDS.SECURITY_BUFFER,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const ESTIMATION_PROCEDURE_FIELDS_EXAMPLE_MEASURING = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.NO_OF_CONSUMERS,
  FIELDS.ESTIMATION_ENERGY_DATA_EXAMPLE_MEASURING,
  FIELDS.SECURITY_BUFFER,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const ESTIMATION_PROCEDURE_FIELDS_NON_CALIBRATED_MEASURING = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.ESTIMATION_ENERGY_DATA,
  FIELDS.SECURITY_BUFFER,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const ESTIMATION_PROCEDURE_FIELDS_PARTIAL_RATIO = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.ESTIMATION_ENERGY_DATA,
  FIELDS.RATIO_OF_ENERGY_DATA,
  FIELDS.SECURITY_BUFFER,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const ESTIMATION_PROCEDURE_FIELDS_MISC = [
  FIELDS.CONSUMER_TYPE,
  FIELDS.FREQUENCY,
  FIELDS.FORMULA,
  FIELDS.TAGS,
  FIELDS.STATEMENT
];

const MARKET_LOCATION_FORM_FIELD_NAMES = ["number", "customLabel"];

const READ_ONLY_FIELDS = [FIELDS.TYPE, FIELDS.ORIGIN, FIELDS.FREQUENCY];

function getTypeFields(type) {
  let fields;

  if (type === TYPES.MISC) {
    fields = [FIELDS.TYPE, ...MISC_FIELDS, FIELDS.ORIGIN];
  } else if (type) {
    fields = [FIELDS.TYPE, ...OTHER_TYPE_FIELDS, FIELDS.ORIGIN];
  } else {
    fields = [FIELDS.TYPE];
  }

  return fields;
}

function getOriginFields(origin) {
  switch (origin) {
    case ORIGINS.METER:
      return METER_FIELDS;
    case ORIGINS.MARKET_LOCATION:
      return MARKET_LOCATION_FIELDS;
    case ORIGINS.COMPUTATION:
      return COMPUTATION_FIELDS;
    case ORIGINS.ESTIMATION:
      return ESTIMATION_FIELDS;
    default:
      return [];
  }
}

function getEstimationProcedureFields(estimationProcedure) {
  if (estimationProcedure === ESTIMATION_PROCEDURES.CONSTANT_LOAD) {
    return ESTIMATION_PROCEDURE_FIELDS_WORST_CASE;
  } else if (estimationProcedure === ESTIMATION_PROCEDURES.EXAMPLE_MEASURING) {
    return ESTIMATION_PROCEDURE_FIELDS_EXAMPLE_MEASURING;
  } else if (
    estimationProcedure === ESTIMATION_PROCEDURES.NON_CALIBRATED_MEASURING
  ) {
    return ESTIMATION_PROCEDURE_FIELDS_NON_CALIBRATED_MEASURING;
  } else if (estimationProcedure === ESTIMATION_PROCEDURES.PARTIAL_RATIO) {
    return ESTIMATION_PROCEDURE_FIELDS_PARTIAL_RATIO;
  } else if (
    estimationProcedure === ESTIMATION_PROCEDURES.TYPICAL_STANDARD_VALUES ||
    estimationProcedure === ESTIMATION_PROCEDURES.TYPICAL_STANDARD_VALUES_15_MIN
  ) {
    return ESTIMATION_PROCEDURE_FIELDS_TYPICAL_STANDARD_VALUES;
  } else if (estimationProcedure === ESTIMATION_PROCEDURES.MISC) {
    return ESTIMATION_PROCEDURE_FIELDS_MISC;
  }
  return [];
}

function EdaForm({
  data,
  optionsUrl,
  postUrl,
  putUrl,
  siteId,
  buttonContainer,
  onSubmit,
  onCancel,
  editing = false
}) {
  const [type, setType] = useState(getInitialType);
  const [origin, setOrigin] = useState(getInitialOrigin);
  const [estimationProcedure, setEstimationProcedure] = useState(
    getInitialEstimationProcedure
  );

  const getFields = useCallback(() => {
    if (origin !== ORIGINS.ESTIMATION && estimationProcedure !== null) {
      setEstimationProcedure(null);
      return;
    }

    let fields = getTypeFields(type);

    if (origin !== null) {
      fields.push(...getOriginFields(origin));
    }

    if (estimationProcedure !== null) {
      fields.push(...getEstimationProcedureFields(estimationProcedure));
    }

    return fields;
  }, [estimationProcedure, origin, type]);

  const [filteredFields, setFilteredFields] = useState(getFields);
  const [CustomFormItemsComponent, setCustomFormItemsComponent] = useState();

  function getInitialType() {
    if (data && data.initialValues) {
      return data.initialValues.type;
    }

    return null;
  }

  function getInitialOrigin() {
    if (data && data.initialValues) {
      return data.initialValues.origin;
    }

    return null;
  }

  function getInitialEstimationProcedure() {
    if (data && data.initialValues) {
      return data.initialValues.estimationProcedure;
    }

    return null;
  }

  function handleInput(fieldName, value) {
    if (fieldName === FIELDS.TYPE) {
      setType(value);
    } else if (fieldName === FIELDS.ORIGIN) {
      setOrigin(value);
    } else if (fieldName === FIELDS.ESTIMATION_PROCEDURE) {
      setEstimationProcedure(value);
    }
  }

  useEffect(() => {
    const newFilteredFields = getFields();
    setFilteredFields(newFilteredFields);
  }, [type, origin, estimationProcedure, getFields]);

  useEffect(() => {
    let FormItemsComponent = FormItems;

    const nonFieldData = {
      site: siteId
    };

    const marketLocationPostUrl = urls.api.marketLocations(siteId);
    const marketLocationPutUrlFunc = urls.api.marketLocation;
    const meterPostUrl = urls.api.meters(siteId);
    const meterPutUrlFunc = urls.api.meter;

    FormItemsComponent = withCreatableDropdown(
      FormItemsComponent,
      FIELDS.MARKET_LOCATION,
      marketLocationPostUrl,
      marketLocationPutUrlFunc,
      MARKET_LOCATION_FORM_FIELD_NAMES,
      nonFieldData
    );

    FormItemsComponent = withCreatableDropdown(
      FormItemsComponent,
      FIELDS.METER,
      meterPostUrl,
      meterPutUrlFunc,
      METER_FORM_FIELD_NAMES,
      nonFieldData
    );

    setCustomFormItemsComponent({
      component: (props) => <FormItemsComponent {...props} />
    });
  }, [siteId]);

  if (!CustomFormItemsComponent) {
    return null;
  }

  const initialValues = data ? data.initialValues : undefined;
  const nonFieldData = {
    site: siteId
  };

  const readOnlyFields = editing ? READ_ONLY_FIELDS : [];

  return (
    <OptionsForm
      allowInput={true}
      buttonContainer={buttonContainer}
      CustomFormItemsComponent={CustomFormItemsComponent.component}
      filteredFields={filteredFields}
      initialValues={initialValues}
      nonFieldData={nonFieldData}
      optionsUrl={optionsUrl}
      postUrl={postUrl}
      putUrl={putUrl}
      readOnlyFields={readOnlyFields}
      onCancel={onCancel}
      onInput={handleInput}
      onSubmit={onSubmit}
    />
  );
}

export { EdaForm };
