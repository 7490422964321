import type { Node, NodeProps, ReactFlowState } from "@xyflow/react";
import { Handle, Position, useStore } from "@xyflow/react";
import { default as classnames } from "classnames";
import { useMemo } from "react";
import type { MeteringDirection } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import type { StructureViewMode } from "../../../StructureView/StructureView.constants";
import type { EdgeData } from "../../StructureViewFlowDiagram.types";
import { ConnectionPointDirection } from "../../StructureViewFlowDiagram.types";
import { getCorrectSizeFromComponentType } from "../../utils/getCorrectSizeFromComponentType";
import { getStructureViewImageUrl } from "../../utils/getStructureViewImageUrl";
import "./CustomFlowNode.scss";

const DISABLED_OPACITY = 0.7;

const connectionNodeIdSelector = (state: ReactFlowState) =>
  state.connection.fromHandle?.nodeId;

export type CustomFlowNodeData = {
  active?: boolean;
  componentId: number;
  image: string;
  imageColor: string;
  label: string;
  size?: number;
  tooltipData: {
    name: string;
    text: string;
    type: string;
    isConsumptionShareConfirmed?: boolean;
    isConsumptionShareEditable?: boolean;
  };
  type: ObjectName;
  meterBillingRelevantOrSubmeter?: string;
  meterPriorityDescription?: string;
  connectedSourceEdges: Array<EdgeData>;
  id: string;
  position: { x: number; y: number };
};

export type CustomFlowNodeType = Node<CustomFlowNodeData, "custom">;
export interface CustomFlowNodeProps extends NodeProps<CustomFlowNodeType> {
  mode: StructureViewMode;
  meteringDirection?: MeteringDirection | null;
}
function CustomFlowNode({ id, data }: CustomFlowNodeProps) {
  const connectionNodeId = useStore<string | undefined>(
    connectionNodeIdSelector
  );

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const imageUrl = useMemo(() => {
    const url = data.image
      ? getStructureViewImageUrl(
          data.image,
          data.imageColor,
          data.active === false ? DISABLED_OPACITY : undefined
        )
      : null;
    return url;
  }, [data.active, data.image, data.imageColor]);

  const size: number | undefined = getCorrectSizeFromComponentType(
    data.type,
    data.size
  );
  const isGhostNode = data.type === ObjectName.GhostNode;
  const label = data.label;
  return (
    <div className="CustomFlowNode">
      <div
        className={classnames("customNodeBody", {
          ghostNode: isGhostNode
        })}
        style={{
          borderStyle: isTarget ? "dashed" : "solid",
          backgroundColor: isGhostNode ? "black" : "",
          backgroundImage: imageUrl ? `url(${imageUrl})` : "",
          width: size ?? 60,
          height: size ?? 60
        }}
      >
        {!isConnecting && (
          <>
            <Handle
              className="customHandle"
              id={ConnectionPointDirection.Right}
              position={Position.Right}
              type="source"
            />
            <Handle
              className="customHandle"
              id={ConnectionPointDirection.Bottom}
              position={Position.Bottom}
              type="source"
            />
          </>
        )}

        <Handle
          className="customHandle"
          id={ConnectionPointDirection.Left}
          isConnectableStart={false}
          position={Position.Left}
          type="target"
        />
        <Handle
          className="customHandle"
          id={ConnectionPointDirection.Top}
          isConnectableStart={false}
          position={Position.Top}
          type="target"
        />
      </div>
      <div className="custom-flow-node-label">{label}</div>
    </div>
  );
}

export { CustomFlowNode };
