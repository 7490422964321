import classNames from "classnames";
import { useState } from "react";
import { createRoot } from "react-dom/client";
import { getErrorText } from "../../../../../utils/get-error-text";
import { Icon } from "../../../Icon/Icon";
import { IconName } from "../../../Icon/types";
import type { ModalSize } from "../../Modal/Modal";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Modal/Modal";
import "./BasicConfirmationModal.scss";
import type { BasicConfirmationFooterProps } from "./BasicConfirmationModalFooter/BasicConfirmationModalFooter";
import { BasicConfirmationFooter } from "./BasicConfirmationModalFooter/BasicConfirmationModalFooter";

export enum BasicConfirmationModalIcon {
  Error = "error",
  Success = "success",
  Warning = "warning"
}

interface BasicConfirmationModalProps extends BasicConfirmationFooterProps {
  className?: string;
  children: React.ReactNode;
  error?: unknown;
  headerText?: string;
  isModalOpen: boolean;
  icon?: BasicConfirmationModalIcon;
  size?: ModalSize;
}

const modalIcons = {
  [BasicConfirmationModalIcon.Error]: (
    <Icon className="icon error-icon" name={IconName.ExclamationRed} />
  ),
  [BasicConfirmationModalIcon.Success]: (
    <Icon className="icon" name={IconName.RoundedCheckGreen} />
  ),
  [BasicConfirmationModalIcon.Warning]: (
    <Icon className="icon warning-icon" name={IconName.Warning} />
  )
} as const;

function getModalIcon(iconType: BasicConfirmationModalIcon) {
  return modalIcons[iconType];
}

function BasicConfirmationModal({
  className,
  children,
  confirmationButtonColor = "primary",
  confirmationButtonText = "OK",
  cancelButtonText = "Abbrechen",
  cancelButtonColor,
  error,
  headerText,
  isConfirmationBtnDisabled,
  isModalOpen,
  isLoading,
  icon,
  size,
  toggleModal,
  onConfirm,
  onCancel
}: BasicConfirmationModalProps) {
  function handleHeaderCloseBtn() {
    if (onCancel) {
      onCancel();
    }

    toggleModal();
  }

  const headerIcon = (function getHeaderIcon() {
    if (error) {
      return BasicConfirmationModalIcon.Error;
    } else if (icon) {
      return icon;
    } else if (confirmationButtonColor === "danger") {
      return BasicConfirmationModalIcon.Error;
    } else if (confirmationButtonColor === "warning") {
      return BasicConfirmationModalIcon.Warning;
    }

    return undefined;
  })();

  const CommonFooter = (
    <BasicConfirmationFooter
      cancelButtonColor={cancelButtonColor}
      cancelButtonText={cancelButtonText}
      confirmationButtonColor={confirmationButtonColor}
      confirmationButtonText={confirmationButtonText}
      error={error}
      isConfirmationBtnDisabled={isConfirmationBtnDisabled}
      isLoading={isLoading}
      toggleModal={toggleModal}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );

  return (
    <Modal
      className={classNames("BasicConfirmationModal", className)}
      isOpen={isModalOpen}
      size={size}
      toggle={toggleModal}
    >
      {headerText && (
        <ModalHeader toggle={onCancel ? handleHeaderCloseBtn : undefined}>
          {headerIcon && getModalIcon(headerIcon)}
          {headerText}
        </ModalHeader>
      )}
      <ModalBody scrollable>
        {error ? <div>{getErrorText(error)}</div> : children}
        {!headerText && (
          <div className="footer-when-no-header">{CommonFooter}</div>
        )}
      </ModalBody>
      {headerText && <ModalFooter>{CommonFooter}</ModalFooter>}
    </Modal>
  );
}

interface ShowBasicConfirmationPopupOptions
  extends Omit<
    UncontrolledBasicConfirmationModalProps,
    "onClose" | "children"
  > {
  text: React.ReactNode;
}

function showBasicConfirmationPopup({
  text,
  ...otherProps
}: ShowBasicConfirmationPopupOptions) {
  const basicConfirmationDiv = document.createElement("div");

  const removeSelf = () => {
    basicConfirmationDiv.remove();
  };
  const root = createRoot(basicConfirmationDiv);

  document.body.appendChild(basicConfirmationDiv);

  root.render(
    <UncontrolledBasicConfirmationModal {...otherProps} onClose={removeSelf}>
      {text}
    </UncontrolledBasicConfirmationModal>
  );
}

interface UncontrolledBasicConfirmationModalProps
  extends Omit<BasicConfirmationModalProps, "toggleModal" | "isModalOpen"> {
  onClose: () => void;
}

function UncontrolledBasicConfirmationModal({
  onConfirm,
  onClose,
  ...otherProps
}: UncontrolledBasicConfirmationModalProps) {
  const [isOpen, setIsOpen] = useState(true);

  function handleClose() {
    setIsOpen(false);
    onClose();
  }

  function handleConfirm() {
    setIsOpen(false);
    onConfirm();
  }

  return (
    <BasicConfirmationModal
      isModalOpen={isOpen}
      toggleModal={handleClose}
      onConfirm={handleConfirm}
      {...otherProps}
    />
  );
}

export {
  BasicConfirmationModal,
  BasicConfirmationModalProps,
  showBasicConfirmationPopup
};
