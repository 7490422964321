import { useCallback, useEffect, useMemo } from "react";
import type { Column, Filter } from "react-table";
import { LAST_YEAR } from "../../../utils/dates";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import { useCustomReactTableRowCount } from "../../CustomReactTable/CustomReactTableHooks";
import { filterString } from "../../CustomReactTable/Filters/common-filters";
import { DropdownFilter } from "../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import type {
  OnboardingTodo,
  SiteOverviewSite,
  SiteStatusLogs,
  SiteStatusSite,
  MeteringConceptInfo,
  DataAssessment
} from "../site-status-types";
import { MeteringConceptType } from "../site-status-types";
import {
  BooleanTodosCell,
  BooleanTodosFilter,
  onFilterBooleanTodos
} from "./TableCells/BooleanTodosCell";
import {
  CategoryFilter,
  onFilterCategories
} from "./TableCells/CategoryFilter";
import { CustomersCell } from "./TableCells/CustomersCell";
import type { DataAssessmentObject } from "./TableCells/DataAssessment";
import {
  DataAssessmentAccessor,
  DataAssessmentCell,
  DataAssessmentFilter,
  onFilterDataAssessment
} from "./TableCells/DataAssessment";
import {
  LogsCell,
  LogsFilter,
  onFilterLogs,
  sortLogs
} from "./TableCells/LogsCell";
import { NameCell } from "./TableCells/NameCell";
import {
  OnboardingTodosCell,
  OnboardingTodosFilter,
  onFilterOnboardingTodos,
  sortOnboardingTodos
} from "./TableCells/OnboardingTodos";
import {
  OnboardingTodosCellSimple,
  OnboardingTodosSimpleFilter,
  onFilterOnboardingTodosSimple,
  sortOnboardingTodosSimple
} from "./TableCells/OnboardingTodosSimple";
import { ProjectCell } from "./TableCells/ProjectCell";
import {
  onFilterSiteLabels,
  SiteLabelCell,
  SiteLabelFilter,
  sortSiteLabels
} from "./TableCells/SiteLabelCell";

interface SimplifiedSiteOverviewSite
  extends Omit<SiteOverviewSite, "categories"> {
  classification: string;
  siteDataAssessmentGridMakoMeter: boolean | null;
}

interface SimplifiedSiteStatusSite extends Omit<SiteStatusSite, "categories"> {
  classification: string;
}

interface SiteStatusTableProps {
  showCategoriesColumn?: boolean;
  showProjectLinks?: boolean;
  simple?: boolean;
  sites: Array<SiteOverviewSite | SiteStatusSite>;
  onClickLogsCell: (logs: SiteStatusLogs) => void;
  onClickOnboardingTodosCell: (todos: Array<OnboardingTodo>) => void;
  onClickMeteringConceptInfoCell: (mbkInfo: MeteringConceptInfo) => void;
  onNumDisplayedRowsUpdated: (rowCount: number | null) => void;
  setTableRef: (ref) => void;
}

function SiteStatusTable({
  showCategoriesColumn,
  showProjectLinks,
  simple,
  sites,
  onClickLogsCell,
  onClickOnboardingTodosCell,
  onClickMeteringConceptInfoCell,
  onNumDisplayedRowsUpdated,
  setTableRef
}: SiteStatusTableProps) {
  const { getNumRows, setTableRef: setTableRowCountTableRef } =
    useCustomReactTableRowCount();

  useEffect(() => {
    onNumDisplayedRowsUpdated(getNumRows());
  }, [getNumRows, onNumDisplayedRowsUpdated, sites]);

  const simplifiedSites:
    | Array<SimplifiedSiteOverviewSite>
    | Array<SimplifiedSiteStatusSite> = useMemo(() => {
    function getSiteDataAssessmentGridMakoMeter(
      siteDataAssessment?: DataAssessment
    ) {
      if (!siteDataAssessment) {
        return null;
      }

      if (siteDataAssessment.gridMeterSufficient !== null) {
        return siteDataAssessment.gridMeterSufficient;
      } else if (siteDataAssessment.makoMeterDataIsSufficient !== null) {
        return siteDataAssessment.makoMeterDataIsSufficient;
      }

      return null;
    }

    function getSiteDataAssessmentGeneratorSubMeter(
      siteDataAssessment?: DataAssessment
    ) {
      if (!siteDataAssessment) {
        return null;
      }

      if (siteDataAssessment.generatorsSufficient !== null) {
        return siteDataAssessment.generatorsSufficient;
      } else if (siteDataAssessment.subMeterDataIsSufficient !== null) {
        return siteDataAssessment.subMeterDataIsSufficient;
      }

      return null;
    }

    return sites.map((site) => {
      return {
        ...site,
        classification: site.siteCategories.join(", "),
        [DataAssessmentAccessor.SiteDataAssessmentGridMakoMeter]:
          getSiteDataAssessmentGridMakoMeter(site?.siteDataAssessment),
        [DataAssessmentAccessor.SiteDataAssessmentGeneratorSubMeter]:
          getSiteDataAssessmentGeneratorSubMeter(site?.siteDataAssessment)
      };
    });
  }, [sites]);

  const tableColumns: Array<
    Column<SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite>
  > = useMemo(() => {
    const columns: Array<
      Column<SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite>
    > = [];
    const categoriesColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: "Klassifizierung",
      id: "siteCategories",
      accessor: "classification",
      Filter: CategoryFilter,
      filterMethod: onFilterCategories,
      maxWidth: 150
    };
    const siteIdColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: "ID",
      accessor: "id",
      filterMethod: filterId,
      width: 64,
      style: { textAlign: "center" }
    };
    const siteNameColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: "Liegenschaft",
      accessor: "name",
      Cell: (data) => (
        <NameCell original={data.original} simple={simple} value={data.value} />
      ),
      filterMethod: (filter, row) => filterString(filter.value, row.name)
    };
    const projectColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: "Projekt",
      accessor: "project",
      Cell: (data) => (
        <ProjectCell
          projectId={data.original.projectId}
          projectName={data.value}
          showLink={showProjectLinks}
        />
      ),
      filterMethod: (filter, row) => filterString(filter.value, row.project)
    };

    if (showCategoriesColumn) {
      columns.push(categoriesColumn);
    }

    if (simple) {
      columns.push(projectColumn, siteIdColumn, siteNameColumn);
    } else {
      columns.push(siteIdColumn, siteNameColumn, projectColumn);
    }

    const msbDataAssessmentColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: `Netzverknüpfungspunkt-Daten ${LAST_YEAR}`,
      accessor: DataAssessmentAccessor.SiteDataAssessmentGridMakoMeter,
      Cell: (data) => (
        <DataAssessmentCell
          assessment={data.value}
          projectId={data.original.projectId}
        />
      ),
      Filter: DataAssessmentFilter,
      filterMethod: (filter: Filter, row: DataAssessmentObject) =>
        onFilterDataAssessment(
          filter,
          row,
          DataAssessmentAccessor.SiteDataAssessmentGridMakoMeter
        )
    };

    const siteDataAssessmentColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: `Daten der Erzeugungsanlagen ${LAST_YEAR}`,
      accessor: DataAssessmentAccessor.SiteDataAssessmentGeneratorSubMeter,
      Cell: (data) => (
        <DataAssessmentCell
          assessment={data.value}
          projectId={data.original.projectId}
        />
      ),
      Filter: DataAssessmentFilter,
      filterMethod: (filter: Filter, row: DataAssessmentObject) =>
        onFilterDataAssessment(
          filter,
          row,
          DataAssessmentAccessor.SiteDataAssessmentGeneratorSubMeter
        )
    };

    const meteringConceptTypeColumn: Column<
      SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
    > = {
      Header: `Meldetyp`,
      accessor: "meteringConceptType",
      Cell: (data) => <span>{data.value}</span>,
      Filter: MeteringConceptTypeFilter,
      filterMethod: onFilterMeteringConceptTypes
    };

    if (!simple) {
      columns.push(
        {
          Header: "Kunde",
          accessor: "customer",
          filterMethod: (filter, row) =>
            filterString(filter.value, row.customer || "")
        },
        {
          Header: "Kunden (alt)",
          accessor: "customers",
          filterMethod: (filter, row) =>
            filterString(filter.value, row.customers.join(" ")),
          Cell: CustomersCell
        },
        {
          Header: "Messkonzepterstellung",
          columns: [
            {
              Header: "Status Onboarding-Aufgaben",
              accessor: "onboardingTodos",
              Cell: (data) => (
                <OnboardingTodosCell
                  todos={data.value}
                  onClick={onClickOnboardingTodosCell}
                />
              ),
              sortMethod: sortOnboardingTodos,
              Filter: OnboardingTodosFilter,
              filterMethod: (
                filter: Filter,
                row: SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
              ) => onFilterOnboardingTodos(filter.value, row.onboardingTodos)
            },
            {
              Header: "Voraussetzung",
              accessor: "meteringConceptRequirementsMet",
              Cell: BooleanTodosCell,
              Filter: BooleanTodosFilter,
              filterMethod: (filter, row) =>
                onFilterBooleanTodos(
                  filter.value,
                  row.meteringConceptRequirementsMet
                )
            },
            meteringConceptTypeColumn
          ],
          sortable: false
        },
        {
          Header: "Verbrauchsanalyse",
          accessor: "logs",
          Cell: (data) => (
            <LogsCell logs={data.value} onClick={onClickLogsCell} />
          ),
          sortMethod: sortLogs,
          Filter: LogsFilter,
          filterMethod: onFilterLogs
        },
        {
          Header: "MBK Erstellung",
          accessor: "meteringConceptInfo",
          Cell: (data) => (
            <MeteringConceptInfoCell
              mbkInfo={data.value}
              onClick={onClickMeteringConceptInfoCell}
            />
          ),
          sortable: false,
          filterMethod: filterMeteringConceptInfo
        },
        {
          Header: "Label",
          accessor: "siteLabels",
          Cell: (data) => <SiteLabelCell siteLabels={data.value} />,
          sortMethod: sortSiteLabels,
          Filter: ({ filter, onChange }) => (
            <SiteLabelFilter
              filter={filter}
              sites={sites as Array<SiteStatusSite>}
              onChange={onChange}
            />
          ),
          filterMethod: onFilterSiteLabels
        }
      );
    } else {
      columns.push({
        Header: "Nächste Onboarding-Aufgabe",
        accessor: "onboardingTodos",
        width: 315,
        Cell: (data) => (
          <OnboardingTodosCellSimple
            projectId={data.original.projectId}
            siteId={data.original.id}
            todos={data.value}
          />
        ),
        sortMethod: sortOnboardingTodosSimple,
        Filter: OnboardingTodosSimpleFilter,
        filterMethod: onFilterOnboardingTodosSimple
      });
      columns.push(msbDataAssessmentColumn);
      columns.push(siteDataAssessmentColumn);
      columns.push(meteringConceptTypeColumn);
    }

    return columns;
  }, [
    simple,
    sites,
    showCategoriesColumn,
    showProjectLinks,
    onClickOnboardingTodosCell,
    onClickLogsCell,
    onClickMeteringConceptInfoCell
  ]);

  const defaultSorted = useMemo(
    () =>
      simple
        ? [
            {
              id: "project",
              desc: false
            }
          ]
        : [],
    [simple]
  );

  const handleSetTableRender = useCallback(
    (ref) => {
      setTableRowCountTableRef(ref);
      setTableRef(ref);
    },
    [setTableRowCountTableRef, setTableRef]
  );

  const handleFilteredChange = useCallback(
    () => onNumDisplayedRowsUpdated(getNumRows()),
    [getNumRows, onNumDisplayedRowsUpdated]
  );

  return (
    <CustomReactTable
      columns={tableColumns}
      data={simplifiedSites}
      defaultSorted={defaultSorted}
      filterable
      minRows={0}
      NoDataComponent={NoDataComponent}
      setTableRef={handleSetTableRender}
      showPageJump
      showPagination
      onFilteredChange={handleFilteredChange}
    />
  );
}

function filterId(
  filter: Filter,
  row: SimplifiedSiteStatusSite | SimplifiedSiteOverviewSite
) {
  return filterString(filter.value, row.id.toString());
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Liegenschaften gefunden.</p>
    </div>
  );
}

function MeteringConceptTypeFilter({ filter, onChange }) {
  const choices = Object.keys(MeteringConceptType).reduce<
    Record<string, MeteringConceptType>
  >((choices, key) => {
    const value = MeteringConceptType[key];
    choices[value] = value;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterMeteringConceptTypes(filter: Filter, row: SiteOverviewSite) {
  const options = filter.value;
  return options[row.meteringConceptType] === true;
}

interface MeteringConceptInfoCellProps {
  mbkInfo: MeteringConceptInfo;
  onClick: (mbkInfo: MeteringConceptInfo) => void;
}

function MeteringConceptInfoCell({
  mbkInfo,
  onClick
}: MeteringConceptInfoCellProps) {
  const executionState = mbkInfo.executionState;
  const errorMessages = mbkInfo.errorMessages;
  const clickable = errorMessages?.length > 0;
  const handleClick = () => {
    if (clickable) {
      onClick(mbkInfo);
    }
  };

  if (executionState) {
    return (
      <div
        className={clickable ? "linked-label-cell-span" : ""}
        onClick={handleClick}
      >
        {executionState}
      </div>
    );
  } else {
    return <span>-</span>;
  }
}

function filterMeteringConceptInfo(filter: Filter, row: SiteStatusSite) {
  let errorMsg = "";
  if (row.meteringConceptInfo.errorMessages) {
    errorMsg = row.meteringConceptInfo.errorMessages.join(" ");
  }
  return filterString(
    filter.value,
    [row.meteringConceptInfo.executionState, errorMsg].join(" ")
  );
}

export { SiteStatusTable };
