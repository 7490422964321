import { Text } from "@mantine/core";
import { Badge } from "../../../../BuildingBlocks/Badge/Badge";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { DeleteIcon } from "../../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../../Buttons/EditIcon";
import { TemplateIcon } from "../../../../Buttons/TemplateIcon";
import "./Paragraph6ContractTitle.scss";

type Paragraph6ContractTitleProps = {
  name: string;
  id: string;
  generatorCount?: number;
  isTemplate?: boolean;
  onClickEdit: () => void;
  onClickMarkAsTemplate: (contractId: string) => void;
  onClickDelete: (contractId: string) => void;
};

function Paragraph6ContractTitle({
  name,
  id,
  generatorCount,
  isTemplate = false,
  onClickEdit,
  onClickMarkAsTemplate,
  onClickDelete
}: Paragraph6ContractTitleProps) {
  return (
    <div className="Paragraph6ContractTitle">
      <Text flex="1 1 auto">
        {name} &nbsp;
        {isTemplate && (
          <Badge
            className="contract-delivery-table-badge"
            color="info"
            title={
              "Dies ist sowohl ein Vertrag mit einer konkreten Gemeinde, als auch ein Referenzvertrag für zukünftige Verträge. "
            }
          >
            Referenzvertrag
          </Badge>
        )}
      </Text>
      <div className="generator-count">
        {!generatorCount ? (
          <>
            <Icon name={IconName.ExclamationOrange} /> Keine
          </>
        ) : (
          generatorCount
        )}{" "}
        Erzeuger
      </div>
      <div className="action-panel">
        <EditIcon tooltipText={"Vertrag bearbeiten"} onClick={onClickEdit} />
        <TemplateIcon
          isTemplate={isTemplate}
          onClick={() => {
            onClickMarkAsTemplate(id);
          }}
        />
        <DeleteIcon
          tooltipText="Vertrag löschen"
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(id);
          }}
        />
      </div>
    </div>
  );
}

export { Paragraph6ContractTitle };
