import classNames from "classnames";
import {
  type Control,
  Controller,
  type FieldError,
  type Path
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Contract, Delivery } from "../../../../utils/backend-types";
import { HelperText } from "../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { getErrorKeyFromFieldError } from "../../../BuildingBlocks/Forms/FormField/HelperText/utils/getErrorKeyFromFieldError";
import type { ChangeablePrice } from "../../../DynamicForm/FormItems/FormField/ChangeablePriceFieldStatic/ChangeablePriceFieldStatic";
import { ChangeablePriceFieldStatic } from "../../../DynamicForm/FormItems/FormField/ChangeablePriceFieldStatic/ChangeablePriceFieldStatic";

interface ChangeablePricesFieldProps<T extends Delivery | Contract> {
  control: Control<T>;
  error?: FieldError;
  name: Path<T>;
  label: string;
  unit: string;
  className?: string;
  allowInput?: boolean;
  collapsible?: boolean;
  previewValue?: number;
  helpText?: string;
}

function ChangeablePricesField<T extends Delivery | Contract>({
  control,
  error,
  name,
  label,
  unit,
  className,
  allowInput = true,
  collapsible = true,
  previewValue,
  helpText
}: ChangeablePricesFieldProps<T>) {
  const { t } = useTranslation();
  const changeablePricesErrorKey = getErrorKeyFromFieldError(error);
  const changeablePricesError =
    changeablePricesErrorKey && t(changeablePricesErrorKey);

  function validatePrices(value?: Array<ChangeablePrice>) {
    if (previewValue) {
      return true;
    }
    if (value && value.length > 0) {
      for (const price of value) {
        if (price.value === null || typeof price.value === "undefined") {
          return t("errors.ContractForm.PriceValueMissing");
        } else if (price.value < 0) {
          return t("errors.ContractForm.NegativePrice");
        }
      }

      return true;
    }

    return t("errors.Required");
  }

  return (
    <div className={classNames("ChangeablePriceField", className)}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <ChangeablePriceFieldStatic
            {...field}
            allowInput={allowInput}
            collapsible={collapsible}
            fieldName={field.name}
            helpText={helpText}
            label={label}
            unit={unit}
            value={
              previewValue
                ? [{ firstDate: "", lastDate: "", value: previewValue }]
                : (field.value as ChangeablePrice[])
            }
            onInput={(_, value) => field.onChange(value)}
          />
        )}
        rules={{
          validate: (value) => validatePrices(value as ChangeablePrice[])
        }}
      />
      <HelperText error={changeablePricesError} />
    </div>
  );
}

export { ChangeablePricesField };
