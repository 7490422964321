import { NavLink } from "@mantine/core";
import { IconPaint, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { ColorSelectionModal } from "../ColorSelectionModal/ColorSelectionModal";
import { PopupBox } from "../PopupBox/PopupBox";

interface CustomContainerElementPopupProps {
  x: number;
  y: number;
  nodeId: string;
  onClickDelete: (nodeId: string) => void;
  onColorChange: (color: string) => void;
  currentlySelectedColor?: string;
}

function CustomContainerElementPopup({
  x,
  y,
  nodeId,
  currentlySelectedColor,
  onClickDelete,
  onColorChange
}: CustomContainerElementPopupProps) {
  const [showColorSelectionModal, setShowColorSelectionModal] = useState(false);

  return (
    <PopupBox className="CustomContainerElementPopup" position={{ x, y }}>
      <NavLink
        component="button"
        label="Farbe ändern"
        leftSection={<IconPaint size="16" />}
        onClick={() => setShowColorSelectionModal(true)}
      />
      <NavLink
        component="button"
        label="Rahmen löschen"
        leftSection={<IconTrash color="red" size="16" />}
        onClick={() => onClickDelete(nodeId)}
      />
      <ColorSelectionModal
        initialColor={currentlySelectedColor?.toUpperCase()}
        isOpen={showColorSelectionModal}
        onClose={() => setShowColorSelectionModal(false)}
        onColorChange={onColorChange}
      />
    </PopupBox>
  );
}

export { CustomContainerElementPopup };
