import { Group } from "@mantine/core";
import type { OptiSupportEndpoints } from "../../urls";
import urls from "../../urls";
import { AnchorLink } from "../AnchorLink/AnchorLink";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./OptiSupportHelpLink.scss";

interface OptiSupportHelpLinkProps {
  iconName?: IconName.QuestionCircle2 | IconName.InfoCircle;
  optiSupportEndpoint: OptiSupportEndpoints;
  text: string;
}

function OptiSupportHelpLink({
  iconName = IconName.QuestionCircle2,
  optiSupportEndpoint,
  text
}: OptiSupportHelpLinkProps) {
  return (
    <Group className="OptiSupportHelpLink" gap="6px" justify="flex-end">
      <Icon name={iconName} />
      <AnchorLink href={urls.optiSupport(optiSupportEndpoint)}>
        {text}
      </AnchorLink>
    </Group>
  );
}

export { OptiSupportHelpLink, OptiSupportHelpLinkProps };
