import { Link } from "react-router-dom";
import type { To } from "react-router-dom";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

interface IconWithRouterLinkProps extends IconProps {
  to: To;
  target?: React.HTMLAttributeAnchorTarget;
}

function IconWithRouterLink({ to, target, ...props }: IconWithRouterLinkProps) {
  return (
    <Link target={target} to={to}>
      <Icon {...props} />
    </Link>
  );
}

export { IconWithRouterLink };
