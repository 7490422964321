import { Paper } from "@mantine/core";
import { Navigate, useParams } from "react-router-dom";
import { useVariantSites } from "../../hooks/useVariantSites";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import type { TabData } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { ParkabrechnungProvider } from "./ParkabrechnungContext";
import { ParkabrechnungDownloadButton } from "./ParkabrechnungDownloadButton/ParkabrechnungDownloadButton";
import { FeedinPage } from "./ParkabrechnungOverview/FeedinPage/FeedinPage";
import { PARKABRECHNUNG_TAGS } from "./ParkabrechnungOverview/ParkabrechnungData/ParkabrechnungData";
import { ParkabrechnungOverview } from "./ParkabrechnungOverview/ParkabrechnungOverview";
import "./ParkabrechnungView.scss";

enum ParkabrechnungTab {
  Einspeisung = "einspeisung",
  Netzbezug = "netzbezug",
  Drittlieferungen = "drittlieferung",
  Vertraege = "vertraege"
}

export const DEFAULT_SUBPAGE = ParkabrechnungTab.Vertraege;

const tabToParkType = (tab?: string) => {
  switch (tab) {
    case ParkabrechnungTab.Netzbezug:
      return PARKABRECHNUNG_TAGS.netzbezug;
    case ParkabrechnungTab.Drittlieferungen:
      return PARKABRECHNUNG_TAGS.drittlieferung;
    case ParkabrechnungTab.Vertraege:
      return PARKABRECHNUNG_TAGS.vertrag;
    default:
      return undefined;
  }
};

type ParkabrechnungViewProps = {
  projectId: string;
  variantId?: number;
};

function ParkabrechnungView({ projectId, variantId }: ParkabrechnungViewProps) {
  const { tab: activeTab } = useParams();
  const { sites } = useVariantSites(variantId);
  // todo: remove staff limitation when feature is ready
  const showEinspeisungTabs = useShouldShowStaffView();
  const abrechnungType = tabToParkType(activeTab);

  if (!showEinspeisungTabs && activeTab === ParkabrechnungTab.Einspeisung) {
    return <Navigate to={`../${ParkabrechnungTab.Netzbezug}`} />;
  }

  const tabs: Array<TabData> = [
    {
      id: ParkabrechnungTab.Vertraege,
      label: "Verträge"
    },
    {
      id: ParkabrechnungTab.Netzbezug,
      label: "Netzbezug"
    },
    {
      id: ParkabrechnungTab.Einspeisung,
      label: "Einspeisung",
      hidden: !showEinspeisungTabs
    },
    {
      id: ParkabrechnungTab.Drittlieferungen,
      label: "Drittlieferungen"
    }
  ];

  return (
    <ParkabrechnungProvider>
      <PageContent className="Parkabrechnung">
        <PageHeader title="Parkabrechnung" />
        <TabBar
          activeTab={activeTab || DEFAULT_SUBPAGE}
          basePath="../"
          tabs={tabs}
        />
        <Paper>
          <div className="button-container">
            <ParkabrechnungDownloadButton
              parkType={tabToParkType(activeTab)}
              projectId={projectId}
            />
          </div>
          {variantId &&
            (abrechnungType === PARKABRECHNUNG_TAGS.vertrag ||
              abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung ||
              abrechnungType === PARKABRECHNUNG_TAGS.netzbezug) && (
              <ParkabrechnungOverview
                abrechnungType={abrechnungType}
                projectId={projectId}
                sites={sites}
                variantId={variantId}
              />
            )}
          {variantId && activeTab === ParkabrechnungTab.Einspeisung && (
            <FeedinPage projectId={projectId} variantId={variantId} />
          )}
        </Paper>
      </PageContent>
    </ParkabrechnungProvider>
  );
}

export { ParkabrechnungView, ParkabrechnungViewProps };
