import * as Sentry from "@sentry/browser";
import classnames from "classnames";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MenuItemData } from "../Menu.types";
import { OnboardingCounter } from "./OnboardingCounter";

interface MenuItemProps extends MenuItemData {
  active: boolean;
  external?: boolean;
  projectId?: string | null;
  simulationId?: string;
  siteId?: number | null;
  variantId?: number | null;
}

export function MenuItem({
  active,
  url,
  external,
  icon,
  label,
  projectId,
  simulationId,
  siteId,
  variantId,
  hidden
}: MenuItemProps) {
  const showOnboardingItem = label === "Onboarding" && variantId;
  let path: string;

  // mk: I don't think this is a great way to do this
  // basically, generatePath will throw an error if some params are missing (e.g. not loaded)
  // but all we really want to do is not show those links
  // solutions I can think of increase code complexity a lot - this is very simple
  try {
    if (!url) {
      throw "Missing menu item url";
    }

    if (icon === IconName.OptiCharge) {
      path = url;
    } else {
      path = generatePath(url, {
        projectId,
        simulationId,
        siteId,
        variantId
      });
    }
  } catch (error) {
    // as per comment above, params can be missing during load
    // however, URL should never be missing - it means something was not implemented in code
    if (!url) {
      console.error("Missing menu item params", error);
      Sentry.captureException(error);
    }

    return null;
  }

  const linkText = (
    <>
      <span className="m-menu__item-here"></span>
      <Icon className={classnames("m-menu__link-icon")} name={icon} />
      <span className="m-menu__link-text">{label}</span>
      <Icon className="m-menu__ver-arrow" name={IconName.AngleRight} />
    </>
  );

  if (hidden) {
    return null;
  }

  return (
    <li
      aria-haspopup="true"
      className={classnames("m-menu__item", {
        "m-menu__item--active": active
      })}
      data-menu-submenu-toggle="hover"
      data-redirect="true"
    >
      {external ? (
        <a
          className="m-menu__link"
          href={path}
          rel="noreferrer"
          target="_blank"
        >
          {linkText}
        </a>
      ) : (
        <Link className="m-menu__link" to={path}>
          {linkText}
        </Link>
      )}
      {showOnboardingItem && <OnboardingCounter variantId={variantId} />}
    </li>
  );
}

interface AdminItemProps extends MenuItemProps {
  adminUrl?: string;
}

export function AdminItem({ adminUrl, ...props }: AdminItemProps) {
  if (!adminUrl) {
    return null;
  }

  return <MenuItem {...props} external url={adminUrl} />;
}

interface OptiChargeItemProps extends MenuItemProps {
  optiChargeUrl?: string;
}

export function OptiChargeItem({
  optiChargeUrl,
  ...props
}: OptiChargeItemProps) {
  if (!optiChargeUrl) {
    return null;
  }
  return <MenuItem {...props} external url={optiChargeUrl} />;
}
interface ModuleSwitchItemProps {
  isAnalyzerPage?: boolean;
  isManagerPage?: boolean;
  isAdminDashboardPage?: boolean;
  userHasAnalyzerLicense?: boolean;
  userHasManagerLicense?: boolean;
}

export function ModuleSwitchItem({
  isAnalyzerPage,
  isManagerPage,
  isAdminDashboardPage,
  userHasAnalyzerLicense,
  userHasManagerLicense
}: ModuleSwitchItemProps) {
  let switchUrl: string, linkText: string;

  if (isAnalyzerPage && userHasManagerLicense) {
    switchUrl = ROUTES.managerStartPage;
    linkText = "Zum Manager";
  } else if (isManagerPage && userHasAnalyzerLicense) {
    switchUrl = ROUTES.analyzerProjectList;
    linkText = "Zum Analyzer";
  } else if (isAdminDashboardPage) {
    switchUrl = ROUTES.admin;
    linkText = "Zum Admin";
  } else {
    return null;
  }

  return (
    <>
      <hr className="mt-5" />
      <li
        aria-haspopup="true"
        className="m-menu__item"
        data-menu-submenu-toggle="hover"
        data-redirect="true"
      >
        {!isAdminDashboardPage ? (
          <Link className="m-menu__link" to={switchUrl}>
            <Icon
              className="m-menu__link-icon"
              name={IconName.ArrowUp}
              style={{ transform: "rotate(-90deg)" }}
            />
            <span className="m-menu__link-text">{linkText}</span>
          </Link>
        ) : (
          <a className="m-menu__link" href={switchUrl}>
            <Icon
              className="m-menu__link-icon"
              name={IconName.ArrowUp}
              style={{ transform: "rotate(-90deg)" }}
            />
            <span className="m-menu__link-text">{linkText}</span>
          </a>
        )}
      </li>
    </>
  );
}
