import type { ModalProps as ReactstrapModalProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { Modal as ReactstrapModal } from "reactstrap";
import type { ModalBodyProps } from "./ModalBody/ModalBody";
import { ModalBody } from "./ModalBody/ModalBody";
import type { ModalFooterProps } from "./ModalFooter/ModalFooter";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import type { ModalHeaderProps } from "./ModalHeader/ModalHeader";
import { ModalHeader } from "./ModalHeader/ModalHeader";

import "./Modal.scss";

export type ModalSize = "sm" | "lg" | "xl" | "xxl";

type ModalProps = Omit<ReactstrapModalProps, "size"> & {
  size?: ModalSize;
};

function Modal({ backdrop = "static", ...props }: ModalProps) {
  return <ReactstrapModal backdrop={backdrop} {...props} />;
}

export { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps };
export type { ModalBodyProps, ModalFooterProps, ModalHeaderProps };
