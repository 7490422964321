import * as Sentry from "@sentry/browser";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { DownloadArgs } from "../../../hooks/useDownload";
import { HTTP_METHODS, useDownload } from "../../../hooks/useDownload";
import urls from "../../../urls";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { showToast } from "../../../utils/toast";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { isDataStringResponse } from "../../EdaVisualizationModal/CreateEdaVersionModal/CreateEdaVersionModal";
import { ParkabrechnungContext } from "../ParkabrechnungContext";

import type {
  ActivatedDrittlieferungButtonProps,
  DrittlieferungExportPayload
} from "./ParkabrechnungDownloadButton.types";
import {
  BUTTON_COLOR_BRAND,
  DOWNLOAD_BUTTON_LABEL,
  DOWNLOAD_BUTTON_LABEL_PROJECT_OVERVIEW
} from "./ParkabrechnungDownloadButton.types";

function downloadHandler({
  startDate,
  endDate,
  selectedCompany,
  projectId,
  selectedSiteId,
  isStromlieferung,
  isStromsteuer,
  payload,
  downloadFn
}: {
  startDate: string;
  endDate: string;
  projectId: string;
  selectedCompany?: number;
  selectedSiteId?: number;
  isStromlieferung?: boolean;
  isStromsteuer?: boolean;
  payload: DrittlieferungExportPayload;
  downloadFn: (args: DownloadArgs) => Promise<void>;
}) {
  const start = backendDateOrDateTimeToLuxonDateTime(startDate);
  const end = backendDateOrDateTimeToLuxonDateTime(endDate);

  if (!selectedCompany) {
    let periodInMonths = end.diff(start, "months").toObject().months;
    periodInMonths =
      typeof periodInMonths === "number"
        ? parseInt(periodInMonths.toFixed(0))
        : undefined;

    while (periodInMonths && periodInMonths >= 0) {
      periodInMonths--;

      const startDate = luxonDateTimeToBackendDateOrDateTime(
        end.minus({ month: periodInMonths }).startOf("month"),
        "ISO 8601"
      );
      const endDate = luxonDateTimeToBackendDateOrDateTime(
        end.minus({ month: periodInMonths }).endOf("month"),
        "ISO 8601"
      );

      downloadFn({
        downloadUrl: urls.api.exportParkabrechnungDrittlieferung({
          projectId,
          queryParams:
            typeof selectedSiteId === "number"
              ? {
                  start_date: startDate,

                  end_date: endDate,
                  site_id: selectedSiteId
                }
              : {
                  start_date: startDate,
                  end_date: endDate
                }
        }),
        options: {
          method: HTTP_METHODS.POST,
          payload: {
            thirdPartyDeliveryPrices: payload,
            includeElectricityTax: isStromsteuer,
            includePrices: isStromlieferung
          }
        }
      });
    }
  } else {
    downloadFn({
      downloadUrl: urls.api.exportParkabrechnungDrittlieferung({
        projectId,
        queryParams:
          typeof selectedSiteId === "number"
            ? {
                start_date: startDate,
                end_date: endDate,
                site_id: selectedSiteId
              }
            : {
                start_date: startDate,
                end_date: endDate
              }
      }),
      options: {
        method: HTTP_METHODS.POST,
        payload: {
          thirdPartyDeliveryPrices: payload,
          includeElectricityTax: isStromsteuer,
          includePrices: isStromlieferung
        }
      }
    });
  }
}

export function ActivatedDrittlieferungButton({
  startDate,
  endDate,
  projectId,
  payload
}: ActivatedDrittlieferungButtonProps) {
  const { isStromsteuer, isStromlieferung, selectedSite, selectedCompany } =
    useContext(ParkabrechnungContext);
  const { t } = useTranslation();
  const { download, isLoading, error } = useDownload();

  let errorMessage = "";
  if (error) {
    if (isDataStringResponse(error)) {
      errorMessage = error.response.data;
    } else {
      errorMessage = t("errors.ServerError");
      console.error(error);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage);
    }
  }, [errorMessage]);

  return (
    <SpinButton
      color={BUTTON_COLOR_BRAND}
      spin={isLoading}
      onClick={() =>
        downloadHandler({
          downloadFn: download,
          startDate,
          endDate,
          payload,
          projectId,
          isStromlieferung,
          isStromsteuer,
          selectedCompany,
          selectedSiteId: selectedSite?.id
        })
      }
    >
      {selectedSite && selectedCompany
        ? DOWNLOAD_BUTTON_LABEL
        : DOWNLOAD_BUTTON_LABEL_PROJECT_OVERVIEW}
    </SpinButton>
  );
}
