import { useState } from "react";
import api from "../../../api";
import { useContainerRef } from "../../../hooks/useContainerRef";
import urls from "../../../urls";
import type { Site } from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import type { SelectedMeter } from "../EditConfigurationModal/EditConfigurationModal";
import { IMPORT_RUNNING_TEXT } from "../HistoricalDataImport/HistoricalDataImport";
import { HistoricalDataImportWithStateAndControls } from "../HistoricalDataImport/HistoricalDataImportWithStateAndControls";
import { MeterSelection } from "../MeterSelection/MeterSelection";
import { SubMeteringConfigurationForm } from "../SubMeteringConfigurationForm/SubMeteringConfigurationForm";
import type { ConfigurationData } from "../ThirdPartySystems.types";
import { getSystemDisplayNameFromValue } from "../utils/getSystemDisplayNameFromValue";

export const STEPS = {
  CONFIGURATION_FORM: 1,
  METER_SELECTION: 2,
  HISTORICAL_DATA_IMPORT: 3,
  SUMMARY: 4
};

interface NewConfigurationModalProps {
  isOpen: boolean;
  sites: Array<Site>;
  variantId: number;
  onConfigurationCreated: (configuration: ConfigurationData) => void;
  onToggle: () => void;
}

function NewConfigurationModal({
  isOpen,
  sites,
  variantId,
  onConfigurationCreated,
  onToggle
}: NewConfigurationModalProps) {
  const [step, setStep] = useState(STEPS.CONFIGURATION_FORM);
  const [configurationId, setConfigurationId] = useState<number | null>(null);
  const [systemName, setSystemName] = useState("");
  const [showImportRunningMessage, setShowImportRunningMessage] =
    useState(false);
  const [
    isSelectedMetersWarningModalOpen,
    setIsSelectedMetersWarningModalOpen
  ] = useState(false);
  const [selectedMeters, setSelectedMeters] = useState<Array<SelectedMeter>>(
    []
  );

  const { containerNode, containerRef } = useContainerRef();

  function handleSubmitConfigurationForm(data: ConfigurationData) {
    setSystemName(data.subMeteringSystem);
    setConfigurationId(data.id);
    setStep(STEPS.METER_SELECTION);

    const dataWithEmptyMeters = {
      ...data,
      meters: []
    };

    onConfigurationCreated(dataWithEmptyMeters);
  }

  function handleNextStep() {
    setStep(STEPS.HISTORICAL_DATA_IMPORT);
  }

  async function handleSubmitHistoricalDateImportDates(
    startDate: string,
    endDate: string
  ) {
    if (!configurationId) {
      return Promise.reject();
    }

    try {
      await api.post(
        urls.api.subMeteringConfigurationHistoricalDataImport(configurationId),
        { start: startDate, end: endDate }
      );
      setShowImportRunningMessage(true);
      setStep(STEPS.SUMMARY);
    } catch (error) {
      showToast("error", error);
      return await Promise.reject();
    }
  }

  function handleSkipHistoricalDateImport() {
    setShowImportRunningMessage(false);
    setStep(STEPS.SUMMARY);
  }

  function handleModalClose() {
    if (selectedMeters.length > 0) {
      setIsSelectedMetersWarningModalOpen(true);
      return;
    }

    onToggle();
  }

  const dataUrls = sites.reduce((dataUrls, site) => {
    dataUrls[site.id] = urls.api.meters(site.id);
    return dataUrls;
  }, {});

  return (
    <Modal
      className="NewConfigurationModal"
      isOpen={isOpen}
      size={step === STEPS.METER_SELECTION ? "xxl" : undefined}
      toggle={handleModalClose}
    >
      <ModalHeader toggle={handleModalClose}>
        Neue Konfiguration einrichten
      </ModalHeader>
      <ModalBody scrollable>
        {step === STEPS.CONFIGURATION_FORM && (
          <div>
            <h5>Verbindung</h5>
            <SubMeteringConfigurationForm
              buttonContainer={containerNode}
              variantId={variantId}
              onSubmit={handleSubmitConfigurationForm}
            />
          </div>
        )}
        {step === STEPS.METER_SELECTION && (
          <div>
            <MeterSelection
              buttonContainer={containerNode}
              configurationId={configurationId}
              dataUrls={dataUrls}
              isSelectedMetersWarningModalOpen={
                isSelectedMetersWarningModalOpen
              }
              selectedMeters={selectedMeters}
              setIsSelectedMetersWarningModalOpen={
                setIsSelectedMetersWarningModalOpen
              }
              setSelectedMeters={setSelectedMeters}
              sites={sites}
              systemName={systemName}
              onNextStep={handleNextStep}
              onToggle={onToggle}
            />
          </div>
        )}
        {step === STEPS.HISTORICAL_DATA_IMPORT && (
          <div>
            <h5>Import von historischen Daten aus Untermesssystemen</h5>
            <HistoricalDataImportWithStateAndControls
              buttonContainer={containerNode}
              onSkip={handleSkipHistoricalDateImport}
              onSubmit={handleSubmitHistoricalDateImportDates}
            />
          </div>
        )}
        {step === STEPS.SUMMARY && (
          <SummaryStep
            showImportRunningMessage={showImportRunningMessage}
            systemName={systemName}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons" ref={containerRef}>
          {step === STEPS.SUMMARY && (
            <Button color="primary" onClick={onToggle}>
              Schließen
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

function SummaryStep({ systemName, showImportRunningMessage }) {
  const displayName = getSystemDisplayNameFromValue(systemName);

  return (
    <div className="summary-step">
      <p>Die Schnittstelle zu {displayName} wurde erfolgreich eingerichtet.</p>
      {showImportRunningMessage && <p>{IMPORT_RUNNING_TEXT}</p>}
    </div>
  );
}

export { NewConfigurationModal };
