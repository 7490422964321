import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaymentIntervallAndBillingMethod } from "../../../../../types/api.types";
import { showBannerToast } from "../../../../../utils/toast";
import {
  TemplateDecisionCards,
  TemplateModalState
} from "../../../../BuildingBlocks/TemplateDecisionCards/TemplateDecisionCards";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { useParagraph6Companies } from "../../../hooks/useParagraph6Companies";
import { useParagraph6Contract } from "../../../hooks/useParagraph6Contract";
import { useParagraph6ContractMutations } from "../../../hooks/useParagraph6ContractMutations";
import type {
  Paragraph6ContractFormFields,
  Paragraph6ContractPayload,
  Paragraph6ContractResponse
} from "../../../Paragraph6.types";
import { Paragraph6ContractForm } from "./Paragraph6ContractForm/Paragraph6ContractForm";
import { Paragraph6ContractTemplateSelect } from "./Paragraph6ContractTemplateSelect";

interface Paragraph6ContractModalContentProps {
  contractId?: string;
  onClose: () => void;
  templates?: Array<Paragraph6ContractResponse>;
  modalState: TemplateModalState;
  setModalState: React.Dispatch<React.SetStateAction<TemplateModalState>>;
  selectedTemplate: Partial<Paragraph6ContractPayload> | null;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<Partial<Paragraph6ContractPayload> | null>
  >;
  variantId: number;
  editMode: boolean;
}

function Paragraph6ContractModalContent({
  contractId,
  onClose,
  templates,
  modalState,
  setModalState,
  selectedTemplate,
  setSelectedTemplate,
  variantId,
  editMode
}: Paragraph6ContractModalContentProps) {
  const { paragraph6Contract, isLoading: paragraph6ContractLoading } =
    useParagraph6Contract(contractId);

  const [responseError, setResponseError] = useState<Error>();
  const shouldShowStaffView = useShouldShowStaffView();

  const navigate = useNavigate();

  const defaultParagraph6Contract = {
    billing_method_and_period:
      PaymentIntervallAndBillingMethod.annual_based_on_measurements,
    variant: variantId,
    erzeuger_anteile: []
  } as const satisfies Partial<Paragraph6ContractPayload>;

  const { paragraph6ContractCreateMutation, paragraph6ContractEditMutation } =
    useParagraph6ContractMutations(variantId);

  const isMutating =
    paragraph6ContractCreateMutation.isPending ||
    paragraph6ContractEditMutation.isPending;

  const { paragraph6Companies, isLoading: paragraph6CompaniesLoading } =
    useParagraph6Companies(variantId);

  const onSubmit: SubmitHandler<Paragraph6ContractFormFields> = (data) => {
    const fiktiveStrommengeActive = data.has_fiktive_strommenge;

    if (!data.has_fiktive_strommenge) {
      data.beteiligung_fiktive_strommengen = null;
    }
    delete data.has_fiktive_strommenge;

    if (!data.has_split_beteiligung) {
      data.beteiligung_nicht_erstattungsfaehig = null;
    }
    delete data.has_split_beteiligung;
    if (data.is_template === undefined) {
      data.is_template = false;
    }

    data.community_iban = (data?.community_iban || "").replace(
      /[^a-zA-Z0-9]/g,
      ""
    );

    const mutation = editMode
      ? paragraph6ContractEditMutation
      : paragraph6ContractCreateMutation;

    mutation.mutate(data, {
      onSuccess: () => {
        onClose();
        if (fiktiveStrommengeActive) {
          const fiktiveMengenToast = showBannerToast("warning", {
            text: "Sie haben in Ihrem Vertrag angegeben, dass auch fiktive Strommengen berücksichtigt werden. Bitte fügen Sie nun die betroffenen Erzeuger hinzu und ergänzen Sie anschließend im Bereich Energiedaten die fiktiven Mengen für den im Vertrag angegebenen Zeitraum. Wenn Sie bereits alle Erzeuger hinzugefügt haben, können Sie auch direkt auf den untenstehenden Button klicken.",
            ctaText: "Fiktive Strommengen bereitstellen",
            ctaAction: () => {
              toast.dismiss(fiktiveMengenToast);
              navigate("../../../energiedaten/bereitstellen");
            }
          });
        }
      },
      onError: (error) => {
        setResponseError(error);
      }
    });
  };

  const showContractForm =
    modalState === TemplateModalState.NewContract ||
    editMode ||
    (modalState === TemplateModalState.NewTemplateContract &&
      selectedTemplate) ||
    !templates ||
    templates.length === 0;

  const contract =
    paragraph6Contract ||
    (modalState === TemplateModalState.NewTemplateContract &&
      selectedTemplate) ||
    defaultParagraph6Contract;

  if (showContractForm) {
    const isLoading =
      paragraph6CompaniesLoading || (editMode && paragraph6ContractLoading);

    return (
      <LoadOrError
        loading={isLoading}
        loadingMessage="Vertrag wird geladen ..."
      >
        <Paragraph6ContractForm
          isStaff={shouldShowStaffView}
          isSubmitting={isMutating}
          paragraph6Companies={paragraph6Companies ?? []}
          paragraph6Contract={contract}
          submissionError={responseError}
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      </LoadOrError>
    );
  }

  if (modalState === TemplateModalState.None) {
    return <TemplateDecisionCards setModalState={setModalState} />;
  }

  return (
    <Paragraph6ContractTemplateSelect
      templates={templates}
      onChange={setSelectedTemplate}
    />
  );
}

export { Paragraph6ContractModalContent };
