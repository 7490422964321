import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import urls from "../../urls";
import { Alert, AlertColor } from "../Alert/Alert";
import { BasicConfirmationModal } from "../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import type { ButtonColor } from "../Buttons/Button/Button";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import DynamicForm from "../DynamicForm/DynamicForm";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";
import type {
  FormFieldData,
  FormItemsProps
} from "../DynamicForm/FormItems/FormItems";
import FormItems from "../DynamicForm/FormItems/FormItems";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type {
  CustomFormOptions,
  FormErrors,
  FormValues
} from "./useCustomForm";
import { useCustomForm } from "./useCustomForm";

import "./CustomForm.scss";

interface BasicCustomFormProps {
  formFields?: Array<FormFieldData>;
  CustomFormItemsComponent?: (props: FormItemsProps) => ReactNode;
  formName?: string;
  hideButtons?: boolean;
  submitButtonText?: string;
  submitButtonColor?: ButtonColor;
  cancelButtonText?: string;
  allowInput?: boolean;
  buttonContainer?: HTMLElement;
  autoSubmitEmptyFirstForm?: boolean;
  confirmSubmitText?: string;
  onCancel?: React.MouseEventHandler;
  onActionBtnClick?: () => void;
  actionButtonText?: string;
  actionButtonColor?: ButtonColor;
  isActionLoading?: boolean;
  isActionBtnDisabled?: boolean;
  children?: ReactNode;
}

interface AdvancedCustomFormProps {
  formValues?: FormValues;
  formErrors: FormErrors;
  submitting: boolean;
  moreInfoNeededEvaluationId?: string;
  waitingForMoreInfo: boolean;
  missingFields?: Array<string>;
  exclusiveOrFields?: Array<Array<string>>;
  changeValue: (
    name: string | null,
    value: FormFieldValue | Record<string, FormFieldValue> | undefined | null
  ) => void;
  submit: () => Promise<boolean>;
  setWaitingForMoreInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CustomFormProps
  extends BasicCustomFormProps,
    AdvancedCustomFormProps {}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CustomForm(props: CustomFormProps) {
  const {
    formFields,
    formValues,
    formErrors,
    submitting,
    moreInfoNeededEvaluationId,
    waitingForMoreInfo,
    changeValue,
    submit,
    onActionBtnClick,
    actionButtonText,
    actionButtonColor,
    isActionLoading,
    isActionBtnDisabled,
    setWaitingForMoreInfo,
    CustomFormItemsComponent,
    formName,
    submitButtonText,
    submitButtonColor,
    cancelButtonText,
    hideButtons,
    allowInput,
    buttonContainer,
    autoSubmitEmptyFirstForm,
    confirmSubmitText,
    onCancel,
    missingFields,
    exclusiveOrFields
  } = props;

  useEffect(() => {
    if (autoSubmitEmptyFirstForm) {
      submit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSubmitEmptyFirstForm]);

  function handleDynamicFormIsLoading() {
    setWaitingForMoreInfo(true);
  }

  function handleMoreInfoNeededCompleteChanged(isComplete) {
    setWaitingForMoreInfo(!isComplete);
  }

  const { nonFieldErrors = [] } = formErrors;

  const evaluationDetailUrl = moreInfoNeededEvaluationId
    ? urls.api.evaluationDetail(moreInfoNeededEvaluationId)
    : "";
  const FormItemsComponent = CustomFormItemsComponent
    ? CustomFormItemsComponent
    : FormItems;

  const showCustomFormButtons =
    !hideButtons && (!autoSubmitEmptyFirstForm || !!moreInfoNeededEvaluationId);
  const showAutoSubmitLoadingIcon =
    autoSubmitEmptyFirstForm && !moreInfoNeededEvaluationId;
  const safeFormValues = formValues ?? {};

  return (
    <div className="CustomForm">
      <FormItemsComponent
        allowInput={allowInput}
        exclusiveOrFields={exclusiveOrFields}
        formErrors={formErrors}
        formItems={formFields}
        formName={formName}
        formValues={safeFormValues}
        missingFields={missingFields}
        onInput={changeValue}
        onSubmit={submit}
      />
      {moreInfoNeededEvaluationId && (
        <div className="more-data-needed">
          <h6>Weitere Daten erforderlich</h6>
          <DynamicForm
            hideDoneButton
            requestUrl={evaluationDetailUrl}
            onComplete={handleMoreInfoNeededCompleteChanged}
            onLoading={handleDynamicFormIsLoading}
            onSubmit={submit}
          />
        </div>
      )}
      {nonFieldErrors.length > 0 && (
        <Alert className="non-field-errors-alert" color={AlertColor.Danger}>
          {nonFieldErrors.map((error) => (
            <p key={error} style={{ margin: "0" }}>
              {error}
            </p>
          ))}
        </Alert>
      )}
      {showAutoSubmitLoadingIcon && <AnimatedLoadingIcon />}
      {showCustomFormButtons && (
        <CustomFormButtons
          actionButtonColor={actionButtonColor}
          actionButtonText={actionButtonText}
          buttonContainer={buttonContainer}
          cancelButtonText={cancelButtonText}
          confirmSubmitText={confirmSubmitText}
          isActionBtnDisabled={isActionBtnDisabled}
          isActionLoading={isActionLoading}
          submitButtonColor={submitButtonColor}
          submitButtonText={submitButtonText}
          submitting={submitting}
          waitingForMoreInfo={waitingForMoreInfo}
          onActionBtnClick={onActionBtnClick}
          onClickCancel={onCancel}
          onClickSubmit={submit}
        />
      )}
    </div>
  );
}

interface CustomFormButtonsProps {
  onClickSubmit?: () => void;
  submitting: boolean;
  waitingForMoreInfo: boolean;
  submitButtonText?: string;
  submitButtonColor?: ButtonColor;
  onClickCancel?: React.MouseEventHandler<Element>;
  cancelButtonText?: string;
  onActionBtnClick?: () => void;
  actionButtonText?: string;
  actionButtonColor?: ButtonColor;
  isActionLoading?: boolean;
  isActionBtnDisabled?: boolean;
  buttonContainer?: HTMLElement;
  confirmSubmitText?: string;
}

export function CustomFormButtons({
  onClickSubmit,
  submitting,
  waitingForMoreInfo,
  submitButtonText = "Speichern",
  submitButtonColor = "primary",
  onClickCancel,
  cancelButtonText = "Abbrechen",
  actionButtonText,
  actionButtonColor = "brand",
  isActionLoading = false,
  onActionBtnClick,
  isActionBtnDisabled = false,
  buttonContainer,
  confirmSubmitText
}: CustomFormButtonsProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  function handleSubmit() {
    if (confirmSubmitText) {
      setIsConfirmationModalOpen(true);
    } else if (onClickSubmit) {
      onClickSubmit();
    }
  }

  function handleClickConfirm() {
    if (onClickSubmit) {
      setIsConfirmationModalOpen(false);
      onClickSubmit();
    }
  }

  const submitButton = (
    <SpinButton
      className="submit-button"
      color={submitButtonColor}
      disabled={waitingForMoreInfo}
      spin={submitting && !waitingForMoreInfo}
      style={{ marginRight: ".25rem" }}
      onClick={handleSubmit}
    >
      {submitButtonText}
    </SpinButton>
  );

  const actionButton = (
    <SpinButton
      color={actionButtonColor}
      disabled={isActionBtnDisabled}
      spin={isActionLoading}
      onClick={onActionBtnClick}
    >
      {actionButtonText}
    </SpinButton>
  );
  const cancelButton = (
    <Button onClick={onClickCancel}>{cancelButtonText}</Button>
  );

  if (buttonContainer) {
    return (
      <>
        {ReactDOM.createPortal(
          <div className="custom-form-controls-inner">
            {onActionBtnClick && actionButton}
            {onClickSubmit && submitButton}
            {onClickCancel && cancelButton}
          </div>,
          buttonContainer
        )}
      </>
    );
  }

  return (
    <>
      {onClickSubmit && submitButton}
      {onClickCancel && cancelButton}
      {!!confirmSubmitText && (
        <BasicConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          toggleModal={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleClickConfirm}
        >
          {confirmSubmitText}
        </BasicConfirmationModal>
      )}
    </>
  );
}

interface CustomFormLoaderWrapperProps extends CustomFormProps {
  autoSubmitEmptyFirstForm?: boolean;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CustomFormLoaderWrapper(props: CustomFormLoaderWrapperProps) {
  const {
    formFields,
    allowInput = true,
    autoSubmitEmptyFirstForm,
    children,
    ...otherProps
  } = props;

  // show a loading animation until formFields are supplied as props
  if (!formFields && !autoSubmitEmptyFirstForm) {
    return <AnimatedLoadingIcon />;
  }

  let formFieldsOrEmpty: Array<FormFieldData> | undefined;

  if (autoSubmitEmptyFirstForm) {
    formFieldsOrEmpty = [];
  } else {
    formFieldsOrEmpty = formFields;
  }

  return (
    <CustomForm
      allowInput={allowInput}
      autoSubmitEmptyFirstForm={autoSubmitEmptyFirstForm}
      formFields={formFieldsOrEmpty}
      {...otherProps}
    >
      {children}
    </CustomForm>
  );
}

interface EasyCustomFormProps extends BasicCustomFormProps, CustomFormOptions {}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function EasyCustomForm(props: EasyCustomFormProps) {
  const {
    formFields,
    nonFieldData,
    postUrl,
    putUrl,
    patchUrl,
    onSubmit,
    onInput,
    onFirstSubmit,
    onPollSuccess,
    onError,
    ...otherProps
  } = props;
  const useCustomFormProps = useCustomForm(formFields, {
    nonFieldData,
    postUrl,
    putUrl,
    patchUrl,
    onSubmit,
    onInput,
    onFirstSubmit,
    onPollSuccess,
    onError
  });

  return (
    <CustomFormLoaderWrapper
      formFields={formFields}
      {...otherProps}
      {...useCustomFormProps}
    />
  );
}

export function getFormFieldsFromResponse(
  fieldNames: Array<string>,
  responseData: Record<string, FormFieldData>,
  initialValues: FormValues = {}
) {
  const selectedFormFields: Array<FormFieldData> = [];

  fieldNames.forEach((fieldName) => {
    if (Object.prototype.hasOwnProperty.call(responseData, fieldName)) {
      const field: FormFieldData = {
        ...responseData[fieldName],
        name: fieldName
      };

      if (Object.prototype.hasOwnProperty.call(initialValues, fieldName)) {
        field["initialValue"] = initialValues[fieldName];
      }
      selectedFormFields.push(field);
    }
  });

  return selectedFormFields;
}

export {
  CustomFormLoaderWrapper as AdvancedCustomForm,
  EasyCustomForm as CustomForm
};
