import { BarChart, type BarChartProps } from "@mantine/charts";
import { Group, Stack } from "@mantine/core";
import classNames from "classnames";
import { type DateTime } from "luxon";
import { useState } from "react";
import { numericFormatter } from "react-number-format";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";
import { Button } from "../../Buttons/Button/Button";
import { MantineChartLegend } from "../common/MantineChartLegend";
import { getColoredSeries } from "../utils/getColoredSeries";
import "./MantineBarChart.scss";
import {
  MantineBarChartTooltip,
  type MantineBarChartPayloadItem
} from "./MantineBarChartTooltip";

type MantineBarChartProps = BarChartProps & {
  zoomable?: boolean;
  onZoom?: (start: DateTime, end: DateTime) => void;
  onZoomReset?: () => void;
};

function MantineBarChart({
  barProps,
  className,
  data,
  dataKey,
  getBarColor,
  h = 450,
  series,
  tooltipProps,
  withLegend,
  xAxisLabel,
  xAxisProps,
  yAxisProps,
  zoomable,
  onZoom,
  onZoomReset,
  ...props
}: MantineBarChartProps) {
  const [activeSeries, setActiveSeries] = useState<Array<string>>([]);

  const coloredSeries = getColoredSeries(series);

  function handleLegendToggle(seriesId: string) {
    setActiveSeries((prev) =>
      prev.includes(seriesId)
        ? prev.filter((id) => id !== seriesId)
        : [...prev, seriesId]
    );
  }

  function handleZoom(start: DateTime, end: DateTime) {
    onZoom?.(start, end);
  }

  return (
    <Group align="start" className="MantineBarChart" gap={0}>
      <Stack w={withLegend ? "80%" : "100%"}>
        {zoomable && (
          <Group>
            <Button
              className="zoom-out-btn"
              disabled={!onZoomReset}
              size="small"
              onClick={onZoomReset}
            >
              Zoom zurücksetzen
            </Button>
          </Group>
        )}
        <BarChart
          barProps={(series) => ({
            ...barProps,
            hide: activeSeries.includes(series.name),
            onClick: (e) => {
              if (zoomable) {
                handleZoom(e.payload.xStart, e.payload.xEnd);
              }
            }
          })}
          className={classNames("MantineBarChart ", className)}
          data={data}
          dataKey={dataKey}
          getBarColor={getBarColor}
          h={h}
          py="lg"
          series={coloredSeries}
          {...props}
          tooltipProps={{
            content: ({
              label,
              payload
            }: {
              label: string;
              payload: Array<MantineBarChartPayloadItem>;
            }) => {
              const title: string =
                label && xAxisProps?.tickFormatter
                  ? xAxisProps.tickFormatter(label, 0)
                  : label;

              return (
                <MantineBarChartTooltip
                  getBarColor={getBarColor}
                  labelFormatter={tooltipProps?.labelFormatter}
                  payload={payload}
                  seriesLabels={coloredSeries.reduce(
                    (result, singleSeries) => ({
                      ...result,
                      [singleSeries.name]:
                        singleSeries.label || singleSeries.name
                    }),
                    {}
                  )}
                  title={title}
                  valueFormatter={(value, name, item, index, payload) =>
                    tooltipProps?.formatter
                      ? tooltipProps.formatter(
                          value,
                          name,
                          item,
                          index,
                          payload
                        )
                      : yAxisProps?.tickFormatter
                        ? yAxisProps.tickFormatter(value, 0)
                        : value.toString()
                  }
                />
              );
            }
          }}
          withLegend={false}
          xAxisProps={{
            allowDataOverflow: true,
            interval: 0, // -> show all tick labels
            label: { value: xAxisLabel, position: "bottom" },
            ...xAxisProps
          }}
          yAxisProps={{
            tickFormatter: (value) =>
              numericFormatter(
                value.toString(),
                getDefaultNumericFormatterProps()
              ),
            ...yAxisProps
          }}
        />
      </Stack>
      {withLegend && series.length > 0 && (
        <MantineChartLegend
          activeSeries={activeSeries}
          series={coloredSeries}
          onClick={handleLegendToggle}
        />
      )}
    </Group>
  );
}

export { MantineBarChart, MantineBarChartProps };
