import { Button } from "../Buttons/Button/Button";

import "./ErrorFallback.scss";

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className="ErrorFallbackContainer" role="alert">
      <h2>Beim Laden der Seite ist ein Fehler aufgetreten.</h2>

      <p>
        Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie,
        die Seite neu zu laden.
      </p>
      <Button color="primary" onClick={resetErrorBoundary}>
        Neu laden
      </Button>
    </div>
  );
}

export { ErrorFallback };
