import { Accordion } from "@mantine/core";
import classnames from "classnames";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import type { WizardMissingData } from "../../../../utils/backend-types";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { TABS } from "../../../DeliveryOverview/DeliveryOverview";
import { OrangeCircle } from "../../../OrangeCircle/OrangeCircle";
import { StepStatus } from "../../ChangeProcess.types";
import type { TenantChange, TenantChangeSteps } from "../TenantChanges.types";

interface TenantChangeProcessProps {
  tenantChange: TenantChange;
  isUpdating: boolean;
  shoudShowStaffView: boolean;
  onUpdateStepStatus: (
    operatorChange: TenantChange,
    step: keyof TenantChangeSteps,
    status: StepStatus
  ) => void;
  projectId: string | undefined;
}

function TenantChangeProcess({
  tenantChange,
  isUpdating,
  shoudShowStaffView,
  onUpdateStepStatus,
  projectId
}: TenantChangeProcessProps) {
  const stepNewData = tenantChange.steps.stepNewData;
  const stepNewContract = tenantChange.steps.stepNewContract;
  const stepOldContract = tenantChange.steps.stepOldContract;
  const stepParticipation = tenantChange.steps.stepParticipation;
  const stepMeteringConcept = tenantChange.steps.stepMeteringConcept;

  return (
    <Accordion.Item
      className="TenantChangeProcess"
      value={`tenant-change-${tenantChange.id}`}
    >
      <Accordion.Control>
        <span className="site-tasks-title">
          {"Mieterwechsel - " + tenantChange.componentName}
        </span>
      </Accordion.Control>
      <Accordion.Panel>
        <StepParticipation
          isUpdating={isUpdating}
          missingData={stepParticipation.missingData}
          modalLink={`mieterwechsel/teilnahme/${tenantChange.id}/`}
          status={stepParticipation.done}
          onUpdateStepStatus={(status) =>
            onUpdateStepStatus(tenantChange, "stepParticipation", status)
          }
        />
        <StepNewData
          disabled={stepParticipation.done === StepStatus.open}
          isUpdating={isUpdating}
          missingData={stepNewData.missingData}
          modalLink={`mieterwechsel/unternehmensdaten/${tenantChange.id}/`}
          status={stepNewData.done}
          onUpdateStepStatus={(status) =>
            onUpdateStepStatus(tenantChange, "stepNewData", status)
          }
        />
        <StepNewContract
          disabled={stepParticipation.done === StepStatus.open}
          isUpdating={isUpdating}
          objectsAreMissing={stepNewContract.objectsAreMissing}
          projectId={projectId}
          status={stepNewContract.done}
          onUpdateStepStatus={(status) =>
            onUpdateStepStatus(tenantChange, "stepNewContract", status)
          }
        />
        <StepOldContract
          disabled={stepParticipation.done === StepStatus.open}
          isUpdating={isUpdating}
          missingData={stepOldContract.missingData}
          modalLink={`mieterwechsel/vertragsdaten/${tenantChange.id}/`}
          status={stepOldContract.done}
          onUpdateStepStatus={(status) =>
            onUpdateStepStatus(tenantChange, "stepOldContract", status)
          }
        />
        <StepMeteringConcept
          disabled={stepParticipation.done === StepStatus.open}
          isUpdating={isUpdating}
          shouldShowStaffView={shoudShowStaffView}
          status={stepMeteringConcept.done}
          onUpdateStepStatus={(status) =>
            onUpdateStepStatus(tenantChange, "stepMeteringConcept", status)
          }
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

interface StepParticipationProps {
  status: StepStatus;
  missingData: Array<WizardMissingData> | null;
  isUpdating: boolean;
  onUpdateStepStatus: (status: StepStatus) => void;
  modalLink: string;
}
function StepParticipation({
  status,
  missingData,
  isUpdating,
  onUpdateStepStatus,
  modalLink
}: StepParticipationProps) {
  return (
    <div className="tenant-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Teilnahme am Mieterstrommodell</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open &&
          (missingData === null || missingData.length === 0) && (
            <Button
              color="secondary"
              disabled={isUpdating}
              onClick={() => onUpdateStepStatus(StepStatus.done)}
            >
              Als erledigt markieren
            </Button>
          )}
        {status === StepStatus.open &&
          missingData &&
          missingData.length > 0 && (
            <Button
              color="brand"
              disabled={isUpdating}
              tag={Link}
              to={modalLink}
            >
              Fehlende Daten ergänzen
            </Button>
          )}
      </div>
    </div>
  );
}

interface StepNewDataProps {
  status: StepStatus;
  disabled: boolean;
  missingData: Array<WizardMissingData> | null;
  isUpdating: boolean;
  onUpdateStepStatus: (status: StepStatus) => void;
  modalLink: string;
}

function StepNewData({
  status,
  missingData,
  isUpdating,
  disabled,
  onUpdateStepStatus,
  modalLink
}: StepNewDataProps) {
  return (
    <div className="tenant-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Stammdaten des neuen Betreibers eintragen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open &&
          (missingData === null || missingData.length === 0) && (
            <Button
              color="secondary"
              disabled={isUpdating || disabled}
              onClick={() => onUpdateStepStatus(StepStatus.done)}
            >
              Als erledigt markieren
            </Button>
          )}
        {status === StepStatus.open &&
          missingData &&
          missingData.length > 0 && (
            <>
              <Button
                color="brand"
                disabled={isUpdating || disabled}
                tag={Link}
                to={modalLink}
              >
                Fehlende Daten ergänzen
              </Button>
              <Button
                color="secondary"
                disabled={isUpdating || disabled}
                onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
              >
                Überspringen
              </Button>
            </>
          )}
      </div>
    </div>
  );
}

interface StepNewContractProps {
  status: StepStatus;
  disabled: boolean;
  isUpdating: boolean;
  objectsAreMissing: boolean | null;
  onUpdateStepStatus: (status: StepStatus) => void;
  projectId: string | undefined;
}

function StepNewContract({
  status,
  isUpdating,
  disabled,
  objectsAreMissing,
  onUpdateStepStatus,
  projectId
}: StepNewContractProps) {
  const newContractLink = `${generatePath(ROUTES.deliveryOverview, {
    projectId: projectId ?? null
  })}/${TABS.TAB_CONTRACTS}/neu/`;

  return (
    <div className="tenant-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Neue Verträge & Lieferungen anlegen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open && !objectsAreMissing && (
          <Button
            color="secondary"
            disabled={isUpdating || disabled}
            onClick={() => onUpdateStepStatus(StepStatus.done)}
          >
            Als erledigt markieren
          </Button>
        )}
        {status === StepStatus.open && objectsAreMissing && (
          <>
            <Button
              color="brand"
              disabled={isUpdating || disabled}
              tag={Link}
              to={newContractLink}
            >
              Neue Verträge und Lieferungen anlegen
            </Button>
            <Button
              color="secondary"
              disabled={isUpdating || disabled}
              onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
            >
              Überspringen
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

interface StepOldContractProps {
  status: StepStatus;
  disabled: boolean;
  isUpdating: boolean;
  missingData: Array<WizardMissingData> | null;
  onUpdateStepStatus: (status: StepStatus) => void;
  modalLink: string;
}

function StepOldContract({
  status,
  isUpdating,
  disabled,
  missingData,
  onUpdateStepStatus,
  modalLink
}: StepOldContractProps) {
  return (
    <div className="tenant-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Verträge des alten Betreibers anpassen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open &&
          (missingData === null || missingData.length === 0) && (
            <Button
              color="secondary"
              disabled={isUpdating || disabled}
              onClick={() => onUpdateStepStatus(StepStatus.done)}
            >
              Als erledigt markieren
            </Button>
          )}
        {status === StepStatus.open &&
          missingData !== null &&
          missingData.length > 0 && (
            <>
              <Button
                color="brand"
                disabled={isUpdating || disabled}
                tag={Link}
                to={modalLink}
              >
                Fehlende Daten ergänzen
              </Button>
              <Button
                color="secondary"
                disabled={isUpdating || disabled}
                onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
              >
                Überspringen
              </Button>
            </>
          )}
      </div>
    </div>
  );
}

interface StepMeteringConceptProps {
  status: StepStatus;
  disabled: boolean;
  isUpdating: boolean;
  shouldShowStaffView: boolean;
  onUpdateStepStatus: (status: StepStatus) => void;
}

function StepMeteringConcept({
  status,
  isUpdating,
  disabled,
  shouldShowStaffView,
  onUpdateStepStatus
}: StepMeteringConceptProps) {
  return (
    <div className="tenant-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Messkonzept aktualisieren</span>
        {status === StepStatus.open && !shouldShowStaffView && (
          <div className="todo-status">
            <OrangeCircle />
          </div>
        )}
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open && shouldShowStaffView && (
          <Button
            color="secondary"
            disabled={isUpdating || disabled}
            onClick={() => onUpdateStepStatus(StepStatus.done)}
          >
            Als erledigt markieren
          </Button>
        )}
        {status === StepStatus.open && !shouldShowStaffView && (
          <span>Prüfung durch Mitarbeiter:innen</span>
        )}
      </div>
    </div>
  );
}

export { TenantChangeProcessProps, TenantChangeProcess };
