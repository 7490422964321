import classNames from "classnames";
import { useCallback, useState } from "react";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./CopyToClipboard.scss";

export interface CopyToClipboardProps {
  label: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

function CopyToClipboard({
  label,
  className,
  children,
  onClick
}: CopyToClipboardProps) {
  const [copyToClipboardClicked, setCopyToClipboardClicked] = useState(false);

  const handleClickCopyToClipboard = useCallback(() => {
    copyToClipboard(label);
    setCopyToClipboardClicked(true);
  }, [label]);

  return (
    <div
      className={classNames("copy-to-clipboard-container", className)}
      onMouseLeave={() => setCopyToClipboardClicked(false)}
    >
      {children ? children : <span onClick={onClick}>{label}</span>}
      <span
        className={classNames("copy-to-clipboard-icon", {
          clicked: copyToClipboardClicked
        })}
        onClick={handleClickCopyToClipboard}
      >
        <Icon
          className={classNames({ "text-light": copyToClipboardClicked })}
          name={IconName.Copy}
          tooltipPlace="right"
          tooltipText={
            copyToClipboardClicked ? "Kopiert" : "In Zwischenablage kopieren"
          }
        />
      </span>
    </div>
  );
}

export { CopyToClipboard };
