import { Paper, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Col, Row } from "reactstrap";
import api from "../../api";
import urls from "../../urls";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { ReportForm } from "./ReportForm/ReportForm";
import { ReportList } from "./ReportList/ReportList";

interface ReportProps {
  projectId: string;
  variantId: number;
}

type Variant = {
  id: number;
  name: string;
  bundleConsumers: boolean;
  isManagerVariant: boolean;
};

function Report(props: ReportProps) {
  const { projectId, variantId } = props;
  const {
    data: variant,
    isLoading: isVariantLoading,
    error: variantError
  } = useQuery({
    queryKey: ["variant", { variantId }],
    queryFn: () => fetchVariant(variantId)
  });

  async function fetchVariant(variantId: number) {
    const response = await api.get<Variant>(urls.api.variantDetail(variantId));
    return response.data;
  }

  return (
    <div className="Report">
      <Row>
        <Col md="6">
          <Paper>
            <Title variant="paper-header">Neuen Report erstellen</Title>
            <ReportForm projectId={projectId} variantId={variantId} />
          </Paper>
        </Col>
        <Col md="6">
          <Paper>
            <Title variant="paper-header">
              {variant && (
                <span>
                  Reports für <em>{variant.name}</em>
                </span>
              )}
            </Title>
            <LoadOrError error={variantError} loading={isVariantLoading}>
              <ReportList variantId={variantId} />
            </LoadOrError>
          </Paper>
        </Col>
      </Row>
    </div>
  );
}

export { Report };
