import { SiteSetupProcessDataStep } from "../../../types/api.types";
import {
  ADDRESS_FORM_FIELDS,
  CONNECTION_FORM_FIELDS,
  CONSUMER_FORM_FIELDS,
  NAME_AND_PVP_PLANTS_FIELDS
} from "../SiteSetupAssistant.types";

export function getFieldsForActiveStep(
  step: SiteSetupProcessDataStep
): Array<string> {
  switch (step) {
    case SiteSetupProcessDataStep.name_and_pv_plants:
      return NAME_AND_PVP_PLANTS_FIELDS;
    case SiteSetupProcessDataStep.address_and_supply_type:
      return ADDRESS_FORM_FIELDS;
    case SiteSetupProcessDataStep.connection:
      return CONNECTION_FORM_FIELDS;
    case SiteSetupProcessDataStep.consumers:
      return CONSUMER_FORM_FIELDS;
    default:
      return [];
  }
}
