import type React from "react";
import { useState, useCallback } from "react";

export function useContainerRef() {
  const [containerNode, setContainerNode] = useState<HTMLElement | undefined>(
    undefined
  );
  const containerRef: React.Ref<HTMLDivElement> | undefined = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setContainerNode(node);
      }
    },
    []
  );

  return { containerNode, containerRef };
}
