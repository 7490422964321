import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../Buttons/Button/Button";
import { Input } from "../../../../DynamicForm/FormItems/FormField/Input/Input";
import type { AcquisitionFilter } from "../../EnergyDataView";
import type {
  EdaFilter,
  EdaFilterOptions
} from "../EnergyDataAcquisitionSelectionModal";
import { DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS } from "../EnergyDataAcquisitionSelectionModal";
import { Filter } from "./Filter";
import { EMPTY_FILTERS, FILTER_TYPE_KEYS } from "./filter-utils";

interface FiltersProps {
  filters: Array<AcquisitionFilter>;
  definedFilters: Array<EdaFilter>;
  definedFilterId: number | null;
  savingDefinedFilters: boolean;
  filterOptions: Record<string, EdaFilterOptions>;
  onChangeFilters: (filters: Array<AcquisitionFilter>) => void;
  onSaveDefinedFilter: (definedFilterName: string) => void;
  onDeleteDefinedFilter: (definedFilterId: number) => void;
}

function Filters({
  filters,
  definedFilters,
  definedFilterId,
  savingDefinedFilters,
  filterOptions,
  onChangeFilters,
  onSaveDefinedFilter,
  onDeleteDefinedFilter
}: FiltersProps) {
  const [namingModalOpen, setNamingModalOpen] = useState(false);

  function handleFilterTypeChange(index: number, type: string) {
    updateFilter(index, type, [""]);
  }

  function handleFilterOptionsChange(
    index: number,
    options: Array<string | { min: number; max: number }>
  ) {
    updateFilter(index, filters[index].type, options);
  }

  function updateFilter(
    index: number,
    type: string,
    options: Array<string | { min: number; max: number }>
  ) {
    const newFilters = [...filters];
    newFilters[index] = {
      type: type,
      options: options
    };

    onChangeFilters(newFilters);
  }

  function handleClickAddFilter() {
    onChangeFilters([
      ...filters,
      {
        type: "",
        options: [""]
      }
    ]);
  }

  function handleClickClearFilters() {
    onChangeFilters(EMPTY_FILTERS);
  }

  function handleRemoveFilter(index: number) {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    onChangeFilters(newFilters);
  }

  function toggleNamingModal() {
    setNamingModalOpen((prevState) => !prevState);
  }

  function handleConfirmNameAndSaveFilter(name: string) {
    onSaveDefinedFilter(name);
    setNamingModalOpen(false);
  }

  function isFilterSafe(filter: AcquisitionFilter) {
    return (
      !!filterOptions[filter.type] ||
      filter.type === "installedCapacity" ||
      filter.type === "date" ||
      filter.type === EMPTY_FILTERS[0].type
    );
  }

  const availableTypes = FILTER_TYPE_KEYS.filter(
    (filterType) => !filters.find((filter) => filter.type === filterType)
  );
  const selectedDefinedFilter = definedFilters.find(
    (definedFilter) => definedFilter.id === definedFilterId
  );
  const canSave = definedFilterId === DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS;
  const canDelete =
    selectedDefinedFilter && !selectedDefinedFilter.availableInAllProjects;
  const safeFilters = filters.filter((filter) => isFilterSafe(filter));

  // old filters might have junk in them, so we should log that
  if (safeFilters.length !== filters.length) {
    Sentry.captureMessage(
      `Unsupported filters found (maybe check defined filter ${definedFilterId}): ${JSON.stringify(
        filters
      )}`
    );
  }

  return (
    <div className="filters">
      <div className="filter-list">
        {safeFilters.map((filter, index) => (
          <Filter
            availableTypes={availableTypes}
            canRemove={index > 0}
            key={filter.type || index}
            options={filter.options}
            possibleOptions={filterOptions[filter.type] || []}
            type={filter.type}
            onChangeOptions={(options) =>
              handleFilterOptionsChange(index, options)
            }
            onChangeType={(type) => handleFilterTypeChange(index, type)}
            onRemoveFilter={() => handleRemoveFilter(index)}
          />
        ))}
        <Button
          className="add-condition-button"
          color="brand"
          disabled={safeFilters.length === FILTER_TYPE_KEYS.length}
          onClick={handleClickAddFilter}
        >
          Kriterium hinzufügen
        </Button>
        <Button
          className="clear-conditions-button"
          color="danger"
          disabled={safeFilters === EMPTY_FILTERS}
          onClick={handleClickClearFilters}
        >
          Alle Kriterien löschen
        </Button>
      </div>
      <div className="filter-controls">
        <Button color="brand" disabled={!canSave} onClick={toggleNamingModal}>
          Filter speichern
        </Button>
        <Button
          color="danger"
          disabled={!canDelete}
          onClick={
            definedFilterId
              ? () => onDeleteDefinedFilter(definedFilterId)
              : undefined
          }
        >
          Filter löschen
        </Button>
        <DefinedFilterNamingModal
          isOpen={namingModalOpen}
          savingDefinedFilters={savingDefinedFilters}
          onConfirmName={handleConfirmNameAndSaveFilter}
          onToggle={toggleNamingModal}
        />
      </div>
    </div>
  );
}

interface DefinedFilterNamingModalProps {
  isOpen: boolean;
  savingDefinedFilters: boolean;
  onToggle: () => void;
  onConfirmName: (name: string) => void;
}

function DefinedFilterNamingModal({
  isOpen,
  savingDefinedFilters,
  onToggle,
  onConfirmName
}: DefinedFilterNamingModalProps) {
  const [name, setName] = useState("");

  useEffect(() => {
    if (isOpen) {
      setName("");
    }
  }, [isOpen]);

  function handleKeyPressEnter() {
    if (name.length > 0) {
      onConfirmName(name);
    }
  }

  return (
    <Modal
      className="DefinedFilterNamingModal"
      isOpen={isOpen}
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>Filter benennen</ModalHeader>
      <ModalBody scrollable>
        <Input
          id="defined-filter-name"
          name="defined-filter-name"
          type="text"
          value={name}
          onChange={(value) =>
            setName(typeof value !== "undefined" ? value.toString() : "")
          }
          onKeyPressEnter={handleKeyPressEnter}
        />
        <Button
          color="brand"
          disabled={name.length === 0 || savingDefinedFilters}
          onClick={() => onConfirmName(name)}
        >
          Speichern
        </Button>
      </ModalBody>
    </Modal>
  );
}

export { Filters };
