import { generatePath, Link, useNavigate } from "react-router-dom";
import type { Filter } from "react-table";

import { ROUTES } from "../../../../routes";
import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import { TABS as ENERGY_DATA_VIEW_TABS } from "../../../EnergyData/EnergyDataView/EnergyDataView";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";

export enum DataAssessmentState {
  Sufficient = "ausreichend",
  NotSufficient = "nicht ausreichend",
  None = "keine"
}

export enum DataAssessmentAccessor {
  SiteDataAssessmentGridMakoMeter = "siteDataAssessmentGridMakoMeter",
  SiteDataAssessmentGeneratorSubMeter = "siteDataAssessmentGeneratorSubMeter"
}

export type DataAssessmentObject =
  | { siteDataAssessmentGridMakoMeter: boolean | null }
  | { siteDataAssessmentGeneratorSubMeter: boolean | null };

interface DataAssessmentCellProps {
  projectId: string;
  assessment: boolean | null;
}

function DataAssessmentCell({
  projectId,
  assessment
}: DataAssessmentCellProps) {
  const textAndSymbolStyle = { display: "flex", alignItems: "center" };
  const energyDataPath = generatePath(ROUTES.energyData, { projectId });
  const dataAvailabilityPath = `${energyDataPath}/${ENERGY_DATA_VIEW_TABS.TAB_ENERGY_DATA_AVAILABILITY}`;

  const navigate = useNavigate();

  if (assessment === null) {
    return (
      <div>
        <span>-</span>
      </div>
    );
  }

  const statusSymbolColour = assessment
    ? StatusSymbolColour.Green
    : StatusSymbolColour.Red;

  return (
    <div
      style={{ ...textAndSymbolStyle, cursor: "pointer" }}
      onClick={() => navigate(dataAvailabilityPath)}
    >
      <StatusSymbol colour={statusSymbolColour} />
      <Link to={dataAvailabilityPath}>
        {assessment === true
          ? DataAssessmentState.Sufficient
          : DataAssessmentState.NotSufficient}
      </Link>
    </div>
  );
}

function DataAssessmentFilter({ filter, onChange }) {
  const choices = Object.keys(DataAssessmentState).reduce((choices, key) => {
    const value = DataAssessmentState[key];
    choices[value] = value;
    return choices;
  }, {});
  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterDataAssessment(
  filter: Filter,
  row: DataAssessmentObject,
  accessor: DataAssessmentAccessor
) {
  if (!filter.value) {
    return true;
  }

  const options = filter.value as Record<string, boolean>;
  const optionLabels = Object.keys(filter.value);
  const assessment = row[accessor] as boolean | null;
  let assessmentValue = DataAssessmentState.None;

  if (assessment !== null) {
    assessmentValue =
      assessment === true
        ? DataAssessmentState.Sufficient
        : DataAssessmentState.NotSufficient;
  }

  const match = optionLabels.some(
    (label) => options[label] && assessmentValue === label
  );

  return match;
}

export { DataAssessmentCell, DataAssessmentFilter, onFilterDataAssessment };
