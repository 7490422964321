import { ROUTES } from "../../routes";
import { IconName } from "../BuildingBlocks/Icon/types";
import type { MenuItemData } from "./Menu.types";
import { Group } from "./Menu.types";

export const MENU_ITEMS: { [key: string]: MenuItemData } = {
  PRODUCT_SELECT_PAGE: {
    label: "Modulauswahl",
    icon: IconName.Projects,
    url: ROUTES.productSelectPage,
    group: Group.ModuleChoice,
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  },
  MANAGER_START_PAGE: {
    label: "Startseite",
    icon: IconName.Home,
    url: ROUTES.managerStartPage,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  MANAGER_PROJECT_LIST: {
    label: "Dashboard",
    icon: IconName.Projects,
    url: ROUTES.managerProjectList,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  ANALYZER_PROJECT_LIST: {
    label: "Projektauswahl",
    icon: IconName.Projects,
    url: ROUTES.analyzerProjectList,
    group: Group.Analyzer,
    showGroups: [Group.TopLevel, Group.Analyzer]
  },
  SIMULATION: {
    label: "PV Simulation",
    icon: IconName.Cogwheel,
    url: ROUTES.simulation,
    group: Group.PvSimulation,
    showGroups: [Group.TopLevel, Group.Analyzer, Group.PvSimulation]
  },
  ANALYZER_VARIANT_STRUCTURE: {
    label: "Konfiguration",
    icon: IconName.Cogwheel,
    url: ROUTES.analyzerVariantStructure,
    group: Group.Analyzer,
    showGroups: [Group.TopLevel, Group.Analyzer],
    activeUrlNames: ["pv-simulation-form-view", "missing-data"]
  },
  ANALYSE: {
    label: "Analyse",
    icon: IconName.Analytics,
    url: ROUTES.analyse,
    group: Group.Analyzer,
    showGroups: [Group.TopLevel, Group.Analyzer]
  },
  VARIANT_COMPARISON: {
    label: "Variantenvergleich",
    icon: IconName.Rocket,
    url: ROUTES.variantComparison,
    group: Group.Analyzer,
    showGroups: [Group.TopLevel, Group.Analyzer]
  },
  REPORT: {
    label: "Ergebnisreport",
    icon: IconName.Report,
    url: ROUTES.report,
    group: Group.Analyzer,
    showGroups: [Group.TopLevel, Group.Analyzer]
  },
  DIREKTVERMARKTUNG_PROJECT_LEVEL: {
    label: "Direktvermarktung",
    icon: IconName.Billing,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    url: ROUTES.direktvermarktungProjectLevel
  },
  MANAGER_PROJECT_VIEW: {
    label: "Projektübersicht",
    icon: IconName.Cogwheel2,
    url: ROUTES.managerProjectView,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  MANAGER_VARIANT_STRUCTURE: {
    label: "Liegenschaft",
    icon: IconName.Pins,
    url: ROUTES.managerVariantStructure,
    group: Group.ManagerSite,
    showGroups: [Group.TopLevel, Group.Manager, Group.ManagerSite]
  },
  TODOS: {
    label: "Aufgaben",
    icon: IconName.Todos,
    url: ROUTES.todos,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  ONBOARDING: {
    label: "Onboarding",
    icon: IconName.List,
    url: ROUTES.onboarding,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  ENERGY_DATA: {
    label: "Energiedaten",
    icon: IconName.Graph,
    url: ROUTES.energyData,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  DELIVERY_OVERVIEW: {
    label: "Rechnungen",
    icon: IconName.Billing,
    url: ROUTES.deliveryOverview,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  GUARANTEE_OF_ORIGIN: {
    label: "Herkunftsnachweis",
    icon: IconName.GuaranteeOfOrigin,
    url: ROUTES.guaranteeOfOrigin,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    staff: true
  },
  OPTI_CHARGE: {
    label: "opti.charge",
    icon: IconName.OptiCharge,
    group: Group.Manager,
    showGroups: [Group.Manager]
  },
  PERFORMANCE_MONITORING_OVERVIEW: {
    label: "Performance Monitoring",
    icon: IconName.Billing,
    url: ROUTES.performanceMonitoringOverview,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  PARKABRECHNUNG: {
    label: "Parkabrechnung",
    icon: IconName.Analytics,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    url: ROUTES.parkAbrechnung
  },
  DIREKTVERMARKTUNG: {
    label: "Direktvermarktung",
    icon: IconName.Billing,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    url: ROUTES.direktvermarktung
  },
  PARAGRAPH6: {
    label: "§ 6 EEG",
    icon: IconName.Paragraph6,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    url: ROUTES.paragraph6
  },
  REVENUE_MONITORING: {
    label: "Erlösmonitoring",
    icon: IconName.RevenueMonitoring,
    url: ROUTES.revenueMonitoring,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  REVENUE_MONITORING_PROJECT_LEVEL: {
    label: "Erlösmonitoring",
    icon: IconName.RevenueMonitoring,
    url: ROUTES.revenueMonitoringProjectLevel,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager]
  },
  API_SETTINGS: {
    label: "API-Einstellungen",
    icon: IconName.Cogs,
    url: ROUTES.apiSettings,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.ModuleChoice],
    persistGroups: true
  },
  HISTORY: {
    label: "History",
    icon: IconName.Chain,
    url: ROUTES.history,
    group: Group.Manager,
    showGroups: [Group.TopLevel, Group.Manager],
    hidden: true,
    persistGroups: true,
    persistActiveItem: true
  },
  ADMIN: {
    label: "Admin",
    icon: IconName.Admin,
    staff: true,
    group: Group.TopLevel,
    showGroups: [Group.TopLevel]
  }
};
