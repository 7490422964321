import { useCallback, useMemo } from "react";
import type { ControlledStateOverrideProps } from "react-table";

import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { COMMON_PAGINATION_PROPS, DEFAULT_PAGE_SIZE } from "../constants";
import { NoDataComponent } from "../CustomReactTable";
import type { CustomReactTableWithSelectProps } from "./CustomReactTableWithSelect";
import { CustomReactTableWithSelect } from "./CustomReactTableWithSelect";

interface Props<T> extends CustomReactTableWithSelectProps<T> {
  count: number;
  name?: string;
  fetchData: (page: number, pageSize: number, ordering?: string) => void;
}

function PagedCustomReactTableWithSelect<T>({
  count,
  name,
  fetchData,
  ...reactTableNativeProps
}: Props<T>) {
  const pages = useMemo(() => {
    const { pageSize, defaultPageSize } = reactTableNativeProps;

    return Math.ceil(
      count / (pageSize || defaultPageSize || DEFAULT_PAGE_SIZE)
    );
  }, [count, reactTableNativeProps]);

  const onFetchData = useCallback(
    ({ page, pageSize, sorted }: ControlledStateOverrideProps) =>
      fetchData(
        page as number,
        pageSize as number,
        `${sorted[0].desc ? "-" : ""}${sorted[0].id}`
      ),
    [fetchData]
  );

  return (
    <CustomReactTableWithSelect<T>
      loadingText={<AnimatedLoadingIcon />}
      manual
      NoDataComponent={() => <NoDataComponent name={name} />}
      pages={pages}
      showPageJump
      showPagination
      onFetchData={onFetchData}
      {...reactTableNativeProps}
      {...COMMON_PAGINATION_PROPS}
    />
  );
}

export { PagedCustomReactTableWithSelect };
