import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { ButtonDropdown, Input, Label } from "reactstrap";
import { usePrevious } from "../../../../hooks/usePrevious";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../../BuildingBlocks/Dropdown/Dropdown";
import { Button } from "../../../Buttons/Button/Button";
import "./DropdownFilter.scss";

interface CheckedKvp {
  key: string;
  value: boolean;
}

const SHOW_ALL_TEXT = "Alle auswählen";
const SHOW_NONE_TEXT = "Keine auswählen";
const WITHOUT_TEXT = "Ohne";
const NO_SELECTION_TEXT = "Bitte wählen";
const SHOW_MANY_TEXT = "Mehrere ausgewählt";

export const WITHOUT_KEY = "ohne";

let preventNextToggle = false;

interface DropdownFilterProps<T> {
  choices: Record<string, T>;
  disabled?: boolean;
  filter: {
    value: Record<string, boolean>;
  } | null;
  instantUpdates?: boolean;
  search?: boolean;
  showNoneOption?: boolean;
  showWithoutOption?: boolean;
  onChange: (selectedKeys: Record<string, boolean>) => void;
}

function DropdownFilter<T extends string>({
  filter,
  choices,
  showNoneOption,
  showWithoutOption,
  search,
  instantUpdates,
  disabled,
  onChange
}: DropdownFilterProps<T>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(getDefaultSelectedKeys());
  const prevIsDropdownOpen = usePrevious(isDropdownOpen);

  function handleInputChange(value: string) {
    setInputValue(value);
  }

  function toggleDropdown() {
    if (preventNextToggle) {
      preventNextToggle = false;
      return;
    }

    if (!isDropdownOpen) {
      setInputValue("");
      setSelectedKeys(getDefaultSelectedKeys(true));
    }

    setIsDropdownOpen(!isDropdownOpen);
  }

  function handleDropdownToggleKeyDown(
    e: React.KeyboardEvent<HTMLButtonElement>
  ) {
    // work around for Dropdown having a focused button, which gets closed when you hit space
    if (e.key === " ") {
      e.preventDefault();
      handleInputChange(`${inputValue} `);
    }
  }

  function handleClickDropdownToggle() {
    // don't toggle/close the dropdown when it is open, if we are using search mode (i.e. there is an Input)
    if (isDropdownOpen && search) {
      preventNextToggle = true;
    }
  }

  function handleClickAll() {
    setSelectedKeys(getAllChecked(true));
  }

  function handleClickNone() {
    setSelectedKeys(getAllChecked(false));
  }

  function handleClickDropdownItem(key: string) {
    const newSelectedKeys = [...selectedKeys];
    const keyIndex = selectedKeys.findIndex(
      (keyValuePair) => keyValuePair.key === key
    );

    if (keyIndex >= 0) {
      newSelectedKeys[keyIndex] = {
        key: key,
        value: !selectedKeys[keyIndex].value
      };
    } else {
      newSelectedKeys.push({
        key: key,
        value: true
      });
    }

    setSelectedKeys(newSelectedKeys);

    if (instantUpdates) {
      handleClickClose();
    }
  }

  function handleClickClose() {
    changeVisibleFilterOptions(selectedKeys);
    toggleDropdown();
  }

  function getDefaultSelectedKeys(sortCheckedFirst?: boolean) {
    if (!filter) {
      return getAllChecked(true);
    }

    let completeFilter = filter.value;
    const isFilterMissingValues =
      Object.keys(filter.value).length !== Object.keys(choices).length;

    if (isFilterMissingValues) {
      completeFilter = {
        ...Object.keys(choices).reduce<Record<string, boolean>>(
          (defaults, choice) => {
            defaults[choice] = false;
            return defaults;
          },
          {}
        ),
        ...filter.value
      };
    }

    const filterArray = Object.keys(completeFilter).map<CheckedKvp>((key) => {
      return {
        key: key,
        value: filter.value[key]
      };
    });

    if (sortCheckedFirst) {
      filterArray.sort((a, b) => {
        if (a.value && !b.value) {
          return -1;
        } else if (b.value && !a.value) {
          return 1;
        } else if (a.key === WITHOUT_KEY) {
          return -1;
        } else if (b.key === WITHOUT_KEY) {
          return 1;
        }

        return 0;
      });
    }

    return filterArray;
  }

  function getAllChecked(checked: boolean) {
    const allChecked: Array<CheckedKvp> = [];

    if (showWithoutOption) {
      allChecked.push({
        key: WITHOUT_KEY,
        value: checked
      });
    }

    Object.keys(choices).forEach((key) => {
      allChecked.push({
        key: key,
        value: checked
      });
    });

    return allChecked;
  }

  function getDisplayText() {
    const numSelectable = selectedKeys.length;
    const numSelected = selectedKeys.filter(
      (keyValuePair) => keyValuePair.value === true
    ).length;
    let text: string;

    if (numSelected === numSelectable) {
      text = SHOW_ALL_TEXT;
    } else if (numSelected === 1) {
      text = getTextWhenOneIsSelected();
    } else if (numSelected === 0) {
      text = NO_SELECTION_TEXT;
    } else {
      // more than 1 but not all are selected
      text = SHOW_MANY_TEXT;
    }

    return text;
  }

  function getTextWhenOneIsSelected() {
    const filteredSelectedKeys = selectedKeys.filter(
      (keyValuePair) => keyValuePair.value === true
    );

    // since there is only one selected, just take the first (only) one
    const selectedKey = filteredSelectedKeys[0].key;

    const text =
      selectedKey === WITHOUT_KEY ? WITHOUT_TEXT : choices[selectedKey];

    return text;
  }

  const shouldShowKey = useCallback(
    (text: string) =>
      !search ||
      text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
      inputValue === "",
    [inputValue, search]
  );

  const shouldShowKeyOrWithoutOption = useCallback(
    (key: string) =>
      key === WITHOUT_KEY
        ? shouldShowKey(WITHOUT_TEXT)
        : shouldShowKey(choices[key]),
    [choices, shouldShowKey]
  );

  const changeVisibleFilterOptions = useCallback(
    (selectedKeys: Array<CheckedKvp>) => {
      const visibleKeys = search
        ? selectedKeys.map((keyValuePair) => {
            const { key } = keyValuePair;
            const value = keyValuePair.value
              ? shouldShowKeyOrWithoutOption(key)
              : false;

            return { key, value };
          })
        : selectedKeys;

      const visibleKeysObject = visibleKeys.reduce<Record<string, boolean>>(
        (keys, keyValuePair) => {
          keys[keyValuePair.key] = keyValuePair.value;
          return keys;
        },
        {}
      );

      onChange(visibleKeysObject);
    },
    [onChange, search, shouldShowKeyOrWithoutOption]
  );

  const selectedKeysObject = selectedKeys.reduce<Record<string, boolean>>(
    (keys, keyValuePair) => {
      keys[keyValuePair.key] = keyValuePair.value;
      return keys;
    },
    {}
  );

  useEffect(() => {
    const dropdownClosed = !!prevIsDropdownOpen && !isDropdownOpen;
    if (dropdownClosed && instantUpdates) {
      changeVisibleFilterOptions(selectedKeys);
    }
  }, [
    prevIsDropdownOpen,
    isDropdownOpen,
    instantUpdates,
    changeVisibleFilterOptions,
    selectedKeys
  ]);

  return (
    <ButtonDropdown
      className="DropdownFilter"
      isOpen={isDropdownOpen}
      toggle={() => toggleDropdown()}
    >
      <DropdownToggle
        caret
        className={classnames({ "dropdown-toggle-with-search": search })}
        disabled={disabled}
        onClick={handleClickDropdownToggle}
        onKeyDown={handleDropdownToggleKeyDown}
      >
        {search && isDropdownOpen ? (
          <Input
            autoFocus
            className="filter-dropdown-text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        ) : (
          <span className="filter-dropdown-text">{getDisplayText()}</span>
        )}
      </DropdownToggle>
      <DropdownMenu strategy="fixed">
        <div className="scrollable-menu-items">
          <DropdownItem toggle={false} onClick={handleClickAll}>
            {SHOW_ALL_TEXT}
          </DropdownItem>
          {showNoneOption && (
            <DropdownItem toggle={false} onClick={handleClickNone}>
              {SHOW_NONE_TEXT}
            </DropdownItem>
          )}
          <DropdownItem divider />
          {selectedKeys.map((selectedKeyValuePair) => (
            <SelectedKeyValuePairDropdownItem
              choices={choices}
              key={selectedKeyValuePair.key}
              selectedKeysObject={selectedKeysObject}
              selectedKeyValuePair={selectedKeyValuePair}
              shouldShowKey={shouldShowKey}
              showWithoutOption={showWithoutOption}
              onClickDropdownItem={handleClickDropdownItem}
            />
          ))}
        </div>
        {!instantUpdates && (
          <Button
            className="apply-button"
            color="brand"
            onClick={handleClickClose}
          >
            Anwenden
          </Button>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

interface SelectedKeyValuePairDropdownItemProps {
  selectedKeyValuePair: CheckedKvp;
  choices: Record<string, string>;
  selectedKeysObject: Record<string, boolean>;
  showWithoutOption?: boolean;
  shouldShowKey: (text: string) => boolean;
  onClickDropdownItem: (key: string) => void;
}

function SelectedKeyValuePairDropdownItem({
  selectedKeyValuePair,
  choices,
  selectedKeysObject,
  showWithoutOption,
  shouldShowKey,
  onClickDropdownItem
}: SelectedKeyValuePairDropdownItemProps) {
  const selectedKey = selectedKeyValuePair.key;

  if (
    selectedKey === WITHOUT_KEY &&
    showWithoutOption &&
    shouldShowKey(WITHOUT_TEXT)
  ) {
    return (
      <DropdownItem
        toggle={false}
        onClick={() => onClickDropdownItem(WITHOUT_KEY)}
      >
        <CheckboxItem
          checked={!!selectedKeysObject[WITHOUT_KEY]}
          text={WITHOUT_TEXT}
        />
      </DropdownItem>
    );
  }

  if (
    selectedKey === WITHOUT_KEY ||
    !Object.prototype.hasOwnProperty.call(choices, selectedKey) ||
    !shouldShowKey(choices[selectedKey])
  ) {
    return null;
  }

  return (
    <DropdownItem
      toggle={false}
      onClick={() => onClickDropdownItem(selectedKey)}
    >
      <CheckboxItem
        checked={!!selectedKeysObject[selectedKey]}
        text={choices[selectedKey]}
      />
    </DropdownItem>
  );
}

function CheckboxItem({ text, checked }) {
  return (
    <Label className="checkbox-item m-checkbox">
      <Input
        checked={checked}
        className="m-checkbox"
        readOnly
        type="checkbox"
      />
      <span />
      {text}
    </Label>
  );
}

export { DropdownFilter, DropdownFilterProps };
