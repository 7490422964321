import { Input, Label } from "reactstrap";
import "./Checkbox.scss";

interface CheckboxProps {
  checked?: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

function Checkbox({
  checked,
  readOnly,
  children,
  onChange,
  disabled
}: CheckboxProps) {
  return (
    <Label className="Checkbox checkbox-item m-checkbox">
      <Input
        checked={checked}
        className="m-checkbox"
        disabled={disabled}
        readOnly={readOnly}
        type="checkbox"
        onChange={() => onChange(!checked)}
      />
      <span className={disabled ? "m-checkbox-disabled" : ""} />
      {children}
    </Label>
  );
}

export { Checkbox, CheckboxProps };
