import { type QueryClient, useQuery } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";

interface UserMfaActiveMethods {
  name: string;
  isPrimary: boolean;
}

export function use2FaConfig() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["2fa-config"],
    queryFn: fetchUser2FaConfig,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchUser2FaConfig() {
    const response = await api.get<Array<UserMfaActiveMethods>>(
      urls.auth.active2FaMethods()
    );
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}

export function invalidate2FaConfigCache(queryClient: QueryClient) {
  queryClient.invalidateQueries({ queryKey: ["2fa-config"] });
}
