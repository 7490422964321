import PropTypes from "prop-types";
import { PureComponent } from "react";
import EnergyFlowDisplay from "./EnergyFlowDisplay/EnergyFlowDisplay";

export default class EnergyFlowPlots extends PureComponent {
  render() {
    const { formatForReport, queryUrl } = this.props;
    return (
      <EnergyFlowDisplay
        consumerTableHeader="Bezugsmengen / Verbraucher"
        dataUrls={queryUrl}
        formatForReport={formatForReport}
        producerTableHeader="Absatzmengen / Erzeuger"
      />
    );
  }
}

EnergyFlowPlots.propTypes = {
  formatForReport: PropTypes.bool,
  queryUrl: PropTypes.objectOf(PropTypes.string).isRequired
};

EnergyFlowPlots.defaultProps = {
  formatForReport: false
};
