import type { Todo } from "../../../utils/backend-types";
import { TodoStatus } from "../../../utils/backend-types";
import { Badge } from "../../BuildingBlocks/Badge/Badge";
import { Mode } from "../../Todos/common";
import { useTodos } from "../../Todos/hooks/useTodos";
import { useSystemUsers } from "../../Todos/utils/useSystemUsers";

interface OnboardingCounterProps {
  variantId: number;
}

function OnboardingCounter({ variantId }: OnboardingCounterProps) {
  const {
    todos,
    isLoading: isTodosLoading,
    error: todosError
  } = useTodos({
    mode: Mode.Onboarding,
    variantId: variantId
  });

  const {
    isLoading: isUsersLoading,
    error: usersError,
    data: users
  } = useSystemUsers();

  if (isTodosLoading || todosError || isUsersLoading || usersError) {
    return null;
  }

  const onboardingTeamUser = users?.find(
    (user) => user.name === "Onboarding Team"
  );

  function filterRelevantTodos(todo: Todo) {
    if (onboardingTeamUser) {
      return (
        todo.status === TodoStatus.Open &&
        todo.responsible !== onboardingTeamUser.id
      );
    } else {
      return todo.status === TodoStatus.Open;
    }
  }

  const numTodos = todos ? todos.filter(filterRelevantTodos).length : 0;

  if (numTodos === 0) {
    return null;
  }

  return (
    <Badge
      color="danger"
      pill
      style={{
        position: "absolute",
        right: "25px",
        top: "15px",
        color: "#FFF",
        padding: "0.25em 0.5em",
        borderRadius: "0.45rem"
      }}
    >
      {numTodos}
    </Badge>
  );
}

export { OnboardingCounter };
