import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import type { Loadprofile } from "../../../utils/backend-types";
import type { FormValues } from "../../CustomForm/useCustomForm";
import { LoadProfileSelect } from "../../LoadProfileSelect/LoadProfileSelect";
import { YearlyEnergyInput } from "../../LoadProfileSelect/YearlyEnergyInput/YearlyEnergyInput";
import type { FormFieldValue } from "./FormField/FormField";
import type FormItems from "./FormItems";
import type { FormItemsProps } from "./FormItems";

const LOADPROFILE_FIELD_NAME = "loadprofile";
const YEARLY_ENERGY_FIELD_NAME = "yearlyEnergy";

function initialOverwrite(formValues: FormValues) {
  if (
    !formValues[YEARLY_ENERGY_FIELD_NAME] ||
    (formValues[LOADPROFILE_FIELD_NAME] as Loadprofile)[
      YEARLY_ENERGY_FIELD_NAME
    ]
  ) {
    return null;
  }

  const yearlyEnergy = formValues[YEARLY_ENERGY_FIELD_NAME] as number;
  const loadprofileYearlyEnergy = (
    formValues[LOADPROFILE_FIELD_NAME] as Loadprofile
  )[YEARLY_ENERGY_FIELD_NAME];

  return Math.round(yearlyEnergy) !== Math.round(loadprofileYearlyEnergy);
}

function getInitialFormItemsWithPortals(
  formItemsWithPortals: Array<string> | undefined
) {
  return [
    ...(formItemsWithPortals || []),
    LOADPROFILE_FIELD_NAME,
    YEARLY_ENERGY_FIELD_NAME
  ];
}

type FormItemsType = typeof FormItems;

interface WrappedFormItemsWithLoadProfileFieldsProps extends FormItemsProps {
  initialLoadprofileListOpen?: boolean;
  variantId: number;
  loadprofileType: "generation" | "consumption";
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function withLoadprofileFields(FormItems: FormItemsType) {
  const WrappedFormItemsWithLoadProfileFields = ({
    formItems,
    formName,
    formValues,
    formErrors,
    initialLoadprofileListOpen,
    variantId,
    loadprofileType,
    formItemsWithPortals,
    allowInput,
    onFormItemNodeChange,
    onInput,
    ...otherProps
  }: WrappedFormItemsWithLoadProfileFieldsProps) => {
    const [yearlyEnergyOverwriteMode, setYearlyEnergyOverwriteMode] = useState(
      initialOverwrite(formValues)
    );
    const [localFormItemsWithPortals, setLocalFormItemsWithPortals] = useState(
      getInitialFormItemsWithPortals(formItemsWithPortals)
    );
    const [loadprofileFormItemNode, setLoadprofileFormItemNode] =
      useState<HTMLElement | null>(null);
    const [yearlyEnergyFormItemNode, setYearlyEnergyFormItemNode] =
      useState<HTMLElement | null>(null);

    const handleInput = useCallback(
      (key: string, value: FormFieldValue | Record<string, FormFieldValue>) => {
        if (
          key === LOADPROFILE_FIELD_NAME &&
          !yearlyEnergyOverwriteMode &&
          value !== null
        ) {
          const loadprofileValue: Loadprofile = value as Loadprofile;

          onInput(null, {
            [key]: loadprofileValue,
            [YEARLY_ENERGY_FIELD_NAME]: loadprofileValue.yearlyEnergy
          });
        } else {
          onInput(key, value);
        }
      },
      [yearlyEnergyOverwriteMode, onInput]
    );

    function handleFormItemNodeChange(
      formItemName: string,
      node: HTMLElement | null
    ) {
      if (formItemName === LOADPROFILE_FIELD_NAME) {
        setLoadprofileFormItemNode(node);
      } else if (formItemName === YEARLY_ENERGY_FIELD_NAME) {
        setYearlyEnergyFormItemNode(node);
      } else if (onFormItemNodeChange) {
        onFormItemNodeChange(formItemName, node);
      }
    }

    function handleToggleYearlyEnergyOverwriteMode() {
      if (yearlyEnergyOverwriteMode) {
        const selectedLoadprofile = formValues[LOADPROFILE_FIELD_NAME];
        const newYearlyEnergy = selectedLoadprofile
          ? selectedLoadprofile[YEARLY_ENERGY_FIELD_NAME]
          : 0;

        onInput(YEARLY_ENERGY_FIELD_NAME, newYearlyEnergy);
      }

      setYearlyEnergyOverwriteMode(!yearlyEnergyOverwriteMode);
    }

    const loadprofileFormItemData = formItems.find(
      (formItem) => formItem.name === LOADPROFILE_FIELD_NAME
    );
    const yearlyEnergyFormItemData = formItems.find(
      (formItem) => formItem.name === YEARLY_ENERGY_FIELD_NAME
    );

    const loadprofileFormItemId = `id_${
      formName ? formName : "form"
    }_${loadprofileFormItemData?.name.replace(/\./g, "__")}`;
    const yearlyEnergyFormItemId = `id_${
      formName ? formName : "form"
    }_${yearlyEnergyFormItemData?.name.replace(/\./g, "__")}`;

    useEffect(() => {
      if (
        !localFormItemsWithPortals.includes(LOADPROFILE_FIELD_NAME) ||
        !localFormItemsWithPortals.includes(YEARLY_ENERGY_FIELD_NAME)
      ) {
        setLocalFormItemsWithPortals(
          getInitialFormItemsWithPortals(formItemsWithPortals)
        );
      }
    }, [formItemsWithPortals, localFormItemsWithPortals]);

    useEffect(() => {
      if (yearlyEnergyOverwriteMode === null) {
        setYearlyEnergyOverwriteMode(initialOverwrite(formValues));
      }
    }, [formValues, yearlyEnergyOverwriteMode]);

    return (
      <>
        <FormItems
          {...otherProps}
          allowInput={allowInput}
          formErrors={formErrors}
          formItems={formItems}
          formItemsWithPortals={localFormItemsWithPortals}
          formValues={formValues}
          onFormItemNodeChange={handleFormItemNodeChange}
          onInput={handleInput}
        />
        {loadprofileFormItemNode &&
          ReactDOM.createPortal(
            <LoadProfileSelect
              allowInput={!loadprofileFormItemData?.readOnly && allowInput}
              errorTexts={formErrors[LOADPROFILE_FIELD_NAME]}
              id={loadprofileFormItemId}
              initialLoadprofileListOpen={initialLoadprofileListOpen}
              loadprofileType={loadprofileType}
              value={formValues[LOADPROFILE_FIELD_NAME]}
              variantId={variantId}
              onInput={handleInput}
              {...loadprofileFormItemData}
            />,
            loadprofileFormItemNode
          )}
        {yearlyEnergyFormItemNode &&
          ReactDOM.createPortal(
            <YearlyEnergyInput
              allowInput={!yearlyEnergyFormItemData?.readOnly && allowInput}
              errorTexts={formErrors[YEARLY_ENERGY_FIELD_NAME]}
              id={yearlyEnergyFormItemId}
              overwriteMode={yearlyEnergyOverwriteMode}
              value={formValues[YEARLY_ENERGY_FIELD_NAME]}
              onInput={handleInput}
              onToggleOverwriteMode={handleToggleYearlyEnergyOverwriteMode}
              {...yearlyEnergyFormItemData}
            />,
            yearlyEnergyFormItemNode
          )}
      </>
    );
  };

  return WrappedFormItemsWithLoadProfileFields;
}

export { withLoadprofileFields };
