import { Paper } from "@mantine/core";
import { Col, Row } from "reactstrap";
import {
  type LocationSection,
  type MeteringConceptByPersons
} from "../../utils/backend-types";
import { LOCATION_SECTIONS, THEME_VARS } from "../../utils/constants";
import { Section } from "../BuildingBlocks/Layout/Section";
import loader from "../Loader/Loader.js";
import MarketLocationComparison from "../MeteringConceptComparison/MarketLocationComparison";
import { mergeByAccessors } from "../MeteringConceptComparison/merging";
import {
  SectionHeaderRow,
  SectionTitleRow
} from "../MeteringConceptComparison/MeteringConceptComparison";
import "./MeteringConcept.scss";

function ContentWrapper({
  formatForReport,
  children
}: {
  formatForReport: boolean;
  children: React.ReactNode;
}) {
  if (formatForReport) {
    // the Mantine `Paper` component somehow prevents the content from being rendered in the report PDF output in this component
    // therefore we revert to simple `div` (since in the report, there's no styling desired anyway)
    return <div>{children}</div>;
  } else {
    return <Paper>{children}</Paper>;
  }
}

interface MeteringConceptProps {
  data: { meteringConcept: [MeteringConceptByPersons] };
  variantId: number;
  formatForReport: boolean;
}

function MeteringConcept({
  data,
  variantId,
  formatForReport
}: MeteringConceptProps) {
  const marketLocations = data.meteringConcept[0].persons.reduce(
    (marketLocations, person) => {
      return [...marketLocations, ...person.marketLocations];
    },
    []
  );
  const marketLocationsByVariantId = {
    [variantId]: marketLocations
  };

  function renderLocationSections(
    locationTypes,
    mergedMarketLocations,
    meteringConceptKeys,
    meteringConceptConfiguration
  ) {
    const keysWithLocationTypeToRender = mergedMarketLocations.keys.filter(
      (key) =>
        locationTypes.includes(mergedMarketLocations.common[key].locationType)
    );

    return keysWithLocationTypeToRender.map((key) => (
      <MarketLocationComparison
        displayFormulas
        key={key}
        marketLocationKey={key}
        mergedMarketLocations={mergedMarketLocations}
        meteringConceptConfiguration={meteringConceptConfiguration}
        meteringConceptKeys={meteringConceptKeys}
      />
    ));
  }

  const meteringConceptKeys = Object.keys(marketLocationsByVariantId);
  const meteringConceptConfiguration = {
    [meteringConceptKeys[0]]: {
      header: "",
      headerBackgroundColor: THEME_VARS.brandColor,
      cellBackgroundColor: null
    }
  };
  const mergedMarketLocations = mergeByAccessors(
    marketLocationsByVariantId,
    ["locationType", "generator.name", "consumer.name", "connection.name"],
    ["generator.name", "consumer.name"]
  );
  const locationSectionsWithRenderedResults = LOCATION_SECTIONS.map<
    LocationSection & { rendered: Array<React.ReactNode> }
  >((section) => {
    return {
      ...section,
      rendered: renderLocationSections(
        section.locationTypes,
        mergedMarketLocations,
        meteringConceptKeys,
        meteringConceptConfiguration
      )
    };
  });
  const minWidth = `${320 * meteringConceptKeys.length}px`;

  return (
    <div id="metering-concept-table">
      <Row style={{ minWidth }}>
        <Col>
          <ContentWrapper formatForReport={formatForReport}>
            {locationSectionsWithRenderedResults.map((section) => {
              const { key: sectionKey, ...sectionProps } = section;
              return (
                <Section key={sectionKey}>
                  <SectionTitleRow
                    meteringConceptConfiguration={meteringConceptConfiguration}
                    meteringConceptKeys={meteringConceptKeys}
                    title={sectionProps.title}
                  />
                  <SectionHeaderRow
                    {...sectionProps}
                    displayFormulas={true}
                    meteringConceptConfiguration={meteringConceptConfiguration}
                    meteringConceptKeys={meteringConceptKeys}
                  />
                  {sectionProps.rendered}
                </Section>
              );
            })}
          </ContentWrapper>
        </Col>
      </Row>
    </div>
  );
}

export default loader(MeteringConcept);
