import type { MRT_Cell, MRT_Row, MRT_RowData } from "mantine-react-table";
import { useCallback, useState } from "react";
import type { AddressInfo } from "../../../../components/SiteSetupAssistant/SiteSetupAssistant.types";
import { MantineAddressInfoCell } from "../cell/MantineAddressInfoCell";

type ValueType = AddressInfo;

interface ControlledMantineEditAddressInfoProps<T extends MRT_RowData> {
  cell: MRT_Cell<T, ValueType>;
  columnId: string;
  row: MRT_Row<T>;
  allowInput?: boolean;
  errorText?: string | undefined;
  checkForValidity?: (value: ValueType) => void;
}

function ControlledMantineEditAddressInfo<T extends MRT_RowData>({
  cell,
  columnId,
  row,
  allowInput,
  errorText,
  checkForValidity
}: ControlledMantineEditAddressInfoProps<T>) {
  const [value, setValue] = useState<ValueType>(cell.getValue() ?? {});

  const saveValueToRowCache = useCallback(
    (newValue: ValueType) => {
      //@ts-expect-error when using a generic type, the valuesCache is readonly
      row._valuesCache[columnId] = newValue;
    },
    [columnId, row]
  );

  function handleChange(newValue: ValueType) {
    setValue(newValue);
    saveValueToRowCache(newValue);
    checkForValidity?.(newValue);
  }

  return (
    <MantineAddressInfoCell
      allowInput={allowInput}
      errorText={errorText}
      initialValue={value}
      onChange={handleChange}
    />
  );
}

export { ControlledMantineEditAddressInfo };
