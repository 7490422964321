import type { NumericFormatProps } from "react-number-format";
import { NumericFormat, numericFormatter } from "react-number-format";
import { getDefaultNumericFormatterProps } from "./getDefaultNumericFormatterProps";

export type RoundedNumberFormatProps = NumericFormatProps;

function RoundedNumberFormat({ value, ...other }: RoundedNumberFormatProps) {
  let roundedValue = value;

  if (value && value !== "" && Math.abs(Number(value)) < 1e-5) {
    roundedValue = 0;
  }

  return (
    <NumericFormat
      decimalSeparator=","
      thousandSeparator="."
      value={roundedValue}
      {...other}
    />
  );
}

function roundedNumericFormatter(
  value: string | number | null | undefined,
  props: NumericFormatProps
) {
  const numericFormatterProps = getDefaultNumericFormatterProps(props);
  const decimalScale = numericFormatterProps.decimalScale || 2;

  if (value === null || value === undefined) {
    return "";
  }

  let roundedValue = value;

  if (value && value !== "" && Math.abs(Number(value)) < 1e-5) {
    roundedValue = 0;
  }

  return numericFormatter(
    String(
      Math.round(Number(roundedValue) * Math.pow(10, decimalScale)) /
        Math.pow(10, decimalScale)
    ),
    numericFormatterProps
  );
}

export { RoundedNumberFormat, roundedNumericFormatter };
