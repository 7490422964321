import { useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import urls from "../../urls";
import { uploadFileOrFiles } from "../../utils/files/uploadFileOrFiles";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";

import "../Todos/TodoModal/DocumentsWidget/DocumentsDropzone/DocumentsDropzone.scss";

interface InvoiceDropzoneProps {
  variantId: number;
}

export function InvoiceDropzone({ variantId }: InvoiceDropzoneProps) {
  const [isUploading, setIsUploading] = useState(false);
  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: isUploading,
    multiple: true,
    onDrop: handleDrop
  });
  const queryClient = useQueryClient();

  function handleDrop(files: Array<File>) {
    if (files.length >= 1) {
      handleUploadFile(files);
    } else {
      alert("Ein Fehler ist aufgetreten. Bitte nochmal versuchen.");
    }
  }

  async function handleUploadFile(files: Array<File>) {
    setIsUploading(true);

    try {
      await uploadFileOrFiles(files, urls.api.uploadInvoices(), "files", {
        variant: variantId
      });

      queryClient.invalidateQueries({
        queryKey: ["invoices", { variantId }]
      });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      openErrorAlertPopup(error);
    }
  }

  function handleClickOpenFileInput(e: React.MouseEvent) {
    e.stopPropagation();
    open();
  }

  return (
    <div className="documents-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <p className="upload-text">
        Um neue Rechnungen hochzuladen, können Sie diese per Drag&amp;Drop in
        diesen Bereich ziehen.
      </p>
      <div className="upload-icon">
        <Icon name={IconName.Upload} />
      </div>
      <Button
        className={classnames("upload-button", {
          "m-loader m-loader--light m-loader--left": isUploading
        })}
        color="brand"
        onClick={handleClickOpenFileInput}
      >
        Datei(-en) auswählen
      </Button>
    </div>
  );
}
