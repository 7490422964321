import type { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Contract, Site } from "../../../../../../utils/backend-types";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { HelperText } from "../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { getErrorKeyFromFieldError } from "../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/getErrorKeyFromFieldError";
import type { Choice } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { CONTRACT_FORM_FIELD_DATA } from "../ContractForm.constants";

interface TemplateFieldsProps {
  control: Control<Contract>;
  errors: FieldErrors<Contract>;
  templateContracts: Array<Contract>;
  sites: Array<Site>;
}

function TemplateFields({
  control,
  errors,
  templateContracts,
  sites
}: TemplateFieldsProps) {
  const { t } = useTranslation();
  const templateChoices: Array<Choice<string>> =
    templateContracts?.map((contract) => {
      return { value: contract.id, displayName: contract.name };
    }) ?? [];
  const filteredSites = sites?.filter((site) => site.invoicing_active);
  const siteChoices: Array<Choice<number>> =
    filteredSites?.map((site) => {
      return { value: site.id, displayName: site.name };
    }) ?? [];
  const templateErrorKey = getErrorKeyFromFieldError(
    errors.creationTemplate || errors.creationTemplateSite
  );
  const templateError = templateErrorKey && t(templateErrorKey);

  return (
    <div className="TemplateFields">
      <FormFieldController
        control={control}
        data={{
          ...CONTRACT_FORM_FIELD_DATA.creationTemplate,
          choices: templateChoices
        }}
      />
      <FormFieldController
        control={control}
        data={{
          ...CONTRACT_FORM_FIELD_DATA.creationTemplateSite,
          choices: siteChoices
        }}
      />
      <HelperText error={templateError} />
    </div>
  );
}

export { TemplateFields };
