import { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import type {
  Contract,
  Delivery,
  Person,
  Site
} from "../../../utils/backend-types";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { ContractDeliveryTable } from "./ContractDeliveryTable/ContractDeliveryTable";
import { ContractModal } from "./ContractModal/ContractModal";
import { DeliveryModal } from "./DeliveryModal/DeliveryModal";
import { NewContractDecisionModal } from "./NewContractDecisionModal/NewContractDecisionModal";
import "./contractDeliveries.scss";

const newPath = `neu/`;

export type ContractDeliveriesProps = {
  variantId: number;
  contracts: Array<Contract>;
  persons: Array<Person>;
  deliveries: Array<Delivery>;
  onContractsUpdated: () => void;
  onDeliveriesUpdated: () => void;
  sites: Array<Site>;
};

function ContractDeliveries({
  variantId,
  contracts: initialContracts,
  persons,
  deliveries: initialDeliveries,
  onContractsUpdated,
  onDeliveriesUpdated,
  sites
}: ContractDeliveriesProps) {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState(initialContracts);
  const [deliveries, setDeliveries] = useState(initialDeliveries);

  const noContracts = !contracts || contracts.length === 0;
  useEffect(() => {
    setContracts(initialContracts);
  }, [initialContracts]);
  useEffect(() => {
    setDeliveries(initialDeliveries);
  }, [initialDeliveries]);

  function handleSubmitNewDelivery() {
    onDeliveriesUpdated();
    handleCloseDeliveryModal();
  }

  function handleCloseDeliveryModal() {
    navigate(".");
  }

  function handleEditDelivery(updatedDelivery) {
    const updatedDeliveries = [...deliveries];
    const index = deliveries.findIndex(
      (delivery) => delivery.id === updatedDelivery.id
    );

    updatedDeliveries[index] = updatedDelivery;

    setDeliveries(updatedDeliveries);
    onDeliveriesUpdated();
    handleCloseDeliveryModal();
  }

  function handleSubmitNewOrEditContract() {
    onContractsUpdated();
    onDeliveriesUpdated();
    handleCloseContractModal();
  }

  function handleCloseContractModal() {
    navigate(".");
  }
  function handleContractsDeleted(ids) {
    const newContracts = contracts.filter(
      (contract) => !ids.includes(contract.id)
    );

    setContracts(newContracts);
    onContractsUpdated();
  }

  return (
    <div className="ContractDeliveries">
      <Routes>
        <Route
          element={
            <DeliveryModal
              variantId={variantId}
              onClose={handleCloseDeliveryModal}
              onSubmit={handleSubmitNewDelivery}
            />
          }
          path="neue-lieferung"
        />
        <Route
          element={
            <DeliveryModalWithDeliveryId
              variantId={variantId}
              onClose={handleCloseDeliveryModal}
              onSubmit={handleEditDelivery}
            />
          }
          path="lieferung/:deliveryId/*"
        />
        <Route
          element={
            <NewContractDecisionModal
              sites={sites.filter((site) => site.variant === variantId)}
              templateContracts={contracts.filter(
                (contract) => contract.isTemplate
              )}
              variantId={variantId}
              onClose={handleCloseContractModal}
              onSubmit={handleSubmitNewOrEditContract}
            />
          }
          path="neu"
        />
        <Route
          element={
            <ContractModalWithContractId
              variantId={variantId}
              onClose={handleCloseContractModal}
              onSubmit={handleSubmitNewOrEditContract}
            />
          }
          path="vertrag/:contractId/*"
        />
      </Routes>
      {noContracts ? (
        <NoDataComponent />
      ) : (
        <div className="contract-deliveries-overview">
          <div className="contract-deliveries-controls-container">
            <IconButton
              color="brand"
              iconName={IconName.Plus}
              tag={Link}
              to={newPath}
            >
              Vertrag hinzufügen
            </IconButton>
          </div>
          <div className="small-height" />
          <ContractDeliveryTable
            contracts={contracts}
            deliveries={deliveries}
            persons={persons}
            sites={sites}
            onContractsUpdated={onContractsUpdated}
            onDataDeleted={handleContractsDeleted}
            onDeliveriesUpdated={onDeliveriesUpdated}
          />
        </div>
      )}
    </div>
  );
}

function NoDataComponent() {
  return (
    <div className="contract-deliveries-no-data-overview">
      <h3 className="contract-deliveries-no-data-header">
        Fügen Sie einen Vertrag hinzu
      </h3>
      <p className="contract-deliveries-no-data-description">
        Es sind derzeit keine Verträge vorhanden. Klicken Sie auf den Button{" "}
        {`"Vertrag hinzufügen"`}, um einen hinzuzufügen.
      </p>
      <IconButton
        color="brand"
        iconName={IconName.Plus}
        tag={Link}
        to={newPath}
      >
        Vertrag hinzufügen
      </IconButton>
    </div>
  );
}

function DeliveryModalWithDeliveryId(props) {
  const { deliveryId } = useParams();
  return <DeliveryModal deliveryId={deliveryId} {...props} />;
}

function ContractModalWithContractId(props) {
  const { contractId } = useParams();
  return <ContractModal contractId={contractId} {...props} />;
}

export { ContractDeliveries };
