import classnames from "classnames";
import { IconName } from "../BuildingBlocks/Icon/types";
import { ActionIcon } from "./ActionIcon";
import "./TemplateIcon.scss";

interface IconForContractTemplateProps {
  isTemplate: boolean;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function TemplateIcon({ isTemplate, onClick }: IconForContractTemplateProps) {
  return (
    <ActionIcon
      className={classnames("TemplateIcon", {
        "TemplateIcon-active": isTemplate
      })}
      data-testid="template-toggle"
      iconName={isTemplate ? IconName.FileMinus : IconName.FileText}
      tooltipText={
        isTemplate
          ? "„Referenzvertrag“-Markierung entfernen"
          : "Als Referenzvertrag für neue Verträge markieren"
      }
      onClick={onClick}
    />
  );
}

export { TemplateIcon };
