import { useContext } from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";

import {
  OBJECT_NAMES_TO_OBJECT_URL_NAMES,
  OBJECT_URL_NAMES_TO_OBJECT_NAMES,
  ObjectName,
  ObjectUrlName,
  Product
} from "../../../utils/enums";
import type { TabData } from "../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../BuildingBlocks/Layout/TabBar/TabBar";
import { ComponentListContainer } from "../../ComponentListContainer/ComponentListContainer";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import type { ViewMode } from "../StructureView";
import { UserAccessContext } from "../UserAccessContext";

import "./ComponentTabs.scss";
import { WeightingTab } from "./NonWizardTabs/WeightingTab";

interface ComponentTabsProps {
  siteId: number;
  variantId: number;
  product: Product;
  onChangeViewMode: (viewMode: ViewMode) => void;
  graphCoordinates: { x: number; y: number };
  setShowNotAccessibleModal: (show: boolean) => void;
}

const staffOnlyTabs = [ObjectName.Weighting] as const;

function ComponentTabs({
  siteId,
  variantId,
  product,
  onChangeViewMode,
  graphCoordinates,
  setShowNotAccessibleModal
}: ComponentTabsProps) {
  const isStaff = useShouldShowStaffView();
  const resolved = useResolvedPath(":objectUrlName/*");
  const match = useMatch({ path: resolved.pathname });
  const activeTab = match
    ? match.params.objectUrlName
    : ObjectUrlName.Generators;

  const userCanCreateAndDelete = useContext(UserAccessContext);

  const nonWizardTabs = {
    [ObjectName.Weighting]: <WeightingTab siteId={siteId} />
  } as const;

  const tabs = Object.values(ObjectName).reduce<Array<TabData>>(
    (tabs, objectName) => {
      if (product === Product.Analyzer) {
        if (
          objectName === ObjectName.MarketLocation ||
          objectName === ObjectName.MeteringLocation
        ) {
          return tabs;
        } else if (objectName === ObjectName.Meter) {
          return tabs;
        } else if (objectName === ObjectName.GasConnection) {
          return tabs;
        }
      } else if (objectName === ObjectName.Person) {
        return tabs;
      }

      return [
        ...tabs,
        {
          id: OBJECT_NAMES_TO_OBJECT_URL_NAMES[objectName],
          label: objectName
        }
      ];
    },
    []
  );

  const staffedTabs = isStaff
    ? tabs
    : tabs.filter(
        (tab) =>
          !staffOnlyTabs.includes(tab.label as (typeof staffOnlyTabs)[number])
      );

  return (
    <div className="ComponentTabs">
      <TabBar
        activeTab={activeTab ?? ObjectUrlName.Generators}
        tabs={staffedTabs}
      />
      <TabContent activeTab={activeTab}>
        {staffedTabs.map(({ id, label }) => (
          <TabPane key={id} tabId={id}>
            {nonWizardTabs[label] ? (
              nonWizardTabs[label]
            ) : (
              <ComponentListContainer
                graphCoordinates={graphCoordinates}
                objectDisplayName={label}
                objectName={OBJECT_URL_NAMES_TO_OBJECT_NAMES[id]}
                product={product}
                setShowNotAccessibleModal={setShowNotAccessibleModal}
                siteId={siteId}
                userHasAccess={
                  !!userCanCreateAndDelete &&
                  userCanCreateAndDelete[OBJECT_URL_NAMES_TO_OBJECT_NAMES[id]]
                }
                variantId={variantId}
                onChangeViewMode={onChangeViewMode}
              />
            )}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}

export { ComponentTabs, ComponentTabsProps };
