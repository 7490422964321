import classNames from "classnames";
import type { BadgeProps as ReactstrapBadgeProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { Badge as ReactstrapBadge } from "reactstrap";
import "./Badge.scss";

const BadgeSupportedColor = [
  "brand",
  "primary",
  "secondary",
  "success",
  "danger",
  "info",
  "warning",
  "light",
  "dark"
] as const;

interface BadgeProps extends Omit<ReactstrapBadgeProps, "color"> {
  color?: (typeof BadgeSupportedColor)[number] | string;
  darkText?: boolean;
}

/** Renders a Badge.
 * @param color When of type `BadgeSupportedColor`, it will render a Badge with the corresponding color using pre-set styles. Custom colours are possible but may require extra styling.
 * @param darkText Adds the "text-dark" bootstrap class to the Badge.
 * @see https://reactstrap.github.io/?path=/docs/components-badge--badge
 */
function Badge({ className, darkText, ...props }: BadgeProps) {
  return (
    <ReactstrapBadge
      className={classNames(className, { "text-dark": darkText })}
      {...props}
    />
  );
}

export { Badge, BadgeProps, BadgeSupportedColor };
