import type { DateTime } from "luxon";
import { useState } from "react";

export function useHistoricalDataImportState() {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  function setDates(start: DateTime, end: DateTime) {
    setStartDate(start.toISO());
    setEndDate(end.toISO());
  }

  return [startDate, endDate, setDates] as const;
}
