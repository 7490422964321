import classnames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import type { IconName } from "../BuildingBlocks/Icon/types";
import "./IconHelpText.scss";
import { Popover, type PopoverPosition } from "./Popover/Popover";

interface IconHelpTextProps {
  className?: string;
  icon: IconName;
  helpText: string;
  popoverPosition: PopoverPosition;
}

function IconHelpTextPopover({
  className,
  helpText,
  icon,
  popoverPosition = "left"
}: IconHelpTextProps) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function togglePopover(e?: React.MouseEvent<HTMLElement, MouseEvent>) {
    setPopoverIsOpen(!popoverIsOpen);
    e?.stopPropagation();
  }

  function handleClickOutside(event: MouseEvent) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as HTMLDivElement)
    ) {
      setPopoverIsOpen(false);
    }
  }

  return (
    <Popover
      opened={popoverIsOpen}
      position={popoverPosition}
      ref={popoverRef}
      text={helpText}
      onOpenModal={togglePopover}
    >
      <Icon
        className={classnames(className, "help-icon-clickable")}
        name={icon}
        onClick={togglePopover}
      />
    </Popover>
  );
}

export { IconHelpTextPopover };
