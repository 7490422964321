import { Tooltip } from "@mantine/core";
import classNames from "classnames";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconWithLink } from "../../../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";

interface RevokeDocumentCellProps {
  revokedStatus?: string;
  onClickRevoke: () => void;
  disabled?: boolean;
}

/** only use in DocumentsList */
function RevokeDocumentCell({
  revokedStatus,
  onClickRevoke,
  disabled
}: RevokeDocumentCellProps) {
  return (
    <div className={classNames("icons", { disabled })}>
      {revokedStatus !== null ? (
        <Tooltip label={`Ungültig markiert am ${revokedStatus}`}>
          <Icon name={IconName.Warning} />
        </Tooltip>
      ) : (
        <IconWithLink
          name={IconName.Close}
          tooltipText="Dokument ungültig markieren"
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClickRevoke();
            }
          }}
        />
      )}
    </div>
  );
}

export { RevokeDocumentCell };
