import type {
  Person,
  Priority,
  RegulatoryDuty,
  TodoCategory,
  TodoStatus,
  ExtendedUser
} from "../../../../utils/backend-types";
import { RegulatoryDutyCategory } from "../../../../utils/backend-types";
import { EMPTY_FIELD } from "../../../../utils/constants";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { CATEGORIES } from "../../common";
import { Description } from "../../TodoDescription/TodoDescription";
import { TodoDueDateCell } from "../../TodoTable/Cells/TodoDueDateCell";
import TodoPersonCell from "../../TodoTable/Cells/TodoPersonCell";
import { TodoResponsibleCell } from "../../TodoTable/Cells/TodoResponsibleCell";
import type { TodoStatusCellProps } from "../../TodoTable/TableColumns";
import {
  PriorityCell,
  TodoDocumentStatusCell,
  TodoStatusCell
} from "../../TodoTable/TableColumns";

interface DescriptionTabProps
  extends Pick<TodoStatusCellProps, "onChangeStatus"> {
  todoId: number;
  label: string;
  description: string | null;
  regulatoryDuty: RegulatoryDuty | null;
  category: TodoCategory;
  priorityValue: Priority;
  fulfillFor: string | null;
  personName: string | null;
  dueDate: string | null;
  overdue: boolean;
  responsibleId: number | null;
  status: TodoStatus;
  users: Array<ExtendedUser>;
  universal: boolean;
  todoStatus: boolean;
  persons: Array<Person>;
  onChangeStatus: (status: TodoStatus, todoId: number) => void;
  onChangeResponsible: (responsibleId: number, todoId: number) => void;
  onChangePriority: (priorityValue: Priority, todoId: number) => void;
  onChangeTodoStatus: (todoId: number) => void;
  onChangeDueDate: (todoId: number, dueDate: string | null) => void;
  onChangeOverdue: (todoId: number, overdue: boolean) => void;
  onChangePerson: (todoId: number, person: number) => void;
}

function DescriptionTab({
  todoId,
  label,
  description,
  regulatoryDuty,
  category,
  fulfillFor,
  personName,
  dueDate,
  overdue,
  responsibleId,
  priorityValue,
  status,
  users,
  universal,
  todoStatus,
  persons,
  onChangeStatus,
  onChangeResponsible,
  onChangePriority,
  onChangeTodoStatus,
  onChangeDueDate,
  onChangePerson,
  onChangeOverdue
}: DescriptionTabProps) {
  const categoryKey = Object.keys(CATEGORIES).find(
    (categoryKey) => CATEGORIES[categoryKey].value === category
  );

  const shouldShowStaffView = useShouldShowStaffView();

  return (
    <div className="overview">
      <div className="todo-property">
        {!description && <h6>Informationen zur regulatorischen Pflicht</h6>}
        <Description
          regulatoryDuty={regulatoryDuty}
          userDefinedDescription={description}
        />
      </div>
      {categoryKey && CATEGORIES[categoryKey] && (
        <div className="todo-property">
          <h6>Kategorie</h6>
          <span className="todo-property-value">
            {CATEGORIES[categoryKey].displayName}
          </span>
        </div>
      )}
      {fulfillFor && (
        <div className="todo-property">
          <h6>Zu erfüllen für</h6>
          <span className="todo-property-value">{fulfillFor}</span>
        </div>
      )}
      <div className="todo-property">
        <h6>Verpflichtetes Unternehmen</h6>
        {regulatoryDuty?.category === RegulatoryDutyCategory.Premium ? (
          <TodoPersonCell
            person={personName}
            persons={persons}
            todoId={todoId}
            onChangePerson={onChangePerson}
          />
        ) : (
          <span className="todo-property-value">
            {personName || EMPTY_FIELD}
          </span>
        )}
      </div>
      <div className="todo-property">
        <h6>Fälligkeit</h6>
        {regulatoryDuty?.category === RegulatoryDutyCategory.Premium ? (
          <TodoDueDateCell
            dueDate={
              dueDate
                ? backendDateOrDateTimeToLuxonDateTime(dueDate)
                : undefined
            }
            overdue={overdue}
            todoId={todoId}
            onChangeDueDate={onChangeDueDate}
            onChangeOverdue={onChangeOverdue}
          />
        ) : (
          <span className="todo-property-value">
            {overdue ? "Überfällig" : dueDate || EMPTY_FIELD}
          </span>
        )}
      </div>
      <div className="todo-property">
        <h6>Verantwortlich</h6>
        <TodoResponsibleCell
          responsibleId={responsibleId}
          todoId={todoId}
          universal={universal}
          users={users}
          onChangeResponsible={onChangeResponsible}
        />
      </div>
      <div className="todo-property">
        <h6>Priorität</h6>
        <PriorityCell
          id={todoId}
          label={label}
          priorityValue={priorityValue}
          onChangePriority={onChangePriority}
        />
      </div>

      <div className="todo-property">
        <h6>Status</h6>
        <TodoStatusCell
          id={todoId}
          label={label}
          status={status}
          onChangeStatus={onChangeStatus}
        />
      </div>
      <div className="todo-property">
        <h6>Aufgaben Status</h6>
        <TodoDocumentStatusCell
          id={todoId}
          isStaff={shouldShowStaffView}
          label={label}
          status={todoStatus}
          onChangeTodoStatus={onChangeTodoStatus}
        />
      </div>
    </div>
  );
}

export { DescriptionTab, DescriptionTabProps };
