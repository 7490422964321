import { List, Text } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { FREQUENCY_OPTIONS } from "../../../../../utils/constants";
import type { Frequency } from "../../../../../utils/enums";
import { Alert, AlertColor } from "../../../../Alert/Alert";
import { Extendable } from "../../../../Extendable/Extendable";
import type { Acquisition } from "../../EnergyDataView";

interface EnergyDataViewWarningProps {
  acquisitionsWithLowerFrequency: Array<Acquisition>;
  selectedFrequency: Frequency;
}

function EnergyDataViewWarning({
  acquisitionsWithLowerFrequency,
  selectedFrequency
}: EnergyDataViewWarningProps) {
  const formattedAcquisitions = acquisitionsWithLowerFrequency.map((a) => (
    <List.Item key={a.id}>
      {`${a.label}`} <strong>[{`${a.frequency}`}]</strong>
    </List.Item>
  ));
  const formattedSelectedFrequency = FREQUENCY_OPTIONS[selectedFrequency];

  return (
    <Alert color={AlertColor.Warning} icon={<IconExclamationCircle />} mb="md">
      <Text>
        Einige Energiedaten sind für das Zeitintervall{" "}
        <strong>{formattedSelectedFrequency}</strong> nicht verfügbar.
      </Text>
      <Extendable>
        <Text mb="xs">
          Folgende Energiedaten liegen nicht im Zeitintervall{" "}
          {formattedSelectedFrequency} vor bzw. können nicht aggregiert werden.
        </Text>
        <List size="sm" withPadding>
          {formattedAcquisitions}
        </List>
      </Extendable>
    </Alert>
  );
}

export { EnergyDataViewWarning };
