import type { SubMeteringSystems } from "../../../utils/enums";
import { getSystemDisplayNameFromValue } from "./getSystemDisplayNameFromValue";

export function getConfigurationDisplayName(
  configurationName: string,
  system: SubMeteringSystems
) {
  let displayName = configurationName;

  if (!displayName) {
    displayName = getSystemDisplayNameFromValue(system);
  }

  return displayName;
}
