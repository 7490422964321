import { useStartPageProducedElectricity } from "../../../hooks/startPage/useStartPageProducedElectricity";
import { formatEnergyValue } from "../../../utils/formatEnergyValue";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type ProducedElectricityMiniTileProps = {
  projectId?: string;
  year: number;
};

function ProducedElectricityMiniTile({
  projectId,
  year
}: ProducedElectricityMiniTileProps) {
  const { data, isLoading } = useStartPageProducedElectricity(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Summe seit Jahresbeginn",
      value: data ? formatEnergyValue(data.producedElectricityKwh) : ""
    }
  ];

  return (
    <MiniTile
      iconName={IconName.WindGenerator}
      isLoading={isLoading}
      items={items}
      title="Erzeugte Strommengen"
    />
  );
}

export { ProducedElectricityMiniTile };
