import { NumberInput, type NumberInputProps } from "@mantine/core";
import type { MRT_Cell, MRT_Row, MRT_RowData } from "mantine-react-table";
import { useCallback, useEffect, useState } from "react";

interface ControlledMantineEditNumberInputProps<T extends MRT_RowData>
  extends Omit<NumberInputProps, "value"> {
  alignRight?: boolean;
  cell: MRT_Cell<T>;
  columnId: string;
  errorText?: string | undefined;
  label?: string;
  overrideTriggersChange?: boolean;
  overrideValue?: string | number;
  row: MRT_Row<T>;
  storeAsNumber?: boolean;
}

/** Custom NumberInput Edit component for mantine-react-table.
 * Inspired by this workaround: https://github.com/KevinVandy/mantine-react-table/discussions/8#discussioncomment-8759454
 */
function ControlledMantineEditNumberInput<T extends MRT_RowData>({
  alignRight,
  cell,
  columnId,
  errorText,
  label,
  overrideTriggersChange,
  overrideValue,
  row,
  storeAsNumber,
  onChange,
  ...rest
}: ControlledMantineEditNumberInputProps<T>) {
  const [value, setValue] = useState(
    cell.getValue<string | number | null | undefined>() ?? ""
  );

  const saveInputValueToRowCache = useCallback(
    (newValue: string | number) => {
      if (storeAsNumber) {
        //@ts-expect-error when using a generic type, the valuesCache is readonly
        row._valuesCache[columnId] =
          typeof newValue === "string" ? Number(newValue) : newValue;
      } else {
        //@ts-expect-error when using a generic type, the valuesCache is readonly
        row._valuesCache[columnId] =
          typeof newValue === "number" ? newValue.toString() : newValue;
      }
    },
    [columnId, row._valuesCache, storeAsNumber]
  );

  const handleChange = useCallback(
    (newValue: typeof value) => {
      onChange?.(newValue);
      setValue(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    saveInputValueToRowCache(value);
  }, [value, saveInputValueToRowCache]);

  useEffect(() => {
    if (typeof overrideValue !== "undefined") {
      setValue(overrideValue);
      if (overrideTriggersChange) {
        onChange?.(overrideValue);
      }
    }
  }, [onChange, overrideTriggersChange, overrideValue]);

  return (
    <NumberInput
      {...rest}
      aria-label={label}
      error={errorText}
      styles={{ input: { textAlign: alignRight ? "right" : "left" } }}
      value={value}
      onChange={handleChange}
    />
  );
}

export { ControlledMantineEditNumberInput };
