import { Button } from "../../../Buttons/Button/Button";

interface DisplayAllToggleButtonProps {
  showAll: boolean;
  onChange: () => void;
}

function DisplayAllToggleButton({
  showAll,
  onChange
}: DisplayAllToggleButtonProps) {
  const text = showAll ? "Profile des Projekts zeigen" : "Alle Profile zeigen";
  return (
    <Button color="secondary" onClick={onChange}>
      {text}
    </Button>
  );
}

export { DisplayAllToggleButton };
