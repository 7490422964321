import { noop } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useMachineAccounts } from "../../../../hooks/useMachineAccounts";
import { useParameterSetCollection } from "../../../../hooks/useParameterSetCollection";
import type { MachineAccountConfiguration } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Input } from "../../../DynamicForm/FormItems/FormField/Input/Input";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";

interface EditMachineAccountModalProps {
  variantId: number;
  machineAccount: MachineAccountConfiguration | null;
  onClose: () => void;
}

function EditMachineAccountModal({
  variantId,
  machineAccount,
  onClose
}: EditMachineAccountModalProps) {
  const [selectedParameterSetId, setSelectedParameterSetId] = useState<
    string | null
  >(null);

  const { data, isLoading, error } = useParameterSetCollection();

  const {
    createMachineAccountConfiguration,
    updateMachineAccountConfiguration
  } = useMachineAccounts(variantId);

  const editMode = !!machineAccount && !!machineAccount.id;

  const parameterSets: Choice[] = useMemo(
    () =>
      (data || []).map(({ id, name }) => ({ displayName: name, value: id })),
    [data]
  );

  const onParameterSetChange = useCallback(
    (_, value: string) => setSelectedParameterSetId(value),
    []
  );

  const onSubmit = useCallback(() => {
    if (!selectedParameterSetId) {
      return;
    }

    try {
      if (machineAccount?.id) {
        updateMachineAccountConfiguration(
          machineAccount.id,
          selectedParameterSetId
        );
      } else {
        createMachineAccountConfiguration(selectedParameterSetId);
      }
    } catch (e) {
      openErrorAlertPopup(e);
    }

    onClose();
  }, [
    createMachineAccountConfiguration,
    machineAccount?.id,
    onClose,
    selectedParameterSetId,
    updateMachineAccountConfiguration
  ]);

  return (
    <Modal className="new-machine-account-modal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {editMode
          ? "Machine Account bearbeiten"
          : "Neuen Machine Account hinzufügen"}
      </ModalHeader>
      <ModalBody>
        {editMode && (
          <div>
            <p>E-Mail</p>
            <Input
              disabled
              type="text"
              value={machineAccount?.email || ""}
              onChange={noop}
            />
          </div>
        )}
        <p>Dateiformat auswählen</p>
        <LoadOrError error={error} loading={isLoading}>
          <TsDropdown
            choices={parameterSets}
            id="meter-number-select-id"
            name="meter-number-select-class"
            placeholder="Bitten wählen…"
            required
            onChange={onParameterSetChange}
            {...(editMode ? { defaultValue: machineAccount.parameterSet } : {})}
          />
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button
            className="button-spacing"
            color="secondary"
            onClick={onClose}
          >
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={!selectedParameterSetId}
            onClick={onSubmit}
          >
            Speichern
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { EditMachineAccountModal };
