import { List } from "@mantine/core";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function ExactlyOneOperatorErrors({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const exactlyOneOperatorErrors = paragraph6CreditErrors.some(
    (error) => error.exactly_one_grid_operator_check_failed
  );

  if (!exactlyOneOperatorErrors) {
    return null;
  }

  return (
    <List.Item>
      Vertrag muss mindestens einen Erzeuger enthalten und alle Erzeuger
      denselben Anschlussnetzbetreiber haben
    </List.Item>
  );
}

export { ExactlyOneOperatorErrors };
