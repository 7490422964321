import type { ProjectMinimal } from "../../utils/backend-types";
import { Select } from "../BuildingBlocks/Forms/Select/Select";

interface ProjectOption {
  value: string | undefined;
  label: string;
}

type AllProjects = {
  id: undefined;
  name: string;
};

interface ProjectSelectionProps {
  projectId?: string;
  projects: Array<ProjectMinimal | AllProjects>;
  onChange: (changedProjectId?: string) => void;
}

function ProjectSelection({
  projectId,
  projects,
  onChange
}: ProjectSelectionProps) {
  function handleChange(newProject: ProjectOption) {
    onChange(newProject.value);
  }

  const projectChoices: Array<ProjectOption> = projects
    ? projects.map((project) => ({
        value: project.id,
        label: project.name
      }))
    : [];
  const project: ProjectMinimal | AllProjects | undefined = projects?.find(
    (project) => project.id === projectId
  );

  return projects.filter((project) => project.id !== undefined).length > 1 ? (
    <Select
      options={projects && projects.length > 1 ? projectChoices : []}
      placeholder="Projektauswahl"
      value={{ value: project?.id, label: project?.name }}
      onChange={handleChange}
    />
  ) : null;
}

export { ProjectSelection, ProjectSelectionProps };
