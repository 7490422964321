import { PulseLoader } from "react-spinners";
import { THEME_VARS } from "../../../utils/constants";
import "./AnimatedLoadingIcon.scss";

interface AnimatedLoadingIconProps {
  color?: string;
}

function AnimatedLoadingIcon({ color }: AnimatedLoadingIconProps) {
  return (
    <div className="AnimatedLoadingIcon">
      <PulseLoader
        aria-label="loading"
        color={color || THEME_VARS.brandColor}
        loading
      />
    </div>
  );
}

/** @deprecated HoCs are not really used in React anymore, AFAIK. */
function withAnimatedLoadingIcon(WrappedComponent, color = undefined) {
  const AnimatedLoadingIconWrapper = ({ requiredProps, ...otherProps }) => {
    const waiting = !!requiredProps.find(
      (prop) =>
        otherProps[prop] === null || typeof otherProps[prop] === "undefined"
    );

    if (waiting) {
      return <AnimatedLoadingIcon color={color} />;
    }

    return <WrappedComponent {...otherProps} />;
  };

  return AnimatedLoadingIconWrapper;
}

export { AnimatedLoadingIcon, withAnimatedLoadingIcon };
