import { IconName } from "../BuildingBlocks/Icon/types";
import { IconButton } from "../Buttons/IconButton/IconButton";
import "./NoContracts.scss";

interface NoContractsProps {
  buttonText?: string;
  onClickAddContract: () => void;
}

function NoContracts({
  buttonText = "Vertrag hinzufügen",
  onClickAddContract
}: NoContractsProps) {
  return (
    <div className="NoContracts">
      <h3 className="empty-page-header">Legen Sie jetzt los!</h3>
      <p className="empty-page-bodytext">
        Es gibt noch keinen Vertrag in Ihrer Liste. Klicken Sie unten auf den
        Button und erstellen Sie Ihren ersten Vertrag.
      </p>
      <IconButton
        color="brand"
        iconName={IconName.Plus}
        onClick={onClickAddContract}
      >
        {buttonText}
      </IconButton>
    </div>
  );
}

export { NoContracts, NoContractsProps };
