import { useEffect, useState } from "react";
import type { Control, FieldErrors } from "react-hook-form";
import urls from "../../../../../../../urls";
import type {
  Delivery,
  GridFees
} from "../../../../../../../utils/backend-types";
import { AlertColor } from "../../../../../../Alert/Alert";
import { AnchorLink } from "../../../../../../AnchorLink/AnchorLink";
import type {
  FormFieldData,
  FormInputData
} from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { IconAlert } from "../../../../../../BuildingBlocks/IconAlert/IconAlert";
import { SectionHeader } from "../../../../../../DynamicForm/FormItems/SectionHeader/SectionHeader";
import { ChangeablePricesField } from "../../../../SharedFields/ChangeablePricesField";
import type { GridUsageBillingModel } from "../../../hooks/useGridFees";
import { useGridFees } from "../../../hooks/useGridFees";
import { DELIVERY_FORM_HELP_TEXTS } from "../../DeliveryForm.constants";
import "./GridUsageFieldsWithHeader.scss";

interface GridUsageFieldsWithHeaderProps {
  control: Control<Delivery>;
  errors: FieldErrors<Delivery>;
  formFieldData: FormFieldData<Delivery>;
  automaticallyDetermineGridFeesValue: boolean;
  setAutomaticallyDetermineGridFeesValue: (value: boolean) => void;
  gridUsageBillingModelValue: string;
  siteId?: number | null;
}

function GridUsageFieldsWithHeader({
  control,
  errors,
  formFieldData,
  gridUsageBillingModelValue,
  automaticallyDetermineGridFeesValue,
  setAutomaticallyDetermineGridFeesValue,
  siteId
}: GridUsageFieldsWithHeaderProps) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [previewGridFees, setPreviewGridFees] = useState<GridFees | null>(null);

  const { gridFeesLoadingError, gridFees } = useGridFees(
    siteId,
    gridUsageBillingModelValue as GridUsageBillingModel,
    { enabled: automaticallyDetermineGridFeesValue }
  );

  useEffect(() => {
    if (automaticallyDetermineGridFeesValue && siteId) {
      if (gridFees) {
        setPreviewGridFees(gridFees);
      } else if (gridFeesLoadingError) {
        setPreviewGridFees(null);
        setShowErrorMessage(true);
        setAutomaticallyDetermineGridFeesValue(false);
      }
    }
  }, [
    automaticallyDetermineGridFeesValue,
    gridFees,
    gridFeesLoadingError,
    setAutomaticallyDetermineGridFeesValue,
    siteId
  ]);
  return (
    <div className="GridUsageFieldsWithHeader">
      <SectionHeader>Netznutzungsentgelte</SectionHeader>
      <FormFieldController
        control={control}
        data={
          formFieldData.gridUsageBillingModel as FormInputData<
            Delivery,
            "gridUsageBillingModel"
          >
        }
        error={errors.gridUsageBillingModel}
      />

      {(gridUsageBillingModelValue === "slp" ||
        gridUsageBillingModelValue === "rlm") && (
        <div className="grid-usage-fields-with-header-grid-fees-checkbox">
          <FormFieldController
            control={control}
            data={
              formFieldData.automaticallyDetermineGridFees as FormInputData<
                Delivery,
                "automaticallyDetermineGridFees"
              >
            }
            error={errors.automaticallyDetermineGridFees}
          />
        </div>
      )}
      {showErrorMessage && (
        <IconAlert
          className="grid-usage-error-message-alert"
          color={AlertColor.Danger}
          withCloseButton
          onClose={() => setShowErrorMessage(false)}
        >
          <div>
            Für das aktuelle Jahr kann der Preis für Netznutzungsentgelte nicht
            automatisch ermittelt werden. Bitte kontaktieren Sie unseren{" "}
            <AnchorLink href={urls.optiSupport()}>Support</AnchorLink> oder
            geben Sie die Informationen manuell ein.
          </div>
        </IconAlert>
      )}

      {(gridUsageBillingModelValue === "slp" ||
        gridUsageBillingModelValue === "rlm") &&
        !automaticallyDetermineGridFeesValue && (
          <ChangeablePricesField
            className="WorkingPricesPrognosis"
            control={control}
            error={
              isFieldError(errors.workingPricesPrognosis)
                ? errors.workingPricesPrognosis
                : undefined
            }
            helpText={DELIVERY_FORM_HELP_TEXTS.workingPricesPrognosis}
            label="Arbeitspreis Netznutzung (Prognose)*"
            name="workingPricesPrognosis"
            unit="ct/kWh"
          />
        )}

      {gridUsageBillingModelValue === "rlm" &&
        !automaticallyDetermineGridFeesValue && (
          <ChangeablePricesField
            className="GridUsagePowerPrices"
            control={control}
            error={
              isFieldError(errors.gridUsagePowerPrices)
                ? errors.gridUsagePowerPrices
                : undefined
            }
            helpText={DELIVERY_FORM_HELP_TEXTS.gridUsagePowerPrices}
            label="Leistungspreis Netznutzung [€/kW]*"
            name="gridUsagePowerPrices"
            unit="€/kW"
          />
        )}

      {automaticallyDetermineGridFeesValue &&
        previewGridFees &&
        previewGridFees.energyPrice && (
          <ChangeablePricesField
            allowInput={false}
            className="WorkingPricesPrognosis"
            collapsible={false}
            control={control}
            error={
              isFieldError(errors.workingPricesPrognosis)
                ? errors.workingPricesPrognosis
                : undefined
            }
            helpText={DELIVERY_FORM_HELP_TEXTS.workingPricesPrognosis}
            label="Arbeitspreis Netznutzung (Prognose)*"
            name="workingPricesPrognosis"
            previewValue={previewGridFees.energyPrice}
            unit="ct/kWh"
          />
        )}

      {automaticallyDetermineGridFeesValue &&
        previewGridFees &&
        previewGridFees.powerPrice && (
          <ChangeablePricesField
            allowInput={false}
            className="GridUsagePowerPrices"
            collapsible={false}
            control={control}
            error={
              isFieldError(errors.gridUsagePowerPrices)
                ? errors.gridUsagePowerPrices
                : undefined
            }
            helpText={DELIVERY_FORM_HELP_TEXTS.gridUsagePowerPrices}
            label="Leistungspreis Netznutzung [€/kW]*"
            name="gridUsagePowerPrices"
            previewValue={previewGridFees.powerPrice}
            unit="€/kW"
          />
        )}

      <FormFieldController
        control={control}
        data={
          formFieldData.lossesTransfer as FormInputData<
            Delivery,
            "lossesTransfer"
          >
        }
        error={errors.lossesTransfer}
      />
    </div>
  );
}

export { GridUsageFieldsWithHeader };
