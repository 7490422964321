export enum Frequency {
  TenMinutes = "10min",
  QuarterHour = "15min",
  Hour = "hour",
  Day = "day",
  Month = "month",
  Year = "year"
}

export enum Unit {
  Watt = "W",
  WattHour = "Wh",
  Kilowatt = "kW",
  KilowattHour = "kWh",
  Megawatt = "MW",
  MegawattHour = "MWh",
  CubicMeter = "m³"
}

export enum MeasurementType {
  Single = "lastgang",
  Cumulative = "zählerstandsgang",
  Arbitrary = "beliebig_zählerstandsgang"
}

export enum Medium {
  Electricity = "electricity",
  Gas = "gas",
  Heat = "heat"
}

export enum Product {
  Analyzer = "analyzer",
  Manager = "manager"
}

/** Duplicated from OBJECT_NAMES in common.js. This enum should be preferred in typescript files. */
export enum ObjectName {
  Generator = "Erzeuger",
  Consumer = "Verbraucher",
  Storage = "Batteriespeicher",
  Person = "Unternehmen",
  Connection = "Netzverknüpfungspunkt",
  GasConnection = "Gas-Netzanschlusspunkt",
  Meter = "Zähler",
  GhostNode = "Verteilungspunkt",
  MarketLocation = "Marktlokation",
  MeteringLocation = "Messlokation",
  Weighting = "Erzeugungsanteile"
}

export enum ObjectUrlName {
  Generators = "erzeuger",
  Consumers = "verbraucher",
  Storages = "batteriespeicher",
  Persons = "unternehmen",
  Connections = "netzverknuepfungspunkte",
  Meters = "zaehler",
  GhostNodes = "verteilungspuenkte",
  MarketLocations = "market-locations",
  MeteringLocations = "metering-locations",
  GasConnections = "gas-netzanschlusspunkt",
  Weightings = "gewichtung"
}

export const OBJECT_URL_NAMES_TO_OBJECT_NAMES: Record<
  ObjectUrlName,
  ObjectName
> = {
  [ObjectUrlName.Generators]: ObjectName.Generator,
  [ObjectUrlName.Consumers]: ObjectName.Consumer,
  [ObjectUrlName.Storages]: ObjectName.Storage,
  [ObjectUrlName.Persons]: ObjectName.Person,
  [ObjectUrlName.Connections]: ObjectName.Connection,
  [ObjectUrlName.GasConnections]: ObjectName.GasConnection,
  [ObjectUrlName.Meters]: ObjectName.Meter,
  [ObjectUrlName.GhostNodes]: ObjectName.GhostNode,
  [ObjectUrlName.MarketLocations]: ObjectName.MarketLocation,
  [ObjectUrlName.MeteringLocations]: ObjectName.MeteringLocation,
  [ObjectUrlName.Weightings]: ObjectName.Weighting
};

export const OBJECT_NAMES_TO_OBJECT_URL_NAMES = Object.keys(
  OBJECT_URL_NAMES_TO_OBJECT_NAMES
).reduce((dict, objectUrlName) => {
  dict[OBJECT_URL_NAMES_TO_OBJECT_NAMES[objectUrlName]] = objectUrlName;
  return dict;
}, {});

export const StructureDiagramObjectName = {
  ...ObjectName,
  Edge: "Verbindung"
};

export enum PhysicalSize {
  Energy = "Energie",
  Power = "Leistung",
  Volume = "Gasvolumen"
}

export enum SiteName {
  Optinode = "opti.node",
  Smarendo = "Smarendo",
  Alva = "Alva",
  Wavelr = "Wavelr"
}

export enum LoadprofileType {
  Generation = "generation",
  Consumption = "consumption"
}

export enum UserLicense {
  Analyzer = "analyzer",
  Manager = "manager"
}

export enum DataImportRequestType {
  METERING = "METERING_DATA",
  REDISPATCH = "REDISPATCH_DATA"
}

export enum MspCategory {
  LiefEdi = "lief-edi",
  EsaEdi = "esa-edi",
  EsaReq = "esa-req",
  CSV = "csv",
  Unknown = "unknown",
  Reluctant = "reluctant"
}

export enum MspImportFrequency {
  Yearly = "yearly",
  Monthly = "monthly",
  Daily = "daily",
  Unknown = "unknown"
}

export enum SubMeteringSystems {
  WIS = "wis_softenergy",
  QIVALO = "qivalo",
  ROTORSOFT = "rotorsoft_drehpunkt",
  WATTLINE = "wattline",
  BYTEMEE = "weo_bytemee",
  GREENBYTE = "breeze_greenbyte",
  SIEMENS = "navigator_siemens",
  WONDER = "wonder_windguard"
}

export enum SupplyType {
  PARTIAL_SUPPLY = "partial",
  FULL_SUPPLY = "full"
}
