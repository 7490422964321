import classNames from "classnames";
import { forwardRef, useId } from "react";
import type { TooltipPlaces } from "../../Tooltip/Tooltip";
import { Tooltip, anchorTooltip } from "../../Tooltip/Tooltip";
import "./Icon.scss";
import type { IconName } from "./types";
import { IconComponentType } from "./types";
import { getFlatIconClassNameByIconName } from "./utils/getFlatIconClassNameByIconName";
import { getIconComponentTypeByIconName } from "./utils/getIconComponentTypeByIconName";
import { getIconImgSrcByIconName } from "./utils/getIconImgSrcByIconName";
import { getLaIconClassNameByIconName } from "./utils/getLaIconClassNameByIconName";
import { getOptiFontClassNameByIconName } from "./utils/getOptiFontClassNameByIconName";

interface IconProps extends IconComponentProps {
  tooltipClassName?: string;
  tooltipText?: string | React.ReactNode;
  tooltipPlace?: TooltipPlaces;
}

function Icon(
  {
    tooltipClassName,
    tooltipPlace,
    tooltipText,
    ...iconComponentProps
  }: IconProps,
  ref: React.Ref<HTMLElement>
) {
  const tooltipId = useId();
  const showTooltip = !!tooltipText;

  if (!showTooltip) {
    return <ForwardedRefIconComponent {...iconComponentProps} ref={ref} />;
  }

  if (typeof tooltipText === "string") {
    return (
      <span
        {...anchorTooltip({
          content: tooltipText,
          place: tooltipPlace || "left"
        })}
        ref={ref}
      >
        <ForwardedRefIconComponent {...iconComponentProps} ref={ref} />
      </span>
    );
  }

  return (
    <>
      <span
        {...anchorTooltip({
          id: tooltipId,
          place: tooltipPlace || "left"
        })}
        ref={ref}
      >
        <ForwardedRefIconComponent {...iconComponentProps} ref={ref} />
      </span>
      <Tooltip id={tooltipId} render={() => tooltipText} />
    </>
  );
}

interface IconComponentProps {
  className?: string;
  id?: string;
  name: IconName;
  pointer?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  "data-testid"?: string;
}

function IconComponent(
  {
    className,
    id,
    name,
    pointer,
    style,
    onClick,
    "data-testid": dataTestId
  }: IconComponentProps,
  ref: React.Ref<HTMLElement>
) {
  const componentType = getIconComponentTypeByIconName(name);

  switch (componentType) {
    case IconComponentType.LaIcon: {
      const iconClassName = getLaIconClassNameByIconName(name);

      if (!iconClassName) {
        console.error(`LaIcon class name not found for name ${name}`);
        return null;
      }

      return (
        <i
          className={classNames("Icon", "la", iconClassName, className, {
            pointer
          })}
          data-testid={dataTestId}
          id={id}
          ref={ref}
          style={style}
          onClick={onClick}
        />
      );
    }
    case IconComponentType.FlatIcon: {
      const iconClassName = getFlatIconClassNameByIconName(name);

      if (!iconClassName) {
        console.error(`FlatIcon class name not found for name ${name}`);
        return null;
      }

      return (
        <i
          className={classNames("Icon", className, iconClassName, {
            pointer
          })}
          data-testid={dataTestId}
          id={id}
          ref={ref}
          style={style}
          onClick={onClick}
        />
      );
    }
    case IconComponentType.ImgSrc: {
      const imgSrc = getIconImgSrcByIconName(name);

      if (!imgSrc) {
        console.error(`No icon in /icons/ found with name ${name}`);
        return null;
      }

      return (
        <i ref={ref}>
          <img
            className={classNames(className, {
              pointer
            })}
            data-testid={dataTestId}
            id={id}
            src={imgSrc}
            style={style || { width: "20px" }}
            onClick={onClick}
          />
        </i>
      );
    }
    case IconComponentType.OptiIcon: {
      const iconClassName = getOptiFontClassNameByIconName(name);

      if (!iconClassName) {
        console.error(`OptiIcon class name not found for name ${name}`);
        return null;
      }

      return (
        <i
          className={classNames("Icon", className, iconClassName, {
            pointer
          })}
          data-testid={dataTestId}
          id={id}
          ref={ref}
          style={style}
          onClick={onClick}
        />
      );
    }
    default:
      console.error("Icon component type not found");
      return null;
  }
}

// these forwarded refs are needed by Mantine Popover
const ForwardedRefIconComponent = forwardRef(IconComponent);
const ForwardedRefIcon = forwardRef(Icon);

export { ForwardedRefIcon as Icon, IconProps };
