import { generatePath } from "react-router-dom";
import { useContainerRef } from "../../../hooks/useContainerRef";
import { usePersons } from "../../../hooks/usePersons";
import urls from "../../../urls";
import { type Todo, type User } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { CATEGORIES, type Mode } from "../common";
import { TodoForm } from "../TodoForm/TodoForm";
import "./EditTodoModal.scss";

interface EditTodoModalProps {
  isOpen: boolean;
  onToggle: () => void;
  todo: Todo;
  users: Array<User>;
  variantId: number;
  projectId: string;
  mode: Mode;
  basePath: string;
}

function EditTodoModal({
  isOpen,
  onToggle,
  todo,
  users,
  variantId,
  projectId,
  mode,
  basePath
}: EditTodoModalProps) {
  const {
    persons,
    isLoading: personsLoading,
    error: personsError
  } = usePersons(variantId);

  const { containerNode: footerNode, containerRef: footerRef } =
    useContainerRef();

  function handleSubmit(data: Todo) {
    document.location.href = `${generatePath(basePath, {
      projectId: projectId
    })}/${data.id}/`;
  }

  const patchUrl = urls.api.todosDetail(todo.id);
  const disableLabelEdit =
    todo.category === CATEGORIES.ONBOARDING.value && todo.systemCreated;

  return (
    <Modal
      className="EditTodoModal"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>Aufgabe bearbeiten</ModalHeader>
      <ModalBody scrollable>
        <LoadOrError error={personsError} loading={personsLoading}>
          <TodoForm
            buttonContainer={footerNode}
            disableLabelEdit={disableLabelEdit}
            initialValues={todo}
            mode={mode}
            patchUrl={patchUrl}
            persons={persons}
            users={users}
            variantId={variantId}
            onCancel={onToggle}
            onSubmit={handleSubmit}
          />
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={footerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { EditTodoModal };
