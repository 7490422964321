import { InputError, TextInput } from "@mantine/core";
import type { AddressInfo } from "../../../../components/SiteSetupAssistant/SiteSetupAssistant.types";

export interface MantineAddressInfoCellProps {
  onChange: (value: AddressInfo) => void;
  initialValue: AddressInfo;
  allowInput?: boolean;
  errorText?: string;
}

function MantineAddressInfoCell({
  onChange,
  initialValue,
  allowInput,
  errorText
}: MantineAddressInfoCellProps) {
  return (
    <div className="MantineAddressInfoCell">
      <TextInput
        aria-label="Adresse"
        disabled={!allowInput}
        placeholder="Adresse"
        type={"text"}
        value={initialValue?.address ?? ""}
        onChange={(event) => {
          onChange({ ...initialValue, address: event.target.value });
        }}
      />
      <TextInput
        aria-label="Postleitzahl"
        disabled={!allowInput}
        placeholder="Postleitzahl"
        type={"text"}
        value={initialValue?.zip ?? ""}
        onChange={(event) => {
          onChange({ ...initialValue, zip: event.target.value });
        }}
      />
      <TextInput
        aria-label="Stadt"
        disabled={!allowInput}
        placeholder="Stadt"
        type={"text"}
        value={initialValue?.city ?? ""}
        onChange={(event) => {
          onChange({ ...initialValue, city: event.target.value });
        }}
      />
      <InputError>{errorText}</InputError>
    </div>
  );
}

export { MantineAddressInfoCell };
