import { THEME_VARS } from "../../../../utils/constants";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";
import { Icon } from "../../Icon/Icon";
import { IconName } from "../../Icon/types";
import "./TitleWithStatusSymbol.scss";

interface TitleWithStatusSymbolProps {
  title: string;
  isLoading: boolean;
  isGreen: boolean;
  isRed: boolean;
}

function TitleWithStatusSymbol({
  title,
  isLoading,
  isGreen,
  isRed
}: TitleWithStatusSymbolProps) {
  let statusSymbolColour = "#FFF";

  if (isGreen) {
    statusSymbolColour = StatusSymbolColour.Green;
  } else if (isRed) {
    statusSymbolColour = StatusSymbolColour.Red;
  }

  return (
    <div className="TitleWithStatusSymbol">
      {isLoading ? (
        <Icon
          className="status-symbol-loader"
          name={IconName.SpinnerSpinning}
          style={{ color: THEME_VARS.brandColor }}
        />
      ) : (
        <StatusSymbol colour={statusSymbolColour} />
      )}
      {title}
    </div>
  );
}

export { TitleWithStatusSymbol, TitleWithStatusSymbolProps };
