import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link, generatePath, useResolvedPath } from "react-router-dom";
import { apiWithoutCamelization } from "../../../api";
import { useRouteSiteId } from "../../../hooks/useRouteSiteId";
import { useSiteVariantId } from "../../../hooks/useSiteVariantId";
import urls from "../../../urls";
import type { Site } from "../../../utils/backend-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { DownIcon } from "../icons/DownIcon";
import { SeparatorIcon } from "../icons/SeparatorIcon";
import { MenuItem } from "../MenuItem/MenuItem";
import { MenuLink } from "../MenuLink/MenuLink";
import { PageTitle } from "../PageTitle/PageTitle";
import "./SiteBreadcrumbs.scss";
import { getSiteTitle } from "./utils/getSiteTitle";

function SiteBreadcrumbs() {
  const currentSiteId = useRouteSiteId();
  const { variantId } = useSiteVariantId(currentSiteId, !currentSiteId);
  const { data: sites } = useQuery({
    queryKey: ["sites-list", { variantId }],
    queryFn: () => fetchSitesList(variantId),
    enabled: !!variantId,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchSitesList(variantId: number | undefined) {
    if (!variantId) {
      return undefined;
    }

    const response = await apiWithoutCamelization.get<Array<Site>>(
      urls.apiWithoutCamelization.sitesList(variantId)
    );

    return response.data;
  }

  if (!sites) {
    return null;
  }

  const currentSite = sites?.find((site) => site.id === currentSiteId);
  const pageTitle = currentSite
    ? getSiteTitle(currentSite)
    : "Unbekannte Liegenschaft";

  return (
    <>
      <SiteListBreadcrumbs />
      <PageTitle title={pageTitle} />
      {sites.length > 1 && (
        <MenuItem className="menu-item-variant-sub-menu">
          <SiteSubMenu currentSiteId={currentSiteId} sites={sites} />
        </MenuItem>
      )}
    </>
  );
}

function SiteListBreadcrumbs() {
  return (
    <>
      <MenuItem rel>
        <MenuLink text="Liegenschaften" to="../" />
      </MenuItem>
      <SeparatorIcon />
    </>
  );
}

interface SiteSubMenuProps {
  sites: Array<Site>;
  currentSiteId?: number;
}

function SiteSubMenu({ sites, currentSiteId }: SiteSubMenuProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const resolved = useResolvedPath("../:siteId");
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      className="SiteSubMenu"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle aria-expanded={dropdownOpen} tag="span">
        <DownIcon />
      </DropdownToggle>
      <DropdownMenu>
        {sites.map((site) => {
          if (site.id !== currentSiteId) {
            const siteUrl = generatePath(resolved.pathname, {
              siteId: site.id
            });

            return (
              <DropdownItem key={site.id} tag={Link} to={siteUrl}>
                {getSiteTitle(site)}
              </DropdownItem>
            );
          } else {
            return null;
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export { SiteBreadcrumbs };
