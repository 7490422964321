import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Input, Label } from "reactstrap";

import api from "../../api";
import { useCurrentUserQuery } from "../../hooks/useCurrentUserQuery";
import urls from "../../urls";
import type { ExtendedUser } from "../../utils/backend-types";
import { Alert, AlertColor } from "../Alert/Alert";
import { DeleteConfirmationModal } from "../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../Buttons/Button/Button";
import { DeleteIcon } from "../Buttons/DeleteIcon";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { shareProjectToEmails } from "./ShareProjects";

import "./ProjectShareWidget.scss";

interface ProjectShareWidgetProps {
  projectId: string;
  projectManagers: Array<ExtendedUser>;
  onDone: () => void;
  onError: (error: unknown) => void;
  onShare: (newManager: ExtendedUser) => void;
}

function ProjectShareWidget({
  projectId,
  projectManagers,
  onDone,
  onError,
  onShare
}: ProjectShareWidgetProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailInput(e.target.value);
  }

  function handleClickSave() {
    setIsLoading(true);

    shareProjectToEmails(projectId, emailInput, onError).then((managers) => {
      managers.forEach((manager) => {
        if (manager) {
          onShare(manager);
        }
      });

      setEmailInput("");
      setIsLoading(false);

      if (onDone) {
        onDone();
      }
    });
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleClickSave();
    }
  }

  return (
    <div className="ProjectShareWidget">
      <div className="content">
        <p>
          Um das Projekt mit anderen Nutzern zu teilen, geben Sie bitte deren
          E-Mail-Adresse ein.
        </p>
        <Label for="share-email">Email*</Label>
        <Input
          id="share-email"
          type="text"
          value={emailInput}
          onChange={handleChangeEmail}
          onKeyUp={handleKeyUp}
        />
        <p />
        <Alert color={AlertColor.Danger} variant="outline">
          ACHTUNG: Der Nutzer erhält vollen Zugriff auf das Projekt
        </Alert>
      </div>
      <div className="buttons">
        <SpinButton
          className="submit-button"
          color="primary"
          disabled={!emailInput || emailInput.length === 0}
          spin={isLoading}
          style={{ marginRight: "1rem" }}
          type="submit"
          onClick={handleClickSave}
        >
          Teilen
        </SpinButton>
        {onDone && <Button onClick={onDone}>Abbrechen</Button>}
      </div>
      <ProjectManagers
        managers={projectManagers}
        projectId={projectId}
        onDone={onDone}
      />
    </div>
  );
}

interface ProjectManagersProps {
  managers: Array<ExtendedUser>;
  projectId: string;
  onDone: () => void;
}

function ProjectManagers({
  managers,
  projectId,
  onDone
}: ProjectManagersProps) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [userIdToRevoke, setUserIdToRevoke] = useState<number | undefined>(
    undefined
  );

  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUserQuery();
  const isStaff = currentUser ? currentUser.is_staff : false;

  function isCurrentUserStaff(manager: ExtendedUser) {
    return isStaff && currentUser?.id === manager.id;
  }

  async function handleRemoveUserShare() {
    setIsDeleteLoading(true);
    if (userIdToRevoke) {
      try {
        await api.post(urls.api.projectsRevoke(projectId), {
          id: userIdToRevoke
        });
      } catch (error) {
        openErrorAlertPopup({ error });
      }
    }
    queryClient.invalidateQueries({
      queryKey: ["project", { projectId }]
    });
    setUserIdToRevoke(undefined);
    setIsDeleteLoading(false);
    setDeleteModalOpen(false);

    if (onDone) {
      onDone();
    }
  }

  return (
    <>
      <div className="m-separator--space m-separator m-separator--dashed" />
      <h5>Benutzer mit Zugriff</h5>
      <table
        className="table table-sm table-responsive"
        id="shared-users-table"
      >
        <tbody>
          {managers.map((manager) => (
            <tr key={manager.name}>
              <td>
                <img
                  alt={manager.name}
                  className="img-thumbnail rounded-circle"
                  src={manager.image}
                  style={{ height: "40px" }}
                />
              </td>
              <td>{manager.name}</td>
              <td>
                {!isCurrentUserStaff(manager) && (
                  <DeleteIcon
                    tooltipText="Projektzugriff für diesen Nutzer entfernen"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      setUserIdToRevoke(manager.id);
                      setDeleteModalOpen(true);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteConfirmationModal
        isLoading={isDeleteLoading}
        isModalOpen={deleteModalOpen}
        modalBodyElement={
          <p>
            Sind Sie sicher, dass Sie{" "}
            <strong>den Projektzugriff für diesen Nutzer</strong> löschen
            möchten?
          </p>
        }
        name="Projektzugriff"
        toggleModal={() => {
          setDeleteModalOpen(!deleteModalOpen);
        }}
        onClickConfirm={() => handleRemoveUserShare()}
      />
    </>
  );
}

export { ProjectShareWidget };
