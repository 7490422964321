import { DateInput, type DateInputProps, type DateValue } from "@mantine/dates";
import classNames from "classnames";
import { DateTime } from "luxon";
import { forwardRef, useId } from "react";
import { backendDateOrDateTimeToLuxonDateTimeOrNull } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTimeOrNull";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { parseManualDateInput } from "../../../../../utils/parseManualDateInput";
import { InputWrapper } from "../../utils/InputWrapper";
import "./DateInputField.scss";

interface DateInputFieldProps
  extends Pick<
    DateInputProps,
    "aria-label" | "disabled" | "id" | "placeholder" | "onKeyDown"
  > {
  className?: string;
  errorText?: string;
  invalid?: boolean;
  useCompactErrorMessage?: boolean;
  value: string | null;
  warned?: boolean;
  onChange: (date: string | null) => void;
}

function DateInputField(
  {
    "aria-label": ariaLabel,
    className,
    disabled,
    errorText,
    id,
    invalid,
    placeholder,
    useCompactErrorMessage,
    value,
    warned,
    onChange,
    onKeyDown
  }: DateInputFieldProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const backupId = useId();
  const datePickerId = id || backupId;

  const parsedValue: DateValue =
    backendDateOrDateTimeToLuxonDateTimeOrNull(value)?.toJSDate() || null;

  function handleChange(value: DateValue) {
    onChange(
      value
        ? luxonDateTimeToBackendDateOrDateTime(DateTime.fromJSDate(value))
        : null
    );
  }

  return (
    <div
      className={classNames("DateInputField", className, {
        "is-invalid": invalid,
        warning: !invalid && warned
      })}
    >
      <InputWrapper
        className="date-picker-input-wrapper"
        errorText={useCompactErrorMessage ? errorText : undefined}
      >
        <DateInput
          aria-label={ariaLabel}
          clearable
          dateParser={parseManualDateInput}
          disabled={disabled}
          error={useCompactErrorMessage ? undefined : errorText}
          id={datePickerId}
          placeholder={placeholder}
          ref={ref}
          value={parsedValue}
          valueFormat="DD.MM.YYYY"
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
      </InputWrapper>
    </div>
  );
}

const ForwardedRefDateInputField = forwardRef(DateInputField);

export { ForwardedRefDateInputField as DateInputField, DateInputFieldProps };
