import classNames from "classnames";
import type { RowProps as ReactstrapRowProps } from "reactstrap";
import { Row as ReactstrapRow } from "reactstrap";

interface RowProps extends ReactstrapRowProps {
  /** Metronic `margin-bottom` style from style.bundle.css */
  mb2?: boolean;
}

function Row({ mb2, className, ...props }: RowProps) {
  return (
    <ReactstrapRow
      className={classNames(className, { "mb-2": mb2 })}
      {...props}
    />
  );
}

export { Row, RowProps };
