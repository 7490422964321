import { Group, Paper, Title } from "@mantine/core";
import * as Sentry from "@sentry/browser";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useManagerVariant } from "../../hooks/useManagerVariant";
import { ROUTES } from "../../routes";
import { SiteSetupProcessDataStep } from "../../types/api.types";
import { showToast } from "../../utils/toast";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SiteSetupAssistantFormControls } from "./Forms/SiteSetupAssistantFormControls/SiteSetupAssistantFormControls";
import { useSiteSetupAssistant } from "./hooks/useSiteSetupAssistant";
import { useSiteSetupAssistantCreation } from "./hooks/useSiteSetupAssistantCreation";
import { useSiteSetupAssistantMutations } from "./hooks/useSiteSetupAssistantMutations";
import type {
  MeterTableErrors,
  SiteSetupProcessForForm,
  TenantTableErrors
} from "./SiteSetupAssistant.types";
import { SiteSetupAssistantNavigation } from "./SiteSetupAssistantNavigation/SiteSetupAssistantNavigation";
import { formatBackendDataToFrontend } from "./utils/formatBackendDataToFrontend";
import { formatBackendErrorsToFrontendErrors } from "./utils/formatBackendErrorsToFrontendErrors";
import { formatConsumerErrorsToMeterTableErrors } from "./utils/formatConsumerErrorsToMeterTableErrors";
import { formatConsumerErrorsToTenantTableErrors } from "./utils/formatConsumerErrorsToTenantTableErrors";
import { formatFrontendDataToBackend } from "./utils/formatFrontendDataToBackend";
import { getErrorMessageAndStep } from "./utils/getErrorMessageAndStep";
import { getFieldsForActiveStep } from "./utils/getFieldsForActiveStep";
import { getOrderFromStep } from "./utils/getOrderFromStep";
import { getTitleFromStep } from "./utils/getTitleFromStep";
import { setSiteSetupAssistantErrorsFromResponseData } from "./utils/setSiteSetupAssistantErrorsFromResponseData";
import "./SiteSetupAssistant.scss";

function SiteSetupAssistant() {
  const navigate = useNavigate();
  const { projectId, siteSetupProcessId } = useParams();
  const {
    variantId,
    isLoading: variantIdIsLoading,
    error: variantIdError
  } = useManagerVariant(projectId);
  const {
    data: siteSetupAssistantData,
    isLoading,
    error
  } = useSiteSetupAssistant(siteSetupProcessId);
  const [activeStep, setActiveStep] = useState<
    SiteSetupProcessDataStep | undefined
  >(undefined);
  const defaultStep = SiteSetupProcessDataStep.name_and_pv_plants;
  const [navigationBlocked, setNavigationBlocked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [tenantTableErrors, setTenantTableErrors] =
    useState<TenantTableErrors | null>(null);
  const [meterTableErrors, setMeterTableErrors] =
    useState<MeterTableErrors | null>(null);
  const [plausibilityErrors, setPlausibilityErrors] = useState<Array<string>>(
    []
  );

  const {
    control,
    formState,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors
  } = useForm<SiteSetupProcessForForm>({ mode: "onBlur" });

  useEffect(() => {
    if (siteSetupAssistantData) {
      // manual form initialization since the data is not available at the time of form creation
      const frontendData = formatBackendDataToFrontend(siteSetupAssistantData);
      if (frontendData) {
        Object.keys(frontendData).forEach((key) => {
          let newValue = frontendData[key];
          if (key === "siteHasBatteryStorage" && !newValue) {
            newValue = false;
          }
          if (key === "isBatteryStorageMeasuredSeparately" && !newValue) {
            newValue = false;
          }
          if (
            key === "connectionIsCalibrated" &&
            typeof newValue !== "boolean"
          ) {
            newValue = true;
          }

          setValue(key as keyof SiteSetupProcessForForm, newValue);
        });
        if (activeStep === undefined && frontendData.step) {
          setActiveStep(frontendData.step);
        }
      }
    }
    // need the following line to avoid triggering twice due to the form being set up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSetupAssistantData]);

  const { updateMutation: updateSiteSetupAssistant } =
    useSiteSetupAssistantMutations(siteSetupProcessId);

  const {
    creationMutation: createSite,
    validationMutation: validateSite,
    validatePlausibilityMutation: validatePlausibility
  } = useSiteSetupAssistantCreation(siteSetupProcessId);

  async function handleStep(stepTo: SiteSetupProcessDataStep) {
    try {
      await saveProgress();
      setActiveStep(stepTo);
    } catch (error) {
      showToast(
        "error",
        "Ein unerwarteter Fehler ist aufgetreten. Bitte überprüfen Sie die Daten auf Vollständigkeit."
      );
      Sentry.captureException(error);
    }
  }

  async function handleSaveProgress() {
    try {
      await saveProgress();
      showToast(
        "success",
        "Ihre Liegenschaftskonfiguration wurde gespeichert."
      );
      navigate(ROUTES.managerProjectList + projectId);
    } catch (error) {
      showToast(
        "error",
        "Ein unerwarteter Fehler ist aufgetreten. Bitte überprüfen Sie die Daten auf Vollständigkeit."
      );
      Sentry.captureException(error);
    }
  }

  async function saveProgress() {
    setIsSubmitting(true);
    //  Quick Info about the errors:
    //  There are plausibility errors and form errors.
    //  The form errors are just missing fields and are handled by the useForm hook.
    //  The plausibility errors are more complex errors that occur when existing data is not matching
    //  with other data. In that case there will be a banner displayed with a specific error message in that step.
    if (activeStep === SiteSetupProcessDataStep.tenants) {
      const newPlausibilityErrors = plausibilityErrors.filter(
        (error) =>
          getErrorMessageAndStep(error).step !==
          getOrderFromStep(SiteSetupProcessDataStep.tenants)
      );
      setPlausibilityErrors(newPlausibilityErrors);
      setTenantTableErrors(null);
    } else if (activeStep === SiteSetupProcessDataStep.meters) {
      const newPlausibilityErrors = plausibilityErrors.filter(
        (error) =>
          getErrorMessageAndStep(error).step !==
          getOrderFromStep(SiteSetupProcessDataStep.meters)
      );
      setPlausibilityErrors(newPlausibilityErrors);
      setMeterTableErrors(null);
    } else {
      const fieldsToClear = getFieldsForActiveStep(activeStep || defaultStep);
      fieldsToClear.forEach((field) => {
        clearErrors(field as keyof SiteSetupProcessForForm);
      });
    }

    try {
      await updateSiteSetupAssistant.mutateAsync(
        formatFrontendDataToBackend({
          ...getValues(),
          step: activeStep || defaultStep
        })
      );
    } catch (error) {
      showToast(
        "error",
        "Ihre Liegenschaftskonfiguration konnte nicht gespeichert werden."
      );
      return Promise.reject(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleCreateSite() {
    await saveProgress();
    setIsCreating(true);
    try {
      const [validateSiteResult, validatePlausibilityResult] =
        await Promise.allSettled([
          validateSite.mutateAsync(),
          validatePlausibility.mutateAsync()
        ]);

      const validateSiteSuccess =
        validateSiteResult.status === "fulfilled" &&
        validateSiteResult.value.status === 200;
      const validatePlausibilitySuccess =
        validatePlausibilityResult.status === "fulfilled" &&
        validatePlausibilityResult.value.status === 200;

      if (validateSiteSuccess && validatePlausibilitySuccess) {
        try {
          const response = await createSite.mutateAsync(
            formatFrontendDataToBackend({
              ...getValues()
            })
          );
          const siteId = response.data;
          if (!!siteId && !!projectId) {
            showToast("success", "Ihre Liegenschaft wurde angelegt.");
            navigate(
              generatePath(ROUTES.managerVariantStructure, {
                projectId,
                siteId
              })
            );
          }
        } catch (error) {
          showToast(
            "error",
            "Ihre Liegenschaftskonfiguration konnte nicht erstellt werden."
          );
          return Promise.reject(error);
        }
      } else {
        if (!validateSiteSuccess || !validatePlausibilitySuccess) {
          if (!validateSiteSuccess && validateSiteResult["reason"]) {
            handleValidationError(validateSiteResult["reason"]);
          }
          if (
            !validatePlausibilitySuccess &&
            validatePlausibilityResult["reason"]
          ) {
            setPlausibilityErrors(
              validatePlausibilityResult["reason"].response.data
            );
          }
          showToast(
            "error",
            "Einige Daten fehlen oder konnten nicht validiert werden. Bitte überprüfen Sie Ihre Eingaben."
          );
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      showToast("error", "Ein unerwarteter Fehler ist aufgetreten.");
      return Promise.reject(error);
    } finally {
      setIsCreating(false);
    }
  }

  function handleValidationError(error) {
    const newError = formatBackendErrorsToFrontendErrors(error.response.data);
    const consumerErrors = error.response.data?.site?.consumers;
    if (consumerErrors) {
      if (consumerErrors.some((consumer) => consumer.tenant)) {
        setTenantTableErrors(
          formatConsumerErrorsToTenantTableErrors(consumerErrors)
        );
      }
      if (consumerErrors.some((consumer) => consumer.meter)) {
        setMeterTableErrors(
          formatConsumerErrorsToMeterTableErrors(consumerErrors)
        );
      }
    }
    setSiteSetupAssistantErrorsFromResponseData<SiteSetupProcessForForm>(
      newError,
      watch(),
      setError,
      t("errors.UnknownError")
    );
  }

  return (
    <LoadOrError
      error={error || variantIdError}
      loading={isLoading || variantIdIsLoading}
    >
      {siteSetupAssistantData && variantId && (
        <Paper className="SiteSetupAssistant">
          <Title
            className="SiteSetupAssistantTitle"
            fw="500"
            mb="xl"
            mt="sm"
            order={3}
            ta="center"
          >
            {getTitleFromStep(activeStep || defaultStep)}
          </Title>
          <form className="site-setup-form">
            <SiteSetupAssistantNavigation
              formControl={control}
              formErrors={formState.errors}
              meterTableErrors={meterTableErrors}
              plausibilityErrors={plausibilityErrors}
              step={activeStep || defaultStep}
              tenantTableErrors={tenantTableErrors}
              watch={watch}
              onBlockNavigation={setNavigationBlocked}
              onChangeStep={(step) => handleStep(step)}
              onSetFormValue={setValue}
            />
          </form>
          <Group grow variant="paper-footer">
            <SiteSetupAssistantFormControls
              activeStep={activeStep || defaultStep}
              isCreating={isCreating}
              isSubmitting={isSubmitting}
              navigationBlocked={navigationBlocked}
              onCancel={() => navigate(ROUTES.managerProjectList + projectId)}
              onCreateSite={handleCreateSite}
              onSaveProgress={handleSaveProgress}
              onStep={handleStep}
            />
          </Group>
        </Paper>
      )}
    </LoadOrError>
  );
}

export { SiteSetupAssistant };
