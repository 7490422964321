import { DateTime } from "luxon";

function getTimeAgoString(date: DateTime) {
  const now = DateTime.now();
  const rtf = new Intl.RelativeTimeFormat("de-de");
  const diff = now.diff(date, ["years", "days", "hours", "minutes", "seconds"]);

  if (diff.years >= 1) {
    return rtf.format(-Math.floor(diff.years), "years");
  }
  if (diff.days >= 1) {
    return rtf.format(-Math.floor(diff.days), "days");
  }
  if (diff.hours >= 1) {
    return rtf.format(-Math.floor(diff.hours), "hours");
  }
  if (diff.minutes >= 1) {
    return rtf.format(-Math.floor(diff.minutes), "minutes");
  }
  return rtf.format(-Math.floor(diff.seconds), "seconds");
}

export { getTimeAgoString };
