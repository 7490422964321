import { type MRT_ColumnDef, type MRT_RowData } from "mantine-react-table";
import { MantineYesNoRadioGroup } from "../../../../../../mantine/mrt/components/cell/MantineYesNoRadioGroup";
import { ControlledMantineEditYesNoRadioGroup } from "../../../../../../mantine/mrt/components/edit/ControlledMantineEditYesNoRadioGroup";
import type { Meter } from "../../../../SiteSetupAssistant.types";

export function getIsCalibratedColumn<T extends MRT_RowData>(
  hasCellError: (
    rowIndex: number,
    accessorKey: keyof Meter
  ) => boolean | null | undefined
): MRT_ColumnDef<T> {
  return {
    accessorKey: "is_calibrated",
    header: "Zähler geeicht?",
    Edit: ({ row, cell, column }) => (
      <ControlledMantineEditYesNoRadioGroup
        allowInput
        cell={cell}
        columnId={column.id}
        errorText={undefined}
        row={row}
      />
    ),
    Cell: ({ row }) => (
      <MantineYesNoRadioGroup
        allowInput={false}
        checked={row.original.is_calibrated}
        errorText={
          hasCellError(row.index, "is_calibrated")
            ? "Bitte wählen Sie eine Option aus."
            : undefined
        }
      />
    )
  };
}
