import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";

type ArchiveConfirmationModalProps = {
  name: string;
  isModalOpen: boolean;
  isLoading: boolean;
  toggleModal: () => void;
  onSuccess: () => void;
  modalBodyElement?: React.ReactNode;
  headerText?: string;
  error?: string;
  isSuccessBtnDisabled?: boolean;
};

function ArchiveConfirmationModal({
  name,
  isLoading,
  isModalOpen,
  toggleModal,
  onSuccess,
  modalBodyElement,
  headerText,
  error,
  isSuccessBtnDisabled
}: ArchiveConfirmationModalProps) {
  return (
    <BasicConfirmationModal
      confirmationButtonColor="danger"
      confirmationButtonText="Stornieren"
      error={error}
      headerText={`${headerText ? headerText : name} stornieren`}
      isConfirmationBtnDisabled={isSuccessBtnDisabled}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      onCancel={toggleModal}
      onConfirm={onSuccess}
    >
      {modalBodyElement ? (
        modalBodyElement
      ) : (
        <div>
          <p>
            Sind Sie sicher, dass Sie <b>{name}</b> stornieren möchten?
          </p>
          <p>Dieser Schritt kann nicht rückgängig gemacht werden.</p>
        </div>
      )}
    </BasicConfirmationModal>
  );
}

export { ArchiveConfirmationModal, ArchiveConfirmationModalProps };
