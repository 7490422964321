import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../../Buttons/Button/Button";
import { NumberValueCell } from "../../../../../ChangeableFieldWidget/ChangeableFieldTable/Cells/NumberValueCell";
import { FormInputV2 } from "../../../../../CustomFormV2/FormInputV2/FormInputV2";
import { FormFieldType } from "../../../../../DynamicForm/FormItems/FormField/FormField";
import type { PaymentDate, UpdatePaymentsValue } from "../PaymentsTab";

interface PaymentsAndReasonModalProps {
  onCloseModal: () => void;
  updatePaymentsValue: UpdatePaymentsValue;
  activeDate: PaymentDate;
  value: number | null;
  reason: string | null;
  valueFieldName: string;
  reasonFieldName: string;
  headerText: string;
}

function PaymentsWithReasonModal({
  value: initialValue,
  reason: initialDescription,
  onCloseModal,
  updatePaymentsValue,
  activeDate,
  valueFieldName,
  reasonFieldName,
  headerText
}: PaymentsAndReasonModalProps) {
  const [value, setValue] = useState<number | null>(initialValue);
  const [description, setDescription] = useState<string | null>(
    initialDescription
  );
  const [reasonFieldErrors, setReasonFieldErrors] = useState<Array<string>>([]);
  const { t } = useTranslation();

  function handleClickSavePayments() {
    if (value && !description) {
      setReasonFieldErrors([t("errors.Required")]);
      return;
    }

    updatePaymentsValue(value, activeDate, valueFieldName);
    updatePaymentsValue(description, activeDate, reasonFieldName);
    onCloseModal();
  }

  return (
    <Modal isOpen>
      <ModalHeader>{headerText} bearbeiten</ModalHeader>
      <ModalBody>
        <Label>{headerText}</Label>
        <NumberValueCell
          decimalScale={2}
          value={value}
          onChange={(value) => setValue(value ?? null)}
        />
        <FormInputV2
          currentValue={description}
          fieldInfo={{
            required: true,
            label: "Begründung",
            type: FormFieldType.String,
            name: reasonFieldName
          }}
          requiredFieldErrors={reasonFieldErrors}
          updateFormValues={(key, value) => {
            setReasonFieldErrors([]);
            setDescription(value as string);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseModal}>Abbrechen</Button>
        <Button color="primary" onClick={handleClickSavePayments}>
          Speichern
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { PaymentsWithReasonModal };
