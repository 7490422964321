import { DateTime } from "luxon";
import type { Dispatch, SetStateAction } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { pollTaskStatus } from "../../../utils/api-utils";
import type { Site } from "../../../utils/backend-types";
import { SubMeteringSystems } from "../../../utils/enums";
import { showToast } from "../../../utils/toast";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type { SelectedMeter } from "../EditConfigurationModal/EditConfigurationModal";
import { useLoadSuggestedMeters } from "../hooks/useLoadSuggestedMeters";
import { useMeterParameters } from "../hooks/useMeterParameters";
import { useMetersBySiteIds } from "../hooks/useMetersBySiteIds";
import { getSuggestedMetersSelectionData } from "../utils/getSuggestedMetersSelectionData";
import { updateOrCreateMeterParameters } from "../utils/synchronizeParameters";
import "./MeterConfiguration.scss";
import { MeterConfigurationTable } from "./MeterConfigurationTable/MeterConfigurationTable";
import { MeterDataLoading } from "./MeterDataLoading/MeterDataLoading";
import { MeterSuggestionsLoading } from "./MeterDataLoading/MeterSuggestionsLoading/MeterSuggestionsLoading";

interface MeterConfigurationProps {
  systemName: SubMeteringSystems;
  configurationId: number;
  sites: Array<Site>;
  connectionLoading: boolean;
  selectedMeters: Array<SelectedMeter>;
  setSelectedMeters: Dispatch<SetStateAction<SelectedMeter[]>>;
  onSubMeteringConnectionCheck: ({
    withSuccessToast
  }: {
    withSuccessToast: boolean;
  }) => Promise<boolean | void>;
  onMetersSave: (singleEntityId?: number) => Promise<{ success: boolean }>;
}

function MeterConfiguration({
  systemName,
  configurationId,
  sites,
  connectionLoading,
  selectedMeters,
  setSelectedMeters,
  onSubMeteringConnectionCheck,
  onMetersSave
}: MeterConfigurationProps) {
  const { t } = useTranslation();
  const {
    meterParams,
    isMeterParamsLoading,
    isMeterParamsFetching,
    refetchMeterParams
  } = useMeterParameters(configurationId);
  const { metersBySiteId, isMetersQueryLoading } = useMetersBySiteIds(sites);

  const {
    refetch: refetchSuggestedMeters,
    isLoading: isSuggestionsLoading,
    isRefetching: isRefetchingSuggestions
  } = useLoadSuggestedMeters(configurationId);

  const meterParamsSorted = useMemo(() => {
    const selectedMetersMap = new Map(
      selectedMeters.map((selectedMeter) => [
        selectedMeter.subMeteringSystemEntityId,
        selectedMeter
      ])
    );

    //  Sorting priority: 1) meter params with assigned meter; 2) meter params with suggested meter; 3) by names
    return meterParams?.sort((a, b) => {
      const aIsSuggested =
        selectedMetersMap.has(a.id) && selectedMetersMap.get(a.id)?.isSuggested;
      const bIsSuggested =
        selectedMetersMap.has(b.id) && selectedMetersMap.get(b.id)?.isSuggested;

      if (a.meter !== null && b.meter === null) return -1;
      if (b.meter !== null && a.meter === null) return 1;

      if (aIsSuggested && !bIsSuggested) return -1;
      if (bIsSuggested && !aIsSuggested) return 1;

      let aName = "";
      let bName = "";

      if (
        systemName === SubMeteringSystems.WIS &&
        "parkName" in a.parameters &&
        "parkName" in b.parameters
      ) {
        aName = a.parameters.parkName;
        bName = b.parameters.parkName;
      }

      if (
        systemName === SubMeteringSystems.QIVALO &&
        "propertyName" in a.parameters &&
        "propertyName" in b.parameters
      ) {
        aName = a.parameters.propertyName;
        bName = b.parameters.propertyName;
      }

      if (
        (systemName === SubMeteringSystems.ROTORSOFT ||
          systemName === SubMeteringSystems.BYTEMEE) &&
        "windparkName" in a.parameters &&
        "windparkName" in b.parameters
      ) {
        aName = a.parameters.windparkName;
        bName = b.parameters.windparkName;
      }

      if (
        systemName === SubMeteringSystems.GREENBYTE &&
        "siteName" in a.parameters &&
        "siteName" in b.parameters
      ) {
        aName = a.parameters.siteName;
        bName = b.parameters.siteName;
      }

      if (
        (systemName === SubMeteringSystems.SIEMENS ||
          systemName === SubMeteringSystems.WONDER) &&
        "name" in a.parameters &&
        "name" in b.parameters
      ) {
        aName = a.parameters.name;
        bName = b.parameters.name;
      }

      if (
        systemName === SubMeteringSystems.WATTLINE &&
        "serialNumber" in a.parameters &&
        "serialNumber" in b.parameters
      ) {
        aName = a.parameters.serialNumber;
        bName = b.parameters.serialNumber;
      }

      return aName.localeCompare(bName);
    });
  }, [meterParams, selectedMeters, systemName]);

  // contains array of config ids for which meter parameters data is currently polling
  const [meterParamsPollingData, setMeterParamsPollingData] = useSessionStorage(
    "meter_params_polling_data",
    [] as Array<number>
  );
  const [meterParamsUpdateData, setMeterParamsUpdateData] = useLocalStorage(
    "meter_params_update_data",
    {} as Record<number, string>
  );

  const isPollingMeterParamsData = Boolean(
    meterParamsPollingData?.find(
      (pollingData) => pollingData === configurationId
    )
  );

  function removeConfigIdFromPollingData() {
    setMeterParamsPollingData((prevState) =>
      prevState.filter((itemId) => itemId !== configurationId)
    );
  }

  const lastUpdate = meterParamsUpdateData[configurationId];

  async function handleInvalidateMeterParams() {
    const { data: meterParamsData } = await refetchMeterParams();
    if (Array.isArray(meterParamsData) && meterParamsData.length > 0) {
      const currentDate = DateTime.now().toFormat("dd.MM.yyyy hh:mm");

      setMeterParamsUpdateData((prevState) => ({
        ...prevState,
        [configurationId]: currentDate
      }));

      showToast(
        "success",
        t("messages.ThirdPartySystems.MeterParametersSuccess")
      );
    } else {
      showToast("error", t("errors.ThirdPartySystems.InvalidMeterParameters"));
    }
  }

  async function retrieveData() {
    try {
      setMeterParamsPollingData((prevState) => [
        ...(prevState || []),
        configurationId
      ]);

      const isConnectionSuccessful = await onSubMeteringConnectionCheck({
        withSuccessToast: false
      });

      if (!isConnectionSuccessful) return;

      const updateOrCreateMeterParametersData =
        await updateOrCreateMeterParameters(configurationId);

      await new Promise((resolve, reject) => {
        pollTaskStatus(
          updateOrCreateMeterParametersData.taskStatusUrl,
          () => {
            removeConfigIdFromPollingData();
            resolve(handleInvalidateMeterParams());
          },
          () => {
            removeConfigIdFromPollingData();
            resolve(handleInvalidateMeterParams());
          },
          () => {
            reject(showToast("error", t("errors.ServerError")));
          },
          1000
        );
      });
    } catch (error) {
      showToast("error", error);
    } finally {
      removeConfigIdFromPollingData();
    }
  }

  async function loadSuggestedMeters() {
    const { data: metersSuggestions } = await refetchSuggestedMeters();

    if (metersBySiteId && meterParamsSorted && metersSuggestions) {
      setSelectedMeters((prevSelectedMeters) => {
        const filteredSuggestions = getSuggestedMetersSelectionData(
          systemName,
          metersSuggestions,
          meterParamsSorted,
          metersBySiteId
        ).filter(
          (suggestion) =>
            !prevSelectedMeters.some(
              (selectedMeter) => selectedMeter.meterId === suggestion.meterId
            )
        );
        return [...prevSelectedMeters, ...filteredSuggestions];
      });
    }
  }

  return (
    <div>
      <div className="MeterConfigurationButtons">
        <span className="LastUpdateText">
          {lastUpdate ? `Letzte Aktualisierung: ${lastUpdate}` : ""}
        </span>

        <div className="MeterConfigurationActions">
          {meterParamsSorted && meterParamsSorted?.length > 0 && (
            <Button
              color="brand"
              disabled={
                isPollingMeterParamsData ||
                connectionLoading ||
                isSuggestionsLoading ||
                isRefetchingSuggestions
              }
              size="sm"
              onClick={loadSuggestedMeters}
            >
              Zuordnung vorschlagen
            </Button>
          )}

          <IconButton
            color="brand"
            disabled={
              isPollingMeterParamsData ||
              connectionLoading ||
              isSuggestionsLoading ||
              isRefetchingSuggestions
            }
            iconName={IconName.Download}
            size="sm"
            onClick={retrieveData}
          >
            Stammdaten abrufen
          </IconButton>
        </div>
      </div>

      {isPollingMeterParamsData && <MeterDataLoading />}

      {(isSuggestionsLoading || isRefetchingSuggestions) && (
        <MeterSuggestionsLoading />
      )}

      {isMeterParamsLoading || isMeterParamsFetching ? (
        <div className="MeterDataLoader">
          <AnimatedLoadingIcon />
        </div>
      ) : meterParamsSorted && meterParamsSorted?.length > 0 ? (
        <MeterConfigurationTable
          configurationId={configurationId}
          isMeterParamsLoading={isMeterParamsLoading}
          isMetersQueryLoading={isMetersQueryLoading}
          isSuggestionsLoading={isSuggestionsLoading || isRefetchingSuggestions}
          meterParams={meterParamsSorted}
          metersBySiteId={metersBySiteId}
          selectedMeters={selectedMeters}
          setSelectedMeters={setSelectedMeters}
          sites={sites}
          systemName={systemName}
          onMetersSave={onMetersSave}
        />
      ) : (
        !isPollingMeterParamsData && (
          <p data-testid="no-data-available" style={{ textAlign: "center" }}>
            Noch keine Stammdaten.
          </p>
        )
      )}
    </div>
  );
}

export { MeterConfiguration };
