import { type ReactNode } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import type {
  SiteEnergyWizardPhases,
  SiteWizardPhases,
  WizardDetail,
  WizardMissingData
} from "../../../../utils/backend-types";
import {
  MissingDataComponentName,
  MissingDataTypes
} from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import "./onboardingMailPage.scss";
import { MISSING_DATA_DESCRIPTIONS } from "./utils/missingDataDescriptions";

type OnboardingMailPageProps = {
  details?: WizardDetail;
  onClose: () => void;
  phaseKey: keyof SiteWizardPhases | keyof SiteEnergyWizardPhases;
};

function getLinkNameFromComponentName(componentName: MissingDataComponentName) {
  switch (componentName) {
    case MissingDataComponentName.PERSON:
      return "../unternehmen";
    case MissingDataComponentName.GENERATOR:
      return "../erzeuger";
    case MissingDataComponentName.METER:
      return "../zaehler";
    case MissingDataComponentName.CONNECTION:
      return "../netzverknuepfungspunkte";
    case MissingDataComponentName.CONSUMER:
      return "../verbraucher";
    case MissingDataComponentName.MARKET_LOCATION:
      return "../market-locations";
    case MissingDataComponentName.METERING_LOCATION:
      return "../metering-locations";
    default:
      return "fehler";
  }
}

function getTabNameFromDataType(dataType: MissingDataTypes) {
  switch (dataType) {
    case MissingDataTypes.DEFAULT:
      return "";
    case MissingDataTypes.METERING_CONCEPT:
      return "messkonzept";
    case MissingDataTypes.ANLAGE:
      return "stromsteuerliche-anlage";
    case MissingDataTypes.REGULATORY:
      return "regulatorische-daten";
    case MissingDataTypes.TAX:
      return "daten-steuerformulare";
    case MissingDataTypes.INVOICE:
      return "stromlieferung-und-abrechnung";
    case MissingDataTypes.MEASUREMENT:
      return "messwerte";
    case MissingDataTypes.EEG:
      return "eeg-anlage";
    case MissingDataTypes.KWK:
      return "kwk-anlage";
    default:
      return "fehler";
  }
}

export function wizardLinkBuilder(
  projectId: string | null,
  componentName: MissingDataComponentName,
  id: number,
  missingDataType: MissingDataTypes
) {
  if (componentName === MissingDataComponentName.SITE) {
    return "./../../?site=" + id;
  }

  const deliveryOverview = generatePath(ROUTES.deliveryOverview, {
    projectId: projectId
  });
  if (componentName === MissingDataComponentName.DELIVERY) {
    return `./../../../lieferungen-uebersicht/vertraege/lieferung/${id}/`;
  }
  if (componentName === MissingDataComponentName.MIETERSTROM_CONTRACT) {
    return `${deliveryOverview}/vertraege/vertrag/${id}/`;
  }

  const linkName = getLinkNameFromComponentName(componentName);
  const tabName = getTabNameFromDataType(missingDataType);
  if (tabName === "fehler" || linkName === "fehler") return "fehler";
  return `${linkName}/${id}/${tabName}`;
}

export function groupMissingData(
  missingData: WizardMissingData[]
): Record<string, WizardMissingData[]> {
  const groupedData: Record<string, WizardMissingData[]> = {};

  missingData.forEach((data) => {
    const { componentName, missingDataType } = data;
    const key = `${componentName}-${missingDataType}`;

    if (!groupedData[key]) {
      groupedData[key] = [];
    }

    groupedData[key].push(data);
  });

  return groupedData;
}

function getDescriptionForComponentAndMissingDataType(
  componentName: MissingDataComponentName,
  missingDataType: MissingDataTypes
) {
  switch (componentName) {
    case MissingDataComponentName.PERSON:
      switch (missingDataType) {
        case MissingDataTypes.DEFAULT:
          return MISSING_DATA_DESCRIPTIONS.configuration.companies;
        case MissingDataTypes.REGULATORY:
          return MISSING_DATA_DESCRIPTIONS.regulatory.regulatoryData;
        case MissingDataTypes.TAX:
          return MISSING_DATA_DESCRIPTIONS.regulatory.taxData;
        case MissingDataTypes.INVOICE:
          return MISSING_DATA_DESCRIPTIONS.regulatory.invoiceData;
        default:
          break;
      }
      break;
    case MissingDataComponentName.GENERATOR:
      switch (missingDataType) {
        case MissingDataTypes.DEFAULT:
          return MISSING_DATA_DESCRIPTIONS.regulatory.generalGenerators;
        case MissingDataTypes.ANLAGE:
          return MISSING_DATA_DESCRIPTIONS.regulatory.regulatoryGenerators;
        case MissingDataTypes.EEG:
          return MISSING_DATA_DESCRIPTIONS.regulatory.eeg;
        case MissingDataTypes.KWK:
          return MISSING_DATA_DESCRIPTIONS.regulatory.kwk;
        default:
          break;
      }
      break;
    case MissingDataComponentName.METER:
      switch (missingDataType) {
        case MissingDataTypes.METERING_CONCEPT:
          return MISSING_DATA_DESCRIPTIONS.meteringConcept.meterMeteringConcept;
        case MissingDataTypes.DEFAULT:
          return MISSING_DATA_DESCRIPTIONS.vollmacht.meters;
        case MissingDataTypes.MEASUREMENT:
          return MISSING_DATA_DESCRIPTIONS.historischeMessdaten.meters;
        default:
          break;
      }
      break;
    case MissingDataComponentName.CONNECTION:
      if (missingDataType === MissingDataTypes.DEFAULT) {
        return MISSING_DATA_DESCRIPTIONS.configuration.connections;
      }
      break;
    case MissingDataComponentName.SITE:
      if (missingDataType === MissingDataTypes.DEFAULT) {
        return MISSING_DATA_DESCRIPTIONS.invoice.site;
      }
      break;
    case MissingDataComponentName.DELIVERY:
      if (missingDataType === MissingDataTypes.DEFAULT) {
        return MISSING_DATA_DESCRIPTIONS.invoice.delivery;
      }
      break;
    case MissingDataComponentName.CONSUMER:
      if (missingDataType === MissingDataTypes.REGULATORY) {
        return MISSING_DATA_DESCRIPTIONS.regulatory.regulatoryConsumers;
      }
      break;
    case MissingDataComponentName.MARKET_LOCATION:
      if (missingDataType === MissingDataTypes.DEFAULT) {
        return MISSING_DATA_DESCRIPTIONS.vollmacht.marketLocations;
      }
      break;
    case MissingDataComponentName.METERING_LOCATION:
      if (missingDataType === MissingDataTypes.DEFAULT) {
        return MISSING_DATA_DESCRIPTIONS.vollmacht.meteringLocations;
      }
      break;
    default:
      return "Für die folgenden Komponenten fehlen Daten: ";
  }
}

function removeComponentTypeFromCategory(
  category: string
): MissingDataTypes | null {
  const [, missingDataType] = category.split("-");
  if (
    missingDataType &&
    Object.values(MissingDataTypes).includes(
      missingDataType as MissingDataTypes
    )
  ) {
    return missingDataType as MissingDataTypes;
  }
  return null;
}

function getTabNameFromCategory(category: string) {
  const missingDataType = removeComponentTypeFromCategory(category);
  const tabName = missingDataType
    ? getTabNameFromDataType(missingDataType)
    : null;
  if (tabName) {
    return tabName;
  } else {
    return "general";
  }
}

function getMissingFieldsForId(
  data: Record<string, WizardMissingData[]>,
  targetId: number
) {
  const missingFieldsForId = {};
  let newCategoryName = "";
  for (const category in data) {
    for (const item of data[category]) {
      if (item.id === targetId) {
        newCategoryName = getTabNameFromCategory(category);
        missingFieldsForId[newCategoryName] = [
          ...(missingFieldsForId[newCategoryName] ?? []),
          ...item.missingFields
        ];
      }
    }
  }
  return missingFieldsForId;
}

export function renderGroupedMissingData(
  projectId: string | null,
  groupedData: Record<string, WizardMissingData[]>,
  exclusiveOrFields?: Array<Array<string>>,
  phaseKey?: keyof SiteWizardPhases | keyof SiteEnergyWizardPhases
) {
  const renderedData: Array<ReactNode> = [];
  for (const key in groupedData) {
    const [componentName, missingDataType] = key.split("-");
    const description = getDescriptionForComponentAndMissingDataType(
      componentName as MissingDataComponentName,
      missingDataType as MissingDataTypes
    );

    renderedData.push(
      <div key={key}>
        <h5 className="missing-data-description">{description}</h5>
        <div className="missing-data-link-container">
          {groupedData[key].map((data, index) => {
            const link = wizardLinkBuilder(
              projectId,
              data.componentName,
              data.id,
              data.missingDataType
            );
            if (link === "fehler") {
              return (
                <div className="missing-data-link" key={index}>
                  {data.name} - Fehler beim Erstellen des Links
                </div>
              );
            }
            return (
              <Link
                className="missing-data-link"
                key={index}
                state={{
                  missingFields: getMissingFieldsForId(groupedData, data.id),
                  exclusiveOrFields: exclusiveOrFields,
                  highlightMissingFieldsFromPhase: phaseKey
                }}
                to={link}
              >
                {data.name}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  return renderedData;
}

function OnboardingMailPage({
  details,
  onClose,
  phaseKey
}: OnboardingMailPageProps) {
  const { projectId } = useParams();

  const headerContent = details ? details.name : "Onboarding Fehler";
  const groupedMissingData = details
    ? groupMissingData(details.missingData)
    : null;
  const exclusiveOrFields = details ? details.exclusiveOrFields : [];

  return (
    <Modal isOpen toggle={() => onClose()}>
      <ModalHeader>{headerContent}</ModalHeader>
      <ModalBody scrollable>
        {groupedMissingData ? (
          renderGroupedMissingData(
            projectId ?? null,
            groupedMissingData,
            exclusiveOrFields,
            phaseKey
          )
        ) : (
          <div>Keine Onboarding Daten verfügbar.</div>
        )}
        <div className="control-container">
          <Button color="brand" onClick={() => onClose()}>
            Fertig
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export { OnboardingMailPage };
