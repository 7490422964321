import { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { TemplateModalState } from "../../../../BuildingBlocks/TemplateDecisionCards/TemplateDecisionCards";
import { Button, buttonColors } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import type {
  Paragraph6ContractPayload,
  Paragraph6ContractResponse
} from "../../../Paragraph6.types";
import { Paragraph6ContractModalContent } from "./Paragraph6ContractModalContent";

interface Paragraph6ContractModalProps {
  variantId: number;
  contractId?: string;
  isOpen?: boolean;
  onClose: () => void;
  templates?: Array<Paragraph6ContractResponse>;
}

function Paragraph6ContractModal({
  variantId,
  contractId,
  isOpen,
  onClose,
  templates
}: Paragraph6ContractModalProps) {
  const [modalState, setModalState] = useState<TemplateModalState>(
    TemplateModalState.None
  );

  const [selectedTemplate, setSelectedTemplate] =
    useState<Partial<Paragraph6ContractPayload> | null>(null);
  const editMode = !!contractId;

  useEffect(() => {
    const singleTemplate = templates?.length === 1 && {
      ...templates[0],
      is_template: false,
      name: "",
      id: undefined,
      erzeuger_anteile: []
    };
    if (singleTemplate) setSelectedTemplate(singleTemplate);
  }, [templates]);

  function onClosed() {
    setModalState(TemplateModalState.None);
    setSelectedTemplate(null);
  }

  const showContractForm =
    modalState === TemplateModalState.NewContract ||
    editMode ||
    !!selectedTemplate ||
    !templates ||
    templates.length === 0;

  return (
    <Modal
      className="Paragraph6ContractModal"
      isOpen={isOpen}
      size="xl"
      onClosed={onClosed}
    >
      <ModalHeader toggle={onClose}>
        Vertrag {editMode ? "bearbeiten" : "hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <Paragraph6ContractModalContent
          contractId={contractId}
          editMode={editMode}
          modalState={modalState}
          selectedTemplate={selectedTemplate}
          setModalState={setModalState}
          setSelectedTemplate={setSelectedTemplate}
          templates={templates}
          variantId={variantId}
          onClose={onClose}
        />
      </ModalBody>
      {!showContractForm && (
        <ModalFooter>
          <Button
            color={buttonColors.secondary}
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            Abbrechen
          </Button>
          <SpinButton color={buttonColors.brand} disabled type="submit">
            Speichern
          </SpinButton>
        </ModalFooter>
      )}
    </Modal>
  );
}

export { Paragraph6ContractModal, Paragraph6ContractModalProps };
