import AlvaLogo from "../../../../alva/assets/img/alva-logo.svg";
import DefaultLogo from "../../../../default/assets/img/logo-opti-node.png";
import SecondaryDefaultLogo from "../../../../default/assets/img/logo.png";
import SecondarySmarendoLogo from "../../../../roedl/assets/img/logo-white-small.png";
import SmarendoLogo from "../../../../roedl/assets/img/logo.svg";
import WavelrLogo from "../../../../wavelr/assets/img/wavelr-logo.svg";
import { SiteName } from "../../../utils/enums";

export function getSiteLogo(siteName: SiteName, useSecondaryImage: boolean) {
  if (siteName === SiteName.Smarendo) {
    if (useSecondaryImage) {
      return SecondarySmarendoLogo;
    }
    return SmarendoLogo;
  } else if (siteName === SiteName.Alva) {
    return AlvaLogo;
  } else if (siteName === SiteName.Wavelr) {
    return WavelrLogo;
  }

  if (useSecondaryImage) {
    return SecondaryDefaultLogo;
  }

  return DefaultLogo;
}
