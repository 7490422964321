import { Select } from "@mantine/core";
import { type MRT_ColumnDef } from "mantine-react-table";
import { type ExtendedUser, type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

const RESPONSIBLE_EMPTY = "empty";

export function getResponsibleColumn(
  handleChangeResponsible: (
    responsibleId: number | null,
    todoId: number
  ) => void,
  todos: Array<Todo>,
  users?: Array<ExtendedUser>
): MRT_ColumnDef<Todo> {
  const allResponsibles = todos.map((todo) => todo.responsible);
  const nonNullResponsibles = allResponsibles.filter(
    (userId) => userId !== null
  );
  const uniqueResponsibles = Array.from(new Set(nonNullResponsibles));
  const responsibleUsers = users
    ? uniqueResponsibles.map((userId) => ({
        id: userId,
        name: users.find((user) => user.id === userId)?.name
      }))
    : [];
  const sortedFilteredUsers = responsibleUsers.sort((userA, userB) =>
    (userA.name ?? "").localeCompare(userB.name ?? "")
  );
  const usedResponsibles = Array.from(sortedFilteredUsers);

  return {
    accessorKey: TodosListColumnKeys.Responsible,
    header: "Verantwortlich",
    Header: () => (
      <>
        Verantwortlich{" "}
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => (
      <Select
        data={users?.map((user) => ({
          value: user.id.toString(),
          label: user.name
        }))}
        value={row.original.responsible?.toString()}
        onChange={(value) =>
          handleChangeResponsible(
            value ? parseInt(value) : null,
            row.original.id
          )
        }
      />
    ),
    filterVariant: "multi-select",
    filterFn: (row, _, filterValue) => {
      if (filterValue.length === 0) {
        return true;
      }
      if (
        filterValue.includes(RESPONSIBLE_EMPTY) &&
        row.original.responsible === null
      ) {
        return true;
      }
      return filterValue.includes(row.original.responsible?.toString());
    },
    filterTooltipValueFn: (value) =>
      value === RESPONSIBLE_EMPTY
        ? "(leer)"
        : (usedResponsibles.find((user) => user.id.toString() === value)
            ?.name ?? ""),
    mantineFilterMultiSelectProps: {
      data: [{ value: RESPONSIBLE_EMPTY, label: "(leer)" }].concat(
        usedResponsibles.map((user) => ({
          value: user.id.toString(),
          label: user.name ?? ""
        }))
      )
    }
  };
}
