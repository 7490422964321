import type { MRT_ColumnDef } from "mantine-react-table";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";
import type { Paragraph6EEGCreditNumberRangeResponse } from "../../../Paragraph6.types";
import { isFormatValid } from "../utils/isFormatValid";

const HELP_TEXT = `Sie haben hier die Möglichkeit einen Nummernkreis entsprechend Ihren Anforderungen zu erstellen. Dazu bietet opti.node Ihnen eine Auswahl an verschiedenen Elementen an, die Sie je nach Bedarf für Ihren Nummernkreis zusammenstellen können.
Diese Elemente sind.

•	{M} Monat als 1-12

•	{MM} Monat (aufgefüllt mit Nullen, z. Bsp.: 01, 02 etc.)

•	{MMM} Monat (als Kurzform mit drei Buchstaben, z. Bsp.: Jan, Feb, …, Dez)

•	{MMMM} Monate als Januar-Dezember

•	{MMMMM} Monate mit den ersten Buchstaben des Monats (z. Bsp.: 1Januar)

•	{T} Tage als 1-31

•	{TT}Tag im Monat (aufgefüllt mit Nullen, z. Bsp.: 01, 02, ...)

•	{JJ} Jahr ohne Jahrtausend/Jahrhundert (z. Bsp.: 21, 22)

•	{JJJJ} Jahr inkl. Jahrtausend/Jahrhundert (z. Bsp.: 2021, 2022)

•	{Nummer} laufende Nummer (also 1,2,3). Führende Nullen  im “Startwert des Nummernkreises” werden gespeichert und als bewusste Wahl für die Mindestlänge der Nummer verwendet (also 005, 006, 007)

Bitte verbinden Sie mehrere Elemente mit einem - .
Möchten Sie einen Nummernkreis anlegen, der mit dem Datum 01.01.2025 sowie der Zahl 1 beginnt, können Sie zum Beispiel folgendes angeben: {JJJJ}-{MM}-{DD}-{Nummer}. Geben Sie dann bei Startwert die Nummer „1“ und bei Startdatum „01.01.2025“ ein.`;

export function getFormatColumn({
  validationErrors,
  setValidationErrors,
  t
}: InlineEditTableColumnData2): MRT_ColumnDef<Paragraph6EEGCreditNumberRangeResponse> {
  return {
    accessorFn: (row) => (row.format ? row.format : "G{JJJJ}-{Nummer}"),
    header: "Format",
    Header: (
      <span className="header-with-help-icon">
        Format
        <IconHelpText helpText={HELP_TEXT} />
      </span>
    ),
    id: "format",
    enableSorting: false,
    mantineEditTextInputProps: ({ table }) => ({
      "aria-label": "Format",
      type: "text",
      error: validationErrors.format,
      required: true,
      onChange: (event) => {
        const value = event.target.value;

        if (!isFormatValid(value)) {
          setValidationErrors({
            ...validationErrors,
            format: t("errors.Paragraph6.CreditNumberRanges.formatError")
          });
        } else {
          delete validationErrors.format;
          setValidationErrors({ ...validationErrors });
        }
      },
      onKeyDown: (event) => {
        submitOrCancelOnKeyPress(event, table);
      }
    })
  };
}
