import { SiteSetupProcessStatus } from "../../../../../types/api.types";
import { Badge } from "../../../../BuildingBlocks/Badge/Badge";

export function DisplayStatus({
  status
}: {
  status?: SiteSetupProcessStatus | null;
}) {
  switch (status) {
    case SiteSetupProcessStatus.NotConfigured:
      return <Badge color="info">Nicht konfiguriert</Badge>;
    case SiteSetupProcessStatus.InProgress:
      return <Badge color="warning">In Bearbeitung</Badge>;
    default:
      return;
  }
}
