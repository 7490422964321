import PropTypes from "prop-types";
import { PureComponent } from "react";
import urls from "../../urls";
import loader from "../Loader";
import { OptionsForm } from "../OptionsForm/OptionsForm";

import "./DefineSolarSimulationForm.scss";

class DefineSolarSimulationForm extends PureComponent {
  handleClickSimulate = (data) => {
    window.location.href = urls.simulation(data.id, this.props.projectId);
  };

  render() {
    const { siteId } = this.props;
    const simulationUrl = urls.api.simulationCreate(siteId);
    const hiddenFields = ["baseVariant", "site"];

    return (
      <div className="DefineSolarSimulationForm">
        <OptionsForm
          hiddenFields={hiddenFields}
          nonFieldData={{ site: siteId }}
          optionsUrl={simulationUrl}
          postUrl={simulationUrl}
          submitButtonText="Simulieren"
          onSubmit={this.handleClickSimulate}
        />
      </div>
    );
  }
}

DefineSolarSimulationForm.propTypes = {
  siteId: PropTypes.number
};

const DefineSolarSimulationFormWithLoader = loader(DefineSolarSimulationForm);

export { DefineSolarSimulationFormWithLoader as DefineSolarSimulationForm };
