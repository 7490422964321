import classNames from "classnames";
import type { FinalState } from "react-table";

import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import type { Choice } from "../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Input } from "../DynamicForm/FormItems/FormField/Input/Input";
import { DOTS, usePagination } from "./hooks/usePagination";

import "./CustomTablePagination.scss";

function CustomTablePagination({
  page = 0,
  pages,
  pageSize = 10,
  pageSizeOptions,
  canNext,
  canPrevious,
  sortedData,
  onPageChange,
  onPageSizeChange
}: Partial<FinalState>) {
  const totalCount = sortedData?.length || 0;

  const paginationRange = usePagination({
    currentPage: page,
    totalCount,
    pages,
    pageSize
  });

  const activePage = page + 1;

  const CHOICES: Array<Choice> | undefined = pageSizeOptions
    ? pageSizeOptions.map((value) => ({
        displayName: `${value} / Seite`,
        value: value
      }))
    : [];

  function onNext() {
    if (onPageChange) {
      onPageChange(page + 1);
    }
  }

  function onPrevious() {
    if (onPageChange) {
      onPageChange(page - 1);
    }
  }

  return (
    <div>
      <div className="table-pagination-controls">
        <div className="table-pagination-range-container">
          <button
            aria-label="left-caret-btn"
            className="table-pagination-btn"
            disabled={!canPrevious}
            onClick={onPrevious}
          >
            <Icon name={IconName.AngleLeft} />
          </button>
          {paginationRange &&
            paginationRange.map((pageNumber, idx) => {
              if (pageNumber === DOTS) {
                return (
                  <div
                    className="table-pagination-dots"
                    key={idx}
                    title="pagination-ellipsis"
                  />
                );
              }

              return (
                <button
                  aria-label="page-number-btn"
                  className={classNames("table-pagination-range-btn", {
                    "table-pagination-range-btn__active":
                      activePage === pageNumber
                  })}
                  key={idx}
                  onClick={
                    onPageChange
                      ? () => onPageChange(Number(pageNumber) - 1)
                      : undefined
                  }
                >
                  {pageNumber}
                </button>
              );
            })}

          <button
            aria-label="right-caret-btn"
            className="table-pagination-btn"
            disabled={!canNext}
            onClick={onNext}
          >
            <Icon name={IconName.AngleRight} />
          </button>
        </div>

        <div className="table-page-size-select">
          <TsDropdown
            choices={CHOICES}
            defaultValue={pageSize}
            id="pages"
            key={pageSize}
            name="pages"
            placeholder="Seitengröße"
            required
            searchable={false}
            onChange={(_, newValue: number) =>
              onPageSizeChange && onPageSizeChange(newValue, page)
            }
          />
        </div>

        <div className="table-page-input-field">
          <Input
            allowNegative={false}
            decimalScale={0}
            isAllowed={(values) => {
              const { floatValue, value } = values;

              console.log("Values >", values);

              if (value === "") {
                return true;
              }

              if (floatValue !== undefined) {
                return (
                  Number.isInteger(floatValue) &&
                  floatValue >= 1 &&
                  floatValue <= Math.ceil(totalCount / pageSize)
                );
              }

              return false;
            }}
            name="page"
            placeholder="Seite"
            thousandSeparator=""
            type="number"
            value={activePage}
            onChange={
              onPageChange
                ? (newValue: number) => onPageChange(newValue - 1)
                : () => null
            }
          />
        </div>
      </div>
    </div>
  );
}

export { CustomTablePagination };
