import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Column } from "react-table";
import { Card, CardBody, Collapse } from "reactstrap";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import type {
  ChangeableFieldData,
  ChangeableFieldWidgetOptions
} from "../../ChangeableFieldWidget/ChangeableFieldWidget";
import { ChangeableFieldWidget } from "../../ChangeableFieldWidget/ChangeableFieldWidget";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import { FormItemLabel } from "../../DynamicForm/FormItem/FormItem";
import type {
  FormFieldData,
  FormItemsProps
} from "../../DynamicForm/FormItems/FormItems";
import "./ChangeableParticipationField.scss";
import { ParticipationWarningModal } from "./ParticipationWarningModal/ParticipationWarningModal";
import { StatusCell } from "./StatusCell";

const CHANGEABLE_FIELD_WIDGET_OPTIONS: ChangeableFieldWidgetOptions = {
  addButtonText: "Status hinzufügen",
  addNullStartDateWhenNoValues: true,
  endDatePlaceholder: "-",
  startDatePlaceholder: "-",
  deleteTooltipText: "Status entfernen",
  numberValueHeader: "Status",
  saveButtonText: "Übernehmen",
  dateCellWidth: 270,
  allowDeletionOfAllRows: true
};

export interface ChangeableParticipation {
  id?: number;
  firstDate: string | null;
  lastDate: string | null;
  value: boolean;
}

interface ChangeableParticipationProps
  extends Omit<FormItemsProps, "formItems" | "onSubmit"> {
  fieldName: string;
  formItems: Record<string, FormFieldData>;
  unit?: string;
  participations: Array<ChangeableParticipation>;
  isMissing?: boolean;
}

function ChangeableParticipationFieldStatic({
  fieldName,
  formItems,
  formName,
  allowInput,
  unit,
  participations = [],
  isMissing,
  onInput
}: ChangeableParticipationProps) {
  const [isHistoryCollapseOpen, setIsHistoryCollapseOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [submittableData, setSubmittableData] = useState<
    ChangeableFieldData<boolean>[]
  >([]);

  const [initialParticipations, setInitialParticipations] =
    useState<Array<ChangeableParticipation>>();

  useEffect(() => {
    if (!initialParticipations && participations) {
      setInitialParticipations([...participations]);
    }
  }, [initialParticipations, participations]);

  const options = useMemo(() => {
    return {
      ...CHANGEABLE_FIELD_WIDGET_OPTIONS,
      inputGroupText: unit
    };
  }, [unit]);

  const formItem: FormFieldData | undefined = formItems[fieldName];
  const formItemId = `id_${
    formName ? formName : "form"
  }_${formItem?.name.replace(/\./g, "__")}`;

  function handleClickHistoryButton() {
    setIsCollapseOpen(false);
    setIsHistoryCollapseOpen((open) => !open);
  }

  function handleClickEditButton() {
    setIsHistoryCollapseOpen(false);
    setIsCollapseOpen((open) => !open);
  }

  const handleSaveParticipations = useCallback(
    (participations) => {
      onInput(fieldName, participations);
      setIsCollapseOpen(false);
      return Promise.resolve();
    },
    [onInput, fieldName]
  );

  const submitParticipations = useCallback(
    (changeableFieldData: Array<ChangeableFieldData<boolean>>) => {
      const newParticipations =
        convertChangeableFieldDataToParticipations(changeableFieldData);

      return handleSaveParticipations(newParticipations);
    },
    [handleSaveParticipations]
  );

  function convertParticipationsToChangeableFieldData(
    participations?: Array<ChangeableParticipation>
  ) {
    if (!participations) {
      return undefined;
    }

    return participations
      .map<ChangeableFieldData<boolean>>((participation) => ({
        id: participation.id,
        startDate: participation.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(participation.firstDate)
          : null,
        endDate: participation.lastDate
          ? backendDateOrDateTimeToLuxonDateTime(participation.lastDate)
          : null,
        value: participation.value
      }))
      .sort((a, b) => {
        if (a.startDate === null && b.startDate === null) {
          return 0;
        } else if (a.startDate === null) {
          return -1;
        } else if (b.startDate === null) {
          return 1;
        } else if (a.startDate < b.startDate) {
          return -1;
        } else if (b.startDate < a.startDate) {
          return 1;
        }

        return 0;
      });
  }

  function convertChangeableFieldDataToParticipations(
    values: Array<ChangeableFieldData<boolean>>
  ) {
    return values.map<ChangeableParticipation>((value) => ({
      id: value.id,
      value: !!value.value,
      firstDate: value.startDate
        ? luxonDateTimeToBackendDateOrDateTime(value.startDate)
        : null,
      lastDate: value.endDate
        ? luxonDateTimeToBackendDateOrDateTime(value.endDate)
        : null
    }));
  }

  function getCurrentParticipation(
    participations: Array<ChangeableParticipation>
  ) {
    const now = DateTime.now();
    return participations.find((participation) => {
      const firstDate = participation.firstDate
        ? backendDateOrDateTimeToLuxonDateTime(participation.firstDate)
        : null;
      const lastDate = participation.lastDate
        ? backendDateOrDateTimeToLuxonDateTime(participation.lastDate)
        : null;

      const firstDateInRange = !firstDate || firstDate <= now;
      const lastDateInRange = !lastDate || now <= lastDate;

      return firstDateInRange && lastDateInRange;
    });
  }

  const currentParticipation = useMemo(() => {
    return getCurrentParticipation(participations);
  }, [participations]);

  const suggestedChangeableParticipations = useMemo(() => {
    return convertParticipationsToChangeableFieldData(participations);
  }, [participations]);

  const memoizedChangeableFieldWidget = useMemo(() => {
    if (!suggestedChangeableParticipations) {
      return null;
    }
    return (
      <ChangeableFieldWidget
        dataName="Statusänderungen"
        initialValues={suggestedChangeableParticipations}
        options={options}
        valueColumns={[
          {
            header: "Status",
            accessor: "participationsInMieterstromModel",
            width: 120,
            Cell: (cellData, onChange) => (
              <StatusCell statusValue={cellData} onChange={onChange} />
            )
          }
        ]}
        onCancel={() => setIsCollapseOpen(false)}
        onDone={(submittableData) => {
          if (
            JSON.stringify(
              convertParticipationsToChangeableFieldData(initialParticipations)
            ) !== JSON.stringify(submittableData)
          ) {
            setShowWarningModal(true);

            setSubmittableData(
              submittableData as ChangeableFieldData<boolean>[]
            );
          } else {
            submitParticipations(
              submittableData as ChangeableFieldData<boolean>[]
            );
          }
          return Promise.resolve();
        }}
      />
    );
  }, [
    initialParticipations,
    options,
    submitParticipations,
    suggestedChangeableParticipations
  ]);

  return (
    <div className="ChangeableParticipationField FormField">
      {showWarningModal && (
        <ParticipationWarningModal
          onCancel={() => {
            setSubmittableData([]);
            setShowWarningModal(false);
          }}
          onSubmit={() => {
            setShowWarningModal(false);
            return submitParticipations(submittableData);
          }}
        />
      )}
      <div>
        <div className="toggler-label">
          {formItem && (
            <FormItemLabel
              helpText={formItem.helpText}
              highlight={
                isMissing && (!participations || participations.length === 0)
              }
              id={formItemId}
              instance={formItem.instance}
              label={formItem.label}
              required={formItem.required}
            />
          )}
          <div className="label-and-button">
            <span
              className="label-container"
              onClick={handleClickHistoryButton}
            >
              <Icon
                className="toggle-icon"
                name={
                  isHistoryCollapseOpen
                    ? IconName.AngleDown
                    : IconName.AngleRight
                }
              />
              {currentParticipation
                ? currentParticipation.value
                  ? "Ja"
                  : "Nein"
                : undefined}
            </span>
            <Button
              className="label-button"
              color="brand"
              disabled={!allowInput || isCollapseOpen}
              onClick={handleClickEditButton}
            >
              Statusänderung hinzufügen
            </Button>
          </div>
        </div>
      </div>
      <Collapse isOpen={isHistoryCollapseOpen}>
        {participations ? (
          <ParticipationHistory participations={participations} />
        ) : (
          <Card className="loading-card">
            <CardBody></CardBody>
          </Card>
        )}
      </Collapse>
      <Collapse isOpen={isCollapseOpen}>
        <Card className="participations-edit">
          <CardBody>{memoizedChangeableFieldWidget}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

interface ParticipationHistoryProps {
  participations: Array<ChangeableParticipation>;
}

function ParticipationHistory({ participations }: ParticipationHistoryProps) {
  const tableColumns: Array<Column<ChangeableParticipation>> = [
    {
      Header: "Von",
      accessor: "firstDate",
      minWidth: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Bis",
      accessor: "lastDate",
      minWidth: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Status",
      accessor: "value",
      Cell: (cellData) => <span>{cellData.value ? "Ja" : "Nein"}</span>,
      sortable: false,
      width: 120
    }
  ];

  return (
    <div className="participation-history" style={{ marginTop: "0.5rem" }}>
      <CustomReactTable
        columns={tableColumns}
        data={participations}
        minRows={0}
        pageSize={participations.length}
      />
    </div>
  );
}

export { ChangeableParticipationFieldStatic };
