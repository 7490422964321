import _ from "lodash";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { Col, Row } from "reactstrap";

import urls from "../../urls";
import { getDataUrlsForSelectedVariantIds } from "../../utils/getDataUrlsForSelectedVariantIds";
import VariantComparison from "../ProjectManager/VariantComparison/VariantComparison";

import "./AssessmentPage.scss";

class AssessmentPage extends PureComponent {
  render() {
    const {
      displayNames,
      selectedVariantIds,
      variants,
      onMoveVariantLeft,
      onMoveVariantRight,
      isAnalyzeView
    } = this.props;

    const selectedDataUrls = getDataUrlsForSelectedVariantIds(
      selectedVariantIds,
      urls.api.meteringConcept
    );

    const variantsById = _.keyBy(variants, "id");
    return (
      <div className="AssessmentPage">
        <Row>
          <Col>
            <VariantComparison
              columnHeaders={displayNames}
              dataUrls={selectedDataUrls}
              isAnalyzeView={isAnalyzeView}
              selectedVariantIds={selectedVariantIds}
              variants={variantsById}
              onMoveLeft={onMoveVariantLeft}
              onMoveRight={onMoveVariantRight}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AssessmentPage.propTypes = {
  displayNames: PropTypes.object,
  selectedVariantIds: PropTypes.array,
  variants: PropTypes.array,
  onMoveVariantLeft: PropTypes.func,
  onMoveVariantRight: PropTypes.func,
  isAnalyzeView: PropTypes.bool
};

export default AssessmentPage;
