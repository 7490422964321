import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useMeteringOrMarketLocations } from "../../../../../../hooks/useMeteringOrMarketLocations";
import { useSuggestedMeteringOrMarketLocation } from "../../../../../../hooks/useSuggestedMeteringOrMarketLocation";
import type { MeteringOrMarketLocation } from "../../../../../../utils/backend-types";
import { buildFieldNameToLabelMap } from "../../../../../../utils/react-hook-form/buildFieldNameToLabelMap";
import { showToast } from "../../../../../../utils/toast";
import { AlertColor } from "../../../../../Alert/Alert";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormBody } from "../../../../../BuildingBlocks/Forms/utils/FormBody";
import { FormContainer } from "../../../../../BuildingBlocks/Forms/utils/FormContainer";
import { FormFooter } from "../../../../../BuildingBlocks/Forms/utils/FormFooter";
import { setErrorsFromResponseData } from "../../../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { IconAlert } from "../../../../../BuildingBlocks/IconAlert/IconAlert";
import { TabBar } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { Button, buttonColors } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";
import type { Choice } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { HistoryTab } from "../../../../../HistoryTab/HistoryTab";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import type {
  Paragraph6GeneratorListElement,
  paragraph6ErzeugerAnteilPayload,
  paragraph6ErzeugerAnteilResponse
} from "../../../../Paragraph6.types";
import type { PARAGRAPH_6_GENERATOR_FORM_TABS } from "./Paragraph6GeneratorForm.constants";
import {
  PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA,
  TABS
} from "./Paragraph6GeneratorForm.constants";
import "./Paragraph6GeneratorForm.scss";

const fieldNameToLabelMap = buildFieldNameToLabelMap(
  PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA
);

interface Paragraph6GeneratorFormProps {
  editMode: boolean;
  paragraph6ErzeugerAnteil?: Partial<paragraph6ErzeugerAnteilResponse>;
  paragraph6UnassignedGenerators?: Array<Paragraph6GeneratorListElement>;
  isSubmitting?: boolean;
  submissionError?: Error;
  onSubmit: SubmitHandler<paragraph6ErzeugerAnteilPayload>;
  onCancel: () => void;
}

export const COPIED_FROM_NOTIFICATION =
  "Für den ausgewählten Erzeuger wurden bereits vorhandene Daten übernommen.";

function Paragraph6GeneratorForm({
  editMode,
  paragraph6ErzeugerAnteil,
  paragraph6UnassignedGenerators,
  isSubmitting,
  submissionError,
  onSubmit,
  onCancel
}: Paragraph6GeneratorFormProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<PARAGRAPH_6_GENERATOR_FORM_TABS>(
    TABS[0].id
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    watch
  } = useForm<paragraph6ErzeugerAnteilResponse>({
    defaultValues: paragraph6ErzeugerAnteil ?? {}
  });

  const generatorValue = watch("generator");
  const selectedGenerator = paragraph6UnassignedGenerators?.find(
    (unassignedGenerator) => unassignedGenerator.generator === generatorValue
  );

  const {
    meteringOrMarketLocations,
    isLoading: meteringOrMarketLocationsLoading
  } = useMeteringOrMarketLocations(
    editMode ? paragraph6ErzeugerAnteil?.site : selectedGenerator?.site
  );

  const {
    suggestedMeteringOrMarketLocation,
    isLoading: suggestedMeteringOrMarketLocationLoading
  } = useSuggestedMeteringOrMarketLocation(generatorValue);

  const generatorChoices = getGeneratorChoices();
  const generatorFieldInfo = {
    ...PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.generator,
    choices: generatorChoices,
    disabled: editMode
  };

  function getGeneratorChoices(): Array<Choice> {
    if (editMode && paragraph6ErzeugerAnteil?.generator_display_name) {
      return [
        {
          value: paragraph6ErzeugerAnteil.generator ?? "",
          displayName: paragraph6ErzeugerAnteil.generator_display_name ?? ""
        }
      ];
    } else {
      return (
        paragraph6UnassignedGenerators?.map((unassignedGenerator) => ({
          value: unassignedGenerator.generator,
          displayName: unassignedGenerator.generator_display_name
        })) ?? []
      );
    }
  }

  const meloMaloChoices = getMeloMaloChoices(
    meteringOrMarketLocations,
    suggestedMeteringOrMarketLocation?.id
  );
  const meloMaloFieldInfo = {
    ...PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.metering_or_market_location,
    choices: meloMaloChoices
  };

  function getMeloMaloChoices(
    meloMalos?: Array<MeteringOrMarketLocation>,
    prioritizedId?: number
  ): Array<Choice> {
    if (!meloMalos) return [];

    return meloMalos.map((meloMalo) => {
      const suggestedPostfix =
        meloMalo.id === prioritizedId ? " (Automatischer Vorschlag)" : "";
      return {
        value: meloMalo.id,
        displayName: meloMalo.name + suggestedPostfix
      };
    });
  }

  useEffect(() => {
    if (
      editMode ||
      !selectedGenerator ||
      meteringOrMarketLocations?.length === 0 ||
      suggestedMeteringOrMarketLocationLoading
    ) {
      return;
    }

    setValue(
      "eeg_inbetriebnahmedatum",
      selectedGenerator.eeg_inbetriebnahmedatum
    );
    setValue("anlagenschluessel_eeg", selectedGenerator.anlagenschluessel_eeg);
    setValue("foerderregime", selectedGenerator.foerderregime);

    if (selectedGenerator.metering_or_market_location_id) {
      setValue(
        "metering_or_market_location",
        selectedGenerator.metering_or_market_location_id
      );
    }

    if (selectedGenerator.weighting) {
      setValue("weighting", selectedGenerator.weighting);
    }
    if (selectedGenerator.anzulegender_wert) {
      setValue("anzulegender_wert", selectedGenerator.anzulegender_wert);
    }

    showToast("success", COPIED_FROM_NOTIFICATION);
  }, [
    editMode,
    meteringOrMarketLocations,
    selectedGenerator,
    setValue,
    suggestedMeteringOrMarketLocationLoading
  ]);

  useEffect(() => {
    if (submissionError) {
      setErrorsFromResponseData<paragraph6ErzeugerAnteilPayload>(
        submissionError,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  }, [setError, submissionError, t, watch]);

  return (
    <FormContainer>
      <form
        className="Paragraph6GeneratorForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormBody>
          <TabBar<PARAGRAPH_6_GENERATOR_FORM_TABS>
            activeTab={activeTab}
            routeless
            tabs={TABS.filter((tab) => editMode || !tab.hiddenInCreateMode)}
            onActivateTab={setActiveTab}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Object.values(TABS)[0].id}>
              <section>
                <FormFieldController
                  control={control}
                  data={generatorFieldInfo}
                  error={errors.generator}
                />
                <LoadOrError
                  className="melomalos-loading"
                  loading={
                    meteringOrMarketLocationsLoading ||
                    suggestedMeteringOrMarketLocationLoading
                  }
                  loadingMessage="Zählpunkte werden geladen ..."
                >
                  {meloMaloChoices.length > 0 && (
                    <>
                      <FormFieldController
                        control={control}
                        data={
                          PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.eeg_inbetriebnahmedatum
                        }
                        error={errors.eeg_inbetriebnahmedatum}
                      />
                      <FormFieldController
                        control={control}
                        data={meloMaloFieldInfo}
                        error={errors.metering_or_market_location}
                      />
                      <FormFieldController
                        control={control}
                        data={PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.weighting}
                        error={errors.weighting}
                      />
                      <FormFieldController
                        control={control}
                        data={
                          PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.community_share
                        }
                        error={errors.community_share}
                      />
                      <FormFieldController
                        control={control}
                        data={
                          PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.anzulegender_wert
                        }
                        error={errors.anzulegender_wert}
                        rules={{
                          validate: (value) => {
                            if (value === null) {
                              return "Ungültiger Wert";
                            }

                            return true;
                          }
                        }}
                      />
                      {/* <FormFieldController
                  control={control}
                  data={{
                    // TODO: check if direct marketing should be removed https://node-energy.atlassian.net/browse/WUP-7772
                    // @ts-expect-error visual placeholder to remove later
                    name: "direct_marketing_type",
                    required: false,
                    label: "Vermarktungsform",
                    type: "dropdown",
                    disabled: true
                  }}
                /> */}
                      <FormFieldController
                        control={control}
                        data={
                          PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.foerderregime
                        }
                        error={errors.foerderregime}
                      />
                      <FormFieldController
                        control={control}
                        data={
                          PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA.anlagenschluessel_eeg
                        }
                        error={errors.anlagenschluessel_eeg}
                      />
                    </>
                  )}
                  {selectedGenerator && meloMaloChoices.length === 0 && (
                    <IconAlert color={AlertColor.Warning}>
                      In Ihrer Konfiguration wurde für den ausgewählten Erzeuger
                      bisher kein geeichter Zähler mit Mess- oder Marktlokation
                      angelegt.
                      <br />
                      Bitte ergänzen Sie diese in Ihrer{" "}
                      <Link
                        to={`../../liegenschaften/${selectedGenerator.site}/zaehler`}
                      >
                        Liegenschaft
                      </Link>
                      .
                    </IconAlert>
                  )}
                  {/*isEligibleForRefund() && ( // todo check if eligible for refund can be reomved https://node-energy.atlassian.net/browse/WUP-7772
              <IconAlert color={AlertColor.Info}>
                Aufgrund der durch Sie hinterlegten Angaben zur Vermarktungsform
                und zum Förderregime besteht in der Regel Anspruch auf anteilige
                Rückerstattung der geleisteten Zahlungen an die Gemeinden und
                Kommunen.
              </IconAlert>
            )*/}
                </LoadOrError>
              </section>
            </TabPane>
            {paragraph6ErzeugerAnteil?.contract &&
              paragraph6ErzeugerAnteil.generator && (
                <TabPane tabId={Object.values(TABS)[1].id}>
                  <HistoryTab
                    entityId={paragraph6ErzeugerAnteil.contract}
                    entityType="paragraph6Generator"
                    fieldNameToLabelMap={fieldNameToLabelMap}
                    subEntityId={paragraph6ErzeugerAnteil.generator}
                  />
                </TabPane>
              )}
          </TabContent>
        </FormBody>
        <FormFooter>
          <Button
            color={buttonColors.secondary}
            disabled={isSubmitting}
            type="button"
            onClick={onCancel}
          >
            Abbrechen
          </Button>
          <SpinButton
            color={buttonColors.brand}
            disabled={meloMaloChoices.length === 0}
            spin={isSubmitting}
            type="submit"
          >
            Speichern
          </SpinButton>
        </FormFooter>
      </form>
    </FormContainer>
  );
}

export { Paragraph6GeneratorForm, Paragraph6GeneratorFormProps };
