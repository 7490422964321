import { UncontrolledAlert } from "reactstrap";

function AnImportIsStoppedWarning() {
  return (
    <div className="AnImportIsStoppedWarning">
      <UncontrolledAlert color="info">
        Der Import der farblich hinterlegten Dateien wartet auf Ihre Eingabe.
        Bitte klicken Sie dafür auf die Links in der Name-Spalte.
      </UncontrolledAlert>
    </div>
  );
}

export { AnImportIsStoppedWarning };
