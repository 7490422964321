import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import api from "../../../api";
import { useDownload } from "../../../hooks/useDownload";
import { useProject } from "../../../hooks/useProject";
import { patchSiteCategories } from "../../../hooks/useSiteCategories";
import urls from "../../../urls";
import type {
  Site,
  SiteCategoriesResponse,
  SiteCategoriesWithSiteFeatures
} from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { DeleteProjectWidget } from "./DeleteProjectWidget/DeleteProjectWidget";
import { ProjectSettingsForm } from "./ProjectSettingsForm/ProjectSettingsForm";
import "./SettingsPage.scss";
import { SiteCategoriesTable } from "./SiteCategoriesTable/SiteCategoriesTable";

interface SettingsPageProps {
  projectId: string;
  variantId: number;
  projectSites: ReadonlyArray<Site>;
  siteCategories: Array<SiteCategoriesResponse>;
}

function SettingsPage({
  projectId,
  variantId,
  projectSites,
  siteCategories
}: SettingsPageProps) {
  const { project } = useProject(projectId);
  const shouldShowStaffView = useShouldShowStaffView();
  const queryClient = useQueryClient();

  const [updatedSiteCategories, setUpdatedSiteCategories] = useState<
    Array<SiteCategoriesWithSiteFeatures>
  >([]);

  const sapExportInputEnabled = projectSites.some(
    (site) => site.invoicing_active
  );

  const buildSiteCategoriesWithInvoiceStatus = useCallback(() => {
    if (!siteCategories) return [];
    return siteCategories.map<SiteCategoriesWithSiteFeatures>(
      (siteCategory) => {
        const siteInvoiceStatus = projectSites.find(
          (site) => site.id === siteCategory.id
        );
        return {
          ...siteCategory,
          invoicing_active: siteInvoiceStatus?.invoicing_active ?? false,
          metering_concept_v2_active:
            siteInvoiceStatus?.metering_concept_v2_active ?? false,
          go_active: siteInvoiceStatus?.go_active ?? false,
          premium_todos_active: siteInvoiceStatus?.premium_todos_active ?? false
        };
      }
    );
  }, [projectSites, siteCategories]);

  useEffect(() => {
    setUpdatedSiteCategories(buildSiteCategoriesWithInvoiceStatus());
  }, [buildSiteCategoriesWithInvoiceStatus]);

  function handleClickSave() {
    showToast("success", "Das Projekt wurde erfolgreich aktualisiert.");
    queryClient.invalidateQueries({
      queryKey: [
        "project",
        {
          projectId
        }
      ]
    });
    queryClient.invalidateQueries({
      queryKey: ["projects-list"]
    });

    if (shouldShowStaffView) {
      const updatedSiteCategoriesWithoutInvoicingActive =
        updatedSiteCategories?.map(({ invoicing_active, ...rest }) => rest);

      patchSiteCategories(
        projectId,
        updatedSiteCategoriesWithoutInvoicingActive
      ).then(() => {
        queryClient.invalidateQueries({
          queryKey: ["siteCategories", { projectId }]
        });
      });
    }
  }

  const { download, isLoading } = useDownload();

  async function handleChangeInvoiceStatus(
    id: number,
    invoicingActive: boolean
  ) {
    const url = urls.api.setSiteInvoicing(id, invoicingActive);
    const successMessage =
      "Die Rechnungsstellung wurde für diese Liegenschaft" +
      (invoicingActive ? " aktiviert." : " deaktiviert.");
    const oldUpdatedSiteCategories = [...updatedSiteCategories];
    const updatedSiteCategoriesWithInvoiceStatus = oldUpdatedSiteCategories.map(
      (siteCategory) => {
        if (siteCategory.id === id) {
          return {
            ...siteCategory,
            invoicing_active: invoicingActive
          };
        }
        return siteCategory;
      }
    );
    setUpdatedSiteCategories(updatedSiteCategoriesWithInvoiceStatus);
    try {
      await api.post(url);
      queryClient.invalidateQueries({
        queryKey: ["sites-list", { variantId }]
      });
      showToast("success", successMessage);
    } catch (error) {
      setUpdatedSiteCategories(oldUpdatedSiteCategories);
      openErrorAlertPopup(error);
    }
  }

  async function handleChangeMeteringConceptStatus(
    id: number,
    active: boolean
  ) {
    const url = active
      ? urls.api.activateMeteringConceptV2(id)
      : urls.api.deactivateMeteringConceptV2(id);
    const successMessage =
      "Die Verwendung des MBK V2 für Rechnungsstellung wurde für diese Liegenschaft" +
      (active ? " aktiviert." : " deaktiviert.");
    const oldUpdatedSiteCategories = [...updatedSiteCategories];
    const updatedSiteCategoriesWithMBKV2Status = oldUpdatedSiteCategories.map(
      (siteCategory) => {
        if (siteCategory.id === id) {
          return {
            ...siteCategory,
            metering_concept_v2_active: active
          };
        }
        return siteCategory;
      }
    );
    setUpdatedSiteCategories(updatedSiteCategoriesWithMBKV2Status);
    try {
      await api.post(url);
      queryClient.invalidateQueries({
        queryKey: ["sites-list", { variantId }]
      });
      showToast("success", successMessage);
    } catch (error) {
      setUpdatedSiteCategories(oldUpdatedSiteCategories);
      openErrorAlertPopup(error);
    }
  }

  async function handleChangeGoStatus(id: number, goActive: boolean) {
    const url = urls.api.setSiteGo(id, goActive);
    const successMessage =
      "Die HKN Dokumentation wurde für diese Liegenschaft" +
      (goActive ? " aktiviert." : " deaktiviert.");
    const oldUpdatedSiteCategories = [...updatedSiteCategories];
    const updatedSiteCategoriesWithGOStatus = oldUpdatedSiteCategories.map(
      (siteCategory) => {
        if (siteCategory.id === id) {
          return {
            ...siteCategory,
            go_active: goActive
          };
        }
        return siteCategory;
      }
    );
    setUpdatedSiteCategories(updatedSiteCategoriesWithGOStatus);
    try {
      await api.post(url);
      queryClient.invalidateQueries({
        queryKey: ["sites-list", { variantId }]
      });
      showToast("success", successMessage);
    } catch (error) {
      setUpdatedSiteCategories(oldUpdatedSiteCategories);
      openErrorAlertPopup(error);
    }
  }

  async function handleChangePremiumStatus(id: number, premiumActive: boolean) {
    const url = urls.api.setIsPremiumSite(id, premiumActive);
    const successMessage =
      "Die Premium Lizenz wurde für diese Liegenschaft" +
      (premiumActive ? " aktiviert." : " deaktiviert.");
    const oldUpdatedSiteCategories = [...updatedSiteCategories];
    const updatedSiteCategoriesWithPremium = oldUpdatedSiteCategories.map(
      (siteCategory) => {
        if (siteCategory.id === id) {
          return {
            ...siteCategory,
            is_premium: premiumActive
          };
        }
        return siteCategory;
      }
    );
    setUpdatedSiteCategories(updatedSiteCategoriesWithPremium);
    try {
      await api.post(url);
      queryClient.invalidateQueries({
        queryKey: ["sites-list", { variantId }]
      });
      showToast("success", successMessage);
    } catch (error) {
      setUpdatedSiteCategories(oldUpdatedSiteCategories);
      openErrorAlertPopup(error);
    }
  }

  async function handleChangePremiumTodosStatus(
    id: number,
    premiumTodosActive: boolean
  ) {
    const url = urls.api.setSitePremiumTodos(id, premiumTodosActive);
    const successMessage =
      "Die Premium Aufgaben wurde für diese Liegenschaft" +
      (premiumTodosActive ? " aktiviert." : " deaktiviert.");
    try {
      await api.post(url);
      if (updatedSiteCategories) {
        const updatedSiteCategoriesWithInvoiceStatus =
          updatedSiteCategories.map((siteCategory) => {
            if (siteCategory.id === id) {
              return {
                ...siteCategory,
                premium_todos_active: premiumTodosActive
              };
            }
            return siteCategory;
          });
        setUpdatedSiteCategories(updatedSiteCategoriesWithInvoiceStatus);
        queryClient.invalidateQueries({
          queryKey: ["sites-list", { variantId }]
        });
        showToast("success", successMessage);
      }
    } catch (error) {
      openErrorAlertPopup(error);
    }
  }

  return (
    <div className="SettingsPage ItemsPage">
      <Section>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            {shouldShowStaffView && updatedSiteCategories && (
              <SiteCategoriesTable
                data={updatedSiteCategories}
                onChange={setUpdatedSiteCategories}
                onChangeGoStatus={handleChangeGoStatus}
                onChangeInvoiceStatus={handleChangeInvoiceStatus}
                onChangeMeteringConceptStatus={
                  handleChangeMeteringConceptStatus
                }
                onChangePremiumStatus={handleChangePremiumStatus}
                onChangePremiumTodosStatus={handleChangePremiumTodosStatus}
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md="4">
            {!!project && (
              <ProjectSettingsForm
                project={project}
                sapExportInputEnabled={sapExportInputEnabled}
                showStaffView={shouldShowStaffView}
                onSubmit={handleClickSave}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <SpinButton
              className="download-button"
              color="brand"
              download
              spin={isLoading}
              onClick={() =>
                download({
                  downloadUrl: urls.api.exportProjectAsExcel(variantId)
                })
              }
            >
              Projekt als .xlsx exportieren
            </SpinButton>

            <DeleteProjectWidget
              project={project}
              showStaffView={shouldShowStaffView}
            />
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export { SettingsPage };
