import type { IconName } from "../BuildingBlocks/Icon/types";

export enum Group {
  TopLevel = "top_level",
  Analyzer = "analyzer",
  Manager = "manager",
  ManagerSite = "manager_site",
  ModuleChoice = "module_choice",
  PvSimulation = "pv_simulation",
  Hidden = "hidden"
}

export interface MenuItemData {
  label: string;
  icon: IconName;
  group: Group;
  showGroups: Array<Group>;
  activeUrlNames?: Array<string>;
  staff?: boolean;
  url?: string;
  persistGroups?: boolean;
  persistActiveItem?: boolean;
  hidden?: boolean;
}
