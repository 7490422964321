import { Link } from "react-router-dom";

interface MenuLinkProps {
  to: string;
  text: string;
  textId?: string;
  afterText?: React.ReactNode;
}

function MenuLink({ to, text, textId, afterText }: MenuLinkProps) {
  return (
    <Link className="m-menu__link" to={to}>
      <span className="m-menu__link-text" id={textId}>
        {text}
      </span>
      {afterText}
    </Link>
  );
}

export { MenuLink };
