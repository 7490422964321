import classnames from "classnames";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSiteWizard } from "../../../../../hooks/useSiteWizard";
import { TodoStatus } from "../../../../../utils/backend-types";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { OrangeCircle } from "../../../../OrangeCircle/OrangeCircle";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import {
  getOnboardingTodoLabel,
  OnboardingTodo
} from "../../../../Todos/onboarding-todos";
import { getTodoStatusChoices } from "../../../../Todos/utils/getTodoStatusChoices";
import "./OptiNodeAssignment.scss";

type OptiNodeAssignmentProps = {
  siteId: number;
  done: boolean;
  isNext: boolean;
  regulatoryTodoDone: boolean;
  msbDataTodoDone: boolean;
  mbkTodoDone: boolean;
  onSiteWizardUpdated: () => Promise<void>;
};

function OptiNodeAssignment({
  siteId,
  done,
  isNext,
  onSiteWizardUpdated,
  regulatoryTodoDone,
  msbDataTodoDone,
  mbkTodoDone
}: OptiNodeAssignmentProps) {
  const [isSpinning, setIsSpinning] = useState(false);
  const shouldShowStaffView = useShouldShowStaffView();
  const { updateOnboardingProcessDoneStatus, updateOnboardingPhaseDoneStatus } =
    useSiteWizard(siteId);

  function setOnboardingPhaseDoneStatus(
    todoType: OnboardingTodo,
    isDone: boolean
  ) {
    setIsSpinning(true);
    const onboardingPhase = getOnboardingTodoLabel(
      todoType,
      DateTime.now().year
    );
    updateOnboardingPhaseDoneStatus(onboardingPhase, isDone)
      .then(onSiteWizardUpdated)
      .finally(() => {
        setIsSpinning(false);
      });
  }

  function setOnboardingProcessStatus() {
    setIsSpinning(true);
    updateOnboardingProcessDoneStatus(!done)
      .then(onSiteWizardUpdated)
      .finally(() => {
        setIsSpinning(false);
      });
  }

  function getTodoStatusElement(
    todoDone: boolean,
    type: OnboardingTodo,
    staff: boolean = false
  ) {
    if (staff) {
      return (
        <div className="todo-status">
          <TodoStatusUpdate
            staff={staff}
            todoDone={todoDone ? TodoStatus.Done : TodoStatus.Open}
            type={type}
            onUpdateOnboardingPhaseDoneStatus={setOnboardingPhaseDoneStatus}
          />
        </div>
      );
    } else if (todoDone) {
      return (
        <div className="todo-status">
          <Icon className="todo-status-icon" name={IconName.Check} />
        </div>
      );
    } else {
      return (
        <div className="todo-status">
          <OrangeCircle />
        </div>
      );
    }
  }

  return (
    <div className="optinode-assignment-container">
      {isNext && (
        <div className="optinode-assignment-todo-list-container">
          <div className="todo-status-container">
            <span
              className={classnames("todo-status-title", {
                staff: shouldShowStaffView
              })}
            >
              MBK erstellen
            </span>
            {getTodoStatusElement(
              mbkTodoDone,
              OnboardingTodo.MESS_UND_BILANZIERUNGSKONZEPT_ERSTELLEN,
              shouldShowStaffView
            )}
          </div>
          <div className="todo-status-container">
            <span
              className={classnames("todo-status-title", {
                staff: shouldShowStaffView
              })}
            >
              Aufgabenliste anlegen
            </span>
            {getTodoStatusElement(
              regulatoryTodoDone,
              OnboardingTodo.AUFGABENLISTE_MIT_REGULATORISCHEN_PFLICHTEN_ANLEGEN,
              shouldShowStaffView
            )}
          </div>
          <div className="todo-status-container">
            <span
              className={classnames("todo-status-title", {
                staff: shouldShowStaffView
              })}
            >
              Datenempfang einrichten
            </span>
            {getTodoStatusElement(
              msbDataTodoDone,
              OnboardingTodo.KONTINUIERLICHER_MSB_DATENEMPFANG_EINRICHTEN,
              shouldShowStaffView
            )}
          </div>
        </div>
      )}
      {shouldShowStaffView && (
        <div className="missing-data-container">
          <SpinButton
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            disabled={!done && !isNext}
            spin={isSpinning}
            onClick={setOnboardingProcessStatus}
          >
            Onboarding {done ? "zurücksetzen" : "abschließen"}
          </SpinButton>
        </div>
      )}
    </div>
  );
}

type TodoStatusUpdateProps = {
  todoDone: TodoStatus;
  type: OnboardingTodo;
  staff: boolean;
  onUpdateOnboardingPhaseDoneStatus: (
    todoType: OnboardingTodo,
    isDone: boolean
  ) => void;
};
function TodoStatusUpdate({
  todoDone,
  type,
  staff,
  onUpdateOnboardingPhaseDoneStatus
}: TodoStatusUpdateProps) {
  return (
    <TsDropdown
      choices={getTodoStatusChoices(todoDone, "", staff)}
      defaultValue={todoDone ?? false}
      id={"todo-status-update" + type}
      name={"todo-status-update"}
      required
      onChange={(_, newValue) => {
        onUpdateOnboardingPhaseDoneStatus(type, newValue === TodoStatus.Done);
      }}
    />
  );
}

export { OptiNodeAssignment };
