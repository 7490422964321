import { useState, useCallback } from "react";

import { getFieldSpecificError } from "../../../../../utils/api-utils";
import { Button } from "../../../../Buttons/Button/Button";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { retryUploadWithParameterSetRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { ReimportDataImportRequestsModal } from "./ReimportDataImportRequestsModal";

interface ReimportDataImportRequestsButtonProps {
  dataImportRequests: EnergyDataImportRequest[];
  setSelection: (selection: string[]) => void;
  onReimport: () => void;
}

function ReimportDataImportRequestsButton({
  dataImportRequests,
  setSelection,
  onReimport
}: ReimportDataImportRequestsButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dataImportRequestsNames = dataImportRequests.map(
    ({ energyDataFile: { name } }) => name
  );

  const toggleModal = () =>
    setIsModalOpen((currentIsModalOpen) => !currentIsModalOpen);

  const handleClickReimport = useCallback(
    (parameterSetId: string) =>
      Promise.allSettled(
        dataImportRequests.map(({ id }) =>
          retryUploadWithParameterSetRequest(id, parameterSetId)
        )
      )
        .then((results) => {
          const rejectedResult = results.find(
            (result) => result.status === "rejected"
          );

          if (rejectedResult) {
            const { reason } = rejectedResult as PromiseRejectedResult;

            const errorMessage = getFieldSpecificError(reason, [
              "parameterSetId"
            ]);

            openErrorAlertPopup(
              reason,
              errorMessage
                ? {
                    [reason?.response?.status as number]: errorMessage
                  }
                : {}
            );
          } else {
            onReimport();
          }
        })
        .finally(() => {
          toggleModal();
          setSelection([]);
        }),
    [dataImportRequests, onReimport, setSelection]
  );

  return (
    <>
      <Button
        color="brand"
        disabled={dataImportRequests.length === 0}
        onClick={toggleModal}
      >
        Reimport
      </Button>
      {isModalOpen && (
        <ReimportDataImportRequestsModal
          dataImportRequestsNames={dataImportRequestsNames}
          onAction={handleClickReimport}
          onToggle={toggleModal}
        />
      )}
    </>
  );
}

export { ReimportDataImportRequestsButton };
