import type { CellContext, ColumnDef } from "@tanstack/react-table";
import type { Payment } from "../../../../../../utils/backend-types";
import { RoundedNumberFormat } from "../../../../../../utils/RoundedNumberFormat";
import { EditIcon } from "../../../../../Buttons/EditIcon";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import type { PaymentDate } from "../PaymentsTab";

function getDiscountPaymentsColumn(
  onClickEdit: (date: PaymentDate) => void
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Rabatt/Bonus{" "}
        <IconHelpText
          helpText="An dieser Stelle können Sie einen möglichen Rabatt/Bonus für Ihre zu erstellende Rechnung hinterlegen. 

1. Geben Sie den Rabatt/Bonus ein: Tragen Sie den gewünschten Rabattbetrag in das entsprechende Feld ein. Dieser wird dann automatisch vom Bruttobetrag Ihrer Rechnung abgezogen.

2. Benennen Sie den Grund für den Rabatt/Bonus: Im Freitextfeld können Sie einen Satz einfügen, welcher Ihren Kunden erläutert, worauf sich dieser Rabatt/Bonus bezieht. 

3. Aktualisierung des Rechnungsbetrags: Der ermittelte Rechnungsbetrag wird automatisch um den Rabatt/Bonus reduziert.

4. Transparenz: Der Rabatt/Bonus wird separat auf der Rechnung ausgewiesen und vom Brutto-Rechnungsbetrag abgezogen. Ihre Begründung wird ebenfalls auf der Rechnung aufgeführt. "
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "discountPayments",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <div className="discountPaymentsCell">
        <RoundedNumberFormat
          className="pdiscountPaymentsCellValue"
          decimalScale={2}
          decimalSeparator=","
          displayType="text"
          fixedDecimalScale
          key={cellInfo.row.original.year}
          suffix=" €"
          thousandSeparator="."
          value={cellInfo.getValue() ?? ""}
        />
        <EditIcon
          tooltipText={"Bearbeiten"}
          onClick={() =>
            onClickEdit({
              year: cellInfo.row.original.year,
              month: cellInfo.row.original.month
            })
          }
        />
      </div>
    )
  };
}

export { getDiscountPaymentsColumn };
