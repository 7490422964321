import { Paper } from "@mantine/core";
import { useState } from "react";
import type { Site } from "../../../../utils/backend-types";
import { showToast } from "../../../../utils/toast";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { buttonColors } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { NoContracts } from "../../../NoContracts/NoContracts";
import { useParkabrechnungPoolContractMutations } from "../../hooks/useParkabrechnungPoolContractMutations";
import { useParkabrechnungPoolContracts } from "../../hooks/useParkabrechnungPoolContracts";
import type { ParkabrechnungPoolContract } from "../../Parkabrechnung.types";
import { ParkabrechnungContractModal } from "./ParkabrechnungContractModal";
import { ParkabrechnungContractTitle } from "./ParkabrechnungContractTitle/ParkabrechnungContractTitle";
import "./ParkabrechnungContracts.scss";

interface ParkabrechnungContractsProps {
  sites: Array<Site>;
  variantId: number;
}

function ParkabrechnungContracts({
  sites,
  variantId
}: ParkabrechnungContractsProps) {
  const { contracts, isLoading, error } =
    useParkabrechnungPoolContracts(variantId);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [contractIdToEdit, setContractIdToEdit] = useState<
    string | undefined
  >();

  function handleClickOpenContract(contractId?: string) {
    if (contractId) {
      setContractIdToEdit(contractId);
    }

    setIsContractModalOpen(true);
  }

  function handleCloseContractModal() {
    setIsContractModalOpen(false);
    setContractIdToEdit(undefined);
  }

  const contractToEdit = contracts?.find(
    (contract) => contract.id === contractIdToEdit
  );

  return (
    <>
      <div className="Contracts">
        <LoadOrError error={error} loading={isLoading}>
          {contracts && contracts.length > 0 ? (
            <Contracts
              contracts={contracts}
              variantId={variantId}
              onClickOpenContract={handleClickOpenContract}
            />
          ) : (
            <NoContracts onClickAddContract={handleClickOpenContract} />
          )}
        </LoadOrError>
      </div>
      {isContractModalOpen && (
        <ParkabrechnungContractModal
          contract={contractToEdit}
          isOpen
          sites={sites}
          variantId={variantId}
          onClose={handleCloseContractModal}
        />
      )}
    </>
  );
}

interface ContractsProps {
  contracts: Array<ParkabrechnungPoolContract>;
  variantId: number;
  onClickOpenContract: (contractId?: string) => void;
}

function Contracts({
  contracts,
  variantId,
  onClickOpenContract
}: ContractsProps) {
  const { parkabrechnungPoolContractDeleteMutation } =
    useParkabrechnungPoolContractMutations(variantId);
  const [contractToDelete, setContractToDelete] =
    useState<ParkabrechnungPoolContract | null>(null);

  async function handleDeleteContract() {
    if (contractToDelete) {
      try {
        await parkabrechnungPoolContractDeleteMutation.mutateAsync(
          contractToDelete.id
        );
      } catch (error) {
        showToast("error", error);
      } finally {
        setContractToDelete(null);
      }

      return Promise.resolve();
    }

    return Promise.reject();
  }

  return (
    <>
      <div className="contracts-header">
        <IconButton
          color={buttonColors.brand}
          iconName={IconName.Plus}
          onClick={() => onClickOpenContract()}
        >
          Vertrag hinzufügen
        </IconButton>
      </div>
      <div className="contracts-body">
        {contracts.map((contract) => (
          <Paper key={contract.id}>
            <ParkabrechnungContractTitle
              name={contract.name}
              onClickDelete={() => setContractToDelete(contract)}
              onClickEdit={() => onClickOpenContract(contract.id)}
            />
          </Paper>
        ))}
      </div>
      {contractToDelete && (
        <MultiConfirmationModal
          actionName="löschen"
          actionObjects={[contractToDelete.name]}
          confirmationText="Ihr Vertrag kann anschließend nicht wiederhergestellt werden."
          headerText="Wollen Sie diesen Vertrag löschen?"
          isModalOpen={!!contractToDelete}
          objectName="Vertrag"
          toggleModal={() => setContractToDelete(null)}
          onAction={handleDeleteContract}
        />
      )}
    </>
  );
}

export { ParkabrechnungContracts, ParkabrechnungContractsProps };
