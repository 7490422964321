import type { To } from "react-router-dom";

import { IconName } from "../BuildingBlocks/Icon/types";
import { ActionIcon } from "./ActionIcon";

const ICON_NAME = IconName.Pencil;
const CLASS_NAME = "edit-icon";

interface EditIconProps {
  to?: To;
  tooltipText?: string;
  onClick?: () => void;
  disabled?: boolean;
}

function EditIcon({ ...props }: EditIconProps) {
  return (
    <ActionIcon
      className={CLASS_NAME}
      iconName={ICON_NAME}
      {...props}
      data-testid="icon-button-edit"
    />
  );
}

export { EditIcon };
