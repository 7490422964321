import type { DateTime } from "luxon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import { useComponentsBase } from "../../../../hooks/useComponentsBase";
import { useConfiguratorComponents } from "../../../../hooks/useConfiguratorComponents";
import type { Tag } from "../../../../hooks/useEnergyDataAcquisitionTags";
import { usePersons } from "../../../../hooks/usePersons";
import { FREQUENCY_CHOICES } from "../../../../utils/constants";
import { ObjectName } from "../../../../utils/enums";

import {
  DatePresetType,
  OpenDirection,
  UncontrolledDateRangePicker
} from "../../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { sortChoices } from "../../../DynamicForm/FormItems/FormField/Dropdown/utils/sortChoices";
import type { FormFieldValue } from "../../../DynamicForm/FormItems/FormField/FormField";
import { FormFieldType } from "../../../DynamicForm/FormItems/FormField/FormField";
import type { Identity } from "../../EdaTable/Mbk";
import type { PrefillData } from "../CreateEdaVersionModal.constants";
import {
  EDA_FORM_FIELDS,
  ESTIMATION_PROCEDURE_FIELDS,
  ESTIMATION_PROCEDURE_OPTIONS,
  INPUT_LABELS,
  MEDIUM_OPTIONS,
  ORIGIN_OPTIONS
} from "../CreateEdaVersionModal.constants";
import type {
  EdaVersionFormValues,
  IdentityDefinitionList
} from "../CreateEdaVersionModal.types";
import {
  ESTIMATION_PROCEDURE_PARAMETER_ANNUAL_CONSUMPTION,
  ESTIMATION_PROCEDURE_PARAMETER_COMPUTATION,
  ESTIMATION_PROCEDURE_PARAMETER_CONSTANT_LOAD_KW,
  ESTIMATION_PROCEDURE_PARAMETER_FREQUENCY,
  ESTIMATION_PROCEDURE_PARAMETER_NUMBER_OF_CONSUMERS,
  ESTIMATION_PROCEDURE_PARAMETER_RATIO_OF_ENERGY_DATA,
  ESTIMATION_PROCEDURE_PARAMETER_REFERENCED_EDA_IDENTITY,
  ESTIMATION_PROCEDURE_PARAMETER_SECURITY_BUFFER,
  ORIGIN_COMPUTATION,
  ORIGIN_ESTIMATION,
  ORIGIN_METER
} from "../CreateEdaVersionModal.types";
import { DYNAMIC_FIELD_PARAMETERS } from "../dynamicFieldParameters";
import { Input } from "../Input/Input";
import "./CreateEdaVersionForm.scss";

interface CreateEdaVersionFormProps {
  formValues: EdaVersionFormValues;
  identityList: IdentityDefinitionList;
  isNewVersion?: boolean;
  prefillData?: PrefillData;
  referencedIdentityOptions: Record<string, Identity>;
  requiredFieldErrors: Array<string>;
  siteId: number;
  tagOptions: Array<Tag>;
  onUpdateFormValues: (
    key: string,
    value:
      | FormFieldValue
      | {
          startDate: DateTime | null;
          endDate: DateTime | null;
        }
  ) => void;
}

function CreateEdaVersionForm({
  formValues,
  identityList,
  isNewVersion,
  prefillData,
  referencedIdentityOptions,
  requiredFieldErrors,
  siteId,
  tagOptions,
  onUpdateFormValues: updateFormValues
}: CreateEdaVersionFormProps) {
  const { t } = useTranslation();
  const { persons: companies } = usePersons(siteId, { searchBy: "siteId" });
  const { data: connections } = useConfiguratorComponents(
    ObjectName.Connection,
    siteId
  );
  const { data: consumers } = useConfiguratorComponents(
    ObjectName.Consumer,
    siteId
  );
  const { data: generators } = useConfiguratorComponents(
    ObjectName.Generator,
    siteId
  );
  const { data: meters } = useConfiguratorComponents(ObjectName.Meter, siteId);
  const { data: componentsBase } = useComponentsBase(siteId);

  const parameters =
    formValues[EDA_FORM_FIELDS.artDerEnergieMenge] &&
    identityList &&
    identityList[formValues[EDA_FORM_FIELDS.artDerEnergieMenge]]
      ? Object.keys(
          identityList[formValues[EDA_FORM_FIELDS.artDerEnergieMenge]]
        )
      : [];

  function shouldShowReferenzenTitle() {
    return (
      parameters.filter((param) => param !== "displayName" && param !== "site")
        .length > 0
    );
  }

  function getArtDerEnergieMengeChoices() {
    if (typeof identityList === "undefined") {
      return [];
    }

    return Object.entries(identityList)
      .reduce<Array<Choice>>((choices, identity) => {
        const [key, value] = identity;
        const displayName = value.displayName;

        if (typeof displayName === "string") {
          return [...choices, { displayName, value: key }];
        }

        return choices;
      }, [])
      .filter((choice) => Object.keys(choice).length <= 3)
      .sort(sortChoices);
  }

  const referencedIdentityChoices = Object.keys(referencedIdentityOptions)
    .map((key) => ({
      value: key,
      displayName: key
    }))
    .sort(sortChoices);

  const sortedMeterOptions = meters
    ?.map(({ id, name }) => ({
      value: id,
      displayName: name
    }))
    .sort(sortChoices);

  const sortedCompanies = companies
    ?.map((company) => ({
      value: company.id,
      displayName: company.name
    }))
    .sort(sortChoices);

  const sortedComponentsBase =
    componentsBase
      ?.map((component) => ({
        value: component.id,
        displayName: component.name
      }))
      .sort(sortChoices) ?? [];

  const memoizedMappedTags = useMemo(
    () => remapTagsForForm(tagOptions),
    [tagOptions]
  );

  const sortedConnections = connections
    ?.map(({ id, name }) => ({
      value: id,
      displayName: name
    }))
    .sort(sortChoices);

  const sortedConsumers = consumers
    ?.map(({ id, name }) => ({
      value: id,
      displayName: name
    }))
    .sort(sortChoices);

  const sortedGenerators = generators
    ?.map(({ id, name }) => ({
      value: id,
      displayName: name
    }))
    .sort(sortChoices);

  const sortedMeters = meters
    ?.map(({ id, name }) => ({
      value: id,
      displayName: name
    }))
    .sort(sortChoices);

  return (
    <FormGroup className="CreateEdaVersionForm">
      <Input
        allowInput={!prefillData?.artDerEnergieMenge}
        choices={getArtDerEnergieMengeChoices()}
        formField={EDA_FORM_FIELDS.artDerEnergieMenge}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.Field}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.artDerEnergieMenge]}
      />
      {shouldShowReferenzenTitle() && (
        <p className="section-label">Referenzen</p>
      )}
      <div className="referenzen-container">
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.description) && (
          <Input
            allowInput={!prefillData}
            formField={EDA_FORM_FIELDS.description}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.String}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.description]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.component) && (
          <Input
            allowInput={!prefillData}
            choices={sortedComponentsBase}
            formField={EDA_FORM_FIELDS.component}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.component]}
          />
        )}

        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.company) && (
          <Input
            allowInput={
              Array.isArray(companies) && companies.length > 0 && !prefillData
            }
            choices={sortedCompanies}
            formField={EDA_FORM_FIELDS.company}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.company]}
          />
        )}

        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.connection) && (
          <Input
            allowInput={!prefillData}
            choices={sortedConnections}
            formField={EDA_FORM_FIELDS.connection}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.connection]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.consumer) && (
          <Input
            allowInput={
              Array.isArray(consumers) && consumers.length > 0 && !prefillData
            }
            choices={sortedConsumers}
            formField={EDA_FORM_FIELDS.consumer}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.consumer]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.generator) && (
          <Input
            allowInput={
              Array.isArray(generators) && generators.length > 0 && !prefillData
            }
            choices={sortedGenerators}
            formField={EDA_FORM_FIELDS.generator}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.generator]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.meter) && (
          <Input
            allowInput={
              Array.isArray(generators) && generators.length > 0 && !prefillData
            }
            choices={sortedMeters}
            formField={EDA_FORM_FIELDS.meter}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.meter]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.rootMeter) && (
          <Input
            allowInput={!prefillData}
            choices={sortedMeters}
            formField={EDA_FORM_FIELDS.rootMeter}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.rootMeter]}
          />
        )}

        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.supplied) && (
          <Input
            allowInput={!prefillData}
            choices={sortedCompanies}
            formField={EDA_FORM_FIELDS.supplied}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.supplied]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.supplier) && (
          <Input
            allowInput={!prefillData}
            choices={sortedCompanies}
            formField={EDA_FORM_FIELDS.supplier}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.supplier]}
          />
        )}
        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.toCompany) &&
          companies.length > 0 && (
            <Input
              allowInput={
                Array.isArray(companies) && companies.length > 0 && !prefillData
              }
              choices={sortedMeters}
              formField={EDA_FORM_FIELDS.toCompany}
              requiredFieldErrors={requiredFieldErrors}
              type={FormFieldType.Field}
              updateFormValues={updateFormValues}
              value={formValues[EDA_FORM_FIELDS.toCompany]}
            />
          )}

        {parameters.includes(DYNAMIC_FIELD_PARAMETERS.fromCompany) &&
          companies.length > 0 && (
            <Input
              allowInput={
                Array.isArray(companies) && companies.length > 0 && !prefillData
              }
              choices={sortedCompanies}
              formField={EDA_FORM_FIELDS.fromCompany}
              requiredFieldErrors={requiredFieldErrors}
              type={FormFieldType.Field}
              updateFormValues={updateFormValues}
              value={formValues[EDA_FORM_FIELDS.fromCompany]}
            />
          )}
      </div>
      <Input
        choices={ORIGIN_OPTIONS}
        formField={EDA_FORM_FIELDS.origin}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.Field}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.origin]}
      />
      <Input
        choices={MEDIUM_OPTIONS}
        formField={EDA_FORM_FIELDS.medium}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.Field}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.medium]}
      />
      <div>
        <p className="section-label">{INPUT_LABELS.validity?.label}*</p>
        <div
          className={`validity-container ${
            requiredFieldErrors.includes(EDA_FORM_FIELDS.validity)
              ? "validity-container-error"
              : ""
          }`}
        >
          <UncontrolledDateRangePicker
            disabled={
              !isNewVersion &&
              Boolean(
                prefillData?.originalVersion.versions[0].lastDate &&
                  prefillData?.originalVersion.versions[0].firstDate
              )
            }
            initialEndDate={
              formValues[EDA_FORM_FIELDS.validity]?.endDate ?? undefined
            }
            initialStartDate={
              formValues[EDA_FORM_FIELDS.validity]?.startDate ?? undefined
            }
            openDirection={OpenDirection.OpenUp}
            presets={DatePresetType.MonthYear}
            onChange={(value) =>
              updateFormValues(EDA_FORM_FIELDS.validity, value)
            }
          />
        </div>

        {requiredFieldErrors.includes(EDA_FORM_FIELDS.validity) &&
          t("errors.Required")}
      </div>

      {(formValues[EDA_FORM_FIELDS.origin] ?? prefillData?.origin) ===
        ORIGIN_METER && (
        <>
          <Input
            choices={sortedMeterOptions}
            formField={EDA_FORM_FIELDS.meter}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.meter]}
          />
        </>
      )}
      {(formValues[EDA_FORM_FIELDS.origin] ?? prefillData?.origin) ===
        ORIGIN_COMPUTATION && (
        <>
          <Input
            choices={Object.values(FREQUENCY_CHOICES)}
            formField={EDA_FORM_FIELDS.frequency}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.frequency]}
          />
          <Input
            formField={EDA_FORM_FIELDS.formula}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.String}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.formula]}
          />
        </>
      )}
      {(formValues[EDA_FORM_FIELDS.origin] ?? prefillData?.origin) ===
        ORIGIN_ESTIMATION && (
        <>
          <Input
            choices={ESTIMATION_PROCEDURE_OPTIONS}
            formField={EDA_FORM_FIELDS.estimationProcedure}
            requiredFieldErrors={requiredFieldErrors}
            type={FormFieldType.Field}
            updateFormValues={updateFormValues}
            value={formValues[EDA_FORM_FIELDS.estimationProcedure]}
          />

          <>
            {formValues[EDA_FORM_FIELDS.estimationProcedure] &&
              ESTIMATION_PROCEDURE_FIELDS[
                formValues[EDA_FORM_FIELDS.estimationProcedure]
              ]?.map((field) => {
                switch (field) {
                  case ESTIMATION_PROCEDURE_PARAMETER_ANNUAL_CONSUMPTION:
                    return (
                      <Input
                        formField={EDA_FORM_FIELDS.annualConsumption}
                        key={EDA_FORM_FIELDS.annualConsumption}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Float}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.annualConsumption]}
                      />
                    );

                  case ESTIMATION_PROCEDURE_PARAMETER_CONSTANT_LOAD_KW:
                    return (
                      <Input
                        formField={EDA_FORM_FIELDS.constantLoadKw}
                        key={EDA_FORM_FIELDS.constantLoadKw}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Float}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.constantLoadKw]}
                      />
                    );

                  case ESTIMATION_PROCEDURE_PARAMETER_NUMBER_OF_CONSUMERS:
                    return (
                      <Input
                        formField={EDA_FORM_FIELDS.numberOfConsumers}
                        key={EDA_FORM_FIELDS.numberOfConsumers}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Integer}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.numberOfConsumers]}
                      />
                    );

                  case ESTIMATION_PROCEDURE_PARAMETER_RATIO_OF_ENERGY_DATA:
                    return (
                      <Input
                        formField={EDA_FORM_FIELDS.ratioOfEnergyData}
                        key={EDA_FORM_FIELDS.ratioOfEnergyData}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Float}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.ratioOfEnergyData]}
                      />
                    );

                  case ESTIMATION_PROCEDURE_PARAMETER_REFERENCED_EDA_IDENTITY:
                    return (
                      <Input
                        choices={referencedIdentityChoices}
                        formField={EDA_FORM_FIELDS.referencedEdaIdentity}
                        key={EDA_FORM_FIELDS.referencedEdaIdentity}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Field}
                        updateFormValues={updateFormValues}
                        value={
                          formValues[EDA_FORM_FIELDS.referencedEdaIdentity]
                        }
                      />
                    );
                  case ESTIMATION_PROCEDURE_PARAMETER_FREQUENCY:
                    return (
                      <Input
                        choices={Object.values(FREQUENCY_CHOICES)}
                        formField={EDA_FORM_FIELDS.frequency}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Field}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.frequency]}
                      />
                    );
                  case ESTIMATION_PROCEDURE_PARAMETER_SECURITY_BUFFER:
                    return (
                      <Input
                        formField={EDA_FORM_FIELDS.securityBuffer}
                        key={EDA_FORM_FIELDS.securityBuffer}
                        requiredFieldErrors={requiredFieldErrors}
                        type={FormFieldType.Float}
                        updateFormValues={updateFormValues}
                        value={formValues[EDA_FORM_FIELDS.securityBuffer]}
                      />
                    );
                  case ESTIMATION_PROCEDURE_PARAMETER_COMPUTATION:
                    return (
                      <div key="computation">
                        <Input
                          choices={Object.values(FREQUENCY_CHOICES)}
                          formField={EDA_FORM_FIELDS.referencedEdaIdentity}
                          requiredFieldErrors={requiredFieldErrors}
                          type={FormFieldType.Field}
                          updateFormValues={updateFormValues}
                          value={
                            formValues[EDA_FORM_FIELDS.referencedEdaIdentity]
                          }
                        />

                        <Input
                          formField={EDA_FORM_FIELDS.formula}
                          requiredFieldErrors={requiredFieldErrors}
                          type={FormFieldType.String}
                          updateFormValues={updateFormValues}
                          value={formValues[EDA_FORM_FIELDS.formula]}
                        />
                      </div>
                    );

                  default:
                    break;
                }
              })}
          </>
        </>
      )}
      <Input
        formField={EDA_FORM_FIELDS.label}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.String}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.label]}
      />
      <Input
        formField={EDA_FORM_FIELDS.stromsteuerpflichtig}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.Boolean}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.stromsteuerpflichtig]}
      />
      <Input
        choices={memoizedMappedTags}
        formField={EDA_FORM_FIELDS.tags}
        requiredFieldErrors={requiredFieldErrors}
        type={FormFieldType.Field}
        updateFormValues={updateFormValues}
        value={formValues[EDA_FORM_FIELDS.tags]}
      />
    </FormGroup>
  );
}

function remapTagsForForm(arg: Array<Tag>): Array<{
  value: string;
  displayName: string;
  fontColor: string;
  color: string;
  group: string;
  shortName: string;
}> {
  return arg.map((tag) => ({
    value: tag.slug,
    displayName: tag.longName,
    fontColor: tag.color,
    color: tag.backgroundColor,
    shortName: tag.slug,
    group: "Klassifizierungen"
  }));
}

export { CreateEdaVersionForm, CreateEdaVersionFormProps };
