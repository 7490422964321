import { Paper, Title } from "@mantine/core";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, generatePath, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import type { ApiResponse } from "../../api";
import api from "../../api";
import { useNav } from "../../hooks/useNav";
import { usePersons } from "../../hooks/usePersons";
import { useProject } from "../../hooks/useProject";
import { useSiteCategories } from "../../hooks/useSiteCategories";
import urls from "../../urls";
import type {
  ExtendedUser,
  Person,
  Priority,
  Todo,
  TodoStatus,
  User
} from "../../utils/backend-types";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { getErrorText } from "../../utils/get-error-text";
import { Alert, AlertColor } from "../Alert/Alert";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { Button } from "../Buttons/Button/Button";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { AutoFillTodoModal } from "./AutoFillTodoModal/AutoFillTodoModal";
import type { TodoUser } from "./common";
import { Mode, getBasePathByMode, groupTodosByPerson } from "./common";
import { useTodos } from "./hooks/useTodos";
import { NewTodoModal } from "./NewTodoModal/NewTodoModal";
import { SingleTodo } from "./SingleTodo";
import { TodoControls } from "./TodoControls/TodoControls";
import { TodoTable } from "./TodoTable/TodoTable";
import { useSystemUsers } from "./utils/useSystemUsers";
import "./Todos.scss";

const ALL_TAB_NAME = "Alle";

interface TodosProps {
  todos: Array<Todo>;
  users: Array<ExtendedUser>;
  systemUsers: Array<User>;
  persons: Array<Person>;
  variantId: number;
  projectId: string;
  mode: Mode;
  updateTodoResponsible: (todo: Todo) => void;
  updateTodoStatus: (todo: Todo) => void;
  updateTodoPriority: (todo: Todo) => void;
  updateTodoDueDate: (todo: Todo) => void;
  updateTodoOverdue: (todo: Todo) => void;
  updateTodoPerson: (todoId: number, personId: number) => void;
  removeTodo: (todo: Todo) => Promise<ApiResponse>;
  removeTodos: (todos: Array<Todo>) => Promise<Array<ApiResponse>>;
  updateDocumentProvided: (todoId: number) => void;
}

function Todos({
  todos,
  users,
  systemUsers,
  persons,
  variantId,
  projectId,
  mode,
  updateTodoResponsible,
  updateTodoPriority,
  updateTodoStatus,
  updateTodoDueDate,
  updateTodoOverdue,
  updateTodoPerson,
  removeTodo,
  removeTodos,
  updateDocumentProvided
}: TodosProps) {
  const { siteCategories } = useSiteCategories(projectId);
  const showPriorityColumn =
    siteCategories && siteCategories.some((site) => site.is_premium);

  const showTodoStatus =
    siteCategories &&
    siteCategories.every((site) => site.is_partial_feedin_site);

  const todosGroups = useMemo(
    () => groupTodosByPerson(todos, mode),
    [mode, todos]
  );

  const [activeTab, setActiveTab] = useState<string>(
    todosGroups.length !== 1 ? ALL_TAB_NAME : todosGroups[0].personName
  );
  // ts: react-table mess of types (better to use any)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableRef, setTableRef] = useState<any>(null);
  const [isNewTodoModalOpen, setIsNewTodoModalOpen] = useState(false);
  const [isAutoFillTodoModalOpen, setIsAutoFillTodoModalOpen] = useState(false);
  const [selection, setSelection] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNav();

  useEffect(() => {
    if (
      activeTab !== ALL_TAB_NAME &&
      !todosGroups.find((group) => group.personName === activeTab)
    ) {
      setActiveTab(
        todosGroups.length !== 1 ? ALL_TAB_NAME : todosGroups[0].personName
      );
    }
  }, [activeTab, todosGroups]);

  function toggleNewTodoModal() {
    setIsNewTodoModalOpen(!isNewTodoModalOpen);
  }

  function toggleAutoFillTodoModal() {
    setIsAutoFillTodoModalOpen(!isAutoFillTodoModalOpen);
  }

  const toggleTodoModal = useCallback(
    (todoId: number | false) => {
      if (todoId) {
        navigate(`./${todoId}`);
      } else {
        navigate(".");
      }
    },
    [navigate]
  );

  const handleChangeStatus = useCallback(
    (status: TodoStatus, todoId: number) => {
      const todo = todos.find((todo) => todo.id === todoId);

      if (todo) {
        updateTodoStatus({
          ...todo,
          status: status
        });
      }
    },
    [todos, updateTodoStatus]
  );

  const handleChangePriority = useCallback(
    (priorityValue: Priority, todoId: number) => {
      const todo = todos.find((todo) => todo.id === todoId);

      if (todo) {
        updateTodoPriority({
          ...todo,
          priority: priorityValue
        });
      }
    },
    [todos, updateTodoPriority]
  );

  const handleChangeResponsible = useCallback(
    (responsibleId: number, todoId: number) => {
      const todo = todos.find((todo) => todo.id === todoId);

      if (todo) {
        updateTodoResponsible({
          ...todo,
          responsible: responsibleId
        });
      }
    },
    [todos, updateTodoResponsible]
  );

  const handleChangeDueDate = useCallback(
    (todoId: number, dueDate: string | null) => {
      const todo = todos.find((todo) => todo.id === todoId);

      if (todo && dueDate) {
        updateTodoDueDate({
          ...todo,
          dueDate: dueDate
        });
      }
    },
    [todos, updateTodoDueDate]
  );

  const handleChangePerson = useCallback(
    (todoId: number, person: number) => {
      const todo = todos.find((todo) => todo.id === todoId);
      if (todo) {
        updateTodoPerson(todoId, person);
      }
    },
    [todos, updateTodoPerson]
  );

  const handleChangeOverdue = useCallback(
    (todoId: number, overdue: boolean) => {
      const todo = todos.find((todo) => todo.id === todoId);

      if (todo) {
        updateTodoOverdue({
          ...todo,
          overdue: overdue
        });
      }
    },
    [todos, updateTodoOverdue]
  );

  function handleDeleteTodo(todo: Todo) {
    return removeTodo(todo)
      .then(() => {
        if (isSelected(todo.id.toString())) {
          setSelection([]);
        }

        if (selection.length === 0 && selectAll) {
          setSelectAll(false);
        }
      })
      .catch(openErrorAlertPopup);
  }

  function handleDeleteMultipleTodos(todos: Array<Todo>) {
    return removeTodos(todos)
      .then(() => {
        setSelection([]);
        setSelectAll(false);
      })
      .catch(openErrorAlertPopup);
  }

  const toggleSelection = useCallback(
    (key: string) => {
      setSelection((oldSelection) => {
        let newSelection: Array<string>;
        const keyExistsInSelection = !!oldSelection.find(
          (selectedKey) => selectedKey === key
        );

        if (keyExistsInSelection) {
          newSelection = oldSelection.filter(
            (selectedKey) => selectedKey !== key
          );
          setSelectAll(false);
        } else {
          newSelection = [...oldSelection, key];
        }

        return newSelection;
      });
    },
    [setSelection, setSelectAll]
  );

  const toggleAllVisibleTodos = useCallback(() => {
    const newSelection: Array<string> = [];

    if (!selectAll && !!tableRef) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = tableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original["id"]}`);
      });
    }

    setSelection(newSelection);
    setSelectAll(!selectAll);
  }, [setSelection, selectAll, setSelectAll, tableRef]);

  const isSelected = useCallback(
    (key: string) => {
      return selection.includes(`select-${key}`);
    },
    [selection]
  );

  // It may happen that a system user is also a project manager
  const uniqueUsersWithSystemUsers = useMemo(() => {
    const markedSystemUsers = systemUsers.map<TodoUser>((user) => {
      return { ...user, isSystemUser: true };
    });

    return _.uniqBy<TodoUser>(users.concat(markedSystemUsers), "id");
  }, [users, systemUsers]);

  function getSelectedAndVisibleTodos() {
    const todos: Array<Todo> = [];

    if (tableRef) {
      const wrappedInstance = tableRef.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      currentRecords.forEach((item) => {
        if (isSelected(item._original["id"])) {
          todos.push(item._original);
        }
      });
    }

    return todos;
  }
  function getDownloadButtonLabel() {
    const selectedTodos = getSelectedAndVisibleTodos();
    const totalSelectedTodos = selectedTodos.length;
    if (totalSelectedTodos === 0) {
      return "";
    }

    let totalDocuments = 0;
    let numberOfTodosWithDocuments = 0;
    selectedTodos.forEach((todo) => {
      totalDocuments += todo.todoFiles.length;
      if (todo.todoFiles.length > 0) {
        numberOfTodosWithDocuments += 1;
      }
    });
    const documentWording = totalDocuments === 1 ? "Dokument" : "Dokumente";
    return `${totalDocuments} ${documentWording} (in ${numberOfTodosWithDocuments} von ${totalSelectedTodos} Aufgaben)`;
  }
  const downloadButtonLabel = getDownloadButtonLabel();
  const handleSetTableRef = useCallback(
    (newTableRef) => setTableRef(newTableRef),
    [setTableRef]
  );

  function handleAutoFillTodoModalRefreshRequired() {
    // in the future, if we have a single page app, this function should add the new todo to redux
    const basePath = getBasePathByMode(mode);
    document.location.href = generatePath(basePath, {
      projectId: projectId
    });
  }

  // in case not saved yet into redux
  if (!todos) {
    return null;
  }

  function handleDownloadSelectedTodoDocuments() {
    const selectedTodos = getSelectedAndVisibleTodos();
    const selectedTodoIds = selectedTodos.map((todo) => todo.id);
    if (selectedTodoIds && selectedTodoIds.length > 0) {
      const url = urls.api.downloadSelectedTodoDocuments(variantId);
      const body = {
        todoIds: selectedTodoIds
      };
      api
        .post(url, body)
        .then((response) =>
          downloadCsvOrExcelFileForResponseWithContentDisposition(
            response,
            true
          )
        )
        .catch((error) => openErrorAlertPopup(error));
    }
  }

  const basePath = getBasePathByMode(mode);

  return (
    <PageContent className="Todos">
      <TodoControls
        getSelectedAndVisibleTodos={getSelectedAndVisibleTodos}
        mode={mode}
        variantId={variantId}
        onClickDeleteMultipleTodos={handleDeleteMultipleTodos}
        onClickDeleteTodo={handleDeleteTodo}
        onClickNewTodo={toggleNewTodoModal}
        onClickRefreshTodoList={toggleAutoFillTodoModal}
      />
      <Paper className="Todos">
        <Title variant="paper-header">
          {mode === Mode.Onboarding ? "Onboarding" : "Aufgaben"}
        </Title>
        {todosGroups.length > 1 && (
          <Nav className="tabs" pills>
            <NavItem key={ALL_TAB_NAME}>
              <NavLink
                active={activeTab === ALL_TAB_NAME}
                onClick={() => setActiveTab(ALL_TAB_NAME)}
              >
                Alle
              </NavLink>
            </NavItem>
            {todosGroups.map((todoGroup) => (
              <NavItem key={todoGroup.personName}>
                <NavLink
                  active={activeTab === todoGroup.personName}
                  onClick={() => setActiveTab(todoGroup.personName)}
                >
                  {todoGroup.personName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        )}
        <TabContent activeTab={activeTab}>
          <TabPane key={ALL_TAB_NAME} tabId={ALL_TAB_NAME}>
            {activeTab === ALL_TAB_NAME && (
              <>
                {todos.length > 0 ? (
                  <TodoTable
                    disableFilters={selection.length > 0}
                    downloadButtonLabel={downloadButtonLabel}
                    isSelected={isSelected}
                    mode={mode}
                    personsForVariant={persons}
                    selectAll={selectAll}
                    setTableRef={handleSetTableRef}
                    showPersonColumn
                    showPriorityColumn={showPriorityColumn}
                    showTodoStatusColumn={showTodoStatus}
                    siteCategories={siteCategories}
                    todos={todos}
                    toggleAll={toggleAllVisibleTodos}
                    toggleSelection={toggleSelection}
                    users={uniqueUsersWithSystemUsers}
                    onChangeDueDate={handleChangeDueDate}
                    onChangeOverdue={handleChangeOverdue}
                    onChangePriority={handleChangePriority}
                    onChangeResponsible={handleChangeResponsible}
                    onChangeStatus={handleChangeStatus}
                    onChangeTodoPerson={handleChangePerson}
                    onChangeTodoStatus={updateDocumentProvided}
                    onClickDownloadSelectedTodoDocuments={
                      handleDownloadSelectedTodoDocuments
                    }
                    onClickRow={toggleTodoModal}
                  />
                ) : (
                  <NoTodos
                    mode={mode}
                    onRequestAutoFillTodos={toggleAutoFillTodoModal}
                  />
                )}
              </>
            )}
          </TabPane>
          {todosGroups.map((todoGroup) => (
            <TabPane key={todoGroup.personName} tabId={todoGroup.personName}>
              {activeTab === todoGroup.personName && (
                <>
                  {todoGroup.todos.length > 0 ? (
                    <TodoTable
                      disableFilters={selection.length > 0}
                      downloadButtonLabel={downloadButtonLabel}
                      isSelected={isSelected}
                      mode={mode}
                      personsForVariant={persons}
                      selectAll={selectAll}
                      setTableRef={handleSetTableRef}
                      showPersonColumn={mode === Mode.Universal}
                      showPriorityColumn={showPriorityColumn}
                      showTodoStatusColumn={showTodoStatus}
                      siteCategories={siteCategories}
                      todos={todoGroup.todos}
                      toggleAll={toggleAllVisibleTodos}
                      toggleSelection={toggleSelection}
                      users={uniqueUsersWithSystemUsers}
                      onChangeDueDate={handleChangeDueDate}
                      onChangeOverdue={handleChangeOverdue}
                      onChangePriority={handleChangePriority}
                      onChangeResponsible={handleChangeResponsible}
                      onChangeStatus={handleChangeStatus}
                      onChangeTodoPerson={handleChangePerson}
                      onChangeTodoStatus={updateDocumentProvided}
                      onClickDownloadSelectedTodoDocuments={
                        handleDownloadSelectedTodoDocuments
                      }
                      onClickRow={toggleTodoModal}
                    />
                  ) : (
                    <NoTodos
                      mode={mode}
                      onRequestAutoFillTodos={toggleAutoFillTodoModal}
                    />
                  )}
                </>
              )}
            </TabPane>
          ))}
        </TabContent>

        <Routes>
          <Route
            element={
              <SingleTodo
                mode={mode}
                projectId={projectId}
                showTodoStatus={showTodoStatus}
                todos={todos}
                users={uniqueUsersWithSystemUsers}
                variantId={variantId}
                onChangeDueDate={handleChangeDueDate}
                onChangeOverdue={handleChangeOverdue}
                onChangePerson={handleChangePerson}
                onChangePriority={handleChangePriority}
                onChangeResponsible={handleChangeResponsible}
                onChangeStatus={handleChangeStatus}
                onChangeTodoStatus={updateDocumentProvided}
                onToggleTodoModal={() => toggleTodoModal(false)}
              />
            }
            path=":todoId/*"
          />
        </Routes>
      </Paper>
      {mode === Mode.Normal && (
        <>
          <NewTodoModal
            basePath={basePath}
            isOpen={isNewTodoModalOpen}
            mode={mode}
            persons={persons}
            projectId={projectId}
            users={users}
            variantId={variantId}
            onToggle={toggleNewTodoModal}
          />
          <AutoFillTodoModal
            isOpen={isAutoFillTodoModalOpen}
            users={users}
            variantId={variantId}
            onRequireTodoRefresh={handleAutoFillTodoModalRefreshRequired}
            onToggle={toggleAutoFillTodoModal}
          />
        </>
      )}
    </PageContent>
  );
}

interface NoTodosProps {
  mode: Mode;
  onRequestAutoFillTodos: () => void;
}

function NoTodos({ mode, onRequestAutoFillTodos }: NoTodosProps) {
  return (
    <div className="no-todos">
      <p>Es gibt noch keine Aufgaben in diesem Projekt.</p>
      {mode === Mode.Normal && (
        <Button color="brand" onClick={onRequestAutoFillTodos}>
          Liste aktualisieren
        </Button>
      )}
    </div>
  );
}

interface TodosDataLoaderProps {
  mode: Mode;
  variantId: number;
  projectId: string;
}

function TodosDataLoader({ mode, variantId, projectId }: TodosDataLoaderProps) {
  const {
    todos,
    isLoading: todosLoading,
    error: todosError,
    updateTodoResponsible,
    updateTodoStatus,
    updateTodoPriority,
    updateTodoDueDate,
    updateTodoOverdue,
    updateTodoPerson,
    removeTodo,
    removeTodos,
    updateDocumentProvided
  } = useTodos({
    mode,
    variantId,
    onUpdateError: openErrorAlertPopup
  });
  const {
    isLoading: systemUsersLoading,
    error: systemUsersError,
    data: systemUsers
  } = useSystemUsers();

  const {
    project,
    isLoading: projectLoading,
    error: projectError
  } = useProject(projectId);
  const {
    persons,
    isLoading: personsLoading,
    error: personsError
  } = usePersons(variantId);

  const loading =
    todosLoading || systemUsersLoading || projectLoading || personsLoading;

  const error = todosError || systemUsersError || projectError || personsError;

  const users = project ? project.managers : null;
  return (
    <LoadOrError error={error} loading={loading}>
      {todos && users && systemUsers && persons && (
        <Todos
          mode={mode}
          persons={persons}
          projectId={projectId}
          removeTodo={removeTodo}
          removeTodos={removeTodos}
          systemUsers={systemUsers}
          todos={todos}
          updateDocumentProvided={updateDocumentProvided}
          updateTodoDueDate={updateTodoDueDate}
          updateTodoOverdue={updateTodoOverdue}
          updateTodoPerson={updateTodoPerson}
          updateTodoPriority={updateTodoPriority}
          updateTodoResponsible={updateTodoResponsible}
          updateTodoStatus={updateTodoStatus}
          users={users}
          variantId={variantId}
        />
      )}
    </LoadOrError>
  );
}

function TodosDataLoaderAndProjectId(
  props: Omit<TodosDataLoaderProps, "projectId">
) {
  const { projectId } = useParams();

  // this should never happen unless Todos is used incorrectly from a wrong route
  if (!projectId) {
    return <Alert color={AlertColor.Danger}>{getErrorText(null)}</Alert>;
  }

  return <TodosDataLoader {...props} projectId={projectId} />;
}

export {
  TodosDataLoaderAndProjectId as Todos,
  TodosDataLoaderProps as TodosProps,
  TodosDataLoader as TodosWithoutProjectIdInRoute
};
