import { Group } from "@mantine/core";
import { IconSolarPanel2 } from "@tabler/icons-react";
import { t } from "i18next";
import { apiWithoutCamelization } from "../../../../api";
import urls from "../../../../urls";
import { FormField } from "../../../BuildingBlocks/Forms/FormField/FormField";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { type PVPlantWithMeter } from "../../SiteSetupAssistant.types";

interface PlantWithSEEProps {
  pvPlants: Array<PVPlantWithMeter>;
  index: number;
  error?: string;
  onChange: (data: Array<PVPlantWithMeter>, index: number) => void;
  onDelete: (index: number) => void;
  onSetError: (error: string | null, index: number) => void;
}

function PlantWithSEE({
  error,
  index,
  pvPlants,
  onChange,
  onDelete,
  onSetError
}: PlantWithSEEProps) {
  const pvPlant = pvPlants[index];

  async function validateSeeNumberLight(
    value: string | undefined,
    index: number
  ) {
    if (value === "" || typeof value === "undefined" || value === null) {
      onSetError(null, index);
    } else if (
      pvPlants.find(
        (pv, pvIndex) => pvIndex !== index && pv?.see_number === value
      )
    ) {
      onSetError(t("errors.SiteSetupAssistant.DuplicateMastrNumber"), index);
    } else {
      const seeNumberRegex = /^(SEE|EEG)\d{12}$/;
      if (!seeNumberRegex.test(value)) {
        onSetError(
          t("errors.SiteSetupAssistant.OfflineMastrNumberValidationFailed"),
          index
        );
      } else {
        try {
          const validationSuccess = await validateSeeNumberDeep(value);
          onSetError(validationSuccess, index);
        } catch (error) {
          onSetError(error, index);
        }
      }
    }
  }

  async function validateSeeNumberDeep(value: string): Promise<string | null> {
    let errorText: string | null = null;
    try {
      await apiWithoutCamelization.post(
        urls.apiWithoutCamelization.validateSEENumber(),
        { see_number: value }
      );
      return Promise.resolve(null);
    } catch (error) {
      errorText = t(`errors.SiteSetupAssistant.${error.response.data[0]}`);
    }

    return Promise.reject(errorText);
  }

  function handleChangeSeeNumber(index: number, value: string) {
    const updatedPvPlants = pvPlants.map((pvPlant, idx) => {
      if (idx === index) {
        return {
          ...pvPlant,
          see_number: value,
          meter: { ...pvPlant.meter, name: "Zähler " + value }
        };
      }
      return pvPlant;
    });
    onChange(updatedPvPlants, index);
  }

  return (
    <Group align="end" className="PlantWithSEE single-plant" gap="md">
      <Group pb={error ? "xl" : "md"}>
        <IconSolarPanel2 />
      </Group>
      <FormField
        error={error}
        label="SEE-/EEG-Nummer"
        placeholder="z.Bsp. SEE111111118666"
        type="text"
        value={pvPlant.see_number ?? undefined}
        onBlur={() => validateSeeNumberLight(pvPlant.see_number ?? "", index)}
        onChange={(event) => handleChangeSeeNumber(index, event.target.value)}
      />
      <Group pb={error ? "xl" : "md"}>
        <Icon
          className="delete-icon"
          name={IconName.Trash}
          tooltipText="Anlage löschen"
          onClick={() => onDelete(index)}
        />
      </Group>
    </Group>
  );
}

export { PlantWithSEE };
