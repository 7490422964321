import { THEME_VARS } from "../../../../utils/constants";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";

import "./MissingDeliveriesInfo.scss";

interface MissingDeliveriesInfoProps {
  numberOfDeliveries: number;
}

function MissingDeliveriesInfo({
  numberOfDeliveries
}: MissingDeliveriesInfoProps) {
  return (
    <div className="missing-deliveries-info-container">
      {numberOfDeliveries === 0 && (
        <Icon
          className="warning-triangle"
          name={IconName.Warning}
          style={{ color: THEME_VARS.warningColor }}
        />
      )}
      <span>
        {numberOfDeliveries > 0 ? numberOfDeliveries : "Keine"}{" "}
        {numberOfDeliveries === 1 ? "Lieferung" : "Lieferungen"}
      </span>
    </div>
  );
}

export { MissingDeliveriesInfo };
