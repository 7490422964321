import { useVariantSites } from "../../../../hooks/useVariantSites";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { ThirdPartySystems } from "../../../ThirdPartySystems/ThirdPartySystems";
import { useSubMeteringConfigurations } from "../../../ThirdPartySystems/utils/useSubMeteringConfigurations";

interface ThirdPartySystemsTabContentProps {
  variantId: number;
}

function ThirdPartySystemsTabContent({
  variantId
}: ThirdPartySystemsTabContentProps) {
  const {
    isLoading: isSitesListloading,
    error: sitesListError,
    sites
  } = useVariantSites(variantId);

  const {
    data,
    isLoading: isDataLoading,
    error: dataError
  } = useSubMeteringConfigurations(variantId);

  return (
    <LoadOrError
      error={dataError || sitesListError}
      loading={isDataLoading || isSitesListloading}
    >
      {data && sites && (
        <ThirdPartySystems
          initialConfigurations={data}
          sites={sites}
          variantId={variantId}
        />
      )}
    </LoadOrError>
  );
}

export { ThirdPartySystemsTabContent };
