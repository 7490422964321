import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../../Buttons/Button/Button";
import type { ChangeableSpotMarketFormula } from "../../ChangeableSpotMarketFormulaFieldStatic/ChangeableSpotMarketFormulaFieldStatic";
import type { ChangeablePrice } from "../ChangeablePriceField";

type FuturePriceWarningProps = {
  onSuccess: (
    prices: ChangeablePrice[] | ChangeableSpotMarketFormula[]
  ) => Promise<void>;
  onCancel: () => void;
  prices: ChangeablePrice[] | ChangeableSpotMarketFormula[];
};

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function FuturePriceWarning({
  onSuccess,
  onCancel,
  prices
}: FuturePriceWarningProps) {
  return (
    <Modal isOpen>
      <ModalHeader>Kurzfristige Änderung bestätigen</ModalHeader>
      <ModalBody>
        Gemäß § 41 Abs. 5 Satz 2 EnWG sind Haushaltskunden spätestens einen
        Monat vor Eintritt der beabsichtigten Änderung zu unterrichten. Für
        andere Letztverbraucher gilt eine Frist von 2 Wochen. Sind Sie sicher,
        dass Sie die Preisänderung durchführen möchten?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button color="warning" onClick={() => onSuccess(prices)}>
          Preisänderung bestätigen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { FuturePriceWarning };
