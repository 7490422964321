import { List, Title } from "@mantine/core";
interface MeterChecksProps {
  label: string;
  problems: Array<string>;
}

function MeterChecks({ label, problems }: MeterChecksProps) {
  if (problems.length === 0) {
    return null;
  }

  return (
    <div className="MeterChecks">
      <Title fw={500} mb={5} order={6}>
        {label}
      </Title>
      <List size="sm" withPadding>
        {problems.map((problem) => (
          <List.Item key={problem}>{problem}</List.Item>
        ))}
      </List>
    </div>
  );
}

export { MeterChecks };
