import { ActionIcon, CopyButton, rem, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
interface MantineCopyButtonProps {
  copyText: string;
  value: string;
}

function MantineCopyButton({ copyText, value }: MantineCopyButtonProps) {
  return (
    <CopyButton value={value}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? "Kopiert" : copyText}
          position="right"
          withinPortal={false}
        >
          <ActionIcon
            color={copied ? "teal" : "gray"}
            variant="subtle"
            onClick={copy}
          >
            {copied ? (
              <IconCheck style={{ width: rem(16) }} />
            ) : (
              <IconCopy style={{ width: rem(16) }} />
            )}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
}

export { MantineCopyButton, MantineCopyButtonProps };
