import { Button, Group, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { useIds } from "../../../hooks/useIds";
import { useProject } from "../../../hooks/useProject";
import { useVariantDetail } from "../../../hooks/useVariantDetail";
import { OptiSupportEndpoints } from "../../../urls";
import { showBannerToast } from "../../../utils/toast";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../../OptiSupportHelpLink/OptiSupportHelpLink";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { useParagraph6Contracts } from "../hooks/useParagraph6Contracts";
import { useParagraph6CreatableCredits } from "../hooks/useParagraph6CreatableCredits";
import { useParagraph6CreditErrors } from "../hooks/useParagraph6CreditErrors";
import { FinancialDocumentType } from "../Paragraph6.types";
import { Paragraph6AvailableCreditsModal } from "./Paragraph6AvailableCreditsModal/Paragraph6AvailableCreditsModal";
import { Paragraph6CreditErrorMessage } from "./Paragraph6CreditErrorMessage/Paragraph6CreditErrorMessage";
import "./Paragraph6CreditsPage.scss";
import { Paragraph6CreditsTable } from "./Paragraph6CreditsTable/Paragraph6CreditsTable";
import { UsePaymentInformationToggleSwitch } from "./UsePaymentInformationToggleSwitch/UsePaymentInformationToggleSwitch";

interface Paragraph6CreditsPageProps {
  variantId: number;
}

function Paragraph6CreditsPage({ variantId }: Paragraph6CreditsPageProps) {
  const { projectId } = useIds();

  const {
    featureFlags: { blockRueckerstattungP6Eeg },
    isLoading: featureFlagsLoading
  } = useFeatureFlags();

  const {
    creatableCredits,
    error: paragraph6CreatableCreditsError,
    createParagraph6Credits,
    fetchParagraph6DraftCreditStatus
  } = useParagraph6CreatableCredits(variantId);

  const {
    isLoading: paymentInformationIsLoading,
    error: paymentInformationError,
    variantDetail
  } = useVariantDetail(variantId);

  const { project, isLoading: projectLoading } = useProject(projectId, {
    enabled: !!projectId,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  const isProjectLoading = projectLoading || featureFlagsLoading;

  const isBlockRueckerstattungP6EegActive =
    isProjectLoading ||
    blockRueckerstattungP6Eeg ||
    project?.manualBlockRueckerstattungP6EegActive;

  const usePaymentInformation =
    variantDetail?.paragraph_6_payment_information_instead_of_credit;

  const { creditErrors } = useParagraph6CreditErrors(variantId);
  const shouldShowStaffView = useShouldShowStaffView();

  const {
    paragraph6Contracts,
    isLoading: paragraph6ContractsLoading,
    error: paragraph6ContractsError
  } = useParagraph6Contracts(variantId);

  const contractNames = paragraph6Contracts?.map(({ name, id }) => ({
    name,
    id
  }));

  const [availableCreditsModalOpen, setAvailableCreditsModalOpen] =
    useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);

  useEffect(() => {
    if (isBlockRueckerstattungP6EegActive === true) {
      showBannerToast(
        "warning",
        {
          headline: "Qualitätsverbesserung",
          text: (
            <>
              Wir haben unsere Qualitätssicherungs-Maßnahmen abgeschlossen. In
              Ihrem Projekt sind dabei Unstimmigkeiten aufgefallen. Wir haben
              Sie dazu kontaktiert und arbeiten gemeinsam daran, dass Sie Ihre
              Rückerstattungsanträge fristgerecht einreichen können. Sollten Sie
              vorab Rückfragen haben, wenden Sie sich an support@node.energy
              <br />
              <br />
              Für weitere Informationen kontaktieren Sie uns bitte unter{" "}
              <a
                href="mailto:support@node.energy"
                rel="noreferrer"
                target="_blank"
              >
                support@node.energy
              </a>
            </>
          )
        },
        {
          toastId: "once"
        }
      );
    }
  }, [isBlockRueckerstattungP6EegActive]);

  function openGenerateCreditsModal() {
    setIsPreviewModal(false);
    setAvailableCreditsModalOpen(true);
  }

  function openPreviewCreditsModal() {
    setIsPreviewModal(true);
    setAvailableCreditsModalOpen(true);
  }

  return (
    <LoadOrError
      error={
        paragraph6ContractsError ||
        paragraph6CreatableCreditsError ||
        paymentInformationError
      }
      loading={paragraph6ContractsLoading || paymentInformationIsLoading}
      loadingMessage="Vertragsinformationen werden geladen ..."
    >
      <div className="Paragraph6CreditsPage">
        <Paragraph6AvailableCreditsModal
          contentType={
            usePaymentInformation
              ? FinancialDocumentType.PaymentInformation
              : FinancialDocumentType.Credit
          }
          contractNames={contractNames}
          creatableCredits={creatableCredits}
          createParagraph6Credits={createParagraph6Credits}
          fetchDraftCreditStatus={fetchParagraph6DraftCreditStatus}
          isOpen={availableCreditsModalOpen}
          previewMode={isPreviewModal}
          variantId={variantId}
          onClose={() => setAvailableCreditsModalOpen(false)}
        />
        <Stack gap="lg" mb="lg">
          {usePaymentInformation ? (
            <OptiSupportHelpLink
              optiSupportEndpoint={
                OptiSupportEndpoints.ZahlungsinformationenZurKommunalenBeteiligungNach6Eeg
              }
              text="Wie lassen sich mit opti.node Zahlungsinformationen erstellen?"
            />
          ) : (
            <OptiSupportHelpLink
              optiSupportEndpoint={
                OptiSupportEndpoints.KommunaleBeteiligungNachParagraph6Eeg
              }
              text="Wie lassen sich mit opti.node Gutschriften und Stornos erstellen?"
            />
          )}
          {shouldShowStaffView && (
            <Group justify="flex-end">
              <UsePaymentInformationToggleSwitch
                usePaymentInformation={!!usePaymentInformation}
                variantId={variantId}
              />
            </Group>
          )}
        </Stack>
        <div className="credits-controls">
          <Stack gap={0} style={{ alignSelf: "flex-end" }}>
            {isBlockRueckerstattungP6EegActive && (
              <span style={{ color: "var(--red)" }}>
                Diese Funktion ist zur Zeit deaktiviert.
              </span>
            )}
            <div className="credits-buttons">
              {usePaymentInformation ? (
                <Button
                  color="brand"
                  disabled={
                    isBlockRueckerstattungP6EegActive ||
                    !creatableCredits ||
                    creatableCredits.length === 0
                  }
                  onClick={openGenerateCreditsModal}
                >
                  Verfügbare Zahlungsinformationen
                </Button>
              ) : (
                <IconButton
                  color="brand"
                  disabled={
                    isBlockRueckerstattungP6EegActive ||
                    !creatableCredits ||
                    creatableCredits.length === 0
                  }
                  iconName={IconName.Plus}
                  onClick={openGenerateCreditsModal}
                >
                  Gutschriften erstellen
                </IconButton>
              )}
              <IconButton
                color={buttonColors.brand}
                iconName={IconName.Eye}
                outline
                onClick={openPreviewCreditsModal}
              >
                Vorschau
              </IconButton>
            </div>
          </Stack>
          {creditErrors && creditErrors.length > 0 && (
            <Paragraph6CreditErrorMessage
              contracts={paragraph6Contracts}
              paragraph6CreditErrors={creditErrors}
            />
          )}
        </div>
        <Paragraph6CreditsTable
          isPaymentInformationView={usePaymentInformation}
          variantId={variantId}
        />
      </div>
    </LoadOrError>
  );
}

export { Paragraph6CreditsPage, Paragraph6CreditsPageProps };
