import { ActionIcon, Group } from "@mantine/core";
import { useState } from "react";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import type { IconData } from "../../StructureViewFlowDiagram.types";
import { getStructureViewImageUrl } from "../../utils/getStructureViewImageUrl";
import "./IconSelectionModal.scss";

interface IconSelectionModalProps {
  isOpen?: boolean;
  icons: Array<IconData>;
  initialIconUrl?: string;
  iconColor?: string;
  onIconChange: (iconName: string) => void;
  onClose: () => void;
}

function IconSelectionModal({
  isOpen,
  icons,
  initialIconUrl,
  iconColor,
  onIconChange,
  onClose
}: IconSelectionModalProps) {
  const initialIcon = icons.find((i) => i.imageUrl === initialIconUrl);
  const [selectedIcon, setSelectedIcon] = useState(initialIcon);

  function handleModalCancel() {
    onClose();
    setSelectedIcon(initialIcon);
  }

  return (
    <BasicConfirmationModal
      className="IconSelectionModal"
      confirmationButtonText="Speichern"
      headerText="Icon wählen"
      isModalOpen={isOpen ?? false}
      toggleModal={handleModalCancel}
      onCancel={handleModalCancel}
      onConfirm={() => {
        if (selectedIcon) {
          onIconChange(selectedIcon.imageName);
        }
        onClose();
      }}
    >
      <Group gap="xs">
        {icons.map((icon) => (
          <ActionIcon
            aria-label={icon.imageName}
            className={
              icon.imageName === selectedIcon?.imageName
                ? "icon-selected"
                : undefined
            }
            key={icon.imageName}
            radius="xl"
            size="xl"
            variant="transparent"
            onClick={() =>
              setSelectedIcon(icons.find((i) => i.imageName === icon.imageName))
            }
          >
            <img
              alt={icon.imageName}
              src={getStructureViewImageUrl(icon.imageUrl, iconColor) ?? ""}
            />
          </ActionIcon>
        ))}
      </Group>
    </BasicConfirmationModal>
  );
}

export { IconSelectionModal };
