import {
  type ConnectionLineComponentProps,
  getSmoothStepPath
} from "@xyflow/react";
type CustomMeteringConnectionLineProps = ConnectionLineComponentProps;
function CustomMeteringConnectionLine({
  fromX,
  fromY,
  toX,
  toY,
  connectionLineStyle,
  fromHandle,
  toHandle
}: CustomMeteringConnectionLineProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    targetPosition: toHandle?.position,
    sourcePosition: fromHandle?.position
  });

  return (
    <g>
      <path d={edgePath} fill="none" style={connectionLineStyle} />
      <circle
        cx={toX}
        cy={toY}
        fill="black"
        r={3}
        stroke="black"
        strokeWidth={1.5}
      />
    </g>
  );
}

export { CustomMeteringConnectionLine };
