import type { ChangeEvent } from "react";
import { useState } from "react";
import { deleteFile } from "../../../utils/files/deleteFile";
import { uploadFileOrFiles } from "../../../utils/files/uploadFileOrFiles";
import { camelize } from "../../../utils/SnakeCamelConversion";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";

interface ExtraProps {
  hasImage: boolean;
  resourceId: number;
  resourceImageFieldName?: string;
  highlighted?: boolean;
  uploadUrl: (resourceId: number, fieldName?: string) => string;
  deleteUrl?: (resourceId: number, fieldName?: string) => string;
  imageType: string;
}

interface UploadWidgetProps extends ExtraProps {
  onDeleted: () => void;
  onUploaded: (url: string) => void;
  setErrorTexts: (errors: Array<string>) => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function UploadWidget({
  hasImage,
  resourceId,
  resourceImageFieldName,
  uploadUrl,
  deleteUrl,
  imageType,
  onDeleted,
  onUploaded,
  setErrorTexts
}: UploadWidgetProps) {
  const [uploading, setUploading] = useState(false);

  function handleClickUpload() {
    const input = document.getElementById(
      "uploadable-image-field-upload-input"
    );

    if (input) {
      input.click();
    }
  }

  async function handleUpload(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    setErrorTexts([]);

    if (file) {
      setUploading(true);
      try {
        const uploadedUrl = await uploadImage(file);
        onUploaded(uploadedUrl);
      } catch (error) {
        setErrorTexts(error.response?.data[camelize(imageType)] ?? []);
      } finally {
        setUploading(false);
      }
    }
  }

  async function handleClickDelete() {
    setUploading(true);
    try {
      await deleteImage();
      onDeleted();
    } catch (error) {
      setErrorTexts(error.response?.data[camelize(imageType)] ?? []);
    } finally {
      setUploading(false);
    }
  }

  async function uploadImage(file: File) {
    const url = resourceImageFieldName
      ? uploadUrl(resourceId, resourceImageFieldName)
      : uploadUrl(resourceId);
    const response = await uploadFileOrFiles<string>(file, url, imageType);

    return response.data;
  }

  async function deleteImage() {
    if (deleteUrl) {
      const url = resourceImageFieldName
        ? deleteUrl(resourceId, resourceImageFieldName)
        : deleteUrl(resourceId);
      const response = await deleteFile<string>(url, imageType);

      return response.data;
    }
  }

  return (
    <span>
      <SpinButton color="brand" spin={uploading} onClick={handleClickUpload}>
        Datei auswählen
        <input
          accept="image/*"
          hidden
          id="uploadable-image-field-upload-input"
          type="file"
          onChange={handleUpload}
        />
      </SpinButton>
      {hasImage && deleteUrl && (
        <Button
          className="delete-logo"
          color="danger"
          onClick={handleClickDelete}
        >
          Logo löschen
        </Button>
      )}
    </span>
  );
}

export { UploadWidget };
