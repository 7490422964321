import { decamelize as decamelizeFunction } from "./SnakeCamelConversion";

/** Decamelize some unknown piece of data, unless it's a File
 * (then just return it as-is, to avoid breaking the file) */
export function decamelizeWithoutBreakingFile(
  data: unknown,
  skipDecamelize?: boolean
) {
  function decamelize(key: string) {
    return skipDecamelize ? key : decamelizeFunction(key);
  }

  if (!data || data instanceof File) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) =>
      decamelizeWithoutBreakingFile(item, skipDecamelize)
    );
  }

  // in all cases where we send FormData to the backend, we set the data to the formDataObject property
  if (
    data instanceof FormData &&
    "formDataObject" in data &&
    typeof data.formDataObject === "object" &&
    data.formDataObject
  ) {
    const formData = new FormData();
    // workaround for edge < 18 because formData has no way to traverse the actual data.
    for (const [key, value] of Object.entries(data.formDataObject)) {
      if (Array.isArray(value)) {
        for (const entry of value) {
          formData.append(decamelize(key), entry);
        }
      } else {
        formData.append(decamelize(key), value as FormDataEntryValue);
      }
    }

    return formData;
  }

  if (typeof data === "object") {
    return Object.keys(data).reduce(
      (acc, next) => ({
        ...acc,
        [decamelize(next)]: decamelizeWithoutBreakingFile(
          data[next],
          skipDecamelize
        )
      }),
      {}
    );
  }

  return data;
}
