import { Title } from "@mantine/core";
import classNames from "classnames";
import { useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import { AlertColor } from "../../../Alert/Alert";
import {
  DatePresetType,
  DateRangePicker
} from "../../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import { FormField } from "../../../BuildingBlocks/Forms/FormField/FormField";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useParkabrechnungPoolContracts } from "../../hooks/useParkabrechnungPoolContracts";
import { FeedinDownloadButton } from "./FeedinDownloadButton/FeedinDownloadButton";
import { useCanExportParkabrechnungFeedinProceeds } from "./hooks/useCanExportParkabrechnungFeedinProceeds";
import "./FeedinPage.scss";

interface FeedinPageProps {
  projectId: string;
  variantId: number;
}

function FeedinPage({ projectId, variantId }: FeedinPageProps) {
  const { contracts, isLoading, error } =
    useParkabrechnungPoolContracts(variantId);
  const [selectedContractId, setSelectedContractId] = useState<string | null>(
    null
  );
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const canDownload = useCanExportParkabrechnungFeedinProceeds(
    selectedContractId,
    startDate,
    endDate
  );
  const energyDataPath = generatePath(ROUTES.energyData, {
    projectId
  });

  const contractChoices =
    contracts?.map<Choice<string>>((contract) => ({
      displayName: contract.name,
      value: contract.id
    })) ?? [];

  return (
    <div className={classNames("FeedinPage", { loading: isLoading })}>
      <div className="controls">
        <LoadOrError error={error} loading={isLoading}>
          <FormField
            choices={contractChoices}
            className="contract-dropdown"
            label="Vertrag"
            name="contract-dropdown"
            placeholder="Vertrag auswählen"
            type="dropdown"
            value={selectedContractId ?? undefined}
            onChange={(value: string) => setSelectedContractId(value)}
          />
          <div className="range-date-picker-wrapper">
            <Title fw={500} order={5} py="xs" size="sm">
              Dargestellte Tage
            </Title>
            <DateRangePicker
              endDateValue={endDate}
              presets={DatePresetType.MonthYear}
              startDateValue={startDate}
              onChangeEndDateValue={(value) => setEndDate(value)}
              onChangeStartDateValue={(value) => setStartDate(value)}
            />
          </div>
          <FeedinDownloadButton
            contractId={selectedContractId}
            disabled={!canDownload}
            endDate={endDate}
            startDate={startDate}
          />
        </LoadOrError>
      </div>
      {canDownload === false && (
        <IconAlert color={AlertColor.Warning}>
          <h5>Energiedaten unvollständig</h5>
          <p>
            Leider liegen die Energiedaten für den ausgewählten Zeitraum nicht
            vollständig vor. Ergänzen Sie bitte die fehlenden{" "}
            <Link to={energyDataPath}>Energiedaten</Link>.
          </p>
        </IconAlert>
      )}
    </div>
  );
}

export { FeedinPage, FeedinPageProps };
