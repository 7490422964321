import { Text } from "@mantine/core";
import { DateTime } from "luxon";
import {
  DatePresetType,
  UncontrolledDateRangePicker
} from "../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";

export const IMPORT_RUNNING_TEXT =
  "Die historischen Messdaten werden nun automatisch von Ihrem Untermesssystem importiert. Dieser Prozess läuft im Hintergrund und kann einige Minuten dauern.";

interface HistoricalDataImportProps {
  onDatesChanged: (startDate: DateTime, endDate: DateTime) => void;
}

function HistoricalDataImport({ onDatesChanged }: HistoricalDataImportProps) {
  function handleDatePickerChange({
    startDate,
    endDate
  }: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    // don't allow empty range
    if (startDate && endDate) {
      onDatesChanged(startDate, endDate);
    }
  }

  return (
    <>
      <Text my="xs">Für welchen Zeitraum sollen Werte abgefragt werden?</Text>
      <UncontrolledDateRangePicker
        maxDate={DateTime.now().minus({ days: 1 })}
        presets={DatePresetType.MonthYear}
        onChange={handleDatePickerChange}
      />
    </>
  );
}

export { HistoricalDataImport, HistoricalDataImportProps };
