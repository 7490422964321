import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

interface IconWithLinkProps extends IconProps {
  to?: string;
}

function IconWithLink({ to, ...props }: IconWithLinkProps) {
  return (
    <a href={to}>
      <Icon {...props} />
    </a>
  );
}

export { IconWithLink };
