import ReactDOM from "react-dom";
import { Button } from "../../Buttons/Button/Button";

interface WizardSuccessWidgetProps extends WizardSuccessWidgetCloseButtonProps {
  message: string;
}

function WizardSuccessWidget({
  message,
  buttonContainer,
  onClose,
  buttonText = "Schließen"
}: WizardSuccessWidgetProps) {
  return (
    <div className="WizardSuccessWidget">
      <p>{message}</p>
      <WizardSuccessWidgetCloseButton
        buttonContainer={buttonContainer}
        buttonText={buttonText}
        onClose={onClose}
      />
    </div>
  );
}

interface WizardSuccessWidgetCloseButtonProps {
  buttonContainer?: HTMLElement;
  buttonText?: string;
  onClose: () => void;
}

function WizardSuccessWidgetCloseButton({
  buttonContainer,
  onClose,
  buttonText
}: WizardSuccessWidgetCloseButtonProps) {
  return (
    <>
      {buttonContainer &&
        ReactDOM.createPortal(
          <>
            <Button className="close-button" color="primary" onClick={onClose}>
              {buttonText}
            </Button>
          </>,
          buttonContainer
        )}
    </>
  );
}

export { WizardSuccessWidget };
