import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { ConfigurationData } from "../ThirdPartySystems.types";

export function useSubMeteringConfigurations(variantId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["sub-metering-configurations", variantId],
    queryFn: () => fetchSubMeteringConfigurations(variantId)
  });

  async function fetchSubMeteringConfigurations(variantId: number) {
    const url = urls.api.subMeteringConfigurations(variantId);
    const response = await api.get<Array<ConfigurationData>>(url);

    return response.data;
  }

  return { data, isLoading, error };
}
