import { Button, Group, Title } from "@mantine/core";
import { IconArrowLeft, IconHistory } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import type { HistoryViewBacklinkType } from "../../../HistoryView/utils/useHistoryViewBacklink";

interface PageHeaderProps {
  title: string;
  historyButtonUrl?: string;
  backButton?: HistoryViewBacklinkType;
}

function PageHeader({ title, historyButtonUrl, backButton }: PageHeaderProps) {
  return (
    <Group justify="space-between" mb={24}>
      <Title
        c="#3f4047"
        ff={"Roboto, sans-serif"}
        fw={500}
        fz={"1.55em"}
        order={3}
      >
        {title}
      </Title>
      {historyButtonUrl && (
        <Button
          component={Link}
          leftSection={<IconHistory size={"1rem"} />}
          size="xs"
          to={historyButtonUrl}
          variant="outline"
        >
          Historie
        </Button>
      )}

      {backButton?.url && (
        <Button
          component={Link}
          leftSection={<IconArrowLeft size={"1rem"} />}
          size="xs"
          to={backButton.url}
          variant="outline"
        >
          {backButton.text || "Zurück"}
        </Button>
      )}
    </Group>
  );
}

export { PageHeader, PageHeaderProps };
