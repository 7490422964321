import { Tabs } from "@mantine/core";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { Frequency } from "../../../utils/enums";
import { PageContent } from "../../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../../BuildingBlocks/Layout/PageHeader/PageHeader";
import { INITIAL_YEAR } from "../common";
import { EnergyDataAvailabilityPage } from "./EnergyDataAvailabilityPage/EnergyDataAvailabilityPage";
import { EnergyDataPage } from "./EnergyDataPage/EnergyDataPage";
import { EnergyDataUploadPage } from "./EnergyDataUploadPage/EnergyDataUploadPage";
import { MachineAccountPage } from "./MachineAccountPage/MachineAccountPage";
import { RawEnergyDataPage } from "./RawEnergyDataPage/RawEnergyDataPage";
import { ThirdPartySystemsTabContent } from "./ThirdPartySystemsTabContent/ThirdPartySystemsTabContent";
import "./EnergyDataView.scss";

export const TABS = {
  TAB_ENERGY_DATA_AVAILABILITY: "verfuegbarkeit",
  TAB_ENERGY_DATA_UPLOAD: "bereitstellen",
  TAB_THIRD_PARTY_SYSTEMS: "untermesssysteme",
  TAB_RAW_ENERGY_DATA: "zaehlerdaten",
  TAB_ENERGY_DATA: "berechnete-energiedaten",
  TAB_MACHINE_ACCOUNT: "machine-account"
} as const;

export const DEFAULT_SUBPAGE = TABS.TAB_ENERGY_DATA_AVAILABILITY;

export interface Acquisition {
  components: Array<string>;
  connections: Array<string>;
  detailLabel: string;
  frequency: Frequency;
  id: number;
  label: string;
  medium: string;
  origin: string;
  publicId: number | null;
  site: string;
  statement: string | null;
  tags: Array<Tag>;
  type: string;
}

export interface Tag {
  color: string;
  fontColor: string;
  longName: string;
  name: string;
}

export interface AcquisitionFilter {
  options: Array<string | { min: number; max: number }>;
  type: string;
}

export interface EnergyDataViewProps {
  variantId: number;
}

function EnergyDataView({ variantId }: EnergyDataViewProps) {
  const navigate = useNavigate();
  const routerParams = useParams();

  const [availabilityYear, setAvailabilityYear] = useState(INITIAL_YEAR);

  return (
    <PageContent className="EnergyDataView">
      <PageHeader title="Energiedaten" />

      <Tabs
        className="energydata-tabs"
        keepMounted={false}
        value={routerParams.tab}
        variant="pills"
        onChange={(value) => {
          let navTarget = `../${value}`;

          // use year from availability tab when switching to raw energy data & energy data tabs
          if (
            value === TABS.TAB_RAW_ENERGY_DATA ||
            value === TABS.TAB_ENERGY_DATA
          ) {
            const yearDate = DateTime.fromISO(`${availabilityYear}-01-01`);

            const yearStart = yearDate.toISODate();
            const yearEnd = yearDate.endOf("year").toISODate();

            navTarget += `?firstDate=${yearStart}&lastDate=${yearEnd}`;
          }

          // navigating from a nested subpage needs to add one extra `../` to the relative path
          if (
            routerParams.tab &&
            !routerParams["*"]?.includes(routerParams.tab)
          ) {
            navTarget = `../${navTarget}`;
          }

          navigate(navTarget);
        }}
      >
        <Tabs.List>
          <Tabs.Tab value={TABS.TAB_ENERGY_DATA_AVAILABILITY}>
            Verfügbarkeit
          </Tabs.Tab>
          <Tabs.Tab value={TABS.TAB_ENERGY_DATA_UPLOAD}>Bereitstellen</Tabs.Tab>
          <Tabs.Tab value={TABS.TAB_THIRD_PARTY_SYSTEMS}>
            Untermesssysteme
          </Tabs.Tab>
          <Tabs.Tab value={TABS.TAB_RAW_ENERGY_DATA}>
            Zähler- und Lokationsdaten
          </Tabs.Tab>
          <Tabs.Tab value={TABS.TAB_ENERGY_DATA}>
            Berechnete Energiedaten
          </Tabs.Tab>
          <Tabs.Tab value={TABS.TAB_MACHINE_ACCOUNT}>Machine Account</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TABS.TAB_ENERGY_DATA_AVAILABILITY}>
          <EnergyDataAvailabilityPage
            variantId={variantId}
            year={availabilityYear}
            onYearChange={setAvailabilityYear}
          />
        </Tabs.Panel>
        <Tabs.Panel value={TABS.TAB_ENERGY_DATA_UPLOAD}>
          <EnergyDataUploadPage variantId={variantId} />
        </Tabs.Panel>
        <Tabs.Panel value={TABS.TAB_THIRD_PARTY_SYSTEMS}>
          <ThirdPartySystemsTabContent variantId={variantId} />
        </Tabs.Panel>
        <Tabs.Panel value={TABS.TAB_RAW_ENERGY_DATA}>
          <RawEnergyDataPage variantId={variantId} />
        </Tabs.Panel>
        <Tabs.Panel value={TABS.TAB_ENERGY_DATA}>
          <EnergyDataPage variantId={variantId} />
        </Tabs.Panel>
        <Tabs.Panel value={TABS.TAB_MACHINE_ACCOUNT}>
          <MachineAccountPage variantId={variantId} />
        </Tabs.Panel>
      </Tabs>
    </PageContent>
  );
}

export { EnergyDataView };
