import { type ChartSeries } from "@mantine/charts";
import { ColorSwatch, Stack, Text } from "@mantine/core";
import classNames from "classnames";
import { Button } from "../../Buttons/Button/Button";
import "./MantineChartLegend.scss";

interface MantineChartLegendProps {
  series: Array<ChartSeries & { color: string }>;
  activeSeries: Array<string>;
  onClick: (seriesId: string) => void;
}

/*  Just like for the tooltip, Mantine doesn't allow changes to the recharts legend */
function MantineChartLegend({
  series,
  activeSeries,
  onClick
}: MantineChartLegendProps) {
  return (
    <Stack className="ChartLegend" gap={0} mah={500} pl="md" w="20%">
      {series.map((singleSeries) => {
        const isActive = !activeSeries.includes(singleSeries.name);
        return (
          <Button
            className={classNames(
              "legend-item",
              isActive ? "active" : "inactive"
            )}
            key={singleSeries.name}
            noBackground
            noBorder
            onClick={() => onClick(singleSeries.name)}
          >
            <ColorSwatch
              bd={isActive ? "none" : `2px solid ${singleSeries.color}`}
              color={isActive ? singleSeries.color : "#fff"}
              size="var(--mantine-font-size-md)"
            />
            <Text className="legend-label" ms="sm">
              {singleSeries.label || singleSeries.name}
            </Text>
          </Button>
        );
      })}
    </Stack>
  );
}

export { MantineChartLegend };
