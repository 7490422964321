import {
  Group,
  RadioGroup as MantineRadioGroup,
  type RadioGroupProps as MantineRadioGroupProps
} from "@mantine/core";
import classNames from "classnames";
import { forwardRef, useId } from "react";
interface RadioGroupProps
  extends Pick<
    MantineRadioGroupProps,
    "aria-label" | "id" | "name" | "value" | "children"
  > {
  className?: string;
  errorText?: string;
  invalid?: boolean;
  layout?: "horizontal" | "vertical";
  useCompactErrorMessage?: boolean;
  warned?: boolean;
  onChange: (newValue: string | null) => void;
}

function RadioGroup(
  {
    "aria-label": ariaLabel,
    className,
    errorText,
    id,
    invalid,
    layout = "horizontal",
    name,
    useCompactErrorMessage,
    children,
    warned,
    value,
    onChange
  }: RadioGroupProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const backupId = useId();
  const radioGroupId = id || backupId;

  return (
    <div
      className={classNames("RadioGroup", className, {
        "is-invalid": invalid,
        warning: !invalid && warned
      })}
    >
      <MantineRadioGroup
        aria-label={ariaLabel}
        error={useCompactErrorMessage ? undefined : errorText}
        id={radioGroupId}
        name={name}
        ref={ref}
        value={value}
        onChange={onChange}
      >
        {layout === "horizontal" ? <Group>{children}</Group> : children}
      </MantineRadioGroup>
    </div>
  );
}

const ForwardedRefRadioGroup = forwardRef(RadioGroup);

export { ForwardedRefRadioGroup as RadioGroup, RadioGroupProps };
