import classNames from "classnames";
import "./PageContent.scss";

function PageContent({
  className,
  children
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames("PageContent", className)}>{children}</div>;
}

export { PageContent };
