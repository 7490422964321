import { Accordion } from "@mantine/core";
import { useMemo, useState } from "react";
import type { Column } from "react-table";
import { bannerDefaultOptions, showToast } from "../../../../utils/toast";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { SwissArmyTable } from "../../../SwissArmyTable/SwissArmyTable";
import { useParagraph6ContractMutations } from "../../hooks/useParagraph6ContractMutations";
import { useParagraph6ErzeugerAnteilMutations } from "../../hooks/useParagraph6ErzeugerAnteilMutations";
import { useParagraph6UnassignedGenerators } from "../../hooks/useParagraph6UnassignedGenerators";
import type { Paragraph6ContractResponse } from "../../Paragraph6.types";
import { Paragraph6ContractTitle } from "./Paragraph6ContractTitle/Paragraph6ContractTitle";
import "./Paragraph6ContractInformation.scss";

const DEFAULT_SORTED = [
  {
    id: "generator_display_name",
    desc: false
  }
];

type Paragraph6ContractInformationProps = {
  contract: Paragraph6ContractResponse;
  onClickEditContract: (contractId: string) => void;
  onClickNewGeneratorShare: (contractId: string) => void;
  onClickEditGeneratorShare: (generatorShareId: number) => void;
};

function Paragraph6ContractInformation({
  contract,
  onClickEditContract,
  onClickNewGeneratorShare,
  onClickEditGeneratorShare
}: Paragraph6ContractInformationProps) {
  const generatorShares = contract.erzeuger_anteile || [];

  const generatorSharesWithId = generatorShares.map((generatorShare) => ({
    ...generatorShare,
    id: generatorShare.generator
  }));

  const getNamesOfGeneratorSharesToDelete = (
    generatorShares: typeof generatorSharesWithId
  ) => {
    return generatorShares
      .map((generatorShare) => generatorShare.generator_display_name)
      .filter((generatorName) => generatorName !== undefined);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        Header: "Erzeuger",
        accessor: "generator_display_name",
        sortMethod: (a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true
          });
        }
      },
      {
        Header: "Einspeisezeitreihe: Zählpunkt",
        accessor: "metering_or_market_location_display_name"
      },
      {
        Header: "Einspeisezeitreihe: Anteil",
        accessor: "weighting",
        Cell: (cell) => <div>{cell.value}</div>
      },
      {
        Header: "Anteil Gemeinde",
        accessor: "community_share",
        Cell: (cell) => <div>{cell.value}</div>
      },
      {
        Header: "Anzulegender Wert in ct/kWh",
        accessor: "anzulegender_wert",
        Cell: (cell) => <div>{cell.value} ct/kWh</div>,
        sortMethod: (a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true
          });
        }
      }
    ] satisfies Array<Column>;
  }, []);

  const {
    paragraph6UnassignedGenerators,
    isLoading: paragraph6UnassignedGeneratorsLoading
  } = useParagraph6UnassignedGenerators(contract.id);

  const { paragraph6ContractEditMutation, paragraph6ContractDeleteMutation } =
    useParagraph6ContractMutations(contract.variant);

  const { paragraph6ErzeugerAnteilDeleteMutation } =
    useParagraph6ErzeugerAnteilMutations(contract.variant, contract.id);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  async function deleteContract() {
    const deletePromise = paragraph6ContractDeleteMutation.mutateAsync(
      contract.id,
      {
        onError: (error) => {
          showToast("error", error, bannerDefaultOptions);
        },
        onSettled: () => {
          setDeleteModalOpen(false);
        }
      }
    );

    return Promise.all([deletePromise]).then(() => {});
  }

  function toggleContractTemplate() {
    contract.is_template = !contract.is_template;
    paragraph6ContractEditMutation.mutateAsync(contract);
  }

  return (
    <>
      <Accordion.Item
        className="Paragraph6ContractInformation"
        value={contract.id}
      >
        <Accordion.Control>
          <Paragraph6ContractTitle
            generatorCount={generatorShares.length}
            id={contract.id}
            isTemplate={contract.is_template}
            name={contract.name}
            onClickDelete={() => setDeleteModalOpen(true)}
            onClickEdit={() => onClickEditContract(contract.id)}
            onClickMarkAsTemplate={toggleContractTemplate}
          />
        </Accordion.Control>
        <Accordion.Panel>
          <LoadOrError
            loading={paragraph6UnassignedGeneratorsLoading}
            loadingMessage="Vertragsinformationen werden geladen ..."
          >
            <SwissArmyTable
              data={generatorSharesWithId}
              defaultSorted={DEFAULT_SORTED}
              options={{
                buttons: {
                  create: {
                    iconButton: true,
                    isPrimary: false,
                    isTertiary: true,
                    path: undefined,
                    disabled:
                      !paragraph6UnassignedGenerators ||
                      paragraph6UnassignedGenerators.length === 0,
                    onClick: () => onClickNewGeneratorShare(contract.id)
                  },
                  delete: {
                    iconButton: true
                  },
                  edit: {
                    onClick: (rowData) => {
                      onClickEditGeneratorShare(Number(rowData.id));
                    }
                  }
                },
                deletion: {
                  confirmationHeader:
                    "Wollen Sie die folgenden Erzeuger löschen?",
                  confirmationText:
                    "Dieser Schritt kann nicht rückgängig gemacht werden.",
                  deleteMutation: paragraph6ErzeugerAnteilDeleteMutation,
                  namesToDeleteCollectionFunction:
                    getNamesOfGeneratorSharesToDelete
                },
                pluralWord: "Erzeuger",
                singleWord: "Erzeuger"
              }}
              tableColumns={tableColumns}
            />
          </LoadOrError>
        </Accordion.Panel>
      </Accordion.Item>
      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={[contract.name]}
        confirmationText="Ihr Vertrag kann anschließend nicht wiederhergestellt werden."
        headerText="Wollen Sie diesen Vertrag löschen?"
        isModalOpen={deleteModalOpen}
        objectName="Vertrag"
        toggleModal={() => setDeleteModalOpen(false)}
        onAction={deleteContract}
      />
    </>
  );
}

export { Paragraph6ContractInformation, Paragraph6ContractInformationProps };
