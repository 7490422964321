import { Row } from "reactstrap";
import { IconHelpText } from "../../IconHelpText/IconHelpText";

import "./siteTasksTitle.scss";

type SiteTasksTitleProps = {
  title: string;
  helpText: string;
};

function SiteTasksTitle({ title, helpText }: SiteTasksTitleProps) {
  return (
    <Row className="site-tasks-title-container">
      <span className="site-tasks-title">{title}</span>
      <div
        className="site-tasks-help-container"
        onClick={(e) => e.stopPropagation()}
      >
        <IconHelpText helpText={helpText} />
      </div>
    </Row>
  );
}

export { SiteTasksTitle };
