import type { CellContext, ColumnDef } from "@tanstack/react-table";
import type { Payment } from "../../../../../../utils/backend-types";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import { NumberCell } from "../Cells/NumberCell";
import type { UpdatePaymentsValue, Value } from "../PaymentsTab";

function getMadeAdvancePaymentsColumn(
  updatePaymentsValue: UpdatePaymentsValue,
  onImportPaymentsValue: (year: number) => Promise<number>
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Geleistet (Brutto){" "}
        <IconHelpText
          helpText="Erfassen Sie in diesem Jahr geleistete Abschlagszahlungen.
                    (Nur relevant bei monatlichen Abschlägen und Jahresendabrechnung)"
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "madeAdvancePayments",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <NumberCell
        importValue={() => onImportPaymentsValue(cellInfo.row.original.year)}
        key={cellInfo.row.original.year}
        unit="€"
        updateValue={(value: Value) =>
          updatePaymentsValue(
            value,
            {
              year: cellInfo.row.original.year,
              month: cellInfo.row.original.month
            },
            "madeAdvancePayments"
          )
        }
        value={cellInfo.getValue()}
      />
    )
  };
}

export { getMadeAdvancePaymentsColumn };
