import type {
  CellContext,
  ColumnDef,
  Row,
  SortingState
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type { Delivery, Payment } from "../../../../../utils/backend-types";
import { PaymentIntervalAndBillingMethod } from "../../../../../utils/backend-types";
import { MONTH_CHOICES } from "../../../../../utils/dates";
import { Button } from "../../../../Buttons/Button/Button";
import type { SelectItem } from "../../../../Select/Select";
import { Select } from "../../../../Select/Select";
import { Table } from "../../../../Table/Table";
import { getDeliveryAmountPredictionThisYearColumn } from "./Columns/DeliveryAmountPredictionThisYearColumn";
import { getDeliverySharePredictionColumn } from "./Columns/DirectDeliverySharePredictionColumn";
import { getDiscountPaymentsColumn } from "./Columns/DiscountPaymentsColumn";
import { getEstimatedAdvancePayments } from "./Columns/EstimatedAdvancePaymentsColumn";
import { getMadeAdvancePaymentsColumn } from "./Columns/MadeAdvancePaymentsColumn";
import { getPendingPaymentsColumn } from "./Columns/PendingPaymentsColumn";
import "./PaymentsTab.scss";
import { PaymentsWithReasonModal } from "./PaymentsWithReasonModal/PaymentsWithReasonModal";

const COL_ID_YEAR = "year";
const COL_ID_MONTH = "month";
const COL_ID_INVOICE = "invoice";
const COL_ID_PAYMENTS = "payments";

const DEFAULT_SORTED: SortingState = [
  { id: COL_ID_YEAR, desc: true },
  { id: COL_ID_MONTH, desc: true }
];

export type Value = number | string | null;
export type UpdatePaymentsValue = (
  value: Value,
  date: PaymentDate,
  objKey: string
) => void;

export type PaymentDate = {
  year: number | null;
  month: number | null;
};

interface PaymentsTabProps {
  billingMethodAndPeriod: PaymentIntervalAndBillingMethod;
  contractId: string;
  deliveries: Delivery[];
  paymentsData?: Array<Payment>;
  updatePaymentsValue: UpdatePaymentsValue;
  addPayment: (hasRlmDeliveries: boolean) => void;
}

function PaymentsTab({
  billingMethodAndPeriod,
  contractId,
  deliveries,
  paymentsData,
  updatePaymentsValue,
  addPayment
}: PaymentsTabProps) {
  const [discountPaymentsActiveDate, setDiscountPaymentsActiveDate] =
    useState<PaymentDate>({ year: null, month: null });
  const [pendingPaymentsActiveDate, setPendingPaymentsActiveDate] =
    useState<PaymentDate>({ year: null, month: null });
  const [yearFilter, setYearFilter] = useState<number | null>(null);

  const isAnnuallyBilled =
    billingMethodAndPeriod ===
    PaymentIntervalAndBillingMethod.AnnualBasedOnMeasurements;

  const isMonthlyBilled =
    billingMethodAndPeriod ===
    PaymentIntervalAndBillingMethod.MonthlyBasedOnMeasurements;

  const hasRlmDeliveries =
    deliveries.find((delivery) => delivery.gridUsageBillingModel === "rlm") !==
    undefined;

  function handleClickToggleDiscountPaymentsModal(date: PaymentDate) {
    setDiscountPaymentsActiveDate(date);
  }

  function handleClickTogglePendingPaymentsModal(date: PaymentDate) {
    setPendingPaymentsActiveDate(date);
  }

  const handleImportPaymentsValue = useCallback(
    (year: number) => {
      /* only for year since there is no payment plan for monthly billed contracts */
      return api
        .get<number>(
          urls.api.contractGetPaymentsSumFromPaymentsPlan(contractId, year)
        )
        .then((response) => response.data);
    },
    [contractId]
  );

  const paymentColumns: Array<ColumnDef<Payment>> = useMemo(() => {
    const yearColumn: ColumnDef<Payment> = {
      accessorKey: "year",
      id: COL_ID_YEAR,
      header: "Jahr",
      cell: (info: CellContext<Payment, number>) => (
        <p className="year">{info.getValue()}</p>
      )
    };

    const monthColumn: ColumnDef<Payment> = {
      id: COL_ID_MONTH,
      accessorKey: "month",
      header: "Monat",
      sortingFn: (a: Row<Payment>, b: Row<Payment>) =>
        a.getValue("month") === null
          ? 1
          : b.getValue("month") === null
            ? -1
            : // parsing required - see https://github.com/TanStack/table/issues/4142
              parseInt(a.getValue("month")) - parseInt(b.getValue("month")),
      cell: (info: CellContext<Payment, number>) => (
        <p className="month">
          {
            MONTH_CHOICES.find((month) => month.value === info.getValue())
              ?.displayName
          }
        </p>
      )
    };

    const invoiceColumn: ColumnDef<Payment> = {
      id: COL_ID_INVOICE,
      header: "Rechnung",
      enableSorting: false,
      columns:
        isAnnuallyBilled || isMonthlyBilled
          ? [
              getPendingPaymentsColumn(handleClickTogglePendingPaymentsModal),
              getDiscountPaymentsColumn(handleClickToggleDiscountPaymentsModal)
            ]
          : [
              getMadeAdvancePaymentsColumn(
                updatePaymentsValue,
                handleImportPaymentsValue
              ),
              getPendingPaymentsColumn(handleClickTogglePendingPaymentsModal),
              getDiscountPaymentsColumn(handleClickToggleDiscountPaymentsModal)
            ]
    };

    const advancePaymentsColumn: ColumnDef<Payment> = {
      id: COL_ID_PAYMENTS,
      header: "Abschlagsplan",
      enableSorting: false,
      columns: [
        getEstimatedAdvancePayments(updatePaymentsValue),
        getDeliveryAmountPredictionThisYearColumn(updatePaymentsValue),
        getDeliverySharePredictionColumn(updatePaymentsValue)
      ]
    };

    return [yearColumn, monthColumn, invoiceColumn, advancePaymentsColumn];
  }, [
    isAnnuallyBilled,
    isMonthlyBilled,
    updatePaymentsValue,
    handleImportPaymentsValue
  ]);

  function getDiscountValueFromYear(date: PaymentDate) {
    return (
      paymentsData?.find(
        (payment) => payment.year === date.year && payment.month === date.month
      )?.discountPayments ?? null
    );
  }

  function getDiscountDescriptionFromYear(date: PaymentDate) {
    return (
      paymentsData?.find(
        (payment) => payment.year === date.year && payment.month === date.month
      )?.discountDescription ?? null
    );
  }

  function getPendingValueFromYear(date: PaymentDate) {
    return (
      paymentsData?.find(
        (payment) => payment.year === date.year && payment.month === date.month
      )?.pendingPayments ?? null
    );
  }

  function getPendingDescriptionFromYear(date: PaymentDate) {
    return (
      paymentsData?.find(
        (payment) => payment.year === date.year && payment.month === date.month
      )?.pendingPaymentsDescription ?? null
    );
  }

  function handleAddPayment() {
    addPayment(hasRlmDeliveries);
  }

  const allYears: SelectItem<number | null> = {
    key: null,
    value: null,
    text: "Alle Jahre"
  };
  const years: SelectItem<number | null>[] = paymentsData
    ? Array.from(new Set(paymentsData.map((pd) => pd.year)))
        .sort((a, b) => b - a)
        .map((pd) => ({
          key: pd,
          value: pd,
          text: pd.toString()
        }))
    : [];
  const allSelectItems: SelectItem<number | null>[] = [allYears].concat(years);

  const filteredPaymentsData: Payment[] = paymentsData
    ? paymentsData.filter((pd) => pd.year === yearFilter)
    : [];

  return (
    <>
      <div className="payments controls">
        {isMonthlyBilled ? (
          <Select
            defaultValue={null}
            items={allSelectItems}
            onChange={setYearFilter}
          />
        ) : (
          <div />
        )}
        <Button
          className="addRowButton"
          color="brand"
          onClick={handleAddPayment}
        >
          Eintrag hinzufügen
        </Button>
      </div>
      {paymentsData && (
        <>
          <Table
            className="payments-table"
            NoDataComponent={NoDataComponent}
            options={{
              columns: paymentColumns,
              data: yearFilter !== null ? filteredPaymentsData : paymentsData,
              initialState: {
                sorting: DEFAULT_SORTED,
                columnVisibility: {
                  [COL_ID_YEAR]: true,
                  [COL_ID_MONTH]: isMonthlyBilled,
                  [COL_ID_INVOICE]: true,
                  [COL_ID_PAYMENTS]: !isAnnuallyBilled && !isMonthlyBilled
                }
              },
              showPagination: true
            }}
          />
          {discountPaymentsActiveDate.year && (
            <PaymentsWithReasonModal
              activeDate={discountPaymentsActiveDate}
              headerText="Rabatt/Bonus"
              reason={getDiscountDescriptionFromYear(
                discountPaymentsActiveDate
              )}
              reasonFieldName="discountDescription"
              updatePaymentsValue={updatePaymentsValue}
              value={getDiscountValueFromYear(discountPaymentsActiveDate)}
              valueFieldName="discountPayments"
              onCloseModal={() =>
                handleClickToggleDiscountPaymentsModal({
                  year: null,
                  month: null
                })
              }
            />
          )}
          {pendingPaymentsActiveDate.year && (
            <PaymentsWithReasonModal
              activeDate={pendingPaymentsActiveDate}
              headerText="Offen (Brutto)"
              reason={getPendingDescriptionFromYear(pendingPaymentsActiveDate)}
              reasonFieldName="pendingPaymentsDescription"
              updatePaymentsValue={updatePaymentsValue}
              value={getPendingValueFromYear(pendingPaymentsActiveDate)}
              valueFieldName="pendingPayments"
              onCloseModal={() =>
                handleClickTogglePendingPaymentsModal({
                  year: null,
                  month: null
                })
              }
            />
          )}
        </>
      )}
    </>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Abschlagsinformationen vorhanden.</p>
    </div>
  );
}

export { PaymentsTab, PaymentsTabProps };
