import { getStraightPath, useInternalNode } from "@xyflow/react";
import type { CSSProperties } from "react";
import { getEdgeParams } from "../../utils/getEdgeParams";

interface FloatingEdgeProps {
  id: string;
  source: string;
  target: string;
  markerStart?: string;
  markerEnd?: string;
  style?: CSSProperties;
}
function FloatingEdge({
  id,
  source,
  target,
  markerStart,
  markerEnd,
  style
}: FloatingEdgeProps) {
  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty
  });

  return (
    <path
      className="react-flow__edge-path"
      d={edgePath}
      id={id}
      markerEnd={markerEnd}
      markerStart={markerStart}
      style={style}
    />
  );
}

export { FloatingEdge };
