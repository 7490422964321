import { useMatch, useResolvedPath } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import type { Delivery } from "../../../../../utils/backend-types";
import { buildFieldNameToLabelMap } from "../../../../../utils/react-hook-form/buildFieldNameToLabelMap";
import type { TabData } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { HistoryTab } from "../../../../HistoryTab/HistoryTab";
import type { DeliveryFormProps } from "../DeliveryForm/DeliveryForm";
import { DeliveryForm } from "../DeliveryForm/DeliveryForm";
import { DELIVERY_FORM_FIELD_DATA } from "../DeliveryForm/DeliveryForm.constants";

export const DELIVERY_TABS = {
  TAB_GENERAL: "allgemein",
  TAB_HISTORY: "historie"
};

const TABS: Array<TabData> = [
  {
    id: DELIVERY_TABS.TAB_GENERAL,
    label: "Allgemein"
  },
  {
    id: DELIVERY_TABS.TAB_HISTORY,
    label: "Historie"
  }
];

interface EditFormProps extends DeliveryFormProps {
  delivery: Delivery;
}

function EditForm({
  buttonContainer,
  delivery,
  options,
  onCancel,
  onSubmit,
  missingFields,
  shouldFieldBeHighlighted
}: EditFormProps) {
  const resolved = useResolvedPath(":activeTab");
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeTab = match?.params.activeTab ?? DELIVERY_TABS.TAB_GENERAL;
  const fieldNameToLabelMap = buildFieldNameToLabelMap<Delivery>(
    DELIVERY_FORM_FIELD_DATA
  );

  return (
    <div className="EditForm">
      <TabBar activeTab={activeTab} firstTabIsIndex tabs={TABS} />
      <TabContent activeTab={activeTab}>
        <TabPane className="general-tab" tabId={DELIVERY_TABS.TAB_GENERAL}>
          <DeliveryForm
            buttonContainer={buttonContainer}
            delivery={delivery}
            missingFields={missingFields}
            options={options}
            shouldFieldBeHighlighted={shouldFieldBeHighlighted}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </TabPane>
        <TabPane className="history-tab" tabId={DELIVERY_TABS.TAB_HISTORY}>
          <HistoryTab
            entityId={delivery.id}
            entityType={"delivery"}
            fieldNameToLabelMap={fieldNameToLabelMap}
          />
        </TabPane>
      </TabContent>
    </div>
  );
}

export { EditForm, EditFormProps };
