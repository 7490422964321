import { buildFieldNameToLabelMap } from "../../utils/react-hook-form/buildFieldNameToLabelMap";
import type { FieldNameToLabelMap } from "../HistoryTab/HistoryTab";
import type { EntityType } from "../HistoryTab/HistoryTab.types";
import { PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA } from "../Paragraph6/Paragraph6ContractPage/Paragraph6ContractModals/Paragraph6ContractModal/Paragraph6ContractForm/Paragraph6ContractForm.constants";

export const HistoryFieldNameToLabelMaps: Record<
  EntityType,
  FieldNameToLabelMap
> = {
  paragraph6Contract: buildFieldNameToLabelMap(
    PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA
  )
};
