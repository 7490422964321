import classnames from "classnames";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";

import "./PremiumBadge.scss";

interface PremiumBadgeProps {
  className?: string;
  text?: string;
}
function PremiumBadge({ className, text = "Premium" }: PremiumBadgeProps) {
  return (
    <div className={classnames("premium-badge-container", className)}>
      <Icon name={IconName.Star} />
      <span className="premium-badge-text">{text}</span>
    </div>
  );
}

export { PremiumBadge };
