import type { AsyncSelectChoice } from "../../Select/AsyncSelect/AsyncSelect";
import type { SelectProps } from "../../Select/Select";
import { Select } from "../../Select/Select";
import "./AsyncSelect.scss";

interface AsyncSelectProps
  extends Pick<
    SelectProps,
    | "id"
    | "name"
    | "placeholder"
    | "isClearable"
    | "invalid"
    | "creatable"
    | "formatCreateLabel"
    | "onCreateOption"
  > {
  dataUrl: string;
  disabled?: SelectProps["isDisabled"];
  value?: AsyncSelectChoice["id"] | null;
  onChange: (value: AsyncSelectChoice["id"] | null) => void;
  labelField?: string;
}

function AsyncSelect({
  creatable,
  disabled,
  formatCreateLabel,
  value,
  onChange,
  onCreateOption,
  ...otherProps
}: AsyncSelectProps) {
  // todo: the types here are a mess but I want to fix production bug https://node-energy.atlassian.net/browse/PROD-779
  function handleChange(selected: unknown) {
    if (onChange) {
      if (!selected) {
        onChange(null);
      } else {
        onChange(
          Object.hasOwn(selected as object, "value")
            ? (selected as { value: AsyncSelectChoice["id"] }).value
            : null
        );
      }
    }
  }

  function handleCreation(inputValue: string) {
    if (onCreateOption) {
      onCreateOption(inputValue);
    }
  }

  return (
    <div className="AsyncSelect">
      <Select
        {...otherProps}
        creatable={creatable}
        defaultValue={value}
        formatCreateLabel={
          formatCreateLabel || ((inputValue) => `Erstelle "${inputValue}"`)
        }
        isDisabled={disabled}
        onChange={handleChange}
        onCreateOption={handleCreation}
      />
    </div>
  );
}

export { AsyncSelect, AsyncSelectProps };
