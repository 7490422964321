import type { Person } from "../../../utils/backend-types";

interface PersonCellProps {
  personId: number;
  persons: Array<Person>;
}

function PersonCell({ personId, persons }: PersonCellProps) {
  const person = persons
    ? persons.find((person) => person.id === personId)
    : null;
  const name = person ? person.name : "Kein Unternehmen gefunden.";

  return <span>{name}</span>;
}

export { PersonCell };
