import { useEffect, useState } from "react";

import { useSiteMutations } from "../../../../../hooks/useSiteMutations";
import { useSitesDetail } from "../../../../../hooks/useSitesDetail";
import { showToast } from "../../../../../utils/toast";
import { Checkbox } from "../../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";

interface NoScadaProps {
  siteId: number;
}

function NoScada({ siteId }: NoScadaProps) {
  const [noScada, setNoScada] = useState(false);
  const {
    data: site,
    error: errorSite,
    isLoading: isSiteLoading
  } = useSitesDetail(siteId);

  const { updateMutation } = useSiteMutations(siteId);

  useEffect(() => {
    if (site) {
      setNoScada(site.no_scada);
    }
  }, [site]);

  function handleChangeNoScada(checked: boolean) {
    setNoScada(checked);
    if (site) {
      updateMutation
        .mutateAsync({
          ...site,
          no_scada: checked
        })
        .catch((error) => {
          showToast("error", error);
          setNoScada(!checked);
        });
    }
  }

  return (
    <LoadOrError error={errorSite} loading={isSiteLoading}>
      <Checkbox checked={noScada} onChange={handleChangeNoScada}>
        Ich wünsche, dass für diese Liegenschaft die Stromsteuermeldung OHNE
        Berücksichtigung von SCADA-Daten berechnet wird.
        <br />
        Achtung: Bei einer Berechnung ohne SCADA-Daten berücksichtigt opti.node
        automatisch die gesetzlich vorgegebenen Sicherheitsaufschläge.
      </Checkbox>
    </LoadOrError>
  );
}

export { NoScada };
