import type { FormFieldValue } from "./FormField/FormField";
import type { FormItemsProps } from "./FormItems";
import type FormItemsType from "./FormItems";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function withDependentDaterange(
  FormItems: typeof FormItemsType,
  fieldName: string,
  dependentFieldName: string
) {
  const WrappedFormItemsWithDependentDaterange = ({
    onInput,
    ...otherProps
  }: FormItemsProps) => {
    function onLocalInput(name: string, value?: FormFieldValue) {
      if (name === fieldName && typeof value !== "undefined") {
        const oldValue = otherProps.formValues[fieldName];
        if ((value === "yes" && oldValue !== "yes") || value !== "yes") {
          onInput(null, {
            [name]: value,
            [dependentFieldName]:
              value === "yes" ? { lower: null, upper: null } : null
          });
        }
      } else {
        onInput(name, value);
      }
    }

    return <FormItems {...otherProps} onInput={onLocalInput} />;
  };

  return WrappedFormItemsWithDependentDaterange;
}

export { withDependentDaterange };
