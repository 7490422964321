import { Button, Group, List, Title } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertColor } from "../../../../../../Alert/Alert";
import { IconAlert } from "../../../../../../BuildingBlocks/IconAlert/IconAlert";
import { BasicConfirmationModal } from "../../../../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import type { BatchImportError } from "../types";
import { ComponentType } from "../types";
import { groupByComponent } from "./utils";

export interface BatchImportErrorDisplayProps {
  errors: Array<BatchImportError>;
}
function BatchImportErrorDisplay({ errors }: BatchImportErrorDisplayProps) {
  const { t } = useTranslation();

  const groupedErrors = groupByComponent(errors);

  return (
    <IconAlert color={AlertColor.Danger}>
      <Title mb="sm" order={5}>
        {t("errors.MieterstromBatchImport.ValidationFailed")}
      </Title>
      <List>
        {Object.entries(groupedErrors).map(([componentType, errors]) => {
          if (componentType === "ungrouped") {
            return errors.map((error, index) => (
              <List.Item key={index}>
                {t(`errors.MieterstromBatchImport.${error.name}`)}
              </List.Item>
            ));
          } else {
            return (
              <GroupedErrorListItem
                componentType={componentType as ComponentType}
                errors={errors}
                key={componentType}
              />
            );
          }
        })}
      </List>
    </IconAlert>
  );
}

function GroupedErrorListItem({
  componentType,
  errors
}: {
  componentType: ComponentType;
  errors: Array<BatchImportError>;
}) {
  const { t } = useTranslation();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  function toggle() {
    setDetailsModalOpen(!detailsModalOpen);
  }

  function getGroupErrorMessage() {
    switch (componentType) {
      case ComponentType.Connection:
        return t("errors.MieterstromBatchImport.ConnectionCreationError");
      case ComponentType.Consumer:
        return t("errors.MieterstromBatchImport.ConsumerCreationError", {
          amount: errors.length
        });
      case ComponentType.Generator:
        return t("errors.MieterstromBatchImport.GeneratorCreationError", {
          amount: errors.length
        });
      case ComponentType.BatteryStorage:
        return t("errors.MieterstromBatchImport.BatteryStorageCreationError");
      default:
        return "";
    }
  }

  function getDetailsModalTitle() {
    switch (componentType) {
      case ComponentType.Connection:
        return "Fehlerhafte Netzverknüpfungspunkt-Daten";
      case ComponentType.Consumer:
        return "Fehlerhafte Verbraucher-Daten";
      case ComponentType.Generator:
        return "Fehlerhafte Erzeuger-Daten";
      case ComponentType.BatteryStorage:
        return "Fehlerhafte Batteriespeicher-Daten";
      default:
        return "Fehlerhafte Daten";
    }
  }

  return (
    <List.Item>
      <Group gap="xs">
        {getGroupErrorMessage()}
        <Button
          h="auto"
          p={0}
          variant="transparent"
          onClick={() => setDetailsModalOpen(true)}
        >
          Details
        </Button>
      </Group>
      <BasicConfirmationModal
        headerText={getDetailsModalTitle()}
        isModalOpen={detailsModalOpen}
        size="lg"
        toggleModal={toggle}
        onConfirm={toggle}
      >
        <List>
          {errors.map((error, index) => (
            <List.Item key={index}>
              {t(`errors.MieterstromBatchImport.${error.name}`)}
            </List.Item>
          ))}
        </List>
      </BasicConfirmationModal>
    </List.Item>
  );
}

export { BatchImportErrorDisplay };
