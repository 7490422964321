import type { MRT_Cell, MRT_Row, MRT_TableInstance } from "mantine-react-table";
import type {
  EnergyRevenuesListItem,
  EnergyRevenuesTotals
} from "../../RevenueMonitoring.types";

const formatField = (value: number | null, decimalScale = 2): number | null =>
  value !== null && value !== undefined
    ? Math.round(value * Math.pow(10, decimalScale)) /
      Math.pow(10, decimalScale)
    : null;

function formatCellValue(
  cell: MRT_Cell<EnergyRevenuesListItem>,
  row: MRT_Row<EnergyRevenuesListItem>
): string | number {
  const key = cell.column.columnDef.accessorKey;

  if (key && typeof row.original[key] === "string") {
    return row.original[key];
  }

  if (key === "revenue_per_kwh") {
    const { net_revenue_minus_fee, direktvermarktung_volume } = row.original;
    return net_revenue_minus_fee && direktvermarktung_volume
      ? (formatField(net_revenue_minus_fee / direktvermarktung_volume, 3) ?? "")
      : "";
  }

  return formatField(Number(cell.getValue())) ?? "";
}

export function prepareTableDataForExport(
  table: MRT_TableInstance<EnergyRevenuesListItem>,
  totals: EnergyRevenuesTotals
): (string | number)[][] {
  const rowData = table
    .getPrePaginationRowModel()
    .rows.map((row) =>
      row.getAllCells().map((cell) => formatCellValue(cell, row))
    );

  const allColumns = table.getAllColumns();
  const rowHeaders = allColumns.map((column) => column.columnDef.header);
  const rowAccessorKeys = allColumns.map(
    (column) => column.columnDef.accessorKey
  );
  const rowTotals = rowAccessorKeys.map((key) =>
    key === "period_start"
      ? "Summe"
      : (formatField(totals[key as keyof EnergyRevenuesTotals] || null) ?? "")
  );

  return [rowHeaders, ...rowData, rowTotals];
}
