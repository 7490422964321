import { useEffect, useState } from "react";
import api from "../../../api";
import { useSystemCreatedTodos } from "../../../hooks/todos/useSystemCreatedTodos";
import { useContainerRef } from "../../../hooks/useContainerRef";
import urls from "../../../urls";
import type { ExtendedUser, Todo } from "../../../utils/backend-types";
import { TodoStatus } from "../../../utils/backend-types";
import { FINAL_TODO_YEAR, INITIAL_TODO_YEAR } from "../../../utils/dates";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { CustomForm } from "../../CustomForm/CustomForm";
import { FormFieldType } from "../../DynamicForm/FormItems/FormField/FormField";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { TodoSystemDecision, TodoUserDecision } from "../common";
import "./AutoFillTodoModal.scss";
import { TodoDecisionWidget } from "./TodoDecisionWidget";

const DEFAULT_USER_DECISION_BY_SYSTEM_DECISION = {
  [TodoSystemDecision.Applicable]: TodoUserDecision.Confirmed,
  [TodoSystemDecision.NotApplicable]: TodoUserDecision.Rejected,
  [TodoSystemDecision.CantTell]: TodoUserDecision.Unclear,
  [TodoSystemDecision.NeedMoreInformation]: TodoUserDecision.Unclear
};

const SUBMIT_BUTTON_TEXT = "Automatisch prüfen";
const EARLIEST_YEAR = 2020;
const YEAR_FIELD = "year";
const INITIAL_YEAR = INITIAL_TODO_YEAR;
const UPDATE_FORM_FIELDS = [
  {
    id: "tu-year",
    name: YEAR_FIELD,
    type: FormFieldType.Dropdown,
    label:
      "Bitte geben Sie das Meldejahr an, für welches die Prüfung der Aufgaben erfolgen soll.",
    choices: Array(FINAL_TODO_YEAR - EARLIEST_YEAR + 1)
      .fill("")
      .map((_, index) => ({
        value: FINAL_TODO_YEAR - index,
        displayName: (FINAL_TODO_YEAR - index).toString()
      })),
    initialValue: INITIAL_YEAR,
    allowNull: false,
    required: true
  }
];

interface AutoFillTodoModalProps {
  isOpen: boolean;
  variantId: number;
  users: Array<ExtendedUser>;
  onToggle: () => void;
  onRequireTodoRefresh: () => void;
}

function AutoFillTodoModal({
  isOpen,
  variantId,
  users,
  onToggle,
  onRequireTodoRefresh
}: AutoFillTodoModalProps) {
  const [buttonText, setButtonText] = useState(SUBMIT_BUTTON_TEXT);
  const [showTodoDecisionWidget, setShowTodoDecisionWidget] = useState(false);
  const [submittingTodos, setSubmittingTodos] = useState(false);
  const [todos, setTodos] = useState<Array<Todo>>([]);
  const [userDecisions, setUserDecisions] = useState<
    Record<number, TodoUserDecision>
  >({});
  const [finishedTodoIds, setFinishedTodoIds] = useState<Array<number>>([]);
  const [year, setYear] = useState(INITIAL_YEAR);
  const { containerNode, containerRef } = useContainerRef();

  const {
    data: systemCreatedTodos,
    error: systemCreatedTodosError,
    isLoading: systemCreatedTodosLoading
  } = useSystemCreatedTodos(variantId, showTodoDecisionWidget);

  useEffect(() => {
    if (systemCreatedTodos) {
      updateTodosAndUserDecisionsFromResponseData(systemCreatedTodos);
    }
  }, [systemCreatedTodos]);

  function updateTodosAndUserDecisionsFromResponseData(
    responseTodos: Array<Todo>
  ) {
    const unconfirmedTodos = responseTodos.filter(
      (todo) => todo.userDecision !== TodoUserDecision.Confirmed
    );
    const currentOrDefaultUserDecisions = responseTodos.reduce(
      (decisions: Record<number, TodoUserDecision>, todo) => {
        if (todo.userDecision === TodoUserDecision.Open) {
          if (todo.systemDecision) {
            const defaultUserDecision =
              DEFAULT_USER_DECISION_BY_SYSTEM_DECISION[todo.systemDecision];
            decisions[todo.id] = defaultUserDecision;
          } else {
            console.log("System decision wasn't set. Does this ever happen??"); // logging to check this case
            decisions[todo.id] = TodoUserDecision.Unclear;
          }
        } else {
          decisions[todo.id] = todo.userDecision;
        }

        return decisions;
      },
      {}
    );

    setTodos(unconfirmedTodos);
    setUserDecisions(currentOrDefaultUserDecisions);
  }

  function handleChangeUserDecisionAndFinished(
    todoId: number,
    userDecision: TodoUserDecision,
    finished: boolean
  ) {
    let newFinishedTodoIds: Array<number>;

    if (userDecision === TodoUserDecision.Confirmed && finished) {
      if (!finishedTodoIds.includes(todoId)) {
        newFinishedTodoIds = [...finishedTodoIds, todoId];
      } else {
        newFinishedTodoIds = finishedTodoIds;
      }
    } else {
      newFinishedTodoIds = finishedTodoIds.filter(
        (finishedTodoId) => finishedTodoId !== todoId
      );
    }

    setUserDecisions({
      ...userDecisions,
      [todoId]: userDecision
    });
    setFinishedTodoIds(newFinishedTodoIds);
  }

  function handleSave() {
    const decisionTodoIds = Object.keys(userDecisions).map((todoId) =>
      parseInt(todoId, 10)
    );
    const changedDecisionTodoIds = decisionTodoIds.filter((todoId) => {
      const originalTodoData = todos.find((todo) => todo.id === todoId);

      if (
        !originalTodoData ||
        originalTodoData.userDecision === userDecisions[todoId]
      ) {
        return false;
      }

      return true;
    });

    setSubmittingTodos(true);

    api
      .post(urls.api.updateTodoUserDecisions(), {
        todos: changedDecisionTodoIds.map((todoId) => ({
          id: todoId,
          userDecision: userDecisions[todoId]
        }))
      })
      .then(() => {
        // these calls are made after the decision updates because of a backend bug where the status updates are lost
        // if they are made before the decision updates have returned
        return Promise.all(
          finishedTodoIds.map((todoId) => {
            return api.post(urls.api.updateTodoStatus(todoId), {
              status: TodoStatus.Done
            });
          })
        );
      })
      .then(() => {
        onRequireTodoRefresh();
        onToggle();
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => setSubmittingTodos(false));
  }

  function handleClickBack() {
    setButtonText(SUBMIT_BUTTON_TEXT);
    setShowTodoDecisionWidget(false);
    setTodos([]);
    setUserDecisions({});
    setFinishedTodoIds([]);
    setYear(INITIAL_YEAR);
  }

  function handleClose() {
    onToggle();
  }

  function handleDone() {
    setShowTodoDecisionWidget(true);
  }

  function handleFirstSubmit() {
    setButtonText("Weiter");
  }

  function handleInput(name: string, value: string) {
    if (name === YEAR_FIELD) {
      setYear(parseInt(value, 10));
    }
  }

  const nonFieldData = {
    variant: variantId
  };

  return (
    <Modal
      className="AutoFillTodoModal"
      isOpen={isOpen}
      size="lg"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose}>
        Aufgaben {year} automatisch prüfen
      </ModalHeader>
      <ModalBody scrollable>
        {showTodoDecisionWidget ? (
          <LoadOrError
            error={systemCreatedTodosError}
            loading={systemCreatedTodosLoading}
          >
            {
              <TodoDecisionWidget
                finishedTodoIds={finishedTodoIds}
                todos={todos}
                userDecisions={userDecisions}
                users={users}
                onChangeUserDecisionAndFinished={
                  handleChangeUserDecisionAndFinished
                }
              />
            }
          </LoadOrError>
        ) : (
          <>
            <Section>
              Auf Basis Ihrer Projekt-Konfiguration wird automatisch überprüft,
              welche Aufgaben erfüllt werden müssen. Während dieser Prüfung
              werden Sie möglicherweise nach weiteren Informationen gefragt.
            </Section>
            <CustomForm
              buttonContainer={containerNode}
              formFields={UPDATE_FORM_FIELDS}
              nonFieldData={nonFieldData}
              postUrl={urls.api.todosUpdateList()}
              submitButtonText={buttonText}
              onFirstSubmit={handleFirstSubmit}
              onInput={handleInput}
              onPollSuccess={handleDone}
              onSubmit={() => {
                /*do nothing - wait for handlePollSuccess*/
              }}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {showTodoDecisionWidget && (
          <Button
            className="back-button"
            color="secondary"
            onClick={handleClickBack}
          >
            Zurück
          </Button>
        )}
        <Button color="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <div ref={containerRef} />
        {showTodoDecisionWidget && (
          <SpinButton
            color="primary"
            spin={submittingTodos}
            onClick={handleSave}
          >
            Auswahl übernehmen
          </SpinButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

export { AutoFillTodoModal, AutoFillTodoModalProps };
