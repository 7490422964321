import { useMutation } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { SiteSetupProcess } from "./useSiteSetupProcesses";

export function useSiteSetupProcessMutations() {
  const deleteMutation = useMutation({
    mutationFn: (setupProcessId: SiteSetupProcess["id"]) =>
      deleteSite(setupProcessId)
  });

  return { deleteMutation };
}

function deleteSite(setupProcessId: SiteSetupProcess["id"]) {
  return apiWithoutCamelization.delete(
    urls.apiWithoutCamelization.siteSetupAssistant.siteSetupProcess(
      setupProcessId
    )
  );
}
