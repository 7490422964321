import { useEffect, useState } from "react";

import type { Contract, Site } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import {
  TemplateDecisionCards,
  TemplateModalState
} from "../../../BuildingBlocks/TemplateDecisionCards/TemplateDecisionCards";
import { Button } from "../../../Buttons/Button/Button";
import { ContractModal } from "../ContractModal/ContractModal";

type NewContractDecisionModalProps = {
  variantId: number;
  templateContracts: Array<Contract>;
  sites?: Array<Site>;
  onSubmit: () => void;
  onClose: () => void;
};

function NewContractDecisionModal({
  variantId,
  onClose,
  onSubmit,
  templateContracts,
  sites
}: NewContractDecisionModalProps) {
  const [openModal, setOpenModal] = useState<TemplateModalState>(
    TemplateModalState.None
  );

  useEffect(() => {
    if (!templateContracts || templateContracts.length === 0) {
      setOpenModal(TemplateModalState.NewContract);
    }
  }, [templateContracts]);

  if (openModal === TemplateModalState.None)
    return (
      <Modal isOpen size="lg">
        <ModalHeader>Vertrag hinzufügen </ModalHeader>
        <ModalBody>
          <TemplateDecisionCards setModalState={setOpenModal} />
        </ModalBody>
        <ModalFooter>
          <div className="decision-modal-footer-container">
            <Button onClick={onClose}>Schließen</Button>
          </div>
        </ModalFooter>
      </Modal>
    );

  if (openModal === TemplateModalState.NewContract)
    return (
      <ContractModal
        variantId={variantId}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );

  return (
    <ContractModal
      sites={sites}
      templateContracts={templateContracts}
      variantId={variantId}
      withTemplate
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}

export { NewContractDecisionModal };
