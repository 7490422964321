import {
  Box,
  Group,
  Paper,
  SegmentedControl,
  Stack,
  Text
} from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import type { Meter } from "../../../utils/backend-types";
import { Product } from "../../../utils/enums";
import { PremiumBadge } from "../../PremiumBadge/PremiumBadge";
import { StructureViewFlowDiagram } from "../../StructureViewFlowDiagram/StructureViewFlowDiagram";
import { VariantRename } from "../../VariantRename/VariantRename";
import { CreateComponentDropdown } from "../CreateComponentDropdown/CreateComponentDropdown";
import { PersonsWidget } from "../PersonsWidget/PersonsWidget";
import { StructureViewMode } from "../StructureView.constants";
import { StructureViewModeContext } from "../StructureViewModeContext";
import { DiagramInfoModal } from "./DiagramInfoModal/DiagramInfoModal";

const MODE_CHOICES = [
  { label: "Normal", value: StructureViewMode.New },
  {
    label: "Messkonzept",
    value: StructureViewMode.MeteringConcept
  }
];

interface StructureViewDiagramContainerProps {
  siteWizardLoading: boolean;
  meteringConceptPhaseFinished?: boolean;
  enableStructureViewDiagramLoad: boolean;
  graphCoordinates: { x: number; y: number };
  hasGasConnection: boolean;
  isPremium: boolean;
  isPartialFeedin: boolean;
  isPPA: boolean;
  product: Product;
  projectId: string;
  meters?: Array<Meter>;
  siteId: number;
  variantId: number;
  handleShowNotAccessibleModal: (show: boolean) => void;
  invalidateMeterData: () => void;
  setGraphCoordinates: ({ x, y }: { x: number; y: number }) => void;
}

function StructureViewDiagramContainer({
  siteWizardLoading,
  meteringConceptPhaseFinished,
  enableStructureViewDiagramLoad,
  graphCoordinates,
  hasGasConnection,
  isPremium,
  isPPA,
  isPartialFeedin,
  product,
  projectId,
  siteId,
  variantId,
  meters,
  handleShowNotAccessibleModal,
  invalidateMeterData,
  setGraphCoordinates
}: StructureViewDiagramContainerProps) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { structureViewMode, updateStructureViewMode } = useContext(
    StructureViewModeContext
  );
  const showMeteringOption = (isPartialFeedin && isPremium) || isPPA;
  const waitForSiteWizard = siteWizardLoading && isPremium;
  const createComponentDropdownMemoized = useMemo(
    () => (
      <CreateComponentDropdown
        graphCoordinates={graphCoordinates}
        hasGasConnection={hasGasConnection}
        product={product}
        setShowNotAccessibleModal={handleShowNotAccessibleModal}
        siteId={siteId}
        variantId={variantId}
      />
    ),
    [
      graphCoordinates,
      product,
      siteId,
      variantId,
      hasGasConnection,
      handleShowNotAccessibleModal
    ]
  );

  useEffect(() => {
    if (
      isPPA ||
      (isPremium &&
        isPartialFeedin &&
        !meteringConceptPhaseFinished &&
        typeof meteringConceptPhaseFinished !== "undefined")
    ) {
      updateStructureViewMode(StructureViewMode.MeteringConcept);
    } else {
      updateStructureViewMode(StructureViewMode.New);
    }
  }, [
    isPPA,
    isPartialFeedin,
    isPremium,
    meteringConceptPhaseFinished,
    updateStructureViewMode
  ]);

  function switchToMeteringConceptMode() {
    setShowInfoModal(true);
  }

  function switchMode(mode: StructureViewMode) {
    if (mode === StructureViewMode.MeteringConcept) {
      switchToMeteringConceptMode();
    } else {
      updateStructureViewMode(mode);
    }
  }

  return (
    <Paper className="StructureViewDiagramContainer" h={680}>
      <Stack h="100%">
        {product === Product.Analyzer && (
          <Group variant="paper-header">
            <VariantRename variantId={variantId} />
          </Group>
        )}
        <Group justify={isPremium ? "space-between" : "flex-end"}>
          {isPremium && <PremiumBadge text="Premium Liegenschaft" />}
          <Group gap="xl" justify="flex-end">
            {showInfoModal && (
              <DiagramInfoModal
                onCancel={() => setShowInfoModal(false)}
                onConfirm={() => {
                  setShowInfoModal(false);
                  updateStructureViewMode(StructureViewMode.MeteringConcept);
                }}
              />
            )}
            {!waitForSiteWizard && showMeteringOption && (
              <Group gap="sm">
                <Text fw="bold">Ansicht:</Text>
                <SegmentedControl
                  data={MODE_CHOICES}
                  size="sm"
                  title="Messkonzept"
                  value={structureViewMode}
                  onChange={switchMode}
                />
              </Group>
            )}
            {createComponentDropdownMemoized}
          </Group>
        </Group>
        <Box flex="1">
          {!waitForSiteWizard && (
            <StructureViewFlowDiagram
              centerCoordinates={graphCoordinates}
              enabled={enableStructureViewDiagramLoad}
              invalidateMeterData={invalidateMeterData}
              meters={meters}
              mode={structureViewMode}
              projectId={projectId}
              report={false}
              setCoordinates={setGraphCoordinates}
              setShowNotAccessibleModal={handleShowNotAccessibleModal}
              siteId={siteId}
            />
          )}
        </Box>
        <PersonsWidget siteId={siteId} variantId={variantId} />
      </Stack>
    </Paper>
  );
}

export { StructureViewDiagramContainer, StructureViewDiagramContainerProps };
