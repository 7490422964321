import { useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";

interface ContractFormControlsProps {
  buttonContainer?: HTMLElement;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
}

function ContractFormControls({
  buttonContainer,
  onCancel,
  onSubmit
}: ContractFormControlsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleClickSubmit() {
    setIsSubmitting(true);

    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className="ContractFormControls">
      {buttonContainer &&
        ReactDOM.createPortal(
          <>
            <SpinButton
              color="primary"
              spin={isSubmitting}
              onClick={handleClickSubmit}
            >
              Speichern
            </SpinButton>
            <Button color="secondary" onClick={onCancel}>
              Abbrechen
            </Button>
          </>,
          buttonContainer
        )}
    </div>
  );
}

export { ContractFormControls, ContractFormControlsProps };
