import type {
  FormatToErrorObject,
  SiteSetupProcessForForm,
  SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function formatBackendErrorsToFrontendErrors(
  errorData: FormatToErrorObject<SiteSetupProcessRaw>
): Partial<FormatToErrorObject<SiteSetupProcessForForm>> {
  const newErrorData = {
    step: errorData?.step,

    /* site */
    name: errorData?.site?.name,
    pvPlants: errorData?.site?.pv_plants,
    siteAddress: errorData?.site?.address?.address,
    siteZip: errorData?.site?.address?.zip,
    siteCity: errorData?.site?.address?.city,
    siteSupplyType: errorData?.site?.supply_type,
    connectionVoltageLevel: errorData?.site?.connection_voltage_level,
    gridOperator: errorData?.site?.grid_operator,

    /* connection meter (in site) */
    connectionName: errorData?.site?.connection_meter?.name,
    connectionMeasurementType:
      errorData?.site?.connection_meter?.measurement_type,
    connectionMeteringDirection:
      errorData?.site?.connection_meter?.metering_direction,
    connectionMeteringServiceProvider:
      errorData?.site?.connection_meter?.metering_service_provider,
    // frontend-only parameter
    connectionLocationType:
      errorData?.site?.connection_meter?.metering_location ?? undefined,
    connectionMeteringLocation:
      errorData?.site?.connection_meter?.metering_location,
    connectionMarketLocationFeedin:
      errorData?.site?.connection_meter?.market_location_feedin,
    connectionMarketLocationFeedout:
      errorData?.site?.connection_meter?.market_location_feedout,

    defaultPvPlantsMeasurementType:
      errorData?.default_pv_plants_measurement_type,
    connectionMeterEnergyDataOrigin:
      errorData?.connection_meter_energy_data_origin,
    voltageLevelDeterminedAutomatically:
      errorData?.voltage_level_determined_automatically,
    consumerAmount: errorData?.consumer_amount,
    defaultConsumerType: errorData?.default_consumer_type,
    defaultConsumerMeasurementType:
      errorData?.default_consumer_measurement_type,
    allTenantAddressesAreSameAsSiteAddress:
      errorData?.all_tenant_addresses_are_same_as_site_address,
    allTenantsParticipatingInMieterstrom:
      errorData?.all_tenants_participating_in_mieterstrom,
    siteHasBatteryStorage: errorData?.site_has_battery_storage,
    isBatteryStorageMeasuredSeparately:
      errorData?.is_battery_storage_measured_separately,
    consumers: errorData?.site?.consumers
  };
  return Object.entries(newErrorData)
    .filter(([, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
}
