import { Highlight } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import WithHover from "../../../../utils/WithHover";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../Buttons/EditIcon";
import { PremiumBadge } from "../../../PremiumBadge/PremiumBadge";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import "./SiteWithHover.scss";

interface SiteProps {
  name: string;
  siteId: number;
  onClickEdit: () => void;
  onClickDelete: () => void;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isPremium: boolean;
  highlight: string;
}

function SiteListElement({
  name,
  siteId,
  onClickEdit,
  onClickDelete,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  isPremium,
  highlight
}: SiteProps) {
  const location = useLocation();
  const basePath = location.pathname.includes("liegenschaften")
    ? ""
    : "liegenschaften/";
  const href = `${basePath}${siteId}`;

  return (
    <Row
      className="SiteListElement"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Col md={10} sm={9}>
        <div className="site-name-container">
          <div className="ml-3 site-name">
            <Link to={href}>
              <Highlight highlight={highlight}>{name}</Highlight>
            </Link>
          </div>
          {isPremium && <PremiumBadge className="premium" />}
        </div>
      </Col>

      <Col className="icons" md={2} sm={3}>
        {isHovered && (
          <SiteIcons onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        )}
      </Col>
    </Row>
  );
}

function SiteIcons({
  onClickEdit,
  onClickDelete
}: {
  onClickEdit: () => void;
  onClickDelete: () => void;
}) {
  const shouldShowStaffView = useShouldShowStaffView();
  return (
    <>
      <EditIcon tooltipText="Liegenschaft bearbeiten" onClick={onClickEdit} />
      {shouldShowStaffView && (
        <DeleteIcon
          tooltipText="Liegenschaft löschen"
          onClick={onClickDelete}
        />
      )}
    </>
  );
}

const SiteWithHover = WithHover(SiteListElement);

export { SiteWithHover };
