import { useEffect, useState } from "react";
import {
  Link,
  generatePath,
  useMatch,
  useResolvedPath
} from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";

import { ROUTES } from "../../../../routes";
import urls from "../../../../urls";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import type { SiteOverviewSite, SiteStatusSite } from "../../site-status-types";

interface NameCellProps {
  value: string;
  original: SiteStatusSite | SiteOverviewSite;
  simple?: boolean;
}

function NameCell({ value, original, simple }: NameCellProps) {
  return (
    <LinkedLabelCell
      label={value}
      projectId={original.projectId}
      simple={simple}
      siteId={original.id}
    />
  );
}

interface LinkedLabelCellProps {
  label: string;
  siteId: number;
  projectId: string;
  simple?: boolean;
}

function LinkedLabelCell({
  label,
  siteId,
  projectId,
  simple
}: LinkedLabelCellProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const resolved = useResolvedPath(":year");
  const match = useMatch({ path: resolved.pathname, end: true });
  const year = match && match.params.year ? match.params.year : undefined;
  const url = year
    ? urls.siteStatusPageByYear(siteId, parseInt(year, 10))
    : urls.siteStatusPage(siteId);

  useEffect(() => {
    document.addEventListener("click", closePopover);

    return () => {
      document.removeEventListener("click", closePopover);
    };
  }, []);

  const toggle = () => setPopoverOpen(!popoverOpen);

  function closePopover() {
    setPopoverOpen(false);
  }

  const nameCellId = `linked-label-cell-span-${siteId}`;
  const managerVariantStructurePath = generatePath(
    ROUTES.managerVariantStructure,
    { projectId: projectId, siteId: siteId.toString() }
  );

  if (simple) {
    return <Link to={managerVariantStructurePath}>{label}</Link>;
  }

  return (
    <>
      <span className="linked-label-cell-span" id={nameCellId} onFocus={toggle}>
        {label}
      </span>
      <Popover
        isOpen={popoverOpen}
        placement="bottom"
        target={nameCellId}
        toggle={toggle}
      >
        <PopoverBody className="linked-label-cell-popover-body">
          <div className="linked-label-cell-popover-body-link">
            <Icon name={IconName.ExternalLink} />
            <a href={url} rel="noopener noreferrer" target="_blank">
              Detailansicht öffnen
            </a>
          </div>
          <div className="linked-label-cell-popover-body-link">
            <Icon name={IconName.ExternalLink} />
            <a
              href={managerVariantStructurePath}
              rel="noopener noreferrer"
              target="_blank"
            >
              In opti.node öffnen
            </a>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
}

export { NameCell };
