import type { DropdownToggleProps as ReactstrapDropdownToggleProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { DropdownToggle as ReactstrapDropdownToggle } from "reactstrap";

type DropdownToggleProps = ReactstrapDropdownToggleProps;

function DropdownToggle(props: DropdownToggleProps) {
  return <ReactstrapDropdownToggle {...props} />;
}

export { DropdownToggle, DropdownToggleProps };
