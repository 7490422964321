import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import type { Site } from "../../../../utils/backend-types";
import { SiteWithHover } from "./SiteWithHover";
import { SiteItem } from "./SiteWithSetupProcess/SiteItem";

interface SiteActionRowProps {
  site: Site;
  isPremium: boolean;
  onDelete: (site: Site) => void;
  onClickEdit: (site: Site) => void;
  highlight: string;
}

function SiteActionRow({
  site,
  isPremium,
  onDelete,
  onClickEdit,
  highlight
}: SiteActionRowProps) {
  const { featureFlags } = useFeatureFlags();
  if (!featureFlags.siteSetupAssistant) {
    // Old display with a hover effect. This will be replaced completely by the new display once
    // the feature flag is removed and the new feature is approved.
    return (
      <SiteWithHover
        highlight={highlight}
        isPremium={isPremium}
        key={site.id}
        name={site.name}
        siteId={site.id}
        onClickDelete={() => onDelete(site)}
        onClickEdit={() => onClickEdit(site)}
      />
    );
  } else {
    return (
      <SiteItem
        highlight={highlight}
        isPremium={isPremium}
        key={site.id}
        site={site}
        onClickEdit={() => onClickEdit(site)}
        onDelete={() => onDelete(site)}
      />
    );
  }
}

export { SiteActionRow };
