import { useProject } from "../../../../hooks/useProject";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { ProjectShareWidget } from "../../../ProjectShareWidget/ProjectShareWidget";

interface ProjectShareModalProps {
  isOpen: boolean;
  toggle: () => void;
  onShare: (projectId: string) => void;
  onError: (error: unknown) => void;
  projectId: string;
  projectName: string;
}

function ProjectShareModal({
  isOpen,
  toggle,
  onShare,
  onError,
  projectId,
  projectName
}: ProjectShareModalProps) {
  const { isLoading, error, project } = useProject(projectId);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{projectName} teilen</ModalHeader>
      <ModalBody scrollable>
        <LoadOrError error={error} loading={isLoading}>
          {project && (
            <ProjectShareWidget
              projectId={projectId}
              projectManagers={project.managers}
              onDone={toggle}
              onError={onError}
              onShare={() => onShare(projectId)}
            />
          )}
        </LoadOrError>
      </ModalBody>
    </Modal>
  );
}

export { ProjectShareModal };
