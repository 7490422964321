import { useGhostNodeMutation } from "../../hooks/useGhostNodeMutation";
import type { GhostNode } from "../../utils/backend-types";
import type { WizardModeProps } from "../VariantObjectWizard/VariantObjectWizard";
import { WizardSuccessWidget } from "../VariantObjectWizard/WizardSuccessWidget/WizardSuccessWidget";
import { GhostNodeForm } from "./GhostNodeForm";
import "./GhostNodeWizard.scss";

type GhostNodeWizardProps = {
  buttonContainer?: HTMLElement;
  siteId: number;
  onClose: () => void;
  graphCoordinates: { x: number; y: number };
} & WizardModeProps<GhostNode>;

function GhostNodeWizard({
  buttonContainer,
  siteId,
  onClose,
  graphCoordinates,
  ...modeProps
}: GhostNodeWizardProps) {
  const { isSuccess: wasFormSubmittedSuccessfully, mutateAsync } =
    useGhostNodeMutation(modeProps.mode, siteId, graphCoordinates);

  return (
    <div className="GhostNodeWizard">
      {!wasFormSubmittedSuccessfully ? (
        <GhostNodeForm
          buttonContainer={buttonContainer}
          onCancel={onClose}
          onSubmit={mutateAsync}
          {...modeProps}
        />
      ) : (
        <WizardSuccessWidget
          buttonContainer={buttonContainer}
          message={
            modeProps.mode === "create"
              ? "Der Verteilungspunkt wurde erstellt."
              : "Die Änderungen wurden gespeichert."
          }
          onClose={onClose}
        />
      )}
    </div>
  );
}

export { GhostNodeWizardProps, GhostNodeWizard };
