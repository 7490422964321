import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAreSpotMarketPricesAvailable } from "../../../../../hooks/useAreSpotMarketPricesAvailable";
import { MixKind } from "../../../../../types/api.types";
import type { Delivery } from "../../../../../utils/backend-types";
import { DeliveryType, PriceModel } from "../../../../../utils/backend-types";
import type {
  FormFieldData,
  FormInputData
} from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { setErrorsFromResponseData } from "../../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { applyHighlightingToObjectFormFieldData } from "../../../../VariantObjectWizard/utils/applyHighlightingToObjectFormFieldData";
import type { DeliveryFormOptions } from "../DeliveryModal.types";
import { DeliveringGenerator } from "./CustomFields/DeliveringGenerator/DeliveringGenerator";
import { DirectDeliveryFixedPriceFields } from "./CustomFields/DirectDeliveryFixedPriceFields/DirectDeliveryFixedPriceFields";
import { DirectDeliveryPassingOnFields } from "./CustomFields/DirectDeliveryPassingOnFields/DirectDeliveryPassingOnFields";
import { FieldName } from "./CustomFields/FieldNames";
import { ForwardFixedPriceFields } from "./CustomFields/ForwardFixedPriceFields/ForwardFixedPriceFields";
import { ForwardPassingOnFields } from "./CustomFields/ForwardPassingOnFields/ForwardPassingOnFields";
import { GridUsageFieldsWithHeader } from "./CustomFields/GridUsageFieldsWithHeader/GridUsageFieldsWithHeader";
import IndividualMixFields from "./CustomFields/IndividualMixFields/IndividualMixFields";
import { MixKindField } from "./CustomFields/MixKindField/MixKindField";
import { PriceModelWithHeader } from "./CustomFields/PriceModelWithHeader/PriceModelWithHeader";
import { DELIVERY_FORM_FIELD_DATA } from "./DeliveryForm.constants";
import { DeliveryFormControls } from "./DeliveryFormControls/DeliveryFormControls";

interface DeliveryFormProps {
  buttonContainer?: HTMLElement;
  delivery?: Delivery;
  options: DeliveryFormOptions;
  onSubmit: (delivery: Delivery) => Promise<Delivery>;
  onCancel: () => void;
  missingFields: Array<string>;
  initialContract?: string | null;
  shouldFieldBeHighlighted: (
    fieldName: string,
    relevantFormValues: Record<string, unknown>
  ) => boolean;
}

function DeliveryForm({
  buttonContainer,
  delivery,
  options,
  initialContract,
  onSubmit,
  onCancel,
  missingFields,
  shouldFieldBeHighlighted
}: DeliveryFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    resetField,
    register,
    unregister
  } = useForm<Delivery>({
    defaultValues: {
      ...delivery,
      contract: delivery?.contract ?? initialContract ?? undefined
    }
  });
  const { t } = useTranslation();
  const isEditMode = !!delivery;
  const { formFieldData } = applyHighlightingToObjectFormFieldData(
    DELIVERY_FORM_FIELD_DATA,
    watch,
    delivery
      ? {
          wizardObject: delivery,
          fieldsThatCouldBeMissing: missingFields,
          shouldFieldBeHighlighted
        }
      : undefined
  );
  const [spotMarketPricesError, setSpotMarketPricesError] =
    useState<Record<string, Array<string>>>();

  const watchDeliveryKind = watch("deliveryKind");
  const watchMixKind = watch("mixKind");
  const watchSite = watch("site");
  const watchContract = watch("contract");
  const watchGenerators = watch("generators");
  const watchPriceModel = watch("priceModel");
  const watchUseSpotMarketPrices = watch("useSpotMarketPrices");
  const watchMinimumPurchaseQuantityApplicable = watch(
    "minimumPurchaseQuantityApplicable"
  );
  const watchGridUsageBillingModel = watch("gridUsageBillingModel");
  const watchAutomaticallyDetermineGridFees = watch(
    "automaticallyDetermineGridFees"
  );

  const { areSpotMarketPricesAvailable } =
    useAreSpotMarketPricesAvailable(watchSite);

  register("generators");

  useEffect(() => {
    if (
      (watchDeliveryKind === DeliveryType.DirectDelivery &&
        watchMixKind === MixKind.Individual) ||
      (watchDeliveryKind === DeliveryType.ForwardingDelivery &&
        watchMixKind === MixKind.SubsidisedTenantSupply)
    ) {
      resetField("mixKind");
    }

    if (watchUseSpotMarketPrices === false) {
      unregister("spotMarketPrices");
      register("workingPrices");
    } else if (watchUseSpotMarketPrices === true) {
      unregister("workingPrices");
      register("spotMarketPrices");
    }
  }, [
    resetField,
    watchDeliveryKind,
    watchMixKind,
    setValue,
    watchUseSpotMarketPrices,
    unregister,
    register
  ]);
  const onValidSubmit: SubmitHandler<Delivery> = async (data) => {
    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      if (
        Object.keys(error.response.data).includes(FieldName.SpotMarketPrices)
      ) {
        setSpotMarketPricesError(
          error.response.data[FieldName.SpotMarketPrices]
        );
      }
      setErrorsFromResponseData<Delivery>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  function handleClickCancel() {
    reset();
    onCancel();
  }

  const formClassNames = "DeliveryForm FormItems";
  const submitCallback = handleSubmit(onValidSubmit);

  return (
    <form className={formClassNames}>
      <FormFieldController
        control={control}
        data={
          {
            ...formFieldData.site,
            choices: options.sites,
            disabled: isEditMode
          } as FormInputData<Delivery, "site">
        }
        error={errors.site}
      />
      <FormFieldController
        control={control}
        data={
          {
            ...formFieldData.deliveryKind,
            disabled: isEditMode
          } as FormInputData<Delivery, "deliveryKind">
        }
        error={errors.deliveryKind}
      />

      <MixKindField
        control={control}
        data={formFieldData.mixKind as FormInputData<Delivery, "mixKind">}
        deliveryType={watchDeliveryKind}
        error={isFieldError(errors.mixKind) ? errors.mixKind : undefined}
        mixKindValue={watchMixKind}
      />
      {watchMixKind === MixKind.Individual && (
        <IndividualMixFields
          control={control}
          errors={errors}
          formFieldData={formFieldData as FormFieldData<Delivery>}
        />
      )}

      {watchMixKind === MixKind.Green && (
        <FormFieldController
          control={control}
          data={formFieldData.greenCountries}
          error={errors.greenCountries}
        />
      )}
      {watchDeliveryKind === "direct_delivery" && watchContract && (
        <DeliveringGenerator
          contract={watchContract}
          control={control}
          disabled={isEditMode}
          error={
            isFieldError(errors.generators) ? errors.generators : undefined
          }
          generatorsValue={watchGenerators}
          resetGenerators={() => resetField("generators")}
          site={watchSite}
        />
      )}
      {watchDeliveryKind && (
        <PriceModelWithHeader
          control={control}
          data={
            formFieldData.priceModel as FormInputData<Delivery, "priceModel">
          }
          disabled={isEditMode}
          error={
            isFieldError(errors.priceModel) ? errors.priceModel : undefined
          }
        />
      )}
      {watchDeliveryKind && (
        <FormFieldController
          control={control}
          data={formFieldData.minimumPurchaseQuantityApplicable}
          error={errors.minimumPurchaseQuantityApplicable}
        />
      )}
      {watchDeliveryKind && watchMinimumPurchaseQuantityApplicable && (
        <FormFieldController
          control={control}
          data={formFieldData.minimumPurchaseQuantity}
          error={errors.minimumPurchaseQuantity}
          rules={{
            validate: (value) => {
              if (value !== null && typeof value !== "number") {
                return "Der Wert muss eine Zahl sein";
              }
              if (value !== null && typeof value === "number" && value <= 0) {
                return "Der Wert muss größer als 0 sein";
              }
              if (!Number.isInteger(value)) {
                return "Der Wert muss ganzzahlig sein";
              }
              return true;
            }
          }}
        />
      )}
      {watchDeliveryKind === DeliveryType.DirectDelivery &&
        watchPriceModel === PriceModel.FixedPrice && (
          <DirectDeliveryFixedPriceFields
            control={control}
            errors={errors}
            formFieldData={formFieldData as FormFieldData<Delivery>}
          />
        )}

      {watchDeliveryKind === DeliveryType.DirectDelivery &&
        watchPriceModel === PriceModel.PassingOn && (
          <DirectDeliveryPassingOnFields
            areSpotMarketPricesAvailable={areSpotMarketPricesAvailable}
            control={control}
            errors={errors}
            formFieldData={formFieldData as FormFieldData<Delivery>}
            spotMarketPricesError={spotMarketPricesError}
            useSpotMarketPrices={watchUseSpotMarketPrices}
          />
        )}

      {watchDeliveryKind === DeliveryType.ForwardingDelivery &&
        watchPriceModel === PriceModel.FixedPrice && (
          <ForwardFixedPriceFields control={control} errors={errors} />
        )}

      {watchDeliveryKind === DeliveryType.ForwardingDelivery &&
        watchPriceModel === PriceModel.PassingOn && (
          <ForwardPassingOnFields
            areSpotMarketPricesAvailable={areSpotMarketPricesAvailable}
            control={control}
            errors={errors}
            formFieldData={formFieldData as FormFieldData<Delivery>}
            spotMarketPricesError={spotMarketPricesError}
            useSpotMarketPrices={watchUseSpotMarketPrices}
          />
        )}
      {watchDeliveryKind === DeliveryType.ForwardingDelivery && (
        <GridUsageFieldsWithHeader
          automaticallyDetermineGridFeesValue={
            watchAutomaticallyDetermineGridFees
          }
          control={control}
          errors={errors}
          formFieldData={formFieldData as FormFieldData<Delivery>}
          gridUsageBillingModelValue={watchGridUsageBillingModel}
          setAutomaticallyDetermineGridFeesValue={(value: boolean) =>
            setValue("automaticallyDetermineGridFees", value)
          }
          siteId={watchSite}
        />
      )}
      <DeliveryFormControls
        buttonContainer={buttonContainer}
        onCancel={handleClickCancel}
        onSubmit={submitCallback}
      />
    </form>
  );
}

export { DeliveryForm, DeliveryFormProps };
