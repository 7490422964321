import { FormItem } from "../../DynamicForm/FormItem/FormItem";
import type {
  Choice,
  ChoiceValue
} from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type {
  FormFieldType,
  FormFieldValue
} from "../../DynamicForm/FormItems/FormField/FormField";

export interface FieldInfo {
  required: boolean;
  name: string;
  label: string;
  type: FormFieldType;
  helpText?: string;
  infoText?: string;
  inputGroupText?: string;
  placeholder?: string;
}

type FormInputV2GenericProps = {
  fieldInfo: FieldInfo;
  requiredFieldErrors?: string[];
  updateFormValues: (key: string, value: FormFieldValue) => void;
  allowInput?: boolean;
  currentValue?: FormFieldValue;
  choices?: Choice<ChoiceValue>[] | undefined;
};

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function FormInputV2({
  requiredFieldErrors,
  updateFormValues,
  allowInput,
  currentValue,
  fieldInfo,
  ...rest
}: FormInputV2GenericProps) {
  if ("choices" in rest) {
    return (
      <FormItem
        allowInput={allowInput}
        choices={rest.choices}
        errors={requiredFieldErrors}
        helpText={fieldInfo.helpText}
        infoText={fieldInfo.infoText}
        initialValue={currentValue}
        inputGroupText={fieldInfo.inputGroupText}
        key={fieldInfo.name}
        label={fieldInfo.label}
        name={fieldInfo.name}
        placeholder={fieldInfo.placeholder}
        required={fieldInfo.required}
        type={fieldInfo.type}
        value={currentValue}
        onInput={updateFormValues}
      />
    );
  } else
    return (
      <FormItem
        allowInput={allowInput}
        errors={requiredFieldErrors}
        helpText={fieldInfo.helpText}
        infoText={fieldInfo.infoText}
        inputGroupText={fieldInfo.inputGroupText}
        label={fieldInfo.label}
        name={fieldInfo.name}
        placeholder={fieldInfo.placeholder}
        required={fieldInfo.required}
        type={fieldInfo.type}
        value={currentValue}
        onInput={updateFormValues}
      />
    );
}

export { FormInputV2 };
