import { TsDropdown } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

interface StatusCellProps {
  statusValue?: boolean;
  onChange: (newValue: boolean) => void;
}

const PARTICIPATION_CHOICES = [
  {
    displayName: "Ja",
    value: true
  },
  {
    displayName: "Nein",
    value: false
  }
];

function StatusCell({ statusValue, onChange }: StatusCellProps) {
  return (
    <div className="StatusCell">
      <TsDropdown
        choices={PARTICIPATION_CHOICES}
        defaultValue={!!statusValue}
        id={"participationsInMieterstromModelDropdown"}
        name={"participationsInMieterstromModel"}
        required={true}
        onChange={(_, value) => onChange(value as boolean)}
      />
    </div>
  );
}

export { StatusCell };
