import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";

type Tag = {
  name: string;
  backgroundColor: string;
  color: string;
  longName: string;
  slug: string;
};

export function ClassificationTag({ slug }: { slug: string }) {
  const { data: tags } = useQuery({
    queryKey: ["tags"],
    queryFn: async () =>
      (await api.get<Tag[]>(urls.api.energyDataAcquisitionTags())).data,
    staleTime: Infinity
  });

  const tagsReduced = tags?.reduce(
    (acc, { name, color, backgroundColor, longName, slug }) => ({
      ...acc,
      [slug]: { name, color, backgroundColor, longName }
    }),
    {}
  );

  const tag = tagsReduced?.[slug];

  const { backgroundColor, color, name } = tag ?? {};

  return (
    <span
      style={{
        color: color,
        background: backgroundColor,
        padding: "5px",
        borderRadius: "5px",
        margin: "3px"
      }}
    >
      {name}
    </span>
  );
}
