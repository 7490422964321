import type { SiteName } from "../../utils/enums";
import { getSiteLogo } from "./utils/getSiteLogo";

interface SiteLogoProps {
  siteName: SiteName;
  useSecondaryImage?: boolean;
}

function SiteLogo({ siteName, useSecondaryImage = false }: SiteLogoProps) {
  return (
    <img alt="logo" id="logo" src={getSiteLogo(siteName, useSecondaryImage)} />
  );
}

export { SiteLogo };
