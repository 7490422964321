import { Box, Flex, Group, Highlight, Stack } from "@mantine/core";
import "./SiteWithSetupProcess.scss";
import { generatePath, Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../routes";
import { DeleteIcon } from "../../../../Buttons/DeleteIcon";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import type { SiteSetupProcess } from "../../../hooks/useSiteSetupProcesses";
import { ContinueEditingButton } from "./ContinueEditingButton";
import { DisplayStatus } from "./DisplayStatus";

interface SetupProcessItemProps {
  setupProcess: SiteSetupProcess;
  onDelete: (site: SiteSetupProcess) => void;
  highlight: string;
}

function SetupProcessItem({
  setupProcess,
  onDelete,
  highlight
}: SetupProcessItemProps) {
  const { projectId } = useParams();
  const shouldShowStaffView = useShouldShowStaffView();
  const path = generatePath(ROUTES.siteSetupAssistant, {
    projectId: projectId || null,
    siteSetupProcessId: setupProcess.id || null
  });

  return (
    <Box className="SiteWithSetupProcess">
      <Stack gap="lg">
        <Group gap="lg" justify="space-between">
          <Group>
            <a href={path}>
              <Highlight fw={500} highlight={highlight}>
                {setupProcess.name}
              </Highlight>
            </a>
            <DisplayStatus status={setupProcess.status} />
          </Group>
          {shouldShowStaffView && (
            <DeleteIcon
              tooltipText="Liegenschaft löschen"
              onClick={() => onDelete(setupProcess)}
            />
          )}
        </Group>
        <Flex justify="flex-end">
          <Link to={path}>
            <ContinueEditingButton status={setupProcess.status} />
          </Link>
        </Flex>
      </Stack>
    </Box>
  );
}
export { SetupProcessItem, SetupProcessItemProps };
