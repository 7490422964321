export function getErrorMessageAndStep(plausibilityError: string) {
  switch (plausibilityError) {
    case "ConsumerMeterMixed":
      return {
        message:
          "Es sind verschiedene Messintervalle (Lastgang vs. Zählerstände) an den Verbrauchsstellen vorhanden. Bitte geben Sie ein einheitliches Messintervall für die Verbrauchsstellen an. Andernfalls ist es nicht möglich, die Verbräuche miteinander zu verrechnen und stimmige Stromlieferungen auszurechnen.",
        step: 5
      };
    case "GeneratorMeterMixed":
      return {
        message:
          "Es sind verschiedene Messintervalle (Lastgang vs. Zählerstände) an den Erzeugungsanlagen vorhanden. Bitte geben Sie ein einheitliches Messintervall für die Erzeugungsanlagen an. Andernfalls ist es nicht möglich, die Werte miteinander zu verrechnen. Möglich sind drei Optionen: a) alle Erzeuger werden mittels Lastgangzählern gemessen, b) alle Erzeuger werden mittels Zählerstandszählern gemessen oder c) alle Erzeuger sind ungemessen. ",
        step: 5
      };
    case "MoreThanOneConsumerUnmeasured":
      return {
        message:
          "Es gibt zu viele ungemessene Verbrauchsorte. Es kann nur ein ungemessener Verbraucher als Differenz aller anderer Zähler ermittelt werden. Bitte prüfen Sie, ob maximal eine Verbrauchsstelle mit “Kein Zähler vorhanden” konfiguriert ist.",
        step: 5
      };
    case "OneConsumerAndSomethingElseUnmeasured":
      return {
        message:
          "Mindestens zwei der folgenden Elemente werden nicht gemessen: Verbrauchsstelle(n), Erzeugungsanlage(n) inkl. Batteriespeicher, Netzübergabemessung. Es kann maximal ein Element nicht gemessen werden (PV-Anlage(n) inklusive Batteriespeicher gelten als ein Element), denn das ungemessene Element wird als Differenz aller anderen Zähler ermittelt. Bitte prüfen Sie, dass höchstens eines der genannten Elemente ungemessen ist.",
        step: 5
      };
    case "NoParticipant":
      return {
        message:
          "Es gibt keinen Verbraucher, welcher am Mieterstrommodell teilnimmt und daher mit Solarstrom versorgt werden soll. Es muss mindestens einen Teilnehmer geben, andernfalls kann opti.node keine Lieferungen berechnen. Bitte prüfen Sie, dass mindestens für einen Verbraucher die Frage nach der Teilnahme am Mieterstrommodell mit “Ja” beantwortet wird.",
        step: 4
      };
    default:
      return {
        message: "Ein unbekannter Fehler ist aufgetreten.",
        step: 0
      };
  }
}
