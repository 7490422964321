import classNames from "classnames";
import type { To } from "react-router-dom";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconWithRouterLink } from "../BuildingBlocks/Icon/IconWithRouterLink/IconWithRouterLink";
import type { IconName } from "../BuildingBlocks/Icon/types";
import "./ActionIcon.scss";

interface ActionIconProps {
  to?: To;
  tooltipText?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  iconName: IconName;
  "data-testid"?: string;
}

function ActionIcon({
  to,
  tooltipText,
  onClick,
  disabled = false,
  iconName,
  className,
  "data-testid": dataTestId
}: ActionIconProps) {
  const wrappedOnClick =
    disabled || !onClick
      ? undefined
      : (e) => {
          onClick(e);
          e.stopPropagation();
        };
  if (to) {
    return (
      <IconWithRouterLink
        className={classNames("ActionIcon", { disabled }, className)}
        data-testid={dataTestId}
        name={iconName}
        to={to}
        tooltipText={tooltipText}
        onClick={wrappedOnClick}
      />
    );
  }

  return (
    <Icon
      className={classNames("ActionIcon", { disabled }, className)}
      data-testid={dataTestId}
      name={iconName}
      tooltipText={tooltipText}
      onClick={wrappedOnClick}
    />
  );
}

export { ActionIcon, ActionIconProps };
