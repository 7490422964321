import { ModalFooter } from "../../Layout/Modal/Modal";

interface FormFooterProps {
  children?: React.ReactNode;
}

function FormFooter({ children }: FormFooterProps) {
  return <ModalFooter>{children}</ModalFooter>;
}

export { FormFooter };
