import { Product } from "../../utils/enums";
import { withAnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type { StructureViewProps } from "../StructureView/StructureView";
import { StructureView } from "../StructureView/StructureView";

type WithAnimatedLoadingIconArgs = { requiredProps?: string[] };

const StructureViewWithAnimatedLoadingIcon =
  withAnimatedLoadingIcon(StructureView);

type Props = StructureViewProps & WithAnimatedLoadingIconArgs;

function AnalyzerStructureView({
  projectId,
  siteId,
  variantId,
  ...otherProps
}: Props) {
  return (
    <StructureViewWithAnimatedLoadingIcon
      {...otherProps}
      context={Product.Analyzer}
      projectId={projectId}
      requiredProps={["projectId", "siteId", "variantId"]}
      siteId={siteId}
      variantId={variantId}
    />
  );
}

export { AnalyzerStructureView };
