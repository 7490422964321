import classNames from "classnames";
import { useState } from "react";
import type {
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
  MenuPlacement,
  StylesConfig
} from "react-select";
import ReactSelect, { components } from "react-select";
import { type AsyncCreatableProps } from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import { THEME_VARS } from "../../../../utils/constants";
import { Icon } from "../../Icon/Icon";
import { IconName } from "../../Icon/types";
import type { AsyncSelectProps } from "./AsyncSelect/AsyncSelect";
import { AsyncSelect } from "./AsyncSelect/AsyncSelect";

interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AsyncCreatableProps<Option, IsMulti, Group> {
  creatable?: boolean;
  dataUrl?: string;
  invalid?: boolean;
  overridingStyles?: StylesConfig<Option, IsMulti, Group>;
  menuWidth?: string | number;
  labelField?: string;
}

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  options,
  dataUrl,
  creatable,
  defaultValue,
  placeholder = "Bitte auswählen...",
  isMulti,
  isClearable,
  isDisabled,
  isSearchable = true,
  invalid,
  id,
  name,
  formatCreateLabel,
  onChange,
  onCreateOption,
  overridingStyles,
  components = {},
  value,
  closeMenuOnSelect,
  menuWidth,
  className,
  labelField
}: SelectProps<Option, IsMulti, Group>) {
  const [isValidInput, setIsValidInput] = useState(!dataUrl);

  function getColor(isFocused?: boolean): string {
    let color: string = THEME_VARS.inputBorderColor;

    if (invalid) {
      color = THEME_VARS.dangerColor;
    } else if (isFocused) {
      color = THEME_VARS.brandColor;
    }

    return color;
  }

  const customStyles = {
    ...overridingStyles,
    control: (
      styles: CSSObjectWithLabel,
      props: ControlProps<Option, IsMulti, Group>
    ) => ({
      ...styles,
      boxShadow: "0px",
      borderColor: getColor(props.isFocused),
      "&:hover": {
        borderColor: getColor(props.isFocused)
      }
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#212529"
    }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: "none"
    }),
    option: (styles: CSSObjectWithLabel) => ({
      ...styles,
      minHeight: "35px"
    }),
    singleValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: THEME_VARS.nodeEnergyBlack,
      fontFamily: "sans-serif,Arial"
    }),
    menuPortal: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: menuWidth ? menuWidth : styles.width,
      zIndex: 9999
    })
  };

  const noOptionsMessage = () =>
    isValidInput
      ? "Keine Ergebnisse gefunden"
      : "Bitte mindestens 3 Zeichen eingeben...";
  const loadingMessage = () => "Suche läuft...";

  const sharedProps = {
    defaultValue: defaultValue,
    components: Object.assign(components, { DropdownIndicator }),
    styles: customStyles,
    noOptionsMessage: noOptionsMessage,
    loadingMessage: loadingMessage,
    placeholder: placeholder,
    menuPlacement: "auto" as MenuPlacement,
    menuPortalTarget: document.body,
    maxMenuHeight: 280,
    isClearable: isClearable,
    isDisabled: isDisabled,
    isSearchable: isSearchable,
    inputId: id,
    name: name,
    onChange: onChange,
    onCreateOption: onCreateOption,
    className: classNames("react-select", className),
    classNamePrefix: "react-select",
    closeMenuOnSelect: closeMenuOnSelect
  } as AsyncCreatableProps<Option, IsMulti, Group>;

  if (dataUrl && onChange) {
    return (
      <AsyncSelect<Option, IsMulti, Group>
        {...(sharedProps as AsyncSelectProps<Option, IsMulti, Group>)}
        creatable={creatable}
        dataUrl={dataUrl}
        formatCreateLabel={formatCreateLabel}
        labelField={labelField}
        onChange={onChange}
        onChangeIsValidInput={setIsValidInput}
        onCreateOption={onCreateOption}
      />
    );
  } else if (creatable) {
    // typescript hack to force Option type
    const defaultValueOption: Option = {
      label: defaultValue,
      value: defaultValue
    } as unknown as Option;

    return (
      <CreatableSelect
        options={options}
        {...sharedProps}
        defaultValue={defaultValueOption}
        formatCreateLabel={(label: string) => `Erstelle "${label}"`}
        styles={{
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            width: "100px",
            flexWrap: "nowrap",
            textOverflow: "ellipsis"
          })
        }}
        value={value}
      />
    );
  } else {
    return (
      <ReactSelect
        isMulti={isMulti}
        options={options}
        value={value}
        {...sharedProps}
      />
    );
  }
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name={IconName.AngleDown}
        style={{ fontSize: "0.8rem", fontWeight: "bold" }}
      />
    </components.DropdownIndicator>
  );
};

export { Select, SelectProps };
