function removeNonNumberKeys(
  arr: Array<{ [key: string]: unknown }>
): Array<{ [key: string]: number }> {
  return arr.map((obj) => {
    const newObj: { [key: string]: number } = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "number") {
        newObj[key] = obj[key] as number;
      }
    });
    return newObj;
  });
}

export default removeNonNumberKeys;
