import classnames from "classnames";
import Multiline from "../../../DynamicForm/FormItems/FormField/Multiline/Multiline";
import "./FreeTextField.scss";

type FreeTextFieldProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
};

function FreeTextField({ onChange, value, className }: FreeTextFieldProps) {
  return (
    <div className={classnames("free-text-field-container", className)}>
      <h6>Ergänzungen</h6>
      <Multiline
        id="free-text-field-delivery-model"
        name="free-text-field-delivery-model"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export { FreeTextField };
